/* eslint-disable */
import { Grid, RACButton } from "@rentacenter/racstrap";
import React, { useContext, useEffect, useState } from "react";
import { EmployerDetails, ParamType } from "../../../interface/globalInterface";
import { globalStyles } from "../../../jsStyles/globalStyles";
import { UpdateCustomerContext } from "../../../context/UpdateCustomerContext";
import SecondFactor from "../../../../racstrapComponent/manageCustomer/secondAuthen";
import {
  DATE_FORMAT,
  EMPTY_STRING,
  MODULE_NAME,
  STRING_NO,
  STRING_YES,
} from "../../../constants/constants";
import {
  updateArrayOfObjectState,
  updateCustomerAndVerification,
} from "../Utils";
import { useParams } from "react-router-dom";
import moment from "moment";
import { DynamicVerifiedStatus } from "../DynamicVerifiedStatus";

export function HandleEdit({ value, setterData }: any) {
  let {
    setUpdatedEmployerDetails,
    updatedEmployerDetails,
    setScreenLoader,
    setGetCustomerInfoResponse,
    setGetApprovalRes,
    currentRole,
    currentEmpId,
    setEmployerDetailsErrorMessage
  } = useContext(UpdateCustomerContext);
  const [showAuthentication, setShowAuthentication] = useState<boolean>(false);
  let { index, employerDetails, setEmployerDetails, checkMandatoryFields } =
    setterData;
  const { customerId } = useParams<ParamType>();
  const classes = globalStyles();


  const validateEmployerDetails: boolean =
  employerDetails[index]?.basicInfo?.companyName && employerDetails[index]?.basicInfo?.companyName !== "" &&
    (employerDetails[index]?.basicInfo?.phoneNumber
        ? employerDetails[index]?.basicInfo?.phoneNumber?.length > 9
        : true) &&
        employerDetails[index]?.incomeVerification?.paydayFrequency && employerDetails[index]?.incomeVerification?.paydayFrequency !== "" &&
    (employerDetails[index]?.incomeVerification?.paydayFrequency !== "DAILY"
      ? employerDetails[index]?.incomeVerification?.dayPaid && employerDetails[index]?.incomeVerification?.dayPaid !== ""
      : true);

      console.log("Employer Details", employerDetails, !validateEmployerDetails);

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const handleEditButton = async (isAuthenticated: boolean) => {
    if (isAuthenticated || !employerDetails[index].verified) {
      setScreenLoader(true);
      // eslint-disable-next-line no-console
      console.log("Inside HandleEdit", employerDetails, updatedEmployerDetails);
      const updatedDetails: any = [];
      for (let i = 0; i < employerDetails.length; i++) {
        const element = employerDetails[i];
        const verified = employerDetails.some((element: any) => element.verified == true);
        if (i == index) {
          updatedDetails.push({
            modifiedBy: index == 0 ? currentEmpId : undefined,
            verified,
            employerReferenceId: element.employerDetailsId,
            employerName: element.basicInfo.companyName,
            takeHomePay: element.incomeVerification.takeHomePay,
            lengthOfEmploymentYears: element.basicInfo.employmentYears,
            employerPhoneNumber: element.basicInfo.phoneNumber?.replace(/\D/g, ''),
            verifyCode: element.verifyCode,
            doNotCall: element.basicInfo.doNotCallEnabled
              ? STRING_YES
              : STRING_NO,
            employerPayschedule:
              element.incomeVerification.paydayFrequency == ""
                ? "0"
                : element.incomeVerification.paydayFrequency,
            employerPayscheduleDesc: element.incomeVerification.payDayFreqDesc,
            daysPaid:
              element.incomeVerification.dayPaid == ""
                ? "0"
                : element.incomeVerification.dayPaid,
            daysPaidDesc: element.incomeVerification.daysPaidDesc,
            decisionEngineId: element?.decisionEngineId,
            verifiedDate: element.verifiedDate
              ? moment(element.verifiedDate).format(DATE_FORMAT)
              : undefined,
            employmentBeginDate: element.basicInfo.employmentYears
              ? moment()
                  .subtract(Number(element.basicInfo.employmentYears), "years")
                  .format(DATE_FORMAT)
              : moment().format(DATE_FORMAT),
          });
        }
      }
      const updateEmpDetails = await updateCustomerAndVerification(
        updatedDetails,
        "EMP",
        customerId || "",
        setGetCustomerInfoResponse,
        setGetApprovalRes
      );
      // eslint-disable-next-line no-console
      console.log("UpdateEmpDetails", updateEmpDetails);
      if (updateEmpDetails) {
        if (employerDetails[index].verified) {
          const empDetails = [
            ...employerDetails.slice(0, index),
            {
              ...employerDetails[index],
              isEditEnabled: true,
              verified: true,
              verifiedBy: currentEmpId,
              verifiedSource: "RACPad",
              verifiedOn: moment().format("MM/DD,YYYY"),
              isCancellable: false
            },
            ...employerDetails.slice(index + 1),
          ];
          setEmployerDetails(empDetails);
          setUpdatedEmployerDetails(empDetails);
        } else {
          console.log("Inside Elseif employerDetails", employerDetails, updatedEmployerDetails);
          const empDetails = [
            ...employerDetails.slice(0, index),
            {
              ...employerDetails[index],
              isEditEnabled: true,
              verified: false,
              isCancellable: false
            },
            ...employerDetails.slice(index + 1),
          ];
          console.log("After Log", empDetails);
          setEmployerDetails(empDetails);
          setUpdatedEmployerDetails(empDetails);
        }
      }
      setScreenLoader(false);
      setShowAuthentication(false);
    } else if (!isAuthenticated) {
      setShowAuthentication(true);
    }
  };

  const handleCancel = () => {
    console.log('HandleCancel', employerDetails, updatedEmployerDetails);
    if(employerDetails[index].isCancellable){
      console.log('If');
      setEmployerDetails((prevDetails: EmployerDetails[]) =>
        prevDetails.filter((_: any, i: any) => i !== index)
      );
      setUpdatedEmployerDetails((prevDetails: EmployerDetails[]) =>
        prevDetails.filter((_, i) => i !== index)
      );
    } else {
      const updateEdit = {
        isEditEnabled: true,
      };
      setUpdatedEmployerDetails((prevDetails: any) => {
        const newDetails = prevDetails.map((detail: any, i: number) =>
          i === index ? { ...detail, ...updateEdit } : detail
        );

        setEmployerDetails((prevDetails: any) =>
          prevDetails.map((detail: any, i: number) =>
            i === index ? { ...newDetails[index] } : detail
          )
        );

        return newDetails;
      });
      setEmployerDetailsErrorMessage((prevErrors: any) =>
        prevErrors.map((error: any, i: number) =>
          i === index
            ? {
                ...error,
                basicInfo: {
                  companyName: EMPTY_STRING,
                  employmentYears: EMPTY_STRING,
                  phoneNumber: EMPTY_STRING,
                  doNotCallEnabled: error.basicInfo.doNotCallEnabled,
                  isEdited: error.basicInfo.isEdited,
                },
                incomeVerification: {
                  paydayFrequency: EMPTY_STRING,
                  dayPaid: EMPTY_STRING,
                  takeHomePay: EMPTY_STRING,
                  isEdited: error.incomeVerification.isEdited,
                  payDayFreqDesc: EMPTY_STRING,
                  daysPaidDesc: EMPTY_STRING,
                },
                addressInfo: {
                  addressLine1: EMPTY_STRING,
                  addressLine2: EMPTY_STRING,
                  zip: EMPTY_STRING,
                  city: EMPTY_STRING,
                  state: EMPTY_STRING,
                  doNotVisit: error.addressInfo.doNotVisit,
                  isEdited: error.addressInfo.isEdited,
                },
                verified: EMPTY_STRING,
                active: error.active,
                verifiedDate: EMPTY_STRING,
                employerDetailsId: null,
                overrideEnabled: error.overrideEnabled,
                isEditEnabled: error.isEditEnabled,
                verifiedBy: EMPTY_STRING,
                decisionEngineId: EMPTY_STRING,
                verifiedSource: EMPTY_STRING,
                verifyCode: EMPTY_STRING,
              }
            : error
        )
      );
    }
  }

  const updateEditButton = () => {
    const updateEdit = {
      isEditEnabled: false,
    };
    const employerEdit = {
      isEditEnabled: false,
      verified: false,
    };

    console.log('Before updatedEmployerDetails', updatedEmployerDetails);
    console.log('Before employerDetails', employerDetails);

    setUpdatedEmployerDetails((prev: any) => prev.map((empData: any, i: number) => i == index ? {...empData, ...updateEdit} : empData));
    setEmployerDetails((prev: any) => prev.map((empData: any, i: number) => i == index ? {...empData, ...employerEdit} : empData));
  };


  return (
    <Grid container md={12} className={`${classes.mt10px}`}>
      <Grid item md={10}>
        {updatedEmployerDetails[index].verified ? (
          <DynamicVerifiedStatus
            value={{
              verifiedBy: value.verifiedBy,
              verifiedSource: value.verifiedSource,
              verifiedOn: value.verifiedDate,
            }}
          />
        ) : null}
      </Grid>
      <Grid item md={2} className={`${classes.alignRight}`}>
        {!value.isEditEnabled ? (
          <RACButton
            variant="outlined"
            color="primary"
            onClick={() => {
              handleCancel();
            }}
          >
            Cancel
          </RACButton>
        ) : null}
        <RACButton
          variant="contained"
          color="primary"
          className={`${classes.ml10px} ${classes.alignRight}`}
          // disabled={!value.isEditEnabled ? !validateEmployerDetails : false}
          onClick={async () => {
            if (value.isEditEnabled) {
              updateEditButton();
            } else {
              checkMandatoryFields(index) ? handleEditButton(false) : null;
            }
          }}
        >
          {`${!value.isEditEnabled ? "Save" : "Edit"}`}
        </RACButton>
      </Grid>
      {showAuthentication ? (
        <SecondFactor
          setTwoFactorCancelClick={() => {
            setShowAuthentication(false);
          }}
          setTwoFactorCompleted={(e: any) => handleEditButton(e)}
          moduleName={MODULE_NAME.EMPLOYMENT_VERIFICATION}
          currentRole={currentRole}
        />
      ) : null}
    </Grid>
  );
}