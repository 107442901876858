/* eslint-disable prettier/prettier */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable no-debugger */
/* eslint-disable use-isnan */
/* eslint-disable sonarjs/no-collapsible-if */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable sonarjs/cognitive-complexity */
import React, { useState } from 'react';
import { useEffect } from 'react';
import {
  RACTextbox,
  RACDatePicker,
  RACSelect,
  RACTableCell,
  RACTable,
  RACTableRow,
  RACRadio,
  RACModalCard,
  RACButton,
  RACCheckBox,
  CircularProgress,
  Card,
  Grid,
  CardContent,
  Typography,
  Box,
} from '@rentacenter/racstrap';
import {
  CreateCustomerInValidPage,
  GetNODEReason,
  RunDE,
  UpdateNoDeReason,
  GetState,
  GetSuggestedAddress,
  GetReferenceValues,
  storeException
} from '../../api/user';
import moment from 'moment';
import clsx from 'clsx';
import HeaderWizards from './HeaderWizards';
import { headerWizardStyles } from '../headerWizardStyles';
import Modal from 'react-modal';
import { customerStyles } from '../customerStyles';
import { API_ERROR_MESSAGE } from '../../constants/constants';
import { ManageCustomerStyles } from '../ManageCustomerStyles';
import { useHistory } from 'react-router-dom';
import crypto, { createDecipheriv } from "crypto";
import { EncryptionService } from '@rentacenter/ess-ts-utils'
import validateAddressFn from '../../common/validateAddress';
import { ReactComponent as WarningIcon } from "../../assets/images/no-records-found.svg";
const decryptKeys = { encryptKey: crypto.randomBytes(16).toString("hex"), ivKey: crypto.randomBytes(8).toString("hex") }

export default function CreateCustomerComponent(props: any) {
  const history = useHistory();
  const classes = customerStyles();
  const classesManageCustomerStyles = ManageCustomerStyles();
  const [blurLoaderActive, setblurLoaderActive] = useState<any>(false);
  const [ValidateIsOpen, setValidateIsOpen] = useState(false);
  const [liketoopen, setliketoopen] = React.useState(false);
  const [enterssnisopen, setenterssnisopen] = React.useState(false);
  const [checkSSNEnter, setcheckSSNEnter] = React.useState(false);
  const [reasonDEopen, setreasonDEopen] = React.useState(false);
  const [dedeclineisopen, setdedeclineisopen] = React.useState(false);
  const [suggestionAddresPopup, setSuggestionAddresPopup] = useState(false);
  const [addressErrorMsg, setAddressErrorMsg] = useState<any>('');
  const [defaultSuggestedAddress, setdefaultSuggestedAddress] = useState('SUG');
  const [BtnIsdisabled1, setBtnIsdisabled1] = useState(true);
  const [BtnIsdisabled2, setBtnIsdisabled2] = useState(false);
  const [storeExceptionReason, setStoreExceptionReason] = useState<any>('')
  const [SuggestedAddress, setSuggestedAddress] = useState<any>();
  const [postnodeReason, setpostnodeReason] = useState('');
  const [FirstName, setFirstName] = useState('');
  const [LastName, setLastName] = useState('');
  const [Dob, SetDob] = useState('');
  const [clearbtnHidden, setclearbtnHidden] = useState(false);
  const [Ssn1, setssn1] = useState('');
  const [Ssn2, setssn2] = useState('');
  const [Ssn3, setssn3] = useState('');
  const [Phonenumber, setPhonenumber] = useState<any>('');
  const [DEStatus, setDEStatus] = useState<any>('');
  const [noDEReasonDD, setnoDEReasonDD] = useState<any>([]);
  const [stateDD, setDD] = useState<any>([{ label: 'Select', value: '0' }]);
  const [dropDownLoaded, setDropDownLoaded] = useState<boolean>(false);
  const [custID, setcustID] = useState<any>(undefined);
  const [Addressline1, setAddressline1] = useState<any>('');
  const [Addressline2, setAddressline2] = useState('');
  const [IsRunDE, setIsRunDE] = useState<boolean>(false);
  const [PhonenumberforrcustValidatoion, setPhonenumberforrcustValidatoion] =
    useState('');
  const [emailaddress, setemailaddress] = useState('');
  const [zip, setzip]: any = useState<any>('');
  const [city, setcity] = useState('');
  const [stateinfo, setstateinfo] = useState('0');
  const [enableValidatebtn, setenableValidatebtn] = useState<any>(true);
  const [ssnValid, setSsnValid] = useState(false);
  const [emailerrMsg, setemailerrMsg] = useState(false);
  const [clearbtndisable, setclearbtndisable] = useState<any>(true);
  const [disabledValidatebtn, setdisabledValidatebtn] = useState<any>(true);
  const [ssnDisable, setssnDisable] = useState<any>(false);
  const [dobdisable, setdobdisable] = useState<any>(false);
  const [errorCC, seterrorCC] = useState<any>(false);
  const [matchCode, setmatchCode] = useState<any>('');
  const [fieldValidationforblur, setfieldValidation] = useState<any>({
    dateOfBirth: false,
    emailAddress: false,
    mobilePhoneNumber: false,
    zipCode: false,
  });
  const dt = new Date();
  const [validatedAddresses,setValidatedAddress] = useState<any>({})
  const { useGoogleAddressValidation } =props;
  const [canSuggestAddress,setCanSuggestAddress] = useState(false);
  const [cantSuggestAddress,setCantSuggestAddress] = useState(false);
  const loginStore =
    localStorage.getItem('storeNumber') == undefined ||
      localStorage.getItem('storeNumber') == null
      ? sessionStorage.getItem('storeNumber')
      : localStorage.getItem('storeNumber');


  const getData = (key: string, iv: string, data: string) => {
    console.log('payloadKeys-->', key, iv, data)
    const decipher = createDecipheriv('aes-256-cbc', key, iv);
    let result: any = decipher.update(data, 'hex', 'utf8');
    result += decipher.final('utf8');
    console.log('payloadKeys', iv, key, data, result)
    return result;
  }

  const decryptObj = new EncryptionService({ iv: decryptKeys.ivKey, key: decryptKeys.encryptKey })
  // const payloadForDecrypt: any = { decryptData: {}, encryptKey: crypto.randomBytes(16).toString("hex"), ivKey: crypto.randomBytes(8).toString("hex") }
  useEffect(() => {
    const stDate = moment(Dob, 'YYYY-MM-DD');
    const lt18 = moment(moment().subtract(18, 'years'), 'YYYY-MM-DD');
    const lt110 = moment(moment().subtract(110, 'years'), 'YYYY-MM-DD');
    const lt18Res: any = lt18.diff(stDate, 'days');
    const lt110Res: any = lt110.diff(stDate, 'days');
    console.log('lt18Res', lt18Res);
    console.log('lt110Res', lt110Res);
    const diffDays = moment().diff(moment(Dob, 'YYYY-MM-DD'), 'days');
    console.log('diffDays', diffDays);
    if (
      FirstName !== '' &&
      LastName !== '' &&
      Dob !== '' &&
      /* Ssn1 !== "" &&
        Ssn2 !== "" &&
        Ssn3 !== "" &&
        ValidateEmail(emailaddress) &&
        PhonenumberforrcustValidatoion.length > 9 */
      Phonenumber.length > 9 &&
      Addressline1 !== '' &&
      city !== '' &&
      stateinfo !== '0' &&
      (zip.length == 5 || zip.length == 10)
    ) {
      //setenableValidatebtn(true);
      setdisabledValidatebtn(false);
    } else {
      setdisabledValidatebtn(true);
    }
    if (
      FirstName !== '' ||
      LastName !== '' ||
      Dob !== '' ||
      Ssn1 !== '' ||
      Ssn2 !== '' ||
      Ssn3 !== '' ||
      Phonenumber !== '' ||
      Addressline1 !== '' ||
      Addressline2 !== '' ||
      city !== '' ||
      stateinfo !== '' ||
      zip != ''
    ) {
      setclearbtndisable(false);
    } else {
      setclearbtndisable(true);
    }
    // eslint-disable-next-line no-console
    console.log('use effect for customer validation');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    FirstName,
    LastName,
    Addressline1,
    zip,
    Addressline2,
    city,
    stateinfo,
    Dob,
    Phonenumber,
    SuggestedAddress,
    PhonenumberforrcustValidatoion,
  ]);
  useEffect(() => {
    GetStateDD();
    console.log('props value', props);
    setPropsVal(props);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const setPropsVal = (props: any) => {
    if (
      props !== undefined &&
      props !== null &&
      props.basicInfo !== undefined &&
      props.basicInfo !== null &&
      props.basicInfo !== '' &&
      props.basicInfo.length > 0
    ) {
      if (
        props.basicInfo[0].firstName !== undefined &&
        props.basicInfo[0].firstName !== null &&
        props.basicInfo[0].firstName !== ''
      ) {
        setFirstName(props.basicInfo[0].firstName);
      }
      if (
        props.basicInfo[0].lastName !== undefined &&
        props.basicInfo[0].lastName !== null &&
        props.basicInfo[0].lastName !== ''
      ) {
        setLastName(props.basicInfo[0].lastName);
      }
      if (
        props.basicInfo[0].dateOfBirth !== undefined &&
        props.basicInfo[0].dateOfBirth !== null &&
        props.basicInfo[0].dateOfBirth !== ''
      ) {
        // console.log('decryptKeys', decryptKeys)
        debugger;
        //const res = decryptObj.
        // const decryptedDob = decryptObj?.decryptValues({ 'dateOfBirth': props.basicInfo[0].dateOfBirthEnc })
        // console.log('decryptedDob', decryptedDob)
        // const dob = getData(decryptKeys.encryptKey, decryptKeys.ivKey, decryptedDob?.decryptData?.dateOfBirth)
        // console.log('decryptedDob dob', dob)
        // const dob = moment(props.basicInfo[0].dateOfBirth, 'YYYY-MM-DD');
        // const lt18 = moment(moment().subtract(18, 'years'), 'YYYY-MM-DD');
        // const lt110 = moment(moment().subtract(110, 'years'), 'YYYY-MM-DD');
        // const lt18Res: any = lt18.diff(stDate, 'days');
        // const lt110Res: any = lt110.diff(stDate, 'days');
        // const res: any = lt18Res >= 0 && lt110Res < 0 ? true : false;
        // setfieldValidation({
        //   ...fieldValidationforblur,
        //   dateOfBirth: !res,
        // });
        const dob: any = moment(props.basicInfo[0].dateOfBirth).format(
          'MM/DD/YYYY'
        );
        // const temp = dob.replace(format);
        SetDob(dob);
        setdobdisable(true);
      }
      if (
        props.basicInfo[0].taxId !== undefined &&
        props.basicInfo[0].taxId !== null &&
        props.basicInfo[0].taxId !== ''
      ) {
        // const decryptedTaxId = decryptObj?.decryptValues({ 'taxId': props.basicInfo[0].taxIdEnc })
        // console.log('decryptedTaxId', decryptedTaxId)
        // const taxId = getData(decryptKeys.encryptKey, decryptKeys.ivKey, decryptedTaxId?.decryptData?.taxId)
        // console.log('decryptedTaxId taxId', taxId)
        setssnDisable(true);
        setssn1(props.basicInfo[0].taxId.slice(0, 3));
        setssn2(props.basicInfo[0].taxId.slice(3, 5));
        setssn3(props.basicInfo[0].taxId.slice(5));
      }
      if (
        props.basicInfo[0].emailAddress !== undefined &&
        props.basicInfo[0].emailAddress !== null &&
        props.basicInfo[0].emailAddress !== ''
      ) {
        setemailaddress(props.basicInfo[0].emailAddress);
      }
      if (
        props.basicInfo[0].phones !== undefined &&
        props.basicInfo[0].phones !== null &&
        props.basicInfo[0].phones !== '' &&
        props.basicInfo[0].phones.length > 0 &&
        props.basicInfo[0].phones[0].phoneNumber !== undefined &&
        props.basicInfo[0].phones[0].phoneNumber !== null &&
        props.basicInfo[0].phones[0].phoneNumber !== ''
      ) {
        const PhoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        const temp = props.basicInfo[0].phones[0].phoneNumber.replace(
          PhoneRegex,
          '($1) $2-$3'
        );
        //alert(temp);
        setPhonenumber(temp);
      }
      if (
        props.basicInfo[0].addresses !== undefined &&
        props.basicInfo[0].addresses !== null &&
        props.basicInfo[0].addresses !== '' &&
        props.basicInfo[0].addresses.length > 0 &&
        props.basicInfo[0].addresses[0].addressLine1 !== undefined &&
        props.basicInfo[0].addresses[0].addressLine1 !== null &&
        props.basicInfo[0].addresses[0].addressLine1 !== ''
      ) {
        setAddressline1(props.basicInfo[0].addresses[0].addressLine1);
      }
      if (
        props.basicInfo[0].addresses !== undefined &&
        props.basicInfo[0].addresses !== null &&
        props.basicInfo[0].addresses !== '' &&
        props.basicInfo[0].addresses.length > 0 &&
        props.basicInfo[0].addresses[0].addressLine2 !== undefined &&
        props.basicInfo[0].addresses[0].addressLine2 !== null &&
        props.basicInfo[0].addresses[0].addressLine2 !== ''
      ) {
        setAddressline2(props.basicInfo[0].addresses[0].addressLine2);
      }
      if (
        props.basicInfo[0].addresses !== undefined &&
        props.basicInfo[0].addresses !== null &&
        props.basicInfo[0].addresses !== '' &&
        props.basicInfo[0].addresses.length > 0 &&
        props.basicInfo[0].addresses[0].city !== undefined &&
        props.basicInfo[0].addresses[0].city !== null &&
        props.basicInfo[0].addresses[0].city !== ''
      ) {
        setcity(props.basicInfo[0].addresses[0].city);
      }
      if (
        props.basicInfo[0].addresses !== undefined &&
        props.basicInfo[0].addresses !== null &&
        props.basicInfo[0].addresses !== '' &&
        props.basicInfo[0].addresses.length > 0 &&
        props.basicInfo[0].addresses[0].postalCode !== undefined &&
        props.basicInfo[0].addresses[0].postalCode !== null &&
        props.basicInfo[0].addresses[0].postalCode !== ''
      ) {
        if (props.basicInfo[0].addresses[0].postalCode.length == 9) {
          const ziptr = /^\(?([0-9]{5})[-. ]?([0-9]{4})$/;
          const temp = props.basicInfo[0].addresses[0].postalCode.replace(
            ziptr,
            '$1-$2'
          );
          setzip(temp);
          // setzip(zipO);
        } else {
          setzip(props.basicInfo[0].addresses[0].postalCode);
        }
      }
      if (
        props.basicInfo[0].addresses !== undefined &&
        props.basicInfo[0].addresses !== null &&
        props.basicInfo[0].addresses !== '' &&
        props.basicInfo[0].addresses.length > 0 &&
        props.basicInfo[0].addresses[0].stateCode !== undefined &&
        props.basicInfo[0].addresses[0].stateCode !== null &&
        props.basicInfo[0].addresses[0].stateCode !== ''
      ) {
        setstateinfo(props.basicInfo[0].addresses[0].stateCode);
      }
    }
  };
  const GetNoDEReason = async () => {
    setDropDownLoaded(false);
    const res: any = await GetNODEReason();
    // eslint-disable-next-line no-console
    console.log(res);
    if (res !== undefined && res.referenceDetails !== undefined) {
      const sortedlist = res.referenceDetails.sort((a: any, b: any) =>
        a['description'] < b['description'] ? -1 : 1
      );
      const referenceDetailsObj: any = [];
      sortedlist.map((value: any, index: any) => {
        if (index === 0) {
          referenceDetailsObj.push({
            label: 'Select',
            value: '0',
          });
        }
        referenceDetailsObj.push({
          label: value.description,
          value: value.referenceCode,
        });
      });
      setnoDEReasonDD(referenceDetailsObj);
      setDropDownLoaded(true);
    }
  };
  const GetStateDD = async () => {
    if (loginStore !== undefined) {
      const res1 = await GetReferenceValues('entity/state_province');
      console.log('res1', res1)
      if (res1 && res1.data && res1.data.referenceDetails.length > 0) {
        const buildStateDropDown: any[] = [];
        res1.data.referenceDetails.map((value: any, index: any) => {
          if (index == 0) {
            buildStateDropDown.push({ label: 'Select', value: '0' });
          }
          buildStateDropDown.push({
            label: value.abbreviation,
            value: value.abbreviation,
          });
        });
        // const stateDDStructure = res1.map((el: any, i: any) => {
        //   res1[i].description = el.abbreviation;
        //   res1[i].label = el.abbreviation;
        //   res1[i].value = el.abbreviation;
        //   return el;
        // });
        // console.log('stateDDStructure', stateDDStructure);
        // const samp = { description: 'Select', label: 'Select', value: '0' };
        // stateDDStructure.unshift(samp);
        setDD(buildStateDropDown);
        console.log('DDStructure', buildStateDropDown);
        setDropDownLoaded(true);
        // return stateDD.map((el: any, i: any) => {
        //   return (
        //     <option key={i} value={el.abbreviation}>
        //       {el.abbreviation}
        //     </option>
        //   );
        // });
      } else {
        setDropDownLoaded(true);
        setDD([]);
      }
      // eslint-disable-next-line no-console
      console.log('state DD', res1);

      // setDD(res1);
    }
  };
  /*Method starts here*/
  const RadioBtnclick = (type: any) => {
    if (type === 'SUG') {
      setBtnIsdisabled1(true);
      setBtnIsdisabled2(false);
      setdefaultSuggestedAddress('SUG');
    } else if (type === 'ENT') {
      setBtnIsdisabled1(false);
      setBtnIsdisabled2(true);
      setdefaultSuggestedAddress('ENT');
    }else if(type =='GA'){
      setdefaultSuggestedAddress('GA')
    }
  };
  // yesHandlechange functionality for the yes button PC-CC-18
  const YesHandleChange = () => {
    setliketoopen(false);
    if (Ssn1.length + Ssn2.length + Ssn3.length < 9) {
      setenterssnisopen(true);
    }
  };
  // NOHandleChange functionality to open reason for NO-DE PC-CC-19
  const NOHandleChange = async () => {
    setliketoopen(false);
    setreasonDEopen(true);
    await GetNoDEReason();
  };
  // Deokclick functionality to open basic information page PC-CC-20
  const DeOkclick = async () => {
    console.log('DeOkclick', DEStatus);
    setdedeclineisopen(false);
    if (
      (DEStatus == '1' || DEStatus == '2' || DEStatus == '4') &&
      DEStatus !== ''
    ) {
      if (custID !== undefined && custID.customerId !== undefined) {
        history.push({
          pathname: `/customer/createcustomer/${custID.customerId}/create`,
        });
        // props.history.push(
        //   `/customer/createcustomer/${custID.customerId}/create`
        // );
      }
    } else if (DEStatus == '') {
      setreasonDEopen(true);
      await GetNoDEReason();
    }
    setIsRunDE(false);
  };
  const CreateCust = async (AddressAry?: any) => {
    const phonesNum: any = [
      {
        phoneType: 'CELL',
        primary: 'Y',
        callTimeType: 'MORN',
      },
    ];
    if (Phonenumber != '') {
      const PhonenumberObj = Phonenumber.replace(/\D/g, '');
      phonesNum[0].phoneNumber =
        PhonenumberforrcustValidatoion !== undefined &&
          PhonenumberforrcustValidatoion !== null &&
          PhonenumberforrcustValidatoion !== ''
          ? PhonenumberforrcustValidatoion
          : PhonenumberObj;
    }
    const addresse: any = [
      {
        addressType: 'PRIM',
        addressLine1:
          AddressAry == undefined
            ? Addressline1
            : AddressAry.Addresses.StreetAddress,
        city: AddressAry == undefined ? city : AddressAry.Addresses.City,
        postalCode:
          AddressAry == undefined ? zip : AddressAry.Addresses.PostalCode,
        state:
          AddressAry == undefined
            ? stateinfo
            : AddressAry.Addresses.StateProvince,
      },
    ];
    if (Addressline2 !== '') {
      addresse[0].addressLine2 = Addressline2;
    }
    if(useGoogleAddressValidation){
      if(AddressAry.Addresses.addressLine2){
        addresse[0].addressLine2 = AddressAry.Addresses.addressLine2 
      }
      else{
        addresse[0].addressLine2 = undefined
      }
    }
    const payloadReq: any = {
      firstName: FirstName,
      lastName: LastName,
      dateOfBirth: props?.basicInfo[0]?.dateOfBirthEnc || Dob,
      preferredLanguage: 'ENG',
      phones: phonesNum,
      addresses: addresse,
      textSolicitationAllowed: 'Y',
      mailSolicitationAllowed: 'Y',
    };
    if (props?.basicInfo[0]?.taxIdEnc) {
      payloadReq.taxId = props?.basicInfo[0]?.taxIdEnc
    } else {
      payloadReq.taxId = Ssn1 + Ssn2 + Ssn3;
    }
    if (emailaddress !== '') {
      payloadReq.emailAddress = emailaddress;
    }
    if (
      props !== undefined &&
      props !== null &&
      props.basicInfo !== undefined &&
      props.basicInfo !== null &&
      props.basicInfo !== '' &&
      props.basicInfo.length > 0 &&
      props.basicInfo[0].globalCustomerId !== undefined &&
      props.basicInfo[0].globalCustomerId !== null &&
      props.basicInfo[0].globalCustomerId !== ''
    ) {
      // const gcid: any = props.basicInfo[0].globalCustomerId;
      // payloadReq.globalCustomerId = 'D11812C0-D8AD-0965-E053-A11CFA0A781A';
      payloadReq.globalCustomerId = props.basicInfo[0].globalCustomerId;
    }
    if (
      props.basicInfo[0].salesLeadId !== undefined &&
      props.basicInfo[0].salesLeadId !== null &&
      props.basicInfo[0].salesLeadId !== ''
    ) {
      payloadReq.salesLeadId = props.basicInfo[0].salesLeadId;
    }
    if (props && props.webleadPaymentId) {
      payloadReq.webleadPaymentId = props.webleadPaymentId;
    }
    //gcid[0] + gcid[1] + gcid[2] + gcid[3] + gcid[4];
    const result: any = await CreateCustomerInValidPage(payloadReq);
    console.log('createCustomerResult', result);
    // eslint-disable-next-line sonarjs/no-all-duplicated-branches
    if (result.globalCustomerId && result.customerId) {
      setcustID(result);
      console.log('resultss', result);
      return result;
      //  checkIfSSNEnter();
    }
  };
  const ssnFoc: any = (e: any) => {
    if (e !== undefined) {
      const stDate = moment(e, 'YYYY-MM-DD');
      const lt18 = moment(moment().subtract(18, 'years'), 'YYYY-MM-DD');
      const lt110 = moment(moment().subtract(110, 'years'), 'YYYY-MM-DD');
      const lt18Res: any = lt18.diff(stDate, 'days');
      const lt110Res: any = lt110.diff(stDate, 'days');
      const res: any = lt18Res >= 0 && lt110Res < 0 ? true : false;
      if (res) {
        const el: any = document.getElementById('a11y_ssnonecc');
        el.focus();
      }
    }
  };
  const HandleDateChange = (InputTxt: any) => {
    // eslint-disable-next-line sonarjs/no-duplicate-string
    const format: any = moment(InputTxt).format('MM/DD/YYYY');
    const temp = InputTxt.replace(format);
    SetDob(temp);
    ssnFoc(InputTxt);
    // }
  };
  // OkHandleChange to open popup PC-CC-21
  const OkHandleChange = () => {
    setcheckSSNEnter(true);
    setenterssnisopen(false);
  };
  // Cancelclick for the Cancel button PC-CC-22
  const Cancelclick = () => {
    setpostnodeReason('');
    setreasonDEopen(false);
  };
  const buildAd2 = (ad: any) => {
    return ad !== null && ad !== undefined && ad !== '' && ad.length > 0
      ? ad + ', '
      : '';
  };
  const runDecisionEngine = async (custID: any) => {
    const payload: any = {
      firstName: FirstName,
      lastName: LastName,
      dateOfBirth: Dob,
      storeNumber: loginStore,
      taxId: Ssn1 + Ssn2 + Ssn3,
      languagePreference: 'ENG',
      customerId: custID.customerId,
      address: {
        addressType: 'PRIM',
        addressLine1: Addressline1,
        addressLine2: Addressline2,
        city: city,
        stateCode: stateinfo,
        zipCode: zip,
      },
      phone: {
        phoneNumber: Phonenumber.replace(/\D/g, ''),
        phoneType: 'CELL',
        primary: 'Y',
        callTimeType: 'MORN',
      },
    };
    await runDEaxiosCall(payload);
  };
  const runDEaxiosCall = async (payload: any) => {
    try {
      const response = await RunDE(payload);
      if (
        response !== undefined &&
        response.approvalResponse !== undefined &&
        (response.approvalResponse == '1' ||
          response.approvalResponse == '2' ||
          response.approvalResponse == '4')
      ) {
        setDEStatus(response.approvalResponse);
        setblurLoaderActive(false);
        setdedeclineisopen(true);
        console.log('Run DE REsp', response);
      }
      console.log('Run DE REsp1', response);
    } catch (e) {
      setblurLoaderActive(false);
      setDEStatus('');
      setdedeclineisopen(true);
      // console.log('payloaad', payload);
      // const runderesponse = await RunDE(payload);

      // console.log('runderesponse', runderesponse);
    }
  };
  const checkIfSSNEnter = async (AddressAry?: any) => {
    // if (Ssn1.length + Ssn2.length + Ssn3.length == 9) {
    debugger;
    setCanSuggestAddress(false)
    setblurLoaderActive(true);
    let result: any;
    try {
      result = await CreateCust(AddressAry);
      if (result !== undefined && result.customerId !== undefined) {
        history.push({
          pathname: `/customer/createcustomer/${result.customerId}/create`,
        });
        // props.history.push(
        //   `/customer/createcustomer/${custID.customerId}/create`
        // );
      }
    } catch (e: any) {
      seterrorCC(true);
      setblurLoaderActive(false);
    }
    // if (
    //   result !== undefined &&
    //   result !== null &&
    //   result !== '' &&
    //   result.customerId !== undefined &&
    //   result.customerId !== null &&
    //   result.customerId !== ''
    // ) {
    //   await runDecisionEngine(result);
    // }
    // } else {
    //   setblurLoaderActive(false);
    //   setliketoopen(true);
    // }
  };
  const continueclick = (type: any, mc?: any) => {
    debugger;
    const address : any={
      Addresses: {
        StreetAddress:'',
        City:'',
        PostalCode:'',
        StateProvince:''
      }
    }
    setCanSuggestAddress(false)
    setValidateIsOpen(false);
    setblurLoaderActive(true);
    if (type == 'SUG') {
      setAddressline1(SuggestedAddress.Addresses.StreetAddress),
        setAddressline2(
          Addressline2 +
          ' ' +
          SuggestedAddress.Addresses?.Building +
          ' ' +
          SuggestedAddress.Addresses?.Unit +
          ' ' +
          SuggestedAddress.Addresses?.POBox
        );
      setcity(SuggestedAddress.Addresses.City);
      setstateinfo(SuggestedAddress.Addresses.StateProvince);
      setzip(SuggestedAddress.Addresses.PostalCode);
    } else if (mc == 'MC1' || type == 'ENT') {
      setAddressline1(Addressline1.toUpperCase());
      setAddressline2(Addressline2.toUpperCase());
      setcity(city.toUpperCase());
      setstateinfo(stateinfo);
      setzip(zip);
      const postalAddress= validatedAddresses?.postalAddress;

      address.Addresses.PostalCode = postalAddress?.postalCode;
address.Addresses.StateProvince = postalAddress?.administrativeArea
address.Addresses.City = postalAddress?.locality
if(postalAddress.addressLines.length > 1){
  address.Addresses.addressLine2 = postalAddress?.addressLines[0]
  address.Addresses.StreetAddress = postalAddress?.addressLines[1]

}else{
  address.Addresses.StreetAddress = postalAddress?.addressLines.join(', ')

}
    }
    else if(type == 'GA'){
      /* 
      {
        "status": true,
        "suggestedAddress": "5501 Headquarters Dr, new york, VA 75024-5837, USA",
        "postalAddress": {
          "regionCode": "US",
          "languageCode": "en",
          "postalCode": "75024-5837",
          "administrativeArea": "VA",
          "locality": "new york",
          "addressLines": [
            "5501 Headquarters Dr"
          ]
        }
      }
       */
      const postalAddress= validatedAddresses?.postalAddress;
      if(postalAddress.addressLines.length > 1){
        setAddressline1(postalAddress.addressLines[1]);
        setAddressline2(postalAddress.addressLines[0]);
        address.Addresses.addressLine2 = postalAddress?.addressLines[0]
address.Addresses.StreetAddress = postalAddress?.addressLines[1]
      }
    else{
      address.Addresses.StreetAddress = postalAddress?.addressLines.join(', ')
      setAddressline1(postalAddress.addressLines[0]);
    }
      setcity(postalAddress.locality);
      setstateinfo(postalAddress.administrativeArea);
      setzip(postalAddress.postalCode);
      address.Addresses.PostalCode = postalAddress?.postalCode;
address.Addresses.StateProvince = postalAddress?.administrativeArea
address.Addresses.City = postalAddress?.locality

    }
    checkIfSSNEnter(type == 'SUG' ? SuggestedAddress :  type == 'GA' || type =='ENT'? address : undefined);
    //showSearchResults();
    //setdepopupisopen(true)
  };
  const formatTextChange = (value: any) => {
    return value !== undefined && value !== null && value.trim().length == 0
      ? value.trim()
      : value;
  };
  const ssn2Handler = (e: any) => {
    const ssn3El: any = document.getElementById('a11y_ssnthreecc');
    const ssn1El: any = document.getElementById('a11y_ssnonecc');
    const ssn2El: any = document.getElementById('a11y_ssntwocc');
    if (e.target.value.length === 2) {
      if (ssn2El == document.activeElement) {
        ssn3El.focus();
      }
    }
    if (e.target.value.length === 0 && Ssn1.length > 0) {
      if (ssn2El == document.activeElement) {
        ssn1El.focus();
      }
    }
  };
  const getAddress = async () => {
    interface AddressResponse {
      message?: string;
      status: boolean;
      matchCode?: string | undefined;
      suggestedAddress?: string ;
      postalAddress?: PostalAddress;
      
    }
    
    interface PostalAddress {
      addressLines: string[];
      administrativeArea: string;
      postalCode: string;
      locality: string;
    }
    const addressPayload : any = {
      MessageID: 'CMS006-93589345',
      MessageType: 'CMS006',
      MessageDTS: '2014-05-23-14:30:15:254633',
      MessageDTSTZOffset: '-06:00',
      RegionID: '1',
      ClientID: '8',
      ClientLocationNumber: '00006',
      ClientSource: '1',
      ClientOriginator: '1',
      LocationNumber: loginStore,
      EncryptionMethod: '0',
      Addresses: [
        {
          AddressID: '11114',
          StreetAddress: '',
          City: '',
          StateProvince: '',
          PostalCode: '',
          Country: '',
        },
      ],
      StoreConfig: {
        storeNumbers: [loginStore],
        paramKeyNames: ['AddressDoctorEnabled'],
      },
    };
    addressPayload.Addresses[0].City = city;
    addressPayload.Addresses[0].StreetAddress = Addressline1;
    addressPayload.Addresses[0].StateProvince = stateinfo;
    addressPayload.Addresses[0].PostalCode = zip.replaceAll('-', '');
    addressPayload.Addresses[0].Country = 'USA';
    try {
      if(useGoogleAddressValidation){
        setdefaultSuggestedAddress('GA')
        const googleAddressValidationPayload : any = JSON.parse(JSON.stringify(addressPayload))
        googleAddressValidationPayload.enableGoogleAddressValidation = true;
        const arr = [Addressline1,city,stateinfo,zip ]
        if(Addressline2){
         addressPayload.Addresses[0].StreetAddress+= ` ${Addressline2}`
        arr.unshift(Addressline2)}
        const validateAddressPayload ={
          postalCode: zip,
          addressLines: arr
        }
        googleAddressValidationPayload.validateAddress = validateAddressPayload
        const response: any = await Promise.all([GetSuggestedAddress(addressPayload),GetSuggestedAddress(googleAddressValidationPayload)]);
        setblurLoaderActive(false);

        console.log(response,"The response from the getSuggested Address");
        debugger;
        if(response[0] && response[1].validateAddress){
          const result: AddressResponse = validateAddressFn(response[1].validateAddress, response[0]?.validateAddress,
            (response[0] && response[0].storeConfig && response[0].storeConfig.storeProfileResponse.configDetails[0]
           .configDetails[0].paramValue == '1')? true :false,googleAddressValidationPayload)          
           if(result.status){
            if(result.matchCode){

              setmatchCode(result.matchCode);
              const errorMessage: string =
              result.matchCode == '1'
              ? `Address could not be corrected. Unlikely to match. Do you want to override?`
              : result.matchCode == '2'
                ? `Zip code found: ${addressPayload.Addresses[0].PostalCode}. Do you Want to overrride?`
                : '';
          setAddressErrorMsg(errorMessage);
          setSuggestionAddresPopup(true);
          setSuggestedAddress(undefined);
          const postalAddress= {
            addressLines:[Addressline1],
            locality:result?.postalAddress?.locality,
            postalCode :result?.postalAddress?.postalCode,
            administrativeArea:result.postalAddress?.administrativeArea
          }
          if(Addressline2)
            postalAddress.addressLines.unshift(Addressline2)
          setValidatedAddress({postalAddress:postalAddress}) 
          setdefaultSuggestedAddress('GA');
            
            }else{
           setCanSuggestAddress(true)
          setValidatedAddress(result)
            }
        }
        else{
          setCantSuggestAddress(true)
          setValidatedAddress(result)
        }
        }
        else{
          throw new Error('Something went wrong')
        }
    }else{
      const response: any = await GetSuggestedAddress(addressPayload);
      console.log('AddressDoctor', response);
      if (
        response !== undefined &&
        response !== null &&
        response.storeConfig !== undefined &&
        (response.storeConfig.storeProfileResponse.configDetails[0]
          .configDetails[0].paramValue == 'Y' ||
          response.storeConfig.storeProfileResponse.configDetails[0]
            .configDetails[0].paramValue == '1' ||
          response.storeConfig.storeProfileResponse.configDetails[0]
            .configDetails[0].paramValue == 1)
      ) {
        setblurLoaderActive(false);
        //setSuggestedAddress(response.validateAddress);
        if (
          response.validateAddress !== undefined &&
          response.validateAddress.Addresses !== undefined &&
          (response.validateAddress.Addresses.MatchCode == '1' ||
            response.validateAddress.Addresses.MatchCode == '2')
        ) {
          setmatchCode(response.validateAddress.Addresses.MatchCode);
          const errorMessage: string =
            response.validateAddress.Addresses.MatchCode == '1'
              ? `Address could not be corrected. Unlikely to match. Do you want to override?`
              : response.validateAddress.Addresses.MatchCode == '2'
                ? `Zip code found: ${addressPayload.Addresses[0].PostalCode}. Do you Want to overrride?`
                : '';
          setAddressErrorMsg(errorMessage);
          setSuggestionAddresPopup(true);
          setSuggestedAddress(undefined);
        } else {
          setSuggestedAddress(response.validateAddress);
          setSuggestionAddresPopup(false);
          setValidateIsOpen(true);
        }
      } else if (
        response !== undefined &&
        response !== null &&
        response.storeConfig !== undefined &&
        (response.storeConfig.storeProfileResponse.configDetails[0]
          .configDetails[0].paramValue == 'N' ||
          response.storeConfig.storeProfileResponse.configDetails[0]
            .configDetails[0].paramValue == '0' ||
          response.storeConfig.storeProfileResponse.configDetails[0]
            .configDetails[0].paramValue == 0)
      ) {
        setblurLoaderActive(false);
        setAddressErrorMsg(
          'Automatic Address validation is Unavailable for the store # Please validate the address Manually.'
        );
        setSuggestionAddresPopup(true);
        setSuggestedAddress(undefined);
        //setnotAvailableAD(true);
      } else {
        setAddressErrorMsg('Address validation currently Unavailable');
        setSuggestionAddresPopup(true);
        setSuggestedAddress(undefined);
        setblurLoaderActive(false);
      }
    }
      //setnotAvailableAD
    } catch (e: any) {
      setAddressErrorMsg('Address validation currently Unavailable');
      setSuggestionAddresPopup(true);
      setSuggestedAddress(undefined);
      setblurLoaderActive(false);
      //setnotAvailableAD(true);
      console.log('Error In Address Doctor', e);
    }
  };
  const ssn1Handler = (e: any) => {
    const ssn2El: any = document.getElementById('a11y_ssntwocc');
    const ssn1El: any = document.getElementById('a11y_ssnonecc');
    if (e.target.value.length === 3) {
      console.log('ssn1El', ssn1El);
      console.log('document.activeElement', document.activeElement);
      if (ssn1El == document.activeElement) {
        ssn2El.focus();
      }
    }
  };
  const ssn3Handler = (e: any) => {
    const ssn3El: any = document.getElementById('a11y_ssnthreecc');
    const ssn2El: any = document.getElementById('a11y_ssntwocc');
    if (e.target.value.length === 0 && Ssn2.length > 0) {
      if (ssn3El == document.activeElement) {
        ssn2El.focus();
      }
    }
  };
  const capitalize = (input: any) => {
    const words: any = input.split(' ');
    const CapitalizedWords: any = [];
    words.forEach((element: any) => {
      CapitalizedWords.push(
        element[0].toUpperCase() + element.slice(1, element.length)
      );
    });
    return CapitalizedWords.join(' ');
  };
  const HandleChange = (e: any) => {
    if (e.target.name === 'FirstName') {
      if (
        e.target.value &&
        e.target.value.length > 0 &&
        e.target.value.charAt(e.target.value.length - 1) !== ' '
      ) {
        const char: any = capitalize(e.target.value);

        setFirstName(formatTextChange(char.replace(/[^A-Z-'.,’ a-z]/gi, '')));
      } else {
        setFirstName(
          formatTextChange(e.target.value.replace(/[^A-Z-'.,’ a-z]/gi, ''))
        );
      }
    }
    if (e.target.name === 'LastName') {
      if (
        e.target.value &&
        e.target.value.length > 0 &&
        e.target.value.charAt(e.target.value.length - 1) !== ' '
      ) {
        const char: any = capitalize(e.target.value);
        setLastName(formatTextChange(char.replace(/[^A-Z-'.,’ a-z]/gi, '')));
      } else {
        setLastName(
          formatTextChange(e.target.value.replace(/[^A-Z-'.,’ a-z]/gi, ''))
        );
      }
    }
    if (e.target.name === 'Dob') {
      const InputTxt = e.target.value;
      const format: any = moment(InputTxt).format('MM/DD/YYYY');
      const temp = InputTxt.replace(format);
      SetDob(temp);
    }
    if (e.target.name === 'Ssn1') {
      setssn1(e.target.value.replace(/\D/g, ''));
      ssn1Handler(e);
    }
    if (e.target.name === 'Ssn2') {
      setssn2(e.target.value.replace(/\D/g, ''));
      ssn2Handler(e);
    }
    if (e.target.name === 'Ssn3') {
      setssn3(e.target.value.replace(/\D/g, ''));
      ssn3Handler(e);
    }
    if (e.target.id === 'a11y_phonenumber') {
      //alert(e.target.value);
      const cleaned = ('' + e.target.value).replace(/\D/g, '');
      //This is raw data to pass on props
      setPhonenumberforrcustValidatoion(e.target.value.replace(/\D/g, ''));
      const PhoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
      const temp = cleaned.replace(PhoneRegex, '($1) $2-$3');
      //alert(temp);
      setPhonenumber(temp);
    }

    if (e.target.name === 'emailaddress') {
      setemailaddress(formatTextChange(e.target.value));
    }
    if (e.target.name === 'Addressline1') {
      setAddressline1(formatTextChange(e.target.value));
    }
    if (e.target.name === 'Addressline2') {
      setAddressline2(formatTextChange(e.target.value));
    }
    if (e.target.name === 'zip') {
      let cleaned = ('' + e.target.value).replace(/\D/g, '');
      cleaned =
        cleaned.length > 5
          ? cleaned.slice(0, 5) + '-' + cleaned.slice(5)
          : cleaned;
      setzip(cleaned);
    }
    if (e.target.name === 'city') {
      setcity(formatTextChange(e.target.value.replace(/[^a-zA-Z ]/g, '')));
    }
    if (e.target.name === 'stateinfo') {
      setstateinfo(e.target.value);
    }
  };
  const validateSSN = () => {
    let ssnValidation = true;
    if (
      Ssn1 !== undefined &&
      Ssn1 !== null &&
      Ssn2 !== undefined &&
      Ssn2 !== null &&
      Ssn3 !== undefined &&
      Ssn3 !== null
    ) {
      if (
        Ssn1.length + Ssn2.length + Ssn3.length !== 0 &&
        Ssn1.length + Ssn2.length + Ssn3.length < 9
      ) {
        ssnValidation = false;
      }
    } else if (Ssn1 !== undefined && Ssn1 !== null && Ssn1.length > 0) {
      ssnValidation = false;
    } else if (Ssn2 !== undefined && Ssn2 !== null && Ssn2.length > 0) {
      ssnValidation = false;
    } else if (Ssn3 !== undefined && Ssn3 !== null && Ssn3.length > 0) {
      ssnValidation = false;
    }
    return ssnValidation;
  };
  const clear = () => {
    setclearbtnHidden(true);
    setFirstName('');
    setLastName('');
    SetDob('');
    setssn1('');
    setssn2('');
    setssn3('');
    setPhonenumber('');
    setemailaddress('');
    setAddressline1('');
    setAddressline2('');
    setzip('');
    setcity('');
    setstateinfo('');
  };
  function ValidateEmail(inputText: any) {
    // eslint-disable-next-line no-useless-escape
    const mailformat =
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i;
    if (inputText.match(mailformat)) {
      //document.getElementsByName("Addressline1")[0].focus();
      setemailerrMsg(false);
      return true;
    } else {
      setemailerrMsg(true);
      return false;
    }
  }
  const ValidateCustomerClcik = async () => {
    if (
      emailaddress.length > 0 ||
      Ssn1.length + Ssn2.length + Ssn3.length > 0
    ) {
      const res = emailaddress.length > 0 ? ValidateEmail(emailaddress) : true;
      const validateSSNObj = validateSSN();

      if (res && validateSSNObj) {
        setclearbtnHidden(true);
        setSsnValid(false);
        setblurLoaderActive(true);
        await getAddress();
        setenableValidatebtn(true);
      }
      validateSSNObj === false ? setSsnValid(true) : setSsnValid(false);
    } else {
      setclearbtnHidden(true);
      setSsnValid(true);
      await getAddress();
      // getAddress();
      setenableValidatebtn(true);
    }

    //SetButtonactive(false)
  };
  /*popup start here */
  /*popup for AD*/
  const addressDoctorPopup = () => {
    return (
      <>
        <Grid item md={12} className={classes.spacerPX2}>
          <Typography className={`${classes.RACPOPMsg} ${classes.semiBold}`}>
            Entered address is not located
          </Typography>
          <Grid
            item
            md={12}
            className={`${classes.spacerMB2} ${classes.spacerMT2}`}
          >
            <Typography className={`${classes.RACPOPMsg} ${classes.semiBold}`}>
              Suggested Addresses
            </Typography>
            <div className={classes.formCheck}>
              <RACRadio
                id="inlineRadio1"
                name="inlineRadioOptions"
                checked={defaultSuggestedAddress == 'SUG' ? true : false}
                onClick={() => RadioBtnclick('SUG')}
                value={'SUG'}
              />
              <Typography
                className={`${classes.RACPOPMsg} ${classes.semiBold} ${classes.dispInlineFlex}`}
              >
                {SuggestedAddress !== undefined &&
                  SuggestedAddress !== null &&
                  SuggestedAddress.Addresses !== null &&
                  SuggestedAddress.Addresses.StreetAddress !== undefined
                  ? `${SuggestedAddress.Addresses.StreetAddress},`
                  : ''}

                {SuggestedAddress !== undefined &&
                  SuggestedAddress !== null &&
                  SuggestedAddress.Addresses !== null &&
                  SuggestedAddress.Addresses.City !== undefined
                  ? `${SuggestedAddress.Addresses.City},`
                  : ''}
                {SuggestedAddress !== undefined &&
                  SuggestedAddress !== null &&
                  SuggestedAddress.Addresses !== null &&
                  SuggestedAddress.Addresses.StateProvince !== undefined
                  ? `${SuggestedAddress.Addresses.StateProvince},`
                  : ''}
                {SuggestedAddress !== undefined &&
                  SuggestedAddress !== null &&
                  SuggestedAddress.Addresses !== null &&
                  SuggestedAddress.Addresses.PostalCode !== undefined
                  ? `${SuggestedAddress.Addresses.PostalCode}`
                  : ''}
              </Typography>
            </div>
          </Grid>
          <Grid item md={12} className={classes.spacerMB3} />
          <Typography className={`${classes.RACPOPMsg} ${classes.semiBold}`}>
            Entered Address
          </Typography>
          <div className={classes.formCheck}>
            <RACRadio
              id="inlineRadio2"
              name="inlineRadioOptions"
              checked={defaultSuggestedAddress == 'ENT' ? true : false}
              onClick={() => RadioBtnclick('ENT')}
              value={'ENT'}
            />
            <Typography
              className={`${classes.RACPOPMsg} ${classes.semiBold} ${classes.dispInlineFlex}`}
            >
              {Addressline1}, {buildAd2(Addressline2)} {city}, {stateinfo},{' '}
              {zip}
            </Typography>
          </div>
        </Grid>
        <Grid
          className={`${classes.spacerMT4} ${classes.w100} ${classes.floatLeft}`}
        >
          <Grid className={classes.floatLeft}>
            <RACButton
              variant="outlined"
              color="primary"
              className={classes.foc}
              onClick={() => setValidateIsOpen(false)}
            >
              Cancel
            </RACButton>
          </Grid>
          <Grid className={`${classes.floatRight}`}>
            <RACButton
              variant="contained"
              color="primary"
              className={classes.spacerME2}
              onClick={() => continueclick('ENT')}
              disabled={BtnIsdisabled1}
            >
              Override
            </RACButton>
            <RACButton
              variant="contained"
              color="primary"
              disabled={BtnIsdisabled2}
              onClick={() => continueclick('SUG')}
            >
              continue
            </RACButton>
          </Grid>
        </Grid>
      </>
    );
  };
  // validation message popup for suggested and entered address PC-CC-23
  function ValidationMessagePopup() {
    return (
      <RACModalCard
        isOpen={ValidateIsOpen}
        maxWidth="sm"
        closeIcon={true}
        borderRadius="20px"
        onClose={() => setValidateIsOpen(false)}
        // eslint-disable-next-line react/no-children-prop
        children={addressDoctorPopup()}
        title="Validation Message"
      />
    );
  }
  /*popup for run de */
  function Liketo() {
    return (
      <RACModalCard
        isOpen={liketoopen}
        maxWidth="xs"
        closeIcon={true}
        borderRadius="20px"
        onClose={() => setliketoopen(false)}
        // eslint-disable-next-line react/no-children-prop
        children={runDEResponsePopup()}
      />
    );
  }
  const postNoDESubmitClick = async () => {
    setreasonDEopen(false);
    setblurLoaderActive(true);
    console.log('custID', custID);
    // eslint-disable-next-line
    debugger
    if (
      custID !== undefined &&
      custID.globalCustomerId !== undefined &&
      custID.customerId !== undefined
    ) {
      const res: any = {
        globalCustomerId: custID.globalCustomerId,
        customerId: custID.customerId,
        deExceptionReason: postnodeReason,
      };
      console.log('res', res);

      const payload: any = {
        storeException: [
          {
            identifierId: Number(custID.customerId),
            identifierType: 'CUSTOMER',
            storeNumber: window.sessionStorage.getItem('storeNumber'),
            exceptionTypeRefCode: "DEB",
            exceptionText: storeExceptionReason
          }
        ]
      }
      const response = await UpdateNoDeReason(res);
      storeException(payload)

      console.log(response);
      setdedeclineisopen(false);
      setblurLoaderActive(false);
      if (custID.customerId !== undefined) {
        history.push({
          pathname: `/customer/createcustomer/${custID.customerId}/create`,
        });
        // props.history.push(
        //   `/customer/createcustomer/${custID.customerId}/create`
        // );
      }
      // if (custID.customerId !== undefined) {
      //   props.history.push(`/customer/update/${custID.customerId}/customer`);
      // props.history.push(
      //   `/customer/createcustomer/${custID.customerId}/create`
      // );
    } else {
      const resp: any = await CreateCust();
      if (
        resp !== undefined &&
        resp !== null &&
        resp.globalCustomerId !== undefined &&
        resp.customerId !== undefined
      ) {
        const res: any = {
          globalCustomerId: resp.globalCustomerId,
          customerId: resp.customerId,
          deExceptionReason: postnodeReason,
        };
        const payload: any = {
          storeException: [
            {
              identifierId: Number(resp.customerId),
              identifierType: 'CUSTOMER',
              storeNumber: window.sessionStorage.getItem('storeNumber'),
              exceptionTypeRefCode: "DEB",
              exceptionText: storeExceptionReason
            }
          ]
        }
        console.log('res', res);
        const response = await UpdateNoDeReason(res);
        storeException(payload)
        console.log(response);
      }
      setdedeclineisopen(false);
      setblurLoaderActive(false);
      if (
        resp !== undefined &&
        resp !== null &&
        resp?.customerId !== undefined
      ) {
        history.push({
          pathname: `/customer/createcustomer/${resp.customerId}/create`,
        });
      }
    }
  };
  const runDEResponsePopup = () => {
    return (
      <Grid item md={12}>
        <Grid item md={12} className={classes.textCenter}>
          <Typography className={classes.RACPOPMsg}>
            Would you like to run the decision engine?
          </Typography>
        </Grid>
        <Grid
          container
          className={`${classes.justifyContentCenter} ${classes.spacerMT4}`}
        >
          <RACButton
            className={`${classes.spacerMR2} ${classes.foc}`}
            size="small"
            variant="outlined"
            color="primary"
            onClick={() => NOHandleChange()}
          >
            No
          </RACButton>
          <RACButton
            variant="contained"
            color="primary"
            isRounded={false}
            onClick={() => YesHandleChange()}
          >
            Yes
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  const enterSSNPopup = () => {
    return (
      <Grid item md={12}>
        <Grid item md={12} className={classes.textCenter}>
          <Typography className={classes.RACPOPMsg}>
            Enter the SSN# to run the decision engine.
          </Typography>
        </Grid>
        <Grid
          container
          className={`${classes.justifyContentCenter} ${classes.spacerMT4}`}
        >
          <RACButton
            variant="contained"
            color="primary"
            isRounded={false}
            style={{
              textTransform: 'none',
              // eslint-disable-next-line sonarjs/no-duplicate-string
              width: '100px !important',
              height: '35px',
              marginTop: '29px',
            }}
            onClick={() => OkHandleChange()}
          >
            Ok
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  const erroronCCPopup = () => {
    return (
      <Grid item md={12}>
        <Grid item md={12} className={classes.textCenter}>
          <Typography className={`${classes.RACPOPMsg} ${classes.semiBold}`}>
            Error on create customer service, Please try again.
          </Typography>
        </Grid>
        <Grid
          container
          className={`${classes.justifyContentCenter} ${classes.spacerMT4}`}
        >
          <RACButton
            variant="contained"
            color="primary"
            isRounded={false}
            style={{
              textTransform: 'none',
              // eslint-disable-next-line sonarjs/no-duplicate-string
              width: '100px !important',
              height: '35px',
              marginTop: '29px',
            }}
            onClick={() => seterrorCC(false)}
          >
            Ok
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  // popup to enter SSn PC-CC-27
  function Enterssn() {
    return (
      <RACModalCard
        isOpen={enterssnisopen}
        maxWidth="xs"
        closeIcon={true}
        borderRadius="20px"
        onClose={() => setenterssnisopen(false)}
        // eslint-disable-next-line react/no-children-prop
        children={enterSSNPopup()}
      />
    );
  }
  const dedeclineMsgPopup = () => {
    return (
      <>
        <Grid item md={12}>
          <Grid item md={12} className={classes.textCenter}>
            {DEStatus == '' ? (
              <Typography variant={'body2'} className={classes.RACPOPMsg}>
                Error in approval process. Please proceed without approval
              </Typography>
            ) : (
              <Typography className={classes.RACPOPMsg}>
                {`Decision engine has ${DEStatus == '1'
                  ? 'approved'
                  : DEStatus == '2'
                    ? 'declined'
                    : DEStatus == '4'
                      ? 'conditionally approved'
                      : ''
                  } the customer`}
              </Typography>
            )}
          </Grid>
          <Grid
            container
            className={`${classes.justifyContentCenter} ${classes.spacerMT4}`}
          >
            <RACButton
              color="primary"
              variant="contained"
              onClick={() => DeOkclick()}
            >
              Ok
            </RACButton>
          </Grid>
        </Grid>
      </>
    );
  };
  function CreatecustomererrorPopup() {
    return (
      <RACModalCard
        isOpen={errorCC}
        maxWidth="xs"
        closeIcon={true}
        borderRadius="20px"
        onClose={() => seterrorCC(false)}
        // eslint-disable-next-line react/no-children-prop
        children={erroronCCPopup()}
      />
    );
  }
  // DE decline popup PC-CC-30
  function Dedecline() {
    return (
      <RACModalCard
        isOpen={dedeclineisopen}
        maxWidth="xs"
        closeIcon={true}
        borderRadius="20px"
        onClose={() => setdedeclineisopen(false)}
        // eslint-disable-next-line react/no-children-prop
        children={dedeclineMsgPopup()}
      />
    );
  }
  const noReasonDEPopup = () => {
    return (
      <Grid item md={12}>
        <Grid item md={12} className={classes.textCenter}>
          <Typography className={classes.RACPOPMsg}>
            Please specify the reason, why you are not running the decision
            engine?
          </Typography>
        </Grid>
        <Grid
          container
          className={`${classes.justifyContentCenter} ${classes.spacerMT4}`}
        >
          <RACSelect
            inputLabel="Reason"
            options={noDEReasonDD}
            name="postNODEReason"
            defaultValue={
              postnodeReason !== undefined &&
                postnodeReason !== null &&
                postnodeReason !== ''
                ? postnodeReason
                : '0'
            }
            loading={noDEReasonDD.length == 0 && !dropDownLoaded ? true : false}
            onChange={(e: any) => {
              e.target.name = `postNODEReason`;
              setpostnodeReason(e.target.value);
              setStoreExceptionReason(e?.currentTarget?.innerText)

            }}
            {...(noDEReasonDD.length == 0 &&
              dropDownLoaded && {
              errorMessage: API_ERROR_MESSAGE,
            })}
          />
        </Grid>
        <Grid
          container
          className={`${classes.justifyContentCenter} ${classes.spacerMT4}`}
        >
          <RACButton
            className={`${classes.spacerMR2} ${classes.foc}`}
            size="small"
            variant="outlined"
            color="primary"
            isRounded={false}
            onClick={() => Cancelclick()}
          >
            Cancel
          </RACButton>
          <RACButton
            size="small"
            variant="contained"
            color="primary"
            isRounded={false}
            onClick={() => postNoDESubmitClick()}
            disabled={postnodeReason == '0' ? true : false}
          >
            Submit
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  function NoDEReason() {
    return (
      <RACModalCard
        isOpen={reasonDEopen}
        maxWidth="xs"
        closeIcon={true}
        borderRadius="20px"
        onClose={() => Cancelclick()}
        // eslint-disable-next-line react/no-children-prop
        children={noReasonDEPopup()}
      />
    );
  }
  const addressDoctorErrorPopup = () => {
    return (
      <Grid item md={12} className={classes.spacerPX2}>
        <Grid item md={12} className={classes.customerTextcenter}>
          <Typography className={classes.customerRacpopup}>
            {addressErrorMsg}
          </Typography>
        </Grid>
        <Grid
          container
          classes={{
            root: clsx(classes.customerJustifycontentcenter, classes.spacerMT4),
          }}
        >
          {matchCode == '1' || matchCode == '2' ? (
            <Grid item md={12} className={classes.customerTextcenter}>
              <RACButton
                className={`${classes.spacerMR2}`}
                variant="outlined"
                color="primary"
                isRounded={false}
                onClick={() => {
                  setSuggestionAddresPopup(false);
                  continueclick('ENT');
                }}
              >
                No
              </RACButton>
              <RACButton
                variant="contained"
                color="primary"
                isRounded={false}
                onClick={() => {
                  setSuggestionAddresPopup(false);
                  continueclick('ENT', 'MC1');
                }}
              >
                Yes
              </RACButton>
            </Grid>
          ) : (
            <Grid item md={12} className={classes.customerTextcenter}>
              <RACButton
                variant="contained"
                color="primary"
                isRounded={false}
                onClick={() => {
                  setSuggestionAddresPopup(false);
                  continueclick('ENT');
                }}
              >
                Ok
              </RACButton>
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  };
  function AddressDoctorErrorMsg() {
    return (
      <RACModalCard
        isOpen={suggestionAddresPopup}
        maxWidth="xs"
        closeIcon={true}
        borderRadius="20px"
        onClose={() => setSuggestionAddresPopup(false)}
        // eslint-disable-next-line react/no-children-prop
        children={addressDoctorErrorPopup()}
        title="Validate Address"
      />
    );
  }
  const fieldValidationOnblur = (field: any, e?: any) => {
    if (field == 'ZIP') {
      zip.length !== 5 && zip.length !== 10
        ? setfieldValidation({ ...fieldValidationforblur, zipCode: true })
        : setfieldValidation({ ...fieldValidationforblur, zipCode: false });
    } else if (field == 'PHONE') {
      Phonenumber.length < 10
        ? setfieldValidation({
          ...fieldValidationforblur,
          mobilePhoneNumber: true,
        })
        : setfieldValidation({
          ...fieldValidationforblur,
          mobilePhoneNumber: false,
        });
    } else if (field == 'DOB') {
      const stDate = moment(e.target.value, 'YYYY-MM-DD');
      const lt18 = moment(moment().subtract(18, 'years'), 'YYYY-MM-DD');
      const lt110 = moment(moment().subtract(110, 'years'), 'YYYY-MM-DD');
      const lt18Res: any = lt18.diff(stDate, 'days');
      const lt110Res: any = lt110.diff(stDate, 'days');
      const res: any = lt18Res >= 0 && lt110Res < 0 ? true : false;
      setfieldValidation({
        ...fieldValidationforblur,
        dateOfBirth: !res,
      });
    }
  };
  /*popup ends here */

  /* Google address validation popup starts here
  */
  function suggestAddressFn(){
    return (
        <>
          <Grid item md={12} className={classes.spacerPX2}>
          <Typography className={`${classes.RACPOPMsg} ${classes.bold}`}>
            Entered Address
          </Typography>
          <div className={classes.formCheck}>
          <RACRadio
                id="inlineRadio1"
                name="inlineRadioOptions"
                checked={defaultSuggestedAddress == 'ENT' ? true : false}
                onClick={() => RadioBtnclick('ENT')}
                value={'ENT'}
              />
          <Typography
              className={`${classes.RACPOPMsg} ${classes.semiBold} ${classes.dispInlineFlex}`}
            >
              {Addressline1}, {buildAd2(Addressline2)}{' '}
              {city}, {stateinfo}, {zip}
            </Typography>
            </div>
          <Grid
            item
            md={12}
            className={`${classes.spacerMB2} ${classes.spacerMT2}`}
          >
            <Typography className={`${classes.RACPOPMsg} ${classes.bold}`}>
              Suggested Addresses
            </Typography>
            <div className={classes.formCheck}>
              <RACRadio
                id="inlineRadio1"
                name="inlineRadioOptions"
                checked={defaultSuggestedAddress == 'GA' ? true : false}
                onClick={() => RadioBtnclick('GA')}
                value={'GA'}
              />
              <Typography
                className={`${classes.RACPOPMsg} ${classes.semiBold} ${classes.dispInlineFlex}`}
              >
              {validatedAddresses.suggestedAddress}
              </Typography>
            </div>
          </Grid>
        </Grid>
        <Grid
          className={`${classes.spacerMT4} ${classes.w100} ${classes.floatLeft}`}
        >
          <Grid className={classes.floatLeft}>
            <RACButton
              variant="outlined"
              color="primary"
              className={classes.foc}
              onClick={() => setCanSuggestAddress(false)}
            >
              Cancel
            </RACButton>
          </Grid>
          <Grid className={`${classes.floatRight}`}>
            <RACButton
              variant="contained"
              color="primary"
              // disabled={BtnIsdisabled2}
              onClick={() => continueclick(defaultSuggestedAddress)} 
            >
              Continue
            </RACButton>
          </Grid>
        </Grid>
        </>
    )
}
function suggestErrorInAddress(){
return (
  <>
      <Grid item md={12}>
      <Grid
            style={{
              justifyContent: "center",
              display: "flex",
              marginBottom:'2%'

            }}
          >
            <WarningIcon width={"10%"} height={"10%"} />
          </Grid>
        <Grid item md={12} className={classes.textCenter}>
          <Typography variant={'body2'} className={classes.RACPOPMsg}>
            {validatedAddresses?.message}
          </Typography>
        </Grid>
        <Grid
          container
          className={`${classes.justifyContentCenter} ${classes.spacerMT4}`}
        >
          <RACButton
            variant="contained"
            color="primary"
            isRounded={false}
            onClick={() => setCantSuggestAddress(false)}
          >
            Ok
          </RACButton>
        </Grid>
      </Grid>
  </>
)
}
/* Google address validation popup ends here */
  return (
    <>
      <div>
        <Grid container id="step-1">
          {blurLoaderActive ? (
            <Grid className={classes.masterLoader}>
              <Grid className={classes.Loader}>
                <CircularProgress />
              </Grid>
            </Grid>
          ) : null}
          <ValidationMessagePopup />
          <Liketo />
          <CreatecustomererrorPopup />
          <Enterssn />
          <Dedecline />
          <NoDEReason />
          <AddressDoctorErrorMsg />
          <Grid container className={classes.spacer}>
            <Card className={classes.card}>
              <CardContent>
                <Grid container className={classes.spacerMB3}>
                  <Grid item md={12}>
                    <Typography variant="h3" className={classes.widgetTitle}>
                      Basic Information
                    </Typography>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item md={3} lg={2}>
                      <RACTextbox
                        type="text"
                        name="FirstName"
                        value={FirstName}
                        disabled={
                          FirstName !== undefined &&
                            FirstName !== null &&
                            FirstName !== ''
                            ? true
                            : false
                        }
                        required={true}
                        maxlength={30}
                        inputlabel="First Name"
                        OnClick={() => {
                          setenableValidatebtn(true);
                          setclearbtnHidden(false);
                        }}
                        OnChange={(e: any) => HandleChange(e)}
                        isCurrency={false}
                      />
                    </Grid>
                    <Grid item md={3} lg={2}>
                      <RACTextbox
                        type="text"
                        name="LastName"
                        value={LastName}
                        disabled={
                          LastName !== undefined &&
                            LastName !== null &&
                            LastName !== ''
                            ? true
                            : false
                        }
                        maxlength={30}
                        required={true}
                        inputlabel="Last Name"
                        OnClick={() => {
                          setenableValidatebtn(true);
                          setclearbtnHidden(false);
                        }}
                        OnChange={(e: any) => HandleChange(e)}
                        isCurrency={false}
                      />
                    </Grid>
                    <Grid item md={3} lg={2} className={classes.datePicker}>
                      {!dobdisable ?
                        <><Box
                          component="div"
                          className={`${classes.formLabel} ${classes.datePickerMB}`}
                        >
                          Date of Birth
                          <Box
                            component="span"
                            className={`${classes.mandatoryfield} ${classes.spacerMS1}`}
                          >
                            *
                          </Box>
                        </Box>
                          <RACDatePicker
                            id="a11y_dob"
                            label=""
                            required={true}
                            value={Dob}
                            name={'Dob'}
                            disabled={dobdisable}
                            onChange={(e: any) => HandleDateChange(e)}
                            inputProps={{
                              min: moment()
                                .subtract(110, 'years')
                                .format('YYYY-MM-DD'),
                              max: moment()
                                .subtract(18, 'years')
                                .format('YYYY-MM-DD'),
                            }}
                            classes={{
                              input:
                                fieldValidationforblur.dateOfBirth === true
                                  ? `${classes.ssnBorderRed}`
                                  : '',
                            }}
                            onBlur={(e: any) => fieldValidationOnblur('DOB', e)}
                          /></> : <RACTextbox
                          type="text"
                          name="Date of Birth"
                          value={Dob?.substring(0, 6) + '****'}
                          disabled={
                            Dob !== undefined &&
                              Dob !== null &&
                              Dob !== ''
                              ? true
                              : false
                          }
                          maxlength={30}
                          required={true}
                          inputlabel="Date of Birth"
                          isCurrency={false}
                        />}

                      {/* <RACDatePicker
                        id="a11y_dob"
                        value={Dob}
                        required={true}
                        label="Date of Birth"
                        onChange={(e: any) => HandleDateChange(e)}
                        inputProps={{
                          min: moment()
                            .subtract(110, 'years')
                            .format('YYYY-MM-DD'),
                          max: moment()
                            .subtract(18, 'years')
                            .format('YYYY-MM-DD'),
                        }}
                        onKeyDown={(e: any) => {
                          e.preventDefault();
                        }}
                      /> */}
                    </Grid>
                    <Grid item md={3} lg={2}>
                      <Box component="label" className={classes.formLabel}>
                        Social Security# / ITIN#
                      </Box>
                      <Grid
                        container
                        spacing={2}
                        className={classes.ssnpadding}
                      >
                        <Grid
                          item
                          md={4}
                          className={`${classesManageCustomerStyles.ssnPaddingTop}`}
                        >
                          <RACTextbox
                            type="password"
                            name="Ssn1"
                            value={Ssn1}
                            id="a11y_ssnonecc"
                            disabled={ssnDisable}
                            placeHolder={'xxx'}
                            maxlength={3}
                            className={
                              checkSSNEnter && Ssn1.length == 0
                                ? `${classes.ssnBorderRed} ${classes.textCenterSSN}`
                                : classes.textCenterSSN
                            }
                            OnClick={() => {
                              setenableValidatebtn(true);
                              setclearbtnHidden(false);
                            }}
                            OnChange={(e: any) => HandleChange(e)}
                            isCurrency={false}
                          />
                        </Grid>
                        <Grid
                          item
                          md={3}
                          className={`${classesManageCustomerStyles.ssnPaddingTop}`}
                        >
                          <RACTextbox
                            type="password"
                            name="Ssn2"
                            value={Ssn2}
                            placeHolder={'xx'}
                            disabled={ssnDisable}
                            formControlClassName={classes.ssnTextField}
                            id="a11y_ssntwocc"
                            maxlength={2}
                            OnClick={() => {
                              setenableValidatebtn(true);
                              setclearbtnHidden(false);
                            }}
                            className={
                              checkSSNEnter && Ssn2.length == 0
                                ? `${classes.ssnBorderRed} ${classes.textCenterSSN}`
                                : classes.textCenterSSN
                            }
                            OnChange={(e: any) => HandleChange(e)}
                            isCurrency={false}
                          />
                        </Grid>
                        <Grid
                          item
                          md={5}
                          className={`${classesManageCustomerStyles.ssnPaddingTop}`}
                        >
                          <RACTextbox
                            type="text"
                            name="Ssn3"
                            value={Ssn3}
                            disabled={ssnDisable}
                            id="a11y_ssnthreecc"
                            placeHolder={'xxxx'}
                            maxlength={4}
                            className={
                              checkSSNEnter && Ssn3.length == 0
                                ? `${classes.ssnBorderRed} ${classes.textCenterSSN}`
                                : classes.textCenterSSN
                            }
                            OnClick={() => {
                              setenableValidatebtn(true);
                              setclearbtnHidden(false);
                            }}
                            OnChange={(e: any) => HandleChange(e)}
                            isCurrency={false}
                          />
                        </Grid>
                      </Grid>
                      {validateSSN() === false && ssnValid === true ? (
                        <Box className={classes.mandatoryfield}>
                          Please enter valid SSN
                        </Box>
                      ) : null}
                    </Grid>
                    <Grid item md={3} lg={2}>
                      <RACTextbox
                        type="phoneno"
                        inputlabel="Phone Number"
                        maxlength={10}
                        id="a11y_phonenumber"
                        name="Phonenumber"
                        Onblur={() => fieldValidationOnblur('PHONE')}
                        OnClick={() => {
                          setenableValidatebtn(true);
                          setclearbtnHidden(false);
                        }}
                        className={
                          fieldValidationforblur.mobilePhoneNumber === true &&
                            Phonenumber.length < 10
                            ? `${classes.ssnBorderRed}`
                            : ''
                        }
                        value={Phonenumber}
                        OnChange={(e: any) => HandleChange(e)}
                        required={true}
                        isCurrency={false}
                      />
                    </Grid>
                    <Grid item md={3} lg={2}>
                      <RACTextbox
                        type="text"
                        inputlabel="Email Address"
                        maxlength={50}
                        id="a11y_emailaddress"
                        name="emailaddress"
                        OnClick={() => {
                          setenableValidatebtn(true);
                          setclearbtnHidden(false);
                        }}
                        value={emailaddress}
                        OnChange={(e: any) => HandleChange(e)}
                        isCurrency={false}
                      />
                      {emailerrMsg ? (
                        <label className={classes.mandatoryfield}>
                          Please enter valid email address
                        </label>
                      ) : null}
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    className={`${classes.spacerMB3} ${classes.spacerMT3}`}
                  >
                    <Grid item md={12}>
                      <Typography variant="h3" className={classes.widgetTitle}>
                        Address Information
                      </Typography>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item md={3} lg={2}>
                        <RACTextbox
                          type="text"
                          inputlabel="Address Line 1"
                          maxlength={30}
                          id="a11y_address-1"
                          name="Addressline1"
                          placeHolder="Address Line 1"
                          OnClick={() => {
                            setenableValidatebtn(true);
                            setclearbtnHidden(false);
                          }}
                          value={Addressline1}
                          OnChange={(e: any) => HandleChange(e)}
                          required={true}
                          isCurrency={false}
                        />
                      </Grid>
                      <Grid item md={3} lg={2}>
                        <RACTextbox
                          type="text"
                          inputlabel="Address Line 2"
                          maxlength={30}
                          infoIcon={true}
                          infoTitle={'Address 2, APT #/Unit #, P.O.Box'}
                          placeHolder="Address 2, APT #/Unit #, P.O.Box"
                          id="a11y_address-2"
                          name="Addressline2"
                          OnClick={() => {
                            setenableValidatebtn(true);
                            setclearbtnHidden(false);
                          }}
                          value={Addressline2}
                          OnChange={(e: any) => HandleChange(e)}
                          isCurrency={false}
                        />
                      </Grid>
                      <Grid item md={3} lg={2}>
                        <RACTextbox
                          type="text"
                          inputlabel="Zip"
                          maxlength={10}
                          id="a11y_zip"
                          placeHolder="Zip Code"
                          name="zip"
                          OnClick={() => {
                            setenableValidatebtn(true);
                            setclearbtnHidden(false);
                          }}
                          className={
                            fieldValidationforblur.zipCode === true &&
                              zip.length !== 5 &&
                              zip.length !== 10
                              ? `${classes.ssnBorderRed}`
                              : ''
                          }
                          value={zip}
                          OnChange={(e: any) => HandleChange(e)}
                          Onblur={() => fieldValidationOnblur('ZIP')}
                          required={true}
                          isCurrency={false}
                        />
                      </Grid>
                      <Grid item md={3} lg={2}>
                        <RACTextbox
                          type="text"
                          inputlabel="City"
                          maxlength={30}
                          id="a11y_city"
                          placeHolder="City"
                          name="city"
                          value={city}
                          OnChange={(e: any) => HandleChange(e)}
                          required={true}
                          isCurrency={false}
                        />
                      </Grid>
                      <Grid item md={3} lg={2}>
                        <RACSelect
                          inputLabel="State"
                          options={stateDD}
                          defaultValue={stateinfo !== '' ? stateinfo : '0'}
                          loading={
                            stateDD.length == 0 && !dropDownLoaded
                              ? true
                              : false
                          }
                          onChange={(e: any) => {
                            e.target.name = `stateinfo`;
                            setstateinfo(e.target.value);
                          }}
                          required={true}
                          {...(stateDD.length == 0 &&
                            dropDownLoaded && {
                            errorMessage: API_ERROR_MESSAGE,
                          })}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid
            item
            className={`${classes.w100} ${classes.spacerMT3} ${classes.textRight} ${classes.fixedBottomAddCo}`}
            style={{ zIndex: 0 }}
          >
            <Grid item spacing={2} className={classes.customerFloatright}>
              <RACButton
                variant="outlined"
                color="primary"
                className={`${classes.spacerMR2} ${classes.foc}`}
                data-testid="clrbtn"
                onClick={() => {
                  props.handleClosePopUp(false);
                }}
              >
                Cancel
              </RACButton>
              <RACButton
                variant="contained"
                color="primary"
                disabled={disabledValidatebtn}
                onClick={() => {
                  ValidateCustomerClcik();
                }}
              >
                Create customer
              </RACButton>
            </Grid>
          </Grid>
        </Grid>
        {
        useGoogleAddressValidation ? 
        <>
        <RACModalCard
         isOpen={canSuggestAddress}
         maxWidth="sm"
         closeIcon={true}
         borderRadius="20px"
         onClose={() => setCanSuggestAddress(false)}
         // eslint-disable-next-line react/no-children-prop
         children={suggestAddressFn()}
         title="Validation Message"
        />
        <RACModalCard
         isOpen={cantSuggestAddress}
         maxWidth="xs"
        //  closeIcon={true}
         borderRadius="20px"
        //  onClose={() => setCantSuggestAddress(false)}
         // eslint-disable-next-line react/no-children-prop
         children={suggestErrorInAddress()}
        />
        </>
        :
        null
      }
      </div>
    </>
  );
}
