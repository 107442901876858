/* eslint-disable prettier/prettier */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable no-debugger */
/* eslint-disable */
/* eslint-disable sonarjs/no-collapsible-if */
/* eslint-disable sonarjs/prefer-single-boolean-return */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable no-console */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable sonarjs/no-nested-template-literals */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable sonarjs/no-unused-collection*/
import React, { useState, useEffect, useContext } from 'react';
import { dropdown, DEHistoryRequest } from './ManageCustomerTypeScripts';
import { ReactComponent as RACLogo } from '../../assets/images/RACUpdatedLogoNew.svg';
//import Alert from '../../assets/images/erroricon.png';

import clsx from 'clsx';
import SecondFactor from './secondAuthen';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  GetCustomerByIdInfo,
  GetCustomerInfoOptions,
  GetCustomerAlert,
  GetEmployInfoOptions,
  UpdateAlert,
  GetAgreementById,
  UpdateCustomerInfo,
  GetSuggestedAddress,
  GetLegalSatatus,
  GetActivateClub,
  getCurrentInfo,
  getPinDetails,
  getprofileaccess,
  getsecondfactor,
  PrintFaxSheet,
  GetAlert,
  getmenuandstore,
  GetCustomerCoverage,
  GetSalesLeadExpirationDays,
  GetCustomerInfo,
  customerInfoLoad,
  GetEmploymentInfoOptions,
  exploymentInfoOpt,
  GetCustomerCreateInfo,
  storeConfigCall,
  GetDEHistory,
  GetCustomerAddress,
  GetCustomerPhone,
  UpdateAddressInfo,
  UpdateAddress,
  decryptCustomeeInfo,
  featureFlagInfo,
  getpaymentHistory
} from '../../api/user';
import {
  RACTextbox,
  RACDatePicker,
  RACSelect,
  RACRadio,
  RACToggle,
  RACCheckBox,
  RACButton,
  RACModalCard,
  RACTableCell,
  RACTable,
  RACTableRow,
  Card,
  Grid,
  CardContent,
  Typography,
  ListItem,
  List,
  Box,
  RACInfoIcon,
  CircularProgress,
  RACTooltip,
} from '@rentacenter/racstrap';
import DatePicker from 'react-datepicker';
import '../../styles/reactDatePicker.css';
import { AddCoCustomer } from './AddCoCustomer';
import { ReactComponent as PreviousBtn } from '../../assets/images/previous-btn-arrow.svg';
import { ReactComponent as Calendar } from '../../assets/images/calendar.svg';
import { ReactComponent as NextBtn } from '../../assets/images/next-btn-arrow.svg';
import { ReactComponent as Alerticon } from '../../assets/images/No-records.svg';
import { ReactComponent as SuccessIcon } from '../../assets/images/success-icon.svg';
import { ReactComponent as ExpandAlert } from '../../assets/images/expand-alert-icon.svg';
import { ReactComponent as ExpandCancel } from '../../assets/images/alert-cancel-icon.svg';
import { ReactComponent as CompletedIcon } from '../../assets/images/wizard-complete.svg';
import { ReactComponent as AccordianOpenIcon } from '../../assets/images/Accordion-down.svg';
import { ReactComponent as AccordianCloseIcon } from '../../assets/images/Accordion-close.svg';
import Modal from 'react-modal';
import moment from 'moment';
import { useLocation, Link, useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { ManageCustomerStyles } from '../ManageCustomerStyles';
import { customerStyles } from '../customerStyles';
import { generateOrderForm } from './OrderForm';
// import { EncryptionService } from '@rentacenter/ess-ts-utils'
import { mapPhones, renderTcpaContent, checkTCPAFlags, ndncTip } from './customerUtil';
import validateAddressFn from '../../common/validateAddress';
import { ReactComponent as WarningIcon } from "../../assets/images/no-records-found.svg";
import {
  API_ERROR_MESSAGE,
  howLongObj,
  relationShipObj,
  buildVechicleYear,
  PHONE_ERROR_MESSAGE,
  INVALID_ZIP,
  FIELD_ERROR,
  agreementHistoryHeader,
  DEHistoryHeader,
  gridheadfields,
  bindHeaderAddressHistory,
  headerPrimAdd,
} from '../../constants/constants';
import { ContainerContext } from '../../app/App';
import { CustomPropInterface } from '../../index';
import { truncate } from 'fs';
import { AppRoute } from '../../config/route-config';
import { customerValidationContext } from '../../context/customerContext/customerContext';
import { FeatureFlagContext } from '../../context/FeatureFlagContext';
import { DeliveryContext } from "../../context/DeliveryContext";
import crypto, { createDecipheriv } from "crypto";
import { ReactComponent as ExchangeIcon } from '../../assets/images/Exchange.svg';
import RACExchange from '../customerComponent/RACExchange';
import { DECISION_ENGINE_APPROVED, DECISION_ENGINE_PREAPPROVED, DECISON_ENGINE_DECLINED, DECISON_ENGINE_EXPIRED, NO_DECISION_ENGINE } from '../../CustomerRefactor/constants/constants';
const decryptKeys = { encryptKey: crypto.randomBytes(16).toString("hex"), ivKey: crypto.randomBytes(8).toString("hex") }

const addressLine1 = 'Address Line 1';
const addressLine2 = 'Address 2, APT #/Unit #, P.O.Box';
interface ParamType {
  customerId: string;
  pageType: string;
}
let IsPrimaryNotSet = '';
enum tabList {
  CUSTOMERINFO,
  EMPLOYMENTINFO,
  RESIDENCE,
  REFERENCE,
  VEHICLEINFO,
  AGREEMENTHISTORY,
  DEHISTORY,
}
//let coWorkerRole = '';
const ApprovedObj = 'Approved';
const ConditionalApproved = 'Approved';
// eslint-disable-next-line sonarjs/cognitive-complexity
// eslint-disable-next-line sonarjs/no-duplicate-string
export function ManageCustomerInfoRacstrap(props: any) {
  const { customerQsrc, agrQ, inventory, reInstateCheck, saleLeadCoCreate } = useContext(
    customerValidationContext
  );

  const { customerSearchValue } = useContext(DeliveryContext);
  const [searchValCustomerEditor, setSearchValCustomerEditor] = customerSearchValue;
  const { featureFlagDetails } = useContext(FeatureFlagContext);
  const [totalRentalRevenueAmount, settotalRentalRevenueAmount] = useState('0')
  const [showTooltip, setShowTooltip] = useState(false);
  const [validatedAddresses,setValidatedAddress] = useState<any>({});
  const [canSuggestAddress,setCanSuggestAddress] = useState(false);
  const [cantSuggestAddress,setCantSuggestAddress] = useState(false);
  const [isFromCustPage, setisFromCustPage] = reInstateCheck;
  const [agrId, setagrId] = agrQ;
  const history: any = useHistory();
  const [storeTransferAllowed, setstoreTransferAllowed] =
    useState<any>(false);
  const containerData = useContext(ContainerContext) as CustomPropInterface;
  const classes = ManageCustomerStyles();
  const customerStyleClasses = customerStyles();
  const { customerId, pageType } = useParams<ParamType>();
  const search = useLocation().search;
  const customerIdQueryParam: any =
    new URLSearchParams(search).get('customerId') !== undefined &&
      new URLSearchParams(search).get('customerId') !== null &&
      new URLSearchParams(search).get('customerId') !== ''
      ? new URLSearchParams(search).get('customerId')
      : null;
  const salesLeadIdQuery: any =
    new URLSearchParams(search).get('salesLeadId') !== null
      ? new URLSearchParams(search).get('salesLeadId')
      : '';
  const modelNumberQueryParams: any =
    new URLSearchParams(search).get('modelNumber') !== null
      ? new URLSearchParams(search).get('modelNumber')
      : null;
  const alertTypeIdQueryParams: any =
    new URLSearchParams(search).get('alertTypeId') !== null
      ? new URLSearchParams(search).get('alertTypeId')
      : null;
  // // eslint-disable-next-line no-console
  // console.log('customerIdQueryParam', customerIdQueryParam);
  const dt = new Date();
  const inactiveTabClass = 'nav-link inactive';
  const [activetab, setactivetab] = useState<tabList | undefined>(
    tabList.CUSTOMERINFO
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isPrimaryCustoInfoUpdate, setIsPrimaryCustoInfoUpdate] =
    useState<boolean>(false);
  const [DEStatus, setDEStatus] = useState<any>();
  const [AddressDoc, setAddressDoc] = useState<boolean>(false);
  const [coworkerPinAuth, setcoworkerPinAuth] = useState(false);
  const [multipleAgreementPrimaryAddress, setMultipleAgreementPrimaryAddress] = useState(false);
  // customer information state variables
  const [SuggestedAddress, setSuggestedAddress] = useState<any>();
  const [createAgreementClick, setCreateAgreementClick] =
    useState<boolean>(false);
  const [datePickerIsLoad, setDatePickerIsLoad] = useState<boolean>(true);
  //const [Addressline1, setAddressline1] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isAuth, setisAuth] = useState(false);
  // eslint-disable-next-line prefer-const
  let [phoneNumberProps, setphoneNumberProps] = useState<any>([]);
  const [slapopupauthentication, setslapopupauthentication] = useState(false);
  const [inActivePopUp1, setInActivePopUp1] = useState<boolean>(false);
  const [activePopUp, setActivePopUp] = useState<boolean>(false);
  const [activePopUp11, setActivePopUp11] = useState<boolean>(false);
  const [inActivePopUp, setInActivePopUp] = useState<boolean>(false);
  const [sendId, setSendId] = useState<any>();
  const [phNumIndex, setPhNumIndex] = useState<any>();
  const [inActivePhArr, setInActivePhArr] = useState<any>([]);
  const [inActConDet, setInActConDet] = useState<boolean>(false);
  const [inActivePopSaveDisable, setInActivePopSaveDisable] = useState<boolean>(true);
  const [primaryIndex, setPrimaryIndex] = useState<any>();
  const [addressHistoryPopUp, setAddressHistoryPopUp] = useState<boolean>(false);
  const [addressHistoryArray, setAddressHistoryArray] = useState<any>([]);

  const [AlertWizard, setAlertWizard] = useState<any>([]);
  const [transferInitiated, settransferInitiated] = useState(false);
  const [CustomAlerttxt, setCustomAlerttxt] = useState('');
  const [Istextboxdisable, setIstextboxdisable] = useState(false);
  const [editInputBox, seteditInputBox] = useState(true);
  const [editInputBoxM, seteditInputBoxM] = useState(true);
  const [TaxIDActive, setTaxIDActive] = useState(false);
  const [taxexempt_toggle, setTaxexempt] = useState(false);
  const [AlertIsOpen, setIsOpen] = useState(false);
  const [Enabletxt, setEnabletxt] = useState(true);
  const [customerInfoChange, setcustomerInfoChange] = useState<boolean>(false);
  const [customerInfoChangePopUp, setcustomerInfoChangePopUp] =
    useState<boolean>(false);
  const [customerInformation, setcustomerInformation] = useState<any>();
  const [PopupClass, setPopupClass] = useState<string>(
    `${classes.customMenuContainer} ${classes.popUpHide}`
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [customerInf, setcustomerInf] = useState<any>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [customerCoverageInf, setcustomerCoverageInf] = useState<any>('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [agreementInf, setagreementInf] = useState<[] | any>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLegalHold, setIsLegalHold] = useState<boolean>(false);
  // employement information state variables
  const [ValidateIsOpen, setValidateIsOpen] = useState(false);
  const [EmpProps, setEmpProps] = useState<any | []>([]);
  const [payDayfrequency, setpayDayfrequency] = useState<dropdown[] | []>([]); //state variable for PayDayFrequency dropdown
  const [payDayfrequencyAdded, setpayDayfrequencyAdded] =
    useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [dayPaid1, setdayPaid1] = useState<dropdown[] | []>([]);
  const [dayPaid3, setdayPaid3] = useState<dropdown[] | []>([]);
  const [dayPaid5, setdayPaid5] = useState<dropdown[] | []>([]);
  const [activateClubObj, setactivateClubObj] = useState<boolean>(false);
  const [Residences, setResidencesvalues] = useState<any | []>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ReferenceProps, setReferenceProps] = useState<any | []>([]);
  const [Vehicle, setVehiclevalues] = useState<any | []>([]);
  const [validateResult, setValidateResult] = useState<any | []>([]);
  const [activeExpand, setactiveExpand] = useState(true);
  const [inActiveExpand, setinActiveExpand] = useState(true);
  const [pending, setpending] = useState(true);
  const [reInstate, setreInstate] = useState(true);
  const [LegalHold, setLegalHold] = useState<any>([]);
  const [LegalOption, setLegalOption] = useState<any>([]);
  //const NotProvided = 'Not Provided';
  const loginStore = sessionStorage.getItem('storeNumber');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [govTypeId, setgovTypeId] = useState<dropdown[]>([]);
  const [salutation, setsalutation] = useState<dropdown[] | []>([]);
  const [suffix, setSuffix] = useState<dropdown[] | []>([]);
  const [state, setstate] = useState<dropdown[] | []>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [addressInfoState, setaddressinfoState] = useState<dropdown[] | []>([]);
  const [restatementPopUp, setrestatementPopUp] = useState<boolean>(false);
  const [restatementCustomer, setrestatementCustomer] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [bestTimeToCall, setbestTimeToCall] = useState<dropdown[] | []>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [phoneType, setphoneType] = useState<dropdown[] | []>([]);
  const [preferredMethod, setpreferredMethod] = useState<dropdown[] | []>([]);
  const [addressInfoRoute, setaddressInfoRoute] = useState<dropdown[] | []>([]);
  const [taxExemptStatus, settaxExemptStatus] = useState<dropdown[] | []>([]);
  const [disableAlert, setdisableAlert] = useState<boolean>(false);
  // Footer Component
  const [errorPopup, setErrorPopup] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [checkAction, setcheckAction] = useState<number[] | any>('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [activateClub, setactivateClub] = useState(false);
  const [objAlertdata, setobjAlertdata] = useState<any>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [AlertLoad, setAlertLoad] = useState<any | []>([]);
  const [AlertLoadCount, setAlertLoadCount] = useState<number>(0);
  const [AlertUpdateObj, setAlertUpdateObj] = useState<any | []>([]);
  const [createActiveTab, setCreateActiveTab] = useState<number>(0);
  const [nextBtnText, setnextBtnText] = useState<string>('Next');
  const [activeAlert, setActiveAlert] = useState<[] | any>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isrequired, setisrequired] = useState<any>(false);
  const [updatePopUp, setShowUpdatePopUp] = useState<boolean>(false);
  // eslint-disable-next-line prefer-const
  let [isSuccess, setisSuccess] = useState<boolean>(false);
  const [isGovtNumOnFocus, setisGovtNumOnFocus] = useState<boolean>(false);
  //const [inActiveAlerts, setinActiveAlerts] = useState<any | []>([]);
  const [AlertAuthenticationNeeded, setAlertAuthenticationNeeded] =
    useState<any>();
  const [preferredRoute, setPreferrefRoute] = useState<any>();
  const [createAgreementPopUp, setcreateAgreementPopUp] =
    useState<boolean>(false);
  const [COAgreementPopUp, setCOAgreementPopUp] =
    useState<boolean>(false);
  const [isAlertLoading, setIsAlertLoading] = useState<boolean>(false);
  const [createCoCustomerPopUp, setcreateCoCustomerPopUp] =
    useState<boolean>(false);
  const addressUpdate: any = {
    address: '',
    address1: '',
    city: '',
    zip: '',
    state: '',
  };
  const [addressErrorMsg, setAddressErrorMsg] = useState<any>('');
  const [updatePrimaryAddress, setUpdatePrimaryAddress] =
    useState<any>(addressUpdate);
  const [updateMailingAddress, setupdateMailingAddress] =
    useState<any>(addressUpdate);
  const [defaultSuggestedAddress, setdefaultSuggestedAddress] = useState('SUG');
  const [BtnIsdisabled1, setBtnIsdisabled1] = useState(true);
  const [BtnIsdisabled2, setBtnIsdisabled2] = useState(false);
  const [runAddressDoc, setrunAddressDoc] = useState<'P' | 'M' | ''>('');
  const [skipHardObj, setskipHardObj] = useState<'S' | 'H' | ''>('');
  //User Authentication
  const [openModal, setOpenModal] = useState(false);
  const [openSecondModal, setOpenSecondModal] = useState(false);
  const [userID, setUserID] = useState('');
  const [password, setPassword] = useState('');
  const [errorline, setErrorLine] = useState(false);
  const [popupFlag, setpopupFlag] = useState(false);
  const [role, setRole] = useState('');
  const [authenType, setauthenType] = useState<
    'RACWORKER' | 'ALERT' | 'ROUTES' | 'SKIP' | '' | 'CREATEAGR'
  >('');
  const [openPinModal, setOpenPinModal] = useState(false);
  const [otp, setotp] = useState('');
  const [loader, setloader] = useState<boolean>(false);
  const [InvalidPin, setInvalidPin] = React.useState<any>();
  const [Continue, setContinue] = React.useState(true);
  const [getStore, setGetStore] = useState<any>();
  const [transferAgr, settransferAgr] = useState(true);
  const [transferAgrContent, settransferAgrContent] = useState('');
  const [showcocustomerpopup, Setshowcocustomerpopup] = useState(false);
  const [dropDownLoaded, setDropDownLoaded] = useState<boolean>(false);
  const [dropDownLoadedEmp, setDropDownLoadedEmp] = useState<boolean>(false);
  const [secondLevelAuthenOn, setSecondLevelAuthenOn] = useState<any>(null);
  const [suggestionAddresPopup, setSuggestionAddresPopup] = useState(false);
  const [takePaymentOpen, settakePaymentOpen] = useState(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [legalHoldApplied, setLegalHoldApplied] = useState<0 | 1 | 2>(0);
  const [isSecondAuthenValid, setIsSecondAuthenValid] =
    useState<boolean>(false);
  const [validateAddress, setValidateAddress] = useState<'P' | 'M' | 'B' | ''>(
    ''
  );
  const [coWorkerRole, setCoWorkerRole] = useState<string>('');
  const [Agrtransferinit, setAgrtransferinit] = useState(false);
  const [SSNDOBdisable,setSSNDOBdisable] = useState(false)
  const [comingSoonPopup, setcomingSoonPopup] = useState<boolean>(false);
  const [verExpDate, setverExpDate] = useState<any>(60);
  const [coWorkerRoleForCSpopup, setcoWorkerRoleForCSpopup] = useState<any>();
  const [taxExSt, settaxExSt] = useState<any>();
  const [validateReference, setValidateReference] = useState<any>(true);
  const [validateEmployement, setValidateEmployement] = useState<any>(true);
  const [createAgrClicked, setcreateAgrClicked] = useState<any>(false);
  const [agrCheckBoxId, setagrCheckBoxId] = useState<any>({
    id: '',
    checkBox: true,
  });
  const [webLink, setWebLink] = useState<boolean>();
  const [webLinkDisable, setWebLinkDisable] = useState<boolean>(false);
  const [webError, setWebError] = useState<boolean>(false);
  const [webLinkFailed, setWebLinkFailed] = useState<boolean>(false);
  const [ecomSupport, setEcomSupport] = useState<boolean>(false);
  const [primaryAddressUpdated, setPrimaryAddressUpdated] = useState<boolean>(false);
  const [webLinked, setWebLinked] = useState<boolean>(false);
  const [webLinkemail, setWebLinkEmail] = useState<boolean>(false);
  const [matchCode, setmatchCode] = useState<any>('');
  const [rel, setrel] = useState<dropdown[]>([]);
  const [customerSrc, setcustomerSrc] = customerQsrc;
  const [selectedinventoryNumber, setselectedinventoryNumber] = inventory;
  const [storeReinstatementAllowed, setstoreReinstatementAllowed] =
    useState<any>(false);
  const [deliveryAddressArray, setDeliveryAddressArray] = useState<any>([]);
  // eslint-disable-next-line prefer-const
  let [deliveryAddDisableArr, setDeliveryAddDisableArr] = useState<any>([]);
  const [editAddBtnShowArr, setEditAddBtnShowArr] = useState<any>([]);
  const [updateDeliveryAddressArray, setUpdateDeliveryAddressArray] = useState<any>([]);
  const [secondaryFilterArr, setSecondaryFilterArr] = useState<any>([]);
  const [deliveryId, setDeliveryId] = useState<any>();
  const [deliveryScen, setDeliveryScen] = useState<boolean>(false);
  const [addressType, setAddressType] = useState<any>();
  const [primAddPopUp, setPrimAddPopUp] = useState<any>(false);
  const [tcpaPopup, setTcpaPopup] = useState<any>(false); //zz
  const [customerAddressActive, setCustomerAddressActive] = useState<any>();

  const [customerauthDOB, setcustomerauthDOB] = useState<any>();

  // eslint-disable-next-line prefer-const
  let [primaryAddArr, setPrimaryAddArr] = useState<any>([]);
  const [radioArray, setRadioArray] = useState<any>([]);
  const [primaryAddressIndex, setPrimaryAddressIndex] = useState<any>();
  const [coCustomerDetail, setcoCustomerDetail] = useState<any>([]);
  const [isMaskedDataHasChanged, setisMaskedDataHasChanged]: any = useState({ ssn: false, govtId: false, dob: false });
  let decryptiondataofbirth
  const DEHISTORY_RESPONSE = {
    globalCustomerId: 'c2e75d9d-ba88-4b78-bcfa-eba6c611bf3c',
    customerId: '1924761',
    ssn: 'XXXXX6942',
    lastName: 'SIMSAMTA',
    firstName: 'KELLYTWO',
    customerApprovalHistory: [
      {
        customerApprovalId: '3535467',
        approvalDate: '2022-09-12',
        approvalExpirationDate: '2022-10-12',
        approvalStatusCode: 'A',
        approvalStatusDesc: 'Approve',
        approvalId: '151304866549102',
        approvalStoreNumber: '02477',
        originalApprovalAmount: '2000',
        weeklyOriginalApprovalAmount: '50',
        createdBy: 'SIMS',
        createdDate: '2022-09-12T21:38:05.000Z',
        lastModifiedBy: 'SIMS',
        lastModifiedDate: '2022-09-12T21:38:05.000Z',
      },
      {
        customerApprovalId: '3535467',
        approvalDate: '2022-09-12',
        approvalExpirationDate: '2022-10-12',
        approvalStatusCode: 'A',
        approvalStatusDesc: 'Approve',
        approvalId: '151304866549102',
        approvalStoreNumber: '02477',
        originalApprovalAmount: '2000',
        weeklyOriginalApprovalAmount: '50',
        createdBy: 'SIMS',
        createdDate: '2022-09-12T21:38:05.000Z',
        lastModifiedBy: 'SIMS',
        lastModifiedDate: '2022-09-12T21:38:05.000Z',
      },
      {
        customerApprovalId: '3535467',
        approvalDate: '2022-09-12',
        approvalExpirationDate: '2022-10-12',
        approvalStatusCode: 'A',
        approvalStatusDesc: 'Approve',
        approvalId: '151304866549102',
        approvalStoreNumber: '02477',
        originalApprovalAmount: '2000',
        weeklyOriginalApprovalAmount: '50',
        createdBy: 'SIMS',
        createdDate: '2022-09-12T21:38:05.000Z',
        lastModifiedBy: 'SIMS',
        lastModifiedDate: '2022-09-12T21:38:05.000Z',
      },
      {
        customerApprovalId: '3535467',
        approvalDate: '2022-09-12',
        approvalExpirationDate: '2022-10-12',
        approvalStatusCode: 'A',
        approvalStatusDesc: 'Approve',
        approvalId: '151304866549102',
        approvalStoreNumber: '02477',
        originalApprovalAmount: '2000',
        weeklyOriginalApprovalAmount: '50',
        createdBy: 'SIMS',
        createdDate: '2022-09-12T21:38:05.000Z',
        lastModifiedBy: 'SIMS',
        lastModifiedDate: '2022-09-12T21:38:05.000Z',
      },
      {
        customerApprovalId: '3535467',
        approvalDate: '2022-09-12',
        approvalExpirationDate: '2022-10-12',
        approvalStatusCode: 'A',
        approvalStatusDesc: 'Approve',
        approvalId: '151304866549102',
        approvalStoreNumber: '02477',
        originalApprovalAmount: '2000',
        weeklyOriginalApprovalAmount: '50',
        createdBy: 'SIMS',
        createdDate: '2022-09-12T21:38:05.000Z',
        lastModifiedBy: 'SIMS',
        lastModifiedDate: '2022-09-12T21:38:05.000Z',
      },
      {
        customerApprovalId: '3535467',
        approvalDate: '2022-09-12',
        approvalExpirationDate: '2022-10-12',
        approvalStatusCode: 'A',
        approvalStatusDesc: 'Approve',
        approvalId: '151304866549102',
        approvalStoreNumber: '02477',
        originalApprovalAmount: '2000',
        weeklyOriginalApprovalAmount: '50',
        createdBy: 'SIMS',
        createdDate: '2022-09-12T21:38:05.000Z',
        lastModifiedBy: 'SIMS',
        lastModifiedDate: '2022-09-12T21:38:05.000Z',
      },
      {
        customerApprovalId: '3535467',
        approvalDate: '2022-09-12',
        approvalExpirationDate: '2022-10-12',
        approvalStatusCode: 'A',
        approvalStatusDesc: 'Approve',
        approvalId: '151304866549102',
        approvalStoreNumber: '02477',
        originalApprovalAmount: '2000',
        weeklyOriginalApprovalAmount: '50',
        createdBy: 'SIMS',
        createdDate: '2022-09-12T21:38:05.000Z',
        lastModifiedBy: 'SIMS',
        lastModifiedDate: '2022-09-12T21:38:05.000Z',
      },
      {
        customerApprovalId: '3535467',
        approvalDate: '2022-09-12',
        approvalExpirationDate: '2022-10-12',
        approvalStatusCode: 'A',
        approvalStatusDesc: 'Approve',
        approvalId: '151304866549102',
        approvalStoreNumber: '02477',
        originalApprovalAmount: '2000',
        weeklyOriginalApprovalAmount: '50',
        createdBy: 'SIMS',
        createdDate: '2022-09-12T21:38:05.000Z',
        lastModifiedBy: 'SIMS',
        lastModifiedDate: '2022-09-12T21:38:05.000Z',
      },
      {
        customerApprovalId: '3535467',
        approvalDate: '2022-09-12',
        approvalExpirationDate: '2022-10-12',
        approvalStatusCode: 'A',
        approvalStatusDesc: 'Approve',
        approvalId: '151304866549102',
        approvalStoreNumber: '02477',
        originalApprovalAmount: '2000',
        weeklyOriginalApprovalAmount: '50',
        createdBy: 'SIMS',
        createdDate: '2022-09-12T21:38:05.000Z',
        lastModifiedBy: 'SIMS',
        lastModifiedDate: '2022-09-12T21:38:05.000Z',
      },
    ],
  };
  const [deHistoryResponse, setDEHistoryResponse] = useState<any>();
  const [deHistoryQueryParams, setdeHistoryQueryParams] = useState<any>({
    offset: 0,
    limit: 0,
  });
  const [hasMore, sethasMore] = useState<boolean>(true);
  const [infinitScrolloader, setinfinitScrolloader] = useState<boolean>(false);
  const [splitCoCustomerAvailble, setSplitCoCustomerAvailble] = useState(false);
  const [splitEligibleAgrs, setsplitEligibleAgrs] = useState([]);
  const [splitBlockOpen, setSplitBlockOpen] = useState(false);
  const [getAgrbyIdresponse, setGetAgrbyIdresponse]: any = useState([]);
  const formatCurrency = new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: 'USD'
  });
  const [isExchangeEnabled, setExchangeEnabled] = useState<any>(false);
  const [exchangePeriod, setExchangePeriod] = useState('');
  const [returnPeriod, setReturnPeriod] = useState('');
  const [racExchangeFuncFlag, setRacExchangeFuncFlag] = useState<boolean>(false);
  const isDropDownValueExist = (options: any, value: string) => {
    let isValueExist = false;
    if (options.length > 0 && value) {
      for (let option = 0; option < options.length; option++) {
        if (options[option]?.value === value) {
          isValueExist = true;
          break;
        }
      }
    }
    return isValueExist;
  };
  //Generator
  function* numCount() {
    let count = 0;
    while (true) yield count++; /*  ww w  .  j av a2s.co  m*/
  }

  const irt = numCount();
  const getData = (key: string, iv: string, data: string) => {
    console.log('payloadKeys-->', key, iv, data)
    const decipher = createDecipheriv('aes-256-cbc', key, iv);
    let result: any = decipher.update(data, 'hex', 'utf8');
    result += decipher.final('utf8');
    console.log('payloadKeys', iv, key, data, result)
    return result;
  }
  const [exchangeSelected, setExchangeSelected] = useState<any>('');
  const [exchangeNavigationPopup, setExchangeNavigationPopup] = useState<boolean>(false);
  const [rentPaid, setRentPaid] = useState<any>(0);
  const [exchangeAgreementId, setAgreementId] = useState<any>(0);
  const [exchangeAgreementNumber, setExchangeAgreementNumber] = useState<any>('');
  const [suspenseAmount, setSuspenseAmount] = useState<any>('');
  const  [btnClicked, setBtnClicked] = useState<any>(null);
  const [cantAccess,setCantAccess]= useState(false)
 
  // eslint-disable-next-line sonarjs/cognitive-complexity

  useEffect(() => {
    if (customerId) {
      const getpaymentHistoryRes = async () => {
        let getpaymentHistoryResponse = await getpaymentHistory(
          customerId,
          "agr", 0
        );
        debugger
        settotalRentalRevenueAmount(getpaymentHistoryResponse?.data?.value?.customerInfo?.totalRentalRevenue)
      }
      getpaymentHistoryRes()
    }

  }, [])
  useEffect(
    // eslint-disable-next-line sonarjs/cognitive-complexity
    () => {
      console.log('inventory[0]', selectedinventoryNumber);
      // eslint-disable-next-line sonarjs/cognitive-complexity
      const PageLoad = async () => {
        debugger;
        let phNRes: any;
        let custNRes: any;
        let custYRes: any;
        const coWorkerRole = await getWorkerRole();
        /* eslint-disable no-console */
        console.log('result inside coWorkerRole', coWorkerRole);
        let isLegalHold = false;
        if (pageType === 'customer') {
          isLegalHold = await getlegalHold();
          //if (isLegalHold) {
          //}
        }
        getCoWorkerRole(isLegalHold, coWorkerRole);
        if (customerId != undefined) {
          try {
            const payload: any = {
              storeNumbers: [],
              paramKeyNames: ['Verification_Expiry_Days', 'Reinstatement', 'AgreementTransfer'],
            };
            payload.storeNumbers.push(loginStore);

            let loadResponseObj: customerInfoLoad = {
              getAlerts: '',
              getCustomerAlerts: '',
              responseSales: '',
              result: '',
              getOptions: '',
              getCoverage: '',
            };

            const getAddY = new Promise(() => {
              const getCustYRes = async () => {
                custYRes = await GetCustomerAddress(customerId, 'Y');

                //setAddressYActCrr(custYRes?.GetCustomerAddress?.value?.addresses);
                console.log(custYRes, 'cusdtYRes');
              }
              getCustYRes();
            })
            const getAddN = new Promise(() => {
              const getCustNRes = async () => {
                custNRes = await GetCustomerAddress(customerId, 'N');

                //setAddressNActArr(custNRes?.GetCustomerAddress?.value?.addresses);
              }
              getCustNRes();
            })
            const getPhN = new Promise(() => {
              const getPhoneNRes = async () => {
                phNRes = await GetCustomerPhone(customerId, 'N');
                //setPhoneNActArr1 = phNRes?.GetCustomerPhone?.value?.response
                console.log(phNRes, 'phNRes');
              }
              getPhoneNRes();
            })

            Promise.race([getAddY, getAddN, getPhN]);
            loadResponseObj = await GetCustomerInfo(
              payload,
              customerId,
              loginStore
            );
            if (pageType === 'customer') {


              getAlertsLoad(
                loadResponseObj.getAlerts,
                loadResponseObj.getCustomerAlerts
              );
              transfergetAgreement(loadResponseObj.getAgrHistory);
              GetVerificationExpirationDays(loadResponseObj.responseSales);
              GetstoreReinstatementAllowed(loadResponseObj.responseSales);
              GetstoreTransferRequest(loadResponseObj.responseSales);
            } else {
              loadResponseObj = await GetCustomerCreateInfo(
                customerId,
                loginStore
              );
            }
            let result3: any;
            if (
              custYRes?.GetCustomerAddress !== undefined &&
              custYRes?.GetCustomerAddress?.value !== undefined &&
              custYRes?.GetCustomerAddress?.value?.addresses !== undefined &&
              custYRes?.GetCustomerAddress?.value?.addresses.length > 0
            ) {
              result3 = custYRes?.GetCustomerAddress?.value;
              console.log(result3, 'result3');
              //buildCustPrimAndDeliv(result3);
            }
            /* eslint-disable no-console */
            console.log('result inside getOptions', loadResponseObj.getOptions);
            /* eslint-enable no-console */
            pageLoadStateVariable(loadResponseObj.getOptions);
            // eslint-disable-next-line

            const result = loadResponseObj.result;
            const CustomerInfo = getCustomerInfo(
              // result !== undefined &&
              //   result?.GetCustomer !== undefined &&
              //   result?.GetCustomer?.value !== undefined &&
              //   result?.GetCustomer?.value.code == undefined
              //   ? result?.GetCustomer.value
              //   : undefined
              result?.GetCustomer.value, result3
            );
            const CustomerDEInfo = createApprovalBuild(result);
            setDEStatus(CustomerDEInfo);
            const verifieddate: any = buildVerificationdate(
              result.GetCustomer.value
            );
            setcustomerCoverageInf(
              loadResponseObj.getCoverage !== undefined &&
                loadResponseObj.getCoverage !== null &&
                loadResponseObj.getCoverage.clubInfo !== undefined &&
                loadResponseObj.getCoverage.clubInfo !== null
                ? loadResponseObj.getCoverage.clubInfo
                : undefined
            );
            /* eslint-disable no-console */
            console.log('verifieddate', verifieddate);
            CustomerInfo.verifiedDate = verifieddate !== '' ? verifieddate : '';
            // const ivKey = crypto.randomBytes(8).toString("hex");

            // // Generate a random cryptographic key
            // const enckey = crypto.randomBytes(16).toString("hex");
            // console.log('keysss', ivKey, enckey)
            // const payloadForDecrypt: any = { decryptData: {}, encryptKey: crypto.randomBytes(16).toString("hex"), ivKey: crypto.randomBytes(8).toString("hex") }
            // if (CustomerInfo && CustomerInfo.dateOfBirthEnc) {
            //   payloadForDecrypt.decryptData.dateOfBirth = CustomerInfo.dateOfBirthEnc
            // }
            // if (CustomerInfo && CustomerInfo.taxIdEnc) {
            //   payloadForDecrypt.decryptData.taxId = CustomerInfo.taxIdEnc
            // }
            // if (CustomerInfo && CustomerInfo.governmentIdEnc) {
            //   payloadForDecrypt.decryptData.governmentId = CustomerInfo.governmentIdEnc
            // }
            // const decryptResponse: any = await decryptCustomeeInfo(payloadForDecrypt)
            // console.log('decryptResponse', decryptResponse)
            // if (decryptResponse && decryptResponse.data?.decryptData ){
            //   console.log('log 1');
            //   if (CustomerInfo && CustomerInfo.dateOfBirthEnc) {
            //     console.log('log 2');
            //     // CustomerInfo.dateOfBirth = buildDateFormat2(getData(payloadForDecrypt.encryptKey, payloadForDecrypt.ivKey, decryptResponse.data?.decryptData?.dateOfBirth))
            //     CustomerInfo.dateOfBirth = getData(payloadForDecrypt.encryptKey, payloadForDecrypt.ivKey, decryptResponse.data?.decryptData?.dateOfBirth)
            //     console.log('log 2', CustomerInfo);
            //   }
            //   if (CustomerInfo && CustomerInfo.taxIdEnc) {
            //     const taxIdRes = getData(payloadForDecrypt.encryptKey, payloadForDecrypt.ivKey, decryptResponse.data?.decryptData?.taxId)
            //     console.log('log 3', taxIdRes);
            //     CustomerInfo.ssn1 = taxIdRes.slice(0, 3)
            //     CustomerInfo.ssn2 = taxIdRes.slice(3, 5)
            //     CustomerInfo.ssn3 = taxIdRes.slice(5)
            //   }
            //   if (CustomerInfo && CustomerInfo.governmentIdEnc) {
            //     CustomerInfo.governmentId = getData(payloadForDecrypt.encryptKey, payloadForDecrypt.ivKey, decryptResponse.data?.decryptData?.governmentId)
            //     console.log('log 4', CustomerInfo);
            //   }
            // }
            // console.log('decryptResponse', decryptResponse)
            console.log('CustomerInfo', CustomerInfo)
            setcustomerInformation(CustomerInfo);
            setIsPrimaryCustoInfoUpdate(false);
            setcustomerInf(result.GetCustomer?.value);

            // console.log("console...",result.GetInactivePhone.phones);
            setphoneNumberProps(mapPhones(result));  //tcpa



            const result1: any = [];
            if (phNRes?.GetCustomerPhone !== undefined &&
              phNRes?.GetCustomerPhone?.value !== undefined &&
              phNRes?.GetCustomerPhone?.value?.phones !== undefined &&
              phNRes?.GetCustomerPhone?.value?.phones?.length > 0) {
              //setInActivePhArr(result.GetInactivePhone.value.phones)
              //console.log(result.GetInactivePhone.phones,"result.GetInactivePhone.phones");
              phNRes?.GetCustomerPhone?.value?.phones.map((val: any, index: any) => {
                if (val.active === '0' && (val.primaryFlag === '0' || val.primaryFlag === '1')) {
                  result1.push({
                    phoneTypeDesc: val.phoneTypeDesc,
                    note: val.note,
                    phoneType: val.phoneType,
                    lastModifiedDate: val.lastModifiedDate,
                    phoneExtension: val.phoneExtension,
                    lastModifiedBy: val.lastModifiedBy,
                    phoneId: val.phoneId,
                    active: val.active == '0' ? 'N' : '',
                    primaryFlag: val.primaryFlag == '0' ? 'N' : 'Y',
                    callType: val.callType,
                    callTimeType: val.callType,
                    createdSource: val.createdSource,
                    callTypeDesc: val.callTypeDesc,
                    createdDate: val.createdDate,
                    phoneNumber: val.phoneNumber,
                    createdBy: val.createdBy,
                    lastModifiedSource: val.lastModifiedSource,
                    entPhoneId: val.entPhoneId,
                    primary: val.primaryFlag == '0' ? 'N' : 'Y',
                    callTimeTypeDesc: val.callTypeDesc,
                  })
                }
              })
            }
            console.log(result1, "result1");
            setInActivePhArr(result1);

            if (
              custNRes?.GetCustomerAddress !== undefined &&
              custNRes?.GetCustomerAddress?.value !== undefined &&
              custNRes?.GetCustomerAddress?.value?.addresses !== undefined &&
              custNRes?.GetCustomerAddress?.value?.addresses?.length > 0
            ) {
              debugger;
              // eslint-disable-next-line prefer-const
              let addressID: any = []
              // eslint-disable-next-line sonarjs/no-unused-collection, prefer-const
              let inActiveAddressArray: any = []
              for (let i = 0; i < custNRes?.GetCustomerAddress?.value?.addresses?.length; i++) {

                const filteredAddress = custNRes?.GetCustomerAddress?.value?.addresses?.filter((val: any) => val.addressId == custNRes?.GetCustomerAddress?.value?.addresses[i].addressId
                  && val.storeNumber == custNRes?.GetCustomerAddress?.value?.addresses[i].storeNumber)
                if (filteredAddress?.length > 0 && !addressID?.includes(custNRes?.GetCustomerAddress?.value?.addresses[i].addressId + "," + custNRes?.GetCustomerAddress?.value?.addresses[i].storeNumber)) {
                  addressID.push(custNRes?.GetCustomerAddress?.value?.addresses[i].addressId + "," + custNRes?.GetCustomerAddress?.value?.addresses[i].storeNumber)
                  let multipleAgreementNumber: any = ""
                  for (let j = 0; j < filteredAddress?.length; j++) {
                    multipleAgreementNumber = multipleAgreementNumber == "" ? filteredAddress[j].agreementNumber : multipleAgreementNumber + "," + " " + filteredAddress[j].agreementNumber
                  }
                  inActiveAddressArray.push({
                    agreementNumber: multipleAgreementNumber,
                    agreementNumbersCount: String(filteredAddress?.length >= 1 && (multipleAgreementNumber === null || multipleAgreementNumber === "") ? "-" : filteredAddress?.length),
                    agreementDescription: filteredAddress[0].agreementDescription,
                    agreementStatus: filteredAddress[0].agreementStatus,
                    agreementStatusDesc: filteredAddress[0].agreementStatusDesc,
                    agreementId: filteredAddress[0].agreementId,
                    storeNumber: filteredAddress[0].storeNumber,
                    addressId: filteredAddress[0].addressId,
                    addressType: filteredAddress[0].addressId,
                    addressTypeDesc: filteredAddress[0].addressTypeDesc,
                    addressLine1: filteredAddress[0].addressLine1,
                    addressLine2: filteredAddress[0].addressLine2,
                    city: filteredAddress[0].city,
                    latitude: null,
                    longitude: null,
                    state: filteredAddress[0].state,
                    stateName: filteredAddress[0].stateName,
                    createdBy: filteredAddress[0].createdBy,
                    createdDate: filteredAddress[0].createdDate,
                    lastModifiedBy: filteredAddress[0].lastModifiedBy,
                    lastModifiedDate: filteredAddress[0].lastModifiedDate,
                    entAddressId: filteredAddress[0].entAddressId,
                    postalCode: filteredAddress[0].postalCode,
                    plus4: filteredAddress[0].plus4,
                    active: filteredAddress[0].active,
                    createdSource: filteredAddress[0].createdSource,
                    lastModifiedSource: filteredAddress[0].lastModifiedSource,
                    verifiedDate: filteredAddress[0].verifiedDate,
                  })
                }
              }
              setAddressHistoryArray(
                inActiveAddressArray
              );
            }
            setCustomerAddressActive(custYRes)
            if (
              custYRes?.GetCustomerAddress !== undefined &&
              custYRes?.GetCustomerAddress?.value !== undefined &&
              custYRes?.GetCustomerAddress?.value?.addresses !== undefined &&
              custYRes?.GetCustomerAddress?.value?.addresses?.length > 0
            ) {
              const filteredDeliveryAddress = custYRes?.GetCustomerAddress?.value?.addresses?.filter((val: any) => val.addressType == 'DELIV');
              setSecondaryFilterArr(filteredDeliveryAddress);
              const addressID: any = []
              // eslint-disable-next-line sonarjs/no-unused-collection
              // eslint-disable-next-line sonarjs/no-unused-collection, prefer-const
              let deliveryaddarray: any = []
              for (let i = 0; i < custYRes?.GetCustomerAddress?.value?.addresses?.length; i++) {
                if (custYRes?.GetCustomerAddress?.value?.addresses[i].addressId != null &&
                  custYRes?.GetCustomerAddress?.value?.addresses[i].addressId != "" &&
                  custYRes?.GetCustomerAddress?.value?.addresses[i].storeNumber != null &&
                  custYRes?.GetCustomerAddress?.value?.addresses[i].storeNumber != "") {
                  const filteredAddress1 = custYRes?.GetCustomerAddress?.value?.addresses?.filter((val: any) => val.addressType == 'DELIV' &&
                    val.addressId == custYRes?.GetCustomerAddress?.value?.addresses[i].addressId
                    && val.storeNumber == custYRes?.GetCustomerAddress?.value?.addresses[i].storeNumber
                  )
                  //setSecondaryFilterArr(filteredAddress1);
                  //.push(filteredAddress1);
                  console.log(filteredAddress1, "filteredAddress1");
                  if (filteredAddress1?.length > 0 && !addressID?.includes(custYRes?.GetCustomerAddress?.value?.addresses[i].addressId + "," + custYRes?.GetCustomerAddress?.value?.addresses[i].storeNumber)) {
                    addressID.push(custYRes?.GetCustomerAddress?.value?.addresses[i].addressId + "," + custYRes?.GetCustomerAddress?.value?.addresses[i].storeNumber)
                    console.log(addressID, "addressID");
                    let multipleAgreementNumber: any = ""
                    let multipleAggrementId: any = ""
                    for (let j = 0; j < filteredAddress1?.length; j++) {
                      multipleAgreementNumber = multipleAgreementNumber == "" ? filteredAddress1[j].agreementNumber : multipleAgreementNumber + ", " + filteredAddress1[j].agreementNumber
                      multipleAggrementId = multipleAggrementId == "" ? filteredAddress1[j].agreementId : multipleAggrementId + ", " + filteredAddress1[j].agreementId
                    }
                    const splitarr = multipleAgreementNumber.split(', ');
                    const splitAgrIdArr = multipleAggrementId.split(', ');
                    console.log(splitarr, 'splitarr');
                    //agreementArr.push(multipleAgreementNumber);
                    //setAggrementArr(multipleAgreementNumber);
                    //console.log(aggrementArr,'agreementArr');

                    console.log(multipleAgreementNumber, "multipleAgreementNumber");
                    console.log(multipleAggrementId, 'multipleAggrementId');
                    deliveryaddarray.push({
                      agreementNumbersCount: filteredAddress1?.length,
                      agreementNumber: multipleAgreementNumber,
                      agreementNumberArr: splitarr,
                      agreementIdArr: splitAgrIdArr,
                      agreementDescription: filteredAddress1[0].agreementDescription,
                      agreementStatus: filteredAddress1[0].agreementStatus,
                      agreementStatusDesc: filteredAddress1[0].agreementStatusDesc,
                      agreementId: multipleAggrementId,
                      addressTypeDesc: filteredAddress1[0].addressTypeDesc,
                      storeNumber: filteredAddress1[0].storeNumber,
                      addressId: filteredAddress1[0].addressId,
                      addressType: filteredAddress1[0].addressType,
                      addressLine1: filteredAddress1[0].addressLine1,
                      addressLine2: filteredAddress1[0].addressLine2,
                      city: filteredAddress1[0].city,
                      state: filteredAddress1[0].state,
                      stateName: filteredAddress1[0].stateName,
                      postalCode: filteredAddress1[0].postalCode,
                      latitude: null,
                      longitude: null,
                      createdBy: filteredAddress1[0].createdBy,
                      createdDate: filteredAddress1[0].createdDate,
                      lastModifiedBy: filteredAddress1[0].lastModifiedBy,
                      lastModifiedDate: filteredAddress1[0].lastModifiedDate,
                      entAddressId: filteredAddress1[0].entAddressId,
                      active: filteredAddress1[0].active,
                      createdSource: filteredAddress1[0].createdSource,
                      lastModifiedSource: filteredAddress1[0].lastModifiedSource,
                      verifiedDate: filteredAddress1[0].verifiedDate,
                      plus4: filteredAddress1[0].plus4,
                    })
                    console.log(deliveryaddarray, 'deleiveryaddarray');
                  }
                }
                // agreementArr = [];
              }
              setDeliveryAddressArray(deliveryaddarray);
              setUpdateDeliveryAddressArray(deliveryaddarray);
              const updatedDisableState = new Array(deliveryaddarray.length).fill(true);
              console.log(updatedDisableState, 'updatedDisableState');
              setDeliveryAddDisableArr(updatedDisableState);
              const editBtnShow = new Array(deliveryaddarray.length).fill(true);
              setEditAddBtnShowArr(editBtnShow);
            }
            /* eslint-disable no-console */
            console.log('result inside useeffect', result);
            setIsLoading(false);
            const employmentInfoOpt: exploymentInfoOpt =
              await GetEmploymentInfoOptions();
            getDayPaidOption(employmentInfoOpt);
            setDropDownLoadedEmp(true);
            // if (pageType == 'customer') {
            //   const payloadForStoreConfig: any = {
            //     storeNumbers: [sessionStorage.getItem('storeNumber')],
            //     paramKeyNames: ['Reinstatement'],
            //   };
            //   payload.storeNumbers.push(loginStore);
            //   const response: any = await storeConfigCall(
            //     payloadForStoreConfig
            //   );
            //   if (
            //     response?.data?.storeProfileResponse?.configDetails[0]
            //       ?.configDetails[0]?.paramValue == '1'
            //   ) {
            //     setstoreReinstatementAllowed(true);
            //   } else {
            //     setstoreReinstatementAllowed(false);
            //   }
            //   console.log('response storeConfigCall', response);
            // }
            // if (pageType === 'customer') {
            //   await getcustomerCoverage();
            // }
          } catch (e: any) {
            setIsLoading(false);
            /* eslint-disable no-console */
            console.log('Error in get options', e);
          }
        }
      };
      PageLoad();

      if (
        alertTypeIdQueryParams !== undefined &&
        alertTypeIdQueryParams !== null
      ) {
        ScrollToPage(alertTypeIdQueryParams, null);
      }
      setIsPrimaryCustoInfoUpdate(false);
      //setLoadAlerts(false);
      //getAlerts();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [customerId, alertTypeIdQueryParams]
  );
  console.log(inActivePhArr, "check inActivePhArr");
  console.log(addressHistoryArray, "check addressHistoryArray");

  useEffect(() => {
    setIsPrimaryCustoInfoUpdate(true);
  }, [customerInformation]);
  useEffect(() => {
    console.log(customerInf, "..customer");
    console.log(customerInf?.oktaId, "..customer1");
    if (customerInf?.oktaId != null && customerInf?.oktaId != undefined) {
      setWebLinkDisable(true)
    }
    else {
      setWebLinkDisable(false);
    }

  }, [customerInf]);
  function GetstoreReinstatementAllowed(response: any) {
    const arr: any =
      response.data?.storeProfileResponse?.configDetails[0]?.configDetails;
    arr.forEach((el: any) => {
      if (el.paramKeyName == 'Reinstatement') {
        el.paramValue == '1'
          ? setstoreReinstatementAllowed(true)
          : setstoreReinstatementAllowed(false);
      }
    });
  }
  const GetVerificationExpirationDays = async (response: any) => {
    try {
      console.log('salesLeadExp Res', response);
      if (
        response && response.data && response.data.storeProfileResponse &&
        response.data.storeProfileResponse.configDetails &&
        response.data.storeProfileResponse.configDetails[0].configDetails
      ) {
        let expiryValue = 60;
        const configData = response.data.storeProfileResponse.configDetails[0].configDetails;
        const verificationConfig = configData?.find((val: any) => val?.paramKeyName === 'Verification_Expiry_Days');
        console.log(verificationConfig, 'Verification_Expiry_Days')

        if (verificationConfig) {
          expiryValue = verificationConfig.paramValue;
        }
        console.log(expiryValue, 'expiryValue')
        setverExpDate(expiryValue);
      } else {
        setverExpDate(60);
      }
    } catch (err) {
      if (err) {
        setverExpDate(60);
      }
    }
  };
  // const getcustomerCoverage = async () => {
  //   try {
  //     const result = await GetCustomerCoverage(customerId, loginStore);
  //     /* eslint-disable no-console */
  //     console.log('result inside getOptions GetCustomerCoverage', result);
  //     setcustomerCoverageInf(
  //       result !== undefined && result.clubInfo !== undefined
  //         ? result.clubInfo
  //         : undefined
  //     );
  //   } catch (e: any) {
  //     setcustomerCoverageInf(undefined);
  //     console.log('Error in co worker role GetCustomerCoverage');
  //   }
  // };
  const getWorkerRole = async () => {
    if (containerData !== undefined) {
      console.log('containerData', containerData);
      console.log('containerDataRole', containerData.GetRole());
      setCoWorkerRole(containerData.GetRole());
      return containerData.GetRole();
    } else {
      const coWorkerRoleObj: any = await getmenuandstore();
      if (
        coWorkerRoleObj?.coworkerProfile !== undefined &&
        coWorkerRoleObj?.coworkerProfile !== null &&
        coWorkerRoleObj?.coworkerProfile.role !== undefined &&
        coWorkerRoleObj?.coworkerProfile.role !== null
      ) {
        const roleObj = coWorkerRoleObj?.coworkerProfile.role;
        setCoWorkerRole(roleObj);
        return roleObj;
      }
    }
  };
  function GetstoreTransferRequest(response: any) {
    const arr: any =
      response.data?.storeProfileResponse?.configDetails[0]?.configDetails;
    arr.forEach((el: any) => {
      if (el.paramKeyName == 'AgreementTransfer') {
        el.paramValue == '1'
          ? setstoreTransferAllowed(true)
          : setstoreTransferAllowed(false);
      }
    });
  }

  const transfergetAgreement = (response: any) => {

    if (response) {
      console.log('Agreements', response);

      const Agreements = response.agreement
      const agreementFilter = Agreements.filter((e: any) => e.storeNumber == window.sessionStorage.getItem('storeNumber'));
      console.log('agreementFilter', agreementFilter);
      if (agreementFilter.length > 0) {
        const isTransferInitiated = agreementFilter.filter((e: any) => e.isTransferInitiated == 1);
        if (isTransferInitiated.length == 0) {
          const mapperFunc = agreementFilter.every((e: any) => {
            if (['Promo', 'Rent to Rent - Loaner'].includes(e.agreementType)) {
              return false
            }
            return true
          })
          console.log('hjhjhjh', mapperFunc);

          if (mapperFunc) {
            const switchoutFilter = agreementFilter.filter((e: any) => e.switchout == 1);
            console.log('switchoutFilter', switchoutFilter);
            const isDeliveryConfirmed = agreementFilter.filter((e: any) => e.isDeliveryConfirmed == 0);
            console.log('isDeliveryConfirmed', isDeliveryConfirmed);
            const isTransferInitiated = agreementFilter.filter((e: any) => e.isTransferInitiated == 1);
            console.log('isTransferInitiated', isTransferInitiated);
            const isDeliveryOpen = agreementFilter.filter((e: any) => e.isDeliveryConfirmed == 1);
            console.log('isDeliveryOpen', isDeliveryOpen);
            if (switchoutFilter.length > 0) {
              settransferAgrContent('Customer has agreement(s) that are pending switch out')
            }
            if (isDeliveryConfirmed.length > 0 || (isDeliveryConfirmed.length > 0 && switchoutFilter.length > 0)) {
              settransferAgrContent('Customer has Out of Store Activities')
            }
            if (switchoutFilter.length == 0 && isDeliveryOpen.length == agreementFilter.length && isTransferInitiated.length == 0) {
              settransferAgr(false)
            }
          }
          else {
            settransferAgrContent('Cannot transfer promo and loaner agreements')
          }
        }
        else {
          setAgrtransferinit(true)
          settransferInitiated(true);
        }



      }
      else {
        console.log('errorinknn');
        settransferAgrContent('No active agreements')

      }

    }


  }
  const getCoWorkerRole = async (isLegalHold: boolean, roleObj: any) => {
    try {
      if (roleObj !== undefined && roleObj !== null) {
        setLegalHoldApplied(
          (roleObj == 'SM' ||
            roleObj == 'CAR' ||
            roleObj == 'AM' ||
            roleObj == 'LAM' ||
            roleObj == 'DM') &&
            isLegalHold
            ? 1
            : isLegalHold && (roleObj == 'DMT' || roleObj == 'RD')
              ? 2
              : 0
        );
        setIsSecondAuthenValid(
          roleObj == 'DMT' || roleObj == 'RD' || roleObj == 'DM' ? true : false
        );
        setcoWorkerRoleForCSpopup(
          roleObj == 'SM' ||
            roleObj == 'CAR' ||
            roleObj == 'AM' ||
            roleObj == 'LAM' ||
            roleObj == 'DMT'
            ? 1
            : roleObj == 'DM' || roleObj == 'RD'
              ? 2
              : 0
        );
        //&& (roleObj == 'DM' || roleObj == 'RD')
        if (isLegalHold && (roleObj == 'DM' || roleObj == 'RD')) {
          racCoWorkerFunc('Assign Alert');
        }
      }
    } catch (e: any) {
      setLegalHoldApplied(0);
      /* eslint-disable no-console */
      console.log('Error in co worker role', e);
    }
  };
  useEffect(() => {
    //debugger;
    if (primaryAddArr.length > 1) {
      console.log('testing3');
      const radioarr = new Array(primaryAddArr?.length).fill(false);
      setRadioArray(radioarr);
      console.log(radioarr, 'radioarr');
      setPrimAddPopUp(true);
    }

  }, [primaryAddArr])
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const getCustomerInfo = (result: any, result3: any) => {
    let CustomerInfo: any = undefined;
    if (result) {
      CustomerInfo = buildCustomerOptions(result, result3);
    }
    return CustomerInfo;
  };
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const buildCustomerOptions = (result: any, result3: any) => {
    //;
    // let address: any =
    //   result.addresses !== undefined && result.addresses !== null
    //     ? result.addresses.filter((val: any) => val.addressType == 'PRIM')
    //     : null;
    //     if(address.length > 1){
    //       console.log("testing");
    //       setPrimaryAddArr(address);
    //     }    
    // let mailingAddress: any =
    //   result.addresses !== undefined && result.addresses !== null
    //     ? result.addresses.filter((val: any) => val.addressType == 'MAIL')
    //     : null;
    // mailingAddress =
    //   mailingAddress == undefined || mailingAddress == null
    //     ? []
    //     : mailingAddress;
    // /* eslint-disable no-console */
    // console.log('result inside mailingAddress', mailingAddress);
    // if (address !== undefined && address !== null && address.length == 0) {
    //   address = mailingAddress;
    // }
    // /* eslint-enable no-console */
    // setUpdatePrimaryAddress({
    //   address:
    //     address !== undefined && address !== null && address.length > 0
    //       ? address[0].addressLine1
    //       : '',
    //   address1:
    //     address !== undefined &&
    //     address !== null &&
    //     address.length > 0 &&
    //     address[0].addressLine2 !== null &&
    //     address[0].addressLine2 !== 'null'
    //       ? address[0].addressLine2
    //       : '',
    //   city:
    //     address !== undefined && address !== null && address.length > 0
    //       ? address[0].city
    //       : '',
    //   zip:
    //     address !== undefined &&
    //     address !== null &&
    //     address.length > 0 &&
    //     address[0].postalCode !== '     -    '
    //       ? address[0].postalCode
    //       : '',
    //   state:
    //     address !== undefined && address !== null && address.length > 0
    //       ? address[0].state
    //       : '',
    // });
    // let samemailingAdddress = false;
    // // eslint-disable-next-line sonarjs/no-collapsible-if
    // if (
    //   mailingAddress !== undefined &&
    //   mailingAddress !== null &&
    //   mailingAddress.length > 0
    // ) {
    //   if (
    //     mailingAddress[0].addressLine1 == address[0].addressLine1 &&
    //     mailingAddress[0].addressLine2 == address[0].addressLine2 &&
    //     mailingAddress[0].city == address[0].city &&
    //     mailingAddress[0].postalCode == address[0].postalCode &&
    //     mailingAddress[0].state == address[0].state
    //   ) {
    //     samemailingAdddress = true;
    //   }
    // }
    // setupdateMailingAddress({
    //   address:
    //     mailingAddress !== undefined &&
    //     mailingAddress !== null &&
    //     mailingAddress.length > 0
    //       ? mailingAddress[0].addressLine1
    //       : '',
    //   address1:
    //     mailingAddress !== undefined &&
    //     mailingAddress !== null &&
    //     mailingAddress.length > 0 &&
    //     mailingAddress[0].addressLine2 !== null &&
    //     mailingAddress[0].addressLine2 !== 'null'
    //       ? mailingAddress[0].addressLine2
    //       : '',
    //   city:
    //     mailingAddress !== undefined &&
    //     mailingAddress !== null &&
    //     mailingAddress.length > 0
    //       ? mailingAddress[0].city
    //       : '',
    //   zip:
    //     mailingAddress !== undefined &&
    //     mailingAddress !== null &&
    //     mailingAddress.length > 0 &&
    //     address[0].postalCode !== '     -    '
    //       ? mailingAddress[0].postalCode
    //       : '',
    //   state:
    //     mailingAddress !== undefined &&
    //     mailingAddress !== null &&
    //     mailingAddress.length > 0
    //       ? mailingAddress[0].state
    //       : '',
    // });


    console.log(result3, 'result31');
    let address1: any =
      result3 && result3?.addresses !== undefined && result3?.addresses !== null
        ? result3.addresses.filter((val: any) => val.addressType == 'PRIM')
        : null;
    console.log(address1, 'address11');
    if (address1?.length > 1) {

      if (
        address1 !== undefined &&
        address1?.length > 0
      ) {
        const filteredDeliveryAddress = address1?.filter((val: any) => val.addressType == 'PRIM');
        setSecondaryFilterArr(filteredDeliveryAddress);
        const addressID: any = []
        // eslint-disable-next-line sonarjs/no-unused-collection
        // let agreementArr :any = []
        // eslint-disable-next-line sonarjs/no-unused-collection, prefer-const
        let primaryArray: any = []
        for (let i = 0; i < address1?.length; i++) {
          const filteredAddress1 = address1?.filter((val: any) => val.addressType == 'PRIM' &&
            val.addressId == address1[i].addressId
          )
          //setSecondaryFilterArr(filteredAddress1);
          //.push(filteredAddress1);
          console.log(filteredAddress1, "filteredAddress1");
          if (filteredAddress1?.length > 0 && !addressID?.includes(address1[i].addressId)) {
            addressID.push(address1[i].addressId)
            console.log(addressID, "addressID");
            let multipleAgreementNumber: any = ""
            let multipleAggrementId: any = ""
            let storeNumberMultiple: any = ""
            const splitStoreNumberArr: any = []
            const splitAgrIdArr: any = []
            const splitarr: any = []
            for (let j = 0; j < filteredAddress1?.length; j++) {
              // multipleAgreementNumber=multipleAgreementNumber==""?filteredAddress1[j].agreementNumber:multipleAgreementNumber+", "+filteredAddress1[j].agreementNumber
              // multipleAggrementId = multipleAggrementId==""?filteredAddress1[j].agreementId:multipleAggrementId+", "+filteredAddress1[j].agreementId
              if (filteredAddress1[j].agreementId != null && filteredAddress1[j].agreementId != "") {
                multipleAggrementId = multipleAggrementId == "" ? filteredAddress1[j].agreementId : multipleAggrementId + ", " + filteredAddress1[j].agreementId
                splitAgrIdArr.push(filteredAddress1[j].agreementId)
              }
              if (filteredAddress1[j].storeNumber != null && filteredAddress1[j].storeNumber != "" && !splitStoreNumberArr.includes(filteredAddress1[j].storeNumber)) {
                storeNumberMultiple = storeNumberMultiple == "" ? filteredAddress1[j].storeNumber : storeNumberMultiple + ", " + filteredAddress1[j].storeNumber
                splitStoreNumberArr.push(filteredAddress1[j].storeNumber)
              }
              if (filteredAddress1[j].agreementNumber != null && filteredAddress1[j].agreementNumber != "") {
                multipleAgreementNumber = multipleAgreementNumber == "" ? filteredAddress1[j].agreementNumber : multipleAgreementNumber + ", " + filteredAddress1[j].agreementNumber
                splitarr.push(filteredAddress1[j].agreementNumber)
              }
            }


            console.log(splitarr, 'splitarr');
            //agreementArr.push(multipleAgreementNumber);
            //setAggrementArr(multipleAgreementNumber);
            //console.log(aggrementArr,'agreementArr');

            console.log(multipleAgreementNumber, "multipleAgreementNumber");
            console.log(multipleAggrementId, 'multipleAggrementId');
            primaryArray.push({
              agreementNumbersCount: filteredAddress1?.length,
              agreementNumber: multipleAgreementNumber,
              storeNumberMultiple: storeNumberMultiple,
              agreementNumberArr: splitarr,
              agreementIdArr: splitAgrIdArr,
              agreementDescription: filteredAddress1[0].agreementDescription,
              agreementStatus: filteredAddress1[0].agreementStatus,
              agreementStatusDesc: filteredAddress1[0].agreementStatusDesc,
              agreementId: multipleAggrementId,
              addressTypeDesc: filteredAddress1[0].addressTypeDesc,
              storeNumber: filteredAddress1[0].storeNumber,
              addressId: filteredAddress1[0].addressId,
              addressType: filteredAddress1[0].addressType,
              addressLine1: filteredAddress1[0].addressLine1,
              addressLine2: filteredAddress1[0].addressLine2,
              city: filteredAddress1[0].city,
              state: filteredAddress1[0].state,
              stateName: filteredAddress1[0].stateName,
              postalCode: filteredAddress1[0].postalCode,
              latitude: null,
              longitude: null,
              createdBy: filteredAddress1[0].createdBy,
              createdDate: filteredAddress1[0].createdDate,
              lastModifiedBy: filteredAddress1[0].lastModifiedBy,
              lastModifiedDate: filteredAddress1[0].lastModifiedDate,
              entAddressId: filteredAddress1[0].entAddressId,
              active: filteredAddress1[0].active,
              createdSource: filteredAddress1[0].createdSource,
              lastModifiedSource: filteredAddress1[0].lastModifiedSource,
              verifiedDate: filteredAddress1[0].verifiedDate,
              plus4: filteredAddress1[0].plus4,
            })
            // console.log(deliveryaddarray,'deleiveryaddarray');
          }

        }
        setPrimaryAddArr(primaryArray);
      }


    }
    let mailingAddress1: any =
      result3 && result3?.addresses !== undefined && result3?.addresses !== null
        ? result3?.addresses.filter((val: any) => val.addressType == 'MAIL')
        : null;
    mailingAddress1 =
      mailingAddress1 == undefined || mailingAddress1 == null
        ? [] : mailingAddress1
    console.log(mailingAddress1, 'mailingAddress11');

    if (address1 !== undefined && address1 !== null && address1?.length == 0) {
      address1 = mailingAddress1;
    }

    setUpdatePrimaryAddress({
      address:
        address1 !== undefined && address1 !== null && address1.length > 0
          ? address1[0].addressLine1
          : '',
      address1:
        address1 !== undefined &&
          address1 !== null &&
          address1.length > 0 &&
          address1[0].addressLine2 !== null &&
          address1[0].addressLine2 !== 'null'
          ? address1[0].addressLine2
          : '',
      city:
        address1 !== undefined && address1 !== null && address1.length > 0
          ? address1[0].city
          : '',
      zip:
        address1 !== undefined &&
          address1 !== null &&
          address1.length > 0 &&
          address1[0].postalCode.trim() !== '-'
          ? `${address1[0].postalCode}${address1[0].plus4 ? '-' + address1[0].plus4 : ''}`
          : '',
      state:
        address1 !== undefined && address1 !== null && address1.length > 0
          ? address1[0].state
          : '',
      addressType:
        address1 !== undefined && address1 !== null && address1.length > 0
          ? address1[0].addressType
          : '',
      active:
        address1 !== undefined && address1 !== null && address1.length > 0
          ? address1[0].active
          : '',
      agreementId:
        address1 !== undefined && address1 !== null && address1.length > 0
          ? address1[0].agreementId
          : '',
      addressId:
        address1 !== undefined && address1 !== null && address1.length > 0
          ? address1[0].addressId
          : '',
      storeNumber:
        address1 !== undefined && address1 !== null && address1.length > 0
          ? address1[0].storeNumber
          : '',
    });
    let samemailingAdddress1 = false;
    if (
      mailingAddress1 !== undefined &&
      mailingAddress1 !== null &&
      mailingAddress1.length > 0
    ) {
      if (
        mailingAddress1[0].addressLine1 == address1[0].addressLine1 &&
        mailingAddress1[0].addressLine2 == address1[0].addressLine2 &&
        mailingAddress1[0].city == address1[0].city &&
        mailingAddress1[0].postalCode == address1[0].postalCode &&
        mailingAddress1[0].state == address1[0].state
      ) {
        samemailingAdddress1 = true;
      }
    }
    setupdateMailingAddress({
      address:
        mailingAddress1 !== undefined &&
          mailingAddress1 !== null &&
          mailingAddress1.length > 0
          ? mailingAddress1[0].addressLine1
          : '',
      address1:
        mailingAddress1 !== undefined &&
          mailingAddress1 !== null &&
          mailingAddress1.length > 0 &&
          mailingAddress1[0].addressLine2 !== null &&
          mailingAddress1[0].addressLine2 !== 'null'
          ? mailingAddress1[0].addressLine2
          : '',
      city:
        mailingAddress1 !== undefined &&
          mailingAddress1 !== null &&
          mailingAddress1.length > 0
          ? mailingAddress1[0].city
          : '',
      zip:
        mailingAddress1 !== undefined &&
          mailingAddress1 !== null &&
          mailingAddress1.length > 0 &&
          address1[0].postalCode.trim() !== '-'
          ? `${mailingAddress1[0].postalCode}${mailingAddress1[0].plus4 ? '-' + mailingAddress1[0].plus4 : ''}`
          : '',
      state:
        mailingAddress1 !== undefined &&
          mailingAddress1 !== null &&
          mailingAddress1.length > 0
          ? mailingAddress1[0].state
          : '',
      active:
        mailingAddress1 !== undefined && mailingAddress1 !== null && mailingAddress1.length > 0
          ? mailingAddress1[0].active
          : '',
      agreementId:
        mailingAddress1 !== undefined && mailingAddress1 !== null && mailingAddress1.length > 0
          ? mailingAddress1[0].agreementId
          : '',
      addressId:
        mailingAddress1 !== undefined && mailingAddress1 !== null && mailingAddress1.length > 0
          ? mailingAddress1[0].addressId
          : '',
      storeNumber:
        mailingAddress1 !== undefined && mailingAddress1 !== null && mailingAddress1.length > 0
          ? mailingAddress1[0].storeNumber
          : '',
    });

    const taxExempts =
      result.taxExempts !== undefined &&
        result.taxExempts !== null &&
        result.taxExempts.length > 0
        ? result.taxExempts[0]
        : null;
    settaxExSt(taxExempts);
    // const verifieddate: any = buildVerificationdate(result);
    // const verifedDate = verifieddate !== '' ? verification(verifieddate) : '';
    const CustomerInfo: any = {
      firstName: result?.firstName,
      lastName: result?.lastName,
      globalCustomerId: result?.globalCustomerId,
      dateOfBirthEnc: result?.dateOfBirthEnc,
      taxIdEnc: result?.taxIdEnc,
      governmentIdEnc: result?.governmentIdEnc,
      dateOfBirth:
        result?.dateOfBirth !== undefined &&
          result?.dateOfBirth !== null &&
          result?.dateOfBirth !== ''
          ? buildDateFormat2(result?.dateOfBirth)
          : '',
      ssn1:
        result?.taxId !== undefined && result?.taxId !== null
          ? result?.taxId.slice(0, 3)
          : '',
      ssn2:
        result?.taxId !== undefined && result?.taxId !== null
          ? result?.taxId.slice(3, 5)
          : '',
      ssn3:
        result?.taxId !== undefined && result?.taxId !== null
          ? result?.taxId.slice(5)
          : '',
      emailAddress: result?.emailAddress,
      governmentIdType: result?.governmentIdType || '',
      governmentId: result?.governmentId,
      governmentIdIssuedState: result?.governmentIdIssuedState || '',
      salutation: result?.salutation || '',
      middleInitial: result?.middleInitial || '',
      suffix: result?.suffix || '',
      racCoworker: result?.coworker,
      createdBy: result?.createdBy,
      createdDate: result?.createdDate,
      lastModifiedBy: result?.lastModifiedBy,
      lastModifiedDate: result?.lastModifiedDate,
      // address information
      addressess:
        result3 && result3?.addresses !== undefined && result3?.addresses !== null
          ? result3?.addresses
          : [],
      addressLine1Id:
        address1 !== undefined && address1 !== null && address1.length > 0
          ? address1[0].addressId
          : '',
      addressLine1:
        address1 !== undefined && address1 !== null && address1.length > 0
          ? address1[0].addressLine1
          : '',
      addressLine2:
        address1 !== undefined &&
          address1 !== null &&
          address1.length > 0 &&
          address1[0].addressLine2 !== null &&
          address1[0].addressLine2 !== 'null'
          ? address1[0].addressLine2
          : '',
      zip:
        address1 !== undefined &&
          address1 !== null &&
          address1.length > 0 &&
          address1[0].postalCode.trim() !== '-'
          ? `${address1[0].postalCode}${address1[0].plus4 ? '-' + address1[0].plus4 : ''}`
          : '',
      city:
        address1 !== undefined && address1 !== null && address1.length > 0
          ? address1[0].city
          : '',
      state:
        address1 !== undefined && address1 !== null && address1?.length > 0
          ? address1[0].state
          : '',
      addressLine1IdM:
        mailingAddress1 !== undefined &&
          mailingAddress1 !== null &&
          mailingAddress1.length > 0
          ? mailingAddress1[0].addressId
          : '',
      addressLine1M:
        mailingAddress1 !== undefined &&
          mailingAddress1 !== null &&
          mailingAddress1.length > 0
          ? mailingAddress1[0].addressLine1
          : '',
      addressLine2M:
        mailingAddress1 !== undefined &&
          mailingAddress1 !== null &&
          mailingAddress1.length > 0 &&
          mailingAddress1[0].addressLine2 !== null &&
          mailingAddress1[0].addressLine2 !== 'null'
          ? mailingAddress1[0].addressLine2
          : '',
      zipM:
        mailingAddress1 !== undefined &&
          mailingAddress1 !== null &&
          mailingAddress1.length > 0 &&
          mailingAddress1[0].postalCode.trim() !== '-'
          ? `${mailingAddress1[0].postalCode}${mailingAddress1[0].plus4 ? '-' + mailingAddress1[0].plus4 : ''}`
          : '',
      cityM:
        mailingAddress1 !== undefined &&
          mailingAddress1 !== null &&
          mailingAddress1.length > 0
          ? mailingAddress1[0].city
          : '',
      stateM:
        mailingAddress1 !== undefined &&
          mailingAddress1 !== null &&
          mailingAddress1.length > 0
          ? mailingAddress1[0].state
          : '',
      mailAddress:
        samemailingAdddress1 ||
          mailingAddress1 == undefined ||
          mailingAddress1 == null ||
          mailingAddress1.length == 0
          ? 'Y'
          : 'N',
      // tax exempt

      taxId: result?.taxId,
      status: '',
      effectiveDate: '',
      expirationDate: '',
      appDenialDate: '',
      skip: result?.skip !== undefined ? result?.skip : 'N',
      stolen: result?.stolen == 'Y' ? result?.stolen : 'N',
      hard: result?.hard == 'Y' ? result?.hard : 'N',
      federalTaxId: taxExempts !== null ? taxExempts.federalTaxId : '',
      taxExemptStatus: taxExempts !== null ? taxExempts.taxExemptStatus : '',
      submittedDate:
        taxExempts !== null &&
          taxExempts.submittedDate !== undefined &&
          taxExempts.submittedDate !== null &&
          taxExempts.submittedDate !== ''
          ? buildDateFormat2(taxExempts.submittedDate)
          : '',
      // communication preferences
      paymentRemainderCall: result?.paymentReminderCallsAllowed,
      textMessageRemainder: result?.textSolicitationAllowed,
      preferredMethod: result?.preferredContactMethod || '',
      preferredPaymentReceipt: result?.printReceiptMethod,
      preferredLanguage: result?.preferredLanguage,
      phoneSolicitationAllowed: result?.phoneSolicitationAllowed,
      emailSolicitationAllowed: result?.emailSolicitationAllowed,
      mailSolicitationAllowed: result?.mailSolicitationAllowed,
      coCustomerId: result?.coCustomerId,
      route:
        result?.customerRoutes !== undefined &&
          result?.customerRoutes !== null &&
          result?.customerRoutes.length > 0
          ? result?.customerRoutes.find((el: any) => el.storeNumber == sessionStorage.getItem('storeNumber'))?.routeCode
          : 'M',
      verified: result?.verified,
    };
    setTaxexempt(
      taxExempts !== null &&
        taxExempts?.federalTaxId !== undefined &&
        taxExempts?.federalTaxId !== null &&
        taxExempts?.federalTaxId !== ''
        ? true
        : false
    );
    setTaxIDActive(
      taxExempts !== null &&
        taxExempts?.federalTaxId !== undefined &&
        taxExempts?.federalTaxId !== null &&
        taxExempts?.federalTaxId !== ''
        ? true
        : false
    );
    setTabValues(result);
    /* eslint-enable no-console */
    // eslint-disable-next-line no-console
    console.log('CustomerInfo', CustomerInfo);
    return CustomerInfo;
  };
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const setTabValues = (result: any) => {
    // eslint-disable-next-line no-console
    console.log('residence result', result);
    // const isrented: boolean =
    //   result?.landlordReferences !== undefined &&
    //   result?.landlordReferences.length > 0 &&
    //   result?.landlordReferences[0].active == 'Y'
    //     ? true
    //     : false;
    // const isOwned: boolean =
    //   result?.residenceSince !== undefined &&
    //   result?.residenceSince !== null &&
    //   isrented === false
    //     ? true
    //     : false;
    setResidencesvalues({
      // rented: isrented,
      // owned: isOwned,
      residenceType: result.residenceType,
      mortgage:
        result?.mortgageCompanyName !== undefined &&
          result?.mortgageCompanyName !== null &&
          result?.mortgageCompanyName !== '' &&
          // result?.residenceSince !== undefined &&
          // result?.residenceSince !== null &&
          result.residenceType != 'RENT'
          ? true
          : false,
      mortgageCompanyName:
        result?.mortgageCompanyName !== undefined &&
          result?.mortgageCompanyName !== null &&
          result.residenceType != 'RENT'
          ? result?.mortgageCompanyName
          : '',
      monthlyMortgagePayment:
        result?.monthlyMortgagePayment !== undefined &&
          result?.monthlyMortgagePayment !== null &&
          result.residenceType != 'RENT'
          ? parseFloat(result?.monthlyMortgagePayment)
            .toFixed(2)
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          : '0.00',
      landlordValue:
        result?.landlordReferences !== undefined &&
          result?.residenceType == 'RENT' &&
          result?.landlordReferences.length > 0
          ? result?.landlordReferences
          : undefined,
      residenceSince:
        result?.residenceSince !== undefined &&
          result?.residenceSince !== null &&
          result.residenceType != 'RENT'
          ? result?.residenceSince
          : '',
      landlordFirstName:
        result?.landlordReferences !== undefined &&
          result?.residenceType == 'RENT' &&
          result?.landlordReferences.length > 0
          ? result?.landlordReferences[0].landlordFirstName
          : '',
      landlordLastName:
        result?.landlordReferences !== undefined &&
          result?.residenceType == 'RENT' &&
          result?.landlordReferences.length > 0
          ? result?.landlordReferences[0].landlordLastName
          : '',
      moveInDate:
        result?.landlordReferences !== undefined &&
          result?.residenceType == 'RENT' &&
          result?.landlordReferences.length > 0
          ? result?.landlordReferences[0].moveInDate
          : '',
      monthlyRent:
        result?.landlordReferences !== undefined &&
          result?.residenceType == 'RENT' &&
          result?.landlordReferences.length > 0
          ? parseFloat(result?.landlordReferences[0].monthlyRent)
            .toFixed(2)
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          : '0.00',
      phoneNumber:
        result?.landlordReferences !== undefined &&
          result?.residenceType == 'RENT' &&
          result?.landlordReferences.length > 0
          ? result?.landlordReferences[0].phoneNumber
          : '',
      // verifiedCheckBox:
      //   result?.landlordReferences !== undefined &&
      //   result?.landlordReferences.length > 0
      //     ? result?.landlordReferences[0].verifiedDate
      //     : null,
      verifiedCheckBox:
        result?.landlordReferences !== undefined &&
          result?.residenceType == 'RENT' &&
          result?.landlordReferences.length > 0
          ? result?.landlordReferences[0].verifiedDate
          : null,
      ownVerifieddate:
        result?.ownVerifiedDate !== undefined &&
          result?.ownVerifiedDate !== null &&
          result?.ownVerifiedDate !== ''
          ? result?.ownVerifiedDate
          : null,
    });
    setReferenceProps(
      result?.personalReferences !== undefined &&
        result?.personalReferences !== null &&
        result?.personalReferences.length > 0
        ? buildRefernceLoad(result?.personalReferences)
        : [
          {
            personalReferenceId: '',
            verifiedDate: '',
            firstName: '',
            lastName: '',
            phoneNumber: '',
            relationshipType: '',
            relationshipTypeDesc: '',
            bestTimeToCall: '',
            addressLine1: '',
            addressLine2: '',
            postalCode: '',
            city: '',
            state: '',
            active: 'Y',
            doNotCall: '',
          },
        ]
    );

    setEmpProps(
      result?.employerReferences !== undefined &&
        result?.employerReferences !== null &&
        result?.employerReferences.length > 0
        ? empInfoTakeHomeHandle(result?.employerReferences)
        : [
          {
            employerReferenceId: '',
            employerName: '',
            takeHomePay: '0.00',
            supervisorFirstName: '',
            supervisorLastName: '',
            jobTitle: '',
            employerPhoneNumber: '',
            employerPhoneExtension: null,
            addressLine1: '',
            addressLine2: '',
            postalCode: '',
            city: '',
            state: '',
            doNotCall: 'N',
            employmentBeginDate: '2021-01-01',
            workStartTime: null,
            workEndTime: null,
            lengthOfEmploymentYears: '0',
            employerPayschedule: '',
            employmentEndDate: null,
            daysPaid: '',
            doNotVisit: 'N',
            active: 'Y',
            verifiedDate: '',
          },
        ]
    );
    //buildEmpDayPaidOptions(result);
    const VechiclesValue: any =
      result?.vehicles !== undefined &&
        result?.vehicles !== null &&
        result?.vehicles.length > 0
        ? result?.vehicles
        : [];
    if (VechiclesValue.length > 0) {
      for (let i = 0; i < VechiclesValue.length; i++) {
        VechiclesValue[i].vehMonthlyPayment =
          VechiclesValue[i].vehMonthlyPayment !== undefined &&
            VechiclesValue[i].vehMonthlyPayment !== null &&
            VechiclesValue[i].vehMonthlyPayment.length > 0
            ? parseFloat(VechiclesValue[i].vehMonthlyPayment)
              .toFixed(2)
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            : '0.00';
        VechiclesValue[i].vehPlanExpirationDate =
          VechiclesValue[i].vehPlanExpirationDate !== undefined &&
            VechiclesValue[i].vehPlanExpirationDate !== null &&
            VechiclesValue[i].vehPlanExpirationDate !== ''
            ? buildDateZReplace(VechiclesValue[i].vehPlanExpirationDate, 'V')
            : '';
      }
    }
    setVehiclevalues(
      VechiclesValue.length > 0
        ? VechiclesValue
        : [
          {
            customerVehicleId: '',
            vehicleMake: null,
            vehicleModel: null,
            vehicleYear: null,
            vehicleColor: null,
            vehicleVin: null,
            vehicleLicensePlate: null,
            vehRegState: null,
            vehPlanExpirationDate: null,
            vehFinancedFrom: null,
            vehMonthlyPayment: null,
            vehicleIndex: null,
          },
        ]
    );
    // setTaxIDActive(
    //   result?.federalTaxId !== undefined &&
    //     result?.federalTaxId !== null &&
    //     result?.federalTaxId !== ''
    //     ? true
    //     : false
    // );
  };
  const focusDatePicker = () => {
    (document.getElementById('DateOfBirth') as HTMLInputElement).focus();
  };
  const empInfoTakeHomeHandle = (ObjEmp: any) => {
    if (ObjEmp !== undefined && ObjEmp !== null) {
      for (let i = 0; i < ObjEmp.length; i++) {
        ObjEmp[i].takeHomePay =
          ObjEmp[i].takeHomePay !== undefined &&
            ObjEmp[i].takeHomePay !== null &&
            ObjEmp[i].takeHomePay.length > 0
            ? parseFloat(ObjEmp[i].takeHomePay)
              .toFixed(2)
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            : '0.00';
        ObjEmp[i].lengthOfEmploymentYears =
          ObjEmp[i].lengthOfEmploymentYears !== undefined &&
            ObjEmp[i].lengthOfEmploymentYears !== null &&
            ObjEmp[i].lengthOfEmploymentYears !== '' &&
            ObjEmp[i].lengthOfEmploymentYears !== 'NaN'
            ? ObjEmp[i].lengthOfEmploymentYears
            : '0';
        ObjEmp[i].verifiedDate =
          checkVerifyDate(ObjEmp[i].verifiedDate) === false
            ? ''
            : ObjEmp[i].verifiedDate;
        ObjEmp[i].employerPayschedule =
          ObjEmp[i].employerPayschedule == 'NONE'
            ? ''
            : ObjEmp[i].employerPayschedule;
        ObjEmp[i].postalCode = ObjEmp[i]?.postalCode?.trim() || '';
        ObjEmp[i].postalCode = ObjEmp[i]?.postalCode?.trim().length == 9 ? ObjEmp[i]?.postalCode.slice(0, 5) + '-' + ObjEmp[i]?.postalCode.slice(5) : ObjEmp[i]?.postalCode?.trim() || '';
        // ObjEmp[i].daysPaid =
        //   ObjEmp[i].daysPaid !== null ? ObjEmp[i].employerPayschedule : '';
      }
    }
    return ObjEmp;
  };
  //eslint-disable-next-line sonarjs/cognitive-complexity
  const createApprovalBuild = (result: any) => {
    const approvalValue: any =
      result.GetApproval?.value !== undefined &&
        result.GetApproval?.value.customerApprovalDetails !== undefined
        ? result.GetApproval?.value.customerApprovalDetails
        : null;
    // eslint-disable-next-line no-console
    console.log('approvalValue', approvalValue);
    const CustomerDEInfo: any = {
      weeklyApprovalAmount:
        approvalValue !== null &&
          approvalValue?.weeklyApprovalAmount !== undefined &&
          approvalValue?.weeklyApprovalAmount !== null &&
          approvalValue?.weeklyApprovalAmount !== ''
          ? approvalValue?.weeklyApprovalAmount
          : null,
      originalApprovalAmount:
        approvalValue !== null &&
          approvalValue?.approvalAmount !== undefined &&
          approvalValue?.approvalAmount !== null &&
          approvalValue?.approvalAmount !== ''
          ? approvalValue?.approvalAmount
          : null,
      approvalExpirationDate:
        approvalValue !== null &&
          approvalValue?.approvalExpirationDate !== undefined &&
          approvalValue?.approvalExpirationDate !== null &&
          approvalValue?.approvalExpirationDate !== ''
          ? approvalValue?.approvalExpirationDate
          : null,
      approvalStatus:
        approvalValue !== null &&
          approvalValue?.approvalStatus !== undefined &&
          approvalValue?.approvalStatus !== null &&
          approvalValue?.approvalStatus !== ''
          ? approvalValue?.approvalStatus == 'E'
            ? DECISON_ENGINE_EXPIRED
            : approvalValue?.approvalStatus == 'M'
              ? DECISION_ENGINE_PREAPPROVED
              : approvalValue?.approvalStatus == 'D'
                ? DECISON_ENGINE_DECLINED
                : approvalValue?.approvalStatus == 'A'
                  ? DECISION_ENGINE_APPROVED
                  : NO_DECISION_ENGINE
          : NO_DECISION_ENGINE,
    };

    // eslint-disable-next-line no-console
    console.log('approvalExpirationDate', CustomerDEInfo);

    return CustomerDEInfo;
  };
  const getlegalHold = async () => {
    let isLeaglHold = false;
    try {
      const getLegalHold = await GetLegalSatatus(customerId);
      /* eslint-disable no-console */
      console.log('getLegalHold', getLegalHold);

      setLegalHold(
        getLegalHold !== undefined &&
          getLegalHold !== null &&
          getLegalHold?.legalHoldStatusRes !== undefined &&
          getLegalHold?.legalHoldStatusRes !== null &&
          getLegalHold?.legalHoldStatusRes.length > 0
          ? getLegalHold.legalHoldStatusRes[0]
          : null
      );
      isLeaglHold =
        getLegalHold !== undefined &&
          getLegalHold !== null &&
          getLegalHold?.legalHoldStatusRes !== undefined &&
          getLegalHold?.legalHoldStatusRes !== null &&
          getLegalHold?.legalHoldStatusRes.length > 0
          ? getLegalHold.legalHoldStatusRes[0].legalHoldStatus == 'true' ||
            getLegalHold.legalHoldStatusRes[0].legalHoldStatus === true
            ? true
            : false
          : false;
      setIsLegalHold(isLeaglHold);
    } catch (e: any) {
      setLegalHold(null);
      /* eslint-disable no-console */
      console.log('getLegalHold', e);
    }
    return isLeaglHold;
  };
  const activateCLub = async () => {
    try {
      const payload: any = {
        isCreate: 0,
        customerId: Number(customerId),
        paymentSchedule: null,
        agreementId: null,
        clubStatusRefCode: 'C',
      };
      hideButtons();
      const response: any = await GetActivateClub(payload);
      // eslint-disable-next-line no-console
      setcustomerCoverageInf({
        ...customerCoverageInf,
        clubStatus: 'D',
      });
      console.log('activateCLub', response);
    } catch (e: any) {
      hideButtons();
      // eslint-disable-next-line no-console
      console.log('activateCLub', e);
    }
  };
  const buildValidData = (dataObj: any) => {
    return dataObj !== undefined && dataObj !== null && dataObj !== ''
      ? dataObj
      : null;
  };
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const buildRefernceLoad = (refernce: any) => {
    const referenceObj: any = [];
    if (refernce !== undefined && refernce !== null && refernce.length > 0) {
      refernce.forEach((element: any) => {
        console.log('buildRefernceLoad', element);
        referenceObj.push({
          personalReferenceId: element?.personalReferenceId,
          verifiedDate:
            checkVerifyDate(element?.verifiedDate) === false
              ? ''
              : element?.verifiedDate,
          firstName: buildValidData(element?.firstName),
          lastName: buildValidData(element?.lastName),
          phoneNumber: buildValidData(element?.phoneNumber),
          relationshipType: buildValidData(element?.relationshipType),
          relationshipTypeDesc: element?.relationshipTypeDesc,
          bestTimeToCall: buildValidData(element?.bestTimeToCall),
          addressLine1: buildValidData(element?.addressLine1),
          addressLine2: buildValidData(element?.addressLine2),
          postalCode: element?.postalCode?.trim().length == 9 ? element?.postalCode?.slice(0, 5) + '-' + element?.postalCode?.slice(5) : element?.postalCode?.trim() || '',
          city: buildValidData(element?.city),
          state: element?.state !== '0' ? buildValidData(element?.state) : null,
          active: element?.active,
          doNotCall: element?.doNotCall,
        });
      });
    }
    return referenceObj;
  };
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const validateUpdateCustomer = () => {
    let number: any = 0;
    for (let i = 0; i < phoneNumberProps.length; i++) {
      if (
        phoneNumberProps[i].primary === 'Y' &&
        phoneNumberProps.length > 1) {
        number = number + 1;
      }
    }

    const validateResultObj: any = [];
    let isValid = true;
    if (
      (legalHoldApplied !== 1 && isLegalHold !== true) ||
      (isLegalHold && coWorkerRoleForCSpopup == 2)
    ) {
      let firstTabValid =
        validateTabOne() === true && validateCustomerTab() === true
          ? true
          : false;
      if (firstTabValid !== false) {
        const SSN: any =
          customerInformation.ssn1 +
            customerInformation.ssn2 +
            customerInformation.ssn3 ==
            ''
            ? null
            : customerInformation.ssn1 +
            customerInformation.ssn2 +
            customerInformation.ssn3;
        firstTabValid =
          SSN != undefined && SSN != null && SSN != ''
            ? SSN.length < 9
              ? false
              : true
            : true;
      }
      if (firstTabValid === false) {
        validateResultObj.push('Customer Information');
        isValid = false;
      }
      if (validateTabTwo().isValidate === false) {
        validateResultObj.push('Employment Information');
        isValid = false;
      }
      if (validateTabThree().isValidate === false) {
        validateResultObj.push('Residence');
        isValid = false;
      }
      if (validateTabFour().isValidate === false) {
        validateResultObj.push('Reference');
        isValid = false;
      }
      setValidateResult(validateResultObj);
    }



    if (isValid) {

      const tcpaFlag = checkTCPAFlags(phoneNumberProps);
      if (tcpaFlag) {
        setTcpaPopup(true);
      }
      else {
        //
        setisrequired(false);
        //setisPrimary(true);
        updateCustomerInfo();
      }
    }
    else if (number > 1) {
      setActivePopUp11(true);
    }
    else {
      setErrorPopup(true);
      setisrequired(true);
    }
  };

  const onClickSavePrimaryAddress = async () => {
    let agreementOtherPrimaryCount = 0
    for (let i = 0; i < primaryAddArr?.length; i++) {
      if (primaryAddArr[i].agreementIdArr?.length > 0 && String(primaryAddArr[i].addressId) != String(primaryAddArr[Number(primaryAddressIndex)].addressId)) {
        // setMultipleAgreementPrimaryAddress(true)
        agreementOtherPrimaryCount++
      }
    }
    setPrimAddPopUp(false)
    if (Number(agreementOtherPrimaryCount) > 0) {
      setMultipleAgreementPrimaryAddress(true)
    } else (
      onClickPrimaryAddressSave(undefined)
    )
  }

  const redirectionToCustomersearch = () => {
    const request: any = searchValCustomerEditor;
    request.cancelClick = false;
    setSearchValCustomerEditor(request);
    history.push({
      pathname: `/customer/searchcustomer`,
    });
  };




  const onClickPrimaryAddressSave = async (type: any) => {

    //  const primaryAddressFiltered = primaryAddArr[primaryAddressIndex]

    const allAddressDataActive: any = []

    const primarySavePayload: any = []

    const primDeliveAddressId = []

    let active = "Y"

    let addressType = "PRIM"
    for (let j = 0; j < primaryAddArr?.length; j++) {
      let selectedPrimaryAddress = 0

      if (String(primaryAddArr[j].addressId) == String(primaryAddArr[Number(primaryAddressIndex)].addressId)) {

        selectedPrimaryAddress = 1

      } else {

        if (primaryAddArr[j].agreementIdArr?.length > 0 && selectedPrimaryAddress == 0) {

          if (type == "No") {

            active = "Y"

            addressType = "DELIV"


          } else if (type == "Yes") {
            active = "N"

            addressType = "PRIM"

            primaryAddArr[Number(primaryAddressIndex)].agreementIdArr = [...primaryAddArr[Number(primaryAddressIndex)].agreementIdArr, ...primaryAddArr[j].agreementIdArr]

            primaryAddArr[j].agreementIdArr = []
          }

        } else {

          active = "N"

          addressType = "PRIM"

          primaryAddArr[j].agreementIdArr = []

        }

      }

    }

    for (let i = 0; i < primaryAddArr?.length; i++) {

      active = "Y"

      addressType = "PRIM"

      let selectedPrimaryAddress = 0

      if (String(primaryAddArr[i].addressId) == String(primaryAddArr[Number(primaryAddressIndex)].addressId)) {

        selectedPrimaryAddress = 1

      } else {

        if (primaryAddArr[i].agreementIdArr?.length > 0 && selectedPrimaryAddress == 0) {

          if (type == "No") {

            active = "Y"

            addressType = "DELIV"
          } else if (type == "Yes") {
            active = "N"

            addressType = "PRIM"
          }

        } else {

          active = "N"

          addressType = "PRIM"

        }

      }




      primarySavePayload.push({

        "addressType": addressType,

        "active": active,

        "agreementIds": primaryAddArr[i].agreementIdArr,

        "addressId": primaryAddArr[i].addressId

      })

      primDeliveAddressId.push(primaryAddArr[i].addressId)




    }

    if (

      customerAddressActive?.GetCustomerAddress !== undefined &&

      customerAddressActive?.GetCustomerAddress?.value !== undefined &&

      customerAddressActive?.GetCustomerAddress?.value?.addresses !== undefined &&

      customerAddressActive?.GetCustomerAddress?.value?.addresses?.length > 0

    ) {

      const filteredDeliveryAddress = customerAddressActive?.GetCustomerAddress?.value?.addresses

      setSecondaryFilterArr(filteredDeliveryAddress);

      const addressID: any = []

      // eslint-disable-next-line sonarjs/no-unused-collection

      // let agreementArr :any = []

      // eslint-disable-next-line sonarjs/no-unused-collection, prefer-const



      for (let i = 0; i < customerAddressActive?.GetCustomerAddress?.value?.addresses?.length; i++) {

        const filteredAddress1 = customerAddressActive?.GetCustomerAddress?.value?.addresses?.filter((val: any) =>
          val.addressId == customerAddressActive?.GetCustomerAddress?.value?.addresses[i].addressId
        )
        console.log(filteredAddress1, "filteredAddress1");
        if (filteredAddress1?.length > 0 && !addressID?.includes(customerAddressActive?.GetCustomerAddress?.value?.addresses[i].addressId)) {
          addressID.push(customerAddressActive?.GetCustomerAddress?.value?.addresses[i].addressId)
          console.log(addressID, "addressID");
          let multipleAgreementNumber: any = ""
          let multipleAggrementId: any = ""
          const splitarr: any = []
          const splitAgrIdArr: any = []
          for (let j = 0; j < filteredAddress1?.length; j++) {
            multipleAgreementNumber = multipleAgreementNumber == "" ? filteredAddress1[j].agreementNumber : multipleAgreementNumber + ", " + filteredAddress1[j].agreementNumber
            multipleAggrementId = multipleAggrementId == "" ? filteredAddress1[j].agreementId : multipleAggrementId + ", " + filteredAddress1[j].agreementId
            splitarr.push(filteredAddress1[j].agreementNumber)
            splitAgrIdArr.push(filteredAddress1[j].agreementId)
          }

          console.log(splitarr, 'splitarr');
          console.log(multipleAgreementNumber, "multipleAgreementNumber");
          console.log(multipleAggrementId, 'multipleAggrementId');
          allAddressDataActive.push({
            agreementNumbersCount: filteredAddress1?.length,
            agreementNumber: multipleAgreementNumber,
            agreementNumberArr: splitarr,
            agreementIdArr: splitAgrIdArr,
            agreementDescription: filteredAddress1[0].agreementDescription,
            agreementStatus: filteredAddress1[0].agreementStatus,
            agreementStatusDesc: filteredAddress1[0].agreementStatusDesc,
            agreementId: multipleAggrementId,
            addressTypeDesc: filteredAddress1[0].addressTypeDesc,
            storeNumber: filteredAddress1[0].storeNumber,
            addressId: filteredAddress1[0].addressId,
            addressType: filteredAddress1[0].addressType,
            addressLine1: filteredAddress1[0].addressLine1,
            addressLine2: filteredAddress1[0].addressLine2,
            city: filteredAddress1[0].city,
            state: filteredAddress1[0].state,
            stateName: filteredAddress1[0].stateName,
            postalCode: filteredAddress1[0].postalCode,
            latitude: null,
            longitude: null,
            createdBy: filteredAddress1[0].createdBy,
            createdDate: filteredAddress1[0].createdDate,
            lastModifiedBy: filteredAddress1[0].lastModifiedBy,
            lastModifiedDate: filteredAddress1[0].lastModifiedDate,
            entAddressId: filteredAddress1[0].entAddressId,
            active: filteredAddress1[0].active,
            createdSource: filteredAddress1[0].createdSource,
            lastModifiedSource: filteredAddress1[0].lastModifiedSource,
            verifiedDate: filteredAddress1[0].verifiedDate,
            plus4: filteredAddress1[0].plus4,
          })
          console.log(allAddressDataActive, 'deleiveryaddarray');
        }
      }
    }
    // for(let j=0;j<allAddressDataActive?.length;j++){
    // if(!primDeliveAddressId.includes(allAddressDataActive[j]?.addressId)){
    //  primarySavePayload.push({
    // "city": allAddressDataActive[j].city,
    // "addressType":allAddressDataActive[j].addressType,
    //  "postalCode": allAddressDataActive[j].postalCode,
    //  "active": Number(allAddressDataActive[j].active)==1?"Y":"N",
    // "addressLine1": allAddressDataActive[j].addressLine1,
    // "addressLine2":  allAddressDataActive[j].addressLine2,
    // "state": allAddressDataActive[j].state,
    // "agreementIds": allAddressDataActive[j].agreementIdArr,
    // "addressId":  allAddressDataActive[j].addressId
    //  })
    //  }
    // }
    //let UpdateCustomerRequestForLegalHoldCustomer: any = [];
    const UpdateCustomerRequest = {
      customerId: customerId,
      addresses: primarySavePayload
    };
    setIsLoading(true);
    setPrimAddPopUp(false)
    setMultipleAgreementPrimaryAddress(false)
    const result = await UpdateAddressInfo(UpdateCustomerRequest);
    setIsLoading(false);
    if (result?.updateAddress?.value?.customerId !== undefined) {
      setPrimAddPopUp(false)
      setPrimaryAddressUpdated(true)
    } else {
      setShowUpdatePopUp(true)
      setPrimAddPopUp(false)
    }
  }

  const renderPrimaryAddress = () => {
    return (
      <>
        <Grid style={{ marginLeft: '32%', fontFamily: 'OpenSans-bold', fontSize: 'medium' }}>Customer is having multiple primary address.</Grid>
        <Grid style={{ marginLeft: '31%', fontFamily: 'OpenSans-bold', fontSize: 'medium' }}>Please select any one of the address as primary</Grid>
        <Grid>
          <RACTable
            renderTableHead={renderHeadPrimAddress}
            renderTableContent={() => renderBodyPrimAddress(primaryAddArr)}
          />
        </Grid>
        <Grid style={{ marginTop: '3%' }}>
          <RACButton
            variant="outlined"
            color="inherit"
            style={{ marginLeft: '75%', padding: '8px 26px' }}
            onClick={() => redirectionToCustomersearch()}
          // paddingTop:'8px' , paddingBottom:'8px' , paddingLeft:'2%' , paddingRight:'2%'
          >
            Cancel
          </RACButton>
          <RACButton
            variant="contained"
            color="primary"
            className={classes.me1}
            style={{ float: 'right', marginRight: '2%', padding: '8px 20px' }}
            disabled={!Number(primaryAddressIndex >= 0)}
            onClick={() => onClickSavePrimaryAddress()}
          >
            Save
          </RACButton>
        </Grid>
      </>
    )
  }
  const renderHeadPrimAddress = () => (
    <>
      {headerPrimAdd.map((val: any, index: any) => {
        if (val !== 'Radio') {
          return (
            <RACTableCell
              style={{ borderBottomStyle: 'groove' }}
              key={index}
            >
              {val}
            </RACTableCell>
          )
        }
        else {
          return (
            <RACTableCell
              style={{ borderBottomStyle: 'groove' }}
              key={index}
            >

            </RACTableCell>
          )
        }

      })}
      {/* <RACTableCell></RACTableCell>
    <RACTableCell style={{paddingRight:'1%'}}>Address Line 1</RACTableCell>
    <RACTableCell style={{marginLeft: '1%'}}>Address Line 2</RACTableCell>
    <RACTableCell>Zip</RACTableCell>
    <RACTableCell>City</RACTableCell>
    <RACTableCell>State</RACTableCell>
    </> */}
    </>
  )

  const buildDeliveryAddress = () => {
    //debugger;
    return deliveryAddressArray.map((val: any, index: any) => {
      //debugger;
      return (
        <Grid
          container
          spacing={2}
          className={customerStyleClasses.ps2}
          key={index}
        >
          {/* <Grid item md={12} className={classes.mt3}>
              <Typography className={classes.title}>
                Delivery Address
              </Typography>
        </Grid>   */}

          <Grid item md={12} className={classes.mt3}>
            <Grid style={{ display: "flex", flexWrap: "wrap", marginTop: '-20px', marginBottom: '-14px' }} >
              <Grid style={{ display: "flex", flexWrap: "wrap" }}>
                <Typography className={classes.title} style={{ marginRight: '240px' }}>
                  Store # : {val.storeNumber}
                </Typography>
              </Grid>
              <Grid style={{ display: "flex", flexWrap: "wrap" }}>
                <Typography className={classes.title}>
                  Agreement # :
                  {val?.agreementNumberArr.map((val1: any, id: any) => {
                    // console.log(val,'val11');
                    // {val?.agreementIdArr.map((val2: any) => {
                    return (
                      // eslint-disable-next-line react/jsx-key
                      <a className={`${customerStyleClasses.racpadLink} ${classes.title}`}
                        //onClick={() => agrNumOnClick(id)}
                        //id={val?.agreementIdArr[id]}
                        href={`/agreement/info/details/${customerId}/${val?.agreementIdArr[id]}`}
                      //value={val}
                      >{val?.agreementNumberArr?.length > 1 ? val1 + ', ' : val1}</a>
                    );
                    // })}
                    //debugger;
                    // return(
                    // // eslint-disable-next-line react/jsx-key
                    // <a className={`${customerStyleClasses.racpadLink} ${classes.title}`}
                    // onClick={() => agrNumOnClick(val)}
                    // id={val}
                    // href={`/agreement/info/details/${customerId}/${val}`}
                    // //value={val}
                    // >{val?.agreementNumberArr?.length>1 ? val : val+', '}</a>
                    // );
                  })}

                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={3} xl={2}>
            <RACTextbox
              isCurrency={false}
              required={true}
              type={'text'}
              id="DeliveryAddressField"
              inputlabel={addressLine1}
              name={`AddressLine1D_${index}`}
              placeHolder={addressLine1}
              OnChange={(e: any) => handleChangeForDeliveryAddress(e)}
              maxlength={30}
              value={
                //  editInputBoxM === true
                //    ? customerInformation?.addressLine1M
                //    : updateMailingAddress.address
                val?.addressLine1
              }
              disabled={deliveryAddDisableArr[index]}
              errorMessage={
                //  editInputBoxM &&
                //  (customerInformation?.addressLine1M ==
                //    undefined ||
                //    customerInformation?.addressLine1M ==
                //      null ||
                //    customerInformation?.addressLine1M ==
                //      '') &&
                //  isrequired === true
                //    ? FIELD_ERROR
                //    : (updateMailingAddress.address ==
                //        undefined ||
                //        updateMailingAddress.address ==
                //          null ||
                //        updateMailingAddress.address ==
                //          '') &&
                //      (validateAddress == 'B' ||
                //        validateAddress == 'M')
                //    ? FIELD_ERROR
                //    : ''
                // eslint-disable-next-line sonarjs/no-redundant-boolean
                isrequired === true && editAddBtnShowArr[index] == false &&
                  (deliveryAddressArray[index].addressLine1 == undefined ||
                    deliveryAddressArray[index].addressLine1 == null ||
                    deliveryAddressArray[index].addressLine1 == ''
                  )
                  ? FIELD_ERROR : ''
                // (updateDeliveryAddressArray[index].addressLine1 == undefined ||
                // updateDeliveryAddressArray[index].addressLine1 == null ||
                // updateDeliveryAddressArray[index].addressLine1 == '') 
                // ? FIELD_ERROR : ''

              }
            />
          </Grid>
          <Grid item lg={3} xl={2}>
            <RACTextbox
              isCurrency={false}
              required={false}
              type={'text'}
              inputlabel="Address Line 2"
              name={`AddressLine2D_${index}`}
              infoIcon={true}
              infoTitle={addressLine2}
              placeHolder={addressLine2}
              OnChange={(e: any) => handleChangeForDeliveryAddress(e)}
              maxlength={30}
              value={
                //  editInputBoxM === true
                //    ? customerInformation?.addressLine2M
                //    : updateMailingAddress.address1
                val?.addressLine2
              }
              disabled={deliveryAddDisableArr[index]}
            //disabled={editInputBoxM}
            />
          </Grid>
          <Grid item lg={3} xl={2}>
            <RACTextbox
              isCurrency={false}
              required={true}
              type={'text'}
              inputlabel="Zip"
              name={`ZipD_${index}`}
              placeHolder="Zip Code"
              OnChange={(e: any) => handleChangeForDeliveryAddress(e)}
              maxlength={10}
              value={
                //  editInputBoxM === true
                //    ? customerInformation?.zipM
                //    : updateMailingAddress.zip
                val?.postalCode
              }
              disabled={deliveryAddDisableArr[index]}
              //disabled={editInputBoxM}
              errorMessage={
                //  editInputBoxM
                //    ? (customerInformation?.zipM ==
                //        undefined ||
                //        customerInformation?.zipM == null ||
                //        customerInformation?.zipM == '') &&
                //      isrequired === true
                //      ? FIELD_ERROR
                //      : customerInformation?.zipM.length >
                //          0 &&
                //        customerInformation?.zipM.length !==
                //          5 &&
                //        customerInformation?.zipM.length !==
                //          10 &&
                //        isrequired === true
                //      ? INVALID_ZIP
                //      : ''
                //    : (updateMailingAddress.zip ==
                //        undefined ||
                //        updateMailingAddress.zip == null ||
                //        updateMailingAddress.zip == '') &&
                //      (validateAddress == 'B' ||
                //        validateAddress == 'M')
                //    ? FIELD_ERROR
                //    : updateMailingAddress.zip.length > 0 &&
                //      updateMailingAddress.zip.length !==
                //        5 &&
                //      updateMailingAddress.zip.length !== 10
                //    ? INVALID_ZIP
                //    : ''
                // eslint-disable-next-line sonarjs/no-redundant-boolean
                isrequired === true && editAddBtnShowArr[index] == false &&
                  ((deliveryAddressArray[index].postalCode == undefined ||
                    deliveryAddressArray[index].postalCode == null ||
                    deliveryAddressArray[index].postalCode == "")
                  ) ? FIELD_ERROR : ''
              }
            />
          </Grid>
          <Grid item lg={3} xl={2}>
            <RACTextbox
              isCurrency={false}
              required={true}
              type={'text'}
              inputlabel="City"
              name={`CityD_${index}`}
              placeHolder="City"
              OnChange={(e: any) => handleChangeForDeliveryAddress(e)}
              maxlength={30}
              value={
                //  editInputBoxM === true
                //    ? customerInformation?.cityM
                //    : updateMailingAddress.city
                val?.city
              }
              disabled={deliveryAddDisableArr[index]}
              //disabled={editInputBoxM}
              errorMessage={
                //  editInputBoxM
                //    ? (customerInformation?.cityM ==
                //        undefined ||
                //        customerInformation?.cityM ==
                //          null ||
                //        customerInformation?.cityM == '') &&
                //      isrequired === true
                //      ? FIELD_ERROR
                //      : ''
                //    : (updateMailingAddress.city ==
                //        undefined ||
                //        updateMailingAddress.city == null ||
                //        updateMailingAddress.city == '') &&
                //      (validateAddress == 'B' ||
                //        validateAddress == 'M')
                //    ? FIELD_ERROR
                //    : ''
                // eslint-disable-next-line sonarjs/no-redundant-boolean
                isrequired === true && editAddBtnShowArr[index] == false &&
                  (deliveryAddressArray[index].city == undefined ||
                    deliveryAddressArray[index].city == null ||
                    deliveryAddressArray[index].city == ''
                  ) ? FIELD_ERROR : ''
              }
            />
          </Grid>
          <Grid item lg={3} xl={2}>
            <RACSelect
              inputLabel="State"
              options={state}

              defaultValue={
                //  editInputBoxM === true
                //    ? customerInformation?.stateM !==
                //        undefined &&
                //      customerInformation?.stateM !==
                //        null &&
                //      customerInformation?.stateM !== ''
                //      ? customerInformation?.stateM
                //      : '0'
                //    : updateMailingAddress.state
                deliveryAddressArray[index].state !== undefined &&
                  deliveryAddressArray[index].state !== null &&
                  deliveryAddressArray[index].state !== '' ?
                  deliveryAddressArray[index].state : '0'
              }
              isDisabled={deliveryAddDisableArr[index]}
              required={true}
              loading={
                state.length == 0 && !dropDownLoaded
                  ? true
                  : false
              }
              onChange={(e: any) => {
                e.target.name = `AddressInfoStateD_${index}`;
                handleChangeForDeliveryAddress(e);
              }}
              errorMessage={
                //  editInputBoxM
                //    ? (customerInformation?.stateM ==
                //        undefined ||
                //        customerInformation?.stateM ==
                //          null ||
                //        customerInformation?.stateM == '' ||
                //        customerInformation?.stateM ==
                //          '0') &&
                //      isrequired === true
                //      ? FIELD_ERROR
                //      : ''
                //    : (updateMailingAddress.state ==
                //        undefined ||
                //        updateMailingAddress.state ==
                //          null ||
                //        updateMailingAddress.state == '' ||
                //        updateMailingAddress.state ==
                //          '0') &&
                //      (validateAddress == 'B' ||
                //        validateAddress == 'M')
                //    ? FIELD_ERROR
                //    : ''
                // eslint-disable-next-line sonarjs/no-redundant-boolean
                isrequired === true && editAddBtnShowArr[index] == false &&
                  (deliveryAddressArray[index].state == undefined ||
                    deliveryAddressArray[index].state == null ||
                    deliveryAddressArray[index].state == '' ||
                    deliveryAddressArray[index].state == '0')
                  ? FIELD_ERROR : ''
              }
            //  {...(state.length == 0 &&
            //    dropDownLoaded && {
            //      errorMessage: API_ERROR_MESSAGE,
            //    })}
            />
          </Grid>

          {editAddBtnShowArr[index] ? (
                      <Grid
                                    item
                                    lg={3}
                                    xl={2}
                                    className={classes.m2}
                                  >
                                    <RACButton
                                      variant="contained"
                                      color="primary"
                                      onClick={() => editClickDelivery(index)}
                                      disabled={val.storeNumber !== loginStore}
                                    >
                                      Edit Address
                                    </RACButton>
                                  </Grid>
                                  ) : (
                                    <Grid
                                    item
                                    lg={3}
                                    xl={2}
                                    className={classes.m2}
                                  >
                                    
                                      <>
                                        <RACButton
                                          variant="contained"
                                          color="primary"
                                          onClick={() => updateAddressInfoD(index)} // validate delivery address
                                          className={customerStyleClasses.me2}
                                        >
                                          Validate
                                        </RACButton>
                                        <RACButton
                                          className={`${customerStyleClasses.me2} ${customerStyleClasses.foc}`}
                                          onClick={() => cancelButtD(index)}
                                          variant="outlined"
                                          color="primary"
                                        >
                                          Cancel
                                        </RACButton>
                                      </>
                                    
                                  </Grid>
                                  )}
      {val.agreementNumbersCount > 1 && val.storeNumber === loginStore ? (                            
      <Grid item md={12} className={classes.mt3} style={{marginTop:'-10px'}}>
       <RACCheckBox
       className={classes.custCheckbox}
       checked={val?.agreementNumbersCount > 1 ? true : false}
       onChange={() => {handleChangeForCheckBox(index)}}
       />
        <Typography style={{marginLeft:'39px',marginTop:'-29px' , fontStyle:'bold'}}>
          Apply the same for agreement # : {val.agreementNumber}
        </Typography>
         </Grid>
         ):''}
    </Grid>
    );
  });
  }

  const handleChangeForDeliveryAddress = (e: any) => {
    debugger;
    const d: any = e.target.name;
    const splitD: any = d.split('_');
    const index = parseInt(splitD[1]);

    if (d == `AddressLine1D_${index}`) {
      const value = e.target.value;
      // deliveryAddressArray[index].addressLine1 = value;
      // setDeliveryAddressArray([...deliveryAddressArray]);
      updateDeliveryAddressArray[index].addressLine1 = value;
      setUpdateDeliveryAddressArray([...updateDeliveryAddressArray]);
    }
    else if (d == `AddressLine2D_${index}`) {
      const value = e.target.value;
      // deliveryAddressArray[index].addressLine2 = value;
      // setDeliveryAddressArray([...deliveryAddressArray]);
      updateDeliveryAddressArray[index].addressLine2 = value;
      setUpdateDeliveryAddressArray([...updateDeliveryAddressArray]);
    }
    else if (d == `ZipD_${index}`) {
      let cleaned = ('' + e.target.value).replace(/\D/g, '');
      cleaned =
        cleaned.length > 5
          ? cleaned.slice(0, 5) + '-' + cleaned.slice(5)
          : cleaned;
      // deliveryAddressArray[index].postalCode = cleaned;  
      // setDeliveryAddressArray([...deliveryAddressArray]); 
      updateDeliveryAddressArray[index].postalCode = cleaned;
      setUpdateDeliveryAddressArray([...updateDeliveryAddressArray]);
    }
    else if (d == `CityD_${index}`) {
      const value = e.target.value.replace(/[^a-zA-Z ]/g, '');
      // deliveryAddressArray[index].city = value;
      // setDeliveryAddressArray([...deliveryAddressArray]);
      updateDeliveryAddressArray[index].city = value;
      setUpdateDeliveryAddressArray([...updateDeliveryAddressArray]);
    }
    else if (d == `AddressInfoStateD_${index}`) {
      // deliveryAddressArray[index].state = e.target.value;
      // setDeliveryAddressArray([...deliveryAddressArray]);
      updateDeliveryAddressArray[index].state = e.target.value;
      setUpdateDeliveryAddressArray([...updateDeliveryAddressArray]);
    }
  }
  const editClickDelivery = (id: any) => {
    debugger;
    editAddBtnShowArr[id] = false;
    setEditAddBtnShowArr([...editAddBtnShowArr]);
    deliveryAddDisableArr[id] = false;
    setDeliveryAddDisableArr([...deliveryAddDisableArr]);
    //  buildDeliveryAddress();   
  }
  const handleChangeForCheckBox = (id: any) => {
    debugger;
    // const deliveryAddlocal1: any = [];
    // const deliveryAddlocal2: any = [];
    const deliveryAddlocal = deliveryAddressArray;
    const tempaddress = deliveryAddressArray[id].storeNumber;
    deliveryAddlocal.splice(id, 1);
    console.log(deliveryAddlocal, 'deliveryAddlocal');
    const tempAddArr = secondaryFilterArr.filter((val: any) => val.storeNumber == tempaddress);

    for (let i = 0; i < tempAddArr.length; i++) {
      // const obj=
      // {
      //   agreementNumbersCount: '1',
      //     agreementNumber: tempAddArr[i].agreementNumber,
      //     agreementDescription: tempAddArr[i].agreementDescription,
      //     agreementStatus: tempAddArr[i].agreementStatus,
      //     agreementStatusDesc: tempAddArr[i].agreementStatusDesc,
      //     agreementId: tempAddArr[i].agreementId,
      //     addressTypeDesc: tempAddArr[i].addressTypeDesc,
      //     storeNumber: tempAddArr[i].storeNumber,
      //     addressId: tempAddArr[i].addressId,
      //     addressType: tempAddArr[i].addressType,
      //     addressLine1: tempAddArr[i].addressLine1,
      //     addressLine2: tempAddArr[i].addressLine2,
      //     city: tempAddArr[i].city,
      //     state: tempAddArr[i].state,
      //     stateName: tempAddArr[i].stateName,
      //     postalCode: tempAddArr[i].postalCode,
      //     latitude: null,
      //     longitude: null,
      //     createdBy: tempAddArr[i].createdBy,
      //     createdDate: tempAddArr[i].createdDate,
      //     lastModifiedBy: tempAddArr[i].lastModifiedBy,
      //     lastModifiedDate: tempAddArr[i].lastModifiedDate,
      //     entAddressId: tempAddArr[i].entAddressId,
      //     active: tempAddArr[i].active,
      //     createdSource: tempAddArr[i].createdSource,
      //     lastModifiedSource: tempAddArr[i].lastModifiedSource,
      //     verifiedDate: tempAddArr[i].verifiedDate,
      //     plus4: tempAddArr[i].plus4,
      // }
      // setTempAdd(obj);
      // console.log(tempAdd,'tempAdd');
      // console.log(obj,'obj3');
      // eslint-disable-next-line prefer-const
      let agrIdArr: any = [];
      // eslint-disable-next-line prefer-const
      let agrNumArr: any = [];
      agrIdArr.push(tempAddArr[i].agreementId);
      agrNumArr.push(tempAddArr[i].agreementNumber)
      deliveryAddlocal.splice(id + i, 0, tempAddArr[i]);
      deliveryAddlocal[id + i].agreementIdArr = agrIdArr;
      deliveryAddlocal[id + i].agreementNumberArr = agrNumArr;
      deliveryAddDisableArr.push(true);
      //deliveryAddlocal.push(tempAddArr[i])
      editAddBtnShowArr.push(true);
    }
    console.log('sdfgesdg54efghytr', deliveryAddlocal);



    setDeliveryAddressArray([...deliveryAddlocal]);
    setUpdateDeliveryAddressArray([...deliveryAddlocal]);
    console.log(tempAddArr, 'tempAddArr');
    //const test = [...deliveryAddlocal2, ...tempAddArr];
    //console.log(test,'test');
    // if(tempAddArr.length > 0)
    // {
    //   tempAddArr.map((val: any) => {
    //     deliveryAddlocal1.push({
    //       agreementNumbersCount: '1',
    //       agreementNumber: val.agreementNumber,
    //       agreementDescription: val.agreementDescription,
    //       agreementStatus: val.agreementStatus,
    //       agreementStatusDesc: val.agreementStatusDesc,
    //       agreementId: val.agreementId,
    //       addressTypeDesc: val.addressTypeDesc,
    //       storeNumber: val.storeNumber,
    //       addressId: val.addressId,
    //       addressType: val.addressType,
    //       addressLine1: val.addressLine1,
    //       addressLine2: val.addressLine2,
    //       city: val.city,
    //       state: val.state,
    //       stateName: val.stateName,
    //       postalCode: val.postalCode,
    //       latitude: null,
    //       longitude: null,
    //       createdBy: val.createdBy,
    //       createdDate: val.createdDate,
    //       lastModifiedBy: val.lastModifiedBy,
    //       lastModifiedDate: val.lastModifiedDate,
    //       entAddressId: val.entAddressId,
    //       active: val.active,
    //       createdSource: val.createdSource,
    //       lastModifiedSource: val.lastModifiedSource,
    //       verifiedDate: val.verifiedDate,
    //       plus4: val.plus4,
    //   })
    //   const obj = [...deliveryAddlocal2,...deliveryAddlocal1];
    //   console.log(obj,'obj');
    //   deliveryAddlocal.splice(id,0,deliveryAddlocal);
    //   deliveryAddDisableArr.push(true);
    //   editAddBtnShowArr.push(true);
    //   })
    // }
    // setDeliveryAddressArray([...deliveryAddlocal]);
    //deliveryAddlocal.push(tempAddArr);
    console.log(deliveryAddlocal, 'deliveryAddlocal1');
  }
  const updateAddressInfoD = (index: any) => {
    // setDeliveryIndex(index);
    console.log(index, 'indexhii');
    debugger;
    validateDeliveryAddress(index);
    setDeliveryId(index);
  }
  interface AddressResponse {
    message?: string | undefined;
    status: boolean;
    matchCode?: string | undefined;
    suggestedAddress?: string | undefined ;
    postalAddress?: PostalAddress | undefined;
    
  }
  
  interface PostalAddress {
    addressLines: string[];
    administrativeArea: string;
    postalCode: string;
    locality: string;
  }
  const validateDeliveryAddress = async (index: any) => {
    debugger;
    setDeliveryScen(true);
    console.log(index,'gii');
    if(validateDelivAdd(index)) {
      const addressPayload : any = {
        MessageID: 'CMS006-93589345',
        MessageType: 'CMS006',
        MessageDTS: '2014-05-23-14:30:15:254633',
        MessageDTSTZOffset: '-06:00',
        RegionID: '1',
        ClientID: '8',
        ClientLocationNumber: '00006',
        ClientSource: '1',
        ClientOriginator: '1',
        LocationNumber: loginStore,
        EncryptionMethod: '0',
        Addresses: [
          {
            AddressID: '11114',
            StreetAddress: '',
            City: '',
            StateProvince: '',
            PostalCode: '',
            Country: '',
          },
        ],
        StoreConfig: {
          storeNumbers: [loginStore],
          paramKeyNames: ['AddressDoctorEnabled'],
        },
      };
      addressPayload.Addresses[0].City = updateDeliveryAddressArray[index].city;
      addressPayload.Addresses[0].StreetAddress = updateDeliveryAddressArray[index].addressLine1;
      addressPayload.Addresses[0].StateProvince = updateDeliveryAddressArray[index].state;
      addressPayload.Addresses[0].PostalCode = updateDeliveryAddressArray[index].postalCode.replaceAll('-', '');
      addressPayload.Addresses[0].Country = 'USA';
      console.log('deliveryPayload', addressPayload);
      try {
        if(featureFlagDetails?.EnableGoogleAddressValidation == '1'){
          const googleAddressValidationPayload : any = JSON.parse(JSON.stringify(addressPayload))
          googleAddressValidationPayload.enableGoogleAddressValidation = true;
          const arr: any = [updateDeliveryAddressArray[index].addressLine1, updateDeliveryAddressArray[index].city,updateDeliveryAddressArray[index].state,updateDeliveryAddressArray[index].postalCode ]
          // if(updateDeliveryAddressArray[index]?.addressLine2)
          // arr.unshift(updateDeliveryAddressArray[index]?.addressLine2)
          if(updateDeliveryAddressArray[index]?.addressLine2){
            arr.unshift(updateDeliveryAddressArray[index]?.addressLine2)
            addressPayload.Addresses[0].StreetAddress+= ` ${updateDeliveryAddressArray[index]?.addressLine2}`
            }
          const validateAddressPayload ={
            postalCode: updateDeliveryAddressArray[index]?.postalCode,
            addressLines: arr
          }
          setIsLoading(true);
          googleAddressValidationPayload.validateAddress = validateAddressPayload
          const response: any = await Promise.all([GetSuggestedAddress(addressPayload),GetSuggestedAddress(googleAddressValidationPayload)]);
          //Need to add loader variable 
          setIsLoading(false);
          if(response[0] && response[1].validateAddress){
            const result : AddressResponse = validateAddressFn(response[1].validateAddress, response[0].validateAddress,
              (response[0] && response[0].storeConfig && response[0].storeConfig.storeProfileResponse.configDetails[0]
             .configDetails[0].paramValue == '1')? true :false, googleAddressValidationPayload)
            if(result.status){
              if(result.matchCode){
                setmatchCode(result.matchCode);
                const errorMessage: string =
                result.matchCode == '1'
                ? `Address could not be corrected. Unlikely to match. Do you want to override?`
                : result.matchCode == '2'
                  ? `Zip code found: ${addressPayload.Addresses[0].PostalCode}. Do you Want to overrride?`
                  : '';
            setAddressErrorMsg(errorMessage);
            setValidateIsOpen(true);
            // setSuggestionAddresPopup(true);
            setSuggestedAddress(undefined);
            const postalAddress= {
              addressLines:[updateDeliveryAddressArray[index].addressLine1],
              locality:result?.postalAddress?.locality,
              postalCode :result?.postalAddress?.postalCode,
              administrativeArea:result.postalAddress?.administrativeArea
            }
            if(updateDeliveryAddressArray[index]?.addressLine2)
              postalAddress.addressLines.unshift(updateDeliveryAddressArray[index]?.addressLine2)
            setValidatedAddress({postalAddress:postalAddress}) 
            setdefaultSuggestedAddress('GA');
              }else{
              setCanSuggestAddress(true)
             setValidatedAddress(result)
              }
           }
           else{
             setCantSuggestAddress(true)
             setValidatedAddress(result)
           }
          }else{
            throw new Error('Something went wrong')
          }
        }
        else{
        setValidateIsOpen(true);
        setAddressDoc(true);
        const response: any = await GetSuggestedAddress(addressPayload);
        console.log('deliveryPayload1', response);
        setAddressDoc(false);
        if (
          response !== undefined &&
          response !== null &&
          response.storeConfig !== undefined &&
          (response.storeConfig.storeProfileResponse.configDetails[0]
            .configDetails[0].paramValue == 'Y' ||
            response.storeConfig.storeProfileResponse.configDetails[0]
              .configDetails[0].paramValue == '1' ||
            response.storeConfig.storeProfileResponse.configDetails[0]
              .configDetails[0].paramValue == 1)
        ) {
          if (
            response.validateAddress !== undefined &&
            response.validateAddress.Addresses !== undefined &&
            (response.validateAddress.Addresses.MatchCode == '1' ||
              response.validateAddress.Addresses.MatchCode == '2')
          ) {
            setmatchCode(response.validateAddress.Addresses.MatchCode);
            const errorMessage: string =
              response.validateAddress.Addresses.MatchCode == '1'
                ? `Address could not be corrected. Unlikely to match. Do you want to override?`
                : response.validateAddress.Addresses.MatchCode == '2'
                  ? `Zip code found: ${addressPayload.Addresses[0].PostalCode}. Do you Want to overrride?`
                  : '';
            setAddressErrorMsg(errorMessage);
            setSuggestedAddress(undefined);
          }
          else {
            setSuggestedAddress(response.validateAddress);
          }
        }
        else {
          //setIsLoading(false);
          setSuggestedAddress(undefined);
          setAddressErrorMsg(
            'Automatic Address validation is Unavailable for the store # Please validate the address Manually.'
          );
          buildDELIVAddress('DELIV1', addressType, index);
          setAddressType('');
      }
    }
      
    }
    catch {
      setAddressDoc(false);
      setAddressErrorMsg('Address validation currently Unavailable');
      setSuggestedAddress(undefined);
      buildDELIVAddress('DELIV1',addressType,index);
      setAddressType('D');
    }
  }
      deliveryAddDisableArr[index] = true;
      setDeliveryAddDisableArr([...deliveryAddDisableArr]);
      editAddBtnShowArr[index] = true;
      setEditAddBtnShowArr([...editAddBtnShowArr]);
}
const cancelButtD = (index: any) => {
  deliveryAddDisableArr[index] = true;
  setDeliveryAddDisableArr([...deliveryAddDisableArr]);
  editAddBtnShowArr[index] = true;
  setEditAddBtnShowArr([...editAddBtnShowArr]);
}

  const validateDelivAdd = (deliveryIndex: any) => {
    debugger;
    let validateDelivery = true;
    if (deliveryAddressArray[deliveryIndex].addressLine1 == undefined
      || deliveryAddressArray[deliveryIndex].addressLine1 == null
      || deliveryAddressArray[deliveryIndex].addressLine1 == ""
    ) {
      validateDelivery = false;
      console.log(deliveryAddressArray[deliveryIndex].addressLine1, 'deliveryAddressArray[index].addressLine1');
    }
    else if (
      deliveryAddressArray[deliveryIndex].postalCode == undefined
      || deliveryAddressArray[deliveryIndex].postalCode == null
      || deliveryAddressArray[deliveryIndex].postalCode == ""
    ) {
      validateDelivery = false;
      console.log(deliveryAddressArray[deliveryIndex].postalCode, 'deliveryAddressArray[index].zip');
    }
    // else if(
    //   deliveryAddressArray[deliveryIndex].postalCode !== undefined &&
    //   deliveryAddressArray[deliveryIndex].postalCode !== null && 
    //   deliveryAddressArray[deliveryIndex].postalCode !== "" &&
    //   deliveryAddressArray[deliveryIndex].postalCode !== 5 &&
    //   deliveryAddressArray[deliveryIndex].postalCode !== 10
    // )
    // // eslint-disable-next-line sonarjs/no-duplicated-branches
    // {
    //   validateDelivery = false;
    //   console.log(deliveryAddressArray[deliveryIndex].postalCode,'deliveryAddressArray[index].zip');
    // }
    else if (
      deliveryAddressArray[deliveryIndex].city == undefined ||
      deliveryAddressArray[deliveryIndex].city == null ||
      deliveryAddressArray[deliveryIndex].city == ""
    ) {
      validateDelivery = false;
      console.log(deliveryAddressArray[deliveryIndex].city, 'deliveryAddressArray[index].city');
    }
    else if (
      deliveryAddressArray[deliveryIndex].state == undefined ||
      deliveryAddressArray[deliveryIndex].state == null ||
      deliveryAddressArray[deliveryIndex].state == '0'
    ) {
      validateDelivery = false;
      console.log(deliveryAddressArray[deliveryIndex].state, 'deliveryAddressArray[index].state');
    }
    return validateDelivery;
  }

  const buildDELIVAddress = (type: any, addressType: 'D' | '', id: any, mc?: any) => {
    debugger;
    console.log(id, 'id5');
    if (type == 'DELIV1' && addressType == 'D') {
      deliveryAddressArray[id].addressLine1 =
        mc == 'MC2'
          ? updateDeliveryAddressArray[id].addressLine1.toUpperCase()
          : updateDeliveryAddressArray[id].addressLine1;
      deliveryAddressArray[id].addressLine2 =
        mc == 'MC2'
          ? updateDeliveryAddressArray[id].addressLine2.toUpperCase()
          : updateDeliveryAddressArray[id].addressLine2;
      deliveryAddressArray[id].city =
        mc == 'MC2'
          ? updateDeliveryAddressArray[id].city.toUpperCase()
          : updateDeliveryAddressArray[id].city;
      deliveryAddressArray[id].state =
        updateDeliveryAddressArray[id].state;
      deliveryAddressArray[id].postalCode =
        updateDeliveryAddressArray[id].postalCode;
      setDeliveryAddressArray([...deliveryAddressArray]);
      updateDeliveryAddressArray[id].addressLine1 =
        mc == 'MC2'
          ? updateDeliveryAddressArray[id].addressLine1.toUpperCase()
          : updateDeliveryAddressArray[id].addressLine1;
      updateDeliveryAddressArray[id].addressLine2 =
        mc == 'MC2'
          ? updateDeliveryAddressArray[id].addressLine2.toUpperCase()
          : updateDeliveryAddressArray[id].addressLine2;
      updateDeliveryAddressArray[id].city =
        mc == 'MC2'
          ? updateDeliveryAddressArray[id].city.toUpperCase()
          : updateDeliveryAddressArray[id].city;
      // updateDeliveryAddressArray[id].postalCode = updateDeliveryAddressArray[id].postalCode;
      // updateDeliveryAddressArray[id].state =  updateDeliveryAddressArray[id].state;
      console.log('sdfes', updateDeliveryAddressArray);

      setUpdateDeliveryAddressArray([...updateDeliveryAddressArray]);
    }
  }
  const renderBodyPrimAddress = (primAddDetails: any) => {
    if (primAddDetails.length > 0) {
      return primAddDetails.map((obj: any, index: any) => {
        return (
          <React.Fragment key={index}>
            <RACTableRow key={index} style={{ backgroundColor: 'white', paddingLeft: '5%', paddingRight: '5%' }}>
              <RACTableCell>
                <RACRadio
                  value=''
                  // eslint-disable-next-line sonarjs/no-redundant-boolean
                  checked={radioArray[index] == false ? false : true}

                  onClick={() => { handleChangeForPrimAddress(index) }}
                />
              </RACTableCell>
              <RACTableCell style={{ paddingRight: '1%' }}>
                {obj.addressLine1}
              </RACTableCell>
              <RACTableCell>
                {obj.addressLine2 == "" ? "-" : obj.addressLine2}
              </RACTableCell>
              <RACTableCell>
                {obj.postalCode}
              </RACTableCell>
              <RACTableCell>
                {obj.city}
              </RACTableCell>
              <RACTableCell>
                {obj.state}
              </RACTableCell>
              <RACTableCell
                title={obj.storeNumberMultiple}>
                {hideLengthyText(obj.storeNumberMultiple, 15, "...")}
              </RACTableCell>
              <RACTableCell
                title={obj.agreementNumber}>

                {hideLengthyTextAgreementNumber(String(obj.agreementIdArr?.length))}
              </RACTableCell>
            </RACTableRow>
          </React.Fragment>
        )
      })
    }
  }

  const handleChangeForPrimAddress = (index: any) => {
    debugger;
    console.log(index, 'index');
    const rad: any = radioArray.map((item: any, position: any) => {
      console.log(position, "position")
      return index == position ? true : false
    })
    setPrimaryAddressIndex(String(index));
    console.log(rad, 'rad');
    setRadioArray([...rad]);
  }
  const validateUpdateCustomerInactive = () => {
    const validateResultObj = [];
    let isValid = true;
    if (
      (legalHoldApplied !== 1 && isLegalHold !== true) ||
      (isLegalHold && coWorkerRoleForCSpopup == 2)
    ) {
      let firstTabValid =
        validateTabOne() === true && validateCustomerTab() === true
          ? true
          : false;
      if (firstTabValid !== false) {
        const SSN: any =
          customerInformation.ssn1 +
            customerInformation.ssn2 +
            customerInformation.ssn3 ==
            ''
            ? null
            : customerInformation.ssn1 +
            customerInformation.ssn2 +
            customerInformation.ssn3;
        firstTabValid =
          SSN != undefined && SSN != null && SSN != ''
            ? SSN.length < 9
              ? false
              : true
            : true;
      }
      if (firstTabValid === false) {
        validateResultObj.push('Customer Information');
        isValid = false;
      }
      if (validateTabTwo().isValidate === false) {
        validateResultObj.push('Employment Information');
        isValid = false;
      }
      if (validateTabThree().isValidate === false) {
        validateResultObj.push('Residence');
        isValid = false;
      }
      if (validateTabFour().isValidate === false) {
        validateResultObj.push('Reference');
        isValid = false;
      }
      setValidateResult(validateResultObj);
    }
    if (isValid) {
      setisrequired(false);
      //setisPrimary(true);
      // updateCustomerInfo();
      inActivePopUpSaveBtn();
    } else {
      setInActConDet(false);
      setErrorPopup(true);
      setisrequired(true);
    }

  }

  const validateValue = (value: any) => {
    let notValid = false;
    if (value == undefined || value == null || value == '') {
      notValid = true;
    }
    return notValid;
  };
  // eslint-disable-next-line sonarjs/no-duplicated-branches
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const validateTabOne = (phoneValidNotReq?: boolean) => {
    // eslint-disable-next-line no-console
    console.log(customerInformation);
    let isValidate = true;

    isValidate = !validateValue(customerInformation.firstName);
    // eslint-disable-next-line no-console
    console.log('firstNamei', isValidate);
    if (isValidate !== false) {
      isValidate = !validateValue(customerInformation.lastName);
    }
    // eslint-disable-next-line no-console
    console.log('lastName', isValidate);
    if (isValidate !== false) {
      isValidate = !validateValue(customerInformation.emailAddress);
      isValidate =
        isValidate !== false
          ? ValidateEmail(customerInformation?.emailAddress)
          : isValidate;
    }
    // eslint-disable-next-line no-console
    console.log('emailAddress', isValidate);
    if (isValidate !== false) {
      isValidate = !validateValue(customerInformation.dateOfBirth);
    }
    // eslint-disable-next-line no-console
    console.log('dateOfBirth', isValidate);
    // if (
    //   customerInformation.governmentIdType == undefined ||
    //   customerInformation.governmentIdType == null ||
    //   customerInformation.governmentIdType == '0'
    // ) {
    //   isValidate = false;
    // }
    // if (
    //   customerInformation.governmentIdType != undefined &&
    //   customerInformation.governmentIdType != null &&
    //   customerInformation.governmentIdType != '0' &&
    //   customerInformation.governmentIdType != 'NONE' &&
    //   customerInformation.governmentIdType != 'PASS' &&
    //   (customerInformation?.governmentId == undefined ||
    //     customerInformation?.governmentId == null ||
    //     customerInformation?.governmentId == '')
    // ) {
    //   isValidate = false;
    // }
    // if (
    //   customerInformation.governmentIdType != undefined &&
    //   customerInformation.governmentIdType != null &&
    //   customerInformation.governmentIdType != '0' &&
    //   customerInformation.governmentIdType != 'NONE' &&
    //   customerInformation.governmentIdType != 'PASS' &&
    //   (customerInformation?.governmentIdIssuedState == undefined ||
    //     customerInformation?.governmentIdIssuedState == null ||
    //     customerInformation?.governmentIdIssuedState == '0')
    // ) {
    //   isValidate = false;
    // }
    // eslint-disable-next-line no-console
    console.log('governmentIdType', isValidate);
    if (isValidate !== false) {
      isValidate = validateCustomerTab();
    }
    // eslint-disable-next-line no-console
    console.log('validateCustomerTab', isValidate);
    const phoneValidate: any = validatePhone(phoneValidNotReq);
    isValidate = isValidate === true ? phoneValidate.isValidate : isValidate;
    if (TaxIDActive === true && isValidate !== false) {
      isValidate = !validateValue(customerInformation.federalTaxId);
    }
    // eslint-disable-next-line no-console
    console.log('callTimeType', isValidate);
    // });
    // eslint-disable-next-line
    //debugger;
    IsPrimaryNotSet =
      phoneValidate.isPrimaryValid === false && phoneValidNotReq !== true
        ? 'Customer must have atleast one primary phone number.'
        : '';

    if (phoneValidate.isPrimaryValid === false && phoneValidNotReq !== true) {
      isValidate = false;
      //setisPrimary(false);
    }
    return isValidate;
  };
  const validatePhone = (phoneValidNotReq?: boolean) => {
    let isPrimaryValid = false;
    let isValidate = true;
    if (phoneValidNotReq !== true) {
      //phoneNumberProps.forEach((element: any) => {
      for (let i = 0; i < phoneNumberProps.length; i++) {
        const formatedPhoneNumber: any =
          phoneNumberProps[i].phoneNumber !== undefined &&
            phoneNumberProps[i].phoneNumber !== null &&
            phoneNumberProps[i].phoneNumber !== ''
            ? phoneNumberProps[i].phoneNumber.length
            : 0;
        if (
          formatedPhoneNumber == 0 ||
          (formatedPhoneNumber !== 0 && formatedPhoneNumber < 10)
        ) {
          isValidate = false;
        }

        // eslint-disable-next-line no-console
        //console.log('phoneNumber', element.phoneNumber.match(/(\d+)/).length);
        // eslint-disable-next-line no-console
        console.log('phoneNumber', isValidate);
        if (
          phoneNumberProps[i].callTimeType == undefined ||
          phoneNumberProps[i].callTimeType == null ||
          phoneNumberProps[i].callTimeType == '' ||
          phoneNumberProps[i].callTimeType == '0'
        ) {
          isValidate = false;
        }
        if (
          phoneNumberProps[i].phoneType == undefined ||
          phoneNumberProps[i].phoneType == null ||
          phoneNumberProps[i].phoneType == '' ||
          phoneNumberProps[i].phoneType == '0'
        ) {
          isValidate = false;
        }
        if (phoneNumberProps[i].primary == 'Y' && isPrimaryValid === false) {
          isPrimaryValid = true;
        }
      }
    }
    return { isPrimaryValid: isPrimaryValid, isValidate: isValidate };
  };
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const buildVerificationdate = (result: any) => {
    // eslint-disable-next-line
    // debugger
    const verificateDate: any = [];
    if (
      result?.verifiedDate !== null &&
      result?.verifiedDate !== undefined &&
      result?.verifiedDate !== ''
    ) {
      verificateDate.push(result?.verifiedDate);
    }
    if (
      result?.landlordReferences !== undefined &&
      result?.landlordReferences.length > 0
    ) {
      result?.landlordReferences.map((val: any, index: any) => {
        if (
          val.verifiedDate !== undefined &&
          val.verifiedDate !== null &&
          val.verifiedDate !== ''
        ) {
          verificateDate.push(val.verifiedDate);
        }
      });
    }
    if (
      result?.personalReferences !== undefined &&
      result?.personalReferences.length > 0
    ) {
      // eslint-disable-next-line sonarjs/no-identical-functions
      result?.personalReferences.map((val: any, index: any) => {
        if (
          val.verifiedDate !== undefined &&
          val.verifiedDate !== null &&
          val.verifiedDate !== ''
        ) {
          verificateDate.push(val.verifiedDate);
        }
      });
    }
    if (
      result?.employerReferences !== undefined &&
      result?.employerReferences.length > 0
    ) {
      // eslint-disable-next-line sonarjs/no-identical-functions
      result?.employerReferences.map((val: any, index: any) => {
        if (
          val.verifiedDate !== undefined &&
          val.verifiedDate !== null &&
          val.verifiedDate !== ''
        ) {
          verificateDate.push(val.verifiedDate);
        }
      });
    }
    verificateDate.sort(function (a: any, b: any) {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return +new Date(b) - +new Date(a);
    });
    // eslint-disable-next-line no-console
    console.log('verificateDate', verificateDate);
    let verificationDate = true;
    try {
      if (verificateDate.length == 0) {
        return '';
      } else if (verificateDate.length >= 3) {
        const verificationDateResponse: any = [];
        for (let i = 0; i < verificateDate.length; i++) {
          if (verificationDate) {
            let myFutureDate: any = '';
            myFutureDate = new Date(verificateDate[i]);
            myFutureDate.setDate(myFutureDate.getDate() + parseInt(verExpDate));
            const date2 = new Date();
            const stDate = moment(verificateDate[i], 'YYYY-MM-DD');
            const lt18 = moment(
              moment().add(parseInt(verExpDate), 'days'),
              'YYYY-MM-DD'
            );
            const lt18Res: any = lt18.diff(stDate, 'days');
            verificationDate = lt18Res > 0 ? true : false;
          }
        }
        console.log('verificateDate array', verificationDateResponse);
        if (verificationDate) {
          return verificateDate[0];
        } else {
          return '';
        }
        // if (myFutureDate.getTime() > date2.getTime()) {
        //   //date 1 is newer
        //   return verificateDate[0];
        // } else {
        //   return '';
        // }
      } else {
        return '';
      }
    } catch (e: any) {
      console.log(e);
      return '';
    }
  };
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const validateCustomerTab = () => {
    // eslint-disable-next-line no-console
    console.log(customerInformation);
    let isValidate = true;
    if (validateValue(customerInformation.addressLine1)) {
      isValidate = false;
      // eslint-disable-next-line no-console
      console.log('addressLine1', isValidate);
    } else if (
      (customerInformation.zip == undefined ||
        customerInformation.zip == null ||
        customerInformation.zip == '') &&
      customerInformation.zip?.length != 5 &&
      customerInformation.zip?.length != 10
    ) {
      isValidate = false;
      // eslint-disable-next-line no-console
      console.log('zip', isValidate);
    } else if (validateValue(customerInformation.city)) {
      isValidate = false;
    } else if (
      customerInformation.state == undefined ||
      customerInformation.state == null ||
      customerInformation.state == '0'
    ) {
      isValidate = false;
      // eslint-disable-next-line no-console
      console.log('city', isValidate);
    } else if (customerInformation.mailAddress == 'N') {
      if (validateValue(customerInformation.addressLine1M)) {
        isValidate = false;
        // eslint-disable-next-line no-console
        console.log('addressLine1M', isValidate);
      }
      if (
        (customerInformation.zipM == undefined ||
          customerInformation.zipM == null ||
          customerInformation.zipM == '') &&
        (customerInformation.zipM.length != 5 ||
          customerInformation.zipM.length != 10)
      ) {
        isValidate = false;
        // eslint-disable-next-line no-console
        console.log('zipM', isValidate);
      }
      if (
        customerInformation.stateM == undefined ||
        customerInformation.stateM == null ||
        customerInformation.stateM == '0'
      ) {
        isValidate = false;
        // eslint-disable-next-line no-console
        console.log('stateM', isValidate);
      }
      isValidate = !validateValue(customerInformation.cityM);
      // eslint-disable-next-line no-console
      console.log('cityM', isValidate);
    } else if (validateSSN() === false) {
      isValidate = false;
    }
    if (deliveryAddDisableArr.length > 0) {
      for (let i = 0; i < deliveryAddDisableArr.length; i++) {
        // eslint-disable-next-line sonarjs/no-redundant-boolean
        if (deliveryAddDisableArr[i] == false) {
          if (deliveryAddressArray[i].addressLine1 == undefined ||
            deliveryAddressArray[i].addressLine1 == null ||
            deliveryAddressArray[i].addressLine1 == "") {
            isValidate = false;
            // state[i].addressLine1 = true;
          }
          else if (
            deliveryAddressArray[i].postalCode == undefined ||
            deliveryAddressArray[i].postalCode == null ||
            deliveryAddressArray[i].postalCode == ""
          )
          // eslint-disable-next-line sonarjs/no-duplicated-branches
          {
            isValidate = false;
          }
          else if (
            deliveryAddressArray[i].city == undefined ||
            deliveryAddressArray[i].city == null ||
            deliveryAddressArray[i].city == ""
          )
          // eslint-disable-next-line sonarjs/no-duplicated-branches
          {
            isValidate = false;
          }
          else if (
            deliveryAddressArray[i].state == undefined ||
            deliveryAddressArray[i].state == null ||
            deliveryAddressArray[i].state == ""
          )
          // eslint-disable-next-line sonarjs/no-duplicated-branches
          {
            isValidate = false;
          }
        }
      }
    }
    return isValidate;
  };
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const validateTabTwo = (createClick?: boolean) => {
    let isValidate = true;
    let UpdatedEmpInfo = true;
    let count = 0;
    let EmpInfoCount = 0;
    EmpProps.forEach((element: any) => {
      count = 0;
      if (element.active == 'Y') {
        if (validateValue(element.employerName)) {
          isValidate = false;
          count = count + 1;
          // eslint-disable-next-line no-console
          console.log('employerName', isValidate);
        }
        const phoneNumberFormated: any =
          element.employerPhoneNumber !== undefined &&
            element.employerPhoneNumber !== null &&
            element.employerPhoneNumber !== ''
            ? element.employerPhoneNumber.length
            : 0;
        // eslint-disable-next-line no-console
        console.log('phoneNumberFormated', phoneNumberFormated);
        if (phoneNumberFormated < 10) {
          isValidate = false;
          count = count + 1;
          // eslint-disable-next-line no-console
          console.log('phoneNumberFormated val', isValidate);
        }
        if (
          element.employerPayschedule == undefined ||
          element.employerPayschedule == null ||
          element.employerPayschedule == '0' ||
          element.employerPayschedule == ''
        ) {
          isValidate = false;
          count = count + 1;
          // eslint-disable-next-line no-console
          console.log('employerPayschedule', isValidate);
        }
        if (
          isValidate !== false &&
          element.employerPayschedule != 'DAILY' &&
          (element.daysPaid == undefined ||
            element.daysPaid == null ||
            element.daysPaid == '0' ||
            element.daysPaid == '')
        ) {
          isValidate = false;
          // eslint-disable-next-line no-console
          console.log('daysPaid', isValidate);
        }
        // if (validateValue(element.addressLine1)) {
        //   isValidate = false;
        //   count = count + 1;
        //   // eslint-disable-next-line no-console
        //   console.log('addressLine1', isValidate);
        // }
        // eslint-disable-next-line sonarjs/no-duplicated-branches
        // if (
        //   element.postalCode == undefined ||
        //   element.postalCode == null ||
        //   element.postalCode == ''
        // ) {
        //   isValidate = false;
        //   count = count + 1;
        //   // eslint-disable-next-line no-console
        //   console.log('callTimeType', isValidate);
        // }
        if (
          element.postalCode != undefined &&
          element.postalCode != null &&
          element.postalCode.length > 0 &&
          element.postalCode.length != 5 &&
          element.postalCode.length != 10
        ) {
          isValidate = false;
          count = count + 1;
          // eslint-disable-next-line no-console
          console.log('callTimeType', isValidate);
        }
        // if (validateValue(element.city)) {
        //   isValidate = false;
        //   count = count + 1;
        //   // eslint-disable-next-line no-console
        //   console.log('city', isValidate);
        // }

        // // eslint-disable-next-line no-console
        // console.log('city', isValidate);

        // if (
        //   element.state == undefined ||
        //   element.state == null ||
        //   element.state == '0' ||
        //   element.state == ''
        // ) {
        //   isValidate = false;
        //   count = count + 1;
        //   // eslint-disable-next-line no-console
        //   console.log('state', isValidate);
        // }
      }
      // if (
      //   count === 7 &&
      //   activetab !== tabList.EMPLOYMENTINFO &&
      //   createActiveTab !== 1
      // ) {
      //   console.log('inside other tab', EmpInfoCount);
      //   EmpInfoCount = EmpInfoCount + 1;
      // } else if (
      //   count <= 7 &&
      //   (activetab == tabList.EMPLOYMENTINFO || createActiveTab == 1)
      // ) {
      //   console.log('inside less than 7', EmpInfoCount);
      //   EmpInfoCount = EmpProps.length - 1;
      // }
      EmpInfoCount = count === 4 ? EmpInfoCount + 1 : EmpInfoCount;
    });
    //if(pageType !== 'customer' && EmpProps.length == 1 && EmpInfoCount ){
    // eslint-disable-next-line no-console
    console.log('activeTab create', createActiveTab);
    console.log('activeTabe customer', activetab);

    console.log('createAgreementClick count', count);
    console.log('createAgreementClick', createAgreementClick);
    UpdatedEmpInfo =
      createClick == undefined
        ? true
        : EmpInfoCount == EmpProps.length
          ? false
          : true;
    // eslint-disable-next-line no-console
    console.log('EmpProps.length', EmpProps.length);
    console.log('EmpInfoCount', EmpInfoCount);
    console.log('UpdatedEmpInfo', UpdatedEmpInfo);
    console.log('isValid for mobile', isValidate);
    // }
    return {
      isValidate: UpdatedEmpInfo === true ? isValidate : true,
      UpdatedInfo: UpdatedEmpInfo,
    };
  };
  // eslint-disable-next-line sonarjs/no-duplicated-branches
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const validateTabThree = (createClick?: boolean) => {
    // eslint-disable-next-line no-console
    console.log(Residences);
    let count = 0;
    let isValidObj = true;
    // eslint-disable-next-line no-console
    console.log(Residences);
    if (Residences.residenceType === 'RENT') {
      const formatedPhoneNumber: any =
        Residences.phoneNumber !== undefined &&
          Residences.phoneNumber !== null &&
          Residences.phoneNumber !== ''
          ? Residences.phoneNumber.length
          : 0;
      if (
        formatedPhoneNumber == 0 ||
        (formatedPhoneNumber !== 0 && formatedPhoneNumber < 10)
      ) {
        isValidObj = false;
        count = count + 1;
        // eslint-disable-next-line no-console
        console.log('phoneNumber', isValidObj);
      }
      if (validateValue(Residences.landlordFirstName)) {
        isValidObj = false;
        count = count + 1;
        // eslint-disable-next-line no-console
        console.log('landlordFirstName', isValidObj);
      }
      if (validateValue(Residences.landlordLastName)) {
        isValidObj = false;
        count = count + 1;
        // eslint-disable-next-line no-console
        console.log('landlordLastName', isValidObj);
      }
    }
    if (
      Residences.residenceType === 'OWN' &&
      Residences.mortgage === true &&
      validateValue(Residences.mortgageCompanyName)
    ) {
      isValidObj = false;
      count = 3;
      // eslint-disable-next-line no-console
      console.log('mortgageCompanyName', isValidObj);
    }
    const UpdatedInfo =
      createClick == undefined
        ? true
        : (count === 3 && Residences.residenceType == 'RENT') ||
          (count === 1 &&
            Residences.residenceType !== 'RENT' &&
            Residences.mortgage)
          ? false
          : true;

    // eslint-disable-next-line no-console
    console.log('isValidObj', isValidObj);
    return {
      isValidate: UpdatedInfo === true ? isValidObj : true,
      UpdatedInfo: UpdatedInfo,
    };
  };
  console.log('activeTab create', createActiveTab);
  console.log('activeTabe customer res', activetab);
  // eslint-disable-next-line sonarjs/no-duplicated-branches
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const validateTabFour = (createClick?: boolean) => {
    // eslint-disable-next-line no-console
    console.log(ReferenceProps);
    let count = 0;
    let isValid = true;
    let ReferenceInfoCount = 0;
    if (ReferenceProps !== undefined && ReferenceProps.length > 0) {
      ReferenceProps.forEach((element: any) => {
        if (element.active == 'Y') {
          const phoneNumberFormated: any =
            element.phoneNumber !== undefined &&
              element.phoneNumber !== null &&
              element.phoneNumber !== ''
              ? element.phoneNumber.length
              : 0;
          if (phoneNumberFormated < 10) {
            isValid = false;
            count = count + 1;
            // eslint-disable-next-line no-console
            console.log('callTimeType', isValid);
          }
          if (validateValue(element.firstName)) {
            isValid = false;
            count = count + 1;
            // eslint-disable-next-line no-console
            console.log('callTimeType', isValid);
          }
          if (validateValue(element.lastName)) {
            isValid = false;
            count = count + 1;
            // eslint-disable-next-line no-console
            console.log('callTimeType', isValid);
          }
          if (
            element.relationshipType == undefined ||
            element.relationshipType == null ||
            element.relationshipType == '0' ||
            element.relationshipType == ''
          ) {
            isValid = false;
            count = count + 1;
            // eslint-disable-next-line no-console
            console.log('relationshipType', isValid);
          }
          // if (validateValue(element.addressLine1)) {
          //   isValid = false;
          //   count = count + 1;
          //   // eslint-disable-next-line no-console
          //   console.log('addressLine1', isValid);
          // }
          // if (validateValue(element.postalCode)) {
          //   isValid = false;
          //   count = count + 1;
          //   // eslint-disable-next-line no-console
          //   console.log('addressLine1', isValid);
          // }
          if (
            isValid !== false &&
            element.postalCode !== undefined &&
            element.postalCode !== null &&
            element.postalCode.length > 0 &&
            element.postalCode.length !== 5 &&
            element.postalCode.length !== 10
          ) {
            isValid = false;
            count = count + 1;
            // eslint-disable-next-line no-console
            console.log('addressLine1', isValid);
          }
          // if (validateValue(element.city)) {
          //   isValid = false;
          //   count = count + 1;
          //   // eslint-disable-next-line no-console
          //   console.log('addressLine1', isValid);
          // }
          // if (
          //   element.state == undefined ||
          //   element.state == null ||
          //   element.state == '0' ||
          //   element.state == ''
          // ) {
          //   isValid = false;
          //   count = count + 1;
          //   // eslint-disable-next-line no-console
          //   console.log('addressLine1', isValid);
          // }
        }
        // if (
        //   count === 8 &&
        //   activetab !== tabList.REFERENCE &&
        //   createActiveTab !== 3
        // ) {
        //   ReferenceInfoCount = ReferenceInfoCount + 1;
        // } else if (
        //   count <= 8 &&
        //   (activetab == tabList.REFERENCE || createActiveTab == 3)
        // ) {
        //   ReferenceInfoCount = ReferenceProps.length - 1;
        // }
        ReferenceInfoCount =
          count === 4 ? ReferenceInfoCount + 1 : ReferenceInfoCount;
      });
    }
    console.log('activeTab create', createActiveTab);
    console.log('activeTabe customer', activetab);
    const UpdatedInfo =
      createClick == undefined
        ? true
        : ReferenceInfoCount == ReferenceProps.length
          ? false
          : true;
    //isValid = count === 3 ? true : isValid;
    // eslint-disable-next-line no-console
    console.log('UpdatedInfo', UpdatedInfo);
    return {
      isValidate: UpdatedInfo === true ? isValid : true,
      UpdatedInfo: UpdatedInfo,
    };
  };
  const buildDate = (date: string, type?: 'V') => {
    // eslint-disable-next-line
    //debugger;
    console.log('Build Date', date);
    const d = date == '' ? new Date() : date;
    // let month = '' + (d.getMonth() + 1);
    // let day = '' + d.getDate();
    // const year = d.getFullYear();

    // if (month.length < 2) month = '0' + month;
    // if (day.length < 2) day = '0' + day;
    return moment(d).format('YYYY/MM/DD').replaceAll('/', '-');
  };
  const buildDateZReplace = (date: string, type?: 'V') => {
    // eslint-disable-next-line
    //debugger;
    console.log('Build Date', date);
    const d = date == '' ? new Date() : date.replace('Z', '');
    // let month = '' + (d.getMonth() + 1);
    // let day = '' + d.getDate();
    // const year = d.getFullYear();

    // if (month.length < 2) month = '0' + month;
    // if (day.length < 2) day = '0' + day;
    return moment(d).format('YYYY/MM/DD').replaceAll('/', '-');
  };
  const buildDateFormat2 = (date: any, type?: 'V') => {
    // eslint-disable-next-line
    //debugger;
    console.log(date, 'date Vanthiduchuuuuu 123');
    const d = date == '' ? new Date() : date;
    // let month = '' + (d.getMonth() + 1);
    // let day = '' + d.getDate();
    // const year = d.getFullYear();

    // if (month.length < 2) month = '0' + month;
    // if (day.length < 2) day = '0' + day;
    // eslint-disable-next-line sonarjs/no-duplicate-string
    console.log(moment(d).format('MM/DD/YYYY'), 'date Vanthiduchuuuuu');
    return moment(d).format('MM/DD/YYYY');
  };
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const verification = (
    isloadVerificationdate?: any,
    result?: any,
    isResidence?: boolean,
    type?: 'R' | 'O',
    residenceValue?: any
    // eslint-disable-next-line sonarjs/cognitive-complexity
  ) => {
    // eslint-disable-next-line
    // debugger
    const ownVerifiedDate: any =
      isResidence === true
        ? type == 'O'
          ? residenceValue
          : Residences.ownVerifieddate
        : Residences.ownVerifieddate;
    const rentVerifiedDate: any =
      isResidence === true
        ? type == 'R'
          ? residenceValue
          : Residences.verifiedCheckBox
        : Residences.verifiedCheckBox;
    const isRent =
      isResidence === true
        ? type == 'O'
          ? false
          : true
        : Residences.residenceType == 'RENT';
    const isOwn =
      isResidence === true
        ? type == 'O'
          ? true
          : false
        : Residences.residenceType == 'OWN';
    let empTabVerified = true;
    let residenceverified = true;
    let referenceVerified = true;
    const EmpPropsObj =
      result !== undefined ? result.employerReferences : EmpProps;
    if (
      EmpPropsObj !== undefined &&
      EmpPropsObj !== null &&
      EmpPropsObj.length > 0
    ) {
      for (let i = 0; i < EmpPropsObj.length; i++) {
        if (
          empTabVerified === true &&
          EmpPropsObj[i].active == 'Y' &&
          (EmpPropsObj[i].verifiedDate == undefined ||
            EmpPropsObj[i].verifiedDate == null ||
            EmpPropsObj[i].verifiedDate == '' ||
            EmpPropsObj[i].verifiedDate == 'null')
        ) {
          empTabVerified = false;
        }
      }
    }
    const ReferencePropsObj =
      result !== undefined ? result.personalReferences : ReferenceProps;
    if (
      ReferencePropsObj !== undefined &&
      ReferencePropsObj !== null &&
      ReferencePropsObj.length > 0 &&
      empTabVerified === true
    ) {
      for (let i = 0; i < ReferencePropsObj.length; i++) {
        if (
          referenceVerified === true &&
          ReferencePropsObj[i].active == 'Y' &&
          (ReferencePropsObj[i].verifiedDate == undefined ||
            ReferencePropsObj[i].verifiedDate == null ||
            ReferencePropsObj[i].verifiedDate == '' ||
            ReferencePropsObj[i].verifiedDate == 'null')
        ) {
          referenceVerified = false;
        }
      }
    }
    if (
      (rentVerifiedDate == undefined ||
        rentVerifiedDate == null ||
        rentVerifiedDate == '') &&
      isRent === true &&
      empTabVerified === true &&
      referenceVerified === true
    ) {
      residenceverified = false;
    } else if (
      (ownVerifiedDate == undefined ||
        ownVerifiedDate == null ||
        ownVerifiedDate == '') &&
      isOwn === true &&
      empTabVerified === true &&
      referenceVerified === true
      // eslint-disable-next-line sonarjs/no-duplicated-branches
    ) {
      // eslint-disable-next-line sonarjs/no-duplicated-branches
      residenceverified = false;
    }
    if (isRent === false && isOwn === false) {
      // eslint-disable-next-line sonarjs/no-duplicated-branches
      residenceverified = false;
    }
    const IsVerified: any =
      empTabVerified === true &&
        referenceVerified === true &&
        residenceverified === true
        ? 'Y'
        : 'N';
    setcustomerInformation({
      ...customerInformation,
      verified: IsVerified,
    });
    if (IsVerified == 'Y' && isloadVerificationdate == undefined) {
      const myCurrentDate = new Date();
      //const myFutureDate = new Date(myCurrentDate);
      //myFutureDate.setDate(myFutureDate.getDate() + 60);
      const customerInformationObj = customerInformation;
      customerInformationObj.verified = 'Y';
      customerInformationObj.verifiedDate = buildDateFormat2(myCurrentDate);
      setcustomerInformation({ ...customerInformationObj });
    } else {
      const customerInformationObj = customerInformation;
      customerInformationObj.verified = 'N';
      customerInformationObj.verifiedDate = null;
      setcustomerInformation({ ...customerInformationObj });
    }
    if (isloadVerificationdate !== undefined && IsVerified == 'Y') {
      const myCurrentDate = new Date(isloadVerificationdate);
      //const myFutureDate = new Date(myCurrentDate);
      //myFutureDate.setDate(myFutureDate.getDate() + 60);
      return buildDateFormat2(myCurrentDate);
    }
  };
  const buildVerification = () => {
    // eslint-disable-next-line no-console
    console.log('customerInformation', customerInformation);
    let myFutureDate: any = '';
    if (
      customerInformation?.verifiedDate !== undefined &&
      customerInformation?.verifiedDate !== null &&
      customerInformation?.verifiedDate !== ''
    ) {
      console.log('testing date', customerInformation?.verifiedDate);

      myFutureDate = new Date(
        customerInformation?.verifiedDate.replace('Z', '')
      );
      console.log('testing date', myFutureDate);
      myFutureDate.setDate(myFutureDate.getDate() + parseInt(verExpDate));
      console.log('testing date', myFutureDate);
    }
    return customerInformation?.verified !== undefined &&
      customerInformation?.verified !== null &&
      customerInformation?.verified !== '' &&
      customerInformation?.verified == 'Y' &&
      customerInformation?.verifiedDate !== undefined &&
      customerInformation?.verifiedDate !== null &&
      customerInformation?.verifiedDate !== '' ? (
      <Grid
        item
        className={`${customerStyleClasses.floatLeft} ${classes.textCenter} ${classes.me3}`}
      >
        <Box
          component="span"
          className={`${classes.badge} ${classes.badgeGreen}`}
        >
          Verified
        </Box>
        <Box component="span" className={classes.racDEDate}>
          Expires on{' '}
          {customerInformation?.verifiedDate !== undefined &&
            customerInformation?.verifiedDate !== null &&
            customerInformation?.verifiedDate !== ''
            ? buildDateFormat2(myFutureDate, 'V')
            : ''}
        </Box>
      </Grid>
    ) : customerInformation?.verified == 'N' ||
      customerInformation?.verifiedDate == undefined ||
      customerInformation?.verifiedDate == null ||
      customerInformation?.verifiedDate == '' ? (
      <Grid
        item
        className={`${customerStyleClasses.floatLeft} ${classes.textCenter} ${customerStyleClasses.mt1} ${classes.me1}`}
      >
        <Box
          component="span"
          className={`${classes.badge} ${classes.badgeRed}`}
        >
          Verification Required
        </Box>
      </Grid>
    ) : null;
  };

  const webLinkCustomer = async () => {

    const payload: any = {
      customerId: customerId,
      globalCustomerId: customerInformation.globalCustomerId,
      emailAddress: customerInformation.emailAddress,
      linkOnlineAccount: "Y",
    };

    setIsLoading(true);
    const result = await UpdateCustomerInfo(payload, customerId);
    console.log(result, "..webresult");
    setIsLoading(false);
    // eslint-disable-next-line sonarjs/no-all-duplicated-branches
    if (result?.UpdateCustomer?.value?.oktaStatus == "NotExists") {
      setWebError(true);
    }
    else if (result?.UpdateCustomer?.value?.oktaStatus == "Success") {
      setWebLinked(true);
    } else if (result?.UpdateCustomer?.value?.oktaStatus == "Failed") {
      setWebLinkFailed(true)
    }
    else {
      setShowUpdatePopUp(true);
      setisSuccess(false);
    }

  }

  const doupateCust = async () => {
    setTcpaPopup(false);
    await updateCustomerInfo();
  }
  //eslint-disable-next-line sonarjs/cognitive-complexity
  const updateCustomerInfo = async () => {
    try {
      let UpdateCustomerRequest: any = [];
      let UpdateCustomerAddress: any;
      let UpdateCustomerRequestForLegalHoldCustomer: any = [];

      if (
        (legalHoldApplied !== 1 && isLegalHold !== true) ||
        (isLegalHold && coWorkerRoleForCSpopup == 2)
      ) {
        UpdateCustomerRequest = {
          customerId: customerId,
          globalCustomerId: customerInformation.globalCustomerId,
          firstName: customerInformation.firstName,
          lastName: customerInformation.lastName,
          middleInitial: customerInformation.middleInitial,
          coworker: customerInformation.racCoworker,
          suffix:
            customerInformation.suffix !== '0' &&
              customerInformation.suffix !== undefined
              ? customerInformation.suffix
              : '',
          salutation: customerInformation.salutation,


          governmentIdType:
            customerInformation.governmentIdType == '0'
              ? ''
              : customerInformation.governmentIdType,

          governmentIdIssuedState: customerInformation.governmentIdIssuedState,
          emailAddress: customerInformation.emailAddress,
          preferredContactMethod:
            customerInformation.preferredMethod !== '0' &&
              customerInformation.preferredMethod !== 'EM'
              ? customerInformation.preferredMethod
              : '',
          preferredLanguage: customerInformation.preferredLanguage,
          printReceiptMethod: customerInformation.preferredPaymentReceipt,
          phoneSolicitationAllowed:
            customerInformation.phoneSolicitationAllowed,
          emailSolicitationAllowed:
            customerInformation.emailSolicitationAllowed,
          mailSolicitationAllowed: customerInformation.mailSolicitationAllowed,
          // textSolicitationAllowed: customerInformation.textMessageRemainder,  //zz
          paymentReminderCallsAllowed: customerInformation.paymentRemainderCall,
          verified:
            customerInformation?.verifiedDate == undefined ||
              customerInformation?.verifiedDate == null ||
              customerInformation?.verifiedDate == ''
              ? 'N'
              : 'Y',
          // verifiedDate:
          //   Residences.ownVerifieddate !== undefined &&
          //   Residences.ownVerifieddate !== null &&
          //   Residences.ownVerifieddate !== ''
          //     ? buildDate(Residences.ownVerifieddate)
          //     : '',
          verifiedDate:
            customerInformation.verifiedDate !== undefined &&
              customerInformation.verifiedDate !== null &&
              customerInformation.verifiedDate !== ''
              ? buildDate(customerInformation.verifiedDate)
              : '',
          skip: customerInformation.skip,
          stolen: customerInformation.stolen,
          hard: customerInformation.hard,
          skipDate: buildDate(''),
        };
        if (isMaskedDataHasChanged.ssn) {
          UpdateCustomerRequest.taxId =
            customerInformation.ssn1 +
              customerInformation.ssn2 +
              customerInformation.ssn3 ==
              ''
              ? null
              : customerInformation.ssn1 +
              customerInformation.ssn2 +
              customerInformation.ssn3,
            UpdateCustomerRequest.taxIdType = 'SSN'
        }
        if (isMaskedDataHasChanged.dob) {
          UpdateCustomerRequest.dateOfBirth = buildDate(customerInformation.dateOfBirth)
        }
        if (isMaskedDataHasChanged.govtId) {
          UpdateCustomerRequest.governmentId = customerInformation.governmentId
        }
        UpdateCustomerRequest.mortgageCompanyName =
          Residences?.residenceType == 'OWN'
            ? Residences.mortgage === true
              ? Residences.mortgageCompanyName
              : ''
            : '';
        UpdateCustomerRequest.residenceType = Residences.residenceType;
        UpdateCustomerRequest.residenceSince =
          Residences.residenceType === 'OWN' ? Residences.residenceSince : null;
        UpdateCustomerRequest.monthlyMortgagePayment =
          Residences.residenceType === 'OWN'
            ? Residences.mortgage === true
              ? Residences.monthlyMortgagePayment == 'NaN'
                ? ''
                : Residences.monthlyMortgagePayment
              : ''
            : '';
        //let addressArray : any = buildAddress()
        // UpdateCustomerRequest.addresses = buildAddress();
        UpdateCustomerAddress = buildUpdateAddress();
        console.log(UpdateCustomerAddress, 'UpdateCustomerRequest.addresses');
        // eslint-disable-next-line no-console
        console.log(UpdateCustomerRequest.addresses);
        //looping to changing phone number to normal
        const phonePropsArray: any = buildPhones();

        if (
          phoneNumberProps !== undefined &&
          phoneNumberProps !== null &&
          phoneNumberProps.length > 0
        ) {
          UpdateCustomerRequest.phones = phonePropsArray;
        }
        // eslint-disable-next-line no-console
        console.log(UpdateCustomerRequest.phones);

        const EmpPropsArray: any = buildEmpObj();

        UpdateCustomerRequest.employerReferences = EmpPropsArray;
        if (
          customerInformation.federalTaxId !== undefined &&
          customerInformation.federalTaxId !== null &&
          customerInformation.federalTaxId !== ''
        ) {
          UpdateCustomerRequest.taxExempts = [
            { federalTaxId: customerInformation.federalTaxId },
          ];
        }
        if (DEStatus.approvalStatus !== DECISION_ENGINE_APPROVED || pageType == 'customer') {
          const ReferencePropsArray: any = buildReferenceObj();
          // eslint-disable-next-line no-console
          console.log('ReferenceProps', ReferencePropsArray);

          UpdateCustomerRequest.personalReferences = ReferencePropsArray;
          //if (Residences.rented === true) {
          const landlordReferencesObj: any = landlordReferences();
          if (
            landlordReferencesObj !== undefined &&
            landlordReferencesObj !== null &&
            landlordReferencesObj.length > 0
          ) {
            UpdateCustomerRequest.landlordReferences = landlordReferencesObj;
          }
          if (
            Residences?.residenceType == 'OWN' &&
            Residences?.ownVerifieddate
          ) {
            UpdateCustomerRequest.ownVerifiedDate = buildDate(
              Residences.ownVerifieddate
            );
          } else {
            UpdateCustomerRequest.ownVerifiedDate = '';
          }
          //}
          const vechicleObj: any = buildVechicle();
          UpdateCustomerRequest.vehicles = vechicleObj;
          if (
            salesLeadIdQuery !== undefined &&
            salesLeadIdQuery !== null &&
            salesLeadIdQuery !== ''
          ) {
            UpdateCustomerRequest.salesLeadId = salesLeadIdQuery;
          }
        }
        UpdateCustomerRequest.assignRoute = {
          storeNumber: loginStore,
          customerId: parseInt(customerId),
          routeRefCode: customerInformation.route,
        };
        UpdateCustomerRequest.monthlyMortgagePayment =
          UpdateCustomerRequest.monthlyMortgagePayment !== ''
            ? UpdateCustomerRequest.monthlyMortgagePayment.replace(',', '')
            : '';
      } else {
        UpdateCustomerRequestForLegalHoldCustomer = {
          customerId: customerId,
          globalCustomerId: customerInformation.globalCustomerId,
          firstName: customerInformation.firstName,
          lastName: customerInformation.lastName,
        };
      }
      // /* eslint-enable no-console */
      // console.log('UpdateCustomerRequest', JSON.stringify(UpdateCustomerRequest));
      // eslint-disable-next-line no-console
      console.log(
        'UpdateCustomerRequest',
        UpdateCustomerRequest,
        customerInformation
      );
      console.log(
        'UpdateCustomerRequest customerInformation',
        customerInformation
      );

      // /* eslint-enable no-console */
      setIsLoading(true);
      const payload: any =
        (legalHoldApplied == 1 && isLegalHold && coWorkerRoleForCSpopup == 2) ||
          (legalHoldApplied !== 1 && isLegalHold !== true)
          ? UpdateCustomerRequest
          : UpdateCustomerRequestForLegalHoldCustomer;
      const updateAddPayload: any = UpdateCustomerAddress;
      const result = await UpdateCustomerInfo(payload, customerId);
      const addResult = await UpdateAddress(updateAddPayload);
      setIsLoading(false);
      // eslint-disable-next-line no-console
      showUpdateStatusPopUp(result);
      // eslint-disable-next-line no-console
      console.log(addResult, 'addResult');
      console.log('response', result);
      // /* eslint-enable no-console */
    } catch (e: any) {
      setIsLoading(false);
      setisSuccess(false);
      setShowUpdatePopUp(true);
      // eslint-disable-next-line no-console
      console.log(e, 'Error in Update Customer');
    }
  };
  const showUpdateStatusPopUp = (result: any) => {
    if (
      result !== undefined &&
      result !== null &&
      result.UpdateCustomer !== undefined &&
      result.UpdateCustomer !== null &&
      result.UpdateCustomer.value !== undefined &&
      result.UpdateCustomer.value !== null &&
      result.UpdateCustomer.value.customerId !== undefined &&
      result.UpdateCustomer.value.customerId !== null &&
      result.UpdateCustomer.value.customerId == customerId
    ) {
      if (customerIdQueryParam == null) {
        if (pageType == undefined || pageType == 'customer') {
          setShowUpdatePopUp(true);
        } else {
          console.log('saleLeadCoCreate', saleLeadCoCreate);

          if (saleLeadCoCreate?.inventoryNumber == null && saleLeadCoCreate?.modelNumber && saleLeadCoCreate?.inventoryReserved === true) {
            setCOAgreementPopUp(true);
          }
          else {
            setcreateAgreementPopUp(true);
          }
        }
      } else {
        if (
          (salesLeadIdQuery !== undefined &&
            salesLeadIdQuery !== null &&
            salesLeadIdQuery !== '' &&
            (customerSrc == null || customerSrc == '')) ||
          (customerInf?.salesLeadId !== undefined &&
            customerInf?.salesLeadId !== null &&
            customerInf?.salesLeadId !== '' &&
            (customerSrc == null || customerSrc == ''))
        ) {
          props.history.push(
            `/agreement/rental/itemsearch/${customerIdQueryParam}?coCustomerId=${customerId}&&salesLeadId=${salesLeadIdQuery !== undefined &&
              salesLeadIdQuery !== null &&
              salesLeadIdQuery !== ''
              ? salesLeadIdQuery
              : customerInf?.salesLeadId
            }`
          );
        } else if (customerSrc == null || customerSrc == '') {
          props.history.push(
            `/agreement/rental/itemsearch/${customerIdQueryParam}?coCustomerId=${customerId}`
          );
        } else if (customerSrc == 'SO') {
          if (
            selectedinventoryNumber !== undefined &&
            selectedinventoryNumber.selectedInventoryNo !== undefined
          ) {
            history.push({
              pathname: `/agreement/switchout/itemsearch/${customerIdQueryParam}/${agrId}`,
              state: {
                selectedInventoryNo:
                  selectedinventoryNumber.selectedInventoryNo,
              },
            });
          }
          // props.history.push(
          //   `/agreement/info/details/${customerIdQueryParam}/${agrId}`
          // );
        } else if (customerSrc == 'RI') {
          if (isFromCustPage == 'RI') {
            props.history.push(
              `/agreement/reinstate/itemsearch/${customerId}/${agrId}?transferrightsapplied=Y`
            );
          }
        }
      }
      // customerIdQueryParam == null
      //   ? pageType == undefined || pageType == 'customer'
      //     ? setShowUpdatePopUp(true)
      //     : setcreateAgreementPopUp(true)
      //   : props.history.push(
      //       (salesLeadIdQuery !== undefined &&
      //         salesLeadIdQuery !== null &&
      //         salesLeadIdQuery !== '') ||
      //         (customerInf?.salesLeadId !== undefined &&
      //           customerInf?.salesLeadId !== null &&
      //           customerInf?.salesLeadId !== '' &&
      //           path == '')
      //         ? `/agreement/rental/itemsearch/${customerIdQueryParam}?coCustomerId=${customerId}&&salesLeadId=${
      //             salesLeadIdQuery !== undefined &&
      //             salesLeadIdQuery !== null &&
      //             salesLeadIdQuery !== ''
      //               ? salesLeadIdQuery
      //               : customerInf?.salesLeadId
      //           }`
      //         : customerSrc != null &&
      //             customerSrc !=
      //               `/agreement/rental/itemsearch/${customerIdQueryParam}?coCustomerId=${customerId}`
      //     );

      //   setisSuccess(true);
      // } else {
      //   setisSuccess(false);
      //   setShowUpdatePopUp(true);
      // }
      if (result?.UpdateCustomer?.value?.oktaStatus == "Failed") {
        setEcomSupport(true)
        setisSuccess(false)
        setShowUpdatePopUp(false)
      }
      else if (result.UpdateCustomer.value.oktaStatus == "Success") {
        setWebLinkEmail(true)
        setWebLinkDisable(true)
        setisSuccess(false)
        setShowUpdatePopUp(false)
      }
      else {
        setisSuccess(true)
      }
    } else {
      setisSuccess(false);
      setShowUpdatePopUp(true);
      // setWebLinkDisable(true);
    }
  };
  const hideupdatePopUp = () => {
    if (isSuccess) {
      setShowUpdatePopUp(false);
      window.location.reload()
    }
    else if(cantAccess){
      setShowUpdatePopUp(false);
    }
    else{    
      setcustomerInformation({
        ...customerInformation,
        racCoworker: 'N',
      });

      setShowUpdatePopUp(false);
    }
    setCantAccess(false)
  };
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const buildVechicle = () => {
    const vechicleObj: any = [];
    if (Vehicle !== undefined && Vehicle !== null && Vehicle.length > 0) {
      Vehicle.forEach((el: any) => {
        if (
          el.vehicleLicensePlate !== undefined &&
          el.vehicleLicensePlate !== '' &&
          el.vehicleLicensePlate !== null
        ) {
          el.vehicleIndex = '';
          el.vehPlanExpirationDate =
            el.vehPlanExpirationDate !== undefined &&
              el.vehPlanExpirationDate !== null &&
              el.vehPlanExpirationDate !== ''
              ? buildDate(el.vehPlanExpirationDate)
              : '';
          el.vehMonthlyPayment =
            el.vehMonthlyPayment !== undefined &&
              el.vehMonthlyPayment !== null &&
              el.vehMonthlyPayment !== 'NaN' &&
              el.vehMonthlyPayment !== ''
              ? el.vehMonthlyPayment.replace(',', '')
              : '0';
          el.vehicleYear =
            el?.vehicleYear !== undefined &&
              el?.vehicleYear !== null &&
              el?.vehicleYear !== '' &&
              isDropDownValueExist(buildVechicleYear(), el?.vehicleYear)
              ? el?.vehicleYear
              : '';
          vechicleObj.push(el);
        }
      });
    }
    return vechicleObj;
  };
  const buildPhones = () => {
    const phonePropsArray: any = [];
    if (
      phoneNumberProps !== undefined &&
      phoneNumberProps !== null &&
      phoneNumberProps.length > 0
    ) {
      phoneNumberProps.forEach((el: any) => {
        el.phoneId =
          el.phoneId !== undefined && el.phoneId !== null && el.phoneId !== ''
            ? el.phoneId
            : '';
        el.phoneNumber =
          el.phoneNumber !== undefined && el.phoneNumber !== null
            ? el.phoneNumber.replace(/\D/g, '')
            : null;
        el.callTimeType =
          el?.callTimeType !== undefined &&
            el?.callTimeType !== null &&
            el?.callTimeType !== '' &&
            isDropDownValueExist(bestTimeToCall, el?.callTimeType)
            ? el?.callTimeType
            : '';
        el.callTimeTypeDesc =
          el?.callTimeType !== undefined &&
            el?.callTimeType !== null &&
            el?.callTimeType !== '' &&
            isDropDownValueExist(bestTimeToCall, el?.callTimeType)
            ? el?.callTimeTypeDesc
            : '';
        el.phoneType =
          el?.phoneType !== undefined &&
            el?.phoneType !== null &&
            el?.phoneType !== '' &&
            isDropDownValueExist(phoneType, el?.phoneType)
            ? el?.phoneType
            : '';
        el.phoneTypeDesc =
          el?.callTimeType !== undefined &&
            el?.callTimeType !== null &&
            el?.callTimeType !== '' &&
            isDropDownValueExist(phoneType, el?.phoneType)
            ? el?.phoneTypeDesc
            : '';

        phonePropsArray.push(el);
      });
    }
    return phonePropsArray;
  };
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const buildReferenceObj = () => {
    const ReferencePropsArray: any = [];
    if (ReferenceProps !== undefined && ReferenceProps.length > 0) {
      ReferenceProps.forEach((el: any) => {
        if (el.active !== 'Y') {
          el.phoneNumber =
            el.phoneNumber !== undefined &&
              el.phoneNumber !== null &&
              el.phoneNumber !== ''
              ? el.phoneNumber
              : null;
          el.relationshipType =
            el.relationshipType !== undefined &&
              el.relationshipType !== null &&
              el.relationshipType !== '' &&
              isDropDownValueExist(rel, el?.relationshipType)
              ? el?.relationshipType
              : '';
          el.bestTimeToCall =
            el.bestTimeToCall !== undefined &&
              el.bestTimeToCall !== null &&
              el.bestTimeToCall !== '' &&
              isDropDownValueExist(bestTimeToCall, el?.bestTimeToCall)
              ? el?.bestTimeToCall
              : '';
          el.addressLine1 =
            el.addressLine1 !== undefined &&
              el.addressLine1 !== null &&
              el.addressLine1 !== ''
              ? el.addressLine1
              : null;
          el.addressLine2 =
            el.addressLine2 !== undefined &&
              el.addressLine2 !== null &&
              el.addressLine2 !== ''
              ? el.addressLine2
              : null;
          el.postalCode =
            el.postalCode !== undefined &&
              el.postalCode !== null &&
              el.postalCode !== '' &&
              (el.postalCode.length == 5 || el.postalCode.length == 9)
              ? el.postalCode
              : '';
          el.city =
            el.city !== undefined && el.city !== null && el.city !== ''
              ? el.city
              : '';
          el.state =
            el.state !== undefined &&
              el.state !== null &&
              el.state !== '' &&
              el.state !== '0'
              ? el.state
              : '';
          el.doNotCall = el.doNotCall !== 'Y' ? 'N' : 'Y';
          el.verifiedDate =
            el.verifiedDate !== undefined &&
              el.verifiedDate !== null &&
              el.verifiedDate !== ''
              ? buildDate(el.verifiedDate)
              : '';
        } else {
          el.phoneNumber =
            el.phoneNumber !== undefined && el.phoneNumber !== null
              ? el.phoneNumber.replace(/\D/g, '')
              : null;
          el.relationshipType =
            el.relationshipType !== undefined &&
              el.relationshipType !== null &&
              el.relationshipType !== '' &&
              isDropDownValueExist(rel, el?.relationshipType)
              ? el?.relationshipType
              : '';
          el.bestTimeToCall =
            el.bestTimeToCall !== undefined &&
              el.bestTimeToCall !== null &&
              el.bestTimeToCall !== '' &&
              isDropDownValueExist(bestTimeToCall, el?.bestTimeToCall)
              ? el?.bestTimeToCall
              : '';
          el.verifiedDate =
            el.verifiedDate !== undefined &&
              el.verifiedDate !== null &&
              el.verifiedDate !== ''
              ? buildDate(el.verifiedDate)
              : '';
          el.addressLine1 =
            el.addressLine1 !== undefined &&
              el.addressLine1 !== null &&
              el.addressLine1 !== ''
              ? el.addressLine1
              : '';
          el.addressLine2 =
            el.addressLine2 !== undefined &&
              el.addressLine2 !== null &&
              el.addressLine2 !== ''
              ? el.addressLine2
              : '';
          el.postalCode =
            el.postalCode !== undefined &&
              el.postalCode !== null &&
              el.postalCode !== ''
              ? el.postalCode
              : '';
          el.city =
            el.city !== undefined && el.city !== null && el.city !== ''
              ? el.city
              : '';
          el.state =
            el.state !== undefined &&
              el.state !== null &&
              el.state !== '' &&
              el.state !== '0'
              ? el.state
              : '';
        }
        ReferencePropsArray.push(el);
      });
    }
    return ReferencePropsArray;
  };
  const getDayPaid = (data: any) => {
    const dayPaidOptions: dropdown[] =
      data?.employerPayschedule == 'SEMI'
        ? dayPaid5
        : data?.employerPayschedule == 'WK' ||
          data?.employerPayschedule == 'BIWK'
          ? dayPaid1
          : data?.employerPayschedule == 'MON'
            ? dayPaid3
            : [];
    return dayPaidOptions;
  };
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const buildEmpObj = () => {
    const EmpPropsArray: any = [];
    if (EmpProps !== undefined && EmpProps !== null && EmpProps.length > 0) {
      EmpProps.forEach((el: any) => {
        if (el.active !== 'Y') {
          el.employerName =
            el.employerName !== undefined &&
              el.employerName !== null &&
              el.employerName !== ''
              ? el.employerName
              : null;
          el.takeHomePay =
            el.takeHomePay !== undefined &&
              el.takeHomePay !== null &&
              el.takeHomePay !== '' &&
              el.takeHomePay !== 'NaN'
              ? el.takeHomePay.replace(',', '')
              : null;
          el.employerPhoneNumber =
            el.employerPhoneNumber !== undefined &&
              el.employerPhoneNumber !== null &&
              el.employerPhoneNumber !== ''
              ? el.employerName.replace(/\D/g, '')
              : null;
          el.addressLine1 =
            el.addressLine1 !== undefined &&
              el.addressLine1 !== null &&
              el.addressLine1 !== ''
              ? el.addressLine1
              : null;
          el.addressLine2 =
            el.addressLine2 !== undefined &&
              el.addressLine2 !== null &&
              el.addressLine2 !== ''
              ? el.addressLine2
              : null;
          el.postalCode =
            el.postalCode !== undefined &&
              el.postalCode !== null &&
              el.postalCode !== '' &&
              (el.postalCode.length == 5 || el.postalCode.length == 9)
              ? el.postalCode
              : '';
          el.city =
            el.city !== undefined && el.city !== null && el.city !== ''
              ? el.city
              : '';
          el.state =
            el.state !== undefined &&
              el.state !== null &&
              el.state !== '' &&
              el.state !== '0' &&
              el.state !== 0
              ? el.state
              : '';
          el.employerPayschedule =
            el.employerPayschedule !== undefined &&
              el.employerPayschedule !== null &&
              el.employerPayschedule !== '' &&
              isDropDownValueExist(payDayfrequency, el?.employerPayschedule)
              ? el?.employerPayschedule
              : null;
          el.employerPayscheduleDesc =
            el.employerPayschedule !== undefined &&
              el.employerPayschedule !== null &&
              el.employerPayschedule !== '' &&
              isDropDownValueExist(payDayfrequency, el?.employerPayschedule)
              ? el?.employerPayscheduleDesc
              : null;
          el.daysPaid =
            el.daysPaid !== undefined &&
              el.daysPaid !== null &&
              el.daysPaid !== '' &&
              isDropDownValueExist(getDayPaid(el), el?.daysPaid)
              ? el?.daysPaid
              : null;
          el.daysPaidDesc =
            el.daysPaid !== undefined &&
              el.daysPaid !== null &&
              el.daysPaid !== '' &&
              isDropDownValueExist(payDayfrequency, el?.daysPaid)
              ? el?.daysPaidDesc
              : null;
          el.lengthOfEmploymentYears =
            el.lengthOfEmploymentYears !== undefined &&
              el.lengthOfEmploymentYears !== null &&
              el.lengthOfEmploymentYears !== ''
              ? el.lengthOfEmploymentYears
              : '0';
          el.workStartTime = null;
          el.workEndTime = null;
          el.supervisorFirstName = '';
          el.supervisorLastName = '';
          el.jobTitle = '';
          el.employerPhoneExtension = null;
          el.employmentEndDate = null;
          el.verifiedDate =
            el.verifiedDate !== undefined &&
              el.verifiedDate !== null &&
              el.verifiedDate !== ''
              ? buildDate(el.verifiedDate)
              : '';
          el.doNotCall = el.doNotCall !== 'Y' ? 'N' : 'Y';
          el.doNotVisit = el.doNotVisit !== 'Y' ? 'N' : 'Y';
        } else {
          const lengthOfEmploymentYearsObj: any =
            el.lengthOfEmploymentYears !== ''
              ? parseInt(el.lengthOfEmploymentYears)
              : '';
          //lengthOfEmploymentYearsObj = lengthOfEmploymentYearsObj !== undefined && lengthOfEmploymentYearsObj !== null && lengthOfEmploymentYearsObj !== NaN ?
          el.employerPhoneNumber =
            el.employerPhoneNumber !== undefined &&
              el.employerPhoneNumber !== null
              ? el.employerPhoneNumber.replace(/\D/g, '')
              : null;
          el.takeHomePay =
            el.takeHomePay !== undefined &&
              el.takeHomePay !== null &&
              el.takeHomePay !== 'NaN' &&
              el.takeHomePay !== ''
              ? el.takeHomePay.replace(',', '')
              : '0';
          el.supervisorFirstName = '';
          el.supervisorLastName = '';
          el.jobTitle = '';
          el.employerPhoneExtension = null;
          el.employerReferenceId =
            el.employerReferenceId !== undefined &&
              el.employerReferenceId !== null &&
              el.employerReferenceId !== ''
              ? el.employerReferenceId
              : '';
          el.employmentBeginDate = '2001-10-18';
          el.workStartTime = null;
          el.workEndTime = null;
          el.lengthOfEmploymentYears =
            lengthOfEmploymentYearsObj !== ''
              ? lengthOfEmploymentYearsObj.toString()
              : null;
          el.employmentEndDate = null;
          el.verifiedDate =
            el.verifiedDate !== undefined &&
              el.verifiedDate !== null &&
              el.verifiedDate !== ''
              ? buildDate(el.verifiedDate)
              : '';
          el.addressLine1 =
            el.addressLine1 !== undefined &&
              el.addressLine1 !== null &&
              el.addressLine1 !== ''
              ? el.addressLine1
              : '';
          el.addressLine2 =
            el.addressLine2 !== undefined &&
              el.addressLine2 !== null &&
              el.addressLine2 !== ''
              ? el.addressLine2
              : '';
          el.postalCode =
            el.postalCode !== undefined &&
              el.postalCode !== null &&
              el.postalCode !== ''
              ? el.postalCode
              : '';
          el.city =
            el.city !== undefined && el.city !== null && el.city !== ''
              ? el.city
              : '';
          el.state =
            el.state !== undefined &&
              el.state !== null &&
              el.state !== '' &&
              el.state !== '0' &&
              el.state !== 0
              ? el.state
              : '';
        }

        EmpPropsArray.push(el);
      });
    }
    return EmpPropsArray;
  };
  // eslint-disable-next-line sonarjs/cognitive-complexity
  // const buildAddress = () => {
  //   // eslint-disable-next-line
  //   // ;
  //   const addressToAdd: any = [];
  //   try {
  //     const address = customerInformation.addressess;
  //     const addressObj: any = address.filter(
  //       (val: any) => val.addressType == 'PRIM'
  //     );
  //     const mailingAddress: any = address.filter(
  //       (val: any) => val.addressType == 'MAIL'
  //     );
  //     const anotherAddress: any = address.filter(
  //       (val: any) => val.addressType != 'MAIL' && val.addressType != 'PRIM'
  //     );
  //     if (addressObj.length > 0) {
  //       addressObj[0].addressType = 'PRIM';
  //       addressObj[0].active='Y';
  //       addressObj[0].addressLine1 = customerInformation.addressLine1;
  //       addressObj[0].addressLine2 = customerInformation.addressLine2;
  //       addressObj[0].city = customerInformation.city;
  //       addressObj[0].state = customerInformation.state;
  //       addressObj[0].postalCode = customerInformation.zip;
  //       addressObj[0].verifiedDate =
  //         addressObj[0].verifiedDate !== undefined &&
  //         addressObj[0].verifiedDate !== null &&
  //         addressObj[0].verifiedDate !== ''
  //           ? buildDate(addressObj[0].verifiedDate)
  //           : null;
  //       addressToAdd.push(addressObj[0]);
  //     } else if (addressObj.length == 0) {
  //       addressToAdd.push({
  //         addressId: '',
  //         addressType: 'PRIM',
  //         addressLine1: customerInformation.addressLine1,
  //         addressLine2: customerInformation.addressLine2,
  //         city: customerInformation.city,
  //         state: customerInformation.state,
  //         postalCode: customerInformation.zip,
  //         latitude: null,
  //         longitude: null,
  //       });
  //     }
  //     // eslint-disable-next-line sonarjs/no-identical-conditions
  //     if (mailingAddress.length > 0 && customerInformation.mailAddress == 'N') {
  //       mailingAddress[0].addressType = 'MAIL';
  //       mailingAddress[0].active='Y';
  //       mailingAddress[0].addressLine1 = customerInformation.addressLine1M;
  //       mailingAddress[0].addressLine2 = customerInformation.addressLine2M;
  //       mailingAddress[0].city = customerInformation.cityM;
  //       mailingAddress[0].state = customerInformation.stateM;
  //       mailingAddress[0].postalCode = customerInformation.zipM;
  //       mailingAddress[0].verifiedDate =
  //         mailingAddress[0].verifiedDate !== undefined &&
  //         mailingAddress[0].verifiedDate !== null &&
  //         mailingAddress[0].verifiedDate !== ''
  //           ? buildDate(mailingAddress[0].verifiedDate)
  //           : null;
  //       addressToAdd.push(mailingAddress[0]);
  //     } else if (
  //       mailingAddress.length == 0 &&
  //       customerInformation.mailAddress == 'N'
  //     ) {
  //       addressToAdd.push({
  //         addressId: '',
  //         addressType: 'MAIL',
  //         addressLine1: customerInformation.addressLine1M,
  //         addressLine2: customerInformation.addressLine2M,
  //         city: customerInformation.cityM,
  //         state: customerInformation.stateM,
  //         postalCode: customerInformation.zipM,
  //         latitude: null,
  //         longitude: null,
  //       });
  //     } else if (
  //       mailingAddress.length > 0 &&
  //       customerInformation.mailAddress == 'Y'
  //     ) {
  //       mailingAddress[0].addressType = 'MAIL';
  //       mailingAddress[0].addressLine1 = customerInformation.addressLine1;
  //       mailingAddress[0].addressLine2 = customerInformation.addressLine2;
  //       mailingAddress[0].active='Y';
  //       mailingAddress[0].city = customerInformation.city;
  //       mailingAddress[0].state = customerInformation.state;
  //       (mailingAddress[0].postalCode = customerInformation.zip),
  //         (mailingAddress[0].verifiedDate =
  //           mailingAddress[0].verifiedDate !== undefined &&
  //           mailingAddress[0].verifiedDate !== null &&
  //           mailingAddress[0].verifiedDate !== ''
  //             ? buildDate(mailingAddress[0].verifiedDate)
  //             : null);
  //       addressToAdd.push(mailingAddress[0]);
  //     } else if (
  //       mailingAddress.length == 0 &&
  //       customerInformation.mailAddress == 'Y'
  //     ) {
  //       addressToAdd.push({
  //         addressId: '',
  //         addressType: 'MAIL',
  //         addressLine1: customerInformation.addressLine1,
  //         addressLine2: customerInformation.addressLine2,
  //         city: customerInformation.city,
  //         state: customerInformation.state,
  //         postalCode: customerInformation.zip,
  //         latitude: null,
  //         longitude: null,
  //       });
  //     }
  //     if (anotherAddress.length > 0) {
  //       anotherAddress.forEach((element: any) => {
  //         element.active='Y'
  //         element.postalCode =
  //           element?.postalCode !== undefined &&
  //           element?.postalCode !== '     -    '
  //             ? element?.postalCode
  //             : '';
  //         element.verifiedDate =
  //           element.verifiedDate !== undefined &&
  //           element.verifiedDate !== null &&
  //           element.verifiedDate !== ''
  //             ? buildDate(element.verifiedDate)
  //             : null;
  //         addressToAdd.push(element);
  //       });
  //     }
  //     // eslint-disable-next-line no-console
  //     console.log('addressToAdd', addressToAdd);
  //   } catch (e: any) {
  //     // eslint-disable-next-line no-console
  //     console.log(e, 'Error in buildAddress');
  //   }
  //   return addressToAdd;
  // };

  const buildUpdateAddress = () => {
    debugger;
    // eslint-disable-next-line sonarjs/no-unused-collection
    // eslint-disable-next-line prefer-const
    let addressPayload: any;
    const addressToAdd: any = [];
    // eslint-disable-next-line prefer-const
    let agrId: any = [];
    // eslint-disable-next-line prefer-const
    let agrId2: any = [];
    // eslint-disable-next-line prefer-const
    let primaddressID :any =[]
    debugger;
    const address = customerInformation.addressess;
    console.log(address, 'address');

    const addressObj: any = address.filter(
      (val: any) => val.addressType == 'PRIM'
    );
    console.log(addressObj, 'addressObj');
    if (addressObj?.length > 0 && primaddressID?.length == 0) {
      primaddressID.push(address[0].addressId);
      for (let j = 0; j < addressObj?.length; j++) {
        if (addressObj[j].agreementId != null && addressObj[j].agreementId != "") {
          agrId.push(addressObj[j].agreementId);
        }
      }
      addressToAdd.push({
        city: customerInformation.city,
        addressType: 'PRIM',
        postalCode: customerInformation.zip,
        active: 'Y',
        addressLine1: customerInformation.addressLine1,
        addressLine2: customerInformation.addressLine2,
        state: customerInformation.state,
        agreementIds: agrId,
        addressId: addressObj[0].addressId,
      })

    } else {
      addressToAdd.push({
        city: customerInformation.city,
        addressType: 'PRIM',
        postalCode: customerInformation.zip,
        active: 'Y',
        addressLine1: customerInformation.addressLine1,
        addressLine2: customerInformation.addressLine2,
        state: customerInformation.state,
        agreementIds: []
      })
    }

    const addressObjMail: any = address.filter(
      (val: any) => val.addressType == 'MAIL'
    );
    if (addressObjMail.length > 0 && customerInformation.mailAddress == 'N') {
      addressToAdd.push({
        city: customerInformation.cityM,
        addressType: 'MAIL',
        postalCode: customerInformation.zipM,
        active: 'Y',
        addressLine1: customerInformation.addressLine1M,
        addressLine2: customerInformation.addressLine2M,
        state: customerInformation.stateM,
        agreementIds: [],
        addressId: addressObjMail[0].addressId,
      })
    } else if (addressObjMail.length == 0 && customerInformation.mailAddress == 'N') {
      addressToAdd.push({
        city: customerInformation.cityM,
        addressType: 'MAIL',
        postalCode: customerInformation.zipM,
        active: 'Y',
        addressLine1: customerInformation.addressLine1M,
        addressLine2: customerInformation.addressLine2M,
        state: customerInformation.stateM,
        agreementIds: [],
      })
    }
    else if (addressObjMail.length > 0 && customerInformation.mailAddress == 'Y') {
      addressToAdd.push({
        city: customerInformation.city,
        addressType: 'MAIL',
        postalCode: customerInformation.zip,
        active: 'Y',
        addressLine1: customerInformation.addressLine1,
        addressLine2: customerInformation.addressLine2,
        state: customerInformation.state,
        agreementIds: [],
        addressId: addressObjMail[0].addressId,
      })
    } else if (addressObjMail.length == 0 && customerInformation.mailAddress == 'Y') {
      addressToAdd.push({
        city: customerInformation.city,
        addressType: 'MAIL',
        postalCode: customerInformation.zip,
        active: 'Y',
        addressLine1: customerInformation.addressLine1,
        addressLine2: customerInformation.addressLine2,
        state: customerInformation.state,
        agreementIds: [],
      })
    }




    const billingAddressID: any = []
    for (let i = 0; i < address.length; i++) {
      const bilingObj: any = address.filter(
        (val: any) => val.addressId == address[i].addressId && val.addressType == "B"
      );
      console.log(bilingObj, 'bilingObj');
      if (bilingObj?.length > 0 && !billingAddressID?.includes(address[i].addressId)) {
        billingAddressID.push(address[i].addressId);
        console.log(billingAddressID, "addressID");
        let multipleAgreementNumber2: any = [];
        for (let j = 0; j < bilingObj?.length; j++) {
          if (bilingObj[j].agreementId != null && bilingObj[j].agreementId != "") {
            agrId2.push(bilingObj[j].agreementId);
          }
        }
        multipleAgreementNumber2 = agrId2;

        console.log(multipleAgreementNumber2, "multipleAgreementNumber");
        addressToAdd.push({
          city: bilingObj[0].city,
          addressType: bilingObj[0].addressType,
          postalCode: bilingObj[0].postalCode,
          active: bilingObj[0].active == 1 ? 'Y' : 'N',
          addressLine1: bilingObj[0].addressLine1,
          addressLine2: bilingObj[0].addressLine2,
          state: bilingObj[0].state,
          agreementIds: multipleAgreementNumber2,
          addressId: bilingObj[0].addressId,
        })
      }
    }

    const deliveryObj: any = updateDeliveryAddressArray.filter(
      (val: any) => val.addressType == 'DELIV'
    );
    if (deliveryObj?.length > 0) {
      const addressIDS: any = []
      for (let j = 0; j < deliveryObj?.length; j++) {
        addressToAdd.push({
          city: deliveryObj[j].city,
          addressType: deliveryObj[j].addressType,
          postalCode: deliveryObj[j].postalCode,
          active: deliveryObj[j].active == 1 ? 'Y' : 'N',
          addressLine1: deliveryObj[j].addressLine1,
          addressLine2: deliveryObj[j].addressLine2,
          state: deliveryObj[j].state,
          agreementIds: deliveryObj[j].agreementIdArr,
          addressId: addressIDS.includes(deliveryObj[j].addressId) ? undefined : deliveryObj[j].addressId,
        })
        addressIDS.push(deliveryObj[j].addressId)
      }


    }
    console.log(addressToAdd, 'addressToAdd');


    // eslint-disable-next-line prefer-const
    addressPayload = {
      customerId: customerId,
      addresses: addressToAdd
    }
    console.log(addressPayload, 'addressPayload');
    return addressPayload

  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const landlordReferences = () => {
    let ResidencesObj = Residences.landlordValue;
    try {
      // eslint-disable-next-line no-console
      console.log(customerInformation);
      if (
        ResidencesObj !== undefined &&
        ResidencesObj !== null &&
        ResidencesObj.length > 0
      ) {
        ResidencesObj[0].landlordFirstName = Residences.landlordFirstName;
        ResidencesObj[0].landlordLastName = Residences.landlordLastName;
        // eslint-disable-next-line prettier/prettier
        ResidencesObj[0].phoneNumber = Residences.phoneNumber !== undefined && Residences.phoneNumber !== null ? Residences.phoneNumber.replace(/\D/g, '') : '';
        ResidencesObj[0].monthlyRent =
          Residences.monthlyRent !== undefined &&
            Residences.monthlyRent !== null &&
            Residences.monthlyRent !== ''
            ? Residences.monthlyRent.replace(',', '')
            : '0.00';
        ResidencesObj[0].addressLine1 = null;
        ResidencesObj[0].addressLine2 = null;
        ResidencesObj[0].city = null;
        ResidencesObj[0].state = null;
        ResidencesObj[0].postalCode = '';
        ResidencesObj[0].phoneExtension = null;
        ResidencesObj[0].leaseHolderName = null;
        ResidencesObj[0].bestTimeToCall = null;
        ResidencesObj[0].leaseCompanyName = null;
        ResidencesObj[0].leaseTerm = null;
        ResidencesObj[0].leaseLengthYears = '0.000000';
        ResidencesObj[0].leaseLengthMonths = '0.000000';
        ResidencesObj[0].verifiedDate =
          Residences.verifiedCheckBox !== undefined &&
            Residences.verifiedCheckBox !== null &&
            Residences.verifiedCheckBox !== ''
            ? buildDate(Residences.verifiedCheckBox)
            : '';
        ResidencesObj[0].active =
          (Residences?.residenceType == 'RENT') === true ? 'Y' : 'N';
        ResidencesObj[0].moveInDate =
          Residences.moveInDate !== undefined &&
            Residences.moveInDate !== null &&
            Residences.moveInDate !== ''
            ? buildDate(Residences.moveInDate)
            : null;
        for (let i = 1; i < ResidencesObj.length; i++) {
          ResidencesObj[i].postalCode =
            ResidencesObj[i].postalCode !== undefined &&
              ResidencesObj[i].postalCode !== '     -    '
              ? ResidencesObj[i].postalCode
              : '';
          ResidencesObj[i].verifiedDate =
            ResidencesObj[i].verifiedDate !== undefined &&
              ResidencesObj[i].verifiedDate !== null &&
              ResidencesObj[i].verifiedDate !== ''
              ? buildDate(ResidencesObj[i].verifiedDate)
              : '';
        }
      } else if (Residences?.residenceType === 'RENT') {
        ResidencesObj = [];
        ResidencesObj = [
          {
            landlordFirstName: Residences.landlordFirstName,
            landlordLastName: Residences.landlordLastName,
            phoneNumber:
              Residences.phoneNumber !== undefined &&
                Residences.phoneNumber !== null
                ? Residences.phoneNumber.replace(/\D/g, '')
                : '',
            monthlyRent:
              Residences.monthlyRent !== undefined &&
                Residences.monthlyRent !== null &&
                Residences.monthlyRent !== ''
                ? Residences.monthlyRent.replace(',', '')
                : '0.00',
            moveInDate:
              Residences.moveInDate !== undefined &&
                Residences.moveInDate !== null &&
                Residences.moveInDate !== ''
                ? buildDate(Residences.moveInDate)
                : null,
            active: 'Y',
            leaseLengthYears: '0.000000',
            leaseLengthMonths: '0.000000',
            leaseTerm: null,
            leaseCompanyName: null,
            phoneExtension: null,
            addressLine1: null,
            addressLine2: null,
            city: null,
            state: null,
            postalCode: '',
            leaseHolderName: null,
            bestTimeToCall: null,
            verifiedDate:
              Residences.verifiedCheckBox !== undefined &&
                Residences.verifiedCheckBox !== null &&
                Residences.verifiedCheckBox !== ''
                ? buildDate(Residences.verifiedCheckBox)
                : '',
          },
        ];
      }
      // eslint-disable-next-line no-console
      console.log('ResidencesObj', ResidencesObj);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e, 'landlordReferences');
    }
    return ResidencesObj;
  };
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const validateNextCustomer = (Type: string) => {
    // if (createActiveTab == 0) {
    //   getEmployOptions();
    // }
    if (Type == 'Next') {
      const validate = validateCreateCustomer();
      if (!validate) {
        setErrorPopup(true);
        setisrequired(true);
      } else {
        if (
          validate &&
          ((DEStatus.approvalStatus != DECISION_ENGINE_APPROVED && createActiveTab == 2) ||
            (DEStatus.approvalStatus == DECISION_ENGINE_APPROVED && createActiveTab == 0))
        ) {
          setCreateActiveTab(createActiveTab + 1);
          setnextBtnText('Finish');
        } else if (validate && nextBtnText != 'Finish') {
          setCreateActiveTab(createActiveTab + 1);
        } else if (validate && nextBtnText == 'Finish') {
          updateCustomerInfo();
        }
        setisrequired(false);
      }
    } else if (Type == 'Back') {
      setCreateActiveTab(createActiveTab - 1);
      setnextBtnText('Next');
    }
  };
  // const CancelRedirect = () => {
  //   const previousPageUrl = document.referrer;
  //   if (
  //     previousPageUrl !== undefined &&
  //     previousPageUrl.includes('am/customer')
  //   ) {
  //     props.history.push(`${AppRoute.accountManagementCustomer}/${customerId}`);
  //   } else {
  //     props.history.push(`/dashboard`);
  //   }
  // };
  const validateCreateCustomer = () => {
    let isValid = false;
    try {
      if (createActiveTab == 0) {
        isValid = validateTabOne() && validateCustomerTab() ? true : false;
      } else if (createActiveTab == 1) {
        isValid = validateTabTwo().isValidate;
      } else if (createActiveTab == 2) {
        isValid = validateTabThree().isValidate;
      } else if (createActiveTab == 3) {
        isValid = validateTabFour().isValidate;
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e, 'landlordReferences');
    }
    return isValid;
  };
  const hideErrorPopUp = () => {
    setErrorPopup(false);
    setValidateResult([]);
  };
  const pageLoadStateVariable = (getOptions: any) => {
    //const No = false;

    // eslint-disable-next-line react-hooks/exhaustive-deps
    /* eslint-disable no-console */
    console.log('getOptions', getOptions);
    setrel(buildDropdownList(getOptions.relationshipType));
    setgovTypeId(buildDropdownList(getOptions.governmentIdTypeOptions));
    setLegalOption(buildDropdownList(getOptions.resLegalHoldList));
    setsalutation(buildDropdownList(getOptions.salutationOptions));
    setSuffix(buildDropdownList(getOptions.nameSuffix, undefined, true));
    const buildStateDropDown: dropdown[] = [];
    if (
      getOptions.stateList != undefined &&
      getOptions.stateList != null &&
      getOptions.stateList.getStateResponse !== undefined &&
      getOptions.stateList.getStateResponse !== null &&
      getOptions.stateList.getStateResponse.length > 0
    ) {
      getOptions.stateList.getStateResponse.map((value: any, index: any) => {
        if (index == 0) {
          buildStateDropDown.push({ label: 'Select', value: '0' });
        }
        buildStateDropDown.push({
          label: value.abbreviation,
          value: value.abbreviation,
        });
      });
    }
    setstate(buildStateDropDown);
    setaddressinfoState(buildStateDropDown);
    setbestTimeToCall(buildDropdownList(getOptions.callTimeList, true));
    setphoneType(buildDropdownList(getOptions.phoneTypeList, true));
    setpreferredMethod(buildDropdownList(getOptions.contactMethodList));
    const buildRouteDropDown: dropdown[] = [];
    if (
      getOptions.getStoreRoutes != undefined &&
      getOptions.getStoreRoutes != null &&
      getOptions.getStoreRoutes.routeIds !== undefined &&
      getOptions.getStoreRoutes.routeIds !== null &&
      getOptions.getStoreRoutes.routeIds.length > 0
    ) {
      getOptions.getStoreRoutes.routeIds.map((value: any) => {
        buildRouteDropDown.push({
          label: value.description,
          value: value.routeRefCode,
        });
      });
    }
    setaddressInfoRoute(buildRouteDropDown);
    settaxExemptStatus(buildDropdownList(getOptions.taxExemptStatusList));
    setDropDownLoaded(true);
  };
  const buildDropdownList = (
    optionsList: any,
    sortSeq?: boolean,
    isSuffix?: boolean
  ) => {
    const options: dropdown[] = [];
    if (
      optionsList != undefined &&
      optionsList != null &&
      optionsList.length > 0
    ) {
      sortSeq === true
        ? optionsList.sort((a: any, b: any) =>
          a['displaySeq'] < b['displaySeq'] ? -1 : 1
        )
        : optionsList;
      optionsList.map((value: any, index: any) => {
        if (index == 0) {
          options.push({ label: 'Select', value: '0' });
        }
        options.push({
          label: isSuffix === true ? value.referenceCode : value.description,
          value: value.referenceCode,
        });
      });
    }
    return options;
  };
  function openAlert() {
    setIsOpen(true);
  }

  function closeAlert() {
    setIsOpen(false);
  }
  const getAlertsLoad = async (popUpAlerts: any, customerAlerts: any) => {
    /* eslint-disable no-console */
    console.log('result inside getAlerts', popUpAlerts);
    await buildActiveAlerts(customerAlerts);
    //Alert list
    setobjAlertdata(popUpAlerts ? popUpAlerts?.alert !== undefined ? popUpAlerts.alert : [] : []);
  };
  const buildActiveAlerts = async (customerAlerts?: any) => {
    let getAlerts: any = customerAlerts;
    //if (updatedAlert == undefined || updatedAlert == null) {
    if (customerAlerts == undefined || customerAlerts == null) {
      getAlerts = await GetCustomerAlert(customerId);
    }

    getAlerts =
      getAlerts?.customerAlertByIdDTO?.value?.customeralert !== undefined
        ? getAlerts?.customerAlertByIdDTO?.value?.customeralert
        : undefined;

    const activeAlerts: any =
      getAlerts !== undefined
        ? getAlerts.filter((val: any) => val.alertCleared == 0)
        : [];
    const popupAlerts: any =
      getAlerts !== undefined
        ? getAlerts.filter(
          (val: any) =>
            val.alertCleared == 0 &&
            val.alertTypeId !== '3' &&
            val.alertTypeId !== 3
        )
        : [];

    const alertLoad: any = [];
    let activeAlertcount = 0;
    if (getAlerts !== undefined && getAlerts.length > 0) {
      for (let i = 0; i < getAlerts.length; i++) {
        alertLoad.push({
          alertTypeId: getAlerts[i].alertTypeId,
          alertCleared: getAlerts[i].alertCleared,
        });
        if (
          getAlerts[i].alertCleared == 0 ||
          getAlerts[i].alertCleared == '0'
        ) {
          activeAlertcount = activeAlertcount + 1;
        }
      }
    }
    setAlertLoadCount(activeAlertcount);
    setAlertLoad(alertLoad);
    setAlertWizard(getAlerts !== undefined ? getAlerts : []);
    //active alert
    setActiveAlert(activeAlerts);
    //Alerts without custom alerts
    setAlertUpdateObj([...popupAlerts]);
  };

  const CustomAlertval = (e: any) => {
    // //;
    const alertValue = formatTextChange(e.target.value);
    setCustomAlerttxt(alertValue);
    document.getElementById('CustomAlert')?.focus();
    if (alertValue == undefined || alertValue == null || alertValue == '') {
      setdisableAlert(true);
    } else {
      setdisableAlert(false);
    }
  };
  const Assignalertclear = (
    action: 'add' | 'remove',
    newAlert: any,
    alertTypeId?: any
    // eslint-disable-next-line sonarjs/cognitive-complexity
  ) => {
    // if (
    //   (alertTypeId == '2' || alertTypeId == '4' || alertTypeId == '5') &&
    //   action == 'add'
    // ) {
    //   setAlertAuthenticationNeeded(
    //     action ? newAlert : { removeAlert: true, alertTypeId: alertTypeId }
    //   );
    //   // setauthenType('ALERT');
    //   // racCoWorkerFunc('Assign Alert');
    // }

    let activeAlertObj: any = AlertUpdateObj;
    if (
      alertTypeId !== undefined &&
      alertTypeId !== null &&
      alertTypeId == '3'
    ) {
      if (action == 'remove') {
        setEnabletxt(true);
        setdisableAlert(false);
      } else {
        setEnabletxt(false);
        setdisableAlert(true);
      }
    }
    activeAlertObj = alterAlertValue(action, newAlert, alertTypeId);
    /* eslint-disable no-console */
    // console.log('val', val);

    setAlertUpdateObj([...activeAlertObj]);
    //reloadActiveActive();
  };

  const alterAlertValue = (
    action: 'add' | 'remove',
    newAlert: any,
    alertTypeId?: any
    // eslint-disable-next-line sonarjs/cognitive-complexity
  ) => {
    let activeAlertObj: any = AlertUpdateObj;

    if (action == 'remove') {
      if (alertTypeId != '3') {
        const editAlert = activeAlertObj.findIndex(
          (val: any) => val.alertTypeId == alertTypeId
        );
        if (editAlert !== -1) {
          activeAlertObj[editAlert].alertCleared = 1;
        }
      } else {
        activeAlertObj = activeAlertObj.filter(
          (val: any) => val.alertTypeId != '3'
        );
      }
    } else {
      // if (alertTypeId !== '2' && alertTypeId !== '4' && alertTypeId !== '5') {
      const editAlert = activeAlertObj.findIndex(
        (val: any) => val.alertTypeId == alertTypeId
      );
      if (editAlert !== -1) {
        activeAlertObj[editAlert].alertCleared = 0;
      } else {
        newAlert.alertCleared = 0;
        activeAlertObj.push(newAlert);
      }
      // }
    }
    return activeAlertObj;
  };
  const RemoveAlert = async (removeAlert: any, index: any) => {
    try {
      // const ObjAlertWizard: any = AlertWizard;
      // ObjAlertWizard.splice(index, 1);
      const payload: any = {
        alertTypes: removeAlert,
        customerId: parseInt(customerId),
        userId: '191197',
      };

      /* eslint-disable no-console */
      console.log('payload', payload);
      /* eslint-disable no-console */
      setIsAlertLoading(true);
      const response: any = await UpdateAlert(payload, customerId);

      /* eslint-disable no-console */
      console.log('response', response);
      /* eslint-disable no-console */
      if (response !== undefined && response !== null) {
        buildActiveAlerts();
        //getAlerts();
      }
      setIsAlertLoading(false);
    } catch (e: any) {
      console.log('error while removing alert', e);
      setIsAlertLoading(false);
    }
  };
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const Assignalert = () => {
    if (
      objAlertdata !== undefined &&
      objAlertdata !== null &&
      objAlertdata.length > 0
    ) {
      const objAlertdataL = objAlertdata.sort((a: any, b: any) =>
        a['alertTypeDescEn'] < b['alertTypeDescEn'] ? -1 : 1
      );
      return objAlertdataL.map((val: any, index: any) => {
        //const AlertWizardList: any = [];
        let inActiveAlert: any = [];
        const isAlertActive: boolean =
          AlertUpdateObj.filter(
            (val1: any) =>
              val1.alertTypeId == val.alertTypeId && val1.alertCleared == 0
          ).length > 0
            ? true
            : false;

        if (isAlertActive === false) {
          // inActiveAlert = inActiveAlerts.filter(
          //   (val1: any) => val1.alertTypeId == val.alertTypeId
          // );
          inActiveAlert = {
            alertText: '',
            alertTypeDescEn: val.alertTypeDescEn,
            alertTypeDescEs: val.alertTypeDescEs,
            alertCleared: 0,
            alertTypeId: val.alertTypeId,
            customerAlertId: '',
          };
        }

        let isOtherAlert = false;
        if (index == objAlertdataL.length - 1) {
          isOtherAlert =
            AlertUpdateObj.filter((val1: any) => val1.alertTypeId == '3')
              .length > 0
              ? true
              : false;
        }

        return index == objAlertdataL.length - 1 ? (
          <React.Fragment>
            {buildAlert(isAlertActive, index, inActiveAlert, val)}
            {buildAlert(
              isOtherAlert,
              index + 1,
              {
                alertCleared: 0,
                alertText: '',
                alertTypeDescEn: 'Other',
                alertTypeDescEs: null,
                alertTypeId: '3',
                customerAlertId: '',
              },
              {
                alertTypeDescEn: 'Other',
                alertTypeDescEs: null,
                alertTypeId: '3',
              }
            )}
          </React.Fragment>
        ) : val.alertTypeId !== '3' ? (
          buildAlert(isAlertActive, index, inActiveAlert, val)
        ) : null;
      });
    } else if (objAlertdata.length == 0) {
      return (
        <Grid
          item
          className={`${classes.racCol2} ${customerStyleClasses.spacerMR2}`}
        >
          <Grid
            item
            style={{ marginLeft: '160px' }}
            className={`${customerStyleClasses.customerNorecordsAlert} ${classes.alertLoader} ${customerStyleClasses.ms2}`}
          >
            <CircularProgress className={customerStyleClasses.alertLoader} />
          </Grid>
        </Grid>
      );
    }
  };

  const buildAlert = (
    isAlertExist: boolean,
    index: any,
    addAlertObj: any,
    val: any
  ) => {
    return (
      <Grid className={`${classes.p0} ${customerStyleClasses.mb3}`} key={index}>
        <RACCheckBox
          name="assignAlertChecked"
          checked={isAlertExist}
          onChange={() =>
            Assignalertclear(
              isAlertExist === false ? 'add' : 'remove',
              addAlertObj,
              val.alertTypeId
            )
          }
        />
        {val.alertTypeDescEn}
      </Grid>
    );
  };
  const AlertMessages = () => {
    if (activeAlert !== undefined && activeAlert !== null) {
      return activeAlert.map((val: any, index: any) => {
        const alertActive = AlertLoad.findIndex(
          (alert: any) =>
            alert.alertTypeId == val.alertTypeId && alert.alertCleared == 0
        );
        if (alertActive !== -1) {
          return (
            <span
              className={`${classes.alertWidgetStyle} ${customerStyleClasses.me2}`}
              key={index}
              id={index}
            >
              {
                /*customAlertSpan ? null : (*/
                val.alertTypeId !== '3' && val.alertTypeId !== 3 ? (
                  <a
                    onClick={(e: any) => ScrollToPage(val.alertTypeId, e)}
                    className={`${customerStyleClasses.me2} ${customerStyleClasses.customerPointercursor}`}
                  >
                    <ExpandAlert></ExpandAlert>
                  </a>
                ) : null
                /*)*/
              }
              {val.alertTypeDescEn == 'Other'
                ? val.alertText
                : val.alertTypeDescEn}
              <a
                className={`${customerStyleClasses.ms2} ${customerStyleClasses.customerPointercursor}`}
              >
                <ExpandCancel
                  onClick={() =>
                    RemoveAlert(
                      [
                        {
                          alertTypeId: parseInt(val.alertTypeId),
                          customerAlertId: parseInt(val.customerAlertId),
                          alertText: '',
                          alertClear: 1,
                        },
                      ],
                      index
                    )
                  }
                ></ExpandCancel>
              </a>
            </span>
          );
        }
      });
    }
  };
  const ScrollToPage = (Id: any, e: any) => {
    if (e !== null) {
      e.preventDefault();
    }
    const elementId: string =
      Id == '6'
        ? 'addressDiv'
        : Id == '9'
          ? 'ParentContactInformation'
          : Id == '4' || Id == '2' || Id == '5'
            ? 'LegalDiv'
            : '';

    const Tab: any = Id == '7' ? 1 : Id == '11' ? 2 : '';
    if (elementId !== '') {
      const titleElement = document.getElementById(elementId);
      if (titleElement !== null) {
        titleElement.scrollIntoView({ behavior: 'smooth' });
      }

      //Need to add redirection to Paymentschedule and capture agreement signture
    }
    if (Tab !== '') {
      setactivetab(Tab);
    }
  };
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const Assignsave_click = async () => {
    try {
      const removeAlert: any = [];
      const AlertWizardObj: any = AlertWizard;
      // eslint-disable-next-line no-console
      console.log('AlertUpdateObj', AlertUpdateObj);
      for (let i = 0; i < AlertUpdateObj.length; i++) {
        const alertExist = AlertWizardObj.filter(
          (val: any) => AlertUpdateObj[i].alertTypeId == val.alertTypeId
        );
        if (
          AlertUpdateObj[i].customerAlertId !== null &&
          AlertUpdateObj[i].alertCleared !== 1
          // eslint-disable-next-line sonarjs/no-duplicated-branches
        ) {
          removeAlert.push({
            alertTypeId: parseInt(AlertUpdateObj[i].alertTypeId),
            customerAlertId:
              AlertUpdateObj[i].customerAlertId !== undefined &&
                AlertUpdateObj[i].customerAlertId !== null &&
                AlertUpdateObj[i].customerAlertId !== ''
                ? parseInt(AlertUpdateObj[i].customerAlertId)
                : alertExist.length > 0 && AlertUpdateObj[i].alertTypeId != '3'
                  ? parseInt(alertExist[0].customerAlertId)
                  : null,
            alertText:
              AlertUpdateObj[i].alertTypeId == '3' ? CustomAlerttxt : '',
            alertClear: AlertUpdateObj[i].alertCleared,
          });
        } else if (
          AlertUpdateObj[i].customerAlertId !== null &&
          AlertUpdateObj[i].alertCleared == 1
          // eslint-disable-next-line sonarjs/no-duplicated-branches
        ) {
          removeAlert.push({
            alertTypeId: parseInt(AlertUpdateObj[i].alertTypeId),
            customerAlertId:
              AlertUpdateObj[i].customerAlertId !== undefined &&
                AlertUpdateObj[i].customerAlertId !== null &&
                AlertUpdateObj[i].customerAlertId !== ''
                ? parseInt(AlertUpdateObj[i].customerAlertId)
                : alertExist.length > 0 && AlertUpdateObj[i].alertTypeId != '3'
                  ? parseInt(alertExist[0].customerAlertId)
                  : null,
            alertText:
              AlertUpdateObj[i].alertTypeId == '3' ? CustomAlerttxt : '',
            alertClear: AlertUpdateObj[i].alertCleared,
          });
        }
      }
      let removeAlertObj = removeAlert;
      removeAlertObj = removeAlertObj.filter(
        (val: any) =>
          (val.customerAlertId == undefined || val.customerAlertId == null) &&
          val.alertClear == 1
      );
      const AlertObj: any = [];
      for (let i = 0; i < removeAlert.length; i++) {
        const inValidAlert = removeAlertObj.filter(
          (val: any) => val.alertTypeId == removeAlert[i].alertTypeId
        );
        if (inValidAlert.length == 0) {
          AlertObj.push(removeAlert[i]);
        }
      }
      const payload: any = {
        alertTypes: AlertObj,
        customerId: parseInt(customerId),
        userId: '191197',
      };
      /* eslint-disable no-console */
      console.log('payload', payload);
      /* eslint-disable no-console */
      setIsAlertLoading(true);
      const response: any = await UpdateAlert(payload, customerId);

      // eslint-disable-next-line no-console
      setCustomAlerttxt('');
      setEnabletxt(true);
      console.log('response', response);
      if (response !== undefined && response !== null) {
        await buildActiveAlerts();
      }
      setIsAlertLoading(false);
      //getAlerts();
      closeAlert();
    } catch (e: any) {
      setIsAlertLoading(false);
      //getAlerts();
      closeAlert();
    }
  };

  // const Cancelclick = () => {
  //   ;
  //   const activeAlertObj = activeAlert;
  //   if (activeAlertObj.length > 0) {
  //     for (let i = 0; i < activeAlertObj.length; i++) {
  //       const alertActive = AlertLoad.findIndex(
  //         (alert: any) => alert.alertTypeId == activeAlertObj[i].alertTypeId
  //       );
  //       if (alertActive !== -1) {
  //         activeAlertObj[i].alertCleared = AlertLoad[alertActive].alertCleared;
  //       } else {
  //         activeAlertObj[i].alertCleared = 1;
  //       }
  //     }
  //   }
  //   setAlertUpdateObj(activeAlertObj);
  //   setEnabletxt(true);
  //   setCustomAlerttxt('');
  //   closeAlert();
  // };
  const Cancelclick = () => {
    const activeAlertObj = activeAlert;
    //added
    const actAlert: any = [];
    if (activeAlertObj.length > 0) {
      for (let i = 0; i < activeAlertObj.length; i++) {
        const alertActive = AlertLoad.findIndex(
          (alert: any) => alert.alertTypeId == activeAlertObj[i].alertTypeId
        );
        if (alertActive !== -1) {
          //added
          const al: any = activeAlertObj[i];
          // if (al.alertTypeId == '3') {
          //   al.alertCleared = 1;
          // } else {
          al.alertCleared = AlertLoad[alertActive].alertCleared;
          // }
          actAlert.push(al);
          //ends
          activeAlertObj[i].alertCleared = AlertLoad[alertActive].alertCleared;
        }
      }
    }
    //added
    const filterOtherCheckBox: any = actAlert.filter(
      (el: any) => el.alertTypeId !== 3 && el.alertTypeId !== '3'
    );
    setAlertUpdateObj([...filterOtherCheckBox]);
    setEnabletxt(true);
    setCustomAlerttxt('');
    setdisableAlert(false);
    closeAlert();
  };
  const Taxidclick = () => {
    setIstextboxdisable(false);
    if (
      (taxexempt_toggle === true && taxExSt !== null) ||
      (taxexempt_toggle === false && taxExSt == null)
    ) {
      setTaxexempt(true);
      setTaxIDActive(true);
    } else if (taxexempt_toggle === true && taxExSt == null) {
      setTaxexempt(false);
      setTaxIDActive(false);
    }
  };

  function ValidateEmail(inputText: any) {
    // eslint-disable-next-line no-useless-escape
    const mailformat = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    return mailformat.test(inputText);
  }

  const dateFormat = 'MM/DD/YYYY';
  // eslint-disable-next-line sonarjs/no-duplicate-string
  const phoneFormat = '($1) $2-$3';

  const ssn1Func = (e: any) => {
    // if (e.target.value.length === 3) {
    //   (document.getElementById('a11y_ssntwo') as HTMLInputElement).focus();
    // }
    const ssn2El: any = document.getElementById('a11y_ssntwo');
    const ssn1El: any = document.getElementById('a11y_ssnone');
    if (e.target.value.length === 3) {
      if (ssn1El == document.activeElement) {
        ssn2El.focus();
      }
    }
  };

  const ssn2Func = (e: any) => {
    // if (e.target.value.length === 2) {
    //   (document.getElementById('a11y_ssnthree') as HTMLInputElement).focus();
    // } else if (
    //   e.target.value.length === 0 &&
    //   customerInformation.ssn1.length > 0
    // ) {
    //   (document.getElementById('a11y_ssnone') as HTMLInputElement).focus();
    // }
    const ssn3El: any = document.getElementById('a11y_ssnthree');
    const ssn1El: any = document.getElementById('a11y_ssnone');
    const ssn2El: any = document.getElementById('a11y_ssntwo');
    if (e.target.value.length === 2) {
      if (ssn2El == document.activeElement) {
        ssn3El.focus();
      }
    }
    if (e.target.value.length === 0 && customerInformation?.ssn1.length > 0) {
      if (ssn2El == document.activeElement) {
        ssn1El.focus();
      }
    }
  };

  const ssn3Func = (e: any) => {
    // if (e.target.value.length === 0 && customerInformation.ssn2.length > 0) {
    //   (document.getElementById('a11y_ssntwo') as HTMLInputElement).focus();
    // }
    const ssn3El: any = document.getElementById('a11y_ssnthree');
    const ssn2El: any = document.getElementById('a11y_ssntwo');
    if (e.target.value.length === 0 && customerInformation?.ssn2.length > 0) {
      if (ssn3El == document.activeElement) {
        ssn2El.focus();
      }
    }
  };

  const mailingAddress = () => {
    setcustomerInfoChange(true);
    const d: any = document.getElementsByName('MailingAddres');
    if (d[0].checked) {
      (
        document.getElementById('MailingAddressField') as HTMLInputElement
      ).focus();
      setcustomerInformation({ ...customerInformation, mailAddress: 'Y' });

      seteditInputBoxM(true);
    } else {
      setcustomerInformation({ ...customerInformation, mailAddress: 'N' });
      seteditInputBoxM(false);
    }
  };

  const formatTextChange = (value: any) => {
    return value !== undefined && value !== null && value.trim().length == 0
      ? value.trim()
      : value;
  };
  const capitalize = (input: any) => {
    const words: any = input.split(' ');
    const CapitalizedWords: any = [];
    words.forEach((element: any) => {
      CapitalizedWords.push(
        element[0].toUpperCase() + element.slice(1, element.length)
      );
    });
    return CapitalizedWords.join(' ');
  };
  const HandleOnChange = (e: any) => {
    setcustomerInfoChange(true);
    if (e.target.name === 'FirstName') {
      if (
        e.target.value &&
        e.target.value.length > 0 &&
        e.target.value.charAt(e.target.value.length - 1) !== ' '
      ) {
        const char: any = capitalize(e.target.value);
        setcustomerInformation({
          ...customerInformation,
          firstName: formatTextChange(char.replace(/[^A-Z-'.,’ a-z]/gi, '')),
        });
      } else {
        setcustomerInformation({
          ...customerInformation,
          firstName: formatTextChange(
            formatTextChange(e.target.value.replace(/[^A-Z-'.,’ a-z]/gi, ''))
          ),
        });
      }
    } else if (e.target.name === 'LastName') {
      if (
        e.target.value &&
        e.target.value.length > 0 &&
        e.target.value.charAt(e.target.value.length - 1) !== ' '
      ) {
        const char: any = capitalize(e.target.value);
        setcustomerInformation({
          ...customerInformation,
          lastName: formatTextChange(char.replace(/[^A-Z-'.,’ a-z]/gi, '')),
        });
      } else {
        setcustomerInformation({
          ...customerInformation,
          lastName: formatTextChange(
            formatTextChange(e.target.value.replace(/[^A-Z-'.,’ a-z]/gi, ''))
          ),
        });
      }
    } else if (e.target.name === 'Dob') {
      const InputTxt = e.target.value;
      /* eslint-disable no-console */
      console.log('InputTxt', InputTxt);
      const date: any = buildDateFormat2(InputTxt);
      /* eslint-disable no-console */
      console.log('InputTxt', InputTxt);
      const temp = InputTxt.replace(date);
      /* eslint-disable no-console */
      console.log('InputTxt', InputTxt);
      setcustomerInformation({ ...customerInformation, dateOfBirth: temp });
    }
  };
  const HandleOnChange1 = (e: any) => {
    setcustomerInfoChange(true);
    if (e.target.name === 'Ssn1') {
      setisMaskedDataHasChanged(((prev: any) => {
        return {
          ...prev, ssn: true
        }
      }))
      setcustomerInformation({
        ...customerInformation,
        ssn1: e.target.value.replace(/\D/g, ''),
      });

      ssn1Func(e);
    } else if (e.target.name === 'Ssn2') {
      setisMaskedDataHasChanged(((prev: any) => {
        return {
          ...prev, ssn: true
        }
      }))
      setcustomerInformation({
        ...customerInformation,
        ssn2: e.target.value.replace(/\D/g, ''),
      });
      ssn2Func(e);
    } else if (e.target.name === 'Ssn3') {
      setisMaskedDataHasChanged(((prev: any) => {
        return {
          ...prev, ssn: true
        }
      }))
      setcustomerInformation({
        ...customerInformation,
        ssn3: e.target.value.replace(/\D/g, ''),
      });
      ssn3Func(e);
    } else if (e.target.name === 'Dob1') {
      // const InputTxt = e.target.value;
      // const format: any = buildDate(e.target.value);
      // const temp = InputTxt.replace(format);
      setcustomerInformation({
        ...customerInformation,
        dateOfBirth: buildDate(e.target.value),
      });
    }
  };
  const HandleOnChange2 = (e: any) => {
    // eslint-disable-next-line
    ////;
    setcustomerInfoChange(true);
    if (e.target.name === 'GovernIDtype') {
      setisMaskedDataHasChanged(((prev: any) => {
        return {
          ...prev, govtId: true
        }
      }))
      const customerInformationObj = customerInformation;
      customerInformationObj.governmentIdType = e.target.value;
      if (e.target.value == 'NONE' || e.target.value == '0') {
        customerInformationObj.governmentId = '';
        customerInformationObj.governmentIdIssuedState = null;
      }
      if (e.target.value == 'PASS') {
        customerInformationObj.governmentIdIssuedState = null;
      }
      setcustomerInformation({
        ...customerInformationObj,
      });
      //governIdType(e);
    } else if (e.target.name === 'Emailaddress') {
      setcustomerInformation({
        ...customerInformation,
        emailAddress: formatTextChange(e.target.value),
      });
      //ValidateEmail(e.target.value);
    } else if (e.target.name === 'GovernIDnum') {
      setcustomerInformation({
        ...customerInformation,
        governmentId: e.target.value.replace(/[^A-Z-'., a-z0-9]/gi, ''),
      });
    } else if (e.target.name === 'Middleinitial') {
      setcustomerInformation({
        ...customerInformation,
        middleInitial: e.target.value.replace(/[^A-Za-z]/gi, ''),
      });
    }
  };
  const HandleOnChange3 = (e: any) => {
    setcustomerInfoChange(true);
    if (e.target.name === 'stateForBasicInfo') {
      setcustomerInformation({
        ...customerInformation,
        governmentIdIssuedState: e.target.value,
      });
    } else if (e.target.name === 'Salutation') {
      setcustomerInformation({
        ...customerInformation,
        salutation: e.target.value !== '0' ? e.target.value : null,
      });
    } else if (e.target.name === 'JrSr') {
      setcustomerInformation({
        ...customerInformation,
        suffix: e.target.value,
      });
    } else if (e.target.name === 'RAC_Co_worker') {
      const value = customerInformation?.racCoworker == 'N' ? true : false;

      if (value) {
        setcoworkerPinAuth(true);
        setauthenType('RACWORKER');
        racCoWorkerFunc('RAC Coworker');
      } else {
        setcoworkerPinAuth(false);
        setauthenType('RACWORKER');
        racCoWorkerFunc('RAC Coworker');
        // setcustomerInformation({
        //   ...customerInformation,
        //   racCoworker: 'N',
        // });
      }
    }
  };
  const handleOnchangeSkipHard = (type: 'S' | 'H') => {
    setskipHardObj(type);
    setauthenType('SKIP');
    racCoWorkerFunc('Assign Alert');
  };
  const HandleOnChange4 = (e: any) => {
    setcustomerInfoChange(true);
    if (e.target.name === 'AddressLine1') {
      setUpdatePrimaryAddress({
        ...updatePrimaryAddress,
        address: formatTextChange(e.target.value),
      });
    } else if (e.target.name === 'AddressLine2') {
      setUpdatePrimaryAddress({
        ...updatePrimaryAddress,
        address1: formatTextChange(e.target.value),
      });
    }
  };
  const HandleOnChange5 = (e: any) => {
    setcustomerInfoChange(true);
    if (e.target.name === 'Zip') {
      let cleaned = ('' + e.target.value).replace(/\D/g, '');
      cleaned =
        cleaned.length > 5
          ? cleaned.slice(0, 5) + '-' + cleaned.slice(5)
          : cleaned;
      setUpdatePrimaryAddress({ ...updatePrimaryAddress, zip: cleaned });
    } else if (e.target.name === 'City') {
      setUpdatePrimaryAddress({
        ...updatePrimaryAddress,
        city: e.target.value.replace(/[^a-zA-Z ]/g, ''),
      });
    } else if (e.target.name === 'AddressInfoState') {
      setUpdatePrimaryAddress({
        ...updatePrimaryAddress,
        state: e.target.value,
      });
    } else if (e.target.name == 'MailingAddres') {
      mailingAddress();
    }
  };
  const HandleOnChange41 = (e: any) => {
    setcustomerInfoChange(true);
    if (e.target.name === 'AddressLine1M') {
      setupdateMailingAddress({
        ...updateMailingAddress,
        address: formatTextChange(e.target.value),
      });
    } else if (e.target.name === 'AddressLine2M') {
      setupdateMailingAddress({
        ...updateMailingAddress,
        address1: formatTextChange(e.target.value),
      });
    }
  };
  const HandleOnChange51 = (e: any) => {
    setcustomerInfoChange(true);
    if (e.target.name === 'ZipM') {
      let cleaned = ('' + e.target.value).replace(/\D/g, '');
      cleaned =
        cleaned.length > 5
          ? cleaned.slice(0, 5) + '-' + cleaned.slice(5)
          : cleaned;
      setupdateMailingAddress({ ...updateMailingAddress, zip: cleaned });
    } else if (e.target.name === 'CityM') {
      setupdateMailingAddress({
        ...updateMailingAddress,
        city: e.target.value.replace(/[^a-zA-Z ]/g, ''),
      });
    } else if (e.target.name === 'AddressInfoStateM') {
      setupdateMailingAddress({
        ...updateMailingAddress,
        state: e.target.value,
      });
    }
  };
  const HandleOnChange6 = (e: any) => {
    setcustomerInfoChange(true);
    if (e.target.name === 'tax_id') {
      setcustomerInformation({
        ...customerInformation,
        federalTaxId: e.target.value,
      });
    } else if (e.target.name === 'tax_status') {
      setcustomerInformation({
        ...customerInformation,
        status: e.target.value,
      });
    } else if (e.target.name === 'tax_EffectiveDate') {
      setcustomerInformation({
        ...customerInformation,
        effectiveDate: e.target.value,
      });
    } else if (e.target.name === 'tax_ExpirationDate') {
      setcustomerInformation({
        ...customerInformation,
        expirationDate: e.target.value,
      });
    }
  };
  const HandleOnChange7 = (e: any) => {
    setcustomerInfoChange(true);
    if (e.target.name === 'tax_appDenailDate') {
      setcustomerInformation({
        ...customerInformation,
        appDenialDate: e.target.value,
      });
    }

    //comm Preferences...
    else if (e.target.name == 'PaymentReminderCall') {
      setcustomerInformation({
        ...customerInformation,
        paymentRemainderCall: e.target.value,
      });
    } else if (e.target.name === 'TextMessageReminder') {
      setcustomerInformation({
        ...customerInformation,
        textMessageRemainder: e.target.value,
      });
    }
  };
  const HandleOnChange8 = (e: any) => {
    setcustomerInfoChange(true);
    if (e.target.name === 'PreferredPaymentReceipt') {
      setcustomerInformation({
        ...customerInformation,
        preferredPaymentReceipt: e.target.value,
      });
    } else if (e.target.name === 'AcceptSolicitation') {
      setcustomerInformation({
        ...customerInformation,

        phoneSolicitationAllowed: e.target.value,
      });
    } else if (e.target.name === 'PreferredContactMethod') {
      setcustomerInformation({
        ...customerInformation,
        preferredMethod: e.target.value,
      });
    } else if (e.target.name === 'AcceptSolicitation1') {
      setcustomerInformation({
        ...customerInformation,

        mailSolicitationAllowed: e.target.value,
      });
    } else if (e.target.name === 'AcceptSolicitation2') {
      setcustomerInformation({
        ...customerInformation,

        emailSolicitationAllowed: e.target.value,
      });
    } else if (e.target.name === 'PreferredLanguage') {
      setcustomerInformation({
        ...customerInformation,
        preferredLanguage: e.target.value,
      });
    }
  };
  const HandleOnChange9 = (e: any, isRent?: 'R' | 'O') => {
    // eslint-disable-next-line
    // 
    if (e.target.name === 'PreferredRoute') {
      setPreferrefRoute(e.target.value);
      racCoWorkerFunc('Modify Customer Route');
      setauthenType('ROUTES');
    } else if (e.target.name === 'residenceInfoVerifiedCheckBox') {
      let llVerifiedDate = Residences.verifiedCheckBox;
      let ownVerifiedDate = Residences.ownVerifieddate;
      if (isRent == 'R' && e.target.checked) {
        llVerifiedDate = new Date();
      } else {
        llVerifiedDate = '';
      }
      if (isRent == 'O' && e.target.checked) {
        ownVerifiedDate = new Date();
      } else {
        ownVerifiedDate = '';
      }

      setResidencesvalues({
        ...Residences,
        verifiedCheckBox: llVerifiedDate,
        ownVerifieddate: ownVerifiedDate,
      });

      //setresVerified(true);
    }
    setcustomerInfoChange(true);
    verification(
      undefined,
      undefined,
      true,
      isRent,
      e.target.checked === true ? new Date() : ''
    );
  };
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const HandleOnChange10 = (e: any) => {
    // eslint-disable-next-line
    setcustomerInfoChange(true);
    if (e.target.name === 'inlineRadioOptions') {
      setResidencesvalues({
        ...Residences,
        residenceType: 'RENT',
        mortgage: false,
        ownVerifieddate:
          e.target.checked === true ? '' : Residences.ownVerifieddate,
      });

      //setresidenceverifiedCheckBox(false);
    } else if (e.target.name === 'inlineRadioOptionsOwn') {
      setResidencesvalues({
        ...Residences,
        residenceType: 'OWN',
        verifiedCheckBox:
          e.target.checked === true ? '' : Residences.verifiedCheckBox,
      });
      //setresidenceverifiedCheckBox(false);
    } else if (e.target.name === 'inlinemortagechk') {
      setResidencesvalues({
        ...Residences,
        mortgage: e.target.checked,
        mortgageCompanyName:
          e.target.checked === false ? '' : Residences.mortgageCompanyName,
        residenceSince:
          e.target.checked === false ? '' : Residences.residenceSince,
        monthlyMortgagePayment:
          e.target.checked === false
            ? '0.00'
            : Residences.monthlyMortgagePayment,
        verifiedCheckBox:
          Residences.residenceType === 'RENT'
            ? ''
            : Residences.verifiedCheckBox,
        ownVerifieddate:
          Residences.residenceType === 'OWN' ? '' : Residences.ownVerifieddate,
      });
      //setresidenceverifiedCheckBox(false);
    } else if (e.target.name === 'llname') {
      setResidencesvalues({
        ...Residences,
        landlordFirstName: formatTextChange(e.target.value).replace(
          /[^A-Z'.;’ a-z]/gi,
          ''
        ),
        verifiedCheckBox:
          Residences.residenceType === 'RENT'
            ? ''
            : Residences.verifiedCheckBox,
        ownVerifieddate:
          Residences.residenceType === 'OWN' ? '' : Residences.ownVerifieddate,
      });
      //setResidencesvalues({...Residences, verifiedCheckBox: false});
    } else if (e.target.name === 'llLastname') {
      setResidencesvalues({
        ...Residences,
        landlordLastName: formatTextChange(e.target.value).replace(
          /[^A-Z'.;’ a-z]/gi,
          ''
        ),
        verifiedCheckBox:
          Residences.residenceType === 'RENT'
            ? ''
            : Residences.verifiedCheckBox,
        ownVerifieddate:
          Residences.residenceType === 'OWN' ? '' : Residences.ownVerifieddate,
      });
    } else if (e.target.name === 'llnumber') {
      const cleaned = ('' + e.target.value).replace(/\D/g, '');
      //This is raw data to pass on props
      const PhoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
      const temp = cleaned.replace(PhoneRegex, phoneFormat);
      setResidencesvalues({
        ...Residences,
        phoneNumber: temp,
        verifiedCheckBox:
          Residences.residenceType === 'RENT'
            ? ''
            : Residences.verifiedCheckBox,
        ownVerifieddate:
          Residences.residenceType === 'OWN' ? '' : Residences.ownVerifieddate,
      });
      //setresidenceverifiedCheckBox(false);
    }
    if (
      e.target.name === 'inlineRadioOptionsOwn' ||
      e.target.name === 'inlineRadioOptions'
    ) {
      verification(
        undefined,
        undefined,
        true,
        e.target.name === 'inlineRadioOptions' ? 'R' : 'O',
        e.target.value
      );
    } else {
      verification();
    }
  };
  const HandleOnChange11 = (e: any) => {
    setcustomerInfoChange(true);
    if (e.target.name === 'llamount') {
      setResidencesvalues({
        ...Residences,
        monthlyRent: e.target.value,
        verifiedCheckBox:
          Residences.residenceType === 'RENT'
            ? ''
            : Residences.verifiedCheckBox,
        ownVerifieddate:
          Residences.residenceType === 'OWN' ? '' : Residences.ownVerifieddate,
      });
      //setresidenceverifiedCheckBox(false);
    } else if (e.target.name === 'lldateMove') {
      const InputTxt = e.target.value;
      const format: any = moment(InputTxt).format(dateFormat);
      const temp = InputTxt.replace(format);
      setResidencesvalues({
        ...Residences,
        moveInDate: temp,
        verifiedCheckBox:
          Residences.residenceType === 'RENT'
            ? ''
            : Residences.verifiedCheckBox,
        ownVerifieddate:
          Residences.residenceType === 'OWN' ? '' : Residences.ownVerifieddate,
      });
      //setresidenceverifiedCheckBox(false);
    }
    verification();
  };
  const HandleOnChange11M = (e: any) => {
    setcustomerInfoChange(true);
    if (e.target.name === 'llamountPaymentAmt') {
      //const regex = /^[+]?\d*(?:[.,]\d*)?$/;
      //if (regex.test(e.target.value)) {
      setResidencesvalues({
        ...Residences,
        monthlyMortgagePayment: e.target.value,
        verifiedCheckBox:
          Residences.residenceType === 'RENT'
            ? ''
            : Residences.verifiedCheckBox,
        ownVerifieddate:
          Residences.residenceType === 'OWN' ? '' : Residences.ownVerifieddate,
      });
      //}
      //setresidenceverifiedCheckBox(false);
    } else if (e.target.name === 'llnumberCompany') {
      setResidencesvalues({
        ...Residences,
        mortgageCompanyName: formatTextChange(e.target.value),
        verifiedCheckBox:
          Residences.residenceType === 'RENT'
            ? ''
            : Residences.verifiedCheckBox,
        ownVerifieddate:
          Residences.residenceType === 'OWN' ? '' : Residences.ownVerifieddate,
      });
      //setresidenceverifiedCheckBox(false);
    }
    verification();
  };
  const setMoveInDate = (e: any, name: any) => {
    if (name === 'lldateOwn') {
      const InputTxt = e;
      const format: any = moment(InputTxt).format(dateFormat);
      const temp = InputTxt.replace(format);
      setResidencesvalues({
        ...Residences,
        residenceSince: temp,
        verifiedCheckBox:
          Residences.residenceType === 'RENT'
            ? ''
            : Residences.verifiedCheckBox,
        ownVerifieddate:
          Residences.residenceType === 'OWN' ? '' : Residences.ownVerifieddate,
      });

      //setresidenceverifiedCheckBox(false);
    } else if (name === 'lldateMove') {
      const InputTxt = e;
      const format: any = moment(InputTxt).format(dateFormat);
      const temp = InputTxt.replace(format);
      setResidencesvalues({
        ...Residences,
        moveInDate: temp,
        verifiedCheckBox:
          Residences.residenceType === 'RENT'
            ? ''
            : Residences.verifiedCheckBox,
        ownVerifieddate:
          Residences.residenceType === 'OWN' ? '' : Residences.ownVerifieddate,
      });

      //setresidenceverifiedCheckBox(false);
    }
    verification();
  };
  const Editclick = () => {
    const el: any = document.getElementById('AddressLine1Edit');
    el.focus();
    seteditInputBox(false);
  };
  const EditclickM = () => {
    seteditInputBoxM(false);
  };
  const updateAddressInfo = () => {
    //seteditInputBox(true);
    setrunAddressDoc('P');
    getAddress('P');
    //seteditInputBox(true);
  };
  const updateAddressInfoM = () => {
    //seteditInputBoxM(true);
    setrunAddressDoc('M');
    getAddress('M');
  };
  const addressDocCancel = (type: 'M' | 'P') => {
    // eslint-disable-next-line no-console
    console.log('runAddressDoc', runAddressDoc);

    //setValidateIsOpen(false);
    if (type == 'M') {
      seteditInputBoxM(true);
    }
    if (type == 'P') {
      seteditInputBox(true);
    }
  };
  // cancel button function for the edit address popup PC-CC-47
  const Cancelbutt = () => {
    const updatePrimaryAddress = {
      address: customerInformation?.addressLine1,
      address1: customerInformation?.addressLine2,
      zip: customerInformation?.zip,
      city: customerInformation?.city,
      state: customerInformation?.state,
    };
    setValidateAddress(validateAddress == 'B' ? 'M' : '');
    setUpdatePrimaryAddress({ ...updatePrimaryAddress });
    seteditInputBox(true);
  };
  const CancelbuttM = () => {
    const updateMailingAddress = {
      address: customerInformation?.addressLine1M,
      address1: customerInformation?.addressLine2M,
      zip: customerInformation?.zipM,
      city: customerInformation?.cityM,
      state: customerInformation?.stateM,
    };
    setValidateAddress(validateAddress == 'B' ? 'P' : '');
    setupdateMailingAddress({ ...updateMailingAddress });
    seteditInputBoxM(true);
  };

  const contactInfoArrayValidation = () => {
    let validate = true;
    phoneNumberProps.forEach((val: any) => {
      if (validate) {
        if (val.phoneNumber === '') {
          validate = false;
        }
        if (val.bestTimeToCall == '0') {
          validate = false;
        }
      }
    });
    return validate;
  };

  const addanotherContact = () => {
    if (contactInfoArrayValidation()) {
      const PhoneNumberLocal = phoneNumberProps;
      PhoneNumberLocal.push({
        phoneNumber: '',
        phoneType: '',
        extension: '',
        primary: 'N',
        callTimeType: '',
        note: '',
        active: 'Y',
      });
      setphoneNumberProps([...PhoneNumberLocal]);
    }
  };

  const hideLengthyText = (Text: any, MaxLength: number, Mask: any) => {
    return Text == "" || Text == null ? "-" : Text.length > MaxLength ? Text.substring(0, MaxLength) + Mask : Text;
  };
  const hideLengthyTextAgreementNumber = (Text: any) => {
    return Text;
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const handleChangeForAddToContact = (e: any) => {
    debugger;
    setcustomerInfoChange(true);
    const d: any = e.target.name;
    const splitD: any = d.split('_');
    const index = parseInt(splitD[1]);

    // for (let i = 0; i < phoneNumberProps.length; i++) {
    //   if (phoneNumberProps[i].primary === 'Y') 
    //   {
    //     setNoRepeatPrimary(i);
    //   }
    // }

    if (d == `phonenumber_${index}`) {
      const cleaned = ('' + e.target.value).replace(/\D/g, '');
      if (cleaned.length <= 10) {
        const PhoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        const temp = cleaned.replace(PhoneRegex, phoneFormat);
        phoneNumberProps[index].phoneNumber = temp;
        setphoneNumberProps([...phoneNumberProps]);
      }
    } else if (d == `ExtforContact_${index}`) {
      phoneNumberProps[index].extension = e.target.value.replace(/\D/g, '');
      setphoneNumberProps([...phoneNumberProps]);
    } else if (d == `BestTimeCall_${index}`) {
      phoneNumberProps[index].callTimeType = e.target.value;
      setphoneNumberProps([...phoneNumberProps]);
    } else if (d == `PhoneType_${index}`) {
      //if(phoneNumberProps.length > 1){

      phoneNumberProps[index].phoneType = e.target.value;
      if (
        e.target.value == 'WORK' &&
        phoneNumberProps[index].primary == 'Y' &&
        phoneNumberProps.length !== 1
      ) {
        let primaryAssigned = false;
        for (let i = 0; i < phoneNumberProps.length; i++) {
          if (!primaryAssigned && phoneNumberProps[i].phoneType !== 'WORK') {
            primaryAssigned = true;
            phoneNumberProps[i].primary = 'Y';
          }
        }
      }
      phoneNumberProps[index].primary =
        e.target.value !== 'WORK' ? phoneNumberProps[index].primary : 'N';
      // }
      setphoneNumberProps([...phoneNumberProps]);
    } else if (d == `PhoneInstructions_${index}`) {
      phoneNumberProps[index].note = formatTextChange(e.target.value);
      setphoneNumberProps([...phoneNumberProps]);
      // eslint-disable-next-line sonarjs/no-collapsible-if
    } else if (d == `isTrnsMsgAllowed_${index}`) {

      // tcpa
      phoneNumberProps[index].isTrnsMsgAllowed = e.target.value;
      setphoneNumberProps([...phoneNumberProps]);
    }
    else if (d == `isMktgMsgAllowed_${index}`) {
      phoneNumberProps[index].isMktgMsgAllowed = e.target.value;
      setphoneNumberProps([...phoneNumberProps]);
    }
    else if (d == `IsPrimary_${index}`) {
      let number: any = 0;
      for (let i = 0; i < phoneNumberProps.length; i++) {
        if (
          phoneNumberProps[i].primary === 'Y' &&
          phoneNumberProps.length >= 1) {
          number = number + 1;
        }
      }
      if (
        phoneNumberProps.length >= 1 &&
        phoneNumberProps[index].primary === 'Y' &&
        phoneNumberProps[index].active === 'Y' &&
        number == 1
      ) {
        setSendId(2);
        setActivePopUp(true);
      }

      if (
        phoneNumberProps.length > 1 &&
        phoneNumberProps[index].primary === 'Y' &&
        phoneNumberProps[index].active === 'Y' &&
        number > 1
      ) {
        phoneNumberProps[index].primary = 'N';
      }

      if (phoneNumberProps[index].primary !== 'Y') {
        phoneNumberProps[index].primary =
          e.target.checked &&
            (phoneNumberProps[index].phoneType == 'CELL' ||
              phoneNumberProps[index].phoneType == 'HOME') &&
            phoneNumberProps[index].phoneNumber !== undefined &&
            phoneNumberProps[index].phoneNumber !== null &&
            phoneNumberProps[index].phoneNumber !== '' &&
            (phoneNumberProps[index].phoneNumber.length == 10 ||
              phoneNumberProps[index].phoneNumber.length == 14) &&
            phoneNumberProps[index].callTimeType !== null &&
            phoneNumberProps[index].callTimeType !== ''
            ? 'Y'
            : 'N';
        phoneNumberProps[index].primary =
          phoneNumberProps.length == 1 &&
            (phoneNumberProps[index].phoneType !== 'WORK' && phoneNumberProps[index].phoneType !== null) &&
            (phoneNumberProps[index].phoneType == 'CELL' ||
              phoneNumberProps[index].phoneType == 'HOME') &&
            phoneNumberProps[index].phoneNumber !== undefined &&
            phoneNumberProps[index].phoneNumber !== null &&
            phoneNumberProps[index].phoneNumber !== '' &&
            (phoneNumberProps[index].phoneNumber.length == 10 ||
              phoneNumberProps[index].phoneNumber.length == 14) &&
            phoneNumberProps[index].callTimeType !== null &&
            phoneNumberProps[index].callTimeType !== '' &&
            phoneNumberProps[index].callTimeType !== 0
            ? 'Y'
            : phoneNumberProps[index].primary;

        if (
          e.target.checked &&
          phoneNumberProps.length > 1 &&
          phoneNumberProps[index].primary === 'Y'
          // eslint-disable-next-line sonarjs/no-identical-expressions

        ) {
          for (let i = 0; i < phoneNumberProps.length; i++) {
            if (i !== index) {
              phoneNumberProps[i].primary = 'N';
            }
          }
        }
        if (e.target.checked &&
          phoneNumberProps.length > 1 &&
          phoneNumberProps[index].primary === 'Y' &&
          phoneNumberProps[index].active === 'N'
        ) {
          phoneNumberProps[index].primary = 'Y';
          phoneNumberProps[index].active = 'Y';
        }

        // if (
        //   e.target.checked &&
        //   // eslint-disable-next-line sonarjs/no-redundant-boolean
        //   phNumInActiveArr.includes(index) == true &&
        //   phoneNumberProps.length > 1 &&
        //   phoneNumberProps[index].primary === 'Y' && 
        //   phoneNumberProps[index].active === 'N'
        // )
        // {
        // for (let i = 0; i < phoneNumberProps.length; i++) {
        //   if (phoneNumberProps[i] === 'y') {
        //     phoneNumberProps[i].primary = 'Y';
        //   }
        // }
        // phoneNumberProps[noRepeatPrimary].primary = 'Y';
        // phoneNumberProps[index].primary = 'N';
        // for (let i = 0; i < phNumInActiveArr.length; i++)
        // {
        //     if(i === index)
        //     {
        //       phoneNumberProps[i].primary = 'N';
        //       phoneNumberProps[i].active = 'N';
        //     }
        // }
        // eslint-disable-next-line sonarjs/no-redundant-boolean
        // if(phNumInActiveArr.includes(index) == true)
        // {
        //   phoneNumberProps[index].primary = 'N';
        //   phoneNumberProps[index].active = 'N';
        // }
        //  }
        setphoneNumberProps([...phoneNumberProps]);
      } else if (phoneNumberProps[index].primary === 'Y') {
        const isPrimaryArg = phoneNumberProps.filter((phoneObj: any) => {
          if (phoneObj.primary === 'Y') {
            return phoneObj;
          }

        });
        if (isPrimaryArg.length > 1) {
          phoneNumberProps[index].primary = 'N';
        }
        setphoneNumberProps([...phoneNumberProps]);
      }
      // else if(
      //   // eslint-disable-next-line no-dupe-else-if
      //   phoneNumberProps.length > 1 &&
      //    phoneNumberProps[index].primary === 'Y' && 
      //    phoneNumberProps[index].active === 'Y'
      //   )
      //   {
      //     setSendId(2);
      //     setActivePopUp(true);
      //   }
    } else if (d == `IsActive_${index}`) {
      if (
        phoneNumberProps.length >= 1 &&
        phoneNumberProps[index].active == 'Y' &&
        phoneNumberProps[index].primary == 'Y'
      ) {
        setSendId(1);
        setActivePopUp(true);
      }
      else if (
        phoneNumberProps.length > 1 &&
        phoneNumberProps[index].active == 'Y' &&
        phoneNumberProps[index].primary == 'N'
      ) {
        setInActivePopUp(true);
        setPhNumIndex(index);
        // phNumInActiveArr.push(index);
      }
      // else if (
      //   phoneNumberProps.length > 1 &&
      //   phoneNumberProps[index].active == 'Y' &&
      //   phoneNumberProps[index].primary == 'Y'
      // )
      // {
      //   // phoneNumberProps[index].active = 'Y';
      //   // phoneNumberProps[index].primary = 'Y'; 
      //   setSendId(1);
      //   setActivePopUp(true);

      // }
      else if (
        phoneNumberProps.length > 1 &&
        phoneNumberProps[index].active == 'N'
      ) {
        phoneNumberProps[index].active = 'Y';
      }

      else if (
        phoneNumberProps.length > 1 &&
        phoneNumberProps[index].active == 'Y'
      ) {
        phoneNumberProps[index].active = 'N';
      }
      // if (
      //   e.target.checked &&
      //   // eslint-disable-next-line sonarjs/no-redundant-boolean
      //   phNumInActiveArr.includes(index) == true &&
      //   phoneNumberProps.length > 1 &&
      //   phoneNumberProps[index].primary === 'N' && 
      //   phoneNumberProps[index].active === 'Y'
      // )
      // { 
      //   phoneNumberProps[index].active = 'N';
      // }
      // if(phoneNumberProps[index].active === 'Y' && phoneNumberProps[index].primary === 'N')
      // {
      //   phoneNumberProps[index].active = 
      //   e.target.checked &&
      //   (phoneNumberProps[index].phoneType == 'CELL' ||
      //    phoneNumberProps[index].phoneType == 'HOME' ||
      //    phoneNumberProps[index].phoneType == 'WORK') &&
      //    phoneNumberProps[index].phoneNumber !== undefined &&
      //    phoneNumberProps[index].phoneNumber !== null &&
      //    phoneNumberProps[index].phoneNumber !== '' &&
      //    (phoneNumberProps[index].phoneNumber.length == 10 ||
      //     phoneNumberProps[index].phoneNumber.length == 14)
      //     ? 'Y'
      //     : 'N';
      // }
      setphoneNumberProps([...phoneNumberProps]);
    }
  };
  const handleEPPAuthentication = (type : string) =>{
    debugger;
    setBtnClicked(type);
    setcreateAgreementPopUp(false);
    /* is rac worker & not yet validated */
    if( customerInformation.racCoworker == 'Y'){  
      // Condition to check whether the user can able to create or not. 
      console.log("current role",coWorkerRole);
      
      if(['DM','FSC-DM', 'RDT', 'FSC-RDT', 'RD', 'FSC-RD'].includes(coWorkerRole)){
        // two factor authentication
        setSecondLevelAuthenOn('RAC Coworker')

      }else{
        setShowUpdatePopUp(true);
        setCantAccess(true)
        // show error popup
      }
    }else{ 
      // Existing flow
      callrespfucntion(type)
    }
  }
  /* Allows user to call respective function based on the type */
  const callrespfucntion = (type : string)=>{
    debugger;
    setBtnClicked(null);
    if(type =='AGREEMENT')
      agreementRedirect('AGREEMENT')
    else if(type =='CO')
      customerOrderRedirection()
    else if (type =='PROMO')
      promoRedirection()
    else if(type == 'ReInstatement')
      agreementRedirect('ReInstatement');
    else if(type == 'CREATE_AGR')
      createAgrHandler();

  }

  useEffect(() => {
    {/*(Pseudo Num : PC_CC_11) This useEffect will set the primary toggles index in state*/ }

    for (let i = 0; i < phoneNumberProps.length; i++) {
      if (phoneNumberProps[i].primary === 'Y') {

        setPrimaryIndex(i);
      }
    }
  }, [inActConDet, phoneNumberProps])
  const handleIsActPopUp1 = (id: any) => {
    {/*(Pseudo Num : PS_CC_03) This function will display the popup
in the main page , with respect to the conditions*/}
    //debugger;
    return (
      <>
        <Grid item md={12}>
          <Grid item md={12} className={classes.textCenter}>

            {id == 1 ?
              (
                <Typography
                  style={{ marginTop: "-16px" }}
                  variant={'body2'}
                  className={`${classes.my3} ${customerStyleClasses.RACPOPMsg}`}
                >
                  Unable to inactive. Customer must have atleast one Phone Number marked as Primary
                </Typography>
              ) : (
                <Typography
                  style={{ marginTop: "-16px" }}
                  variant={'body2'}
                  className={`${classes.my3} ${customerStyleClasses.RACPOPMsg}`}
                >
                  Unable to set inprimary. Customer must have atleast one Phone Number marked as Primary
                </Typography>
              )}
          </Grid>
          <Grid
            item
            className={`${classes.textCenter} ${customerStyleClasses.mt4} ${customerStyleClasses.w100}`}
          >
            <RACButton
              variant="contained"
              color="primary"
              onClick={() => setActivePopUp(false)}
            >
              Ok
            </RACButton>
          </Grid>
        </Grid>
      </>
    );
  };

  const handleIsActPopUp11 = () => {
    {/*(Pseudo Num : PS_CC_03) This function will display the popup
in the main page , with respect to the conditions*/}
    //debugger;
    return (
      <>
        <Grid item md={12}>
          <Grid item md={12} className={classes.textCenter}>
            <Typography
              style={{ marginTop: "-16px" }}
              variant={'body2'}
              className={`${classes.my3} ${customerStyleClasses.RACPOPMsg}`}
            >
              Unable to set inprimary phone number. Customer must have only one Phone Number marked as Primary
            </Typography>

          </Grid>
          <Grid
            item
            className={`${classes.textCenter} ${customerStyleClasses.mt4} ${customerStyleClasses.w100}`}
          >
            <RACButton
              variant="contained"
              color="primary"
              onClick={() => {
                setErrorPopup(true);
                setisrequired(true);
                setActivePopUp11(false)
              }}
            >
              Ok
            </RACButton>
          </Grid>
        </Grid>
      </>
    );
  };

  const handleInActivePopUp1 = (id: any) => {
    {/*(Pseudo Num : PC_CC_10) This function will display the popup , when the user is trying to inactive the ph num */ }
    return (
      <>
        <Grid item md={12}>
          <Grid item md={12} className={classes.textCenter}>
            {id == 1 ?
              (
                <Typography
                  variant={'body2'}
                  className={`${classes.my3} ${customerStyleClasses.RACPOPMsg}`}
                >
                  Unable to inactive. Customer must have atleast one Phone Number marked as Primary
                </Typography>
              ) : (
                <Typography
                  variant={'body2'}
                  className={`${classes.my3} ${customerStyleClasses.RACPOPMsg}`}
                >
                  Unable to set inprimary. Customer must have atleast one Phone Number marked as Primary
                </Typography>
              )}

          </Grid>
          <Grid
            item
            className={`${classes.textCenter} ${customerStyleClasses.mt4} ${customerStyleClasses.w100}`}
          >
            <RACButton
              variant="contained"
              color="primary"
              onClick={() => inActivePopUp1Ok()}
            >
              Ok
            </RACButton>
          </Grid>
        </Grid>
      </>
    );
  }

  const inActivePopUp1Ok = () => {
    {/*(Pseudo Num : PC_CC_10) This function will handle with the opening & closing
of the popup */}
    debugger
    setInActConDet(true);
    setActivePopUp(false);
  }
  const handleIsActPopUp2 = () => {
    {/*(Pseudo Num : PS_CC_05) This function will display the confirmation popup , when user tries to 
                                               inactive the phone number*/}
    return (
      <>
        <Grid item md={12}>
          <Grid
            item
            md={12}
            className={classes.textCenter}
            style={{ marginTop: '15px ' }}
          >
            <Typography
              style={{ marginTop: "-28px" }}
              variant={'body2'}
              className={`${classes.my3} ${customerStyleClasses.RACPOPMsg}`}
            >
              Are you sure you want to inactive this Phone number
            </Typography>
          </Grid>
          <Grid
            item
            className={` ${customerStyleClasses.mt4} ${customerStyleClasses.w100}`}
          >
            <RACButton
              style={{
                marginLeft: '28%',
                paddingRight: '8%',
                paddingLeft: '8%',
              }}
              variant="contained"
              color="inherit"
              onClick={() => isActCancelBtn(phNumIndex)}
            >
              No
            </RACButton>
            <RACButton
              style={{
                marginLeft: '15px',
                paddingRight: '8%',
                paddingLeft: '8%',
              }}
              variant="contained"
              color="primary"
              onClick={() => isActSaveBtn(phNumIndex)}
            >
              Yes
            </RACButton>
          </Grid>
        </Grid>
      </>
    );
  };

  const isActSaveBtn = (phNumIndex1: any) => {
    {/*(Pseudo Num : PC_CC_06) This function will be called , when the user is 
clicking on the save button in the handleIsActPopUp2()*/}
    phoneNumberProps[phNumIndex1].active = 'N';
    setphoneNumberProps(phoneNumberProps);

    setInActivePopUp(false);

  };
  const isActCancelBtn = (phNumIndex1: any) => {
    {/*(Pseudo Num : PC_CC_06) This function will be called , when the user is 
                                                     clicking on the save button in the handleIsActPopUp2()*/}

    phoneNumberProps[phNumIndex1].active = 'Y';
    setphoneNumberProps(phoneNumberProps);

    setInActivePopUp(false);

  };

  const handleInActPhNum = () => {
    {/*(Pseudo Num : PC_CC_07) This function will render the Table in RACModalCard*/ }
    return (
      <>
        <Grid>
          <Grid style={{ overflow: "auto", height: "400px", width: "100%" }}>
            <RACTable
              // eslint-disable-next-line sonarjs/no-use-of-empty-return-value
              renderTableHead={bindGridHead}
              renderTableContent={() => bindGridBody(inActivePhArr)}
              stickyHeader
            />
          </Grid>
          <Grid style={{ marginTop: '3%' }}>
            <RACButton
              variant="outlined"
              color="inherit"
              style={{ marginLeft: '75%', padding: '8px 26px' }}
              onClick={() => inActivePopUpCancelBtn()}
            // paddingTop:'8px' , paddingBottom:'8px' , paddingLeft:'2%' , paddingRight:'2%'
            >
              Cancel
            </RACButton>
            <RACButton
              variant="contained"
              color="primary"
              className={classes.me1}
              style={{ float: 'right', marginRight: '2%', padding: '8px 20px' }}
              disabled={inActivePopSaveDisable}
              onClick={() => inActivePopUpSaveBtn()}
            >
              Save
            </RACButton>
          </Grid>
        </Grid>
      </>
    )
  }


  const inActivePopUpSaveBtn = () => {
    {/*(Pseudo Num : PC_CC_12) This func will invoke when user clicks on save btn in Inactive Ph Num popup */ }
    debugger;
    // for(let i = 0 ; i < phoneNumberProps.length ; i++)
    // {
    //   if(phoneNumberProps[i].primary === 'Y')
    //   {
    //     phoneNumberProps[i].primary = 'N'
    //   }
    // }
    const filterActive = inActivePhArr.filter(
      (x: any) => x.active === 'Y'
    )
    // const filterPrimary = phoneNumberProps.filter(
    //   (x: any) => x.primary === 'Y'
    // )
    console.log(filterActive, "filterActive");
    filterActive.map((val: any, index: any) => {
      if (val.primaryFlag === 'Y') {
        val.primary = 'Y';
        phoneNumberProps.map((x: any) => x.primary = 'N')
        setphoneNumberProps(phoneNumberProps);
      }
    })

    setInActivePopUp1(false);
    const result = [...phoneNumberProps, ...filterActive];
    phoneNumberProps = result
    setphoneNumberProps(phoneNumberProps);
    updateCustomerInfoInactive();
    setInActConDet(false);

  }

  const updateCustomerInfoInactive = async () => {

    try {
      let UpdateCustomerRequest: any = [];
      //let UpdateCustomerRequestForLegalHoldCustomer: any = [];
      UpdateCustomerRequest = {
        customerId: customerId,
        globalCustomerId: customerInformation.globalCustomerId,
        firstName: customerInformation.firstName,
        lastName: customerInformation.lastName,
        middleInitial: customerInformation.middleInitial,
        coworker: customerInformation.racCoworker,
        // assignRoute: {
        //   storeNumber: loginStore,
        //  customerId: parseInt(customerId),
        //  routeRefCode: customerInformation.route,
        //  }
      };
      UpdateCustomerRequest.assignRoute = {
        storeNumber: loginStore,
        customerId: parseInt(customerId),
        routeRefCode: customerInformation.route,
      };
      const phonePropsArray: any = buildPhones();
      if (
        phoneNumberProps !== undefined &&
        phoneNumberProps !== null &&
        phoneNumberProps.length > 0
      ) {
        UpdateCustomerRequest.phones = phonePropsArray;
      }
      setIsLoading(true);
      const payload: any = UpdateCustomerRequest
      const result = await UpdateCustomerInfo(payload, customerId);
      setIsLoading(false);
      // eslint-disable-next-line no-console
      showUpdateStatusPopUp(result);
      // eslint-disable-next-line no-console
      console.log('response', result);
      // /* eslint-enable no-console */
    } catch (e: any) {
      setIsLoading(false);
      setisSuccess(false);
      setShowUpdatePopUp(true);
      // eslint-disable-next-line no-console
      console.log(e, 'Error in Update Customer');
    }
  };

  const inActivePopUpCancelBtn = () => {
    {/*(Pseudo Num : PC_CC_13) This func will invoke when user clicks on Cancel btn in Inactive Ph Num popup */ }
    setInActConDet(false);
    setInActivePopUp1(false);
    setInActivePopSaveDisable(true);
    inActivePhArr.map((val: any) => {
      if (val.active === 'Y' || val.primaryFlag === 'Y') {
        val.active = 'N';
        val.primaryFlag = 'N'
        setInActivePhArr(inActivePhArr);
      }
    })
  }

  const handleSLApopoup = () => {
    console.log(customerauthDOB, 'Vanthiduchuuuu');
    (customerauthDOB) ? setcustomerauthDOB(false) : null;
    (customerauthDOB) ? setSSNDOBdisable(false) : null;
    setslapopupauthentication(!slapopupauthentication);
    setSSNDOBdisable(true);
  }

  const bindGridHead = () => (
    <>
      {gridheadfields.map((val: any, index: any) => {

        return (
          <RACTableCell
            key={index}
            style={{ borderBottomStyle: 'groove', marginRight: '15px' }}
          >
            {val}
          </RACTableCell>
        );
      })
      }
    </>
  );
  useEffect(() => {
    let saveEnableCount = 0
    for (let i = 0; i < inActivePhArr.length; i++) {
      if (inActivePhArr[i].active == 'Y' || inActivePhArr[i].primaryFlag == 'Y') {
        saveEnableCount++;

      }
      if (saveEnableCount > 0) {
        setInActivePopSaveDisable(false);
      } else {
        setInActivePopSaveDisable(true);
      }
    }
  }, [inActivePhArr])

  const bindGridBody = (inActiveDetails: any): any => {
    console.log('inActiveDetails', inActiveDetails);
    debugger; {/*(Pseudo Num : PC_CC_07) This function will bind the body part in the Grid*/ }
    // eslint-disable-next-line sonarjs/no-all-duplicated-branches
    if (inActiveDetails.length > 0) {
      return inActiveDetails.map((obj: any, index: any) => {
        return (
          <React.Fragment key={index}>
            <RACTableRow key={index} style={{ backgroundColor: 'white' }}>
              <RACTableCell style={{ marginRight: '15px' }}>
                {obj.phoneNumber}
              </RACTableCell>
              <RACTableCell>
                {obj.phoneExtension === null || obj.phoneExtension === "" ? '-' : obj.phoneExtension}
              </RACTableCell>
              <RACTableCell
                title={obj.callTypeDesc}>

                {hideLengthyText(obj.callTypeDesc, 15, "...")}

              </RACTableCell>
              <RACTableCell>
                {obj.phoneTypeDesc}
              </RACTableCell>
              <RACTableCell
                title={obj.note}
              >
                {hideLengthyText(obj.note, 15, "...")}
              </RACTableCell>
              <RACTableCell>
                <RACToggle
                  toggleColor="#0d6efd"
                  name={`IsActive_${index}`}
                  labelClassName={classes.switchLabel}
                  checked={obj?.active == 'Y' ? true : false}
                  OnChange={(e: any) => {
                    handleChangeForInActiveNum(e);
                  }}
                />
              </RACTableCell>
              <RACTableCell>
                <RACToggle
                  toggleColor="#0d6efd"
                  name={`IsPrimary_${index}`}
                  labelClassName={classes.switchLabel}
                  checked={obj?.primaryFlag == 'Y' ? true : false}
                  OnChange={(e: any) => {
                    handleChangeForInActiveNum(e);
                  }}
                />
              </RACTableCell>

            </RACTableRow>

          </React.Fragment>

        )
      })
    }
    // eslint-disable-next-line sonarjs/no-duplicated-branches
    else if (inActiveDetails.length == 0) {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      // return inActiveDetails.map((obj: any,index: any) => {
      return (
        // <React.Fragment key={index}>
        <RACTableRow style={{ backgroundColor: 'white' }}>
          <RACTableCell>

          </RACTableCell>
          <RACTableCell>

          </RACTableCell>
          <RACTableCell>

          </RACTableCell>
          <RACTableCell>
            No InActive Phone Numbers !!
          </RACTableCell>
          <RACTableCell>

          </RACTableCell>
          <RACTableCell>

          </RACTableCell>
          <RACTableCell>

          </RACTableCell>

        </RACTableRow>

        // </React.Fragment>
      )
      // })
    }

  }
  const handleChangeForInActiveNum = (e: any) => {
    debugger; {/*(Pseudo Num : PC_CC_08) This function will handle with the toggle buttons in onChange */ }
    const d: any = e.target.name;
    const splitD: any = d.split('_');
    const index = parseInt(splitD[1]);


    if (d == `IsPrimary_${index}`) {
      if (inActivePhArr[index].primaryFlag !== 'Y' &&
        inActivePhArr[index].active === 'Y' &&
        inActivePhArr[index].phoneTypeDesc !== 'Work Phone'
      ) {
        inActivePhArr[index].primaryFlag = 'Y'
      }
      else if (inActivePhArr[index].primaryFlag !== 'Y' &&
        inActivePhArr[index].active === 'N' &&
        inActivePhArr[index].phoneTypeDesc !== 'Work Phone'
      ) {
        inActivePhArr[index].primaryFlag = 'Y';
        inActivePhArr[index].active = 'Y';
      }
      else if (inActivePhArr[index].primaryFlag === 'Y' &&
        inActivePhArr[index].active === 'Y')
      // eslint-disable-next-line sonarjs/no-duplicated-branches
      {
        // inActivePhArr[index].primary = 'Y';
        // inActivePhArr[index].active = 'Y';
        //setActivePopUp(true);
        setSendId(2);
        //setInActivePopUpIndex(index);
        setInActConDet(false);
        setInActivePopUp1(true);
      }
      if (
        e.target.checked &&
        inActivePhArr.length > 1 &&
        inActivePhArr[index].primaryFlag == 'Y' &&
        inActivePhArr[index].active === 'Y' &&
        inActivePhArr[index].phoneTypeDesc !== 'Work Phone'
      ) {
        for (let i = 0; i < inActivePhArr.length; i++) {
          if (i !== index) {
            inActivePhArr[i].primaryFlag = 'N';
          }
        }
      }

      setInActivePhArr([...inActivePhArr]);
    }

    else if (d == `IsActive_${index}`) {
      // if(inActivePhArr[index].active === 'Y' &&
      // inActivePhArr[index].primary === 'Y'
      // )
      // {
      //   inActivePhArr[index].active = 'N';
      //   inActivePhArr[index].primary = 'N';
      //   //setInActivePhArr([...inActivePhArr]);
      //   //break;
      // }

      if (inActivePhArr[index].active === 'N') {
        inActivePhArr[index].active = 'Y'
      }
      else if (inActivePhArr[index].active === 'Y' &&
        inActivePhArr[index].primaryFlag === 'N'
      ) {
        inActivePhArr[index].active = 'N'
      }
      else if (inActivePhArr[index].active === 'Y' &&
        inActivePhArr[index].primaryFlag === 'Y'
      ) {
        setSendId(1);
        //setInActivePopUpIndex(index);
        setInActConDet(false);
        //setActivePopUp(true);
        setInActivePopUp1(true);
        //handleInActivePopUp1(1);

      }

      setInActivePhArr([...inActivePhArr]);
    }
  }

  const renderAddressHistory = () => {            // (Pseudo no : PC_AH_03) This function will render tht grid in the RACModalCard
    //debugger;
    return (
      <>
        <Grid style={{ overflow: "auto", height: "350px", width: "100%" }}>
          <RACTable
            renderTableHead={bindHeaderAddress}
            renderTableContent={() => bindBodyAddress(addressHistoryArray)}
            stickyHeader
          />
        </Grid>
      </>
    )
  }

  const bindHeaderAddress = () => (              // (Pseudo no : PC_AH_03 & PC_AH_04) This function will render the header part in the grid 

    <>
      {bindHeaderAddressHistory.map((value: any, index: any) => {

        return (
          // eslint-disable-next-line react/jsx-key
          <RACTableCell
            key={index}
            style={{ borderBottomStyle: 'groove' }}
          >
            {value}
          </RACTableCell>
        );
      })}
    </>
  )

  function bindBodyAddress(bindBodyAddressHistory: any) {       // (Pseudo no : PC_AH_03 & PC_AH_04) This function will render the body part in the grid 
    //debugger;
    console.log(bindBodyAddressHistory, "bindBodyAddressHistory");
    if (bindBodyAddressHistory.length > 0) {
      return bindBodyAddressHistory.map((value: any, index: any) => {

        return (
          <React.Fragment key={index}>
            <RACTableRow key={index} style={{ backgroundColor: 'white' }}>
              <RACTableCell>

                {value.addressTypeDesc == null || value.addressTypeDesc == "" ? "-" : value.addressTypeDesc}

              </RACTableCell>
              <RACTableCell
                title={value.agreementNumber}
              >

                {hideLengthyTextAgreementNumber(value.agreementNumbersCount)}

              </RACTableCell>
              <RACTableCell>

                {value.storeNumber == null || value.storeNumber == "" ? "-" : value.storeNumber}
              </RACTableCell>
              <RACTableCell
                title={value.addressLine1}
              >

                {value.addressLine1 == null || value.addressLine1 == "" ? "-" : hideLengthyText(value.addressLine1, 17, '...')}
                {/* {value.addressLine1}  */}
              </RACTableCell>
              <RACTableCell
                title={value.addressLine2}
              >

                {value.addressLine2 == null || value.addressLine2 == "" ? "-" : hideLengthyText(value.addressLine2, 17, '...')}
                {/* {value.addressLine2} */}
              </RACTableCell>
              <RACTableCell>

                {value.postalCode == null || value.postalCode == "" ? "-" : value.postalCode}

              </RACTableCell>
              <RACTableCell>

                {value.city == null || value.city == "" ? "-" : value.city}

              </RACTableCell>
              <RACTableCell>

                {value.state == null || value.state == "" ? "-" : value.state}

              </RACTableCell>
              <RACTableCell>

                {value.lastModifiedDate == null || value.lastModifiedDate == "" ? "-" : moment(value.lastModifiedDate).format("MM/DD/YYYY")}

              </RACTableCell>
              <RACTableCell>

                {value.lastModifiedBy == null || value.lastModifiedBy == "" ? "-" : value.lastModifiedBy}

              </RACTableCell>
            </RACTableRow>
          </React.Fragment>
        )
      })
    }
    else if (bindBodyAddressHistory.length == 0) {
      return (
        // <React.Fragment key={index}>
        <RACTableRow style={{ backgroundColor: 'white' }}>
          <RACTableCell>

          </RACTableCell>
          <RACTableCell>

          </RACTableCell>
          <RACTableCell>

          </RACTableCell>
          <RACTableCell>

          </RACTableCell>
          <RACTableCell>
            No Address History!!
          </RACTableCell>
          <RACTableCell>

          </RACTableCell>
          <RACTableCell>

          </RACTableCell>
          <RACTableCell>

          </RACTableCell>
          <RACTableCell>

          </RACTableCell>
          <RACTableCell>

          </RACTableCell>
        </RACTableRow>
        //</React.Fragment>
      )
    }
  }


  const removeContactInfoDiv = (index: any) => {
    setcustomerInfoChange(true);
    const PhoneNumberLocal = phoneNumberProps;
    PhoneNumberLocal.splice(index, 1);
    setphoneNumberProps([...phoneNumberProps]);
  };

  const employementInfoArrayValidation = () => {
    let validate = true;
    EmpProps.forEach((val: any) => {
      val.employerPhoneNumber =
        val.employerPhoneNumber == undefined || val.employerPhoneNumber == null
          ? ''
          : val.employerPhoneNumber;
      if (
        validate !== false &&
        (val.employerName === '' ||
          val.employerPhoneNumber == '' ||
          val.employerPhoneNumber.length < 10 ||
          val.employerPayschedule == undefined ||
          val.employerPayschedule == null ||
          val.employerPayschedule == '' ||
          val.employerPayschedule == '0') &&
        val.active == 'Y'
      ) {
        validate = false;
      } else if (
        val.employerPayschedule !== undefined &&
        val.employerPayschedule !== null &&
        val.employerPayschedule !== '' &&
        val.employerPayschedule !== '0' &&
        val.employerPayschedule !== 'DAILY' &&
        (val.daysPaid == undefined ||
          val.daysPaid == null ||
          val.daysPaid == '' ||
          val.daysPaid == '0')
        // eslint-disable-next-line sonarjs/no-duplicated-branches
      ) {
        validate = false;
      }
    });
    setValidateEmployement(validate);
    return validate;
  };

  const addanotherEmployement = () => {
    if (employementInfoArrayValidation()) {
      const EmpLocal = EmpProps;
      EmpLocal.push({
        employerReferenceId: '',
        employerName: '',
        takeHomePay: '0.00',
        supervisorFirstName: '',
        supervisorLastName: '',
        jobTitle: '',
        employerPhoneNumber: '',
        employerPhoneExtension: null,
        addressLine1: '',
        addressLine2: '',
        postalCode: '',
        city: '',
        state: '',
        doNotCall: 'N',
        employmentBeginDate: '2021-01-01',
        workStartTime: null,
        workEndTime: null,
        lengthOfEmploymentYears: '0',
        employerPayschedule: '',
        employmentEndDate: null,
        daysPaid: '',
        doNotVisit: 'N',
        active: 'Y',
        verifiedDate: '',
      });
      setEmpProps([...EmpLocal]);
    }
  };

  const removeEmployementDiv = (index: any) => {
    setcustomerInfoChange(true);
    const EmpLocal = EmpProps;
    EmpLocal.splice(index, 1);
    setEmpProps([...EmpLocal]);
  };
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const handleChangeForAddEmployeInfo = (e: any) => {
    setcustomerInfoChange(true);
    // eslint-disable-next-line
    // debugger
    const d: any = e.target.name;
    const splitD: any = d.split('_');
    const index = parseInt(splitD[1]);
    // verified check box
    if (d == `verifiedCheckBox_${index}`) {
      EmpProps[index].verifiedDate =
        e.target.checked === true ? new Date() : '';
      setEmpProps([...EmpProps]);
    } else if (d == `verifiedActiveDiv_${index}`) {
      EmpProps[index].active = e.target.checked === true ? 'Y' : 'N';
      setEmpProps([...EmpProps]);
    }
    //company name
    else if (d == `companyName_${index}`) {
      EmpProps[index].employerName = formatTextChange(e.target.value);
      EmpProps[index].verifiedDate = '';
      setEmpProps([...EmpProps]);
    }
    // how long
    else if (d == `howLong_${index}`) {
      EmpProps[index].lengthOfEmploymentYears = e.target.value;
      //EmpProps[index].verifiedDate = '';
      setEmpProps([...EmpProps]);
    }

    // employer phone number
    else if (d == `employePhoneNum_${index}`) {
      const cleaned = ('' + e.target.value).replace(/\D/g, '');
      if (cleaned.length <= 10) {
        const PhoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        const temp = cleaned.replace(PhoneRegex, phoneFormat);
        EmpProps[index].employerPhoneNumber = temp;
        EmpProps[index].verifiedDate = '';
        setEmpProps([...EmpProps]);
      }
      //setempInfoVerifiedCheckBox(false);
    }
    // do not call
    else if (d == `doNotCall_${index}`) {
      EmpProps[index].doNotCall = e.target.checked ? 'Y' : 'N';
      //EmpProps[index].verifiedDate = '';
      setEmpProps([...EmpProps]);
      //setempInfoVerifiedCheckBox(false);
    }

    // pay day freq
    else if (d == `payDayFreq_${index}`) {
      EmpProps[index].employerPayschedule = e.target.value;
      EmpProps[index].daysPaid = '';
      EmpProps[index].verifiedDate = '';

      setEmpProps([...EmpProps]);
    }

    // day paid

    //date paid
    else if (d == `datePaid_${index}`) {
      EmpProps[index].daysPaid = e.target.value;
      EmpProps[index].verifiedDate = '';
      setEmpProps([...EmpProps]);
      //setempInfoVerifiedCheckBox(false);
    } else if (d == `takeHomePay_${index}`) {
      // const regex = /^[+]?\d*(?:[.,]\d*)?$/;
      // if (regex.test(e.target.value)) {
      EmpProps[index].takeHomePay = e.target.value;
      //EmpProps[index].verifiedDate = '';
      setEmpProps([...EmpProps]);
      // }

      //setempInfoVerifiedCheckBox(false);
    } else if (d == `addressLine1_${index}`) {
      EmpProps[index].addressLine1 = formatTextChange(e.target.value);
      //EmpProps[index].verifiedDate = '';
      setEmpProps([...EmpProps]);
      //setempInfoVerifiedCheckBox(false);
    } else if (d == `addressLine2_${index}`) {
      EmpProps[index].addressLine2 = formatTextChange(e.target.value);
      //EmpProps[index].verifiedDate = '';
      setEmpProps([...EmpProps]);
      //setempInfoVerifiedCheckBox(false);
    } else if (d == `zip_${index}`) {
      let cleaned = ('' + e.target.value).replace(/\D/g, '');
      cleaned =
        cleaned.length > 5
          ? cleaned.slice(0, 5) + '-' + cleaned.slice(5)
          : cleaned;
      EmpProps[index].postalCode = cleaned;
      //EmpProps[index].verifiedDate = '';
      setEmpProps([...EmpProps]);
      //setempInfoVerifiedCheckBox(false);
    } else if (d == `city_${index}`) {
      // eslint-disable-next-line
      // 
      EmpProps[index].city = e.target.value.replace(/[^a-z A-Z ]/g, '');
      //EmpProps[index].verifiedDate = '';
      setEmpProps([...EmpProps]);
      //setempInfoVerifiedCheckBox(false);
    } else if (d == `state_${index}`) {
      EmpProps[index].state = e.target.value;
      //EmpProps[index].verifiedDate = '';
      setEmpProps([...EmpProps]);
      //setempInfoVerifiedCheckBox(false);
    } else if (d == `doNotVisit_${index}`) {
      EmpProps[index].doNotVisit = e.target.checked ? 'Y' : 'N';
      //EmpProps[index].verifiedDate = '';
      setEmpProps([...EmpProps]);
      //setempInfoVerifiedCheckBox(false);
    }
    verification();
  };
  // const buildVechicleYear = () => {
  //   const dt = new Date();
  //   const year = dt.getFullYear() - 50;
  //   const yearList = [];
  //   for (let i = dt.getFullYear(); i > year; i--) {
  //     yearList.push(i);
  //   }
  //   return yearList.map((val, index) => {
  //     return (
  //       <option value={val} key={index}>
  //         {val}
  //       </option>
  //     );
  //   });
  // };
  const printTemplate = (data: any) => {
    // eslint-disable-next-line no-console
    console.log('dat', data);
    return (
      <div style={{ display: 'block' }}>
        <div
          style={{
            width: '100%',
            fontFamily: 'Arial',
            margin: '80px auto 0px auto',
          }}
          id="printHTML"
        >
          {/*content starts*/}
          {/*Section 1 starts*/}
          <div style={{ width: '100%', float: 'left' }}>
            <div
              style={{
                width: '100%',
                float: 'left',
                marginBottom: '10px',
              }}
            >
              <div style={{ width: '66.66%', float: 'left' }}>
                <p style={{ fontSize: '13px', marginBottom: '10px' }}>
                  Rent-A- Center
                </p>
                <p
                  style={{
                    fontSize: '13px',
                    marginTop: '10px',
                    marginBottom: '10px',
                  }}
                >
                  {data !== undefined &&
                    data.response !== undefined &&
                    data.response.length > 0
                    ? data.response[0].storeName +
                    ' ' +
                    '(' +
                    data.response[0].storeName +
                    ')'
                    : ''}
                </p>
                <p
                  style={{
                    fontSize: '13px',
                    marginTop: '10px',
                    marginBottom: '10px',
                  }}
                >
                  {data !== undefined &&
                    data.response !== undefined &&
                    data.response.length > 0
                    ? data.response[0].addressLine1
                    : ''}
                </p>
                <p
                  style={{
                    fontSize: '13px',
                    marginTop: '10px',
                    marginBottom: '10px',
                  }}
                >
                  {data !== undefined &&
                    data.response !== undefined &&
                    data.response.length > 0
                    ? data.response[0].city +
                    ' ' +
                    data.response[0].stateName +
                    ' ' +
                    data.response[0].fullZip
                    : ''}
                </p>
                <p
                  style={{
                    fontSize: '13px',
                    marginTop: '10px',
                    marginBottom: '10px',
                  }}
                >
                  {data !== undefined &&
                    data.response !== undefined &&
                    data.response.length > 0
                    ? data.response[0].workPhoneNumber.replace(
                      /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
                      '($1) $2-$3'
                    )
                    : ''}
                </p>
              </div>
              <table style={{ width: '33.33%', float: 'right' }}>
                <thead />
                <tbody
                  style={{
                    width: '100%',
                    float: 'left',
                    marginTop: '10px',
                  }}
                >
                  <tr
                    style={{
                      width: '15%',
                      float: 'left',
                      textAlign: 'left',
                    }}
                  >
                    <td style={{ fontSize: '13px' }}>Date:</td>
                  </tr>
                  <tr
                    style={{
                      width: '85%',
                      float: 'left',
                      textAlign: 'left',
                    }}
                  >
                    <td style={{ fontSize: '13px' }}>{buildDateFormat2('')}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          {/*Section 1 ends*/}
          {/*Section 2 starts*/}
          <div style={{ width: '100%', float: 'left' }}>
            <div
              style={{
                width: '100%',
                float: 'left',
                marginBottom: '10px',
              }}
            >
              <table style={{ width: '75%', float: 'left' }}>
                <thead />
                <tbody
                  style={{
                    width: '100%',
                    float: 'left',
                    marginTop: '10px',
                  }}
                >
                  <tr
                    style={{
                      width: '4%',
                      float: 'left',
                      textAlign: 'left',
                    }}
                  >
                    <td style={{ fontSize: '13px' }}>To:</td>
                  </tr>
                  <tr
                    style={{
                      width: '96%',
                      float: 'left',
                      textAlign: 'left',
                    }}
                  >
                    <td style={{ fontSize: '13px' }}>
                      Rent-A-Center Field Service Center Tax Department
                    </td>
                  </tr>
                  <tr
                    style={{
                      width: '5%',
                      float: 'left',
                      textAlign: 'left',
                    }}
                  >
                    <td style={{ fontSize: '13px' }}>Fax:</td>
                  </tr>
                  <tr
                    style={{
                      width: '95%',
                      float: 'left',
                      textAlign: 'left',
                    }}
                  >
                    <td style={{ fontSize: '13px' }}>(866) 706-2972</td>
                  </tr>
                  <tr
                    style={{
                      width: '4%',
                      float: 'left',
                      textAlign: 'left',
                    }}
                  >
                    <td style={{ fontSize: '13px' }}>Re:</td>
                  </tr>
                  <tr
                    style={{
                      width: '96%',
                      float: 'left',
                      textAlign: 'left',
                    }}
                  >
                    <td style={{ fontSize: '13px' }}>
                      Tax Exempt Customer Documentation
                    </td>
                  </tr>
                  <tr
                    style={{
                      width: '33%',
                      float: 'left',
                      textAlign: 'left',
                    }}
                  >
                    <td style={{ fontSize: '13px' }}>
                      Pages (including cover page)
                    </td>
                  </tr>
                  <tr
                    style={{
                      width: '67%',
                      float: 'left',
                      textAlign: 'left',
                    }}
                  >
                    <td style={{ fontSize: '13px' }}>
                      <span
                        style={{
                          borderBottom: '0.5px solid #707070',
                          width: '130px',
                          marginTop: '13px',
                          float: 'left',
                        }}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <p
                style={{
                  float: 'left',
                  marginTop: '5px',
                  fontSize: '13px',
                  marginLeft: '5px',
                }}
              >
                Please fax documentation to support this Tax Exempt Application.
              </p>
              <table
                style={{
                  width: '75%',
                  float: 'left',
                  marginBottom: '400px',
                }}
              >
                <thead />
                <tbody>
                  <tr
                    style={{
                      width: '100%',
                      float: 'left',
                      textAlign: 'left',
                    }}
                  >
                    <td style={{ fontSize: '13px' }}>
                      Please complete the following:
                    </td>
                  </tr>
                  <tr
                    style={{
                      width: '20%',
                      float: 'left',
                      textAlign: 'left',
                    }}
                  >
                    <td style={{ fontSize: '13px' }}>Customer Name:</td>
                  </tr>
                  <tr
                    style={{
                      width: '80%',
                      float: 'left',
                      textAlign: 'left',
                    }}
                  >
                    <td style={{ fontSize: '13px' }}>
                      {customerInformation?.firstName +
                        ' ' +
                        customerInformation?.lastName}
                    </td>
                  </tr>
                  <tr
                    style={{
                      width: '25%',
                      float: 'left',
                      textAlign: 'left',
                    }}
                  >
                    <td style={{ fontSize: '13px' }}>Customer ID:</td>
                  </tr>

                  <tr
                    style={{
                      width: '75%',
                      float: 'left',
                      textAlign: 'left',
                    }}
                  >
                    <td style={{ fontSize: '13px' }}>{customerId}</td>
                  </tr>
                  <tr
                    style={{
                      width: '25%',
                      float: 'left',
                      textAlign: 'left',
                    }}
                  >
                    <td style={{ fontSize: '13px' }}>Agreement Number:</td>
                  </tr>
                  <tr
                    style={{
                      width: '75%',
                      float: 'left',
                      textAlign: 'left',
                    }}
                  >
                    <td style={{ fontSize: '13px' }} />
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          {/*Section 2 ends*/}
          {/*Section 3 starts*/}
          <div>
            <p
              style={{
                width: '100%',
                fontSize: '13px',
                float: 'left',
                marginBottom: '0px',
              }}
            >
              Legal Disclosure
            </p>
            <p style={{ width: '100%', fontSize: '13px', float: 'left' }}>
              TBD
            </p>
          </div>
          {/*Section 3 ends*/}
          {/*Content ends*/}
        </div>
      </div>
    );
  };
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const handleChangeForAddReferenceInfo = (e: any) => {
    setcustomerInfoChange(true);
    const d: any = e.target.name;
    const splitD: any = d.split('_');
    const index = parseInt(splitD[1]);

    if (d == `verifiedCheckBox_${index}`) {
      ReferenceProps[index].verifiedDate = e.target.checked ? new Date() : '';
      setReferenceProps([...ReferenceProps]);
      //setrefVerified(true);
    } else if (d == `activeCheckBox_${index}`) {
      ReferenceProps[index].active = e.target.checked ? 'Y' : 'N';
      setReferenceProps([...ReferenceProps]);
      //setrefVerified(true);
    } else if (d == `parent-RelativeName_${index}`) {
      ReferenceProps[index].firstName = formatTextChange(
        e.target.value
      ).replace(/[^A-Z-'.,’ a-z]/gi, '');
      ReferenceProps[index].verifiedDate = '';
      setReferenceProps([...ReferenceProps]);
    } else if (d == `parent-RelativeLastName_${index}`) {
      ReferenceProps[index].lastName = formatTextChange(e.target.value).replace(
        /[^A-Z-'.,’ a-z]/gi,
        ''
      );
      ReferenceProps[index].verifiedDate = '';
      setReferenceProps([...ReferenceProps]);
    } else if (d == `phoneNumber_${index}`) {
      const cleaned = ('' + e.target.value).replace(/\D/g, '');
      if (cleaned.length <= 10) {
        const PhoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        const temp = cleaned.replace(PhoneRegex, phoneFormat);
        ReferenceProps[index].phoneNumber = temp;
        ReferenceProps[index].verifiedDate = '';
        setReferenceProps([...ReferenceProps]);
      }
    } else if (d == `bestTimeToCall_${index}`) {
      ReferenceProps[index].bestTimeToCall =
        e.target.value !== '0' ? e.target.value : '';
      //ReferenceProps[index].verifiedDate = '';
      setReferenceProps([...ReferenceProps]);
    } else if (d == `relationShip_${index}`) {
      ReferenceProps[index].relationshipType = e.target.value;
      ReferenceProps[index].verifiedDate = '';
      setReferenceProps([...ReferenceProps]);
    } else if (d == `addressLine1_${index}`) {
      ReferenceProps[index].addressLine1 = formatTextChange(e.target.value);
      //ReferenceProps[index].verifiedDate = '';
      setReferenceProps([...ReferenceProps]);
    } else if (d == `addressLine2_${index}`) {
      ReferenceProps[index].addressLine2 = formatTextChange(e.target.value);
      //ReferenceProps[index].verifiedDate = '';
      setReferenceProps([...ReferenceProps]);
    } else if (d == `zip_${index}`) {
      let cleaned = ('' + e.target.value).replace(/\D/g, '');
      cleaned =
        cleaned.length > 5
          ? cleaned.slice(0, 5) + '-' + cleaned.slice(5)
          : cleaned;
      ReferenceProps[index].postalCode = cleaned;
      //ReferenceProps[index].verifiedDate = '';
      setReferenceProps([...ReferenceProps]);
    } else if (d == `city_${index}`) {
      ReferenceProps[index].city = e.target.value.replace(/[^a-z A-Z ]/g, '');
      //ReferenceProps[index].verifiedDate = '';
      setReferenceProps([...ReferenceProps]);
    } else if (d == `state_${index}`) {
      ReferenceProps[index].state = e.target.value;
      //ReferenceProps[index].verifiedDate = '';
      setReferenceProps([...ReferenceProps]);
    } else if (d == `doNotVisit_${index}`) {
      ReferenceProps[index].doNotCall = e.target.checked ? 'Y' : 'N';
      //ReferenceProps[index].verifiedDate = '';
      setReferenceProps([...ReferenceProps]);
    }
    verification();
  };

  const referenceInfoArrayValidation = () => {
    let validate = true;
    ReferenceProps.forEach((val: any) => {
      val.phoneNumber =
        val.phoneNumber == undefined || val.phoneNumber == null
          ? ''
          : val.phoneNumber;
      if (
        validate !== false &&
        (val.firstName == '' ||
          val.lastName == '' ||
          val.phoneNumber == '' ||
          val.phoneNumber.length < 10 ||
          val.relationshipType == null ||
          val.relationshipType == '0' ||
          val.relationshipType == '') &&
        val.active == 'Y'
      ) {
        validate = false;
      }
    });
    setValidateReference(validate);
    return validate;
  };

  const addanotherReference = () => {
    if (referenceInfoArrayValidation()) {
      const RefLocal = ReferenceProps;
      RefLocal.push({
        verifiedDate: '',
        firstName: '',
        lastName: '',
        phoneNumber: '',
        relationshipType: '',
        bestTimeToCall: '',
        addressLine1: '',
        addressLine2: '',
        postalCode: '',
        city: '',
        state: '',
        active: 'Y',
        doNotCall: '',
      });
      setReferenceProps([...RefLocal]);
    }
  };

  const removeDivanotherReference = (index: any) => {
    const RefLocal = ReferenceProps;
    RefLocal.splice(index, 1);
    setReferenceProps([...RefLocal]);
  };

  const addanotherVehicle = () => {
    const VehicleLocal = Vehicle;
    VehicleLocal.push({
      vehicleMake: null,
      vehicleModel: null,
      vehicleYear: null,
      vehicleColor: null,
      vehicleVin: null,
      vehicleLicensePlate: null,
      vehRegState: null,
      vehPlanExpirationDate: null,
      vehFinancedFrom: null,
      vehMonthlyPayment: '0.00',
    });
    setVehiclevalues([...Vehicle]);
  };

  const removeDivVehicle = (index: any) => {
    const VehicleLocal = Vehicle;
    VehicleLocal.splice(index, 1);
    setVehiclevalues([...Vehicle]);
  };
  const customerOrderRedirection = () => {
    props.history.push(`/agreement/customerorder/itemsearch/${customerId}`);
  };
  const promoRedirection = () => {
    props.history.push(`/agreement/promo/itemsearch/${customerId}`);
  };
  const handleChangeForAddVehicleInfo = (e: any) => {
    setcustomerInfoChange(true);
    const d: any = e.target.name;
    const splitD: any = d.split('_');
    const index = parseInt(splitD[1]);

    if (d == `make_${index}`) {
      Vehicle[index].vehicleMake = formatTextChange(e.target.value);
      setVehiclevalues([...Vehicle]);
    } else if (d == `model_${index}`) {
      Vehicle[index].vehicleModel = formatTextChange(e.target.value);
      setVehiclevalues([...Vehicle]);
    } else if (d == `year_${index}`) {
      Vehicle[index].vehicleYear = e.target.value;
      setVehiclevalues([...Vehicle]);
    } else if (d == `color_${index}`) {
      Vehicle[index].vehicleColor = formatTextChange(e.target.value);
      setVehiclevalues([...Vehicle]);
    } else if (d == `vin_${index}`) {
      //const cleaned = ('' + e.target.value).replace(/\D/g, '');
      Vehicle[index].vehicleVin = formatTextChange(e.target.value);
      setVehiclevalues([...Vehicle]);
    } else if (d == `tag_${index}`) {
      //const cleaned = ('' + e.target.value).replace(/\D/g, '');
      Vehicle[index].vehicleLicensePlate = formatTextChange(e.target.value);
      setVehiclevalues([...Vehicle]);
    } else if (d == `tagState_${index}`) {
      Vehicle[index].vehRegState = e.target.value;
      setVehiclevalues([...Vehicle]);
    } else if (d == `tagExpirationDate_${index}`) {
      const InputTxt = e.target.value;
      const format: any = moment(InputTxt).format(dateFormat);
      const temp = InputTxt.replace(format);

      Vehicle[index].vehPlanExpirationDate = temp;
      setVehiclevalues([...Vehicle]);
    } else if (d == `whereIsAutoFinance_${index}`) {
      Vehicle[index].vehFinancedFrom = e.target.value;
      setVehiclevalues([...Vehicle]);
    } else if (d == `monthlyAutoPayment_${index}`) {
      //const regex = /^[+]?\d*(?:[,]\d*)?$/;
      //if (regex.test(e.target.value)) {
      Vehicle[index].vehMonthlyPayment = e.target.value;
      setVehiclevalues([...Vehicle]);
      //}
    }
  };

  // const CheckboxClick = (agreement_id: number, e: any) => {
  //   const checkActionValue: number[] = checkAction;
  //   if (e.target.checked === true) {
  //     settakePaymentOpen(false);
  //     checkActionValue.push(agreement_id);
  //     setcheckAction(checkActionValue);
  //   } else {
  //     const agreementsChecked = checkActionValue.filter(
  //       (value) => value !== agreement_id
  //     );
  //     setcheckAction(agreementsChecked);
  //     if (agreementsChecked.length !== 0) {
  //       settakePaymentOpen(false);
  //     } else {
  //       settakePaymentOpen(true);
  //     }
  //   }
  // };
  const CheckboxClick = (
    agreement_id: number,
    agreementStatus: any,
    agreementType: any,
    isReinstate: any,
    e: any
  ) => {
    setagrCheckBoxId({ id: agreement_id, checkBox: e.target.checked });
    if (e.target.checked === true && isReinstate === true) {
      settakePaymentOpen(false);
      setcheckAction(agreement_id);
    } else {
      setcheckAction('');
    }
  };

  const GridShow = (agreementStatus: string) => {
    if (agreementStatus === 'Active') {
      setactiveExpand(!activeExpand);
    } else if (agreementStatus === 'InActive') {
      setinActiveExpand(!inActiveExpand);
      setreInstate(!inActiveExpand);
    } else if (agreementStatus === 'Pending') {
      setpending(!pending);
    } else if (agreementStatus === 'Reinstatable') {
      setreInstate(!reInstate);
    }
  };

  const getAgreement = async () => {
    const storeNumber = window.sessionStorage.getItem("storeNumber")
    setagreementInf(undefined);
    const bodyobject = {
      storeNumbers: [String(window.sessionStorage.getItem("storeNumber"))],
      paramKeyNames: ['AgreementExchange', 'AgreementExchangePeriod', 'AgreementExchangeReturnPeriod', 'RACExchange'],
    };
    let CoworkerNum: any = '';
    let getCustomersResAll: any = {};
    let getCustomersResActive: any = {};
    try {
      await Promise.all([
        ((storeNumber && storeNumber != null && storeNumber != undefined && storeNumber != '' && storeNumber != 'null' && storeNumber != 'undefined') ? featureFlagInfo(bodyobject) : Promise.resolve()),
        GetAgreementById(customerId, 'Y'),
        GetAgreementById(customerId, 'N'),
      ]).then((resp) => {
        [CoworkerNum, getCustomersResAll, getCustomersResActive] = resp;
        console.log('getCustomersResAlll', CoworkerNum);
        console.log('getCustomersResAlll', getCustomersResAll);
        console.log('getCustomersResActive', getCustomersResActive);
        /* eslint-disable no-console */
        if (
          CoworkerNum !== undefined &&
          CoworkerNum !== null &&
          CoworkerNum.storeProfileResponse !== undefined &&
          CoworkerNum.storeProfileResponse &&
          CoworkerNum.storeProfileResponse.configDetails !== undefined &&
          CoworkerNum.storeProfileResponse.configDetails.length > 0 &&
          CoworkerNum.storeProfileResponse.configDetails[0].configDetails !==
          undefined &&
          CoworkerNum.storeProfileResponse.configDetails[0].configDetails.length > 0
        ) {
          for (
            let i = 0;
            i <
            CoworkerNum.storeProfileResponse.configDetails[0].configDetails.length;
            i++
          ) {
            const configArray =
              CoworkerNum.storeProfileResponse.configDetails[0].configDetails;
            if (configArray[i].paramKeyName == "AgreementExchange") {
              setExchangeEnabled(
                Number(configArray[i].paramValue) > 0 ? true : false
              );
            }
            if (configArray[i].paramKeyName == "AgreementExchangePeriod") {
              setExchangePeriod(
                configArray[i].paramValue ? configArray[i].paramValue : ''
              );
            }
            if (configArray[i].paramKeyName == "AgreementExchangeReturnPeriod") {
              setReturnPeriod(
                configArray[i].paramValue ? configArray[i].paramValue : ''
              );
            }
            (configArray[i].paramKeyName == "RACExchange" && configArray[i].paramValue == '1') && setRacExchangeFuncFlag(true);
          }
        }
        const agreement =
          getCustomersResAll != undefined && getCustomersResAll.agreement !== undefined ? getCustomersResAll.agreement : [];
        if (getCustomersResActive?.agreement) {
          setGetAgrbyIdresponse(getCustomersResActive?.agreement);
        }
        if (getCustomersResAll.clubInfoResponse.length > 0) {
          getCustomersResAll.clubInfoResponse.forEach((el: any) => {
            getCustomersResAll.agreement.push(el);
          });
        }
        console.log('getAgreement', agreement);

        const agrRes: any = checkAgreementReinstate(agreement);
        setagreementInf(agrRes);
        const activeAgreements = agrRes.filter(
          (value: any) =>
            value.agreementStatus == 'Active' ||
            value.agreementStatus == 'Pending'
        );
        console.log('activeAgreements', getCustomersResAll);
        if (getCustomersResActive.coCustomerId) {
          setSplitCoCustomerAvailble(true);
        }
        setsplitEligibleAgrs(activeAgreements);
      })
    } catch (e: any) {
      if (getCustomersResAll == '') {
        setagreementInf([]);
        setsplitEligibleAgrs([]);
      }
    }
    //setIsLoading(false);
    /* eslint-enable no-console */
  };
  const getDEHistory = async () => {
    if (!deHistoryResponse) {
      setDEHistoryResponse('LOADING');
      const payload: DEHistoryRequest = {
        globalCustomerId: customerInformation?.globalCustomerId,
        limit: '',
        offset: '',
      };
      try {
        const {
          data: { customerApprovalHistory },
        } = await GetDEHistory(payload);
        setDEHistoryResponse(customerApprovalHistory);
      } catch (e: any) {
        setDEHistoryResponse('ERROR');
      }
    }

    //setIsLoading(false);
    /* eslint-enable no-console */
  };
  const checkAgreementReinstate = (customerAgreements: any) => {
    const agr: any = [];
    for (let index = 0; index < customerAgreements.length; index++) {
      const agreement: any = customerAgreements[index];

      if (agreement.reInstated == '1' && storeReinstatementAllowed) {
        agreement.isReinstate = true;
      } else {
        agreement.isReinstate = false;
      }
      agr.push(agreement);
    }
    // eslint-disable-next-line no-console
    console.log('getAgreement agr', agr);
    return agr;
  };
  const filterAgreement = (agreementStatusProp: string) => {
    let agreements: any = [];
    if (
      agreementInf !== undefined &&
      agreementInf !== '' &&
      agreementInf.length > 0
    ) {
      agreements = agreementInf.filter(
        (value: any) => value.agreementStatus == agreementStatusProp
      );
      if (agreementStatusProp == 'InActive') {
        agreements = agreementInf.filter(
          (value: any) =>
            value.agreementStatus != 'Active' &&
            value.agreementStatus != 'Pending' &&
            value.agreementStatus != 'Preliminary' &&
            value.agreementStatus != 'Preliminary Paid' &&
            value.agreementStatus != 'Preliminary Refunded' &&
            value.agreementStatus != 'Preliminary Inventory' &&
            value.isReinstate === false
        );
      } else if (agreementStatusProp == 'Reinstatable') {
        agreements = agreementInf.filter(
          (value: any) => value.isReinstate === true
        );
      }
      else if (agreementStatusProp == 'Pending') {
        agreements = agreementInf.filter(
          (value: any) => value.agreementStatus == 'Pending' ||
            value.agreementStatus == 'Preliminary' || value.agreementStatus == 'Preliminary Paid' || value.agreementStatus == 'Preliminary Refunded' || value.agreementStatus == 'Preliminary Inventory'
        )
      }
    }
    return agreements;
  };
  const getClass = (agreementStatusProp: any) => {
    return agreementStatusProp == 'InActive'
      ? `${customerStyleClasses.racpadAgrLink} ${customerStyleClasses.racpadPaymentInActive} ${customerStyleClasses.racpadLink}`
      : agreementStatusProp == 'PaymentIssue'
        ? `${customerStyleClasses.racpadAgrLink} ${customerStyleClasses.racpadPaymentFails} ${customerStyleClasses.racpadLink}`
        : agreementStatusProp == 'club'
          ? `${customerStyleClasses.racpadClubLink}`
          : `${customerStyleClasses.racpadAgrLink} ${customerStyleClasses.racpadPaymentSuccess} ${customerStyleClasses.racpadLink}`;
  };
  const renderDEHistoryContent = () => {
    return deHistoryResponse.map((el: any) => {
      return (
        <RACTableRow
          key={irt?.next()?.value || null}
          className={customerStyleClasses.customerTablecellbgcolor}
        >
          <RACTableCell className={`${customerStyleClasses.textCenter}`}>
            {el.approvalDate ? moment(el.approvalDate).format('MM/DD/YYYY') : ''}
          </RACTableCell>
          <RACTableCell className={`${customerStyleClasses.textCenter}`}>
            {el.approvalStatusDesc ? el.approvalStatusDesc : ''}
          </RACTableCell>
          <RACTableCell className={`${customerStyleClasses.textCenter}`}>
            {el.originalApprovalAmount ? `${formatCurrency.format(el.originalApprovalAmount)}` : ''}
          </RACTableCell>
          <RACTableCell className={`${customerStyleClasses.textCenter}`}>
            {el.weeklyOriginalApprovalAmount
              ? `${formatCurrency.format(el.weeklyOriginalApprovalAmount)}`
              : ''}
          </RACTableCell>
          <RACTableCell className={`${customerStyleClasses.textCenter}`}>
            {el.createdBy ? el.createdBy : ''}
          </RACTableCell>
          <RACTableCell className={`${customerStyleClasses.textCenter}`}>
            {el.approvalExpirationDate ? moment(el.approvalExpirationDate).format('MM/DD/YYYY') : ''}
          </RACTableCell>
        </RACTableRow>
      );
    });
  };
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const AgreementGrid = (
    agreementStatusProp: string,
    accordianOpen: boolean
    // eslint-disable-next-line sonarjs/cognitive-complexity
  ) => {
    // eslint-disable-next-line sonarjs/cognitive-complexity
    /* eslint-disable no-console */
    console.log('agreementInf', agreementInf);
    const exchangeAgreementsList: any = {};
    const agreementStatus: any = {};
    agreementInf.forEach((element: any) => {
      exchangeAgreementsList[element?.agreementId] = element?.parentAgreementId;
      agreementStatus[element?.agreementId] = element?.isExchangeAgreement;
    });
    console.log(exchangeAgreementsList, 'List of the Parent Agreements');
    const agreements = filterAgreement(agreementStatusProp);
    if (
      agreements !== undefined &&
      agreements.length !== undefined &&
      agreements.length > 0
    ) {
      // eslint-disable-next-line sonarjs/cognitive-complexity
      return agreements.map((value: any, index: any) => {
        const passDue =
          value.currentDayslate !== undefined &&
            value.currentDayslate !== null &&
            value.currentDayslate !== '' &&
            value.currentDayslate !== '0' &&
            value.currentDayslate !== 0
            ? false
            : true;
        return (
          <React.Fragment key={'agreement' + index}>
            {index === 0 ? (
              <RACTableRow
                key={'agreement' + index}
                className={customerStyleClasses.customerTablecellbgcolor}
              >
                <RACTableCell
                  colSpan={10}
                  key={'agreement' + index}
                  className={
                    value.isReinstate === true
                      ? `${customerStyleClasses.pl40}`
                      : `${classes.agreementCategory}`
                  }
                >
                  {/* <span
                    className={
                      accordianOpen === false
                        ? `${customerStyleClasses.accordionIconGrid} ${customerStyleClasses.me2} ${customerStyleClasses.accordionGridClose}`
                        : `${customerStyleClasses.accordionIconGrid} ${customerStyleClasses.me2}`
                    }
                    onClick={() => GridShow(agreementStatusProp)}
                  ></span> */}
                  {accordianOpen === false ? (
                    <AccordianCloseIcon
                      onClick={() => GridShow(agreementStatusProp)}
                      className={`${customerStyleClasses.accordionIconGrid} ${customerStyleClasses.me2} ${customerStyleClasses.accordionGridClose}`}
                    />
                  ) : (
                    <AccordianOpenIcon
                      onClick={() => GridShow(agreementStatusProp)}
                      className={`${customerStyleClasses.accordionIconGrid} ${customerStyleClasses.me2}`}
                    />
                  )}
                  {agreementStatusProp}
                </RACTableCell>
              </RACTableRow>
            ) : null}
            {accordianOpen === true ? (
              <RACTableRow
                className={
                  passDue === false
                    ? `${customerStyleClasses.paymentIssue} ${customerStyleClasses.agreementRow}`
                    : `${customerStyleClasses.agreementRow} ${customerStyleClasses.customerTablecellbgcolor}`
                }
              >
                <RACTableCell>
                  <RACCheckBox
                    className={
                      value.isReinstate === true
                        ? `${customerStyleClasses.pl28}`
                        : `${customerStyleClasses.me2}`
                    }
                    checked={
                      value.agreementId == agrCheckBoxId.id &&
                      agrCheckBoxId.checkBox
                    }
                    onClick={(e: any) =>
                      CheckboxClick(
                        value.agreementId,
                        value.agreementStatus,
                        agreementStatusProp,
                        value.isReinstate,
                        e
                      )
                    }
                  />{' '}
                  {value.agreementType === 'club' ? (
                    <a
                      className={`${getClass(
                        passDue === false ? 'PaymentIssue' : 'club'
                      )} ${customerStyleClasses.focforLinkPaymentIssue}`}
                      style={{ padding: '3px !important' }}
                    >
                      {value.agreementNumber}
                    </a>
                  ) : featureFlagDetails?.CustomerOrder == '0' && ['PRELIM', 'PRELIMPAID', 'PRELIMRFND', 'PRELIMINV'].includes(value.agreementRefCode) ? (
                    <a
                      className={`${getClass(
                        passDue === false ? 'PaymentIssue' : agreementStatusProp
                      )} ${customerStyleClasses.focforLinkPaymentIssue}`}
                      style={{ padding: '3px !important' }}
                    >
                      {value.agreementNumber}
                    </a>
                  ) : (
                    <a
                      className={`${getClass(
                        passDue === false ? 'PaymentIssue' : agreementStatusProp
                      )} ${customerStyleClasses.focforLinkPaymentIssue}`}
                      style={{ padding: '3px !important' }}
                      href={exchangeAgreementsList[value.agreementId] && racExchangeFuncFlag && agreementStatus[value.parentAgreementId] ? `/agreement/info/details/${customerId}/${value.agreementId}?isExchange=1&exchangeagreementid=${exchangeAgreementsList[value.agreementId]}`
                      : `/agreement/info/details/${customerId}/${value.agreementId}`}
                    >
                      {value.agreementNumber}
                    </a>
                  )}
                  {/* <a
                    className={getClass(
                      passDue === false ? 'PaymentIssue' : agreementStatusProp
                    )}
                    style={{ padding: '3px !important' }}
                    href={`/agreement/info/details/${customerId}/${value.agreementId}`}
                  >
                    {value.agreementNumber}
                  </a> */}
                  {value.autoPay !== null &&
                    value.autoPay !== undefined &&
                    value.autoPay ? (
                    <span className={customerStyleClasses.badgeContainer}>
                      AP
                    </span>
                  ) : null}
                </RACTableCell>
                <RACTableCell
                  title={value.agreementDescription}
                  className={
                    value.agreementDescription !== undefined &&
                      value.agreementDescription !== null &&
                      value.agreementDescription !== ''
                      ? ''
                      : `${customerStyleClasses.textCenter}`
                  }
                >
                  {value.agreementDescription !== undefined &&
                    value.agreementDescription !== null &&
                    value.agreementDescription !== ''
                    ? truncString(value.agreementDescription, 30, '...')
                    : '-'}
                </RACTableCell>
                <RACTableCell
                  title={value.agreementType}
                  className={
                    value.agreementType !== undefined &&
                      value.agreementType !== null &&
                      value.agreementType !== ''
                      ? ''
                      : `${customerStyleClasses.textCenter}`
                  }
                >
                  {value.agreementType !== undefined &&
                    value.agreementType !== null &&
                    value.agreementType !== ''
                    ? truncString(value.agreementType, 13, '...')
                    : '-'}
                </RACTableCell>
                <RACTableCell
                  className={
                    value.storeNumber !== undefined &&
                      value.storeNumber !== null &&
                      value.storeNumber !== ''
                      ? ''
                      : `${customerStyleClasses.textCenter}`
                  }
                >
                  {value.storeNumber !== undefined &&
                    value.storeNumber !== null &&
                    value.storeNumber !== ''
                    ? value.storeNumber
                    : '-'}
                </RACTableCell>
                <RACTableCell
                  className={
                    value.numberOfPayments !== undefined &&
                      value.numberOfPayments !== null &&
                      value.numberOfPayments !== ''
                      ? `${customerStyleClasses.textRight}`
                      : `${customerStyleClasses.textCenter}`
                  }
                >
                  {value.numberOfPayments !== undefined &&
                    value.numberOfPayments !== null &&
                    value.numberOfPayments !== ''
                    ? value.numberOfPayments
                    : '-'}
                </RACTableCell>
                <RACTableCell
                  className={
                    value.totalCost !== undefined &&
                      value.totalCost !== null &&
                      value.totalCost !== ''
                      ? `${customerStyleClasses.lineBreak} ${classes.textRight}`
                      : `${customerStyleClasses.textCenter}`
                  }
                >
                  {value.totalCost !== undefined &&
                    value.totalCost !== null &&
                    value.totalCost !== ''
                    ? '$ '
                    : '-'}

                  {value.totalCost !== undefined &&
                    value.totalCost !== null &&
                    value.totalCost !== ''
                    ? parseFloat(value.totalCost)
                      .toFixed(2)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    : ''}
                </RACTableCell>
                <RACTableCell
                  className={
                    value.paymentSchedule !== undefined &&
                      value.paymentSchedule !== null &&
                      value.paymentSchedule !== ''
                      ? `${customerStyleClasses.lineBreak}`
                      : `${customerStyleClasses.textCenter}`
                  }
                >
                  {value.paymentSchedule !== undefined &&
                    value.paymentSchedule !== null &&
                    value.paymentSchedule !== ''
                    ? value.paymentSchedule
                    : '-'}
                </RACTableCell>
                <RACTableCell
                  className={value.nextDueDate !== null &&
                      value.nextDueDate !== ''
                      ? `${customerStyleClasses.lineBreak} ${customerStyleClasses.txtStart}`
                      : `${customerStyleClasses.textCenter}`
                  }
                >
                  {value?.isExchangeAgreement && racExchangeFuncFlag && value?.agreementStatus == 'Active' ? 
                    <span style={{
                      color: '#fcd209', 
                      // fontWeight: 'bold', 
                      float: 'left' 
                    }}>Exchange In Progress</span>
                    :value.nextDueDate !== undefined &&
                    value.nextDueDate !== null &&
                    value.nextDueDate !== ''
                    ? nextDueDayWithDate(value.nextDueDate)
                    : '-'}
                </RACTableCell>
                <RACTableCell
                  className={
                    value.amountDue !== undefined &&
                      value.amountDue !== null &&
                      value.amountDue !== ''
                      ? `${customerStyleClasses.lineBreak} ${customerStyleClasses.textCenter}`
                      : `${customerStyleClasses.textCenter}`
                  }
                >
                  {value.amountDue !== undefined &&
                    value.amountDue !== null &&
                    value.amountDue !== ''
                    ? '$ '
                    : '-'}

                  {value.amountDue !== undefined &&
                    value.amountDue !== null &&
                    value.amountDue !== ''
                    ? parseFloat(value.amountDue)
                      .toFixed(2)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    : ''}
                </RACTableCell>
                <RACTableCell
                  className={
                    value.agreementSubstatus !== undefined &&
                      value.agreementSubstatus !== null &&
                      value.agreementSubstatus !== ''
                      ? `${customerStyleClasses.lineBreak}`
                      : `${customerStyleClasses.textCenter}`
                  }
                >
                  {value.agreementSubstatus !== undefined &&
                    value.agreementSubstatus !== null &&
                    value.agreementSubstatus !== ''
                    ? value.agreementSubstatus
                    : '-'}
                </RACTableCell>
                {isExchangeEnabled ?
                  <RACTableCell
                  style={{ textAlign: 'center' }}
                  className={value?.isExchangeAgreement && racExchangeFuncFlag ? `${customerStyleClasses.pointerEvents} ${customerStyleClasses.positionRelative}` : ''}
                  >
                  {/* {
                    value?.isExchangeAgreement && racExchangeFuncFlag ? 
                    <Grid className={`${customerStyleClasses.overlay}`}>
                      
                    </Grid> : null
                  } */}
                  {validateForExchange(value?.agreementStatus, value?.closeDate, value?.currentDueDate, value?.agreementOpenDate, value.isEppAgreement, value.agreementType) && (value.storeNumber == window.sessionStorage.getItem("storeNumber")) ? (
                      <RACTooltip
                        placement={value?.isDeliveryConfirmed == '0' ? 'top' : 'top-start'}
                        className={customerStyleClasses.tooltipcss}
                        classes={{
                          tooltip: customerStyleClasses.toolTipStyle,
                          arrow: customerStyleClasses.arrowStyle
                        }}
                        title={<span style={{ fontSize: '10px' }}>{value?.isDeliveryConfirmed == '0' ? `Please confirm delivery to initiate an exchange` :`Exchange`}</span>
                        }
                      >
                        {/* <Grid className={value?.isDeliveryConfirmed == '0' ? `${customerStyleClasses.positionRelative}` : ``}> */}
                          {/* {
                            value?.isDeliveryConfirmed == '0' ?
                            <Grid className={`${customerStyleClasses.overlay1}`}>

                            </Grid> : null
                          } */}
                        <ExchangeIcon className={(value?.isDeliveryConfirmed == '0' || value?.isExchangeAgreement) ? `${customerStyleClasses.iconWidth1} ${customerStyleClasses.pointer} ${customerStyleClasses.blur}`: `${customerStyleClasses.iconWidth1} ${customerStyleClasses.pointer}`}
                          onClick={() => {
                            if (racExchangeFuncFlag && value?.isDeliveryConfirmed == '1') {
                              racCoWorkerFunc('RACExchange');
                              setExchangeSelected(true);
                              setSecondLevelAuthenOn('RACExchange');
                              setRentPaid(value?.rentPaid);
                              setAgreementId(value?.agreementId);
                              setSuspenseAmount(value?.suspenseAmount == '0.00' ? 0 : value?.suspenseAmount)
                              setExchangeAgreementNumber(value?.agreementNumber)
                            }
                          }}
                        />
                        {/* </Grid> */}
                      </RACTooltip>
                  ) : <span style={{ textAlign: 'center', height: '100%', }}>-</span>}
                </RACTableCell>
                  : null
                }
              </RACTableRow>
            ) : null}
          </React.Fragment>
        );
      });
    }
  };
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const AgreementGridForReinstate = (
    agreementStatusProp: string,
    accordianOpen: boolean
    // eslint-disable-next-line sonarjs/cognitive-complexity
    // eslint-disable-next-line sonarjs/no-identical-functions
  ) => {
    // eslint-disable-next-line sonarjs/cognitive-complexity
    /* eslint-disable no-console */
    console.log('agreementInf', agreementInf);
    // const agreements = filterAgreement(agreementStatusProp);
    // if (
    //   agreements !== undefined &&
    //   agreements.length !== undefined &&
    //   agreements.length > 0
    // ) {
    // eslint-disable-next-line sonarjs/cognitive-complexity
    // eslint-disable-next-line sonarjs/no-identical-functions
    // return agreements.map((value: any, index: any) => {
    return (
      <React.Fragment key={'agreement'}>
        <RACTableRow
          key={'agreement'}
          className={customerStyleClasses.customerTablecellbgcolor}
        >
          <RACTableCell
            colSpan={10}
            key={'agreement'}
            className={`${classes.agreementCategory}`}
          >
            {/* <span
                      className={
                        accordianOpen === false
                          ? `${customerStyleClasses.accordionIconGrid} ${customerStyleClasses.me2} ${customerStyleClasses.accordionGridClose}`
                          : `${customerStyleClasses.accordionIconGrid} ${customerStyleClasses.me2}`
                      }
                      onClick={() => GridShow(agreementStatusProp)}
                    ></span> */}
            {accordianOpen === false ? (
              <AccordianCloseIcon
                onClick={() => GridShow(agreementStatusProp)}
                className={`${customerStyleClasses.accordionIconGrid} ${customerStyleClasses.me2} ${customerStyleClasses.accordionGridClose}`}
              />
            ) : (
              <AccordianOpenIcon
                onClick={() => GridShow(agreementStatusProp)}
                className={`${customerStyleClasses.accordionIconGrid} ${customerStyleClasses.me2}`}
              />
            )}
            {agreementStatusProp}
          </RACTableCell>
        </RACTableRow>
      </React.Fragment>
    );
    // });
    // }
  };
  const isDueDatePassed = (date: any) => {
    /* eslint-disable no-console */
    console.log(date);
    // const date1: any = new Date(date);
    // const cMonth = dt.getMonth() + 1;
    // const cDate = dt.getDate() < 10 ? '0' + dt.getDate() : dt.getDate();
    // const cYear = dt.getFullYear();
    // const currentDate: any = new Date(`${cMonth}/${cDate}/${cYear}`);
    // const diffTime = Math.abs(currentDate - date1);
    // const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    let myFutureDate: any = '';
    myFutureDate = new Date(date);
    const date2 = new Date();
    return myFutureDate.getTime() > date2.getTime() ? false : true;
  };
  const nextDueDayWithDate = (date: any) => {
    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const today = new Date(date);
    // eslint-disable-next-line no-console
    console.log('today', today);
    const index = today.getDay();
    // eslint-disable-next-line no-console
    console.log('today', days[index] + ', ' + date);
    return days[index] !== undefined && days[index] !== null
      ? days[index] + ', ' + date
      : date;
  };
  const truncString = (str: any, max: number, add: any) => {
    add = add || '...';
    return str !== undefined &&
      str !== null &&
      typeof str === 'string' &&
      str.length > max
      ? str.substring(0, max) + add
      : str;
  };
  const hideButtons = () => {
    setactivateClub(!activateClub);
    //setClubSatus(!activateClub);
  };
  const checkVerifyDate: any = (date: any) => {
    try {
      console.log('date param', date);
      if (date !== undefined && date !== null && date !== '') {
        let myFutureDate: any = '';
        myFutureDate = new Date(date);
        myFutureDate.setDate(myFutureDate.getDate() + parseInt(verExpDate));
        //const date1 = new Date(verificateDate[0]);
        console.log('futureDate', myFutureDate.getTime());
        const date2 = moment(new Date(), 'YYYY-MM-DD');
        const date1 = moment(myFutureDate, 'YYYY-MM-DD');
        //console.log('date2', date2.getTime());
        // const stDate = moment(date, 'YYYY-MM-DD');
        // const lt18 = moment(
        //   date.add(parseInt(verExpDate), 'days'),
        //   'YYYY-MM-DD'
        // );
        const lt18Res: any = date1.diff(date2, 'days');
        console.log('lt18ResForDate', lt18Res);
        if (lt18Res > 0) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } catch (e: any) {
      return false;
    }
  };
  // const dynamicDropdown = (val: any, isSort?: boolean) => {
  //   if (val !== undefined && val !== null && val.length > 0) {
  //     val =
  //       isSort === false
  //         ? val
  //         : val.sort((a: any, b: any) => (a['Text'] < b['Text'] ? -1 : 1));
  //     return val.map((value: any) => {
  //       return (
  //         <option key={value.name} value={value.value}>
  //           {value.label}
  //         </option>
  //       );
  //     });
  //   }
  // };
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const verifiedEmployeDisable = (data: any) => {
    let verfDisable = false;
    verfDisable = validateValue(data.employerName);
    verfDisable =
      verfDisable === false
        ? validateValue(data?.employerPhoneNumber)
        : verfDisable;
    verfDisable =
      verfDisable === false
        ? data?.employerPhoneNumber.length < 10
          ? true
          : verfDisable
        : verfDisable;
    verfDisable =
      verfDisable === false
        ? validateValue(data?.employerPayschedule)
        : verfDisable;
    verfDisable =
      verfDisable === false
        ? data?.employerPayschedule == '0'
          ? true
          : verfDisable
        : verfDisable;
    verfDisable =
      verfDisable === false
        ? data?.employerPayschedule != undefined &&
          data?.employerPayschedule != null &&
          data?.employerPayschedule != '0' &&
          data?.employerPayschedule != '' &&
          data?.employerPayschedule != 'DAILY' &&
          (data?.daysPaid == undefined ||
            data?.daysPaid == null ||
            data?.daysPaid == '' ||
            data?.daysPaid == '0')
          ? true
          : verfDisable
        : verfDisable;
    // verfDisable =
    //   verfDisable === false ? validateValue(data?.addressLine1) : verfDisable;
    // verfDisable =
    //   verfDisable === false ? validateValue(data?.postalCode) : verfDisable;
    // verfDisable =
    //   verfDisable === false
    //     ? data?.postalCode.length != 5 && data?.postalCode.length != 10
    //       ? true
    //       : verfDisable
    //     : verfDisable;
    // verfDisable =
    //   verfDisable === false ? validateValue(data?.city) : verfDisable;
    // verfDisable =
    //   verfDisable === false ? validateValue(data?.state) : verfDisable;
    // verfDisable =
    //   verfDisable === false
    //     ? data?.state == '0'
    //       ? true
    //       : verfDisable
    //     : verfDisable;
    return verfDisable;
  };
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const employerInfoFunc = () => {
    return EmpProps.map((data: any, index: any) => {
      console.log('data emp', data);
      const isActive: boolean =
        data?.active !== undefined &&
          data?.active !== '' &&
          data?.active !== null &&
          data?.active !== 'null' &&
          data?.active == 'Y'
          ? true
          : false;
      const dayPaidOptions: dropdown[] =
        data?.employerPayschedule == 'SEMI'
          ? dayPaid5
          : data?.employerPayschedule == 'WK' ||
            data?.employerPayschedule == 'BIWK'
            ? dayPaid1
            : data?.employerPayschedule == 'MON'
              ? dayPaid3
              : [];
      // eslint-disable-next-line no-console
      console.log(
        'employerPayschedule',
        data?.employerPayschedule == 'SEMI'
          ? dayPaid5
          : data?.employerPayschedule == 'WK' ||
            data?.employerPayschedule == 'BIWK'
            ? dayPaid1
            : data?.employerPayschedule == 'MON'
              ? dayPaid3
              : null
      );
      const isVerifiedDisabled: boolean = verifiedEmployeDisable(data);
      //Employer information starts here
      // eslint-disable-next-line no-console
      console.log('employerPayschedule', data?.employerPayschedule);
      if (!isDropDownValueExist(howLongObj, data?.lengthOfEmploymentYears)) {
        data.lengthOfEmploymentYears = '';
      }
      if (!isDropDownValueExist(payDayfrequency, data?.employerPayschedule)) {
        data.employerPayschedule = '';
      }
      if (!isDropDownValueExist(dayPaidOptions, data?.daysPaid)) {
        data.daysPaid = '';
      }
      if (!isDropDownValueExist(state, data?.state)) {
        data.state = '';
      }
      if (data?.postalCode.includes('-') && data?.postalCode?.split('-')[1]?.length == 0) {
        data.postalCode = data?.postalCode.split('-')[0]
      }
      // if (data?.postalCode.length == 9) {
      //   data.postalCode = data?.postalCode.slice(0, 5) + '-' + data?.postalCode.slice(5)
      // }
      return (
        <Grid container key={index} className={classes.mb1}>
          <Card
            className={`${customerStyleClasses.card} ${customerStyleClasses.w100
              } ${index !== '0' ? customerStyleClasses.mb2 : ''}`}
          >
            <CardContent id="employe_info_div">
              <Grid container id={`anotherEmployeInfo_${index}`}>
                <Grid item md={10} className={classes.mb0}>
                  <Typography className={classes.title}>
                    Basic Information
                  </Typography>
                </Grid>
                <Grid item md={2} className={classes.textRight}>
                  <Grid container spacing={2}>
                    {pageType == undefined || pageType == 'customer' ? (
                      <Grid item md={6}>
                        <Grid item className={customerStyleClasses.formCheck}>
                          <RACCheckBox
                            label="Verified"
                            size="small"
                            className={classes.custCheckbox}
                            name={`verifiedCheckBox_${index}`}
                            onChange={(e: any) => {
                              handleChangeForAddEmployeInfo(e);
                            }}
                            disabled={Agrtransferinit ? true : (isVerifiedDisabled || !isActive)}
                            checked={
                              data?.verifiedDate !== undefined &&
                                data?.verifiedDate !== '' &&
                                data?.verifiedDate !== null &&
                                data?.verifiedDate !== 'null'
                                ? true
                                : false
                            }
                          />
                        </Grid>
                      </Grid>
                    ) : null}
                    {pageType == undefined || pageType == 'customer' ? (
                      <Grid item md={6}>
                        <Grid
                          item
                          className={`${customerStyleClasses.formCheck} ${customerStyleClasses.floatRight}`}
                        >
                          <RACCheckBox
                            label="Active"
                            disabled={Agrtransferinit}
                            size="small"
                            className={classes.custCheckbox}
                            name={`verifiedActiveDiv_${index}`}
                            onChange={(e: any) => {
                              handleChangeForAddEmployeInfo(e);
                            }}
                            checked={
                              data?.active !== undefined &&
                                data?.active !== '' &&
                                data?.active !== null &&
                                data?.active !== 'null' &&
                                data?.active == 'Y'
                                ? true
                                : false
                            }
                          />
                        </Grid>
                      </Grid>
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>
              <Grid container spacing={2} className={customerStyleClasses.mb3}>
                <Grid item md={4} lg={2}>
                  <RACTextbox
                    isCurrency={false}
                    required={data?.active == 'Y' ? true : false}
                    type={'text'}
                    inputlabel="Company Name"
                    name={`companyName_${index}`}
                    OnChange={(e: any) => handleChangeForAddEmployeInfo(e)}
                    maxlength={30}
                    value={
                      data?.employerName !== undefined ? data?.employerName : ''
                    }
                    disabled={Agrtransferinit ? true : !isActive}
                    errorMessage={
                      data?.employerName !== undefined &&
                        data?.employerName == '' &&
                        (isrequired === true || validateEmployement === false) &&
                        data?.active == 'Y'
                        ? FIELD_ERROR
                        : ''
                    }
                  />
                </Grid>
                <Grid item md={4} lg={2}>
                  <RACSelect
                    inputLabel="How Long?"
                    options={howLongObj}
                    isDisabled={Agrtransferinit ? true : !isActive}
                    defaultValue={
                      data?.lengthOfEmploymentYears !== undefined &&
                        data?.lengthOfEmploymentYears !== null &&
                        data?.lengthOfEmploymentYears !== 'NaN' &&
                        isDropDownValueExist(
                          howLongObj,
                          data?.lengthOfEmploymentYears
                        )
                        ? data?.lengthOfEmploymentYears
                        : '0'
                    }
                    loading={
                      howLongObj.length == 0 && !dropDownLoaded ? true : false
                    }
                    onChange={(e: any) => {
                      e.target.name = `howLong_${index}`;
                      handleChangeForAddEmployeInfo(e);
                    }}
                    {...(howLongObj.length == 0 &&
                      dropDownLoaded && {
                      errorMessage: API_ERROR_MESSAGE,
                    })}
                  />
                </Grid>
                <Grid item md={4} lg={2}>
                  <RACTextbox
                    isCurrency={false}
                    required={data?.active == 'Y' ? true : false}
                    type={
                      data?.doNotCall !== undefined && data?.doNotCall == 'Y'
                        ? 'password'
                        : 'text'
                    }
                    inputlabel="Employer Phone Number"
                    name={`employePhoneNum_${index}`}
                    OnChange={(e: any) => handleChangeForAddEmployeInfo(e)}
                    maxlength={14}
                    value={
                      data?.employerPhoneNumber !== undefined
                        ? formatPhone(data?.employerPhoneNumber)
                        : ''
                    }
                    disabled={
                      Agrtransferinit ? true : (
                        (data?.doNotCall !== undefined &&
                          data?.doNotCall == 'Y') ||
                          isActive === false
                          ? true
                          : false)
                    }
                    errorMessage={
                      (data?.employerPhoneNumber == undefined ||
                        data?.employerPhoneNumber == null ||
                        data?.employerPhoneNumber == '') &&
                        (isrequired === true || validateEmployement === false) &&
                        data?.active == 'Y'
                        ? FIELD_ERROR
                        : (isrequired === true ||
                          validateEmployement === false) &&
                          data?.employerPhoneNumber !== undefined &&
                          data?.employerPhoneNumber !== null &&
                          data?.employerPhoneNumber !== '' &&
                          data?.employerPhoneNumber.length < 10 &&
                          data?.active == 'Y'
                          ? PHONE_ERROR_MESSAGE
                          : ''
                    }
                  />
                </Grid>
                <Grid item md={3} lg={2}>
                  <Grid
                    item
                    className={`${customerStyleClasses.formCheck} ${classes.mtcustom} ${classes.ps0}`}
                  >
                    <RACCheckBox
                      label="Do Not Call"
                      size="small"
                      disabled={Agrtransferinit}
                      className={classes.custCheckbox}
                      name={`doNotCall_${index}`}
                      onChange={(e: any) => {
                        handleChangeForAddEmployeInfo(e);
                      }}
                      checked={
                        data?.doNotCall !== undefined && data?.doNotCall == 'Y'
                          ? true
                          : false
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container spacing={2} className={customerStyleClasses.mb3}>
                <Grid item md={12} className={customerStyleClasses.pb0}>
                  <Typography variant="h6" className={classes.title}>
                    Income Verification
                  </Typography>
                </Grid>
                <Grid item md={3} lg={2} className={classes.pt0}>
                  <RACSelect
                    inputLabel="Payday Frequency"
                    options={payDayfrequency}
                    defaultValue={
                      data?.employerPayschedule !== undefined &&
                        data?.employerPayschedule !== null &&
                        data?.employerPayschedule !== '' &&
                        isDropDownValueExist(
                          payDayfrequency,
                          data?.employerPayschedule
                        )
                        ? data?.employerPayschedule
                        : '0'
                    }
                    required={data?.active == 'Y' ? true : false}
                    loading={
                      payDayfrequency.length == 0 && !dropDownLoadedEmp
                        ? true
                        : false
                    }
                    isDisabled={Agrtransferinit ? true : !isActive}
                    onChange={(e: any) => {
                      e.target.name = `payDayFreq_${index}`;
                      handleChangeForAddEmployeInfo(e);
                    }}
                    errorMessage={
                      data?.employerPayschedule !== undefined &&
                        (data?.employerPayschedule == '' ||
                          data?.employerPayschedule == '0') &&
                        (isrequired === true || validateEmployement === false) &&
                        data?.active == 'Y'
                        ? FIELD_ERROR
                        : ''
                    }
                    {...(payDayfrequency.length == 0 &&
                      dropDownLoadedEmp && {
                      errorMessage: API_ERROR_MESSAGE,
                    })}
                  />
                </Grid>
                <Grid item md={2} lg={2} className={classes.pt0}>
                  <RACSelect
                    inputLabel="Day Paid"
                    options={dayPaidOptions}
                    defaultValue={
                      data?.daysPaid !== undefined &&
                        data?.daysPaid !== null &&
                        data?.daysPaid !== '' &&
                        isDropDownValueExist(dayPaidOptions, data?.daysPaid)
                        ? data?.daysPaid
                        : '0'
                    }
                    required={
                      data?.active == 'Y' &&
                        data?.employerPayschedule != undefined &&
                        data?.employerPayschedule != null &&
                        data?.employerPayschedule != '0' &&
                        data?.employerPayschedule != '' &&
                        data?.employerPayschedule != 'DAILY'
                        ? true
                        : false
                    }
                    isDisabled={
                      Agrtransferinit ? true :
                        (!isActive ||
                          data?.employerPayschedule == 'DAILY' ||
                          data?.employerPayschedule == undefined ||
                          data?.employerPayschedule == null ||
                          data?.employerPayschedule == '' ||
                          data?.employerPayschedule == '0')
                    }
                    loading={
                      dayPaidOptions.length == 0 && !dropDownLoadedEmp
                        ? true
                        : false
                    }
                    onChange={(e: any) => {
                      e.target.name = `datePaid_${index}`;
                      handleChangeForAddEmployeInfo(e);
                    }}
                    errorMessage={
                      (data?.employerPayschedule == 'WK' ||
                        data?.employerPayschedule == 'SEMI' ||
                        data?.employerPayschedule == 'MON' ||
                        data?.employerPayschedule == 'BIWK') &&
                        (data?.daysPaid == undefined ||
                          data?.daysPaid == null ||
                          data?.daysPaid == '' ||
                          data?.daysPaid == '0') &&
                        (isrequired === true || validateEmployement === false) &&
                        data?.active == 'Y'
                        ? FIELD_ERROR
                        : ''
                    }
                    {...(dayPaidOptions.length == 0 &&
                      data?.employerPayschedule != 'DAILY' &&
                      data?.employerPayschedule !== '0' &&
                      data?.employerPayschedule != null &&
                      data?.employerPayschedule != undefined &&
                      data?.employerPayschedule != '' &&
                      dropDownLoadedEmp && {
                      errorMessage: API_ERROR_MESSAGE,
                    })}
                  />
                </Grid>
                <Grid item md={2} lg={2} className={classes.pt0}>
                  <RACTextbox
                    isCurrency={true}
                    type={'number'}
                    digitFormat={'currency'}
                    inputlabel="Take-Home Pay"
                    name={`takeHomePay_${index}`}
                    OnChange={(e: any) => handleChangeForAddEmployeInfo(e)}
                    maxlength={10}
                    value={
                      data?.takeHomePay !== undefined &&
                        data?.takeHomePay !== null &&
                        data?.takeHomePay !== 'NaN'
                        ? data?.takeHomePay
                        : '0.00'
                    }
                    disabled={Agrtransferinit ? true : !isActive}
                    className={classes.custDigit}
                    dollarTextClassName={classes.currencyDollarField}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item md={12} className={customerStyleClasses.pb0}>
                  <Typography variant="h6" className={classes.title}>
                    Address Information
                  </Typography>
                </Grid>
                <Grid item md={3} lg={2} className={classes.pt0}>
                  <RACTextbox
                    isCurrency={false}
                    required={false}
                    type={
                      data?.doNotVisit !== undefined && data?.doNotVisit == 'Y'
                        ? 'password'
                        : 'text'
                    }
                    inputlabel={addressLine1}
                    placeHolder={addressLine1}
                    name={`addressLine1_${index}`}
                    OnChange={(e: any) => handleChangeForAddEmployeInfo(e)}
                    maxlength={30}
                    value={
                      data?.addressLine1 !== undefined ? data.addressLine1 : ''
                    }
                    disabled={
                      Agrtransferinit ? true :
                        ((data?.doNotVisit !== undefined &&
                          data?.doNotVisit == 'Y') ||
                          isActive === false
                          ? true
                          : false)
                    }
                  />
                </Grid>
                <Grid item md={3} lg={2} className={classes.pt0}>
                  <RACTextbox
                    isCurrency={false}
                    type={
                      data?.doNotVisit !== undefined && data?.doNotVisit == 'Y'
                        ? 'password'
                        : 'text'
                    }
                    inputlabel="Address Line 2"
                    infoIcon={true}
                    infoTitle={addressLine2}
                    placeHolder={addressLine2}
                    name={`addressLine2_${index}`}
                    OnChange={(e: any) => handleChangeForAddEmployeInfo(e)}
                    maxlength={30}
                    value={
                      data?.addressLine2 !== undefined ? data?.addressLine2 : ''
                    }
                    disabled={
                      Agrtransferinit ? true :
                        ((data?.doNotVisit !== undefined &&
                          data?.doNotVisit == 'Y') ||
                          isActive === false
                          ? true
                          : false)
                    }
                  />
                </Grid>
                <Grid item md={3} lg={2} className={classes.pt0}>
                  <RACTextbox
                    isCurrency={false}
                    required={false}
                    type={
                      data?.doNotVisit !== undefined && data?.doNotVisit == 'Y'
                        ? 'password'
                        : 'text'
                    }
                    inputlabel="Zip"
                    placeHolder="Zip Code"
                    name={`zip_${index}`}
                    OnChange={(e: any) => handleChangeForAddEmployeInfo(e)}
                    maxlength={10}
                    value={
                      data?.postalCode !== undefined ? data?.postalCode : ''
                    }
                    disabled={
                      Agrtransferinit ? true :
                        ((data?.doNotVisit !== undefined &&
                          data?.doNotVisit == 'Y') ||
                          isActive === false
                          ? true
                          : false)
                    }
                    errorMessage={
                      data?.postalCode != undefined &&
                        data?.postalCode != null &&
                        data?.postalCode != '' &&
                        data?.active == 'Y' &&
                        isrequired === true &&
                        data?.postalCode.length > 0 &&
                        data?.postalCode.length != 5 &&
                        data?.postalCode.length != 10
                        ? INVALID_ZIP
                        : ''
                    }
                  />
                </Grid>
                <Grid item md={3} lg={2} className={classes.pt0}>
                  <RACTextbox
                    isCurrency={false}
                    required={false}
                    type={
                      data?.doNotVisit !== undefined && data?.doNotVisit == 'Y'
                        ? 'password'
                        : 'text'
                    }
                    inputlabel="City"
                    placeHolder="City"
                    name={`city_${index}`}
                    OnChange={(e: any) => handleChangeForAddEmployeInfo(e)}
                    maxlength={30}
                    value={data?.city !== undefined ? data?.city : ''}
                    disabled={
                      Agrtransferinit ? true :
                        ((data?.doNotVisit !== undefined &&
                          data?.doNotVisit == 'Y') ||
                          isActive === false
                          ? true
                          : false)
                    }
                  />
                </Grid>
                <Grid item md={4} lg={1} className={classes.pt0}>
                  <RACSelect
                    inputLabel="State"
                    options={state}
                    isDisabled={Agrtransferinit}
                    defaultValue={
                      data?.state !== undefined &&
                        data?.doNotVisit !== undefined &&
                        data?.doNotVisit == 'Y'
                        ? '**'
                        : data?.state !== undefined &&
                          data?.state !== null &&
                          data?.state !== '' &&
                          isDropDownValueExist(state, data?.state)
                          ? data?.state
                          : '0'
                    }
                    required={false}
                    loading={
                      state.length == 0 && !dropDownLoaded ? true : false
                    }
                    onChange={(e: any) => {
                      e.target.name = `state_${index}`;
                      handleChangeForAddEmployeInfo(e);
                    }}
                    {...(state.length == 0 &&
                      dropDownLoaded && {
                      errorMessage: API_ERROR_MESSAGE,
                    })}
                  />
                </Grid>
                <Grid
                  item
                  md={3}
                  lg={2}
                  className={`${classes.pt0} ${classes.mt0}`}
                >
                  <Grid
                    item
                    className={`${customerStyleClasses.formCheck} ${classes.mtcustom} ${customerStyleClasses.floatLeft} ${classes.ps0}`}
                  >
                    <RACCheckBox
                      label="Do Not Visit"
                      size="small"
                      disabled={Agrtransferinit}
                      className={classes.custCheckbox}
                      name={`doNotVisit_${index}`}
                      onChange={(e: any) => {
                        handleChangeForAddEmployeInfo(e);
                      }}
                      checked={
                        data?.doNotVisit !== undefined &&
                          data?.doNotVisit == 'Y'
                          ? true
                          : false
                      }
                    />
                  </Grid>
                </Grid>
                {index != 0 &&
                  (data?.employerReferenceId == undefined ||
                    data?.employerReferenceId == null ||
                    data?.employerReferenceId == '') ? (
                  <Grid
                    item
                    md={2}
                    lg={1}
                    className={`${classes.pt0} ${classes.mt3}`}
                  >
                    <RACButton
                      variant="outlined"
                      color="primary"
                      className={customerStyleClasses.foc}
                      onClick={() => removeEmployementDiv(index)}
                    >
                      Cancel
                    </RACButton>
                  </Grid>
                ) : null}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      );
    });
  };
  const tagExpirationDate = (e: any, index: any) => {
    const InputTxt = e;
    const format: any = moment(InputTxt).format(dateFormat);
    const temp = InputTxt.replace(format);
    Vehicle[index].vehPlanExpirationDate = temp;
    setVehiclevalues([...Vehicle]);
  };
  //Employer information ends here
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const buildVechicleBlock = () => {
    return Vehicle.map((data: any, index: any) => {
      if (!isDropDownValueExist(buildVechicleYear(), data?.vehicleYear)) {
        data.vehicleYear = '';
      }
      if (!isDropDownValueExist(state, data?.vehRegState)) {
        data.vehRegState = '';
      }
      return (
        <Grid container key={index}>
          <Card
            className={`${customerStyleClasses.card} ${customerStyleClasses.w100} ${customerStyleClasses.mb2}`}
          >
            <CardContent>
              <Grid container spacing={2}>
                <Grid item md={3} lg={2}>
                  <RACTextbox
                    isCurrency={false}
                    disabled={Agrtransferinit}
                    type="text"
                    inputlabel="Make"
                    name={`make_${index}`}
                    OnChange={(e: any) => handleChangeForAddVehicleInfo(e)}
                    maxlength={15}
                    value={data?.vehicleMake}
                  />
                </Grid>
                <Grid item md={3} lg={2}>
                  <RACTextbox
                    isCurrency={false}
                    type="text"
                    disabled={Agrtransferinit}
                    inputlabel="Model"
                    name={`model_${index}`}
                    OnChange={(e: any) => handleChangeForAddVehicleInfo(e)}
                    maxlength={14}
                    value={data?.vehicleModel}
                  />
                </Grid>
                <Grid item md={3} lg={2}>
                  <RACSelect
                    inputLabel="Year"
                    isDisabled={Agrtransferinit}
                    options={buildVechicleYear()}
                    defaultValue={
                      data?.vehicleYear !== undefined &&
                        data?.vehicleYear !== null &&
                        data?.vehicleYear !== '' &&
                        isDropDownValueExist(
                          buildVechicleYear(),
                          data?.vehicleYear
                        )
                        ? data?.vehicleYear
                        : '0'
                    }
                    loading={
                      buildVechicleYear().length == 0 && !dropDownLoaded
                        ? true
                        : false
                    }
                    onChange={(e: any) => {
                      e.target.name = `year_${index}`;
                      handleChangeForAddVehicleInfo(e);
                    }}
                    {...(buildVechicleYear().length == 0 &&
                      dropDownLoaded && {
                      errorMessage: API_ERROR_MESSAGE,
                    })}
                  />
                </Grid>
                <Grid item md={3} lg={2}>
                  <RACTextbox
                    isCurrency={false}
                    type="text"
                    disabled={Agrtransferinit}
                    inputlabel="Color"
                    name={`color_${index}`}
                    OnChange={(e: any) => handleChangeForAddVehicleInfo(e)}
                    maxlength={20}
                    value={data?.vehicleColor}
                  />
                </Grid>
                <Grid item md={3} lg={2}>
                  <RACTextbox
                    isCurrency={false}
                    type="text"
                    disabled={Agrtransferinit}
                    inputlabel="VIN#"
                    name={`vin_${index}`}
                    OnChange={(e: any) => handleChangeForAddVehicleInfo(e)}
                    maxlength={20}
                    value={data?.vehicleVin}
                  />
                </Grid>
                <Grid item md={3} lg={2}>
                  <RACTextbox
                    isCurrency={false}
                    type="text"
                    disabled={Agrtransferinit}
                    inputlabel="Tag#"
                    name={`tag_${index}`}
                    OnChange={(e: any) => handleChangeForAddVehicleInfo(e)}
                    maxlength={15}
                    value={data?.vehicleLicensePlate}
                  />
                </Grid>
                <Grid item md={3} lg={2}>
                  <RACSelect
                    inputLabel="Tag State"
                    options={state}
                    isDisabled={Agrtransferinit}
                    defaultValue={
                      data?.vehRegState !== undefined &&
                        data?.vehRegState !== null &&
                        data?.vehRegState !== '' &&
                        isDropDownValueExist(state, data?.vehRegState)
                        ? data?.vehRegState
                        : '0'
                    }
                    loading={
                      state.length == 0 && !dropDownLoaded ? true : false
                    }
                    onChange={(e: any) => {
                      e.target.name = `tagState_${index}`;
                      handleChangeForAddVehicleInfo(e);
                    }}
                    {...(state.length == 0 &&
                      dropDownLoaded && {
                      errorMessage: API_ERROR_MESSAGE,
                    })}
                  />
                </Grid>
                <Grid item md={3} lg={2}>
                  <RACDatePicker
                    value={
                      data?.vehPlanExpirationDate !== undefined &&
                        data?.vehPlanExpirationDate !== null &&
                        data?.vehPlanExpirationDate !== ''
                        ? data?.vehPlanExpirationDate
                        : ''
                    }
                    label="Tag Expiration Date"
                    id={`tagExpirationDateDiv_${index}`}
                    name={`tagExpirationDate_${index}`}
                    disabled={Agrtransferinit}
                    onChange={(e: any) => tagExpirationDate(e, index)}
                  />
                </Grid>
                <Grid item md={3} lg={2}>
                  <RACTextbox
                    isCurrency={false}
                    type="text"
                    disabled={Agrtransferinit}
                    inputlabel="Where is Auto Financed?"
                    name={`whereIsAutoFinance_${index}`}
                    OnChange={(e: any) => handleChangeForAddVehicleInfo(e)}
                    maxlength={30}
                    value={data?.vehFinancedFrom}
                  />
                </Grid>
                <Grid item md={2} lg={2} className={classes.pt0}>
                  {/* <RACTextbox
                    isCurrency={true}
                    type={'number'}
                    digitFormat={'currency'}
                    inputlabel="Monthly Auto Payment"
                    id={`monthlyAutoPaymentDiv_${index}`}
                    name={`monthlyAutoPayment_${index}`}
                    value={
                      data?.vehMonthlyPayment !== undefined &&
                      data?.vehMonthlyPayment !== null &&
                      data?.vehMonthlyPayment !== ''
                        ? data?.vehMonthlyPayment
                        : '0.00'
                    }
                    OnChange={(e: any) => handleChangeForAddVehicleInfo(e)}
                    maxlength={10}
                    className={classes.custDigit}
                    dollarTextClassName={classes.currencyDollarField}
                  /> */}
                  <RACTextbox
                    isCurrency={true}
                    type={'number'}
                    digitFormat={'currency'}
                    disabled={Agrtransferinit}
                    inputlabel="Monthly Auto Payment"
                    id={`monthlyAutoPaymentDiv_${index}`}
                    name={`monthlyAutoPayment_${index}`}
                    OnChange={(e: any) => handleChangeForAddVehicleInfo(e)}
                    maxlength={10}
                    value={
                      data?.vehMonthlyPayment !== undefined &&
                        data?.vehMonthlyPayment !== null &&
                        data?.vehMonthlyPayment !== ''
                        ? data?.vehMonthlyPayment
                        : '0.00'
                    }
                    className={classes.custDigit}
                    dollarTextClassName={classes.currencyDollarField}
                  />
                </Grid>
                {index != 0 &&
                  (data?.customerVehicleId == undefined ||
                    data?.customerVehicleId == null ||
                    data?.customerVehicleId == '') ? (
                  <Grid
                    item
                    md={1}
                    lg={4}
                    className={`${customerStyleClasses.mt4} ${classes.textCenter}`}
                  >
                    <RACButton
                      variant="outlined"
                      color="primary"
                      className={customerStyleClasses.foc}
                      onClick={() => removeDivVehicle(index)}
                    >
                      Cancel
                    </RACButton>
                  </Grid>
                ) : null}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      );
    });
  };
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const buildPhone = () => {
    return phoneNumberProps.map((data: any, index: any) => {
      if (!isDropDownValueExist(bestTimeToCall, data?.callTimeType)) {
        data.callTimeType = '';
      }
      if (!isDropDownValueExist(phoneType, data?.phoneType)) {
        data.phoneType = '';
      }
      return (
        <Grid container key={index} className={classes.mb0}>
          <Card
            className={`${customerStyleClasses.card} ${customerStyleClasses.floatRight
              } ${index !== 0
                ? phoneNumberProps.length > 1
                  ? `${classes.mt3}`
                  : `${classes.mt0}`
                : null
              }`}
            key={index}
          >
            <CardContent id="contact_info_div">
              <Grid
                container
                spacing={2}
                id={`anotherContact_${index}`}
                className={classes.mb0}
              >
                <Grid item lg={4} xl={3}>
                  <RACTextbox
                    isCurrency={false}
                    required={true}
                    type={'text'}
                    disabled={Agrtransferinit}
                    inputlabel="Phone Number"
                    name={`phonenumber_${index}`}
                    OnChange={(e: any) => handleChangeForAddToContact(e)}
                    maxlength={13}
                    value={
                      data?.phoneNumber !== undefined
                        ? formatPhone(data?.phoneNumber)
                        : ''
                    }
                    errorMessage={
                      data?.phoneNumber !== undefined &&
                        data?.phoneNumber !== null &&
                        data?.phoneNumber == '' &&
                        isrequired === true
                        ? FIELD_ERROR
                        : isrequired === true &&
                          data?.phoneNumber !== undefined &&
                          data?.phoneNumber !== null &&
                          data?.phoneNumber.length < 10
                          ? PHONE_ERROR_MESSAGE
                          : ''
                    }
                  />
                </Grid>
                <Grid item lg={4} xl={3}>
                  <RACTextbox
                    isCurrency={false}
                    type={'text'}
                    disabled={Agrtransferinit}
                    inputlabel="Ext"
                    name={`ExtforContact_${index}`}
                    OnChange={(e: any) => handleChangeForAddToContact(e)}
                    maxlength={5}
                    value={data?.extension}
                  />
                </Grid>
                <Grid item lg={4} xl={3}>
                  <RACSelect
                    inputLabel="Best Time to Call"
                    options={bestTimeToCall}
                    defaultValue={
                      data?.callTimeType !== undefined &&
                        data?.callTimeType !== null &&
                        data?.callTimeType !== '' &&
                        isDropDownValueExist(bestTimeToCall, data?.callTimeType)
                        ? data?.callTimeType
                        : '0'
                    }
                    required={true}
                    isDisabled={Agrtransferinit}
                    loading={
                      bestTimeToCall.length == 0 && !dropDownLoaded
                        ? true
                        : false
                    }
                    onChange={(e: any) => {
                      e.target.name = `BestTimeCall_${index}`;
                      handleChangeForAddToContact(e);
                    }}
                    errorMessage={
                      data?.callTimeType !== undefined &&
                        (data?.callTimeType == '' || data?.callTimeType == '0') &&
                        isrequired === true
                        ? FIELD_ERROR
                        : ''
                    }
                    {...(bestTimeToCall.length == 0 &&
                      dropDownLoaded && {
                      errorMessage: API_ERROR_MESSAGE,
                    })}
                  />
                </Grid>
                <Grid item lg={4} xl={3}>
                  <RACSelect
                    inputLabel="Phone Type"
                    options={phoneType}
                    isDisabled={Agrtransferinit}
                    defaultValue={
                      data?.phoneType !== undefined &&
                        data?.phoneType !== null &&
                        data?.phoneType !== '' &&
                        isDropDownValueExist(phoneType, data?.phoneType)
                        ? data?.phoneType
                        : '0'
                    }
                    required={true}
                    loading={
                      phoneType.length == 0 && !dropDownLoaded ? true : false
                    }
                    onChange={(e: any) => {
                      e.target.name = `PhoneType_${index}`;
                      handleChangeForAddToContact(e);
                    }}
                    errorMessage={
                      data?.phoneType !== undefined &&
                        (data?.phoneType == '' || data?.phoneType == '0') &&
                        isrequired === true
                        ? FIELD_ERROR
                        : ''
                    }
                    {...(phoneType.length == 0 &&
                      dropDownLoaded && {
                      errorMessage: API_ERROR_MESSAGE,
                    })}
                  />
                </Grid>
                <Grid item lg={4} xl={3}>
                  <RACTextbox
                    isCurrency={false}
                    disabled={Agrtransferinit}
                    type={'text'}
                    inputlabel="Phone Instructions"
                    name={`PhoneInstructions_${index}`}
                    OnChange={(e: any) => handleChangeForAddToContact(e)}
                    maxlength={50}
                    value={data?.note}
                  />
                </Grid>
                <Grid item lg={4} xl={3}>
                  <Grid container >
                    {data.phoneId && (<Grid item lg={6} xl={6}>
                      <Typography
                        component="label"
                        htmlFor="#"
                        className={classes.formLabelCustom}
                      >
                        National Do Not Contact
                        <RACInfoIcon infoTitle={ndncTip} />
                      </Typography>
                      <Grid item className={classes.formLabelInline}>
                        <RACRadio
                          disabled={true}
                          value={'Y'}
                          checked={data?.isNDNCOpted == 'Y' ? true : false}
                          label="Yes"
                          name="ndnc"
                        />
                      </Grid>
                      <Grid item className={classes.formLabelInline}>
                        <RACRadio
                          value={'N'}
                          disabled={true}
                          checked={
                            data?.isNDNCOpted == 'N' ? true : false}
                          label="No"
                          name="ndnc"
                        />
                      </Grid>
                    </Grid>)}
                    <Grid item lg={6} xl={6}
                      className={`${customerStyleClasses.formCheck2} ${classes.mtcustom} ${customerStyleClasses.floatLeft} ${classes.ps0}`}
                    >
                      <RACCheckBox
                        label="Text Message Reminder" //tcpa
                        size="small"
                        disabled={Agrtransferinit || (!data?.textMessageReminderOri)}
                        className={classes.custCheckbox}
                        name={`isTrnsMsgAllowed_${index}`}
                        onChange={(e: any) => {
                          handleChangeForAddToContact(e);
                        }}
                        value={
                          data?.isTrnsMsgAllowed ==
                            'Y' ? 'N' : 'Y'
                        }
                        checked={
                          (data?.isTrnsMsgAllowed ==
                            'Y' && (data?.isNDNCOpted === 'N')) ? true : false
                        }
                      />
                      <RACCheckBox
                        label="Text Marketing Offers"
                        size="small"
                        disabled={Agrtransferinit || (!data?.textMarketingOffersOri) || (data?.isNDNCOpted === 'Y')}
                        className={classes.custCheckbox}
                        name={`isMktgMsgAllowed_${index}`}
                        onChange={(e: any) => {
                          handleChangeForAddToContact(e);
                        }}
                        value={
                          data?.isMktgMsgAllowed ==
                            'Y' ? 'N' : 'Y'
                        }
                        checked={
                          (data?.isMktgMsgAllowed ==
                            'Y' && (data?.isNDNCOpted === 'N')) ? true : false
                        }
                      />
                    </Grid>
                  </Grid>

                </Grid>

                <Grid item lg={4} xl={3} className={customerStyleClasses.mt4}>
                  <Grid style={{ display: "flex", flexWrap: "wrap" }}>
                    <Grid style={{ display: "flex", flexWrap: "wrap", marginRight: '18%' }}>
                      <Typography
                        component="label"
                        className={`${customerStyleClasses.formLabel} ${customerStyleClasses.floatLeft} ${customerStyleClasses.me2}`}
                        htmlFor="IsPrimary"
                      >
                        Is Primary?
                      </Typography>
                      <RACToggle
                        style={{ marginLeft: 0 }}
                        id={`Is_Primary_${index}`}
                        checked={
                          phoneNumberProps.length > 0 && data?.primary == 'N'
                            ? false
                            : true
                        }
                        toggleColor="#0d6efd"
                        name={`IsPrimary_${index}`}
                        labelClassName={classes.switchLabel}
                        OnChange={(e: any) => {
                          handleChangeForAddToContact(e);
                        }}
                      />
                    </Grid>

                    <Grid style={{ display: "flex", flexWrap: "wrap" }}>
                      <Typography
                        component="label"
                        //className={`${customerStyleClasses.formLabel} ${customerStyleClasses.floatLeft} ${customerStyleClasses.me2}`}
                        htmlFor="IsActive"
                        style={{ float: 'right', marginRight: '10px' }}
                      >
                        Is Active?
                      </Typography>
                      <RACToggle
                        //style={{ marginLeft: 0 }}
                        style={{ float: 'right' }}
                        id={`Is_Active_${index}`}
                        checked={
                          phoneNumberProps.length > 0 && data?.active == 'Y'
                            ? true
                            : false
                          //activeChkBox
                        }
                        toggleColor="#0d6efd"
                        name={`IsActive_${index}`}
                        //labelClassName={classes.switchLabel}
                        OnChange={(e: any) => {
                          handleChangeForAddToContact(e);
                        }}
                      />
                    </Grid>
                  </Grid>
                  {data?.primary == 'N' &&
                    (data?.phoneId == undefined ||
                      data?.phoneId == null ||
                      data?.phoneId == '') ? (
                    <Grid item className={customerStyleClasses.floatRight}>
                      <RACButton
                        variant="outlined"
                        color="primary"
                        className={customerStyleClasses.foc}
                        onClick={() => removeContactInfoDiv(index)}
                      >
                        Cancel
                      </RACButton>
                    </Grid>
                  ) : null}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      );
    });
  };
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const validateAddressFnc = (type: 'P' | 'M') => {
    // eslint-disable-next-line
    // 
    let validatePrimary = true;
    let validateMailing = true;
    if (!editInputBox) {
      if (validateValue(updatePrimaryAddress.address)) {
        validatePrimary = false;
        // eslint-disable-next-line no-console
        console.log('addressLine1', validatePrimary);
      } else if (
        updatePrimaryAddress.zip == undefined ||
        updatePrimaryAddress.zip == null ||
        updatePrimaryAddress.zip == ''
      ) {
        // eslint-disable-next-line sonarjs/no-duplicated-branches
        validatePrimary = false;
        // eslint-disable-next-line no-console
        console.log('zip', validatePrimary);
      } // eslint-disable-next-line sonarjs/no-duplicated-branches
      else if (
        updatePrimaryAddress.zip !== undefined &&
        updatePrimaryAddress.zip !== null &&
        updatePrimaryAddress.zip !== '' &&
        updatePrimaryAddress.zip.length != 5 &&
        updatePrimaryAddress.zip.length != 10
        // eslint-disable-next-line sonarjs/no-duplicated-branches
      ) {
        // eslint-disable-next-line sonarjs/no-duplicated-branches
        validatePrimary = false;
        // eslint-disable-next-line no-console
        console.log('zip', validatePrimary);
      } else if (validateValue(updatePrimaryAddress.city)) {
        validatePrimary = false;
      } else if (
        updatePrimaryAddress.state == undefined ||
        updatePrimaryAddress.state == null ||
        updatePrimaryAddress.state == '0'
      ) {
        validatePrimary = false;
        // eslint-disable-next-line no-console
        console.log('city', validatePrimary);
      }
    }
    if (!editInputBoxM) {
      if (validateValue(updateMailingAddress.address)) {
        validateMailing = false;
        // eslint-disable-next-line no-console
        console.log('addressLine1M', validateMailing);
      } else if (
        updateMailingAddress.zip == undefined ||
        updateMailingAddress.zip == null ||
        updateMailingAddress.zip.trim() == '' ||
        updateMailingAddress.zip.length < 5
      ) {
        // eslint-disable-next-line sonarjs/no-duplicated-branches
        validateMailing = false;
        // eslint-disable-next-line no-console
        console.log('zipM', validateMailing);
      } else if (
        updateMailingAddress.state == undefined ||
        updateMailingAddress.state == null ||
        updateMailingAddress.state == '0'
      ) {
        validateMailing = false;
        // eslint-disable-next-line no-console
        console.log('stateM', validateMailing);
      } else if (validateValue(updateMailingAddress.city)) {
        validateMailing = false;
      }
    }
    setValidateAddress(
      !validatePrimary && !validateMailing
        ? 'B'
        : !validatePrimary
          ? 'P'
          : !validateMailing
            ? 'M'
            : ''
    );
    return type == 'M' ? validateMailing : validatePrimary;
  };
  function suggestAddressFn(){
    return (
        <>
          <Grid item md={12} className={customerStyleClasses.spacerPX2}>
          <Typography className={`${customerStyleClasses.RACPOPMsg} ${customerStyleClasses.bold}`}>
            Entered Address
          </Typography>
          <RACRadio
                id="inlineRadio1"
                name="inlineRadioOptions"
                checked={defaultSuggestedAddress == 'ENT' ? true : false}
                onClick={() => RadioBtnclick('ENT')}
                value={'ENT'}
              />
          <Typography
              className={`${customerStyleClasses.RACPOPMsg} ${customerStyleClasses.semiBold} ${customerStyleClasses.dispInlineFlex}`}
            >
              {runAddressDoc === 'P'
                    ? updatePrimaryAddress.address +
                      ', ' +
                      buildAd2(updatePrimaryAddress.address1) +
                      updatePrimaryAddress.city +
                      ', ' +
                      updatePrimaryAddress.state +
                      ', ' +
                      updatePrimaryAddress.zip
                    : updateMailingAddress.address +
                      ', ' +
                      buildAd2(updateMailingAddress.address1) +
                      updateMailingAddress.city +
                      ', ' +
                      updateMailingAddress?.state +
                      ', ' +
                      updateMailingAddress.zip}
            </Typography>
          <Grid
            item
            md={12}
            className={`${customerStyleClasses.spacerMB2} ${customerStyleClasses.spacerMT2}`}
          >
            <Typography className={`${customerStyleClasses.RACPOPMsg} ${customerStyleClasses.bold}`}>
              Suggested Addresses
            </Typography>
            <div className={customerStyleClasses.formCheck}>
              <RACRadio
                id="inlineRadio1"
                name="inlineRadioOptions"
                checked={defaultSuggestedAddress == 'GA' ? true : false}
                onClick={() => RadioBtnclick('GA')}
                value={'GA'}
              />
              <Typography
                className={`${customerStyleClasses.RACPOPMsg} ${customerStyleClasses.semiBold} ${customerStyleClasses.dispInlineFlex}`}
              >
              {validatedAddresses.suggestedAddress} 
              </Typography>
            </div>
          </Grid>
        </Grid>
        <Grid
          className={`${customerStyleClasses.spacerMT4} ${classes.w100} ${customerStyleClasses.floatLeft}`}
        >
          <Grid className={customerStyleClasses.floatLeft}>
            <RACButton
              variant="outlined"
              color="primary"
              className={customerStyleClasses.foc}
              onClick={() => setCanSuggestAddress(false)}
            >
              Cancel
            </RACButton>
          </Grid>
          <Grid className={`${classes.floatRight}`}>
            <RACButton
                  variant="contained"
                  color="primary"
                  onClick={() => continueclickAddressDoc(defaultSuggestedAddress, runAddressDoc)}
                >
                  Continue
                </RACButton>
          </Grid>
        </Grid>
        </>
    )
}
function suggestErrorInAddress(){
return (
  <>
      <Grid item md={12}>
      <Grid
            style={{
              justifyContent: "center",
              display: "flex",
              marginBottom:'2%'

            }}
          >
            <WarningIcon width={"10%"} height={"10%"} />
          </Grid>
        <Grid item md={12} className={classes.textCenter}>
          <Typography variant={'body2'} className={customerStyleClasses.RACPOPMsg}>
            {validatedAddresses?.message}
          </Typography>
        </Grid>
        <Grid
          container
          className={`${customerStyleClasses.justifyContentCenter} ${customerStyleClasses.spacerMT4}`}
        >
          <RACButton
            variant="contained"
            color="primary"
            isRounded={false}
            onClick={() => setCantSuggestAddress(false)}
          >
            Ok
          </RACButton>
        </Grid>
      </Grid>
  </>
)
}
  const CancelRedirect = () => {
    const previousPageUrl = document.referrer;
    if (
      previousPageUrl !== undefined &&
      previousPageUrl.includes('am/customer')
    ) {
      props.history.push(`${AppRoute.accountManagementCustomer}/${customerId}`);
    } else {
      props.history.push(`/dashboard`);
    }
  };
  interface AddressResponse {
    message?: string | undefined;
    status: boolean;
    matchCode?: string | undefined;
    suggestedAddress?: string | undefined ;
    postalAddress?: PostalAddress | undefined;
    
  }
  
  interface PostalAddress {
    addressLines: string[];
    administrativeArea: string;
    postalCode: string;
    locality: string;
  }
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const getAddress = async (type: 'P' | 'M') => {
    if (validateAddressFnc(type)) {
      // setIsLoading(true);
      const addressPayload : any = {
        MessageID: 'CMS006-93589345',
        MessageType: 'CMS006',
        MessageDTS: '2014-05-23-14:30:15:254633',
        MessageDTSTZOffset: '-06:00',
        RegionID: '1',
        ClientID: '8',
        ClientLocationNumber: '00006',
        ClientSource: '1',
        ClientOriginator: '1',
        LocationNumber: loginStore,
        EncryptionMethod: '0',
        Addresses: [
          {
            AddressID: '11114',
            StreetAddress: '',
            City: '',
            StateProvince: '',
            PostalCode: '',
            Country: '',
          },
        ],
        StoreConfig: {
          storeNumbers: [loginStore],
          paramKeyNames: ['AddressDoctorEnabled'],
        },
      };
      addressPayload.Addresses[0].City =
        type === 'P' ? updatePrimaryAddress?.city : updateMailingAddress?.city;
      addressPayload.Addresses[0].StreetAddress =
        type === 'P'
          ? updatePrimaryAddress?.address
          : updateMailingAddress?.address;
      addressPayload.Addresses[0].StateProvince =
        type === 'P'
          ? updatePrimaryAddress?.state
          : updateMailingAddress?.state;
      addressPayload.Addresses[0].PostalCode =    
        type === 'P'
          ? updatePrimaryAddress?.zip.replaceAll('-', '')
          : updateMailingAddress?.zip.replaceAll('-', '');
      addressPayload.Addresses[0].Country = 'USA';
      // eslint-disable-next-line no-console
      console.log('AddressDoctor', addressPayload);
      try {
        if(featureFlagDetails?.EnableGoogleAddressValidation == '1'){
          setIsLoading(true);
          const googleAddressValidationPayload : any = JSON.parse(JSON.stringify(addressPayload))
          googleAddressValidationPayload.enableGoogleAddressValidation = true; 
          const arr: any = [ type === 'P'
          ? updatePrimaryAddress?.address
          : updateMailingAddress?.address,  type === 'P' ? updatePrimaryAddress?.city : updateMailingAddress?.city,type === 'P'
          ? updatePrimaryAddress?.state
          : updateMailingAddress?.state,type === 'P'
          ? updatePrimaryAddress?.zip
          : updateMailingAddress?.zip ]
          if((type === 'P' && updatePrimaryAddress?.address1) || (type !== 'P' && updateMailingAddress?.address1)){
            arr.unshift(type === 'P'
          ? updatePrimaryAddress?.address1
          : updateMailingAddress?.address1)
          addressPayload.Addresses[0].StreetAddress+= ` ${type === 'P'
          ? updatePrimaryAddress?.address1
          : updateMailingAddress?.address1}`
          }
          const validateAddressPayload ={
            postalCode: type === 'P'
            ? updatePrimaryAddress?.zip
            : updateMailingAddress?.zip ,
            addressLines: arr
          }
          googleAddressValidationPayload.validateAddress = validateAddressPayload
          const response: any = await Promise.all([GetSuggestedAddress(addressPayload),GetSuggestedAddress(googleAddressValidationPayload)]);
          setIsLoading(false);
          if(response[0] && response[1].validateAddress){
            const result : AddressResponse = validateAddressFn(response[1].validateAddress, response[0].validateAddress,
              (response[0] && response[0].storeConfig && response[0].storeConfig.storeProfileResponse.configDetails[0]
             .configDetails[0].paramValue == '1')? true :false, googleAddressValidationPayload)
            if(result.status){
              setdefaultSuggestedAddress('GA');
              if(result.matchCode){
                setmatchCode(result.matchCode);
                const errorMessage: string =
                result.matchCode == '1'
                ? `Address could not be corrected. Unlikely to match. Do you want to override?`
                : result.matchCode == '2'
                  ? `Zip code found: ${addressPayload.Addresses[0].PostalCode}. Do you Want to overrride?`
                  : '';
            setAddressErrorMsg(errorMessage);
            setSuggestionAddresPopup(true);
            setSuggestedAddress(undefined);
            const postalAddress= {
              addressLines:[type === 'P'
              ? updatePrimaryAddress?.address
              : updateMailingAddress?.address],
              locality:result?.postalAddress?.locality,
              postalCode :result?.postalAddress?.postalCode,
              administrativeArea:result?.postalAddress?.administrativeArea
            }
            if(updatePrimaryAddress?.address1 || updateMailingAddress?.address1)
              postalAddress.addressLines.unshift(type === 'P'
            ? updatePrimaryAddress?.address1
            : updateMailingAddress?.address1)
            setValidateIsOpen(true);
            setValidatedAddress({postalAddress:postalAddress}) 
            setdefaultSuggestedAddress('GA');
              }else{
              setCanSuggestAddress(true)
             setValidatedAddress(result)  
            }
           }
           else{
             setCantSuggestAddress(true)
             setValidatedAddress(result)
           }
          }else{
            throw new Error('Something went wrong')
          }
        }
        else{
        //setIsLoading(true);
        setValidateIsOpen(true);

        setAddressDoc(true);
        const response: any = await GetSuggestedAddress(addressPayload);
        setIsLoading(false);
        // eslint-disable-next-line no-console
        console.log('AddressDoctor', response);
        setAddressDoc(false);
        if (
          response !== undefined &&
          response !== null &&
          response.storeConfig !== undefined &&
          (response.storeConfig.storeProfileResponse.configDetails[0]
            .configDetails[0].paramValue == 'Y' ||
            response.storeConfig.storeProfileResponse.configDetails[0]
              .configDetails[0].paramValue == '1' ||
            response.storeConfig.storeProfileResponse.configDetails[0]
              .configDetails[0].paramValue == 1)
        ) {
          // eslint-disable-next-line no-console
          console.log('IsaddressValid');
          // addressDocCancel();
          // if (
          //   response.validateAddress !== undefined &&
          //   response.validateAddress.Addresses !== undefined &&
          //   response.validateAddress.Addresses !== null
          // ) {
          //   if (response.validateAddress.Addresses.MatchCode == '1') {
          //   }
          // }
          if (
            response.validateAddress !== undefined &&
            response.validateAddress.Addresses !== undefined &&
            (response.validateAddress.Addresses.MatchCode == '1' ||
              response.validateAddress.Addresses.MatchCode == '2')
          ) {
            setmatchCode(response.validateAddress.Addresses.MatchCode);
            const errorMessage: string =
              response.validateAddress.Addresses.MatchCode == '1'
                ? `Address could not be corrected. Unlikely to match. Do you want to override?`
                : response.validateAddress.Addresses.MatchCode == '2'
                  ? `Zip code found: ${addressPayload.Addresses[0].PostalCode}. Do you Want to overrride?`
                  : '';
            // const el: any = document.getElementById('addressErrorMsgFOC');
            // el.focus();
            setAddressErrorMsg(errorMessage);
            setSuggestedAddress(undefined);
          } else {
            setSuggestedAddress(response.validateAddress);
          }
          //setValidateIsOpen(true);
          // eslint-disable-next-line no-console
          console.log('IsaddressValid1');
        } else {
          //setIsLoading(false);
          setSuggestedAddress(undefined);
          setAddressErrorMsg(
            'Automatic Address validation is Unavailable for the store # Please validate the address Manually.'
          );
          // eslint-disable-next-line no-console
          console.log('ENT', runAddressDoc);
          buildENTAddres('ENT', runAddressDoc);
          addressDocCancel(type);
          setrunAddressDoc(type);
        }
      }
      } catch (e: any) {
        //setIsLoading(false);
        // eslint-disable-next-line no-console
        setAddressDoc(false);
        setAddressErrorMsg('Address validation currently Unavailable');
        setSuggestedAddress(undefined);
        console.log('ENT', e);
        buildENTAddres('ENT', runAddressDoc);
        addressDocCancel(type);
        setrunAddressDoc(type);
        type == 'P' ? seteditInputBox(true) : seteditInputBoxM(true);
      }
    }
  };
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const addressDoctorPopup = () => {
    return (
      <Grid item md={12} className={customerStyleClasses.spacerPX2}>
        {AddressDoc === true ? (
          <div className={customerStyleClasses.customerNorecords}>
            <CircularProgress />
          </div>
        ) : SuggestedAddress == undefined ? (
          <React.Fragment>
            <Typography className={customerStyleClasses.RACPOPMsg}>
              {addressErrorMsg}
            </Typography>
            {matchCode === '1' || matchCode === '2' ? (
              <Grid
                item
                md={12}
                className={customerStyleClasses.customerTextcenter}
              >
                <RACButton
                  className={`${customerStyleClasses.spacerMR2}`}
                  variant="outlined"
                  color="primary"
                  isRounded={false}
                  onClick={() => {
                    continueclickAddressDoc('ENT', runAddressDoc);
                  }}
                >
                  No
                </RACButton>
                <RACButton
                  variant="contained"
                  color="primary"
                  isRounded={false}
                  onClick={() => {
                    continueclickAddressDoc('ENT', runAddressDoc, 'MC1');
                  }}
                >
                  Yes
                </RACButton>
              </Grid>
            ) : (
              <Grid
                item
                md={12}
                className={`${customerStyleClasses.customerTextcenter} ${customerStyleClasses.spacerMT2}`}
              >
                <RACButton
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    continueclickAddressDoc('ENT', runAddressDoc);
                  }}
                >
                  Ok
                </RACButton>
              </Grid>
            )}
          </React.Fragment>
        ) : (
          <Grid item md={12} className={customerStyleClasses.spacerPX2}>
            <Typography
              className={`${customerStyleClasses.RACPOPMsg} ${customerStyleClasses.semiBold}`}
            >
              Entered address is not located
            </Typography>
            <Grid
              item
              md={12}
              className={`${customerStyleClasses.spacerMB2} ${customerStyleClasses.spacerMT2}`}
            >
              <Typography
                className={`${customerStyleClasses.RACPOPMsg} ${customerStyleClasses.semiBold}`}
              >
                Suggested Addresses
              </Typography>
              <div className={customerStyleClasses.formCheck}>
                <RACRadio
                  id="inlineRadio1"
                  name="inlineRadioOptions"
                  checked={defaultSuggestedAddress == 'SUG' ? true : false}
                  onClick={() => RadioBtnclick('SUG')}
                  value={'SUG'}
                />
                <Typography
                  variant="caption"
                  className={`${customerStyleClasses.RACPOPMsg} ${customerStyleClasses.semiBold}`}
                >
                  {SuggestedAddress !== undefined &&
                    SuggestedAddress !== null &&
                    SuggestedAddress.Addresses !== null &&
                    SuggestedAddress.Addresses.StreetAddress !== undefined
                    ? `${SuggestedAddress.Addresses.StreetAddress},${SuggestedAddress.Addresses?.Building} ${SuggestedAddress.Addresses?.Unit} ${SuggestedAddress.Addresses?.POBox}`
                    : ''}

                  {SuggestedAddress !== undefined &&
                    SuggestedAddress !== null &&
                    SuggestedAddress.Addresses !== null &&
                    SuggestedAddress.Addresses.City !== undefined
                    ? `${SuggestedAddress.Addresses.City},`
                    : ''}
                  {SuggestedAddress !== undefined &&
                    SuggestedAddress !== null &&
                    SuggestedAddress.Addresses !== null &&
                    SuggestedAddress.Addresses.StateProvince !== undefined
                    ? `${SuggestedAddress.Addresses.StateProvince},`
                    : ''}
                  {SuggestedAddress !== undefined &&
                    SuggestedAddress !== null &&
                    SuggestedAddress.Addresses !== null &&
                    SuggestedAddress.Addresses.PostalCode !== undefined
                    ? `${SuggestedAddress.Addresses.PostalCode}`
                    : ''}
                </Typography>
              </div>
            </Grid>
            <Grid item md={12} className={customerStyleClasses.spacerMB3}>
              <Typography
                className={`${customerStyleClasses.RACPOPMsg} ${customerStyleClasses.semiBold}`}
              >
                Entered Address
              </Typography>
              <div className={customerStyleClasses.formCheck}>
                <RACRadio
                  id="inlineRadio2"
                  name="inlineRadioOptions"
                  checked={defaultSuggestedAddress == 'ENT' ? true : false}
                  onClick={() => RadioBtnclick('ENT')}
                  value={'ENT'}
                />
                <Typography
                  variant="caption"
                  className={`${customerStyleClasses.RACPOPMsg} ${customerStyleClasses.semiBold}`}
                >
                  {runAddressDoc === 'P'
                    ? updatePrimaryAddress.address +
                    ',' +
                    buildAd2(updatePrimaryAddress.address1) +
                    updatePrimaryAddress.city +
                    ',' +
                    updatePrimaryAddress.state +
                    ',' +
                    updatePrimaryAddress.zip
                    : updateMailingAddress.address +
                    ',' +
                    buildAd2(updateMailingAddress.address1) +
                    updateMailingAddress.city +
                    ',' +
                    updateMailingAddress?.state +
                    ',' +
                    updateMailingAddress.zip}
                </Typography>
              </div>
            </Grid>
            <Grid
              className={`${customerStyleClasses.spacerMT4} ${customerStyleClasses.w100} ${customerStyleClasses.floatLeft}`}
            >
              <Grid className={customerStyleClasses.floatLeft}>
                <RACButton
                  variant="outlined"
                  color="primary"
                  className={customerStyleClasses.foc}
                  onClick={() => continueclickAddressDoc('ENT', runAddressDoc)}
                >
                  Cancel
                </RACButton>
              </Grid>
              <Grid className={`${customerStyleClasses.floatRight}`}>
                <RACButton
                  variant="contained"
                  color="primary"
                  className={customerStyleClasses.spacerME2}
                  onClick={() => continueclickAddressDoc('ENT', runAddressDoc)}
                  disabled={BtnIsdisabled1}
                >
                  Override
                </RACButton>
                <RACButton
                  variant="contained"
                  color="primary"
                  disabled={BtnIsdisabled2}
                  onClick={() => continueclickAddressDoc('SUG', runAddressDoc)}
                >
                  Continue
                </RACButton>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  };
  const buildAd2 = (ad: any) => {
    return ad !== null && ad !== undefined && ad !== '' && ad.length > 0
      ? ad + ', '
      : '';
  };
  function ValidationMessageMsg() {
    return (
      <RACModalCard
        borderRadius="20px"
        isOpen={ValidateIsOpen}
        maxWidth="xs"
        closeIcon={true}
        onClose={() => {
          setValidateIsOpen(false);
          seteditInputBox(true);
        }}
        // eslint-disable-next-line react/no-children-prop
        children={addressDoctorPopup()}
        title="Validation Message"
      />
    );
  }

  const continueclickAddressDoc = (
    type: any,
    addressType: 'P' | 'M' | '',
    mc?: any
  ) => {
    debugger;
    setValidateIsOpen(false);
    // eslint-disable-next-line no-console
    console.log('addressType', addressType);
    // eslint-disable-next-line no-console
    console.log('type', type);
    if (type == 'SUG' && addressType == 'P') {
      const customerInformationObj = customerInformation;
      customerInformationObj.addressLine1 =
        SuggestedAddress.Addresses.StreetAddress;
      customerInformationObj.addressLine2 =
        updatePrimaryAddress.address1 +
        ' ' +
        SuggestedAddress.Addresses?.Building +
        ' ' +
        SuggestedAddress.Addresses?.Unit +
        ' ' +
        SuggestedAddress.Addresses?.POBox;
      customerInformationObj.city = SuggestedAddress.Addresses.City;
      customerInformationObj.state = SuggestedAddress.Addresses.StateProvince;
      customerInformationObj.zip = SuggestedAddress.Addresses.PostalCode;
      const updatePrimaryAddressObj = updatePrimaryAddress;
      updatePrimaryAddressObj.address =
        updatePrimaryAddress.address1 +
        ' ' +
        SuggestedAddress.Addresses.StreetAddress;
      updatePrimaryAddressObj.address1 =
        SuggestedAddress.Addresses?.Building +
        ' ' +
        SuggestedAddress.Addresses?.Unit +
        ' ' +
        SuggestedAddress.Addresses?.POBox;
      updatePrimaryAddressObj.city = SuggestedAddress.Addresses.City;
      updatePrimaryAddressObj.state = SuggestedAddress.Addresses.StateProvince;
      updatePrimaryAddressObj.zip = SuggestedAddress.Addresses.PostalCode;
      setcustomerInformation(customerInformationObj);
      setUpdatePrimaryAddress(updatePrimaryAddressObj);
      setIsPrimaryCustoInfoUpdate(true);
    } else if (type == 'SUG' && addressType == 'M') {
      const customerInformationObj = customerInformation;
      customerInformationObj.addressLine1M =
        SuggestedAddress.Addresses.StreetAddress;
      customerInformationObj.addressLine2M =
        updateMailingAddress.address1 +
        ' ' +
        SuggestedAddress.Addresses?.Building +
        ' ' +
        SuggestedAddress.Addresses?.Unit +
        ' ' +
        SuggestedAddress.Addresses?.POBox;
      customerInformationObj.cityM = SuggestedAddress.Addresses.City;
      customerInformationObj.stateM = SuggestedAddress.Addresses.StateProvince;
      customerInformationObj.zipM = SuggestedAddress.Addresses.PostalCode;
      const updateMailingAddressObj = updateMailingAddress;
      updateMailingAddressObj.address =
        SuggestedAddress.Addresses.StreetAddress;
      updateMailingAddressObj.address1 =
        updateMailingAddress.address1 +
        ' ' +
        SuggestedAddress.Addresses?.Building +
        ' ' +
        SuggestedAddress.Addresses?.Unit +
        ' ' +
        SuggestedAddress.Addresses?.POBox;
      updateMailingAddressObj.city = SuggestedAddress.Addresses.City;
      updateMailingAddressObj.state = SuggestedAddress.Addresses.StateProvince;
      updateMailingAddressObj.zip = SuggestedAddress.Addresses.PostalCode;
      setcustomerInformation(customerInformationObj);
      setupdateMailingAddress(updateMailingAddressObj);
      setIsPrimaryCustoInfoUpdate(true);
    } else if(type == 'GA' && addressType == 'P'){
      const postalAddress= validatedAddresses?.postalAddress;
      const customerInformationObj = customerInformation;
      customerInformationObj.city = postalAddress?.locality;
      customerInformationObj.state = postalAddress?.administrativeArea;
      customerInformationObj.zip = postalAddress?.postalCode;
      const updatePrimaryAddressObj = updatePrimaryAddress;
      if(postalAddress?.addressLines.length>1){
        customerInformationObj.addressLine1 =postalAddress?.addressLines[1]
        customerInformationObj.addressLine2 =postalAddress?.addressLines[0]
      updatePrimaryAddressObj.address =  postalAddress?.addressLines[1];
      updatePrimaryAddressObj.address1 = postalAddress?.addressLines[0];
     }
      else {
        customerInformationObj.addressLine1 =
      postalAddress?.addressLines.join(', ');
      customerInformationObj.address1 = '';
        updatePrimaryAddressObj.address =  postalAddress?.addressLines.join(', ');
        customerInformationObj.addressLine2 ='';
        updatePrimaryAddressObj.address1 =''
      }
      updatePrimaryAddressObj.city = postalAddress?.locality;
      updatePrimaryAddressObj.state = postalAddress?.administrativeArea;
      updatePrimaryAddressObj.zip =  postalAddress?.postalCode;
      console.log("P----> Customer infroamtion object\t:::", customerInformationObj, "\n updatePrimaryAddressObj \t :::", updatePrimaryAddressObj)
      setcustomerInformation(customerInformationObj);
      setUpdatePrimaryAddress(updatePrimaryAddressObj);
      setIsPrimaryCustoInfoUpdate(true);
    }else if(type == 'GA' && addressType == 'M'){
      const customerInformationObj = customerInformation;
      const postalAddress= validatedAddresses?.postalAddress;
      customerInformationObj.cityM = postalAddress?.locality;
      customerInformationObj.stateM = postalAddress?.administrativeArea;
      customerInformationObj.zipM =  postalAddress?.postalCode;
      const updateMailingAddressObj = updateMailingAddress;
      if(postalAddress?.addressLines.length>1){
        customerInformationObj.addressLine1M =postalAddress?.addressLines[1]
        customerInformationObj.addressLine2M =postalAddress?.addressLines[0]
        updateMailingAddressObj.address =  postalAddress?.addressLines[1];
        updateMailingAddressObj.address1 = postalAddress?.addressLines[0];
       }
        else {
          customerInformationObj.addressLine2M = ''
          customerInformationObj.addressLine1M = postalAddress?.addressLines.join(', ');
          updateMailingAddressObj.address =  postalAddress?.addressLines.join(', ');
          updateMailingAddressObj.address1 =''
      }
      updateMailingAddressObj.city = postalAddress?.locality;
      updateMailingAddressObj.state = postalAddress?.administrativeArea;
      updateMailingAddressObj.zip = postalAddress?.postalCode;
      console.log(" M----> Customer infroamtion object\t:::", customerInformationObj, "\n updateMailingAddressObj \t :::", updateMailingAddressObj)
      setcustomerInformation(customerInformationObj);
      setupdateMailingAddress(updateMailingAddressObj);
      setIsPrimaryCustoInfoUpdate(true);
    }
    else{
      buildENTAddres(type, addressType, mc);
    }
    setCanSuggestAddress(false)
    addressType == 'P' ? seteditInputBox(true) : seteditInputBoxM(true);
    setrunAddressDoc('');
  };
  const buildENTAddres = (type: any, addressType: 'P' | 'M' | '', mc?: any) => {
    if (type == 'ENT' && addressType == 'P') {
      const customerInformationObj = customerInformation;
      customerInformationObj.addressLine1 =
        mc == 'MC1'
          ? updatePrimaryAddress.address.toUpperCase()
          : updatePrimaryAddress.address;
      customerInformationObj.addressLine2 =
        mc == 'MC1'
          ? updatePrimaryAddress.address1.toUpperCase()
          : updatePrimaryAddress.address1;
      customerInformationObj.city =
        mc == 'MC1'
          ? updatePrimaryAddress.city.toUpperCase()
          : updatePrimaryAddress.city;
      customerInformationObj.state = updatePrimaryAddress.state;
      customerInformationObj.zip = updatePrimaryAddress.zip;

      setcustomerInformation(customerInformationObj);
      setUpdatePrimaryAddress({
        address:
          mc == 'MC1'
            ? updatePrimaryAddress.address.toUpperCase()
            : updatePrimaryAddress.address,
        address1:
          mc == 'MC1'
            ? updatePrimaryAddress.address1.toUpperCase()
            : updatePrimaryAddress.address1,
        city:
          mc == 'MC1'
            ? updatePrimaryAddress.city.toUpperCase()
            : updatePrimaryAddress.city,
        zip: updatePrimaryAddress.zip,
        state: updatePrimaryAddress.state,
      });
      // setUpdatePrimaryAddress(customerInformationObj);
    } else if (type == 'ENT' && addressType == 'M') {
      const customerInformationObj = customerInformation;
      customerInformationObj.addressLine1M =
        mc == 'MC1'
          ? updateMailingAddress.address.toUpperCase()
          : updateMailingAddress.address;
      customerInformationObj.addressLine2M =
        mc == 'MC1'
          ? updateMailingAddress.address1.toUpperCase()
          : updateMailingAddress.address1;
      customerInformationObj.cityM =
        mc == 'MC1'
          ? updateMailingAddress.city.toUpperCase()
          : updateMailingAddress.city;
      customerInformationObj.stateM = updateMailingAddress.state;
      customerInformationObj.zipM = updateMailingAddress.zip;

      setcustomerInformation(customerInformationObj);
      setupdateMailingAddress({
        address:
          mc == 'MC1'
            ? updateMailingAddress.address.toUpperCase()
            : updateMailingAddress.address,
        address1:
          mc == 'MC1'
            ? updateMailingAddress.address1.toUpperCase()
            : updateMailingAddress.address1,
        city:
          mc == 'MC1'
            ? updateMailingAddress.city.toUpperCase()
            : updateMailingAddress.city,
        zip: updateMailingAddress.zip,
        state: updateMailingAddress.state,
      });
      // setUpdatePrimaryAddress(customerInformationObj);
    }
    addressType == 'P' ? seteditInputBox(true) : seteditInputBoxM(true);
  };
  const agreementMenu = () => {
    console.log('checkAction', checkAction);
    if (createAgrClicked) {
      setcreateAgrClicked(false);
    } else {
      setcreateAgrClicked(true);
    }
    if (PopupClass === `${classes.customMenuContainer} ${classes.popUpHide}`) {
      setPopupClass(`${classes.customMenuContainer} ${classes.popUp}`);
    }
    if (PopupClass === `${classes.customMenuContainer} ${classes.popUp}`) {
      setPopupClass(`${classes.customMenuContainer} ${classes.popUpHide}`);
    }
  };
  const otherActOnblur = () => {
    setPopupClass(`${classes.customMenuContainer} ${classes.popUpHide}`);
  };
  const RentalRedirect = (btn: any) => {
    if (
      customerInformation.racCoworker !== undefined &&
      customerInformation.racCoworker !== null &&
      customerInformation.racCoworker !== '' &&
      customerInformation.racCoworker == 'Y' &&
      btn == 'AGREEMENT' && !coworkerPinAuth
    ) {
      setauthenType('CREATEAGR');
      racCoWorkerFunc('RAC Coworker');
      //setcomingSoonPopup(true);
    }
    else {
      agreementRedirect('AGREEMENT');
    }
  }
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const agreementRedirect = async (btn: any) => {
    setCreateAgreementClick(true);
    // ;
    //if (pageType !== 'customer') {
    let UpdateCustomerAddress: any;
    if (
      customerInformation.racCoworker !== undefined &&
      customerInformation.racCoworker !== null &&
      customerInformation.racCoworker !== '' &&
      customerInformation.racCoworker == 'Y' && featureFlagDetails?.DiscountedAgreement == '0' &&
      btn == 'AGREEMENT'
    ) {
      setcomingSoonPopup(true);
    } else {
      const validateResultObj: any = [];
      let isValid = true;
      let firstTabValid =
        validateTabOne(true) && validateCustomerTab() ? true : false;
      const SSN: any =
        customerInformation.ssn1 +
          customerInformation.ssn2 +
          customerInformation.ssn3 ==
          ''
          ? null
          : customerInformation.ssn1 +
          customerInformation.ssn2 +
          customerInformation.ssn3;
      // firstTabValid =
      //   SSN != null ? (SSN.length < 9 ? false : true) : firstTabValid;
      if (SSN !== null && SSN.length < 9) {
        firstTabValid = false;
      }
      console.log('firstTabValidSSN', firstTabValid);
      console.log('createActiveTab', createActiveTab);
      if (firstTabValid === false) {
        validateResultObj.push('Customer Information');
        isValid = false;
      }

      setValidateResult(validateResultObj);
      let result: any = '';
      if (isValid) {
        try {
          if (isPrimaryCustoInfoUpdate) {
            setisrequired(false);
            const UpdateCustomerRequest: any = {
              customerId: customerId,
              globalCustomerId: customerInformation.globalCustomerId,
              firstName: customerInformation.firstName,
              lastName: customerInformation.lastName,
              middleInitial: customerInformation.middleInitial,
              coworker: customerInformation.racCoworker,
              suffix:
                customerInformation.suffix !== '0' &&
                  customerInformation.suffix !== undefined
                  ? customerInformation.suffix
                  : '',
              salutation: customerInformation.salutation,

              governmentId: customerInformation.governmentId,
              governmentIdType:
                customerInformation.governmentIdType == '0'
                  ? ''
                  : customerInformation.governmentIdType,

              governmentIdIssuedState:
                customerInformation.governmentIdIssuedState,
              emailAddress: customerInformation.emailAddress,
              preferredContactMethod:
                customerInformation.preferredMethod !== '0' &&
                  customerInformation.preferredMethod !== 'EM'
                  ? customerInformation.preferredMethod
                  : '',
              preferredLanguage: customerInformation.preferredLanguage,
              printReceiptMethod: customerInformation.preferredPaymentReceipt,
              phoneSolicitationAllowed:
                customerInformation.phoneSolicitationAllowed,
              emailSolicitationAllowed:
                customerInformation.emailSolicitationAllowed,
              mailSolicitationAllowed:
                customerInformation.mailSolicitationAllowed,
              textSolicitationAllowed: customerInformation.textMessageRemainder,
              paymentReminderCallsAllowed:
                customerInformation.paymentRemainderCall,
              verified:
                customerInformation?.verifiedDate == undefined ||
                  customerInformation?.verifiedDate == null ||
                  customerInformation?.verifiedDate == ''
                  ? 'N'
                  : 'Y',
              verifiedDate:
                Residences.ownVerifieddate !== undefined &&
                  Residences.ownVerifieddate !== null &&
                  Residences.ownVerifieddate !== ''
                  ? buildDate(Residences.ownVerifieddate)
                  : '',
              skip: customerInformation.skip,
              stolen: customerInformation.stolen,
              hard: customerInformation.hard,
              skipDate: buildDate(''),
            };
            if (isMaskedDataHasChanged.ssn) {
              UpdateCustomerRequest.taxId = SSN,
                UpdateCustomerRequest.taxIdType = 'SSN'
            }
            if (isMaskedDataHasChanged.dob) {
              UpdateCustomerRequest.dateOfBirth = buildDate(customerInformation.dateOfBirth)
            }
            if (isMaskedDataHasChanged.govtId) {
              UpdateCustomerRequest.governmentId = customerInformation.governmentId
            }
            // UpdateCustomerRequest.addresses = buildAddress();
            UpdateCustomerAddress = buildUpdateAddress();
            const phonePropsArray: any = buildPhones();

            if (
              phoneNumberProps !== undefined &&
              phoneNumberProps !== null &&
              phoneNumberProps.length > 0 &&
              validatePhone(false).isValidate
            ) {
              UpdateCustomerRequest.phones = phonePropsArray;
            }
            const ObjTabTwo = validateTabTwo(true);
            console.log('isValid create agreement', ObjTabTwo);

            const ObjTabThree = validateTabThree(true);

            const ObjTabFour = validateTabFour(true);

            if (
              ObjTabTwo.isValidate === true &&
              ObjTabTwo.UpdatedInfo === true
            ) {
              const EmpPropsArray: any = buildEmpObj();

              UpdateCustomerRequest.employerReferences = EmpPropsArray;
            } else {
              if (
                (ObjTabTwo.isValidate === false ||
                  ObjTabTwo.UpdatedInfo === false) &&
                (activetab == tabList.EMPLOYMENTINFO || createActiveTab == 1)
              ) {
                // validateResultObj.push('Employment Information');
                setisrequired(true);
                return;
              }
            }
            if (
              ObjTabThree.isValidate === true &&
              ObjTabThree.UpdatedInfo === true
            ) {
              if (Residences.residenceType === 'RENT') {
                UpdateCustomerRequest.landlordReferences = landlordReferences();
                UpdateCustomerRequest.residenceType = Residences.residenceType;
                UpdateCustomerRequest.ownVerifiedDate = '';
              } else {
                UpdateCustomerRequest.mortgageCompanyName =
                  Residences.residenceType === 'OWN'
                    ? Residences.mortgage === true
                      ? Residences.mortgageCompanyName
                      : ''
                    : '';
                UpdateCustomerRequest.residenceSince =
                  Residences.residenceType === 'OWN'
                    ? Residences.residenceSince
                    : null;
                UpdateCustomerRequest.monthlyMortgagePayment =
                  Residences.residenceType === 'OWN'
                    ? Residences.mortgage === true
                      ? Residences.monthlyMortgagePayment == 'NaN'
                        ? ''
                        : Residences.monthlyMortgagePayment
                      : ''
                    : '';
              }
            } else {
              if (
                (ObjTabThree.isValidate === false ||
                  ObjTabThree.UpdatedInfo === false) &&
                (activetab == tabList.RESIDENCE || createActiveTab == 2)
              ) {
                // validateResultObj.push('Residence Information');
                setisrequired(true);
                return;
              }
            }
            if (
              salesLeadIdQuery !== undefined &&
              salesLeadIdQuery !== null &&
              salesLeadIdQuery !== ''
            ) {
              UpdateCustomerRequest.salesLeadId = salesLeadIdQuery;
            }

            if (
              ObjTabFour.isValidate === true &&
              ObjTabFour.UpdatedInfo === true
            ) {
              const ReferencePropsArray: any = buildReferenceObj();
              // eslint-disable-next-line no-console
              console.log('ReferenceProps', ReferencePropsArray);

              UpdateCustomerRequest.personalReferences = ReferencePropsArray;
            } else {
              if (
                (ObjTabFour.isValidate === false ||
                  ObjTabFour.UpdatedInfo === false) &&
                (activetab == tabList.REFERENCE || createActiveTab == 3)
              ) {
                // validateResultObj.push('Reference Information');
                setisrequired(true);
                return;
              }
            }
            if (validateResultObj.length > 0) {
              setErrorPopup(true);
              setisrequired(true);
              return;
            }
            if (pageType == 'customer') {
              const vechicleObj: any = buildVechicle();
              UpdateCustomerRequest.vehicles = vechicleObj;
            }
            if (
              customerInformation.racCoworker !== undefined &&
              customerInformation.racCoworker !== null &&
              customerInformation.racCoworker !== '' &&
              customerInformation.racCoworker == 'Y' &&
              btn == 'AGREEMENT' && !coworkerPinAuth && isAuth === false
            ) {
              setauthenType('CREATEAGR');
              racCoWorkerFunc('RAC Coworker');
              setisAuth(true);
              //setcomingSoonPopup(true);
            }
            else {
              UpdateCustomerRequest.assignRoute = {
                storeNumber: loginStore,
                customerId: parseInt(customerId),
                routeRefCode: customerInformation.route,
              };
              // /* eslint-enable no-console */
              setIsLoading(true);

              result = await UpdateCustomerInfo(
                UpdateCustomerRequest,
                customerId
              );
              const updateAddPayload: any = UpdateCustomerAddress;
              const addResult = await UpdateAddress(updateAddPayload);
              setIsLoading(false);
            }
          }
          if (
            !isPrimaryCustoInfoUpdate ||
            (result !== undefined &&
              result !== null &&
              result.UpdateCustomer !== undefined &&
              result.UpdateCustomer !== null &&
              result.UpdateCustomer.value !== undefined &&
              result.UpdateCustomer.value !== null &&
              result.UpdateCustomer.value.customerId !== undefined &&
              result.UpdateCustomer.value.customerId !== null &&
              result.UpdateCustomer.value.customerId == customerId)
          ) {
            if (customerId !== undefined) {
              if (
                customerInformation.coCustomerId !== undefined &&
                customerInformation.coCustomerId !== null &&
                customerInformation.coCustomerId !== '' &&
                btn == 'AGREEMENT'
              ) {
                props.history.push(
                  (salesLeadIdQuery !== undefined &&
                    salesLeadIdQuery !== null &&
                    salesLeadIdQuery !== '') ||
                    (customerInf?.salesLeadId !== undefined &&
                      customerInf?.salesLeadId !== null &&
                      customerInf?.salesLeadId !== '')
                    ? `/agreement/rental/itemsearch/${customerId}?salesLeadId=${salesLeadIdQuery !== undefined &&
                      salesLeadIdQuery !== null &&
                      salesLeadIdQuery !== ''
                      ? salesLeadIdQuery
                      : customerInf?.salesLeadId
                    }`
                    : `/agreement/rental/itemsearch/${customerId}`
                );
              } else if (btn == 'PAYMENT') {
                props.history.push(
                  `/payment1/paymentinformation/${customerId}/0`
                );
              } else if (btn == 'ReInstatement') {
                setrestatementPopUp(true);
              }
              else if (customerInformation.racCoworker !== undefined &&
                customerInformation.racCoworker !== null &&
                customerInformation.racCoworker !== '' &&
                customerInformation.racCoworker == 'Y' &&
                btn == 'AGREEMENT') {
                //setcreateCoCustomerPopUp(true);
                props.history.push(
                  (salesLeadIdQuery !== undefined &&
                    salesLeadIdQuery !== null &&
                    salesLeadIdQuery !== '') ||
                    (customerInf?.salesLeadId !== undefined &&
                      customerInf?.salesLeadId !== null &&
                      customerInf?.salesLeadId !== '')
                    ? `/agreement/rental/itemsearch/${customerId}?salesLeadId=${salesLeadIdQuery !== undefined &&
                      salesLeadIdQuery !== null &&
                      salesLeadIdQuery !== ''
                      ? salesLeadIdQuery
                      : customerInf?.salesLeadId
                    }`
                    : `/agreement/rental/itemsearch/${customerId}?coCustomerId=N`
                );
              }
              else {
                setcreateCoCustomerPopUp(true);
              }
              //props.history.push(`/agreement/rental/itemsearch/${customerId}`);
            }
          } else {
            setisSuccess(false);
            setShowUpdatePopUp(true);
          }
        } catch (e: any) {
          setIsLoading(false);
          setErrorPopup(true);
        }
      } else {
        setErrorPopup(true);
        setisrequired(true);
      }
      setCreateAgreementClick(false);
      setIsPrimaryCustoInfoUpdate(false);
      // } else {
      //   setCreateAgreementClick(false);
      //   if (customerId !== undefined) {
      //     props.history.push(`/agreement/rental/itemsearch/${customerId}`);
      //   }
      // }
    }
  };
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const createAgrHandler = async () => {
    //if (pageType !== 'customer') {
    if (
      customerInformation.racCoworker !== undefined &&
      customerInformation.racCoworker !== null &&
      customerInformation.racCoworker !== '' &&
      customerInformation.racCoworker == 'Y' && featureFlagDetails?.DiscountedAgreement == '1'
    ) {
      //setcomingSoonPopup(true);
      history.push(`/agreement/rental/itemsearch/${customerId}?coCustomerId=N`)
    }
    else if (customerInformation.racCoworker !== undefined &&
      customerInformation.racCoworker !== null &&
      customerInformation.racCoworker !== '' &&
      customerInformation.racCoworker == 'Y' && featureFlagDetails?.DiscountedAgreement == '0') {
      setcomingSoonPopup(true);
    }
    else {
      setcreateAgreementPopUp(false);
      setcreateCoCustomerPopUp(true);
    }
  };
  const validateSSN = () => {
    let ssnValidation = true;
    if (
      customerInformation?.ssn1 !== undefined &&
      customerInformation?.ssn1 !== null &&
      customerInformation?.ssn2 !== undefined &&
      customerInformation?.ssn2 !== null &&
      customerInformation?.ssn3 !== undefined &&
      customerInformation?.ssn3 !== null
    ) {
      if (
        customerInformation?.ssn1.length +
        customerInformation?.ssn2.length +
        customerInformation?.ssn3.length !==
        0 &&
        customerInformation?.ssn1.length +
        customerInformation?.ssn2.length +
        customerInformation?.ssn3.length <
        9
      ) {
        ssnValidation = false;
      }
    } else if (
      customerInformation?.ssn1 !== undefined &&
      customerInformation?.ssn1 !== null &&
      customerInformation?.ssn1.length > 0
    ) {
      ssnValidation = false;
    } else if (
      customerInformation?.ssn2 !== undefined &&
      customerInformation?.ssn2 !== null &&
      customerInformation?.ssn2.length > 0
    ) {
      ssnValidation = false;
    } else if (
      customerInformation?.ssn3 !== undefined &&
      customerInformation?.ssn3 !== null &&
      customerInformation?.ssn3.length > 0
    ) {
      ssnValidation = false;
    }
    return ssnValidation;
  };
  const RadioBtnclick = (type: any) => {
    if (type === 'SUG') {
      setBtnIsdisabled1(true);
      setBtnIsdisabled2(false);
      setdefaultSuggestedAddress('SUG');
    } else if (type === 'ENT') {
      setBtnIsdisabled1(false);
      setBtnIsdisabled2(true);
      setdefaultSuggestedAddress('ENT');
    }
    else if(type == 'GA'){
      setdefaultSuggestedAddress('GA')
    }
  };
  const formatPhone = (input: any) => {
    // //;
    //alert(e.target.value);
    if (input && input?.length > 0) {
      const cleaned = ('' + input).replace(/\D/g, '');
      //This is raw data to pass on props
      const PhoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
      //Isvalid = temp.length == 14 ? true:false
      //alert(temp);
      return cleaned.replace(PhoneRegex, '($1) $2-$3');
    }
    return ''
  };
  // const formatCurrency = (value: any) => {
  //   const nfObject = new Intl.NumberFormat('en-US');
  //   return nfObject.format(value);
  // };
  // const HandleDecimal = (value: any) => {
  //   const formatedValue: any =
  //     value !== undefined && value !== null ? value.replace(',', '') : 0;
  //   // eslint-disable-next-line no-console
  //   console.log('formatedValue', formatedValue);
  //   const nfObject = new Intl.NumberFormat('en-US', {
  //     minimumFractionDigits: 2,
  //   });
  //   return nfObject.format(formatedValue);
  // };
  const verifiedResidenceDisable = () => {
    let verfDisable = false;
    if (Residences?.residenceType == 'RENT') {
      verfDisable = validateValue(Residences?.landlordFirstName);
      verfDisable =
        verfDisable === false
          ? validateValue(Residences?.landlordLastName)
          : verfDisable;
      verfDisable =
        verfDisable === false
          ? validateValue(Residences?.phoneNumber)
          : verfDisable;
      verfDisable =
        verfDisable === false
          ? Residences?.phoneNumber.length < 10
            ? true
            : verfDisable
          : verfDisable;
    } else if (
      Residences?.residenceType == 'OWN' &&
      Residences?.mortgage !== undefined &&
      Residences?.mortgage === true
    ) {
      verfDisable = validateValue(Residences?.mortgageCompanyName);
    } else if (
      Residences?.residenceType !== 'OWN' ||
      Residences?.residenceType !== 'RENT'
    ) {
      verfDisable = true;
    }
    return verfDisable;
  };
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const verifiedRefernceDisable = (data: any) => {
    let verfDisable = false;
    verfDisable = validateValue(data?.firstName);
    verfDisable =
      verfDisable === false ? validateValue(data?.lastName) : verfDisable;
    verfDisable =
      verfDisable === false ? validateValue(data?.phoneNumber) : verfDisable;
    verfDisable =
      verfDisable === false
        ? data?.phoneNumber.length < 10
          ? true
          : verfDisable
        : verfDisable;
    verfDisable =
      verfDisable === false
        ? validateValue(data?.relationshipType)
        : verfDisable;
    verfDisable =
      verfDisable === false
        ? data?.relationshipType == '0'
          ? true
          : verfDisable
        : verfDisable;
    // verfDisable =
    //   verfDisable === false ? validateValue(data?.addressLine1) : verfDisable;
    // verfDisable =
    //   verfDisable === false ? validateValue(data?.postalCode) : verfDisable;
    // verfDisable =
    //   verfDisable === false
    //     ? data?.postalCode.length !== 5 && data?.postalCode.length !== 10
    //       ? true
    //       : verfDisable
    //     : verfDisable;
    // verfDisable =
    //   verfDisable === false ? validateValue(data?.city) : verfDisable;
    // verfDisable =
    //   verfDisable === false ? validateValue(data?.state) : verfDisable;
    // verfDisable =
    //   verfDisable === false
    //     ? data?.state == '0'
    //       ? true
    //       : verfDisable
    //     : verfDisable;
    return verfDisable;
  };
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const referenceInfoFunc = () => {
    return ReferenceProps.map((data: any, index: any) => {
      const activeReferencOBj: boolean =
        data?.active !== undefined &&
          data?.active !== '' &&
          data?.active !== null &&
          data?.active !== 'null' &&
          data?.active == 'Y'
          ? true
          : false;
      const verifiedDiabled: boolean = verifiedRefernceDisable(data);
      if (!isDropDownValueExist(bestTimeToCall, data?.bestTimeToCall)) {
        data.bestTimeToCall = '';
      }
      if (!isDropDownValueExist(rel, data?.relationshipType)) {
        data.relationshipType = '';
      }
      if (!isDropDownValueExist(state, data?.state)) {
        data.state = '';
      }
      if (data?.postalCode.includes('-') && data?.postalCode?.split('-')[1]?.length == 0) {
        data.postalCode = data?.postalCode.split('-')[0]
      }
      // if (data?.postalCode.length == 9) {
      //   data.postalCode = data?.postalCode.slice(0, 5) + '-' + data?.postalCode.slice(5)
      // }
      return (
        <>
          <Grid container>
            <Card
              className={`${customerStyleClasses.card} ${customerStyleClasses.w100} ${customerStyleClasses.mb3}`}
            >
              <CardContent id="reference_info_div">
                {pageType == undefined || pageType == 'customer' ? (
                  <Grid container>
                    <Grid item md={10}></Grid>
                    <Grid item md={1}>
                      <RACCheckBox
                        label="Verified"
                        size="small"
                        name={`verifiedCheckBox_${index}`}
                        onChange={(e: any) => {
                          handleChangeForAddReferenceInfo(e);
                        }}
                        disabled={Agrtransferinit ? true : (verifiedDiabled || !activeReferencOBj)}
                        checked={
                          data?.verifiedDate !== undefined &&
                            data?.verifiedDate !== '' &&
                            data?.verifiedDate !== null &&
                            data?.verifiedDate !== 'null'
                            ? true
                            : false
                        }
                      />
                    </Grid>
                    <Grid item className={customerStyleClasses.formCheck}>
                      <RACCheckBox
                        label="Active"
                        size="small"
                        className={classes.custCheckbox}
                        name={`activeCheckBox_${index}`}
                        onChange={(e: any) => {
                          handleChangeForAddReferenceInfo(e);
                        }}
                        checked={
                          data?.active !== undefined &&
                            data?.active !== '' &&
                            data?.active !== null &&
                            data?.active !== 'null' &&
                            data?.active == 'Y'
                            ? true
                            : false
                        }
                        disabled={
                          Agrtransferinit ? true : data?.personalReferenceId == '' ? true : false
                        }
                      />
                    </Grid>
                  </Grid>
                ) : null}
                <Grid container spacing={2}>
                  <Grid item md={3} lg={2}>
                    <RACTextbox
                      isCurrency={false}
                      required={data?.active == 'Y' ? true : false}
                      type={'text'}
                      inputlabel="Parent/Relative First Name"
                      inputLabelClassname={classes.textWrapStyle}
                      name={`parent-RelativeName_${index}`}
                      OnChange={(e: any) => handleChangeForAddReferenceInfo(e)}
                      maxlength={25}
                      value={
                        data?.firstName !== undefined &&
                          data?.firstName !== null
                          ? data?.firstName
                          : ''
                      }
                      disabled={Agrtransferinit ? true : !activeReferencOBj}
                      errorMessage={
                        data?.firstName !== undefined &&
                          data?.firstName == '' &&
                          (isrequired === true || validateReference === false) &&
                          data?.active == 'Y'
                          ? FIELD_ERROR
                          : ''
                      }
                    />
                  </Grid>
                  <Grid item md={3} lg={2}>
                    <RACTextbox
                      isCurrency={false}
                      required={data?.active == 'Y' ? true : false}
                      type={'text'}
                      inputlabel="Parent/Relative Last Name"
                      name={`parent-RelativeLastName_${index}`}
                      OnChange={(e: any) => handleChangeForAddReferenceInfo(e)}
                      maxlength={25}
                      value={
                        data?.lastName !== undefined && data?.lastName !== null
                          ? data?.lastName
                          : ''
                      }
                      disabled={Agrtransferinit ? true : !activeReferencOBj}
                      errorMessage={
                        data?.lastName !== undefined &&
                          data?.lastName == '' &&
                          (isrequired === true || validateReference === false) &&
                          data?.active == 'Y'
                          ? FIELD_ERROR
                          : ''
                      }
                    />
                  </Grid>
                  <Grid item md={3} lg={2}>
                    <RACTextbox
                      isCurrency={false}
                      required={data?.active == 'Y' ? true : false}
                      type={
                        data?.doNotCall !== undefined && data?.doNotCall == 'Y'
                          ? 'password'
                          : 'text'
                      }
                      inputlabel="Phone Number"
                      name={`phoneNumber_${index}`}

                      OnChange={(e: any) => handleChangeForAddReferenceInfo(e)}
                      maxlength={14}
                      value={
                        data?.phoneNumber !== undefined
                          ? formatPhone(data?.phoneNumber)
                          : ''
                      }
                      disabled={
                        Agrtransferinit ? true :
                          (!activeReferencOBj ||
                            (data?.doNotCall !== undefined && data?.doNotCall == 'Y'
                              ? true
                              : false))
                      }
                      errorMessage={
                        (isrequired === true || validateReference === false) &&
                          (data?.phoneNumber == undefined ||
                            data?.phoneNumber == null ||
                            data?.phoneNumber == '') &&
                          data?.active == 'Y'
                          ? FIELD_ERROR
                          : (isrequired === true ||
                            validateReference === false) &&
                            data?.active == 'Y' &&
                            data?.phoneNumber.length < 10
                            ? 'Please enter valid phone number'
                            : ''
                      }
                    />
                  </Grid>
                  <Grid item md={3} lg={2}>
                    <RACSelect
                      inputLabel="Best Time to Call"
                      options={bestTimeToCall}
                      defaultValue={
                        data?.bestTimeToCall !== undefined &&
                          data?.bestTimeToCall !== null &&
                          data?.bestTimeToCall !== '' &&
                          isDropDownValueExist(
                            bestTimeToCall,
                            data?.bestTimeToCall
                          )
                          ? data?.bestTimeToCall
                          : '0'
                      }
                      isDisabled={Agrtransferinit ? true : !activeReferencOBj}
                      loading={
                        bestTimeToCall.length == 0 && !dropDownLoaded
                          ? true
                          : false
                      }
                      onChange={(e: any) => {
                        e.target.name = `bestTimeToCall_${index}`;
                        handleChangeForAddReferenceInfo(e);
                      }}
                      {...(bestTimeToCall.length == 0 &&
                        dropDownLoaded && {
                        errorMessage: API_ERROR_MESSAGE,
                      })}
                    />
                  </Grid>
                  <Grid item md={3} lg={2}>
                    <RACSelect
                      inputLabel="Relationship"
                      options={rel}
                      isDisabled={Agrtransferinit}
                      defaultValue={
                        data?.relationshipType !== undefined &&
                          data?.relationshipType !== null &&
                          data?.relationshipType !== '' &&
                          isDropDownValueExist(rel, data?.relationshipType)
                          ? data?.relationshipType
                          : '0'
                      }
                      required={data?.active == 'Y' ? true : false}
                      loading={
                        relationShipObj.length == 0 && !dropDownLoaded
                          ? true
                          : false
                      }
                      onChange={(e: any) => {
                        e.target.name = `relationShip_${index}`;
                        handleChangeForAddReferenceInfo(e);
                      }}
                      errorMessage={
                        data?.relationshipType !== undefined &&
                          (data?.relationshipType == '' ||
                            data?.relationshipType == '0') &&
                          (isrequired === true || validateReference === false) &&
                          data?.active == 'Y'
                          ? FIELD_ERROR
                          : ''
                      }
                      {...(relationShipObj.length == 0 &&
                        dropDownLoaded && {
                        errorMessage: API_ERROR_MESSAGE,
                      })}
                    />
                  </Grid>
                  <Grid item md={4} lg={2} className={classes.mtcustom}>
                    <RACCheckBox
                      label="Do Not Call"
                      size="small"
                      id={`doNotVisitDiv_${index}`}
                      name={`doNotVisit_${index}`}
                      className={classes.custCheckbox}
                      onChange={(e: any) => handleChangeForAddReferenceInfo(e)}
                      checked={
                        data?.doNotCall !== undefined && data?.doNotCall == 'Y'
                          ? true
                          : false
                      }
                      disabled={Agrtransferinit ? true : !activeReferencOBj}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item md={12}>
                    <Typography
                      variant="h6"
                      className={`${classes.subTitle} ${classes.mt3}`}
                    >
                      Address Information
                    </Typography>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item md={4} lg={2}>
                      <RACTextbox
                        isCurrency={false}
                        type={'text'}
                        inputlabel={addressLine1}
                        placeHolder={addressLine1}
                        disabled={Agrtransferinit ? true : !activeReferencOBj}
                        OnChange={(e: any) =>
                          handleChangeForAddReferenceInfo(e)
                        }
                        id={`addressLine1Div_${index}`}
                        name={`addressLine1_${index}`}
                        value={
                          data?.addressLine1 !== undefined
                            ? data?.addressLine1
                            : ''
                        }
                        maxlength={30}
                      />
                    </Grid>
                    <Grid item md={4} lg={2}>
                      <RACTextbox
                        isCurrency={false}
                        required={false}
                        type={'text'}
                        inputlabel="Address Line 2"
                        infoIcon={true}
                        infoTitle={addressLine2}
                        placeHolder={addressLine2}
                        disabled={Agrtransferinit ? true : !activeReferencOBj}
                        OnChange={(e: any) =>
                          handleChangeForAddReferenceInfo(e)
                        }
                        maxlength={30}
                        id={`addressLine2Div_${index}`}
                        name={`addressLine2_${index}`}
                        value={
                          data?.addressLine2 !== undefined
                            ? data?.addressLine2
                            : ''
                        }
                      />
                    </Grid>
                    <Grid item md={4} lg={2}>
                      <RACTextbox
                        isCurrency={false}
                        type={'text'}
                        disabled={Agrtransferinit ? true : !activeReferencOBj}
                        id={`zipDiv_${index}`}
                        name={`zip_${index}`}
                        placeHolder="Zip Code"
                        value={
                          data?.postalCode !== undefined ? data?.postalCode : ''
                        }
                        data-testid="zipTextBox"
                        OnChange={(e: any) =>
                          handleChangeForAddReferenceInfo(e)
                        }
                        maxlength={10}
                        errorMessage={
                          data?.postalCode != undefined &&
                            data?.postalCode != null &&
                            data?.postalCode != '' &&
                            isrequired === true &&
                            data?.active == 'Y' &&
                            data?.postalCode.length > 0 &&
                            data?.postalCode.length != 5 &&
                            data?.postalCode.length != 10
                            ? INVALID_ZIP
                            : ''
                        }
                        inputlabel="Zip"
                      />
                    </Grid>
                    <Grid item md={4} lg={2}>
                      <RACTextbox
                        isCurrency={false}
                        type={'text'}
                        inputlabel="City"
                        id={`cityDiv_${index}`}
                        placeHolder="City"
                        disabled={Agrtransferinit ? true : !activeReferencOBj}
                        name={`city_${index}`}
                        value={data?.city !== undefined ? data?.city : ''}
                        OnChange={(e: any) =>
                          handleChangeForAddReferenceInfo(e)
                        }
                        maxlength={15}
                      />
                    </Grid>
                    <Grid item md={4} lg={1}>
                      <RACSelect
                        inputLabel="State"
                        options={state}
                        defaultValue={
                          data?.state !== null &&
                            data?.state !== '' &&
                            isDropDownValueExist(state, data?.state)
                            ? data?.state
                            : '0'
                        }
                        isDisabled={Agrtransferinit ? true : !activeReferencOBj}
                        required={false}
                        loading={
                          state.length == 0 && !dropDownLoaded ? true : false
                        }
                        onChange={(e: any) => {
                          e.target.name = `state_${index}`;
                          handleChangeForAddReferenceInfo(e);
                        }}
                        {...(state.length == 0 &&
                          dropDownLoaded && {
                          errorMessage: API_ERROR_MESSAGE,
                        })}
                      />
                    </Grid>

                    {index != 0 &&
                      (data?.personalReferenceId == undefined ||
                        data?.personalReferenceId == null ||
                        data?.personalReferenceId == '') ? (
                      <Grid item md={3} lg={1} className={classes.mt3}>
                        <RACButton
                          variant="outlined"
                          color="primary"
                          className={customerStyleClasses.foc}
                          onClick={() => removeDivanotherReference(index)}
                        >
                          Cancel
                        </RACButton>
                      </Grid>
                    ) : null}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </>
      );
    });
  };
  const handleDateSelect = (e: any) => {
    setisMaskedDataHasChanged(((prev: any) => {
      return {
        ...prev, dob: true
      }
    }))
    setDatePickerIsLoad(false);
    setcustomerInformation({
      ...customerInformation,
      dateOfBirth: buildDate(e),
    });
  };
  /* eslint-disable sonarjs/cognitive-complexity */
  const getDayPaidOption = (getEmployOptions: exploymentInfoOpt) => {
    // setIsLoading(false);
    /* eslint-disable no-console */
    console.log('getEmployOptions', getEmployOptions);
    const paidDropDown: [] | any = [];
    if (
      getEmployOptions.weekLy?.referenceOptionsDTO.referenceDetails !=
      undefined &&
      getEmployOptions.weekLy?.referenceOptionsDTO.referenceDetails != null &&
      getEmployOptions.weekLy?.referenceOptionsDTO.referenceDetails.length > 0
    ) {
      const sortSeq =
        getEmployOptions.weekLy?.referenceOptionsDTO.referenceDetails.sort(
          (a: any, b: any) => (a['displaySeq'] < b['displaySeq'] ? -1 : 1)
        );
      sortSeq.map((value: any, index: any) => {
        if (index === 0) {
          paidDropDown.push({
            label: 'Select',
            value: '0',
            name: 'select',
          });
        }
        paidDropDown.push({
          label: value.description,
          value: value.referenceCode,
          name: value.id,
        });
      });
    }
    setpayDayfrequency(paidDropDown);
    buildDayPaid(1, getEmployOptions.weekLy);
    buildDayPaid(3, getEmployOptions.monthly);
    buildDayPaid(5, getEmployOptions.semiMonthly);
    // eslint-disable-next-line
    //

    //return dayPaidDropDown;
  };
  const buildDayPaid = (sequenceId: 1 | 3 | 5, getEmployOptions: any) => {
    const dayPaidDropDown: dropdown[] = [];
    if (
      getEmployOptions?.dayspaidDTO != undefined &&
      getEmployOptions?.dayspaidDTO != null &&
      getEmployOptions?.dayspaidDTO.dayPaid !== undefined &&
      getEmployOptions?.dayspaidDTO.dayPaid !== null &&
      getEmployOptions?.dayspaidDTO.dayPaid.length > 0
    ) {
      getEmployOptions.dayspaidDTO.dayPaid.map((value: any, index: any) => {
        dayPaidDropDown.push({
          label: value.daysPaidDescEn,
          value: value.refCode,
        });
      });
      // eslint-disable-next-line no-console
      let sorted: any = dayPaidDropDown;
      if (sequenceId !== 1) {
        sorted = dayPaidDropDown.sort((a: any, b: any) => {
          return a['label'].localeCompare(b['label'], undefined, {
            numeric: true,
            sensitivity: 'base',
          });
        });
      }
      const dayPaidDD: dropdown[] = [];
      sorted.map((value: any, index: any) => {
        if (index === 0) {
          dayPaidDD.push({
            label: 'Select',
            value: '0',
          });
        }
        dayPaidDD.push(value);
      });
      if (sequenceId == 1) {
        setdayPaid1(dayPaidDD);
      } else if (sequenceId == 3) {
        setdayPaid3(dayPaidDD);
        // eslint-disable-next-line no-console
        console.log('dayPaidDropDown', dayPaidDD);
      } else if (sequenceId == 5) {
        setdayPaid5(dayPaidDD);
        // eslint-disable-next-line no-console
        console.log('dayPaidDropDown', dayPaidDD);
      }
    }
  };
  const handleUserID = (event: any) => {
    // setErrorLine(false);
    setUserID(event.target.value);
  };

  const handlePassword = (event: any) => {
    // setErrorLine(false);
    setPassword(event.target.value);
  };

  const loginClick = async (username: any, Password: any) => {
    setOpenModal(false);
    setloader(true);
    const payload = {
      userName: username,
      role: role,
      storeNumber: loginStore,
      password: Password,
    };
    setErrorLine(false);
    const result = await getsecondfactor(payload);
    setloader(false);
    UserValidate(result);
  };
  const seconduthenResponses = (e: any) => {
        if (e === true && btnClicked ){
      callrespfucntion(btnClicked)
      return 
    }
    if (e === true) {
      if(exchangeSelected){
        setExchangeNavigationPopup(true);
        setExchangeSelected(false);
        setSecondLevelAuthenOn(null);
      }
      else {
        if (authenType == 'RACWORKER') {
          setcustomerInformation({
            ...customerInformation,
            racCoworker: customerInformation.racCoworker == 'Y' ? 'N' : 'Y',
          });
        }
        else if (authenType == 'CREATEAGR') {
          agreementRedirect('AGREEMENT');
        }
        else if (authenType == 'ALERT') {
          let alertUpdateObj: any = AlertUpdateObj;
          if (AlertAuthenticationNeeded.removeAlert == undefined) {
            alertUpdateObj.push(AlertAuthenticationNeeded);
          } else {
            alertUpdateObj = alterAlertValue(
              'remove',
              null,
              AlertAuthenticationNeeded.alertTypeId
            );
          }
          setAlertUpdateObj([...alertUpdateObj]);
          setAlertAuthenticationNeeded('');
        } else if (authenType == 'ROUTES') {
          setcustomerInformation({
            ...customerInformation,
            route: preferredRoute,
          });
          setPreferrefRoute('');
        } else if (authenType == 'SKIP') {
          if (skipHardObj == 'S') {
            setcustomerInformation({
              ...customerInformation,
              skip: customerInformation?.skip == 'Y' ? 'N' : 'Y',
            });
          } else if (skipHardObj == 'H') {
            setcustomerInformation({
              ...customerInformation,
              hard: customerInformation?.hard == 'Y' ? 'N' : 'Y',
            });
          }
          setskipHardObj('');
        }
        setSecondLevelAuthenOn(null);
        setauthenType('');
      }
    }
  };

  const seconduthenResponsesofSSNandDOB = async (e: any) => {
    debugger;
    setcustomerauthDOB(true);
    setloader(true)
    // const decryptObj = new EncryptionService({ iv: decryptKeys.ivKey, key: decryptKeys.encryptKey });
    // console.log('decryptobj', decryptObj);

    // const res = decryptObj
    // const decryptedDob = decryptObj?.decryptValues({ 'dateOfBirth': props.basicInfo[0].dateOfBirthEnc })
    // console.log('decryptedDob', decryptedDob)
    // const dob = getData(decryptKeys.encryptKey, decryptKeys.ivKey, decryptedDob?.decryptData?.dateOfBirth)
    // console.log('decryptedDob dob', dob)

    // const decryptedTaxId = decryptObj?.decryptValues({ 'taxId': props.basicInfo[0].taxIdEnc })
    // console.log('decryptedTaxId', decryptedTaxId)
    // const taxId = getData(decryptKeys.encryptKey, decryptKeys.ivKey, decryptedTaxId?.decryptData?.taxId)
    // console.log('decryptedTaxId taxId', taxId)

    // Generate a random cryptographic key
    const enckey = crypto.randomBytes(16).toString("hex");
    console.log('keysss', enckey)
    const payloadForDecrypt: any = { decryptData: {}, encryptKey: crypto.randomBytes(16).toString("hex"), ivKey: crypto.randomBytes(8).toString("hex") }

    if (customerInformation && customerInformation.dateOfBirthEnc) {
      payloadForDecrypt.decryptData.dateOfBirth = customerInformation.dateOfBirthEnc
    }
    if (customerInformation && customerInformation.taxIdEnc) {
      payloadForDecrypt.decryptData.taxId = customerInformation.taxIdEnc
    }
    if (customerInformation && customerInformation.governmentIdEnc) {
      payloadForDecrypt.decryptData.governmentId = customerInformation.governmentIdEnc
    }
    const decryptResponse: any = await decryptCustomeeInfo(payloadForDecrypt)
    console.log('decryptResponse', decryptResponse)

    //       const decryptedDob = decryptResponse?.decryptValues({ 'dateOfBirth': decryptResponse.decryptData.dateOfBirth })
    // console.log('decryptedDob', decryptedDob)
    // const dob = getData(decryptKeys.encryptKey, decryptKeys.ivKey, decryptedDob?.decryptData?.dateOfBirth)
    // console.log('decryptedDob dob', dob)

    // const decryptedTaxId = decryptResponse?.decryptValues({ 'taxId': decryptResponse.decryptData.taxId })
    // console.log('decryptedTaxId', decryptedTaxId)
    // const taxId = getData(decryptKeys.encryptKey, decryptKeys.ivKey, decryptedTaxId?.decryptData?.taxId)
    // console.log('decryptedTaxId taxId', taxId)

    if(decryptResponse.status == 200){
      setloader(false)   

    if (decryptResponse && decryptResponse.data?.decryptData) {
      console.log('log 1');
      setcustomerauthDOB(true);
      if (customerInformation && customerInformation.dateOfBirthEnc) {
        console.log('log 2');
        // CustomerInfo.dateOfBirth = buildDateFormat2(getData(payloadForDecrypt.encryptKey, payloadForDecrypt.ivKey, decryptResponse.data?.decryptData?.dateOfBirth))
        customerInformation.dateOfBirth = getData(payloadForDecrypt.encryptKey, payloadForDecrypt.ivKey, decryptResponse.data?.decryptData?.dateOfBirth)

        decryptiondataofbirth = getData(payloadForDecrypt.encryptKey, payloadForDecrypt.ivKey, decryptResponse.data?.decryptData?.dateOfBirth)
        console.log('decryptiondataofbirth', decryptiondataofbirth);
      }
      if (customerInformation && customerInformation.taxIdEnc) {
        const taxIdRes = getData(payloadForDecrypt.encryptKey, payloadForDecrypt.ivKey, decryptResponse.data?.decryptData?.taxId)
        console.log('log 3', taxIdRes);
        customerInformation.ssn1 = taxIdRes.slice(0, 3)
        customerInformation.ssn2 = taxIdRes.slice(3, 5)
        customerInformation.ssn3 = taxIdRes.slice(5)
        const customerSSN1 = taxIdRes.slice(0, 3)
        const customerSSN2 = taxIdRes.slice(3, 5)
        const customerSSN3 = taxIdRes.slice(5)

        console.log('customerSSN1', customerSSN1)
        console.log('customerSSN2', customerSSN2)
        console.log('customerSSN3', customerSSN3)
        setcustomerInformation((pre: any) => ({
          ...pre,
          ssn1: customerSSN1
        }));
        setcustomerInformation((pre: any) => ({
          ...pre,
          ssn2: customerSSN2
        }));
      }
      if (customerInformation && customerInformation.governmentIdEnc) {
        const govermentid = getData(payloadForDecrypt.encryptKey, payloadForDecrypt.ivKey, decryptResponse.data?.decryptData?.governmentId)
        console.log('log 4', customerInformation);

        setcustomerInformation((pre: any) => ({
          ...pre,
          governmentId: govermentid
        }));
      }
    }
    console.log('decryptResponse', decryptResponse)

    console.log('payloadForDecrypt', payloadForDecrypt)
  }
  }

  const UserValidate = (result: any) => {
    if (result.status == 200) {
      if (
        result.data.LoginAuthenticationResult.value.StatusCode === '200' &&
        result.data.CoworkerAuthenticationResult.value.statusCode === 200
      ) {
        setOpenModal(false);
        if (authenType == 'RACWORKER') {
          setcustomerInformation({ ...customerInformation, racCoworker: 'Y' });
        }
        if (authenType == 'ALERT') {
          const alertUpdateObj: any = AlertUpdateObj;
          alertUpdateObj.push(AlertAuthenticationNeeded);
          setAlertUpdateObj([...alertUpdateObj]);
          setAlertAuthenticationNeeded('');
        }
        if (authenType == 'ROUTES') {
          setcustomerInformation({
            ...customerInformation,
            route: preferredRoute,
          });
          setPreferrefRoute('');
        }
      } else {
        setOpenModal(false);
        setOpenSecondModal(true);
      }
    } else {
      setOpenModal(false);
      setOpenSecondModal(true);
    }
    setotp('');
    setUserID('');
    setPassword('');
    setauthenType('');
  };
  const popupSelect = (result: any) => {
    if (result.data.pin === 1 && result.data.secondFactor === 1) {
      setOpenPinModal(true);
      setpopupFlag(true);
    } else if (result.data.pin === 1) {
      setOpenPinModal(true);
      setpopupFlag(false);
    } else if (result.data.secondFactor === 1) {
      setOpenModal(true);
      setpopupFlag(false);
    }
  };

  const openModelCancelClick = () => {
    setOpenModal(false);
    setErrorLine(false);
  };

  const okClick = () => {
    setOpenSecondModal(false);
    setOpenModal(true);
    setErrorLine(true);
    // setUserID('');
    // setPassword('');
  };
  useEffect(() => {
    if (otp.length == 4) {
      setContinue(false);
    } else {
      setContinue(true);
    }
  }, [otp]);
  const continueClick = async () => {
    setOpenPinModal(false);
    setloader(true);
    const userResponse = await getCurrentInfo();
    // eslint-disable-next-line no-console
    console.log('getCurrentUser Response', userResponse);
    const id = userResponse.data.employeeId
      ? userResponse.data.employeeId
      : userResponse.data.email.toLowerCase();
    const pin = otp;
    const payload = {
      userId: id,
      encryptedPin: pin.toString(),
    };
    // eslint-disable-next-line no-console
    console.log('getPin Payload', payload);
    const result = await getPinDetails(payload);
    // eslint-disable-next-line no-console
    console.log('Total Result', result);
    setloader(false);
    setOpenPinModal(true);
    setInvalidPin(result.status);
    if (result.data.statusCode == 200 && popupFlag) {
      setOpenPinModal(false);
      setOpenModal(true);
    }
    if (result.data.statusCode == 200 && !popupFlag) {
      setOpenPinModal(false);
      setcustomerInformation({ ...customerInformation, racCoworker: 'Y' });
    }
  };

  const setChargeOffclose = () => {
    setOpenPinModal(false);
  };
  const renderAgreementHistoryHeader = () => (
    <>
      {agreementHistoryHeader.map((col: string, index: number) => {
        return (
          <RACTableCell
            className={
              col == '# Payments' || col == 'Total Cost'
                ? `${customerStyleClasses.textCenter} ${customerStyleClasses.lineBreak} ${customerStyleClasses.customerTablecellbgcolor}`
                : col == 'Next Due Date'
                  ? `${customerStyleClasses.txtStart} ${customerStyleClasses.lineBreak} ${customerStyleClasses.customerTablecellbgcolor}`
                  : `${customerStyleClasses.lineBreak} ${customerStyleClasses.customerTablecellbgcolor}`
            }
            key={index}
          >
            {col}
          </RACTableCell>
        );
      })}
    </>
  );
  const renderDEHistoryHeader = () => (
    <>
      {DEHistoryHeader.map((col: string, index: number) => {
        return (
          <RACTableCell
            className={`${customerStyleClasses.textCenter} ${customerStyleClasses.lineBreak} ${customerStyleClasses.customerTablecellbgcolor}`}
            key={index}
          >
            {col}
          </RACTableCell>
        );
      })}
    </>
  );
  const renderAgreementHistoryCOntentForActiveInactive = () => {
    const inActiveagreements = agreementInf.filter(
      // eslint-disable-next-line sonarjs/no-identical-functions
      (value: any) =>
        value.agreementStatus != 'Active' &&
        value.agreementStatus != 'Pending' &&
        value.isReinstate === false
    );
    const reInagreements = agreementInf.filter(
      (value: any) => value.isReinstate === true
    );
    const payloadObj = [
      { objKey: 'Active', objVal: activeExpand },
      { objKey: 'Pending', objVal: pending },
      { objKey: 'InActive', objVal: inActiveExpand },
      { objKey: 'Reinstatable', objVal: reInstate },
    ];
    if (isExchangeEnabled && !agreementHistoryHeader.includes('Action')) {
      agreementHistoryHeader.push('Action');
    }
    return payloadObj.map((el: any) => {
      if (
        el.objKey == 'InActive' &&
        inActiveagreements.length == 0 &&
        reInagreements.length > 0
      ) {
        return AgreementGridForReinstate(el.objKey, el.objVal);
      }
      return AgreementGrid(el.objKey, el.objVal);
    });
  };
  /* eslint-disable sonarjs/cognitive-complexity */
  const renderContentNoRecordFound = () => {
    return (
      <RACTableRow hover backgroundColor="white">
        <RACTableCell></RACTableCell>
        <RACTableCell></RACTableCell>
        <RACTableCell></RACTableCell>
        <RACTableCell></RACTableCell>
        <RACTableCell></RACTableCell>
        <RACTableCell></RACTableCell>
        <RACTableCell>No Records Found</RACTableCell>
        <RACTableCell></RACTableCell>
        <RACTableCell></RACTableCell>
        <RACTableCell></RACTableCell>
        <RACTableCell></RACTableCell>
        <RACTableCell></RACTableCell>
      </RACTableRow>
    );
  };
  const renderContentNoDEHistoryRecordFound = () => {
    return (
      <RACTableRow hover backgroundColor="white">
        <RACTableCell></RACTableCell>
        <RACTableCell></RACTableCell>
        <RACTableCell></RACTableCell>
        <RACTableCell style={{ textAlign: 'center' }}>
          No Records Found
        </RACTableCell>
        <RACTableCell></RACTableCell>
        <RACTableCell></RACTableCell>
        <RACTableCell></RACTableCell>
      </RACTableRow>
    );
  };
  const renderAgreementHistoryContent: any = () => {
    //if (loading) return <></>;

    return agreementInf.length > 0
      ? renderAgreementHistoryCOntentForActiveInactive()
      : renderContentNoRecordFound();
  };
  const renderDEHistoryData: any = () => {
    return deHistoryResponse && deHistoryResponse.length > 0
      ? renderDEHistoryContent()
      : renderContentNoDEHistoryRecordFound();
  };
  const nextscroll = async () => {
    try {
      const existingData: any = deHistoryResponse;
      setinfinitScrolloader(true);
      setdeHistoryQueryParams({
        limit: deHistoryQueryParams?.limit + 10,
        offset: deHistoryQueryParams?.offset + 10,
      });
      const payload: DEHistoryRequest = {
        globalCustomerId: customerInformation?.globalCustomerId,
        limit: deHistoryQueryParams?.limit + 10,
        offset: deHistoryQueryParams?.offset + 10,
      };
      const {
        data: { customerApprovalHistory },
      } = await GetDEHistory(payload);
      console.log('data for DE', customerApprovalHistory);
      setDEHistoryResponse([...existingData, ...customerApprovalHistory]);
      if (customerApprovalHistory?.length >= 40) {
        sethasMore(false);
      }
      setinfinitScrolloader(false);
    } catch (err) {
      if (deHistoryResponse?.length > 0) {
        sethasMore(false);
      }
      // setDEHistoryResponse('ERROR');
      setinfinitScrolloader(false);
    }
  };
  const splitBtnClick = () => {
    const inActiveAgr = getAgrbyIdresponse?.filter(
      (obj: any) => obj?.agreementRefCode != 'ACTIVE'
    );
    if (inActiveAgr?.length > 0) {
      setSplitBlockOpen(true);
    } else {
      props.history.push(`/agreement/split/${customerId}`);
    }
  };

  const splitBlockPopup = () => {
    return (
      <div
        id="initialpayment"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <Grid className={classes.textCenter}>
          <Alerticon></Alerticon>
          <Typography className={customerStyleClasses.RACPOPMsg}>
            {
              'Please activate the pending agreements before performing the split'
            }
          </Typography>
        </Grid>
        <Grid
          item
          md={12}
          className={`${classes.mt3} ${classes.pb4} ${classes.textCenter}`}
        >
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => {
              setSplitBlockOpen(false);
            }}
          >
            Ok
          </RACButton>
        </Grid>
      </div>
    );
  };

  const TransferInitiatedPopup = () => {
    return (
      <div
        id="initialpayment"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <Grid className={classes.textCenter}>

          <Typography className={customerStyleClasses.RACPOPMsg} style={{ fontSize: '0.9rem' }}>
            {
              'Agreement Transfer is pending for this customer.'}
            {'The system will now disable all edits.'
            }
          </Typography>
        </Grid>
        <Grid
          item
          md={12}
          className={`${classes.mt3} ${classes.pb4} ${classes.textCenter}`}
        >
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => {
              settransferInitiated(false);
            }}
          >
            OK
          </RACButton>
        </Grid>
      </div>
    );
  };
  const infiniteScrollDEHistory = () => {
    return (
      <Grid
        className={`${customerStyleClasses.fixTableHead} ${customerStyleClasses.fixTableHeight400}`}
      >
        {/* <> */}
        {deHistoryResponse?.length > 0 ? (
          <InfiniteScroll
            dataLength={deHistoryResponse?.length} //This is important field to render the next data
            next={nextscroll}
            hasMore={hasMore}
            height={400}
            loader={
              infinitScrolloader ? (
                <Grid className={customerStyleClasses.GridLoader}>
                  <CircularProgress></CircularProgress>
                </Grid>
              ) : null
            }
          >
            <RACTable
              renderTableHead={renderDEHistoryHeader}
              renderTableContent={renderDEHistoryData}
              stickyHeader
            />
          </InfiniteScroll>
        ) : (
          <RACTable
            renderTableHead={renderDEHistoryHeader}
            renderTableContent={renderDEHistoryData}
          />
        )}
        {/* </> */}
      </Grid>
    );
  };
  const noClick = () => {
    setOpenPinModal(false);
  };

  const pinEnter = (event: any) => {
    // eslint-disable-next-line sonarjs/no-redundant-boolean
    if (event.charCode === 13 && Continue == false) {
      event.preventDefault();
      continueClick();
    }
  };

  const secondfactorEnter = (event: any, username: any, Password: any) => {
    /* eslint-enable no-console */
    if (event.charCode === 13 && userID !== '' && password !== '') {
      event.preventDefault();
      loginClick(username, Password);
    }
  };
  const otpchange = (event: any) => {
    setInvalidPin(200);
    setotp(event);
  };
  const racCoWorkerFunc = async (moduleName: string) => {
    try {
      // eslint-disable-next-line no-console
      console.log('Module Name', moduleName);
      setSecondLevelAuthenOn(moduleName);
    } catch (e: any) {
      // eslint-disable-next-line no-console
      console.log('getprofileaccess payload', e);
    }
  };
  const printHTML = async () => {
    const response = await getPrintDetail();
    setGetStore(response);
    if (response !== undefined) {
      const html = document?.getElementById('printHTML')?.innerHTML;
      // eslint-disable-next-line no-console
      console.log('html', html);
      if (html !== undefined && html !== null) {
        //const divContents = html;
        const a: any = window.open(
          '',
          '',
          'width=1000,height=650,scrollbars=1,menuBar=1'
        );
        a.document.write('<html>');
        a.document.write(html);
        a.document.write('</html>');
        a.document.close();
        a.print();
      }
    }
  };
  const getPrintDetail = async () => {
    setIsLoading(true);
    const response = await PrintFaxSheet(loginStore);
    setIsLoading(false);
    // eslint-disable-next-line no-console
    console.log('pfs', response);
    if (response !== undefined) {
      return response;
    } else {
      return undefined;
    }
  };
  const activateClubPopup = () => {
    return (
      <Grid item md={12}>
        <Grid item md={12} className={classes.textCenter}>
          <Typography className={customerStyleClasses.RACPOPMsg}>
            {'Club will be immediately deactivated if you click "Yes". '}
            {'Club cannot be "Re-activated" without a payment being made.'}
          </Typography>
          <Typography className={customerStyleClasses.RACPOPMsg}>
            {'Are you sure you want to continue?'}
          </Typography>
        </Grid>
        <Grid
          container
          className={`${customerStyleClasses.spacerMT4} ${customerStyleClasses.justifyContentCenter}`}
        >
          <RACButton
            color="primary"
            variant="outlined"
            className={`${customerStyleClasses.me2} ${customerStyleClasses.foc}`}
            onClick={() => hideButtons()}
          >
            No
          </RACButton>
          <RACButton
            variant="contained"
            color="primary"
            onClick={() => activateCLub()}
          >
            Yes
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  // Would you like to Run DE popup PC-CC-26
  function ActivateClubfn() {
    return (
      <RACModalCard
        borderRadius="20px"
        isOpen={activateClub}
        maxWidth="sm"
        closeIcon={true}
        onClose={() => hideButtons()}
        // eslint-disable-next-line react/no-children-prop
        children={activateClubPopup()}
        title="Activate Club"
      />
    );
  }
  const ErrorMsgPopup = () => {
    return (
      <Grid item md={12}>
        <Grid item md={12} className={classes.textCenter}>
          <Typography variant={'h6'} className={customerStyleClasses.RACPOPMsg}>
            {' '}
            {pageType == undefined || pageType == 'customer'
              ? 'Please fill all the mandatory fields in the below listed sections'
              : 'Please fill all the mandatory fields'}
          </Typography>
          {validateResult.map((element: any, index: any) => {
            return (
              <Typography
                className={customerStyleClasses.RACPOPMsg}
                key={index}
              >
                {element}
              </Typography>
            );
          })}
          {IsPrimaryNotSet !== undefined &&
            IsPrimaryNotSet !== null &&
            IsPrimaryNotSet !== '' ? (
            <Typography className={customerStyleClasses.RACPOPMsg}>
              {IsPrimaryNotSet}
            </Typography>
          ) : null}
        </Grid>
        <Grid
          container
          className={`${customerStyleClasses.justifyContentCenter} ${customerStyleClasses.spacerMT4}`}
        >
          <RACButton
            variant="outlined"
            color="primary"
            className={customerStyleClasses.foc}
            onClick={() => hideErrorPopUp()}
          >
            Ok
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  // popup to enter SSn PC-CC-27
  function ErrorMgs() {
    return (
      <RACModalCard
        borderRadius="20px"
        isOpen={errorPopup}
        maxWidth="xs"
        closeIcon={true}
        onClose={() => hideErrorPopUp()}
        // eslint-disable-next-line react/no-children-prop
        children={ErrorMsgPopup()}
      />
    );
  }
  const UpdateInfoMsgPopup = () => {
    return (
      <Grid item md={12}>
        <Grid item md={12} className={classes.textCenter}>
          {cantAccess ?<Alerticon/> :isSuccess ? <SuccessIcon></SuccessIcon> : <Alerticon></Alerticon>}
          {cantAccess ? 
          <>
          <Typography
            variant={'body1'}
            style={{margin:'1px'}}
            className={`${classes.my3} ${customerStyleClasses.RACPOPMsg}`}
          >{'The selected customer is a RAC Coworker. '}</Typography> 
          <Typography
          variant={'body2'}
          style={{marginTop:'1px'}}
          className={`${classes.my3} ${customerStyleClasses.RACPOPMsg}`}
        >{'Only DM ( and above ) can create agreements for RAC Coworkers.'}</Typography> </>: <Typography
            variant={'body2'}
            className={`${classes.my3} ${customerStyleClasses.RACPOPMsg}`}
          >
            {isSuccess
              ? 'Customer information is updated successfully'
              : // eslint-disable-next-line sonarjs/no-duplicate-string
              'Something went wrong'}
          </Typography>}
        </Grid>
        <Grid
          item
          className={`${classes.textCenter} ${customerStyleClasses.mt4} ${customerStyleClasses.w100}`}
        >
          <RACButton
            variant="contained"
            color="primary"
            onClick={() => hideupdatePopUp()}
          >
            Ok
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  const createAgreementPopup = () => {
    return (
      <Grid item md={12}>
        <Grid item md={12} className={classes.textCenter}>
          <Typography
            className={`${customerStyleClasses.font16} ${customerStyleClasses.bold}`}
          >
            The customer record has been created
          </Typography>
        </Grid>
        <Grid item md={12} className={classes.textCenter}>
          <Typography className={customerStyleClasses.RACPOPMsg}>
            Would you like to create a new agreement?
          </Typography>
        </Grid>
        <Grid
          container
          className={`${customerStyleClasses.justifyContentCenter} ${customerStyleClasses.spacerMT4}`}
        >
          <RACButton
            variant="outlined"
            color="primary"
            className={`${customerStyleClasses.me2} ${customerStyleClasses.foc}`}
            onClick={() => props.history.push(`/dashboard`)}
          >
            Not Now
          </RACButton>
          <RACButton
            variant="contained"
            color="primary"
            onClick={() => {
              handleEPPAuthentication('CREATE_AGR');
            }}
          >
            Yes, Create new agreement
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  const COAgreementPopup = () => {
    return (
      <Grid item md={12}>
        <Grid item md={12} className={classes.textCenter}>
          <Typography
            className={`${customerStyleClasses.font16} ${customerStyleClasses.bold}`}
          >
            The customer record has been created
          </Typography>
        </Grid>
        <Grid item md={12} className={classes.textCenter}>
          <Typography className={customerStyleClasses.RACPOPMsg}>
            Would you like to create a Customer order agreement?
          </Typography>
        </Grid>
        <Grid
          container
          className={`${customerStyleClasses.justifyContentCenter} ${customerStyleClasses.spacerMT4}`}
        >
          <RACButton
            variant="outlined"
            color="primary"
            className={`${customerStyleClasses.me2} ${customerStyleClasses.foc}`}
            onClick={() => props.history.push(`/dashboard`)}
          >
            Not Now 
          </RACButton>
          <RACButton
            variant="contained"
            color="primary"
            onClick={() => {
              history.push(`/agreement/customerorder/itemsearch/${customerId}?coCustomerId=N`)
                ;
            }}
          >
            Yes, Create new agreement
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  function CreateAgreementrMsg() {
    return (
      <RACModalCard
        borderRadius="20px"
        isOpen={createAgreementPopUp}
        maxWidth="xs"
        closeIcon={true}
        onClose={() => setcreateAgreementPopUp(false)}
        // eslint-disable-next-line react/no-children-prop
        children={createAgreementPopup()}
        title=""
      />
    );
  }

  function COAgreementrMsg() {
    return (
      <RACModalCard
        borderRadius="20px"
        isOpen={COAgreementPopUp}
        maxWidth="xs"
        closeIcon={true}
        onClose={() => setCOAgreementPopUp(false)}
        // eslint-disable-next-line react/no-children-prop
        children={COAgreementPopup()}
        title=""
      />
    );
  }
  // popup to enter SSn PC-CC-27
  function UpdateInfoMsg() {
    return (
      <RACModalCard
        borderRadius="20px"
        isOpen={updatePopUp}
        maxWidth="xs"
        closeIcon={true}
        onClose={() => hideupdatePopUp()}
        // eslint-disable-next-line react/no-children-prop
        children={UpdateInfoMsgPopup()}
      />
    );
  }
  const liketoRunAgreementPopup = () => {
    return (
      <Grid item md={12}>
        <Grid item md={12} className={classes.textCenter}>
          <Typography className={customerStyleClasses.RACPOPMsg}>
            Would you like to add the Co-Customer for agreement?
          </Typography>
        </Grid>
        <Grid
          container
          className={`${customerStyleClasses.justifyContentCenter} ${customerStyleClasses.spacerMT4}`}
        >
          <RACButton
            variant="contained"
            color="primary"
            className={`${customerStyleClasses.me2} ${customerStyleClasses.foc}`}
            onClick={() => {
              setcreateCoCustomerPopUp(false);
              setrestatementCustomer(false);
              props.history.push(
                (salesLeadIdQuery !== undefined &&
                  salesLeadIdQuery !== null &&
                  salesLeadIdQuery !== '') ||
                  (customerInf?.salesLeadId !== undefined &&
                    customerInf?.salesLeadId !== null &&
                    customerInf?.salesLeadId !== '')
                  ? `/agreement/rental/itemsearch/${customerId}?salesLeadId=${salesLeadIdQuery !== undefined &&
                    salesLeadIdQuery !== null &&
                    salesLeadIdQuery !== ''
                    ? salesLeadIdQuery
                    : customerInf?.salesLeadId
                  }&&coCustomerId=N`
                  : `/agreement/rental/itemsearch/${customerId}?coCustomerId=N`
              );
            }}
          >
            No
          </RACButton>
          <RACButton
            variant="outlined"
            color="primary"
            onClick={() => {
              setrestatementCustomer(false);
              setcreateCoCustomerPopUp(false);
              Setshowcocustomerpopup(true);
            }}
          >
            Yes
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  const instatementPopup = () => {
    return (
      <Grid item md={12}>
        <Grid item md={12} className={classes.textCenter}>
          <Typography className={customerStyleClasses.RACPOPMsg}>
            Is it a Transfer of Reinstatement Rights?
          </Typography>
        </Grid>
        <Grid
          container
          className={`${customerStyleClasses.justifyContentCenter} ${customerStyleClasses.spacerMT4}`}
        >
          <RACButton
            variant="contained"
            color="primary"
            className={customerStyleClasses.me2}
            onClick={() => {
              setrestatementPopUp(false);
              props.history.push(
                `/agreement/reinstate/itemsearch/${customerId}/${checkAction !== undefined &&
                  checkAction !== null &&
                  checkAction !== ''
                  ? checkAction
                  : ''
                }`
              );
            }}
          >
            No
          </RACButton>
          <RACButton
            variant="outlined"
            color="primary"
            className={`${customerStyleClasses.me2} ${customerStyleClasses.foc}`}
            onClick={() => {
              setrestatementPopUp(false);
              setrestatementCustomer(true);
              Setshowcocustomerpopup(true);
              setagrId(checkAction);
            }}
          >
            Yes
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  // Would you like to Run DE popup PC-CC-26
  function LiketoRunAgreementMsg() {
    return (
      <RACModalCard
        borderRadius="20px"
        isOpen={createCoCustomerPopUp}
        maxWidth="xs"
        closeIcon={true}
        onClose={() => setcreateCoCustomerPopUp(false)}
        // eslint-disable-next-line react/no-children-prop
        children={liketoRunAgreementPopup()}
        title=""
      />
    );
  }
  function ReinstatementMsg() {
    return (
      <RACModalCard
        borderRadius="20px"
        isOpen={restatementPopUp}
        maxWidth="xs"
        closeIcon={true}
        onClose={() => setrestatementPopUp(false)}
        // eslint-disable-next-line react/no-children-prop
        children={instatementPopup()}
        title=""
      />
    );
  }

  const onlineAccountWeblinkFailed = () => {
    return (
      <Grid container>
        <Grid style={{ marginLeft: "20px", marginRight: "20px", fontFamily: 'OpenSans-bold', fontSize: "16px" }}>
          Web Link did not occur. Please contact RACPad support or solution center
        </Grid>

        <RACButton style={{ marginTop: "30px", marginLeft: "180px" }}
          variant="contained"
          color="primary"
          id="assignAlertBtn"
          className={customerStyleClasses.mt1}
          onClick={() => { setWebLinkFailed(false) }}
        >
          ok
        </RACButton>

      </Grid>
    )
  }
  const onlineAccount = () => {
    return (
      <Grid container>
        <Grid style={{ marginLeft: "20px", marginRight: "20px", fontFamily: 'OpenSans-bold', fontSize: "16px" }}>
          Web Link did not occur. No e-pay email account found .
          Have Customer create account online.
        </Grid>

        <RACButton style={{ marginTop: "30px", marginLeft: "180px" }}
          variant="contained"
          color="primary"
          id="assignAlertBtn"
          className={customerStyleClasses.mt1}
          onClick={() => { setWebError(false) }}
        >
          ok
        </RACButton>

      </Grid>
    )
  }

  const otherPrimaryAddressAGR = () => {
    return (
      <Grid container>
        <Grid style={{ marginLeft: "20px", marginRight: "20px", fontFamily: 'OpenSans-bold', fontSize: "16px" }}>
          Other Primary Address are associated with agreements. Do you want to change it to new primary address
        </Grid>

        <Grid
          container
          className={`${customerStyleClasses.spacerMT4} ${customerStyleClasses.justifyContentCenter}`}
        >
          <RACButton
            color="primary"
            variant="outlined"
            className={`${customerStyleClasses.me2} ${customerStyleClasses.foc}`}
            onClick={() => { onClickPrimaryAddressSave("No") }}
          >
            No
          </RACButton>
          <RACButton
            variant="contained"
            color="primary"
            onClick={() => { onClickPrimaryAddressSave("Yes") }}
          >
            Yes
          </RACButton>
        </Grid>


      </Grid>
    )
  }
  const webLinkSuccess = () => {
    return (
      <Grid container>
        <SuccessIcon style={{ marginLeft: "180px", marginBottom: "10px" }}></SuccessIcon>
        <Grid style={{ fontFamily: 'OpenSans-bold', fontSize: "15px" }}>
          Email address successfully linked with online account.
        </Grid>
        <RACButton style={{ marginTop: "20px", marginLeft: "180px" }}
          variant="contained"
          color="primary"
          id="assignAlertBtn"
          className={customerStyleClasses.mt1}
          onClick={() => { setWebLinked(false) }}
        >
          ok
        </RACButton>

      </Grid>
    )
  }
  const webLinkEmailUpdation = () => {
    return (
      <Grid container>
        <SuccessIcon style={{ marginLeft: "180px", marginBottom: "10px" }}></SuccessIcon>
        <Grid style={{ fontFamily: 'OpenSans-bold', fontSize: "16px", marginLeft: "20px" }}>
          Customer Information is updated successfully and email address linked with email account.
        </Grid>
        <RACButton style={{ marginTop: "20px", marginLeft: "180px" }}
          variant="contained"
          color="primary"
          id="assignAlertBtn"
          className={customerStyleClasses.mt1}
          onClick={() => { setWebLinkEmail(false) }}
        >
          ok
        </RACButton>

      </Grid>
    )
  }

  const primaryAddresspopupok = () => {
    setPrimaryAddressUpdated(false)
    window.location.reload()
  }

  const primaryAddressupdatedSuccessfully = () => {
    return (
      <Grid container>
        <Grid style={{ marginLeft: "15px", marginRight: "15px", fontFamily: 'OpenSans-bold', fontSize: "16px" }}>
          Primary Address is updated successfully
        </Grid>
        <RACButton style={{ marginTop: "20px", marginLeft: "180px" }}
          variant="contained"
          color="primary"
          id="assignAlertBtn"
          className={customerStyleClasses.mt1}
          onClick={() => { primaryAddresspopupok() }}
        >
          ok
        </RACButton>


      </Grid>
    )
  }

  const technicalIssue = () => {
    return (
      <Grid container>
        <Grid style={{ marginLeft: "15px", marginRight: "15px", fontFamily: 'OpenSans-bold', fontSize: "16px" }}>
          Customer Information is updated successfully.
        </Grid>
        <Grid style={{ marginLeft: "10px", marginRight: "10px", fontFamily: 'OpenSans-bold', fontSize: "16px", marginTop: "20px" }}>
          Due to technical issue,failed to update email address.
          Please contact the RACPad Support or Solution Center.
        </Grid>
        <RACButton style={{ marginTop: "20px", marginLeft: "180px" }}
          variant="contained"
          color="primary"
          id="assignAlertBtn"
          className={customerStyleClasses.mt1}
          onClick={() => { setEcomSupport(false) }}
        >
          ok
        </RACButton>


      </Grid>
    )
  }
  const cancelWithoutSavingtPopup = () => {
    return (
      <Grid item md={12}>
        <Grid item md={12} className={classes.textCenter}>
          <Typography className={customerStyleClasses.RACPOPMsg}>
            Cancel without saving?
          </Typography>
        </Grid>
        <Grid
          container
          className={`${customerStyleClasses.justifyContentCenter} ${customerStyleClasses.spacerMT4}`}
        >
          <RACButton
            variant="outlined"
            color="primary"
            className={`${customerStyleClasses.me2} ${customerStyleClasses.foc}`}
            onClick={() => {
              setcustomerInfoChangePopUp(false);
            }}
          >
            No
          </RACButton>
          <RACButton
            variant="contained"
            color="primary"
            onClick={() => {
              props.history.push(`/dashboard`);
            }}
          >
            Yes
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  // Would you like to Run DE popup PC-CC-26
  function CancelWithoutSavingtMsg() {
    return (
      <RACModalCard
        borderRadius="20px"
        isOpen={customerInfoChangePopUp}
        maxWidth="xs"
        closeIcon={true}
        onClose={() => setcustomerInfoChangePopUp(false)}
        // eslint-disable-next-line react/no-children-prop
        children={cancelWithoutSavingtPopup()}
        title=""
      />
    );
  }

  const AlertPopUp = () => {
    return (
      <Grid container>
        <Grid
          item
          className={`${customerStyleClasses.floatLeft} ${customerStyleClasses.w100}`}
        >
          {Assignalert()}
          {Enabletxt !== true ? (
            <RACTextbox
              isCurrency={false}
              //hidden={Enabletxt}
              id="CustomAlert"
              value={CustomAlerttxt}
              OnChange={(e: any) => CustomAlertval(e)}
            />
          ) : null}
        </Grid>
        {objAlertdata.length > 0 ? (
          <Grid item md={12} className={customerStyleClasses.mt4}>
            <Grid className={customerStyleClasses.floatRight}>
              <RACButton
                variant="outlined"
                color="primary"
                className={`${classes.mx1} ${customerStyleClasses.foc}`}
                onClick={() => Cancelclick()}
              >
                Cancel
              </RACButton>

              <RACButton
                disabled={
                  (activeAlert.length == 0 && AlertUpdateObj.length == 0) ||
                    disableAlert
                    ? true
                    : false
                }
                variant="contained"
                color="primary"
                className={classes.mx1}
                onClick={() => {
                  Assignsave_click();
                  closeAlert();
                }}
              >
                Save
              </RACButton>
            </Grid>
          </Grid>
        ) : null}
      </Grid>
    );
  };
  // function AlertModelMsg() {
  //   return (
  //     <RACModalCard
  //       isOpen={AlertIsOpen}
  //       maxWidth="xs"
  //       title="Customer Alerts"
  //       onClose={() => closeAlert()}
  //       closeIcon={true}
  //       // eslint-disable-next-line react/no-children-prop
  //       children={AlertPopUp()}
  //     />
  //   );
  // }
  const amCustomerRedirect = () => {
    // if (customerId !== undefined) {
    //   props.history.push(`${AppRoute.accountManagementCustomer}/${customerId}`);
    // }
    // eslint-disable-next-line no-console
    console.log(history?.location?.state);
  };
  const activateClubObjPopup = () => {
    return (
      <Grid container>
        <Grid item className={classes.racCol12}>
          <Grid container spacing={2} className={customerStyleClasses.mb3}>
            <Grid item className={classes.racCol6}>
              <label
                htmlFor="a11y_Member"
                className={customerStyleClasses.formLabel}
              >
                Member Id
              </label>
            </Grid>
            <Grid className={classes.racCol6}>
              <RACTextbox
                isCurrency={false}
                type={'text'}
                OnChange={(e: any) => {
                  handleChangeForAddReferenceInfo(e);
                }}
                disabled={true}
                value={
                  customerCoverageInf !== undefined &&
                    customerCoverageInf?.membershipNumber !== undefined
                    ? customerCoverageInf?.membershipNumber
                    : ''
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} className={customerStyleClasses.mb3}>
            <Grid item className={classes.racCol6}>
              <label
                htmlFor="a11y_Benefits"
                className={customerStyleClasses.formLabel}
              >
                Level Of Benefits
              </label>
            </Grid>
            <Grid className={classes.racCol6}>
              <RACTextbox
                isCurrency={false}
                type={'text'}
                OnChange={(e: any) => {
                  handleChangeForAddReferenceInfo(e);
                }}
                disabled={true}
                value={
                  customerCoverageInf !== undefined &&
                    customerCoverageInf?.clubName !== undefined
                    ? customerCoverageInf?.clubName
                    : ''
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} className={customerStyleClasses.mb3}>
            <Grid item className={classes.racCol6}>
              <label
                htmlFor="a11y_Start"
                className={customerStyleClasses.formLabel}
              >
                Start date
              </label>
            </Grid>
            <Grid className={classes.racCol6}>
              <RACTextbox
                isCurrency={false}
                type={'text'}
                OnChange={(e: any) => {
                  handleChangeForAddReferenceInfo(e);
                }}
                disabled={true}
                value={
                  customerCoverageInf !== undefined &&
                    customerCoverageInf?.startDate !== undefined
                    ? customerCoverageInf?.startDate
                    : ''
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} className={customerStyleClasses.mb3}>
            <Grid item className={classes.racCol6}>
              <label
                htmlFor="a11y_Date"
                className={customerStyleClasses.formLabel}
              >
                Next Fee Date
              </label>
            </Grid>
            <Grid className={classes.racCol6}>
              <RACTextbox
                isCurrency={false}
                type={'text'}
                OnChange={(e: any) => {
                  handleChangeForAddReferenceInfo(e);
                }}
                disabled={true}
                value={
                  customerCoverageInf !== undefined &&
                    customerCoverageInf?.nextDueDate !== undefined
                    ? customerCoverageInf?.nextDueDate
                    : ''
                }
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} className={customerStyleClasses.mb3}>
            <Grid item className={classes.racCol6}>
              <label
                htmlFor="a11y_Frequency"
                className={customerStyleClasses.formLabel}
              >
                Payment Frequency
              </label>
            </Grid>
            <Grid className={classes.racCol6}>
              <RACTextbox
                isCurrency={false}
                type={'text'}
                OnChange={(e: any) => {
                  handleChangeForAddReferenceInfo(e);
                }}
                disabled={true}
                value={
                  customerCoverageInf !== undefined &&
                    customerCoverageInf?.paymentSchedule !== undefined
                    ? customerCoverageInf?.paymentSchedule
                    : ''
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} className={customerStyleClasses.mb3}>
            <Grid item className={classes.racCol6}>
              <label
                htmlFor="a11y_Fee"
                className={customerStyleClasses.formLabel}
              >
                Fee
              </label>
            </Grid>
            <Grid className={classes.racCol6}>
              <RACTextbox
                isCurrency={false}
                type={'text'}
                OnChange={(e: any) => {
                  handleChangeForAddReferenceInfo(e);
                }}
                disabled={true}
                value={
                  customerCoverageInf !== undefined &&
                    customerCoverageInf?.clubAmount !== undefined
                    ? '$ ' + customerCoverageInf?.clubAmount
                    : ''
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          className={`${customerStyleClasses.w100} ${customerStyleClasses.textRight} ${customerStyleClasses.floatLeft} ${customerStyleClasses.mb3}`}
        >
          <RACButton
            variant="outlined"
            color="primary"
            className={`${customerStyleClasses.me2} ${customerStyleClasses.foc}`}
            onClick={() => {
              setactivateClubObj(false);
              setactivateClub(true);
            }}
          >
            Deactivate
          </RACButton>

          <RACButton
            variant="contained"
            color="primary"
            onClick={() => setactivateClubObj(false)}
          >
            Ok
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  function ActivateClubObjMsg() {
    return (
      <RACModalCard
        borderRadius="20px"
        isOpen={activateClubObj}
        maxWidth="xs"
        title=""
        onClose={() => setactivateClubObj(false)}
        closeIcon={true}
        // eslint-disable-next-line react/no-children-prop
        children={activateClubObjPopup()}
      />
    );
  }

  const addCoCustomerChildrenPopup = () => {
    return (
      <AddCoCustomer
        customerId={customerId}
        handleClosePopUp={(action: boolean) => {
          if (restatementCustomer !== true) {
            Setshowcocustomerpopup(action);
            setcreateCoCustomerPopUp(true);
          } else {
            setrestatementPopUp(true);
            Setshowcocustomerpopup(action);
          }
          // Setshowcocustomerpopup(action);
          // if (action === false) {
          //   setcreateCoCustomerPopUp(true);
          // }
        }}
        showCustomerInfo={(customerId: string) => {
          props.history.push(`/customer/update/${customerId}/customer`);
        }}
        history={props.history}
        salesLeadIdQuery={salesLeadIdQuery}
        modelNumberQueryParams={modelNumberQueryParams}
        styles={customerStyles}
        exceptionSt={customerInformation}
        reinstatementConsition={restatementCustomer}
        agreementId={
          checkAction !== undefined &&
            checkAction !== null &&
            checkAction !== ''
            ? checkAction
            : ''
        }
      ></AddCoCustomer>
    );
  };
  function AddCoCustomerMsg() {
    return (
      <RACModalCard
        borderRadius="20px"
        isOpen={showcocustomerpopup}
        maxWidth="lg"
        closeIcon={true}
        onClose={() => {
          // eslint-disable-next-line no-console
          console.log('checkAction', checkAction);
          if (restatementCustomer !== true) {
            Setshowcocustomerpopup(false);
            setcreateCoCustomerPopUp(true);
          } else {
            setrestatementPopUp(true);
            Setshowcocustomerpopup(false);
          }
        }}
        // eslint-disable-next-line react/no-children-prop
        children={addCoCustomerChildrenPopup()}
        title={
          restatementCustomer === true
            ? 'Customer Information'
            : 'Co-Customer Information'
        }
      />
    );
  }
  const comingsoonpopUp = () => {
    return (
      <>
        <Grid className={`${classes.infoTextStyle}`}>
          <Typography>Coming Soon!</Typography>
        </Grid>
        <Grid
          container
          item
          xl={12}
          className={`${classes.px2} ${classes.pt2}`}
        >
          <CardContent className={`${classes.infoTextStyle} ${classes.mb5}`}>
            <Typography>
              {coWorkerRoleForCSpopup == 1
                ? 'Selected customer is a RAC employee. Only a DM (and above) can create an employee discounted Agreement'
                : coWorkerRoleForCSpopup == 2
                  ? 'Selected customer is a RAC employee, please use SIMS for creating discounted agreement'
                  : ''}
            </Typography>
          </CardContent>
        </Grid>
      </>
    );
  };
  function ComingSoonMsg() {
    return (
      <RACModalCard
        borderRadius="20px"
        isOpen={comingSoonPopup}
        maxWidth="xs"
        closeIcon={true}
        onClose={() => setcomingSoonPopup(false)}
        // eslint-disable-next-line react/no-children-prop
        children={comingsoonpopUp()}
        title=""
      />
    );
  }
  const validategovtTypeId = (obj: any, ref: any) => {
    if (obj && obj == ref) {
      return true
    } else {
      return false
    }
  }
  const isPrimarySameAsMailing = (address: any) => {
    const primAddress = address?.find((el: any) => el.addressType == 'PRIM')
    const mailAddress = address?.find((el: any) => el.addressType == 'MAIL')
    if (mailAddress && Object.keys(mailAddress).length == 0) {
      return false
    }
    if (primAddress && Object.keys(primAddress).length == 0) {
      return false
    }
    if (primAddress?.addressLine1 == mailAddress?.addressLine1 &&
      primAddress?.addressLine2 == mailAddress?.addressLine2 &&
      primAddress?.city == mailAddress?.city &&
      primAddress?.postalCode == mailAddress?.postalCode &&
      primAddress?.state == mailAddress?.state) {
      return true
    }
    return false
  }



  const getName = (ln: any, fn: any) => {
    ln = ln ? ln : ''
    fn = fn ? fn : ''
    return `${ln} ${fn}`
  }

  const printOrderForm = async () => {
    let cocustomerDetail: any = coCustomerDetail

    // eslint-disable-next-line no-console
    console.log('customerInformation', customerInformation)
    // eslint-disable-next-line no-console
    console.log('cocustomerInformation', cocustomerDetail)
    if ((customerInformation.coCustomerId && !coCustomerDetail?.customerId) || (customerInformation.coCustomerId && coCustomerDetail?.customerId !== customerInformation.coCustomerId)) {
      setIsLoading(true)
      cocustomerDetail = await GetCustomerByIdInfo(customerInformation.coCustomerId, sessionStorage.getItem('storeNumber'))
      if (cocustomerDetail?.GetCustomer?.value) {
        cocustomerDetail = cocustomerDetail?.GetCustomer?.value
      }
      setcoCustomerDetail(cocustomerDetail)
      setIsLoading(false)
    }

    /*
    phoneNumberProps: any, 
    customerInformation:any,
    editInputBox:any,
    EmpProps:any,
    cocustomerDetail:any,
    isGovtNumOnFocus: any,
    Residences: any,
    ReferenceProps:any,
    updatePrimaryAddress:any
    */
    const html = generateOrderForm(phoneNumberProps, customerInformation, editInputBox,
      EmpProps, cocustomerDetail, isGovtNumOnFocus, Residences, ReferenceProps, updatePrimaryAddress);


    const a: any = window.open(
      '',
      '',
      'width=1600,height=650,scrollbars=1,menuBar=1'
    );
    a.document.write(html);
    a.document.close();
    a.print();
  }

  //Exchange Indication
  function validateForExchange(agreementStatus: any, closeDate: any, currentDueDate: any, agreementOpenDate: any, isEppAgreement: any, agreementType: any) {
    if (!isExchangeEnabled || isEppAgreement) {
      return false;
    }
    const today = moment().format('YYYY-MM-DD');
    let sevenDaysAgo = moment(today, 'YYYY-MM-DD').subtract(7, 'days');
    if (returnPeriod) {
      sevenDaysAgo = moment(today, 'YYYY-MM-DD').subtract(returnPeriod, 'days');
    }
    let sixMonthsLater = agreementOpenDate && moment(agreementOpenDate, 'YYYY-MM-DD').add(6, 'months').format('YYYY-MM-DD');
    if (exchangePeriod) {
      sixMonthsLater = agreementOpenDate && moment(agreementOpenDate, 'YYYY-MM-DD').add(exchangePeriod, 'months').format('YYYY-MM-DD');
    }
    if ((closeDate && (moment(closeDate).format('YYYY-MM-DD') >= sevenDaysAgo.format('YYYY-MM-DD'))) && (agreementStatus == 'Agreement Returned To Store (Repossession)')) {
      return true;
    }
    if ((agreementStatus === 'Active' && agreementType == 'Rental Agreement (RTO)')) {
      if (currentDueDate && moment(currentDueDate).format('YYYY-MM-DD') >= today) {
        // const sixMonthsLater = agreementOpenDate && moment(agreementOpenDate, 'YYYY-MM-DD').add(6, 'months').format('YYYY-MM-DD');
        if (sixMonthsLater && today <= sixMonthsLater) {
          return true;
        }
      }
    }
    return false;
  }
  return (
    <Grid container>
      <ActivateClubfn />
      <ErrorMgs />
      <UpdateInfoMsg />
      <LiketoRunAgreementMsg />
      <CancelWithoutSavingtMsg />
      <ValidationMessageMsg />
      {/* <AlertModelMsg /> */}
      <ActivateClubObjMsg />
      <AddCoCustomerMsg />
      <CreateAgreementrMsg />
      <COAgreementrMsg />
      <ComingSoonMsg />
      <ReinstatementMsg />
      {/* <AssignALertfn /> */}
      {/* {!isLoading ? ( */}
      <RACModalCard
        borderRadius="20px"
        isOpen={tcpaPopup}
        maxWidth="xs"
        title="Text Message Opt-lns"
        closeIcon={true}
        onClose={() => setTcpaPopup(false)}
        // eslint-disable-next-line react/no-children-prop
        children={renderTcpaContent(doupateCust)}
      />

      <RACModalCard
        borderRadius="20px"
        isOpen={primAddPopUp}
        maxWidth="md"
        title=""
        closeIcon={true}
        onClose={() => redirectionToCustomersearch()}
        // eslint-disable-next-line react/no-children-prop
        children={renderPrimaryAddress()}
      />

      <RACModalCard
        borderRadius="20px"
        isOpen={activePopUp}
        maxWidth="xs"
        title=""
        closeIcon={true}
        onClose={() => setActivePopUp(false)}
        // eslint-disable-next-line react/no-children-prop
        children={handleIsActPopUp1(sendId)}
      />

      <RACModalCard
        borderRadius="20px"
        isOpen={activePopUp11}
        maxWidth="xs"
        title=""
        closeIcon={true}
        onClose={() => setActivePopUp11(false)}
        // eslint-disable-next-line react/no-children-prop
        children={handleIsActPopUp11()}
      />

      <RACModalCard
        borderRadius="20px"
        isOpen={inActivePopUp1}
        maxWidth="xs"
        title=""
        closeIcon={true}
        onClose={() => setInActivePopUp1(false)}
        // eslint-disable-next-line react/no-children-prop
        children={handleInActivePopUp1(sendId)}
      />

      <RACModalCard
        borderRadius="20px"
        isOpen={inActivePopUp}
        maxWidth="xs"
        title=""
        closeIcon={true}
        onClose={() => isActCancelBtn(phNumIndex)}
        // eslint-disable-next-line react/no-children-prop
        children={handleIsActPopUp2()}
      />

      <RACModalCard
        borderRadius="20px"
        isOpen={inActConDet}
        maxWidth="md"
        title="Inactive Phone Numbers"
        closeIcon={true}
        onClose={() => inActivePopUpCancelBtn()}
        // eslint-disable-next-line react/no-children-prop
        children={handleInActPhNum()}
      />

      <RACModalCard
        borderRadius="20px"
        isOpen={addressHistoryPopUp}
        maxWidth="lg"
        title="Address History"
        closeIcon={true}   // (Pseudo Num: PC_AH_05) when user clicking on the close icon popup will be closed.
        onClose={() => setAddressHistoryPopUp(false)}
        // eslint-disable-next-line react/no-children-prop
        children={renderAddressHistory()}
      />

      <RACModalCard
        borderRadius="20px"
        isOpen={AlertIsOpen}
        maxWidth="xs"
        title="Customer Alerts"
        onClose={() => {
          Cancelclick();
          closeAlert();
        }}
        closeIcon={true}
        // eslint-disable-next-line react/no-children-prop
        children={AlertPopUp()}
      />
      <RACModalCard
        borderRadius="20px"
        isOpen={webError}
        maxWidth="xs"
        title=""
        onClose={() => {
          setWebError(false)
        }}
        closeIcon={true}
        // eslint-disable-next-line react/no-children-prop
        children={onlineAccount()}
      />

      <RACModalCard
        borderRadius="20px"
        isOpen={multipleAgreementPrimaryAddress}
        maxWidth="xs"
        title=""
        onClose={() => {
          setMultipleAgreementPrimaryAddress(false)
        }}
        closeIcon={true}
        // eslint-disable-next-line react/no-children-prop
        children={(otherPrimaryAddressAGR())}
      />
      <RACModalCard
        borderRadius="20px"
        isOpen={webLinkFailed}
        maxWidth="xs"
        title=""
        onClose={() => {
          setWebError(false)
        }}
        closeIcon={true}
        // eslint-disable-next-line react/no-children-prop
        children={onlineAccountWeblinkFailed()}
      />
      <RACModalCard
        borderRadius="20px"
        isOpen={ecomSupport}
        maxWidth="xs"
        title=""
        onClose={() => {
          setEcomSupport(false)
        }}
        closeIcon={true}
        // eslint-disable-next-line react/no-children-prop
        children={technicalIssue()}
      />
      <RACModalCard
        borderRadius="20px"
        isOpen={primaryAddressUpdated}
        maxWidth="xs"
        title=""
        onClose={() => {
          setPrimaryAddressUpdated(false)
        }}
        closeIcon={true}
        // eslint-disable-next-line react/no-children-prop
        children={primaryAddressupdatedSuccessfully()}
      />

      <RACModalCard
        borderRadius="20px"
        isOpen={webLinked}
        maxWidth="xs"
        title=""
        onClose={() => {
          setWebLinked(false)
        }}
        closeIcon={true}
        // eslint-disable-next-line react/no-children-prop
        children={webLinkSuccess()}
      />
      <RACModalCard
        borderRadius="20px"
        isOpen={webLinkemail}
        maxWidth="xs"
        title=""
        onClose={() => {
          setWebLinkEmail(false)
        }}
        closeIcon={true}
        // eslint-disable-next-line react/no-children-prop
        children={webLinkEmailUpdation()}
      />
      <RACModalCard
        borderRadius="20px"
        isOpen={transferInitiated}
        maxWidth="xs"
        onClose={() => settransferInitiated(false)}
        closeIcon={true}
        // eslint-disable-next-line react/no-children-prop
        children={TransferInitiatedPopup()}
      />
      <RACModalCard
        isOpen={splitBlockOpen}
        closeIcon={false}
        maxWidth="xs"
        borderRadius={'20px !important'}
      >
        {splitBlockPopup()}
      </RACModalCard>
      <Modal
        isOpen={isLoading}
        id="openpopup"
        className={customerStyleClasses.loaderStyle}
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <div>
          <div className={customerStyleClasses.RACLoaderPage}>
            <CircularProgress />
            {createAgreementClick ? (
              <Typography
                variant="h4"
                className={`${customerStyleClasses.textCenter} ${customerStyleClasses.font16} ${customerStyleClasses.bold}`}
              >
                Saving the customer information.
              </Typography>
            ) : (
              ''
            )}
          </div>
        </div>
      </Modal>
      <React.Fragment>
        {pageType == undefined || pageType == 'customer' ? (
          <Grid className={classes.parentclass}>
            <Grid item md={12} className={classes.flexratio}>
              <List className={`${classes.breadcrumb} ${classes.py1}`}>
                <ListItem
                  className={`${classes.breadcrumbItemActive} ${classes.breadCrumbMarg}`}
                >
                  <a
                    style={{ cursor: 'pointer' }}
                    onClick={() => props.history.push(`/dashboard`)}
                  >
                    Dashboard
                  </a>
                </ListItem>
                <ListItem
                  className={`${classes.breadcrumbItem} ${classes.breadcrumbArrow} ${classes.racpadLinkCustomer}`}
                >{featureFlagDetails?.CustomerRewards == '1' && customerInformation?.firstName && totalRentalRevenueAmount && Number(totalRentalRevenueAmount) >= Number(featureFlagDetails.RewardsEligibilityAmount) ? 
                <>
                  <div style={{ position: 'relative', display: 'inline-block' }}>
                    <span
                      onMouseOver={() => {
                        setShowTooltip(true);
                      }}
                      onMouseOut={() => {
                        setShowTooltip(false);
                      }}
                      style={{
                        backgroundColor: '#198754',
                        color: 'white',
                        padding: '1px 7px',
                        borderRadius: '11px',
                        fontSize: '12px',
                        cursor: 'pointer'
                      }}
                    >
                      {customerInformation?.firstName +
                        ' ' +
                        customerInformation?.lastName}
                    </span>
                    {showTooltip && (
                      <div style={{
                        position: 'absolute',
                        bottom: '100%',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        marginBottom: '5px',
                        backgroundColor: '#e9f1f6',
                        color: 'black',
                        padding: '5px',
                        borderRadius: '10px',
                        fontSize: '12px',
                        whiteSpace: 'nowrap',
                        zIndex: 99999
                      }}>
                        Eligible for RAC Select Rewards
                      </div>
                    )}
                  </div>
                </>
                  : customerInformation?.firstName
                    ? customerInformation?.firstName +
                    ' ' +
                    customerInformation?.lastName
                    : ''
                }
                {' '}
                  - {customerId}
                </ListItem>
              </List>
              <Grid className={classes.overall}>
                {/* <Grid className={`${classes.floatLeft} ${classes.me3} `}> */}
                {DEStatus?.approvalStatus !== DECISON_ENGINE_EXPIRED ?
                  <Typography className={classes.agrInfoPanelviewagr}>
                    <Typography variant="caption" className={classes.approvalAmt}>
                      Approval Amount :
                    </Typography>
                    <Typography
                      variant="caption"
                      className={`${classes.racpadLink} ${classes.check}`}
                    >
                      $
                      {' ' + DEStatus &&
                        DEStatus?.originalApprovalAmount &&
                        DEStatus?.originalApprovalAmount != null
                        ? parseFloat(DEStatus?.originalApprovalAmount)
                          .toFixed(2)
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        : '0.00'}
                    </Typography>
                  </Typography> : null}
                {/* </Grid> */}
                {/* <Grid className={classes.over1}> */}
                {
                  DEStatus?.approvalStatus !== DECISON_ENGINE_EXPIRED ? <Typography className={classes.agrInfoPanelviewagr}>
                    <Typography variant="caption" className={classes.approvalAmt}>
                      Weekly Approval Amount :
                    </Typography>
                    <Typography
                      variant="caption"
                      className={`${classes.racpadLink} ${classes.check}`}
                    >
                      $
                      {' ' + DEStatus &&
                        DEStatus?.weeklyApprovalAmount &&
                        DEStatus?.weeklyApprovalAmount != null
                        ? parseFloat(DEStatus?.weeklyApprovalAmount)
                          .toFixed(2)
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        : '0.00'}
                    </Typography>
                  </Typography> : null}
                {/* </Grid> */}
                {pageType == undefined || pageType == 'customer' ? (
                  <React.Fragment>
                    {DEStatus?.approvalStatus !== DECISION_ENGINE_APPROVED &&
                      buildVerification()}
                  </React.Fragment>
                ) : null}
                {DEStatus !== undefined &&
                  DEStatus?.approvalStatus == DECISION_ENGINE_APPROVED ? (
                  <Grid
                    item
                    className={`${customerStyleClasses.floatLeft} ${classes.textCenter} ${customerStyleClasses.me2}`}
                  >
                    <Box
                      component="span"
                      className={`${classes.badge} ${classes.badgeGreen}`}
                    >
                      {DEStatus?.approvalStatus}
                    </Box>
                    <Box component="span" className={classes.racDEDate}>
                      Expires on{' '}
                      {buildDateFormat2(DEStatus?.approvalExpirationDate)}
                    </Box>
                  </Grid>
                ) : DEStatus?.approvalStatus == DECISON_ENGINE_DECLINED ? (
                  <Grid
                    item
                    className={`${customerStyleClasses.floatRight} ${classes.textCenter} ${customerStyleClasses.mt1}`}
                  >
                    <Box
                      component="span"
                      className={`${classes.badge} ${classes.badgeRed}`}
                    >
                      {DEStatus?.approvalStatus}
                    </Box>
                  </Grid>
                ) : DEStatus?.approvalStatus == DECISION_ENGINE_PREAPPROVED ? (
                  <Grid
                    item
                    className={`${customerStyleClasses.floatRight} ${classes.textCenter} ${customerStyleClasses.mt1}`}
                  >
                    <Box
                      component="span"
                      className={`${classes.badge} ${classes.badgeOrange}`}
                    >
                      {DEStatus?.approvalStatus}
                    </Box>
                    <Box component="span" className={classes.racDEDate}>
                      Expires on{' '}
                      {buildDateFormat2(DEStatus?.approvalExpirationDate)}
                    </Box>
                  </Grid>
                ) : DEStatus?.approvalStatus !== undefined &&
                  DEStatus?.approvalStatus !== null &&
                  DEStatus?.approvalStatus !== '' ? (
                  <Grid
                    item
                    className={`${customerStyleClasses.floatRight} ${classes.textCenter} ${customerStyleClasses.mt1}`}
                  >
                    <Box
                      component="span"
                      className={`${classes.badge} ${classes.badgeRed}`}
                    >
                      {DEStatus?.approvalStatus}
                    </Box>
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
        ) : null}

        <Grid container className={customerStyleClasses.mb3}>
          <Card
            className={`${customerStyleClasses.card} ${customerStyleClasses.w100} ${customerStyleClasses.mb3} ${customerStyleClasses.borderRadiusZero}`}
          >
            <CardContent
              className={`${classes.headerTab} ${customerStyleClasses.floatLeft} ${customerStyleClasses.w100} ${classes.py0}`}
            >
              <Grid
                item
                className={`${customerStyleClasses.floatLeft} ${customerStyleClasses.w100}`}
              >
                {pageType == undefined || pageType == 'customer' ? (
                  <Box className={customerStyleClasses.floatLeft}>
                    <List
                      className={`${classes.racpadTab} ${classes.py0}`}
                      id="CustTab"
                      role="tabList"
                    >
                      <ListItem
                        className={
                          activetab == 0
                            ? `${classes.navLinkActive} ${classes.navLinkItem}`
                            : `${classes.navLinkItem} ${classes.p3}`
                        }
                        id="Customerinfo-tab"
                        data-testid="customerInfoTab"
                        data-bs-toggle="tab"
                        data-bs-target="#customerinfo"
                        role="tab"
                        aria-controls="Customerinfo-tab"
                        aria-selected="true"
                        onClick={() => setactivetab(tabList.CUSTOMERINFO)}
                      >
                        Customer Information
                      </ListItem>
                      <ListItem
                        className={
                          activetab == 1
                            ? `${classes.navLinkActive} ${classes.navLinkItem}`
                            : `${classes.navLinkItem} ${classes.p3}`
                        }
                        id="employment-tab"
                        data-testid="employmentInfoTab"
                        data-bs-toggle="tab"
                        data-bs-target="#employmentinfo"
                        role="tab"
                        aria-controls="employment-tab"
                        aria-selected="false"
                        onClick={() => setactivetab(tabList.EMPLOYMENTINFO)}
                      >
                        Employment Information
                      </ListItem>
                      <ListItem
                        className={
                          activetab == 2
                            ? `${classes.navLinkActive} ${classes.navLinkItem}`
                            : `${classes.navLinkItem} ${classes.p3}`
                        }
                        id="employment-tab"
                        data-testid="residenceTab"
                        data-bs-toggle="tab"
                        data-bs-target="#employmentinfo"
                        role="tab"
                        aria-controls="employment-tab"
                        aria-selected="false"
                        onClick={() => setactivetab(tabList.RESIDENCE)}
                      >
                        Residence
                      </ListItem>
                      <ListItem
                        className={
                          activetab == 3
                            ? `${classes.navLinkActive} ${classes.navLinkItem}`
                            : `${classes.navLinkItem} ${classes.p3}`
                        }
                        id="employment-tab"
                        data-testid="referenceTab"
                        data-bs-toggle="tab"
                        data-bs-target="#employmentinfo"
                        role="tab"
                        aria-controls="employment-tab"
                        aria-selected="false"
                        onClick={() => setactivetab(tabList.REFERENCE)}
                      >
                        Reference
                      </ListItem>
                      <ListItem
                        className={
                          activetab == 4
                            ? `${classes.navLinkActive} ${classes.navLinkItem}`
                            : `${classes.navLinkItem} ${classes.p3}`
                        }
                        id="employment-tab"
                        data-bs-toggle="tab"
                        data-testid="vehicleInfoTab"
                        data-bs-target="#employmentinfo"
                        role="tab"
                        aria-controls="employment-tab"
                        aria-selected="false"
                        onClick={() => setactivetab(tabList.VEHICLEINFO)}
                      >
                        Vehicle Information
                      </ListItem>
                      <ListItem
                        className={
                          activetab == 5
                            ? `${classes.navLinkActive} ${classes.navLinkItem}`
                            : `${classes.navLinkItem} ${classes.p3}`
                        }
                        id="employment-tab"
                        data-bs-toggle="tab"
                        data-testid="agreementHistoryTab"
                        data-bs-target="#employmentinfo"
                        role="tab"
                        aria-controls="employment-tab"
                        aria-selected="false"
                        onClick={() => {
                          getAgreement();
                          setactivetab(tabList.AGREEMENTHISTORY);
                        }}
                      >
                        Agreement History
                      </ListItem>
                      <ListItem
                        className={
                          activetab == 6
                            ? `${classes.navLinkActive} ${classes.navLinkItem}`
                            : `${classes.navLinkItem} ${classes.p3}`
                        }
                        id="employment-tab"
                        data-bs-toggle="tab"
                        data-testid="agreementHistoryTab"
                        data-bs-target="#employmentinfo"
                        role="tab"
                        aria-controls="employment-tab"
                        aria-selected="false"
                        onClick={() => {
                          getDEHistory();
                          setactivetab(tabList.DEHISTORY);
                        }}
                      >
                        DE History
                      </ListItem>
                    </List>
                  </Box>
                ) : (
                  <Grid
                    item
                    id="smartwizard"
                    lg={8}
                    xl={8}
                    className={`${classes.swThemeDefault} ${customerStyleClasses.customerFloatleft}`}
                  >
                    <Grid item className={classes.swThemeDefaultContainer}>
                      <List className={classes.smartWizard}>
                        <ListItem className={classes.smartWizardList}>
                          <a className={classes.smartWizardListLinkActive}>
                            <Box
                              component="span"
                              className={`${classes.borderActive} ${classes.done}`}
                            >
                              <CompletedIcon className={classes.successicon} />
                            </Box>
                            {/* <Box
                              component="span"
                              className={`${classes.borderActive} ${classes.done}`}
                            >
                              1
                            </Box> */}
                            {customerIdQueryParam == null ||
                              customerSrc !== null
                              ? 'Customer Validation'
                              : 'Co-Customer Validation'}
                          </a>
                        </ListItem>
                        <ListItem className={classes.smartWizardList}>
                          <a
                            className={
                              createActiveTab == 0
                                ? `${classes.smartWizardListLinkActive}`
                                : createActiveTab > 0
                                  ? `${classes.smartWizardListLinkActive}`
                                  : inactiveTabClass
                            }
                          >
                            {createActiveTab == 0 ? (
                              <Box
                                component="span"
                                className={classes.borderActive}
                              >
                                2
                              </Box>
                            ) : createActiveTab > 0 ? (
                              <Box
                                component="span"
                                className={`${classes.borderActive} ${classes.done}`}
                              >
                                <CompletedIcon
                                  className={classes.successicon}
                                />
                              </Box>
                            ) : (
                              ''
                            )}
                            {/* <Box
                              component="span"
                              className={`border-value ${
                                createActiveTab == 0
                                  ? `${classes.borderActive}`
                                  : createActiveTab > 0
                                  ? `${classes.borderActive} ${classes.done}`
                                  : ''
                              }`}
                            >
                              2
                            </Box> */}
                            Customer Information
                          </a>
                        </ListItem>
                        <ListItem className={classes.smartWizardList}>
                          <a
                            className={
                              createActiveTab == 1
                                ? `${classes.smartWizardListLinkActive}`
                                : createActiveTab > 1
                                  ? `${classes.smartWizardListLinkActive}`
                                  : `${classes.navInactive}`
                            }
                          >
                            {createActiveTab == 1 ? (
                              <Box
                                component="span"
                                className={classes.borderActive}
                              >
                                3
                              </Box>
                            ) : createActiveTab > 1 ? (
                              <Box
                                component="span"
                                className={`${classes.borderActive} ${classes.done}`}
                              >
                                <CompletedIcon
                                  className={classes.successicon}
                                />
                              </Box>
                            ) : (
                              <Box
                                component="span"
                                className={classes.borderDefault}
                              >
                                3
                              </Box>
                            )}
                            {/* <Box
                              component="span"
                              className={`border-value ${
                                createActiveTab == 1
                                  ? `${classes.borderActive}`
                                  : createActiveTab > 1
                                  ? `${classes.borderActive} ${classes.done}`
                                  : `${classes.borderDefault}`
                              }`}
                            >
                              3
                            </Box> */}
                            Employment Information
                          </a>
                        </ListItem>
                        {DEStatus !== undefined &&
                          DEStatus?.approvalStatus !== undefined &&
                          DEStatus?.approvalStatus != DECISION_ENGINE_APPROVED ? (
                          <React.Fragment>
                            <ListItem className={classes.smartWizardList}>
                              <a
                                className={
                                  createActiveTab == 2
                                    ? `${classes.smartWizardListLinkActive}`
                                    : createActiveTab > 2
                                      ? `${classes.smartWizardListLinkActive}`
                                      : `${classes.navInactive}`
                                }
                              >
                                {createActiveTab == 2 ? (
                                  <Box
                                    component="span"
                                    className={classes.borderActive}
                                  >
                                    4
                                  </Box>
                                ) : createActiveTab > 2 ? (
                                  <Box
                                    component="span"
                                    className={`${classes.borderActive} ${classes.done}`}
                                  >
                                    <CompletedIcon
                                      className={classes.successicon}
                                    />
                                  </Box>
                                ) : (
                                  <Box
                                    component="span"
                                    className={classes.borderDefault}
                                  >
                                    4
                                  </Box>
                                )}
                                {/* <Box
                                  component="span"
                                  className={`border-value ${
                                    createActiveTab == 2
                                      ? `${classes.borderActive}`
                                      : createActiveTab > 2
                                      ? `${classes.borderActive} ${classes.done}`
                                      : `${classes.borderDefault}`
                                  }`}
                                >
                                  4
                                </Box> */}
                                Residence
                              </a>
                            </ListItem>
                            <ListItem className={classes.smartWizardList}>
                              <a
                                className={
                                  createActiveTab == 3
                                    ? `${classes.smartWizardListLinkActive}`
                                    : createActiveTab > 3
                                      ? `${classes.smartWizardListLinkActive}`
                                      : `${classes.navInactive}`
                                }
                              >
                                <Box
                                  component="span"
                                  className={`border-value ${createActiveTab == 3
                                      ? `${classes.borderActive}`
                                      : createActiveTab > 3
                                        ? `${classes.borderActive} ${classes.done}`
                                        : `${classes.borderDefault}`
                                    }`}
                                >
                                  5
                                </Box>
                                Reference
                              </a>
                            </ListItem>
                          </React.Fragment>
                        ) : null}
                      </List>
                    </Grid>
                  </Grid>
                )}
                <Grid
                  item
                  className={`${customerStyleClasses.floatRight} ${customerStyleClasses.mt1} ${customerStyleClasses.ms2}`}
                >
                  {DEStatus !== undefined &&
                    DEStatus?.originalApprovalAmount !== undefined &&
                    DEStatus?.originalApprovalAmount !== null &&
                    ((DEStatus?.approvalStatus == DECISION_ENGINE_APPROVED &&
                      pageType == 'create') ||
                      (pageType == 'create' &&
                        DEStatus?.approvalStatus == DECISION_ENGINE_PREAPPROVED)) ? (
                    <Grid
                      item
                      className={`${customerStyleClasses.floatLeft} ${classes.textCenter} ${classes.mt2}`}
                    >
                      <Box
                        component="span"
                        className={classes.custApprovalLabel}
                      >
                        Approval Amount
                      </Box>
                      <Box
                        component="span"
                        className={classes.custApprovalLabel}
                      >
                        {'$ ' +
                          parseFloat(DEStatus?.originalApprovalAmount)
                            .toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      </Box>
                    </Grid>
                  ) : null}
                  {DEStatus !== undefined &&
                    pageType == 'create' &&
                    DEStatus?.weeklyApprovalAmount !== undefined &&
                    DEStatus?.weeklyApprovalAmount !== null &&
                    (DEStatus?.approvalStatus == DECISION_ENGINE_APPROVED ||
                      DEStatus?.approvalStatus == DECISION_ENGINE_PREAPPROVED) ? (
                    <Grid
                      item
                      className={`${customerStyleClasses.floatLeft} ${classes.textCenter} ${classes.mt2}`}
                    >
                      <Box
                        component="span"
                        className={classes.custApprovalLabel}
                      >
                        Weekly Approval Amount
                      </Box>
                      <Box
                        component="span"
                        className={classes.custApprovalLabel}
                      >
                        {'$ ' +
                          parseFloat(DEStatus?.weeklyApprovalAmount)
                            .toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      </Box>
                    </Grid>
                  ) : null}
                  {DEStatus !== undefined &&
                    pageType == 'create' &&
                    DEStatus?.approvalStatus == DECISION_ENGINE_APPROVED ? (
                    <Grid
                      item
                      className={`${customerStyleClasses.floatLeft} ${classes.textCenter} ${customerStyleClasses.mt1}`}
                    >
                      <Box
                        component="span"
                        className={`${classes.badge} ${classes.badgeGreen}`}
                      >
                        {DEStatus?.approvalStatus}
                      </Box>
                      <Box component="span" className={classes.racDEDate}>
                        Expires on{' '}
                        {buildDateFormat2(DEStatus?.approvalExpirationDate)}
                      </Box>
                    </Grid>
                  ) : DEStatus?.approvalStatus == DECISON_ENGINE_DECLINED &&
                    pageType == 'create' ? (
                    <Grid
                      item
                      className={`${customerStyleClasses.floatRight} ${classes.textCenter} ${customerStyleClasses.mt1}`}
                    >
                      <Box
                        component="span"
                        className={`${classes.badge} ${classes.badgeRed}`}
                      >
                        {DEStatus?.approvalStatus}
                      </Box>
                    </Grid>
                  ) : DEStatus?.approvalStatus == DECISION_ENGINE_PREAPPROVED &&
                    pageType == 'create' ? (
                    <Grid
                      item
                      className={`${customerStyleClasses.floatRight} ${classes.textCenter} ${customerStyleClasses.mt1}`}
                    >
                      <Box
                        component="span"
                        className={`${classes.badge} ${classes.badgeOrange}`}
                      >
                        {DEStatus?.approvalStatus}
                      </Box>
                      <Box component="span" className={classes.racDEDate}>
                        Expires on{' '}
                        {buildDateFormat2(DEStatus?.approvalExpirationDate)}
                      </Box>
                    </Grid>
                  ) : DEStatus?.approvalStatus !== undefined &&
                    DEStatus?.approvalStatus !== null &&
                    pageType == 'create' &&
                    DEStatus?.approvalStatus !== '' ? (
                    <Grid
                      item
                      className={`${customerStyleClasses.floatRight} ${classes.textCenter} ${customerStyleClasses.mt1}`}
                    >
                      <Box
                        component="span"
                        className={`${classes.badge} ${classes.badgeRed}`}
                      >
                        {DEStatus?.approvalStatus}
                      </Box>
                    </Grid>
                  ) : null}
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          <React.Fragment>
            {((pageType == undefined || pageType == 'customer') &&
              activetab == 0) ||
              (pageType !== 'customer' && createActiveTab == 0) ? (
              <Grid container
                //className={ Agrtransferinit? `${classes.mb5} ${classes.pointerEvent}` : `${classes.mb5}`}
                className={`${classes.mb5}`} id="RACTabContent">
                {((pageType == undefined || pageType == 'customer') &&
                  legalHoldApplied !== 1 &&
                  isLegalHold !== true) ||
                  (isLegalHold && coWorkerRoleForCSpopup == 2) ? (
                  <Card
                    className={`${customerStyleClasses.card} ${customerStyleClasses.w100} ${classes.py2} ${classes.mx2}`}
                  >
                    <Grid
                      item
                      className={`${classes.racCol12} ${customerStyleClasses.floatLeft}`}
                    >
                      <Grid
                        item
                        className={`${classes.racCol10} ${customerStyleClasses.floatLeft} ${customerStyleClasses.ps2}`}
                      >
                        <Grid
                          item
                          className={`${customerStyleClasses.w100} ${customerStyleClasses.floatLeft} ${classes.mt2}`}
                        >
                          {activeAlert !== undefined &&
                            activeAlert !== null &&
                            activeAlert.length > 0 &&
                            AlertLoadCount > 0 ? (
                            <React.Fragment>
                              <Typography
                                variant="h4"
                                className={`${customerStyleClasses.font16} ${customerStyleClasses.bold} ${customerStyleClasses.floatLeft} ${classes.me1} ${customerStyleClasses.mt1}`}
                              >
                                Customer Alert
                              </Typography>
                              <Typography
                                variant="h4"
                                className={`${customerStyleClasses.font16} ${customerStyleClasses.bold} ${customerStyleClasses.floatLeft} ${classes.me4} ${customerStyleClasses.mt1}`}
                              >
                                ({AlertLoadCount})
                              </Typography>
                            </React.Fragment>
                          ) : (
                            <Typography
                              variant="h4"
                              className={`${customerStyleClasses.font16} ${customerStyleClasses.bold} ${customerStyleClasses.floatLeft} ${classes.me1} ${customerStyleClasses.mt1}`}
                            >
                              Customer Alert
                            </Typography>
                          )}
                          <Grid item className={classes.alertWidgetContainer}>
                            {AlertMessages()}
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        className={`${classes.racCol2} ${customerStyleClasses.floatLeft} ${customerStyleClasses.ps2}`}
                      >
                        <Grid container>
                          <Grid
                            item
                            className={`${classes.racCol2} ${customerStyleClasses.spacerMR2}`}
                          >
                            {isAlertLoading ? (
                              <Grid
                                item
                                className={`${customerStyleClasses.customerNorecordsAlert} ${classes.alertLoader} ${customerStyleClasses.ms2}`}
                              >
                                <CircularProgress
                                  className={customerStyleClasses.alertLoader}
                                />
                              </Grid>
                            ) : null}
                          </Grid>
                          <Grid item className={classes.racCol8}>
                            <RACButton
                              variant="contained"
                              color="primary"
                              id="assignAlertBtn"
                              disabled={Agrtransferinit}
                              className={customerStyleClasses.mt1}
                              onClick={openAlert}
                            >
                              Assign Alert
                            </RACButton>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Card>
                ) : null}
                {/*Basic Info starts assign  here*/}
                <Grid
                  container
                  className={`${customerStyleClasses.px2} ${classes.mt3} ${customerStyleClasses.mb3}`}
                >
                  <Grid item md={12}>
                    <Typography className={classes.title}>
                      Basic Information
                    </Typography>
                  </Grid>
                  <Grid item md={12}>
                    <Card
                      className={`${customerStyleClasses.card} ${customerStyleClasses.w100}`}
                    >
                      <CardContent>
                        <Grid
                          container
                          spacing={2}
                          className={customerStyleClasses.mb2}
                        >
                          <Grid item lg={3} xl={2}>
                            {pageType == undefined || pageType == 'customer' ? (
                              <RACTextbox
                                isCurrency={false}
                                required={true}
                                disabled={Agrtransferinit}
                                type={'text'}
                                inputlabel="Last Name"
                                name={`LastName`}
                                OnChange={(e: any) => HandleOnChange(e)}
                                maxlength={30}
                                value={customerInformation?.lastName}
                                errorMessage={
                                  customerInformation?.lastName !== undefined &&
                                    customerInformation?.lastName == '' &&
                                    isrequired === true
                                    ? FIELD_ERROR
                                    : ''
                                }
                              />
                            ) : null}
                            {pageType != 'customer' ? (
                              <label
                                htmlFor="a11y_ln"
                                className={customerStyleClasses.formLabel}
                              >
                                Last Name
                                <span
                                  className={
                                    customerStyleClasses.mandatoryfield
                                  }
                                >
                                  *
                                </span>
                                {'  '}
                                <span
                                  className={`${classes.mx2} ${classes.fontColor}`}
                                >
                                  {customerInformation?.lastName}
                                </span>
                              </label>
                            ) : null}
                          </Grid>
                          <Grid item lg={3} xl={2}>
                            {pageType != 'customer' ? (
                              <label
                                htmlFor="a11y_fn"
                                className={customerStyleClasses.formLabel}
                              >
                                First Name
                                <span
                                  className={
                                    customerStyleClasses.mandatoryfield
                                  }
                                >
                                  *
                                </span>
                                {'  '}
                                <span
                                  className={`${classes.mx2} ${classes.fontColor}`}
                                >
                                  {customerInformation?.firstName}
                                </span>
                              </label>
                            ) : null}
                            {pageType == undefined || pageType == 'customer' ? (
                              <RACTextbox
                                isCurrency={false}
                                required={true}
                                type={'text'}
                                disabled={Agrtransferinit}
                                inputlabel="First Name"
                                name={`FirstName`}
                                OnChange={(e: any) => HandleOnChange(e)}
                                maxlength={30}
                                value={customerInformation?.firstName}
                                errorMessage={
                                  customerInformation?.firstName !==
                                    undefined &&
                                    customerInformation?.firstName == '' &&
                                    isrequired === true
                                    ? FIELD_ERROR
                                    : ''
                                }
                              />
                            ) : null}
                          </Grid>
                          {(legalHoldApplied !== 1 && isLegalHold !== true) ||
                            (isLegalHold && coWorkerRoleForCSpopup == 2) ? (
                            <React.Fragment>
                              <Grid item lg={3} xl={2}>
                                <Box
                                  component="div"
                                  className={customerStyleClasses.formLabel}
                                >
                                  Date of Birth
                                  <Box
                                    component="span"
                                    className={
                                      customerStyleClasses.mandatoryfield
                                    }
                                  >
                                    *
                                  </Box>
                                  {'  '}
                                  <Box
                                    component="span"
                                    className={`${classes.mx2} ${classes.fontColor}`}
                                  >
                                    {pageType != 'customer'
                                      ? customerInformation?.dateOfBirth !==
                                        undefined &&
                                        customerInformation?.dateOfBirth !==
                                        null &&
                                        customerInformation?.dateOfBirth !== ''
                                        ? customerauthDOB ? buildDateFormat2(
                                          customerInformation?.dateOfBirth
                                        ) : buildDateFormat2(
                                          customerInformation?.dateOfBirth
                                        ).substring(0, 6) + '****'
                                        : ''
                                      : null}
                                  </Box>
                                </Box>
                                {pageType == 'customer' ? (
                                  <React.Fragment>
                                    <Typography
                                      className={classes.custDateField}
                                      onClick={() => focusDatePicker()}
                                      style={{ backgroundColor: (Agrtransferinit || customerauthDOB) ? '#e4e4de' : '' }}
                                    >
                                      {customerInformation?.dateOfBirth !==
                                        undefined &&
                                        customerInformation?.dateOfBirth !==
                                        null &&
                                        customerInformation?.dateOfBirth !== ''
                                        ? customerauthDOB ?
                                          buildDateFormat2(
                                            customerInformation?.dateOfBirth
                                          ) :
                                          buildDateFormat2(
                                            customerInformation?.dateOfBirth
                                          ).substring(0, 6) + '****'
                                        : 'MM/DD/YYYY'}
                                      <Calendar
                                        className={classes.datePickerImg}
                                        onClick={() => focusDatePicker()}
                                      ></Calendar>
                                      <DatePicker
                                        className={classes.dateField}

                                        id={'DateOfBirth'}
                                        fixedHeight
                                        disabled={Agrtransferinit || customerauthDOB}
                                        placeholderText="MM/DD/YYYY"
                                        dateFormat="MM/dd/yyyy"
                                        yearDropdownItemNumber={100}
                                        showYearDropdown
                                        //dropdownMode="select"
                                        scrollableYearDropdown
                                        // scrollableMonthYearDropdown
                                        selected={
                                          datePickerIsLoad === false
                                            ? customerInformation?.dateOfBirth !==
                                              undefined &&
                                              customerInformation?.dateOfBirth !==
                                              null &&
                                              customerInformation?.dateOfBirth !==
                                              ''
                                              ? new Date(
                                                moment(
                                                  customerInformation?.dateOfBirth
                                                ).format('MM/DD/YYYY')
                                              )
                                              : null
                                            : new Date(
                                              dt.getFullYear() -
                                              18 +
                                              '-' +
                                              (dt.getMonth() + 1) +
                                              '-' +
                                              (dt.getDate() < 10
                                                ? '0' + dt.getDate()
                                                : dt.getDate())
                                            )
                                        }
                                        minDate={
                                          new Date(
                                            dt.getFullYear() -
                                            110 +
                                            '-' +
                                            (dt.getMonth() + 1) +
                                            '-' +
                                            (dt.getDate() < 10
                                              ? '0' + dt.getDate()
                                              : dt.getDate())
                                          )
                                        }
                                        maxDate={
                                          new Date(
                                            dt.getFullYear() -
                                            18 +
                                            '-' +
                                            (dt.getMonth() + 1) +
                                            '-' +
                                            (dt.getDate() < 10
                                              ? '0' + dt.getDate()
                                              : dt.getDate())
                                          )
                                        }
                                        shouldCloseOnSelect={true}
                                        onChange={(e: any) =>
                                          handleDateSelect(e)
                                        }
                                      />
                                    </Typography>
                                  </React.Fragment>
                                ) : null}
                                {customerInformation?.dateOfBirth !==
                                  undefined &&
                                  customerInformation?.dateOfBirth == '' &&
                                  isrequired === true ? (
                                  <label
                                    className={
                                      customerStyleClasses.mandatoryfield
                                    }
                                  >
                                    Required
                                  </label>
                                ) : (
                                  ''
                                )}
                              </Grid>
                              <Grid item lg={3} xl={2} className={classes.pt2}>
                                <label
                                  htmlFor="a11y_ssnone"
                                  id={'SSNField'}
                                  className={customerStyleClasses.formLabel}
                                >
                                  Social Security# / ITIN#{'   '}{' '}
                                  <span
                                    className={`${classes.mx2} ${classes.fontColor}`}
                                  >
                                    <React.Fragment>
                                      {pageType != 'customer' &&
                                        customerInformation?.ssn3 !== undefined &&
                                        customerInformation?.ssn3 !== null &&
                                        customerInformation?.ssn3 !== ''
                                        ? '***  **  '
                                        : null}
                                      {pageType != 'customer'
                                        ? customerInformation?.ssn3
                                        : null}
                                    </React.Fragment>
                                  </span>
                                </label>
                                {pageType == undefined ||
                                  pageType == 'customer' ? (
                                  <Grid
                                    container
                                    spacing={2}
                                    className={customerStyleClasses.ssnpadding}
                                  >
                                    <Grid
                                      item
                                      md={4}
                                      className={classes.ssnPaddingTop}
                                    >
                                      <RACTextbox
                                        isCurrency={false}
                                        required={false}
                                        id="a11y_ssnone"
                                        type={customerauthDOB ? 'text' : 'password'}
                                        disabled={Agrtransferinit || customerauthDOB}
                                        placeHolder={'xxx'}
                                        name={`Ssn1`}
                                        className={classes.textCenterSSN}
                                        OnChange={(e: any) =>
                                          HandleOnChange1(e)
                                        }
                                        maxlength={3}
                                        value={customerInformation?.ssn1}
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      md={3}
                                      className={classes.ssnPaddingTop}
                                    >
                                      <RACTextbox
                                        isCurrency={false}
                                        required={false}
                                        type={customerauthDOB ? 'text' : 'password'}
                                        disabled={Agrtransferinit || customerauthDOB}
                                        id="a11y_ssntwo"
                                        name={`Ssn2`}
                                        placeHolder={'xx'}
                                        className={classes.textCenterSSN}
                                        OnChange={(e: any) =>
                                          HandleOnChange1(e)
                                        }
                                        maxlength={2}
                                        value={customerInformation?.ssn2}
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      md={4}
                                      className={classes.ssnPaddingTop}
                                    >
                                      <RACTextbox
                                        isCurrency={false}
                                        required={false}
                                        type={'text'}
                                        disabled={Agrtransferinit || customerauthDOB}
                                        name={`Ssn3`}
                                        placeHolder={'xxxx'}
                                        className={classes.textCenterSSN}
                                        id="a11y_ssnthree"
                                        OnChange={(e: any) =>
                                          HandleOnChange1(e)
                                        }
                                        maxlength={4}
                                        value={customerInformation?.ssn3}
                                      />
                                    </Grid>
                                    {validateSSN() === false &&
                                      isrequired === true ? (
                                      <Box
                                        className={
                                          customerStyleClasses.mandatoryfield
                                        }
                                      >
                                        Please enter valid SSN Number
                                      </Box>
                                    ) : null}
                                  </Grid>
                                ) : null}
                              </Grid>
                            </React.Fragment>
                          ) : null}
                        </Grid>
                        {(legalHoldApplied !== 1 && isLegalHold !== true) ||
                          (isLegalHold && coWorkerRoleForCSpopup == 2) ? (
                          <Grid container spacing={2}>
                            <Grid item lg={3} xl={2}>
                              <RACTextbox
                                isCurrency={false}
                                required={true}
                                type={'text'}
                                inputlabel="Email Address"
                                name={`Emailaddress`}
                                OnChange={(e: any) => HandleOnChange2(e)}
                                maxlength={50}
                                disabled={Agrtransferinit}
                                value={customerInformation?.emailAddress}
                                errorMessage={
                                  (customerInformation?.emailAddress ==
                                    undefined ||
                                    customerInformation?.emailAddress == null ||
                                    customerInformation?.emailAddress == '') &&
                                    isrequired === true
                                    ? FIELD_ERROR
                                    : isrequired === true &&
                                      ValidateEmail(
                                        customerInformation?.emailAddress
                                      ) === false
                                      ? 'Please enter valid email address'
                                      : ''
                                }
                              />
                            </Grid>
                            <Grid item lg={3} xl={2}>
                              <RACSelect
                                inputLabel="Gov ID Type"
                                options={govTypeId}
                                isDisabled={Agrtransferinit || customerauthDOB}
                                defaultValue={
                                  customerInformation?.governmentIdType !==
                                    undefined &&
                                    customerInformation?.governmentIdType !==
                                    null &&
                                    customerInformation?.governmentIdType !==
                                    '' &&
                                    isDropDownValueExist(
                                      govTypeId,
                                      customerInformation?.governmentIdType
                                    )
                                    ? customerInformation?.governmentIdType
                                    : '0'
                                }
                                name="GovernIDtype"
                                loading={
                                  govTypeId.length == 0 && !dropDownLoaded
                                    ? true
                                    : false
                                }
                                onChange={(e: any) => {
                                  e.target.name = 'GovernIDtype';
                                  HandleOnChange2(e);
                                }}
                                {...(govTypeId.length == 0 &&
                                  dropDownLoaded && {
                                  errorMessage: API_ERROR_MESSAGE,
                                })}
                              />
                            </Grid>
                            <Grid item lg={3} xl={2}>
                              <RACTextbox
                                isCurrency={false}
                                type={'text'}
                                inputlabel="Gov ID Number"
                                name={`GovernIDnum`}
                                OnChange={(e: any) => HandleOnChange2(e)}
                                maxlength={30}
                                onFocus={() => setisGovtNumOnFocus(true)}
                                Onblur={() => setisGovtNumOnFocus(false)}
                                disabled={
                                  Agrtransferinit || customerauthDOB ? true :
                                    (customerInformation?.governmentIdType ==
                                      undefined ||
                                      customerInformation?.governmentIdType ==
                                      null ||
                                      customerInformation?.governmentIdType ==
                                      '0' ||
                                      customerInformation?.governmentIdType == '' ||
                                      customerInformation?.governmentIdType ==
                                      'NONE'
                                      ? true 
                                      : false)
                                }
                                value={
                                  customerInformation?.governmentId !==
                                    undefined &&
                                    customerInformation?.governmentId !== null &&
                                    customerInformation?.governmentId !== ''
                                    ? customerInformation?.governmentId.length >
                                      4 && isGovtNumOnFocus !== true
                                      ? customerauthDOB ?
                                        customerInformation?.governmentId :
                                        customerInformation?.governmentId
                                          .substring(
                                            0,
                                            customerInformation?.governmentId
                                              .length - 4
                                          )
                                          .replace(/[A-Za-z0-9.,-]/g, '*') +
                                        customerInformation?.governmentId.substring(
                                          customerInformation?.governmentId
                                            .length - 4
                                        )
                                      : customerInformation?.governmentId
                                    : ''
                                }
                              />
                            </Grid>
                            <Grid item lg={3} xl={1}>
                              <RACSelect
                                inputLabel="State"
                                options={state}
                                defaultValue={
                                  customerInformation?.governmentIdIssuedState !==
                                    undefined &&
                                    customerInformation?.governmentIdIssuedState !==
                                    null &&
                                    customerInformation?.governmentIdIssuedState !==
                                    '' &&
                                    isDropDownValueExist(
                                      state,
                                      customerInformation?.governmentIdIssuedState
                                    )
                                    ? customerInformation?.governmentIdIssuedState
                                    : '0'
                                }
                                isDisabled={
                                  Agrtransferinit ? true :
                                    (customerInformation?.governmentIdType ==
                                      undefined ||
                                      customerInformation?.governmentIdType ==
                                      null ||
                                      customerInformation?.governmentIdType ==
                                      '0' ||
                                      customerInformation?.governmentIdType == '' ||
                                      customerInformation?.governmentIdType ==
                                      'PASS' ||
                                      customerInformation?.governmentIdType ==
                                      'NONE'
                                      ? true
                                      : false)
                                }
                                required={
                                  customerInformation?.governmentIdType !==
                                    undefined &&
                                    customerInformation?.governmentIdType !==
                                    null &&
                                    customerInformation?.governmentIdType !==
                                    '0' &&
                                    customerInformation?.governmentIdType !==
                                    'NONE' &&
                                    customerInformation?.governmentIdType !==
                                    'PASS'
                                    ? true
                                    : false
                                }
                                loading={
                                  state.length == 0 && !dropDownLoaded
                                    ? true
                                    : false
                                }
                                onChange={(e: any) => {
                                  e.target.name = 'stateForBasicInfo';
                                  HandleOnChange3(e);
                                }}
                                errorMessage={
                                  customerInformation?.governmentIdType !==
                                    undefined &&
                                    customerInformation?.governmentIdType !==
                                    null &&
                                    customerInformation?.governmentIdType !==
                                    '0' &&
                                    customerInformation?.governmentIdType !==
                                    'NONE' &&
                                    customerInformation?.governmentIdType !==
                                    'PASS' &&
                                    ((customerInformation?.governmentIdIssuedState ==
                                      undefined &&
                                      customerInformation?.governmentIdIssuedState ==
                                      null) ||
                                      customerInformation?.governmentIdIssuedState ==
                                      '' ||
                                      customerInformation?.governmentIdIssuedState ==
                                      '0') &&
                                    isrequired === true
                                    ? FIELD_ERROR
                                    : ''
                                }
                                {...(state.length == 0 &&
                                  dropDownLoaded && {
                                  errorMessage: API_ERROR_MESSAGE,
                                })}
                              />
                            </Grid>
                            <Grid item lg={3} xl={1}>
                              <RACSelect
                                inputLabel="Salutation"
                                options={salutation}
                                isDisabled={Agrtransferinit}
                                defaultValue={
                                  customerInformation?.salutation !==
                                    undefined &&
                                    customerInformation?.salutation !== null &&
                                    customerInformation?.salutation !== '' &&
                                    isDropDownValueExist(
                                      salutation,
                                      customerInformation?.salutation
                                    )
                                    ? customerInformation?.salutation
                                    : '0'
                                }
                                loading={
                                  salutation.length == 0 && !dropDownLoaded
                                    ? true
                                    : false
                                }
                                onChange={(e: any) => {
                                  e.target.name = 'Salutation';
                                  HandleOnChange3(e);
                                }}
                                {...(salutation.length == 0 &&
                                  dropDownLoaded && {
                                  errorMessage: API_ERROR_MESSAGE,
                                })}
                              />
                            </Grid>
                            <Grid item lg={3} xl={1}>
                              <RACTextbox
                                isCurrency={false}
                                type={'text'}
                                inputlabel="Middle initial"
                                name={`Middleinitial`}
                                disabled={Agrtransferinit}
                                OnChange={(e: any) => HandleOnChange2(e)}
                                maxlength={1}
                                value={customerInformation?.middleInitial}
                              />
                            </Grid>
                            <Grid item lg={3} xl={1}>
                              <RACSelect
                                inputLabel="Jr/Sr"
                                options={suffix}
                                isDisabled={Agrtransferinit}
                                defaultValue={
                                  customerInformation?.suffix !== undefined &&
                                    customerInformation?.suffix !== null &&
                                    customerInformation?.suffix !== '' &&
                                    isDropDownValueExist(
                                      suffix,
                                      customerInformation?.suffix
                                    )
                                    ? customerInformation?.suffix
                                    : '0'
                                }
                                loading={
                                  suffix.length == 0 && !dropDownLoaded
                                    ? true
                                    : false
                                }
                                onChange={(e: any) => {
                                  e.target.name = 'JrSr';
                                  HandleOnChange3(e);
                                }}
                                {...(suffix.length == 0 &&
                                  dropDownLoaded && {
                                  errorMessage: API_ERROR_MESSAGE,
                                })}
                              />
                            </Grid>
                            <Grid
                              item
                              lg={3}
                              xl={2}
                              className={`${classes.pt0} ${customerStyleClasses.mt4}`}
                            >
                              <Grid item className={classes.formCheckToggle}>
                                <RACToggle
                                  name={'RAC_Co_worker'}
                                  id={'RACCOWORKER'}
                                  disabled={Agrtransferinit}
                                  //className={classes.hunt}
                                  GridClassName={classes.gridClassToggleReverse}
                                  labelGridClassName={classes.gridClassLabel}
                                  checked={
                                    customerInformation?.racCoworker == 'N'
                                      ? false
                                      : true
                                  }
                                  toggleColor="#0d6efd"
                                  leftText={true}
                                  labelClassName={classes.switchLabel}
                                  leftLabel="RAC Co-worker"
                                  //labelClassName={classes.demo}
                                  OnChange={(e: any) => {
                                    HandleOnChange3(e);
                                  }}
                                />
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              lg={3}
                              xl={2}
                              className={`${classes.pt0} ${customerStyleClasses.mt4}`}
                            >
                              {webLinkDisable ? <><div title='This account is already linked with e-pay login'>
                                <RACButton
                                  variant="contained"
                                  color="primary"
                                  id="assignAlertBtn"
                                  disabled={webLinkDisable}
                                  className={customerStyleClasses.mt1}
                                  // onClick={() => setWebError(true)}
                                  onClick={() => { webLinkCustomer() }}
                                >
                                  Web Link
                                </RACButton>
                              </div> </> : <> <RACButton
                                variant="contained"
                                color="primary"
                                id="assignAlertBtn"
                                disabled={webLinkDisable}
                                className={customerStyleClasses.mt1}
                                // onClick={() => setWebError(true)}
                                onClick={() => { webLinkCustomer() }}
                              >
                                Web Link
                              </RACButton>
                              </>}

                            </Grid>

                            <Grid style={{ margin: '45px' }}>
                              <RACToggle
                                name={'RAC_Co_worker'}
                                id={'RACCOWORKER'}
                                //disabled={Agrtransferinit}
                                //className={classes.hunt}
                                GridClassName={classes.gridClassToggleReverse}
                                labelGridClassName={classes.gridClassLabel}
                                checked={slapopupauthentication}
                                toggleColor="#0d6efd"
                                rightText={true}
                                labelClassName={classes.switchLabel}
                                rightLabel="Show Personal Information"
                                //labelClassName={classes.demo}
                                OnChange={(e: any) => {
                                  handleSLApopoup();
                                }}
                              />

                            </Grid>
                          </Grid>
                        ) : null}
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
                {/*Basic Info ends here*/}
                {/* Address Info starts here */}
                {(legalHoldApplied !== 1 && isLegalHold !== true) ||
                  (isLegalHold && coWorkerRoleForCSpopup == 2) ? (
                  <Grid
                    container
                    className={`${classes.px2} ${customerStyleClasses.mb3}`}
                  >
                    <Grid item md={12}>
                      <Grid style={{ display: "flex", flexWrap: "wrap" }}>
                        <Grid style={{ display: "flex", flexWrap: "wrap", marginRight: '80%' }}>
                          <Typography className={classes.title}>
                            {' '}
                            Address Information
                          </Typography>
                        </Grid>
                        <Grid style={{ display: "flex", flexWrap: "wrap" }}>
                          <a
                            className={`${customerStyleClasses.racpadLink} ${classes.title}`}
                            //style={{ cursor: 'pointer' , marginLeft:'76%' ,}}
                            onClick={() => setAddressHistoryPopUp(true)}
                          >
                            Address History
                          </a>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item md={12} id="addressDiv">
                      <Card
                        className={`${customerStyleClasses.card} ${customerStyleClasses.w100}`}
                      >
                        <CardContent>
                          <Grid container spacing={2}>
                            <Grid
                              container
                              className={`${classes.p2} ${classes.pb0}`}
                            >
                              <Grid item md={12}>
                                <Grid
                                  className={customerStyleClasses.floatLeft}
                                >
                                  <Typography className={classes.title}>
                                    Primary Address
                                  </Typography>
                                </Grid>
                                <Grid
                                  className={customerStyleClasses.floatRight}
                                >
                                  <Grid className={Agrtransferinit ? `${classes.pointerEvent} ${classes.formCheckToggle}` : `${classes.formCheckToggle}`}>
                                    <RACToggle
                                      name="MailingAddres"

                                      GridClassName={
                                        classes.gridClassToggleReverse
                                      }
                                      disabled={Agrtransferinit}
                                      labelGridClassName={
                                        classes.gridClassLabel
                                      }
                                      checked={
                                        customerInformation?.mailAddress == 'Y'
                                          ? true
                                          : false
                                      }
                                      toggleColor="#0d6efd"
                                      leftText={true}
                                      labelClassName={classes.switchLabel}
                                      leftLabel="Mailing address same as primary?"
                                      OnChange={(e: any) => {
                                        HandleOnChange5(e);
                                      }}
                                    />
                                    {/* <Typography
                                      component="label"
                                      className={`${classes.floatLeft} ${classes.mt2}`}
                                      htmlFor="MailingAddress"
                                    >
                                      Mailing address same as primary?
                                    </Typography> */}
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item lg={3} xl={2}>
                              <RACTextbox
                                isCurrency={false}
                                required={true}
                                type={'text'}
                                inputlabel={addressLine1}
                                name={`AddressLine1`}
                                id={`AddressLine1Edit`}
                                OnChange={(e: any) => HandleOnChange4(e)}
                                maxlength={30}
                                placeHolder={addressLine1}
                                value={
                                  editInputBox === true
                                    ? customerInformation?.addressLine1
                                    : updatePrimaryAddress.address
                                }
                                disabled={Agrtransferinit ? true : editInputBox}
                                errorMessage={
                                  editInputBox
                                    ? (customerInformation?.addressLine1 ==
                                      undefined ||
                                      customerInformation?.addressLine1 ==
                                      null ||
                                      customerInformation?.addressLine1 ==
                                      '') &&
                                      isrequired === true
                                      ? FIELD_ERROR
                                      : ''
                                    : (updatePrimaryAddress.address ==
                                      undefined ||
                                      updatePrimaryAddress.address == null ||
                                      updatePrimaryAddress.address == '') &&
                                      (validateAddress == 'B' ||
                                        validateAddress == 'P')
                                      ? FIELD_ERROR
                                      : ''
                                }
                              />
                            </Grid>
                            <Grid item lg={3} xl={2}>
                              <RACTextbox
                                isCurrency={false}
                                required={false}
                                type={'text'}
                                inputlabel="Address Line 2"
                                name={`AddressLine2`}
                                infoIcon={true}
                                infoTitle={addressLine2}
                                placeHolder={addressLine2}
                                OnChange={(e: any) => HandleOnChange4(e)}
                                maxlength={30}
                                value={
                                  editInputBox === true
                                    ? customerInformation?.addressLine2
                                    : updatePrimaryAddress.address1
                                }
                                disabled={Agrtransferinit ? true : editInputBox}
                              />
                            </Grid>
                            <Grid item lg={3} xl={2}>
                              <RACTextbox
                                isCurrency={false}
                                required={true}
                                type={'text'}
                                inputlabel="Zip"
                                name={`Zip`}
                                placeHolder="Zip Code"
                                OnChange={(e: any) => HandleOnChange5(e)}
                                maxlength={10}
                                value={
                                  editInputBox === true
                                    ? customerInformation?.zip?.trim()
                                    : updatePrimaryAddress?.zip?.trim()
                                }
                                disabled={Agrtransferinit ? true : editInputBox}
                                errorMessage={
                                  editInputBox
                                    ? (customerInformation?.zip == undefined ||
                                      customerInformation?.zip == null ||
                                      customerInformation?.zip == '') &&
                                      isrequired === true
                                      ? FIELD_ERROR
                                      : customerInformation?.zip !==
                                        undefined &&
                                        customerInformation?.zip !== null &&
                                        customerInformation?.zip.length !== 5 &&
                                        customerInformation?.zip.length !==
                                        10 &&
                                        isrequired === true
                                        ? INVALID_ZIP
                                        : ''
                                    : (updatePrimaryAddress.zip == undefined ||
                                      updatePrimaryAddress.zip == null ||
                                      updatePrimaryAddress.zip == '') &&
                                      (validateAddress == 'B' ||
                                        validateAddress == 'P')
                                      ? FIELD_ERROR
                                      : updatePrimaryAddress.zip !== undefined &&
                                        updatePrimaryAddress.zip !== null &&
                                        updatePrimaryAddress.zip.length !== 5 &&
                                        updatePrimaryAddress.zip.length !== 10 &&
                                        (validateAddress == 'B' ||
                                          validateAddress == 'P')
                                        ? INVALID_ZIP
                                        : ''
                                }
                              />
                            </Grid>
                            <Grid item lg={3} xl={2}>
                              <RACTextbox
                                isCurrency={false}
                                required={true}
                                type={'text'}
                                inputlabel="City"
                                name={`City`}
                                placeHolder="City"
                                OnChange={(e: any) => HandleOnChange5(e)}
                                maxlength={30}
                                value={
                                  editInputBox === true
                                    ? customerInformation?.city
                                    : updatePrimaryAddress.city
                                }
                                disabled={Agrtransferinit ? true : editInputBox}
                                errorMessage={
                                  editInputBox
                                    ? (customerInformation?.city == undefined ||
                                      customerInformation?.city == null ||
                                      customerInformation?.city == '') &&
                                      isrequired === true
                                      ? FIELD_ERROR
                                      : ''
                                    : (updatePrimaryAddress.city == undefined ||
                                      updatePrimaryAddress.city == null ||
                                      updatePrimaryAddress.city == '') &&
                                      (validateAddress == 'B' ||
                                        validateAddress == 'P')
                                      ? FIELD_ERROR
                                      : ''
                                }
                              />
                            </Grid>
                            <Grid item lg={3} xl={2}>
                              <RACSelect
                                inputLabel="State"
                                options={state}
                                defaultValue={
                                  editInputBox === true
                                    ? customerInformation?.state !==
                                      undefined &&
                                      customerInformation?.state !== null &&
                                      customerInformation?.state !== '' &&
                                      isDropDownValueExist(
                                        state,
                                        customerInformation?.state
                                      )
                                      ? customerInformation?.state
                                      : '0'
                                    : updatePrimaryAddress.state
                                }
                                isDisabled={Agrtransferinit ? true : editInputBox}
                                required={true}
                                loading={
                                  state.length == 0 && !dropDownLoaded
                                    ? true
                                    : false
                                }
                                onChange={(e: any) => {
                                  e.target.name = 'AddressInfoState';
                                  HandleOnChange5(e);
                                }}
                                errorMessage={
                                  editInputBox
                                    ? (customerInformation?.state ==
                                      undefined ||
                                      customerInformation?.state == null ||
                                      customerInformation?.state == '' ||
                                      customerInformation?.state == '0') &&
                                      isrequired === true
                                      ? FIELD_ERROR
                                      : ''
                                    : (updatePrimaryAddress.state ==
                                      undefined ||
                                      updatePrimaryAddress.state == null ||
                                      updatePrimaryAddress.state == '' ||
                                      updatePrimaryAddress.state == '0') &&
                                      isrequired === true
                                      ? FIELD_ERROR
                                      : ''
                                }
                                {...(state.length == 0 &&
                                  dropDownLoaded && {
                                  errorMessage: API_ERROR_MESSAGE,
                                })}
                              />
                            </Grid>
                            {editInputBox ? (
                              <Grid item lg={3} xl={2} className={classes.m2}>
                                <RACButton
                                  onClick={Editclick}
                                  variant="contained"
                                  color="primary"
                                  disabled={Agrtransferinit}
                                >
                                  Edit Address
                                </RACButton>
                              </Grid>
                            ) : (
                              <Grid item lg={3} xl={2} className={classes.m2}>
                                {!editInputBox && (
                                  <>
                                    <RACButton
                                      variant="contained"
                                      color="primary"
                                      className={customerStyleClasses.me2}
                                      onClick={updateAddressInfo} // getAddress with P
                                      disabled={Agrtransferinit} 
                                    >
                                      Validate
                                    </RACButton>
                                    <RACButton
                                      className={customerStyleClasses.foc}
                                      variant="outlined"
                                      color="primary"
                                      onClick={Cancelbutt}
                                      disabled={Agrtransferinit}
                                    >
                                      Cancel
                                    </RACButton>
                                  </>
                                )}
                              </Grid>
                            )}
                            {deliveryAddressArray.length > 0 ? (
                              <Grid
                                container
                                spacing={2}
                                className={customerStyleClasses.ps2}
                              >
                                <Grid item md={12} className={classes.mt3}>
                                  <Typography className={classes.title}>
                                    Delivery Address
                                  </Typography>
                                  {buildDeliveryAddress()}
                                </Grid>
                              </Grid>
                            ) : ('')}

                            {customerInformation?.mailAddress == 'N' ? (
                              <Grid
                                container
                                spacing={2}
                                className={customerStyleClasses.ps2}
                              >
                                <Grid item md={12} className={classes.mt3}>
                                  <Typography className={classes.title}>
                                    Mailing Address
                                  </Typography>
                                </Grid>
                                <Grid item lg={3} xl={2}>
                                  <RACTextbox
                                    isCurrency={false}
                                    required={true}
                                    type={'text'}
                                    id="MailingAddressField"
                                    inputlabel={addressLine1}
                                    name={`AddressLine1M`}
                                    placeHolder={addressLine1}
                                    OnChange={(e: any) => HandleOnChange41(e)}
                                    maxlength={30}
                                    value={
                                      editInputBoxM === true
                                        ? customerInformation?.addressLine1M
                                        : updateMailingAddress.address
                                    }
                                    disabled={Agrtransferinit ? true : editInputBoxM}
                                    errorMessage={
                                      editInputBoxM ?
                                        (customerInformation?.addressLine1M ==
                                          undefined ||
                                          customerInformation?.addressLine1M ==
                                          null ||
                                          customerInformation?.addressLine1M ==
                                          '') &&
                                        isrequired === true
                                        ? FIELD_ERROR : ''
                                        : (updateMailingAddress.address ==
                                          undefined ||
                                          updateMailingAddress.address ==
                                          null ||
                                          updateMailingAddress.address ==
                                          '') &&
                                          (validateAddress == 'B' ||
                                            validateAddress == 'M')
                                          ? FIELD_ERROR
                                          : ''
                                    }
                                  />
                                </Grid>
                                <Grid item lg={3} xl={2}>
                                  <RACTextbox
                                    isCurrency={false}
                                    required={false}
                                    type={'text'}
                                    inputlabel="Address Line 2"
                                    name={`AddressLine2M`}
                                    infoIcon={true}
                                    infoTitle={addressLine2}
                                    placeHolder={addressLine2}
                                    OnChange={(e: any) => HandleOnChange41(e)}
                                    maxlength={30}
                                    value={
                                      editInputBoxM === true
                                        ? customerInformation?.addressLine2M
                                        : updateMailingAddress.address1
                                    }
                                    disabled={Agrtransferinit ? true : editInputBoxM}
                                  />
                                </Grid>
                                <Grid item lg={3} xl={2}>
                                  <RACTextbox
                                    isCurrency={false}
                                    required={true}
                                    type={'text'}
                                    inputlabel="Zip"
                                    name={`ZipM`}
                                    placeHolder="Zip Code"
                                    OnChange={(e: any) => HandleOnChange51(e)}
                                    maxlength={10}
                                    value={
                                      editInputBoxM === true
                                        ? customerInformation?.zipM
                                        : updateMailingAddress.zip
                                    }
                                    disabled={Agrtransferinit ? true : editInputBoxM}
                                    errorMessage={
                                      editInputBoxM
                                        ? (customerInformation?.zipM ==
                                          undefined ||
                                          customerInformation?.zipM == null ||
                                          customerInformation?.zipM == '') &&
                                          isrequired === true
                                          ? FIELD_ERROR
                                          : customerInformation?.zipM.length >
                                            0 &&
                                            customerInformation?.zipM.length !==
                                            5 &&
                                            customerInformation?.zipM.length !==
                                            10 &&
                                            isrequired === true
                                            ? INVALID_ZIP
                                            : ''
                                        : (updateMailingAddress.zip ==
                                          undefined ||
                                          updateMailingAddress.zip == null ||
                                          updateMailingAddress.zip == '') &&
                                          (validateAddress == 'B' ||
                                            validateAddress == 'M')
                                          ? FIELD_ERROR
                                          : updateMailingAddress.zip.length > 0 &&
                                            updateMailingAddress.zip.length !==
                                            5 &&
                                            updateMailingAddress.zip.length !== 10
                                            ? INVALID_ZIP
                                            : ''
                                    }
                                  />
                                </Grid>
                                <Grid item lg={3} xl={2}>
                                  <RACTextbox
                                    isCurrency={false}
                                    required={true}
                                    type={'text'}
                                    inputlabel="City"
                                    name={`CityM`}
                                    placeHolder="City"
                                    OnChange={(e: any) => HandleOnChange51(e)}
                                    maxlength={30}
                                    value={
                                      editInputBoxM === true
                                        ? customerInformation?.cityM
                                        : updateMailingAddress.city
                                    }
                                    disabled={Agrtransferinit ? true : editInputBoxM}
                                    errorMessage={
                                      editInputBoxM
                                        ? (customerInformation?.cityM ==
                                          undefined ||
                                          customerInformation?.cityM ==
                                          null ||
                                          customerInformation?.cityM == '') &&
                                          isrequired === true
                                          ? FIELD_ERROR
                                          : ''
                                        : (updateMailingAddress.city ==
                                          undefined ||
                                          updateMailingAddress.city == null ||
                                          updateMailingAddress.city == '') &&
                                          (validateAddress == 'B' ||
                                            validateAddress == 'M')
                                          ? FIELD_ERROR
                                          : ''
                                    }
                                  />
                                </Grid>
                                <Grid item lg={3} xl={2}>
                                  <RACSelect
                                    inputLabel="State"
                                    options={state}
                                    defaultValue={
                                      editInputBoxM === true
                                        ? customerInformation?.stateM !==
                                          undefined &&
                                          customerInformation?.stateM !==
                                          null &&
                                          customerInformation?.stateM !== '' &&
                                          isDropDownValueExist(
                                            state,
                                            customerInformation?.stateM
                                          )
                                          ? customerInformation?.stateM
                                          : '0'
                                        : updateMailingAddress.state
                                    }
                                    isDisabled={Agrtransferinit ? true : editInputBoxM}
                                    required={true}
                                    loading={
                                      state.length == 0 && !dropDownLoaded
                                        ? true
                                        : false
                                    }
                                    onChange={(e: any) => {
                                      e.target.name = 'AddressInfoStateM';
                                      HandleOnChange51(e);
                                    }}
                                    errorMessage={
                                      editInputBoxM
                                        ? (customerInformation?.stateM ==
                                          undefined ||
                                          customerInformation?.stateM ==
                                          null ||
                                          customerInformation?.stateM == '' ||
                                          customerInformation?.stateM ==
                                          '0') &&
                                          isrequired === true
                                          ? FIELD_ERROR
                                          : ''
                                        : (updateMailingAddress.state ==
                                          undefined ||
                                          updateMailingAddress.state ==
                                          null ||
                                          updateMailingAddress.state == '' ||
                                          updateMailingAddress.state ==
                                          '0') &&
                                          (validateAddress == 'B' ||
                                            validateAddress == 'M')
                                          ? FIELD_ERROR
                                          : ''
                                    }
                                    {...(state.length == 0 &&
                                      dropDownLoaded && {
                                      errorMessage: API_ERROR_MESSAGE,
                                    })}
                                  />
                                </Grid>
                                {editInputBoxM ? (
                                  <Grid
                                    item
                                    lg={3}
                                    xl={2}
                                    className={classes.m2}
                                  >
                                    <RACButton
                                      variant="contained"
                                      color="primary"
                                      onClick={EditclickM}
                                      disabled={Agrtransferinit}
                                    >
                                      Edit Address
                                    </RACButton>
                                  </Grid>
                                ) : (
                                  <Grid
                                    item
                                    lg={3}
                                    xl={2}
                                    className={classes.m2}
                                  >
                                    {!editInputBoxM && (
                                      <>
                                        <RACButton
                                          variant="contained"
                                          color="primary"
                                          onClick={updateAddressInfoM}
                                          disabled={Agrtransferinit}
                                          className={customerStyleClasses.me2} //getAddress with M
                                        >
                                          Validate
                                        </RACButton>
                                        <RACButton
                                          className={`${customerStyleClasses.me2} ${customerStyleClasses.foc}`}
                                          onClick={CancelbuttM}
                                          variant="outlined"
                                          color="primary"
                                          disabled={Agrtransferinit}
                                        >
                                          Cancel
                                        </RACButton>
                                      </>
                                    )}
                                  </Grid>
                                )}
                              </Grid>
                            ) : null}
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                ) : null}
                {/* Address Info ends here */}

                {/*Contact Info starts here*/}
                {(legalHoldApplied !== 1 && isLegalHold !== true) ||
                  (isLegalHold && coWorkerRoleForCSpopup == 2) ? (
                  <React.Fragment>
                    <Grid
                      container
                      className={customerStyleClasses.px2}
                      id="ParentContactInformation"
                    >
                      <Typography className={classes.title}>
                        {' '}
                        Contact Information
                      </Typography>
                      <a
                        className={`${customerStyleClasses.racpadLink} ${classes.title}`}
                        style={{ cursor: 'pointer', marginLeft: '76%', }}
                        onClick={() => setInActConDet(true)}
                      >
                        Inactive Phone Numbers
                      </a>
                      {buildPhone()}
                    </Grid>
                    <Grid
                      item
                      md={12}
                      className={`${classes.mt2} ${customerStyleClasses.mb3}`}
                    >
                      <a
                        className={Agrtransferinit ? `${customerStyleClasses.racpadLink} ${customerStyleClasses.px2} ${classes.pointerEvent}` : `${customerStyleClasses.racpadLink} ${customerStyleClasses.px2}`}
                        style={{ cursor: 'pointer' }}
                        onClick={() => addanotherContact()}

                      >
                        + Add another Contact
                      </a>
                    </Grid>
                  </React.Fragment>
                ) : null}
                {/*Contact Info ends here*/}
                {(legalHoldApplied !== 1 && isLegalHold !== true) ||
                  (isLegalHold && coWorkerRoleForCSpopup == 2) ? (
                  <Grid
                    container
                    className={`${customerStyleClasses.px2} ${customerStyleClasses.mb2}`}
                  >
                    <Grid container>
                      <Typography className={classes.title}>
                        Communication Preferences
                      </Typography>
                    </Grid>
                    <Grid container>
                      <Card
                        className={`${customerStyleClasses.card} ${customerStyleClasses.w100}`}
                      >
                        <CardContent>
                          <Grid container spacing={2}>
                            <Grid item lg={3} xl={2}>
                              <Typography
                                component="label"
                                htmlFor="#"
                                className={classes.formLabelCustom}
                              >
                                Payment Reminder Call
                              </Typography>
                              <Grid item className={classes.formLabelInline}>
                                <RACRadio
                                  disabled={Agrtransferinit}
                                  value={'Y'}
                                  checked={
                                    customerInformation?.paymentRemainderCall ==
                                      'Y'
                                      ? true
                                      : false
                                  }
                                  onChange={(e: any) => HandleOnChange7(e)}
                                  label="Opt In"
                                  name="PaymentReminderCall"
                                />
                              </Grid>
                              <Grid item className={classes.formLabelInline}>
                                <RACRadio
                                  value={'N'}
                                  disabled={Agrtransferinit}
                                  checked={
                                    customerInformation?.paymentRemainderCall ==
                                      'N'
                                      ? true
                                      : false
                                  }
                                  onChange={(e: any) => HandleOnChange7(e)}
                                  label="Opt Out"
                                  name="PaymentReminderCall"
                                />
                              </Grid>
                            </Grid>
                            {/* <Grid item lg={3} xl={2}>
                              <Typography
                                component="label"
                                htmlFor="#"
                                className={classes.formLabelCustom}
                              >
                                Text Message Reminder
                              </Typography>
                              <Grid item className={classes.formLabelInline}>
                                <RACRadio
                                  value={'Y'}
                                  disabled={Agrtransferinit}
                                  checked={
                                    customerInformation?.textMessageRemainder ==
                                    'Y'
                                      ? true
                                      : false
                                  }
                                  onChange={(e: any) => HandleOnChange7(e)}
                                  label="Opt In"
                                  name="TextMessageReminder"
                                />
                              </Grid>
                              <Grid item className={classes.formLabelInline}>
                                <RACRadio
                                  value={'N'}
                                  disabled={Agrtransferinit}
                                  checked={
                                    customerInformation?.textMessageRemainder ==
                                    'N'
                                      ? true
                                      : false
                                  }
                                  onChange={(e: any) => HandleOnChange7(e)}
                                  label="Opt Out"
                                  name="TextMessageReminder"
                                />
                              </Grid>
                            </Grid> */}
                            <Grid item lg={3} xl={2}>
                              <RACSelect
                                inputLabel="Preferred Method of Contact "
                                options={preferredMethod}
                                isDisabled={Agrtransferinit}
                                defaultValue={
                                  customerInformation?.preferredMethod !==
                                    undefined &&
                                    customerInformation?.preferredMethod !==
                                    null &&
                                    customerInformation?.preferredMethod !== '' &&
                                    isDropDownValueExist(
                                      preferredMethod,
                                      customerInformation?.preferredMethod
                                    )
                                    ? customerInformation?.preferredMethod
                                    : '0'
                                }
                                required={false}
                                loading={
                                  preferredMethod.length == 0 && !dropDownLoaded
                                    ? true
                                    : false
                                }
                                onChange={(e: any) => {
                                  e.target.name = 'PreferredContactMethod';
                                  HandleOnChange8(e);
                                }}
                                {...(preferredMethod.length == 0 &&
                                  dropDownLoaded && {
                                  errorMessage: API_ERROR_MESSAGE,
                                })}
                              />
                            </Grid>
                            <Grid item lg={3} xl={2}>
                              <Typography
                                component="label"
                                htmlFor="PaymentReceipt"
                                className={classes.formLabelCustom}
                              >
                                Preferred Payment Receipt
                              </Typography>
                              <Grid
                                item
                                className={`${classes.formLabelInline} ${classes.ps0} ${customerStyleClasses.floatLeft}`}
                              >
                                <RACRadio
                                  value="PREM"
                                  disabled={Agrtransferinit}
                                  checked={
                                    customerInformation?.preferredPaymentReceipt ==
                                      'PREM'
                                      ? true
                                      : false
                                  }
                                  id={'PREMRadio'}
                                  onClick={(e: any) => HandleOnChange8(e)}
                                  label="Email"
                                  name="PreferredPaymentReceipt"
                                />
                              </Grid>
                              <Grid item className={classes.formLabelInline}>
                                <RACRadio
                                  value="EM"
                                  disabled={Agrtransferinit}
                                  checked={
                                    customerInformation?.preferredPaymentReceipt ==
                                      'EM'
                                      ? true
                                      : false
                                  }
                                  onClick={(e: any) => HandleOnChange8(e)}
                                  label="Text"
                                  name="PreferredPaymentReceipt"
                                />
                              </Grid>
                              <Grid item className={classes.formLabelInline}>
                                <RACRadio
                                  value="PRNT"
                                  disabled={Agrtransferinit}
                                  checked={
                                    customerInformation?.preferredPaymentReceipt ==
                                      'PRNT'
                                      ? true
                                      : false
                                  }
                                  onClick={(e: any) => HandleOnChange8(e)}
                                  label="Print"
                                  name="PreferredPaymentReceipt"
                                />
                              </Grid>
                            </Grid>
                            <Grid item lg={3} xl={2}>
                              <Typography
                                component="label"
                                htmlFor="#"
                                className={classes.formLabelCustom}
                              >
                                Preferred Language
                              </Typography>
                              <Grid item className={classes.formLabelInline}>
                                <RACRadio
                                  value="ENG"
                                  disabled={Agrtransferinit}
                                  checked={
                                    customerInformation?.preferredLanguage ==
                                      'ENG'
                                      ? true
                                      : false
                                  }
                                  onChange={(e: any) => HandleOnChange8(e)}
                                  label="English"
                                  name="PreferredLanguage"
                                />
                              </Grid>
                              <Grid item className={classes.formLabelInline}>
                                <RACRadio
                                  value="SPA"
                                  disabled={Agrtransferinit}
                                  checked={
                                    customerInformation?.preferredLanguage ==
                                      'SPA'
                                      ? true
                                      : false
                                  }
                                  onChange={(e: any) => HandleOnChange8(e)}
                                  label="Spanish"
                                  name="PreferredLanguage"
                                />
                              </Grid>
                            </Grid>
                            <Grid item lg={3} xl={2}>
                              <Typography
                                component="label"
                                htmlFor="PaymentReceipt"
                                className={classes.formLabelCustom}
                              >
                                Accept Solicitation
                              </Typography>
                              <Grid item className={classes.formLabelInline}>
                                <RACCheckBox
                                  label="Phone"
                                  disabled={Agrtransferinit}
                                  size="small"
                                  name={'AcceptSolicitation'}
                                  onChange={(e: any) => {
                                    HandleOnChange8(e);
                                  }}
                                  value={
                                    customerInformation?.phoneSolicitationAllowed ==
                                      'Y'
                                      ? 'N'
                                      : 'Y'
                                  }
                                  checked={
                                    customerInformation?.phoneSolicitationAllowed ==
                                      'Y'
                                      ? true
                                      : false
                                  }
                                />
                              </Grid>
                              <Grid item className={classes.formLabelInline}>
                                <RACCheckBox
                                  disabled={Agrtransferinit}
                                  label="Mail"
                                  size="small"
                                  name={'AcceptSolicitation1'}
                                  onChange={(e: any) => {
                                    HandleOnChange8(e);
                                  }}
                                  value={
                                    customerInformation?.mailSolicitationAllowed ==
                                      'Y'
                                      ? 'N'
                                      : 'Y'
                                  }
                                  checked={
                                    customerInformation?.mailSolicitationAllowed ==
                                      'Y'
                                      ? true
                                      : false
                                  }
                                />
                              </Grid>
                              <Grid item className={classes.formLabelInline}>
                                <RACCheckBox
                                  label="Email"
                                  size="small"
                                  disabled={Agrtransferinit}
                                  name={'AcceptSolicitation2'}
                                  onChange={(e: any) => {
                                    HandleOnChange8(e);
                                  }}
                                  value={
                                    customerInformation?.emailSolicitationAllowed ==
                                      'Y'
                                      ? 'N'
                                      : 'Y'
                                  }
                                  checked={
                                    customerInformation?.emailSolicitationAllowed ==
                                      'Y'
                                      ? true
                                      : false
                                  }
                                />
                              </Grid>
                            </Grid>
                            <Grid item lg={3} xl={2}>
                              <RACSelect
                                inputLabel="Route"
                                options={addressInfoRoute}
                                isDisabled={Agrtransferinit}
                                defaultValue={
                                  customerInformation?.route !== undefined &&
                                    customerInformation?.route &&
                                    isDropDownValueExist(
                                      addressInfoRoute,
                                      customerInformation?.route
                                    )
                                    ? customerInformation?.route
                                    : '0'
                                }
                                loading={
                                  addressInfoRoute.length == 0 &&
                                    !dropDownLoaded
                                    ? true
                                    : false
                                }
                                onChange={(e: any) => {
                                  e.target.name = 'PreferredRoute';
                                  HandleOnChange9(e);
                                }}
                                {...(addressInfoRoute.length == 0 &&
                                  dropDownLoaded && {
                                  errorMessage: API_ERROR_MESSAGE,
                                })}
                              />
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                ) : null}
                {/*Legal starts here*/}
                {pageType == undefined || pageType == 'customer' ? (
                  <Grid
                    container
                    className={`${classes.px1} ${customerStyleClasses.mb3}`}
                  >
                    <Grid item md={12}>
                      <Typography className={classes.title}>Legal</Typography>
                    </Grid>
                    <Grid item md={12}>
                      <Card
                        className={`${customerStyleClasses.card} ${customerStyleClasses.w100}`}
                      >
                        <CardContent className={classes.pb3}>
                          <Grid container spacing={2} id="LegalDiv">
                            <Grid
                              item
                              className={`${classes.widthAuto} ${classes.pt1}`}
                            >
                              <RACCheckBox
                                label="Legal Hold"
                                size="small"
                                disabled={true}
                                value={isLegalHold === true ? 'true' : 'false'}
                                checked={isLegalHold}
                              />
                            </Grid>
                            <Grid
                              item
                              className={`${classes.widthAuto} ${classes.pt1}`}
                            >
                              <Grid item>
                                <RACCheckBox
                                  label="Skip"
                                  size="small"
                                  name={'SkipCustomer'}
                                  disabled={true}
                                  value={
                                    customerInformation?.skip == 'Y' ? 'N' : 'Y'
                                  }
                                  checked={
                                    customerInformation?.skip == 'Y'
                                      ? true
                                      : false
                                  }
                                  onChange={() => handleOnchangeSkipHard('S')}
                                />
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              className={`${classes.widthAuto} ${classes.pt1}`}
                            >
                              <Grid>
                                <RACCheckBox
                                  label="Hard"
                                  size="small"
                                  name="HardCustomer"
                                  value={
                                    customerInformation?.hard == 'Y' ? 'N' : 'Y'
                                  }
                                  checked={
                                    customerInformation?.hard == 'Y'
                                      ? true
                                      : false
                                  }
                                  onChange={() => handleOnchangeSkipHard('H')}
                                  disabled={true}
                                />
                              </Grid>
                            </Grid>
                            {LegalHold?.legalHoldStatus !== undefined &&
                              LegalHold?.legalHoldStatus !== null &&
                              (LegalHold?.legalHoldStatus === true ||
                                LegalHold?.legalHoldStatus === 'true') ? (
                              <Grid item md={4} lg={2}>
                                <RACSelect
                                  inputLabel="Legal Hold Reason"
                                  options={LegalOption}
                                  defaultValue={
                                    LegalHold?.legalHoldStatus !== undefined &&
                                      LegalHold?.legalHoldStatus &&
                                      isDropDownValueExist(
                                        LegalOption,
                                        LegalHold?.legalHoldStatus
                                      )
                                      ? LegalHold?.refCode
                                      : '0'
                                  }
                                  loading={
                                    LegalOption.length == 0 && !dropDownLoaded
                                      ? true
                                      : false
                                  }
                                  onChange={(e: any) => {
                                    e.target.name = 'PreferredRoute';
                                    HandleOnChange9(e);
                                  }}
                                  {...(LegalOption.length == 0 &&
                                    dropDownLoaded && {
                                    errorMessage: API_ERROR_MESSAGE,
                                  })}
                                  isDisabled={true}
                                />
                              </Grid>
                            ) : null}
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                ) : null}
                {/*Legal ends here*/}
                {/*Tax info starts here*/}
                {(legalHoldApplied !== 1 && isLegalHold !== true) ||
                  (isLegalHold && coWorkerRoleForCSpopup == 2) ? (
                  <Grid container className={`${classes.px2} ${classes.mb5}`}>
                    <Grid md={12}>
                      <Grid item className={classes.formCheckToggle}>
                        <Typography
                          component="label"
                          className={`${customerStyleClasses.floatLeft} ${customerStyleClasses.me2}`}
                          htmlFor="taxexempt_toggle"
                        >
                          Tax Exempt
                        </Typography>
                        <RACToggle
                          id={'TaxExempt'}
                          disabled={Agrtransferinit}
                          checked={taxExSt !== null ? true : taxexempt_toggle}
                          toggleColor="#0d6efd"
                          labelClassName={classes.switchLabel}
                          OnChange={(e: any) => {
                            Taxidclick();
                            HandleOnChange(e);
                          }}
                        />
                      </Grid>
                    </Grid>
                    {TaxIDActive === true ? (
                      <Card
                        className={`${customerStyleClasses.card} ${customerStyleClasses.w100}`}
                      >
                        <CardContent>
                          <Grid container spacing={2}>
                            <Grid item md={3} lg={2}>
                              <RACTextbox
                                isCurrency={false}
                                required={true}
                                type={taxExSt !== null ? 'password' : 'text'}
                                inputlabel="Tax ID"
                                name={`tax_id`}
                                OnChange={(e: any) => HandleOnChange6(e)}
                                maxlength={30}
                                value={customerInformation?.federalTaxId}
                                disabled={taxExSt !== null ? true : false}
                                errorMessage={
                                  customerInformation?.federalTaxId !==
                                    undefined &&
                                    (customerInformation?.federalTaxId == null ||
                                      customerInformation?.federalTaxId == '') &&
                                    isrequired === true
                                    ? FIELD_ERROR
                                    : ''
                                }
                              />
                            </Grid>
                            {pageType == undefined || pageType == 'customer' ? (
                              <Grid
                                item
                                md={3}
                                lg={2}
                                className={customerStyleClasses.mb3}
                              >
                                <RACSelect
                                  inputLabel="Status"
                                  options={taxExemptStatus}
                                  isDisabled={true}
                                  defaultValue={
                                    customerInformation?.taxExemptStatus &&
                                      isDropDownValueExist(
                                        taxExemptStatus,
                                        customerInformation?.taxExemptStatus
                                      )
                                      ? customerInformation?.taxExemptStatus
                                      : '0'
                                  }
                                  loading={
                                    taxExemptStatus.length == 0 &&
                                      !dropDownLoaded
                                      ? true
                                      : false
                                  }
                                  onChange={(e: any) => {
                                    e.target.name = `tax_status`;
                                    HandleOnChange6(e);
                                  }}
                                  {...(taxExemptStatus.length == 0 &&
                                    dropDownLoaded && {
                                    errorMessage: API_ERROR_MESSAGE,
                                  })}
                                />
                              </Grid>
                            ) : null}
                            {pageType == undefined || pageType == 'customer' ? (
                              <Grid item md={4} lg={2}>
                                <RACDatePicker
                                  value={
                                    customerInformation?.submittedDate !==
                                      undefined &&
                                      customerInformation?.submittedDate !==
                                      null &&
                                      customerInformation?.submittedDate !== ''
                                      ? buildDate(
                                        customerInformation?.submittedDate
                                      )
                                      : ''
                                  }
                                  label="Effective Date"
                                  name="tax_EffectiveDate"
                                  disabled={true}
                                  onChange={(e: any) => HandleOnChange6(e)}
                                />
                              </Grid>
                            ) : null}
                            {pageType == undefined || pageType == 'customer' ? (
                              <Grid item md={4} lg={2}>
                                <RACDatePicker
                                  value={customerInformation?.expirationDate}
                                  label="Expiration Date"
                                  name="tax_ExpirationDate"
                                  disabled={true}
                                  onChange={(e: any) => HandleOnChange6(e)}
                                />
                              </Grid>
                            ) : null}
                            {pageType == undefined || pageType == 'customer' ? (
                              <Grid item md={4} lg={2}>
                                <RACDatePicker
                                  value={customerInformation?.appDenialDate}
                                  label="App/Denial Date"
                                  name="tax_appDenailDate"
                                  disabled={true}
                                  onChange={(e: any) => HandleOnChange7(e)}
                                />
                              </Grid>
                            ) : null}
                          </Grid>
                          <Grid container>
                            <Grid item md={4} lg={2} className={classes.mt2}>
                              <RACButton
                                variant="contained"
                                color="primary"
                                disabled={
                                  customerInformation?.federalTaxId ==
                                    undefined ||
                                    customerInformation?.federalTaxId == null ||
                                    customerInformation?.federalTaxId == ''
                                    ? true
                                    : false
                                }
                                onClick={() => printHTML()}
                              >
                                Print Fax Cover Sheet
                              </RACButton>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    ) : null}
                  </Grid>
                ) : null}
              </Grid>
            ) : null}
            {((pageType == undefined || pageType == 'customer') &&
              activetab == 1) ||
              (pageType !== 'customer' && createActiveTab == 1) ? (
              (legalHoldApplied !== 1 && isLegalHold !== true) ||
                (isLegalHold && coWorkerRoleForCSpopup == 2) ? (
                <Grid
                  container
                  id="employmentinfo"
                  //className={ Agrtransferinit?`${classes.custGridMargin} ${customerStyleClasses.mb3} ${customerStyleClasses.px2} ${classes.pointerEvent}`:`${classes.custGridMargin} ${customerStyleClasses.mb3} ${customerStyleClasses.px2}`}
                  className={`${classes.custGridMargin} ${customerStyleClasses.mb3} ${customerStyleClasses.px2}`}

                >
                  <Typography className={classes.title}>
                    Employer’s Information

                  </Typography>
                  {employerInfoFunc()}
                  <Grid container>
                    <a
                      className={Agrtransferinit ? `${customerStyleClasses.racpadLink} ${classes.mb5} ${classes.pointerEvent}` : `${customerStyleClasses.racpadLink} ${classes.mb5}`}
                      style={{ cursor: 'pointer' }}
                      onClick={() => addanotherEmployement()}
                    >
                      + Add another Employment
                    </a>
                  </Grid>
                </Grid>
              ) : null
            ) : null}

            {((pageType == undefined || pageType == 'customer') &&
              activetab == 2) ||
              (pageType !== 'customer' && createActiveTab == 2) ? (
              (legalHoldApplied !== 1 && isLegalHold !== true) ||
                (isLegalHold && coWorkerRoleForCSpopup == 2) ? (
                <Grid
                  container
                  id="residenceinfo"
                  // className={ Agrtransferinit?`${customerStyleClasses.px2} ${classes.pointerEvent}`:`${customerStyleClasses.px2}`}
                  className={`${customerStyleClasses.px2}`}

                >
                  <Grid item md={12}>
                    <Typography className={classes.title}>
                      Residence Information
                    </Typography>
                  </Grid>
                  <Grid container>
                    <Card
                      className={`${customerStyleClasses.card} ${customerStyleClasses.w100}`}
                    >
                      <CardContent>
                        <Grid container>
                          <Grid
                            item
                            md={12}
                            className={customerStyleClasses.mb3}
                          >
                            <Grid
                              item
                              className={`${classes.racCol12} ${customerStyleClasses.floatLeft}`}
                            >
                              <Grid
                                item
                                className={customerStyleClasses.floatLeft}
                              >
                                <Typography
                                  component="label"
                                  className={`${customerStyleClasses.formLabel} ${customerStyleClasses.w100} ${customerStyleClasses.floatLeft}`}
                                >
                                  Does the customer rent or own the residence?
                                </Typography>

                                <Grid
                                  item
                                  className={`${classes.formLabelInline} ${classes.ps0}`}
                                >
                                  <RACRadio
                                    name="inlineRadioOptions"
                                    id="rent"
                                    disabled={Agrtransferinit}
                                    checked={
                                      Residences?.residenceType == 'RENT'
                                        ? true
                                        : false
                                    }
                                    onChange={(e: any) => {
                                      HandleOnChange10(e);
                                    }}
                                    value=""
                                    label="Rent"
                                  />
                                </Grid>
                                <Grid item className={classes.formLabelInline}>
                                  <RACRadio
                                    name="inlineRadioOptionsOwn"
                                    id="own"
                                    disabled={Agrtransferinit}
                                    checked={
                                      Residences?.residenceType == 'OWN'
                                        ? true
                                        : false
                                    }
                                    onChange={(e: any) => {
                                      HandleOnChange10(e);
                                    }}
                                    label="Own"
                                    value={undefined}
                                  />
                                </Grid>
                              </Grid>
                              {pageType == undefined ||
                                pageType == 'customer' ? (
                                <Grid
                                  item
                                  className={customerStyleClasses.floatRight}
                                >
                                  <Grid item>
                                    <RACCheckBox
                                      id="flexCheckDefault77"
                                      name="residenceInfoVerifiedCheckBox"
                                      checked={
                                        // Residences?.residenceType == 'RENT'
                                        //   ? Residences.verifiedCheckBox !==
                                        //       undefined &&
                                        //     Residences.verifiedCheckBox !==
                                        //       null &&
                                        //     Residences.verifiedCheckBox !== ''
                                        //     ? true
                                        //     : false
                                        //   : checkVerifyDate(
                                        //       Residences.ownVerifieddate
                                        //     )
                                        Residences?.residenceType == 'RENT'
                                          ? Residences.verifiedCheckBox
                                          : Residences?.ownVerifieddate
                                      }
                                      label="Verified"
                                      disabled={Agrtransferinit ? true : verifiedResidenceDisable()}
                                      onChange={(e: any) => {
                                        HandleOnChange9(
                                          e,
                                          Residences?.residenceType == 'RENT'
                                            ? 'R'
                                            : 'O'
                                        );
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              ) : null}
                            </Grid>
                            {Residences?.residenceType == 'OWN' ? (
                              <Grid
                                item
                                className={`${classes.racCol12} ${customerStyleClasses.floatLeft} ${classes.mt3}`}
                              >
                                <Grid
                                  item
                                  className={`${classes.formLabelInline} ${classes.ps0}`}
                                >
                                  <RACCheckBox
                                    id="mortgage"
                                    name="inlinemortagechk"
                                    disabled={Agrtransferinit}
                                    checked={
                                      Residences?.mortgage !== undefined &&
                                        !Residences?.mortgage
                                        ? false
                                        : true
                                    }
                                    onChange={(e: any) => {
                                      HandleOnChange10(e);
                                    }}
                                    label="Mortgage"
                                  />
                                </Grid>
                              </Grid>
                            ) : null}
                          </Grid>
                          <Grid container spacing={2}>
                            {Residences?.residenceType === 'OWN' ? (
                              <Grid
                                item
                                className={`${classes.racCol2} ${customerStyleClasses.floatLeft}`}
                              >
                                <Grid item className={classes.racCol12}>
                                  <RACDatePicker
                                    value={
                                      Residences?.residenceSince !== undefined
                                        ? Residences?.residenceSince
                                        : ''
                                    }
                                    id="a11y_moveindate"
                                    label="Move in Date"
                                    name="lldateOwn"
                                    disabled={Agrtransferinit}
                                    onChange={(e: any) =>
                                      setMoveInDate(e, 'lldateOwn')
                                    }
                                  />
                                </Grid>
                              </Grid>
                            ) : null}
                            {Residences?.mortgage !== undefined &&
                              Residences?.mortgage === true &&
                              Residences?.residenceType === 'OWN' ? (
                              <React.Fragment>
                                <Grid item className={classes.racCol2}>
                                  <RACTextbox
                                    isCurrency={false}
                                    required={true}
                                    type={'text'}
                                    disabled={Agrtransferinit}
                                    inputlabel="Mortgage Company Name"
                                    name={`llnumberCompany`}
                                    OnChange={(e: any) => HandleOnChange11M(e)}
                                    maxlength={30}
                                    value={
                                      Residences?.mortgageCompanyName !==
                                        undefined
                                        ? Residences?.mortgageCompanyName
                                        : ''
                                    }
                                    errorMessage={
                                      Residences?.mortgageCompanyName !==
                                        undefined &&
                                        Residences?.mortgageCompanyName == '' &&
                                        isrequired === true
                                        ? FIELD_ERROR
                                        : ''
                                    }
                                  />
                                </Grid>
                                <Grid item className={classes.racCol2}>
                                  <RACTextbox
                                    isCurrency={true}
                                    type={'number'}
                                    disabled={Agrtransferinit}
                                    digitFormat={'currency'}
                                    inputlabel="Payment Amount"
                                    name={`llamountPaymentAmt`}
                                    OnChange={(e: any) => HandleOnChange11M(e)}
                                    maxlength={10}
                                    value={
                                      Residences?.monthlyMortgagePayment !==
                                        undefined &&
                                        Residences?.monthlyMortgagePayment !==
                                        null &&
                                        Residences?.monthlyMortgagePayment !==
                                        'NaN'
                                        ? Residences?.monthlyMortgagePayment
                                        : '0.00'
                                    }
                                    className={classes.custDigit}
                                    dollarTextClassName={
                                      classes.currencyDollarField
                                    }
                                  />
                                </Grid>
                              </React.Fragment>
                            ) : Residences?.residenceType == 'RENT' ? (
                              <React.Fragment>
                                <Grid item className={classes.racCol2}>
                                  <RACTextbox
                                    isCurrency={false}
                                    required={true}
                                    type={'text'}
                                    disabled={Agrtransferinit}
                                    inputlabel="Landlord’s First Name"
                                    name={`llname`}
                                    OnChange={(e: any) => HandleOnChange10(e)}
                                    maxlength={30}
                                    value={
                                      Residences?.landlordFirstName !==
                                        undefined
                                        ? Residences?.landlordFirstName
                                        : ''
                                    }
                                    errorMessage={
                                      Residences?.landlordFirstName !==
                                        undefined &&
                                        Residences?.landlordFirstName == '' &&
                                        Residences?.owned === false &&
                                        isrequired === true
                                        ? FIELD_ERROR
                                        : ''
                                    }
                                  />
                                </Grid>
                                <Grid item className={classes.racCol2}>
                                  <RACTextbox
                                    isCurrency={false}
                                    required={true}
                                    type={'text'}
                                    disabled={Agrtransferinit}
                                    inputlabel="Landlord’s Last Name"
                                    name={`llLastname`}
                                    OnChange={(e: any) => HandleOnChange10(e)}
                                    maxlength={30}
                                    value={
                                      Residences?.landlordLastName !== undefined
                                        ? Residences?.landlordLastName
                                        : ''
                                    }
                                    errorMessage={
                                      Residences?.landlordLastName !==
                                        undefined &&
                                        Residences?.landlordLastName == '' &&
                                        Residences?.owned === false &&
                                        isrequired === true
                                        ? FIELD_ERROR
                                        : ''
                                    }
                                  />
                                </Grid>
                                <Grid item className={classes.racCol2}>
                                  <RACTextbox
                                    isCurrency={false}
                                    required={true}
                                    type={'text'}
                                    disabled={Agrtransferinit}
                                    inputlabel="Landlord’s Phone Number"
                                    name={`llnumber`}
                                    OnChange={(e: any) => HandleOnChange10(e)}
                                    maxlength={13}
                                    value={
                                      Residences?.phoneNumber !== undefined
                                        ? formatPhone(Residences.phoneNumber)
                                        : ''
                                    }
                                    errorMessage={
                                      Residences?.phoneNumber !== undefined &&
                                        Residences?.phoneNumber == '' &&
                                        Residences?.owned === false &&
                                        isrequired === true
                                        ? FIELD_ERROR
                                        : isrequired === true &&
                                          Residences?.owned === false &&
                                          (Residences?.phoneNumber ==
                                            undefined ||
                                            Residences?.phoneNumber == null ||
                                            Residences?.phoneNumber.length < 10)
                                          ? 'Please enter valid phone number'
                                          : ''
                                    }
                                  />
                                </Grid>
                                <Grid item className={classes.racCol2}>
                                  <RACTextbox
                                    isCurrency={true}
                                    type={'number'}
                                    digitFormat={'currency'}
                                    disabled={Agrtransferinit}
                                    inputlabel="Payment Amount"
                                    name={`llamount`}
                                    OnChange={(e: any) => HandleOnChange11(e)}
                                    maxlength={10}
                                    value={
                                      Residences?.monthlyRent !== undefined &&
                                        Residences?.monthlyRent !== null &&
                                        Residences?.monthlyRent !== 'NaN'
                                        ? Residences?.monthlyRent
                                        : '0.00'
                                    }
                                    className={classes.custDigit}
                                    dollarTextClassName={
                                      classes.currencyDollarField
                                    }
                                  />
                                </Grid>
                                <Grid item className={classes.racCol2}>
                                  <RACDatePicker
                                    value={
                                      Residences?.moveInDate !== undefined
                                        ? Residences?.moveInDate
                                        : ''
                                    }
                                    disabled={Agrtransferinit}
                                    label="Move in Date"
                                    name="lldateMove"
                                    onChange={(e: any) =>
                                      setMoveInDate(e, 'lldateMove')
                                    }
                                  />
                                </Grid>
                              </React.Fragment>
                            ) : null}
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              ) : null
            ) : null}

            {((pageType == undefined || pageType == 'customer') &&
              activetab == 3) ||
              (pageType !== 'customer' && createActiveTab == 3) ? (
              (legalHoldApplied !== 1 && isLegalHold !== true) ||
                (isLegalHold && coWorkerRoleForCSpopup == 2) ? (
                <Grid
                  container
                  id="referenceinfo"
                  //className={ Agrtransferinit?`${classes.custGridMargin} ${customerStyleClasses.px2} ${classes.pointerEvent}`:`${classes.custGridMargin} ${customerStyleClasses.px2}`}
                  className={`${classes.custGridMargin} ${customerStyleClasses.px2}`}
                >
                  <Grid item md={12}>
                    <Typography variant="h6" className={classes.title}>
                      Reference Information
                    </Typography>
                    {referenceInfoFunc()}
                  </Grid>
                  <Grid item md={12} className={customerStyleClasses.mb3}>
                    <a
                      className={Agrtransferinit ? `${customerStyleClasses.racpadLink} ${classes.pointerEvent}` : `${customerStyleClasses.racpadLink}`}
                      style={{ cursor: 'pointer' }}
                      onClick={() => addanotherReference()}
                    >
                      + Add another Reference
                    </a>
                  </Grid>
                </Grid>
              ) : null
            ) : null}
            {((pageType == undefined || pageType == 'customer') &&
              activetab == 4) ||
              (pageType !== 'customer' && createActiveTab == 4) ? (
              (legalHoldApplied !== 1 && isLegalHold !== true) ||
                (isLegalHold && coWorkerRoleForCSpopup == 2) ? (
                <Grid
                  container
                  id={'vehicleinfo'}
                  className={`${classes.custGridMargin} ${customerStyleClasses.px2}`}
                >
                  <Grid item md={12}>
                    <Typography className={classes.title}>
                      Vehicle Information
                    </Typography>
                    {buildVechicleBlock()}
                  </Grid>
                  <Grid item md={12} className={customerStyleClasses.mb3}>
                    <a
                      className={Agrtransferinit ? `${customerStyleClasses.racpadLink} ${classes.pointerEvent}` : `${customerStyleClasses.racpadLink}`}
                      onClick={() => addanotherVehicle()}
                    >
                      + Add another Vehicle
                    </a>
                  </Grid>
                </Grid>
              ) : null
            ) : null}
            {((pageType == undefined || pageType == 'customer') &&
              activetab == 5) ||
              (pageType !== 'customer' && createActiveTab == 5) ? (
              (legalHoldApplied !== 1 && isLegalHold !== true) ||
                (isLegalHold && coWorkerRoleForCSpopup == 2) ? (
                <Grid
                  container
                  id="agreementhistoryinfo"
                  className={Agrtransferinit ? `${classes.pointerEvent}${customerStyleClasses.mb3} ${customerStyleClasses.px2}` : `${customerStyleClasses.mb3} ${customerStyleClasses.px2}`}
                >
                  <Grid item md={12}>
                    <Typography className={classes.title}>
                      Agreement History
                    </Typography>
                  </Grid>
                  <Grid item md={12} className={customerStyleClasses.mb100}>
                    <Card
                      className={`${customerStyleClasses.card} ${customerStyleClasses.w100}`}
                    >
                      <CardContent>
                        <Grid md={12}>
                          {agreementInf === undefined ? (
                            <Grid md={12} className={classes.textCenter}>
                              <CircularProgress />
                            </Grid>
                          ) : agreementInf !== undefined &&
                            agreementInf !== null &&
                            agreementInf.length > 0 ? (
                            <>
                              <RACTable
                                renderTableHead={renderAgreementHistoryHeader}
                                renderTableContent={
                                  renderAgreementHistoryContent
                                }
                                className={classes.agrementGridMC}
                              />
                            </>
                          ) : (
                            <Grid md={12} className={classes.textCenter}>
                              <Alerticon></Alerticon>
                              <Typography
                                className={`${classes.mt3} ${classes.textCenter}`}
                              >
                                {agreementInf !== null
                                  ? 'No rental agreements found'
                                  : // eslint-disable-next-line sonarjs/no-duplicate-string
                                  'Something went wrong'}
                              </Typography>
                            </Grid>
                          )}
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              ) : null
            ) : null}
            {((pageType == undefined || pageType == 'customer') &&
              activetab == 6) ||
              (isLegalHold && coWorkerRoleForCSpopup == 2) ? (
              <Grid
                container
                id="dehistoryinfo"
                className={`${customerStyleClasses.mb3} ${customerStyleClasses.px2}`}
              >
                <Grid item md={12}>
                  <Typography className={classes.title}>DE History</Typography>
                </Grid>
                <Grid item md={12} className={customerStyleClasses.mb100}>
                  <Card
                    className={`${customerStyleClasses.card} ${customerStyleClasses.w100}`}
                  >
                    <CardContent>
                      <Grid md={12}>
                        {deHistoryResponse == 'LOADING' ? (
                          <Grid md={12} className={classes.textCenter}>
                            <CircularProgress />
                          </Grid>
                        ) : deHistoryResponse !== 'ERROR' ? (
                          <>
                            {/* <RACTable
                              renderTableHead={renderDEHistoryHeader}
                              renderTableContent={renderDEHistoryData}
                              className={classes.agrementGridMC}
                            /> */}
                            {infiniteScrollDEHistory()}
                          </>
                        ) : (
                          <Grid md={12} className={classes.textCenter}>
                            <Alerticon></Alerticon>
                            <Typography
                              className={`${classes.mt3} ${classes.textCenter}`}
                            >
                              {deHistoryResponse == 'ERROR'
                                ? 'Something went wrong'
                                : ''}
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            ) : null}
            {/* ) : null} */}
          </React.Fragment>
        </Grid>
        {secondLevelAuthenOn !== null ? (
          <SecondFactor
            setTwoFactorCancelClick={() => setSecondLevelAuthenOn(null)}
            setTwoFactorCompleted={(e: any) => seconduthenResponses(e)}
            moduleName={secondLevelAuthenOn}
            currentRole={coWorkerRole}
          ></SecondFactor>
        ) : null}

        {slapopupauthentication !== false ? (
          <SecondFactor
            setTwoFactorCancelClick={() => {
              setSecondLevelAuthenOn(null);
              setcustomerauthDOB(false);
              setslapopupauthentication(false);
            }}
            setTwoFactorCompleted={(e: any) => seconduthenResponsesofSSNandDOB(e)}
            moduleName="ViewPIIData"
            currentRole={coWorkerRole}
          ></SecondFactor>
        ) : null}

        <div id="printHtmlDiv" style={{ display: 'none' }}>
          {printTemplate(getStore)}
        </div>
        <Grid
          item
          classes={{
            root: clsx(
              customerStyleClasses.customerToolbar,
              customerStyleClasses.fixedBottom
            ),
          }}
          style={{ zIndex: 0 }}
        >
          <Grid item className={customerStyleClasses.floatLeft}>
            <RACButton
              variant="outlined"
              color="primary"
              className={`${classes.me1} ${customerStyleClasses.foc}`}
              onClick={() => {
                customerInfoChange === true
                  ? setcustomerInfoChangePopUp(true)
                  : CancelRedirect();
              }}
            >
              Cancel
            </RACButton>
            {pageType !== 'customer' && createActiveTab > 0 ? (
              <RACButton
                variant="outlined"
                color="primary"
                className={customerStyleClasses.foc}
                onClick={() => validateNextCustomer('Back')}
              >
                <PreviousBtn className={customerStyleClasses.me2}></PreviousBtn>{' '}
                Previous
              </RACButton>
            ) : null}
          </Grid>
          <Grid className={`${customerStyleClasses.floatRight}`} style={{ display: 'flex' }}>
            {pageType == undefined ||
              (pageType == 'customer' &&
                activetab == 5 &&
                splitEligibleAgrs?.length > 0) ? (
              (legalHoldApplied !== 1 && isLegalHold !== true) ||
                (isLegalHold && coWorkerRoleForCSpopup == 2) ? (
                featureFlagDetails?.SplitAgreement == 1 ?
                  <Grid title={splitCoCustomerAvailble ? '' : 'Co-Customer not found'}>
                    <RACButton
                      variant="contained"
                      color="primary"
                      className={`${customerStyleClasses.floatLeft} ${classes.me1}`}
                      onClick={() => {
                        splitBtnClick();
                      }}
                      disabled={splitCoCustomerAvailble ? false : true}
                    >
                      Split
                    </RACButton>
                  </Grid> : null
              ) : null
            ) : null}
            {(pageType == undefined || pageType == 'customer'
              && activetab == 0 && storeTransferAllowed)
              ? (
                <Grid title={transferAgrContent}>
                  <RACButton
                    variant="contained"
                    color="primary"
                    disabled={Agrtransferinit ? true : transferAgr}
                    className={`${customerStyleClasses.floatLeft} ${classes.me1}`}
                    onClick={() => history.push(`/agreement/transfer/${customerId}`)}
                  >
                    Transfer Agreement
                  </RACButton>
                </Grid>

              ) : null}
            {pageType == 'customer' ? (
              <Grid
                item
                className={`${customerStyleClasses.floatLeft} ${classes.me1}`}
              >
                <Link
                  style={{ color: 'inherit', textDecoration: 'unset' }}
                  to={{
                    pathname: `${AppRoute.accountManagementCustomer}/${customerId}`,
                    state: {
                      redirectSource: 'customer',
                    },
                  }}
                >
                  <RACButton
                    variant="contained"
                    color="primary"
                    disabled={Agrtransferinit}
                    onClick={() => amCustomerRedirect()}
                  >
                    Account Management
                  </RACButton>
                </Link>
              </Grid>
            ) : null}
            {customerIdQueryParam == null ? (
              legalHoldApplied !== 1 && isLegalHold !== true ? (
                <Grid
                  item
                  className={`${customerStyleClasses.floatLeft} ${classes.me1} ${classes.pr}`}
                  role="group"
                >
                  {' '}
                  <RACButton
                    variant="contained"
                    color="primary"
                    disabled={Agrtransferinit}
                    onClick={() => agreementMenu()}
                  >
                    Create Agreement
                    <span className={classes.dropdowntoggle}></span>
                  </RACButton>
                  {createAgrClicked ? (
                    <Grid
                      className={
                        checkAction == ''
                          ? `${classes.hideRental}`
                          : `${classes.popupRental}`
                      }
                    >
                      <List
                        data-testid="UlButtonsId"
                        aria-labelledby="btnGroupDrop1"
                        style={{
                          left: '947 !important',
                          top: '-35px !important',
                        }}
                      >
                        <ListItem>
                          <a
                            className={`${classes.dropdownitemcustom} ${classes.semiBold} ${classes.bgBlue}`}
                            data-bs-toggle="modal"
                            data-testid="TerminateLDWId"
                            onClick={() => handleEPPAuthentication('AGREEMENT')}
                            data-bs-target="#terminateldw"
                          >
                            Rental
                          </a>
                        </ListItem>
                        {featureFlagDetails?.CustomerOrder == '1' ?
                          <ListItem>
                            <a
                              className={`${classes.dropdownitemcustom} ${classes.semiBold} ${classes.bgBlue}`}
                              data-bs-toggle="modal"
                              data-testid="TerminateLDWId"
                              onClick={() => handleEPPAuthentication  ('CO')}
                              data-bs-target="#terminateldw"
                            >
                              Customer order
                            </a>
                          </ListItem> : null}
                        {featureFlagDetails?.PromoAgreement == '1' ?
                          <ListItem>
                            <a
                              className={`${classes.dropdownitemcustom} ${classes.semiBold} ${classes.bgBlue}`}
                              data-bs-toggle="modal"
                              data-testid="TerminateLDWId"
                              onClick={() => handleEPPAuthentication('PROMO')}
                              data-bs-target="#terminateldw"
                            >
                              Promo
                            </a>
                          </ListItem> : null}
                        {checkAction !== undefined &&
                          checkAction !== null &&
                          checkAction !== '' &&
                          storeReinstatementAllowed === true ? (
                          <ListItem>
                            <a
                              className={`${classes.dropdownitemcustom} ${classes.semiBold} ${classes.bgBlue}`}
                              data-testid="ActiveLDWId"
                              data-bs-toggle="modal"
                              onClick={() => {
                                handleEPPAuthentication('ReInstatement');
                              }}
                              data-bs-target="#terminateldw"
                            >
                              Reinstatement
                            </a>
                          </ListItem>
                        ) : null}
                      </List>
                    </Grid>
                  ) : null}
                </Grid>
              ) : null
            ) : null}
            {pageType == undefined || pageType == 'customer' ? (
              (legalHoldApplied !== 1 && isLegalHold !== true) ||
                (isLegalHold && coWorkerRoleForCSpopup == 2) ? (
                <RACButton
                  variant="contained"
                  color="primary"
                  disabled={Agrtransferinit}
                  className={classes.me1}
                  onClick={() => agreementRedirect('PAYMENT')}
                >
                  Take Payment
                </RACButton>
              ) : null
            ) : null}
            {pageType == undefined || pageType == 'customer' ? (
              (legalHoldApplied !== 1 && isLegalHold !== true) ||
                (isLegalHold && coWorkerRoleForCSpopup == 2) ? (
                <RACButton
                  variant="contained"
                  color="primary"
                  className={classes.me1}
                  onClick={() => printOrderForm()}
                >
                  Print Order Form
                </RACButton>
              ) : null
            ) : null}
            {(legalHoldApplied !== 1 && isLegalHold !== true) ||
              (isLegalHold &&
                coWorkerRoleForCSpopup == 2 &&
                customerCoverageInf?.clubStatus !== undefined) ? (
              customerCoverageInf !== undefined &&
                customerCoverageInf?.clubStatus !== undefined &&
                (customerCoverageInf?.clubStatus == 'Active' ||
                  customerCoverageInf?.clubStatus == 'A' ||
                  customerCoverageInf?.clubStatus == 'P') &&
                (pageType == undefined || pageType == 'customer') ? (
                <RACButton
                  variant="contained"
                  color="primary"
                  className={classes.me1}
                  onClick={() => setactivateClubObj(true)}
                  disabled={
                    Agrtransferinit ? true :
                      (customerCoverageInf?.originStoreNumber == loginStore
                        ? false
                        : true)
                  }
                >
                  {'Club Is Active'}
                </RACButton>
              ) : (
                <RACButton
                  variant="contained"
                  color="primary"
                  className={classes.me1}
                  disabled={true}
                >
                  {'Club Is Not Active'}
                </RACButton>
              )
            ) : null}

            {pageType == 'customer' ? (
              <RACButton
                variant="contained"
                color="primary"
                disabled={Agrtransferinit}
                className={classes.me1}
                onClick={() => validateUpdateCustomer()}
              >
                Save
              </RACButton>
            ) : null}
            {pageType !== 'customer' ? (
              <RACButton
                variant="contained"
                color="primary"
                className={classes.me1}
                onClick={() => validateNextCustomer('Next')}
              >
                {nextBtnText}
                {nextBtnText == 'Next' ? (
                  <NextBtn className={customerStyleClasses.ms2}></NextBtn>
                ) : null}
              </RACButton>
            ) : null}
          </Grid>
        </Grid>
        {
        featureFlagDetails?.EnableGoogleAddressValidation == '1' ? 
        <>
        <RACModalCard
         isOpen={canSuggestAddress}
         maxWidth="sm"
         closeIcon={true}
         borderRadius="20px"
         onClose={() => setCanSuggestAddress(false)}
         // eslint-disable-next-line react/no-children-prop
         children={suggestAddressFn()}
         title="Validation Message"
        />
        <RACModalCard
         isOpen={cantSuggestAddress}
         maxWidth="xs"
        //  closeIcon={true}
         borderRadius="20px"
        //  onClose={() => setCantSuggestAddress(false)}
         // eslint-disable-next-line react/no-children-prop
         children={suggestErrorInAddress()}
        />
        </>
        :
        null
      }
       {
          exchangeNavigationPopup ? (
            <RACExchange value={{
              rentPaid: rentPaid,
              agreementId: exchangeAgreementId,
              customerId: customerId,
              setExchangeNavigationPopup: setExchangeNavigationPopup,
              exchangeNavigationPopup: exchangeNavigationPopup,
              racExchangeFuncFlag: racExchangeFuncFlag,
              exchangeAgreementNumber: exchangeAgreementNumber,
              suspenseAmount: suspenseAmount
            }} />
          ): null
      }
      </React.Fragment>
    </Grid>
  );
}
export default ManageCustomerInfoRacstrap;
