/* eslint-disable prettier/prettier */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/no-collapsible-if */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable use-isnan */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, useContext } from "react";
import {
  RACTextbox,
  RACDatePicker,
  RACSelect,
  RACTableCell,
  RACTable,
  RACTableRow,
  RACButton,
  RACRadio,
  RACModalCard,
  RACCheckBox,
  Card,
  Grid,
  CardContent,
  Typography,
  CircularProgress,
  Button,
  Box,
  ListItem,
  List,
  RACTooltip,
} from "@rentacenter/racstrap";

import { ReactComponent as SortDownIcon } from "../../assets/images/sort-down-icon.svg";
import { ReactComponent as SortUpIcon } from "../../assets/images/sort-up-icon.svg";
import { ReactComponent as DEConditionallyApproved } from "../../assets/images/DE-VerificationRequired.svg";
import { ReactComponent as DEApproved } from "../../assets/images/DE-Approved.svg";
import { ReactComponent as Alerticon } from "../../assets/images/No-records.svg";
import { ReactComponent as DEDeclined } from "../../assets/images/DE-Declined.svg";
import { ReactComponent as Polygon } from "../../assets/images/actionarrow.svg";
import PolygonRight from "../../assets/images/polygon-right.png";
import { ReactComponent as SearchImageDefault } from "../../assets/images/search-btn.svg";
import { ReactComponent as AccordianOpenIcon } from "../../assets/images/Accordion-down.svg";
import { ReactComponent as AccordianCloseIcon } from "../../assets/images/Accordion-close.svg";
import AccordionDown from "../../assets/images/Accordion-down-updated.png";
import AccordionOpen from "../../assets/images/Accordion-close-updated.png";
import { ReactComponent as CrossIcon } from "../../assets/images/CrossIcon.svg";
// import accordianOpenIcon from '../assets/images/Accordion-down.svg';
// import accordianCloseIcon from '../assets/images/Accordion-close.svg';
import { ReactComponent as ExchangeIcon } from "../../assets/images/Exchange.svg";
import { ReactComponent as RetentionIcon } from "../../assets/images/Retention.svg";

import { customerValidationContext } from "../../context/customerContext/customerContext";
import { FeatureFlagContext } from "../../context/FeatureFlagContext";
import moment from "moment";
import SecondFactor from "../manageCustomer/secondAuthen";
import { API_ERROR_MESSAGE } from "../../constants/constants";
import { AddCoCustomer } from "../manageCustomer/AddCoCustomer";
import { ContainerContext } from "../../app/App";
import { CustomPropInterface } from "../../index";
import { fieldDeviceStyles } from "../fieldDeviceSty";
import {
  agreementGridHeader,
  SearchGridHeader,
} from "../../constants/constants";
import {
  GetSearchResult,
  GetAgreementById,
  GetCustomerBasicInfo,
  // GetNODEReason,
  RunDE,
  UpdateNoDeReason,
  getmenuandstore,
  GetLegalSatatus,
  getRetentionOffers,
  featureFlagInfo,
  postAgreementActivity,
  storeException
} from "../../api/user";
export interface sortColumns {
  columnId: string;
  sortOrder_FN: string;
  sortOrder_LN: string;
  IsSorted: boolean;
  IsSortable: boolean;
}
import Modal from "react-modal";
import { ManageCustomerStyles } from "../ManageCustomerStyles";
import { customerStyles } from "../customerStyles";
import clsx from "clsx";
import CreateCustomerComponent from "./CreateCustomer";
import { DeliveryContext } from "../../context/DeliveryContext";
import RACExchange from "./RACExchange";
// eslint-disable-next-line sonarjs/cognitive-complexity
export default function SearchResult(props: any) {
  const classes1 = fieldDeviceStyles();
  const [actionBtnView, setactionBtnView] = useState(false);
  const containerData = useContext(ContainerContext) as CustomPropInterface;
  const classesManageCustomerStyles = ManageCustomerStyles();
  const classes = customerStyles();
  const { searchVal, destatus } = useContext(customerValidationContext);

  const { featureFlagDetails, racExchangeFuncFlag, setRacExchangeFuncFlag } = useContext(FeatureFlagContext);
  const [storeExceptionReason, setStoreExceptionReason] = useState<any>('')
  const [blurLoaderActive, setblurLoaderActive] = useState<any>(false);
  const [showcocustomerpopup, Setshowcocustomerpopup] = useState(false);
  const [postnodeReason, setpostnodeReason] = useState("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchValues, setsearchVal] = searchVal;
  const [sortOrder, setSortOrder] = useState<any>({ fN: "asc", lN: "asc" });
  const [showCustomerAgrIndex, setshowCustomerAgrIndex] =
    useState<any>(undefined);
  const [EnableLoaderButton, setEnbleLoaderButton] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [PhonenumberforrcustValidatoion, setPhonenumberforrcustValidatoion] =
    useState("");
  const [newCustValidBtnHidden, setnewCustValidBtnHidden] = useState<any>(true);
  const [noDEReasonDD, setnoDEReasonDD] = useState<any>([]);
  const [FirstName, setFirstName] = useState("");
  const [LastName, setLastName] = useState("");
  const [Dob, SetDob] = useState<any>("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [Dobnew, SetDobnew] = useState("");
  const [disableSearchBtn, setdisableSearchBtn] = useState(true);
  const [noSearchResult, setNoSearchResult] = useState("");
  const [canEnableGoogleValdation,setCanEnableGoogleValdation] = useState(false);
  // const[Dobvalid,setDobvalid]=useState("")
  const [Ssn1, setssn1] = useState("");
  const [Ssn2, setssn2] = useState("");
  const [Ssn3, setssn3] = useState("");
  const [Phonenumber, setPhonenumber] = useState("");
  const [secondLevelAuthenOn, setSecondLevelAuthenOn] = useState<any>(null);
  const [currentRole, setcurrentRole] = useState<any>("");
  const [initialNoRecPopUp, setinitialNoRecPopUp] = useState<any>(true);
  const [enterssnisopen, setenterssnisopen] = React.useState(false);
  const [DEStatus, setDEStatus] = destatus;
  // const [downArrow, setdownArrow] = useState<any>([]);
  const [searchResults, setsearchResults] = useState<any>([]);
  const [agreements, setagreements] = useState<any>([]);
  const [checkBoxActive, setcheckBoxActive] = useState<any>(undefined);
  const [custID, setcustID] = useState<any>(undefined);
  const dt = new Date();
  const loginStore = sessionStorage.getItem("storeNumber");
  const [intialLoad, setintialLoad] = useState<boolean>(true);
  const [isclsAlertMsg, setisclsAlertMsg] = useState<any>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [liketoCreateAgreement, setliketoCreateAgreement] =
    useState<any>(false);
  const [dropDownLoaded, setDropDownLoaded] = useState<boolean>(false);
  const [isUnableTofetchData, setisUnableTofetchData] = useState<any>(false);
  const [dedeclineisopen, setdedeclineisopen] = React.useState(false);
  const [reasonDEopen, setreasonDEopen] = React.useState(false);
  const [comingSoonPopup, setcomingSoonPopup] = useState<boolean>(false);
  const [agreementMandatoryFieldpopup, setagreementMandatoryFieldpopup] =
    useState<boolean>(false);
  const [coWorkerRole, setcoWorkerRole] = useState<any>();
  const [legalHoldStatus, setlegalHoldStatus] = useState<any>(false);
  const [activeGcid, setactiveGcid] = useState<any>(undefined);
  const [createcustomerPopup, setcreatecustomerPopup] = useState<any>(false);
  const [agrDetails, setagrDetails] = useState<any>();
  const [clearbtndisable, setclearbtndisable] = useState<any>(false);
  const [createAgrClicked, setcreateAgrClicked] = useState<any>(false);
  const [checkAction, setcheckAction] = useState<number[] | any>("");
  const {
    customerSearchValue,
    // searchResult
  } = useContext(DeliveryContext);
  const [searchValCustomerEditor, setSearchValCustomerEditor] =
    customerSearchValue;
  const maxiDate = new Date();
  maxiDate.setFullYear(maxiDate.getFullYear() - 18);

  // retention changes start
  const [isRetentionEnabled, setIsRetentionEnabled] = useState<boolean>(false);
  const [openSLA, setOpenSLA] = useState<boolean>(false);
  const [openOfferLoading, setOpenOfferLoading] = useState<boolean>(false);
  const [choosenAgreementNumber, setChoosenAgreementNumber] = useState<any>();
  const [choosenAgreementID, setChoosenAgreementID] = useState<any>();
  const [availableRetentionOffers, setAvailableOffers] = useState<any>();
  const [offerResponse, setOfferResponse] = useState<any>();
  const [openRetention, setOpenRetention] = useState<boolean>(false);
  const [choosenOptionIndex, setChoosenIndex] = useState<any>();
  const [choosenRetentionOptions, setChoosenRetentionOptions] = useState<any>();
  const [choosenOfferReason, setChoosenOfferReason] = useState<string>();
  const [retentionOptions, setRetentionOptions] = useState<any>();
  const [openReinstateAlert, setOpenReinstateAlert] = useState<boolean>(false);
  const [openActivityLoading, setOpenActivityLoading] =
    useState<boolean>(false);
  const [customerId, setCustomerId] = useState<any>({});
  //Exchange Indication
  const [isExchangeEnabled, setExchangeEnabled] = useState<any>(false);
  const [exchangeNavigationPopup, setExchangeNavigationPopup] =
    useState<any>(false);
  const [exchangePeriod, setExchangePeriod] = useState('');
  const [returnPeriod, setReturnPeriod] = useState('');
  const availableOffers = [
    "Product",
    "No Longer Needs",
    "EPO too high",
    "Renewal rate too high",
    "Cannot make current payment",
    "Service",
    "Moving",
    "Total RTO/Term too high",
    "SAC too high",
  ];
  const [exchangeAgreementId, setExchangeAgreementId] = useState<any>(0); 
  const [rentPaid, setExchangeRentPaid] = useState<any>(0); 
  const [exchangeSelected, setExchangeSelected] = useState<boolean>(false);
  const [suspenseAmount, setSuspenseAmount] = useState<any>('');
  const [exchangeAgreementNumber, setExchangeAgreementNumber] = useState<any>('');
  const  [btnClicked, setBtnClicked] = useState<any>(null);
  const [updatePopUp,setUpdatePopUp ] = useState(false);
  // retention changes end

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log("props.location.state outer if", props.location.state);
    if (props.location.state && intialLoad === true) {
      /* eslint-disable no-console */
      console.log("props.location.state", props.location.state);
      //setFirstNameVal(props);
      setSeachVal(props);
      setintialLoad(false);
    }
    configCustomerFunctions();
    console.log("searchValCustomerEditor.cancelClick", searchValCustomerEditor);
    if (!searchValCustomerEditor.cancelClick) {
      contextPayload();
    }
    const getCoWorkerRole = async () => {
      try {
        if (containerData !== undefined) {
          console.log("containerData", containerData);
          console.log("containerDataRole", containerData.GetRole());
          const roleObj = containerData.GetRole();
          setcurrentRole(containerData.GetRole());
          setcoWorkerRole(
            roleObj == "SM" ||
              roleObj == "CAR" ||
              roleObj == "AM" ||
              roleObj == "LAM" ||
              roleObj == "DMT"
              ? 1
              : roleObj == "DM" || roleObj == "RD"
                ? 2
                : 0
          );
        } else {
          const coWorkerRoleObj: any = await getmenuandstore();
          if (
            coWorkerRoleObj?.coworkerProfile !== undefined &&
            coWorkerRoleObj?.coworkerProfile !== null &&
            coWorkerRoleObj?.coworkerProfile.role !== undefined &&
            coWorkerRoleObj?.coworkerProfile.role !== null
          ) {
            const roleObj = coWorkerRoleObj?.coworkerProfile.role;
            // setcoWorkerRole(roleObj);
            setcurrentRole(roleObj);
            setcoWorkerRole(
              roleObj == "SM" ||
                roleObj == "CAR" ||
                roleObj == "AM" ||
                roleObj == "LAM" ||
                roleObj == "DMT"
                ? 1
                : roleObj == "DM" || roleObj == "RD"
                  ? 2
                  : 0
            );
          }
        }
      } catch (e: any) {
        /* eslint-disable no-console */
        console.log("Error in co worker role");
      }
    };
    setinitialNoRecPopUp(true);
    console.log("Initial rendering");
    // GetNoDEReason();
    getCoWorkerRole();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      FirstName?.length == 0 &&
      LastName?.length == 0 &&
      Dob == "" &&
      Ssn1?.length == 0 &&
      Ssn2?.length == 0 &&
      Ssn3?.length == 0 &&
      Phonenumber?.length == 0
    ) {
      setdisableSearchBtn(true);
    }
    checkField9();
    checkField8();
    checkField1();
    checkField2();
    checkField3();
    checkField4();
    checkField5();
    checkField6();
    checkField7();
    if (
      Ssn1?.length + Ssn2?.length + Ssn3?.length > 0 &&
      Phonenumber?.length == 0
    ) {
      checkSSn();
    }
    if (
      Phonenumber?.length > 0 &&
      Ssn1?.length + Ssn2?.length + Ssn3?.length == 0
    ) {
      checkPhone();
    }
    if (
      Ssn1?.length + Ssn2?.length + Ssn3?.length > 0 &&
      Phonenumber.length == 14
    ) {
      checkSSn();
    }
    if (
      Phonenumber.length > 0 &&
      Ssn1?.length + Ssn2?.length + Ssn3?.length == 9
    ) {
      checkPhone();
    }
    /* eslint-disable no-console */
    console.log("useEffect getting trigger for firstName");
    if (checkBoxActive == undefined) {
      setnewCustValidBtnHidden(true);
    } else {
      setnewCustValidBtnHidden(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    FirstName,
    LastName,
    Dob,
    Ssn1,
    Ssn2,
    Ssn3,
    Phonenumber,
    checkBoxActive,
    isclsAlertMsg,
  ]);

  useEffect(() => {
    const collectedRetentionOptions: any = [];
    if (offerResponse) {
      //Exchange Indication
      const filteredReason: any = offerResponse?.returnReasons.filter(
        (el: any) => el.reason == choosenOfferReason
      );
      offerResponse?.agreementOffers.forEach((offer: any) => {
        offer.updateDateTime = new Date(offer.updateDateTime);
      });
      const agreementData: any = offerResponse?.agreementOffers.sort(
        (a: any, b: any) => b.updateDateTime - a.updateDateTime
      );    
      if (agreementData[0]?.exchangeOfferDescription && !racExchangeFuncFlag) {
        collectedRetentionOptions.push({
          displayLabel: agreementData[0]?.exchangeOfferDescription,
          offerValue: {
            redirectionPage: 'Inventory',
            functionalityInPage: 'AGREXC',
            description: agreementData[0].exchangeOfferDescription,
            agreementExchangeAmount: agreementData[0]?.exchangeAmount,
          },
        });
      }
      if (choosenOfferReason == "Product" || choosenOfferReason == "Service") {
        collectedRetentionOptions.push(
          {
            displayLabel: "Repair or switch out product",
            offerValue: {
              redirectionPage: "Agreement",
              functionalityInPage: "SW",
            },
          },
          {
            displayLabel: "Customer does not accept any offer",
          }
        );
      } else if (choosenOfferReason == "No Longer Needs") {
        collectedRetentionOptions.push(
          {
            displayLabel: "Explain lifetime reinstatement to the customer",
            offerValue: {
              redirectionPage: "Agreement",
              functionalityInPage: "R",
            },
          },
          {
            displayLabel: "Customer does not accept any offer",
          }
        );
      } else if (choosenOfferReason == "Moving") {
        collectedRetentionOptions.push(
          {
            displayLabel: "Give customers their local RAC store info",
            offerValue: {
              redirectionPage: "StoreManagement",
              functionalityInPage: "MOVE",
            },
          },
          {
            displayLabel: "Customer does not accept any offer",
          }
        );
      } else if (choosenOfferReason == 'RAC Exchange') {
        collectedRetentionOptions.push(
          {
            displayLabel: agreementData[0]?.exchangeOfferDescription,
            offerValue: {
              redirectionPage: 'Inventory',
              functionalityInPage: 'AGMXNGS',
              description: 'Agreement Exchange Selected',
            }
          },
          {
            displayLabel: "Customer does not accept any offer"
          }
        );
      } else {
        // let alteredReasonDescription: any = '';
        // if (choosenOfferReason == 'Early Purchase Option (EPO) too high') {
        //   alteredReasonDescription = 'EPO too High';
        // } else if (choosenOfferReason == 'Same as Cash (SAC) too high') {
        //   alteredReasonDescription = 'SAC too High';
        // } else if (choosenOfferReason == 'Term Rent to own (TRTO) too high') {
        //   alteredReasonDescription = 'Total RTO/Term too high';
        // } else {
        //   alteredReasonDescription = choosenOfferReason;
        // }

        filteredReason.map((el: any) => {
          if (el.epoEligible == "Yes") {
            collectedRetentionOptions.push({
              displayLabel: agreementData[0].epoOfferDescription,
              offerValue: {
                redirectionPage: "Payment",
                functionalityInPage: "EPO",
                currentEpo: agreementData[0].currentEpo,
                epoDiscount: agreementData[0].epoDiscount,
                newEpo: agreementData[0].newEpo,
              },
            });
          }
          if (el.rateReductionEligible == "Yes") {
            collectedRetentionOptions.push({
              displayLabel: agreementData[0].rateOfferDescription,
              offerValue: {
                redirectionPage: "Agreement",
                functionalityInPage: "RR",
                currentRate: agreementData[0].currentRate,
                rateReduction: agreementData[0].rateReduction,
                newRate: agreementData[0].newRate,
              },
            });
          }
          if (el.termReductionEligible == "Yes") {
            collectedRetentionOptions.push({
              displayLabel: agreementData[0].termOfferReduction,
              offerValue: {
                redirectionPage: "Agreement",
                functionalityInPage: "TR",
                currentRemainingTerm: agreementData[0].currentRemainingTerm,
                termReduction: agreementData[0].termReduction,
                newRemainingTerm: agreementData[0].newRemainingTerm,
              },
            });
          }
          if (el.extensionEligible == "Yes") {
            collectedRetentionOptions.push({
              displayLabel: agreementData[0].extensionOfferDescription,
              offerValue: {
                redirectionPage: "Payment",
                functionalityInPage: "FREETIME",
                extension: agreementData[0].extension,
              },
            });
          }
        });
        if (collectedRetentionOptions.length) {
          collectedRetentionOptions.push({
            displayLabel: "Customer does not accept any offer",
          });
        } else {
          collectedRetentionOptions.push({
            displayLabel: "No retention offer option available",
          });
        }
      }
    } else {
      collectedRetentionOptions.push({
        displayLabel: "No retention offer option available",
      });
    }
    setRetentionOptions(collectedRetentionOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [choosenOfferReason]);

  const contextPayload = async () => {
    // eslint-disable-next-line no-console
    console.log("searchValCustomerEditor", searchValCustomerEditor);
    // eslint-disable-next-line no-debugger
    // debugger;
    if (
      searchValCustomerEditor.firstName != "" ||
      searchValCustomerEditor.lastName != "" ||
      searchValCustomerEditor.dob !== "" ||
      searchValCustomerEditor.taxId !== "" ||
      searchValCustomerEditor.phones !== ""
    ) {
      // eslint-disable-next-line no-console
      console.log("sufi", searchValCustomerEditor);
      const phones: any = [
        {
          phoneNumber: "",
        },
      ];
      const payloadReq: any = {};

      if (
        searchValCustomerEditor.firstName != undefined &&
        searchValCustomerEditor.firstName != null &&
        searchValCustomerEditor.firstName != ""
      ) {
        payloadReq.firstName = searchValCustomerEditor.firstName;
        setFirstName(searchValCustomerEditor.firstName);
      }
      if (
        searchValCustomerEditor.lastName != undefined &&
        searchValCustomerEditor.lastName != null &&
        searchValCustomerEditor.lastName != ""
      ) {
        payloadReq.lastName = searchValCustomerEditor.lastName;
        setLastName(searchValCustomerEditor.lastName);
      }

      if (
        searchValCustomerEditor.dob !== undefined &&
        searchValCustomerEditor.dob !== null &&
        searchValCustomerEditor.dob !== ""
      ) {
        // eslint-disable-next-line no-debugger
        // debugger;
        payloadReq.dateOfBirth = searchValCustomerEditor.dob;
        // eslint-disable-next-line no-console
        console.log("dobloki", searchValCustomerEditor.dob);

        const InputTxt = searchValCustomerEditor.dob;
        const TempArr = InputTxt.split("-");
        //const newDate = `${TempArr[1]}/${TempArr[2]}/${TempArr[0]}`;
        const format: any = moment(InputTxt).format("MM/DD/YYYY");
        const temp = InputTxt.replace(format);

        SetDob(temp);
      }
      //console.log('ssnloki',searchVal.ssn1)
      if (
        searchValCustomerEditor.taxId !== undefined &&
        searchValCustomerEditor.taxId !== null &&
        searchValCustomerEditor.taxId !== ""
      ) {
        // eslint-disable-next-line no-debugger
        // debugger;
        //payloadReq.taxId = searchVal.ssn1 + searchVal.ssn2 + searchVal.ssn3;
        payloadReq.taxId = searchValCustomerEditor.taxId;
        // console.log('ssnloki',payloadReq.taxId)
        setssn1(searchValCustomerEditor.taxId.slice(0, 3));
        setssn2(searchValCustomerEditor.taxId.slice(3, 5));
        setssn3(searchValCustomerEditor.taxId.slice(5));
      }

      if (
        searchValCustomerEditor.phones !== undefined &&
        searchValCustomerEditor.phones != null &&
        searchValCustomerEditor.phones != ""
      ) {
        // eslint-disable-next-line no-debugger
        // debugger;
        //const searchedVal = searchVal.phoneNumber;
        //console.log('ser1', searchVal.phones)
        phones[0].phoneNumber = searchValCustomerEditor.phoneNumber.replace(
          /\D/g,
          ""
        );
        payloadReq.phones = phones;

        const cleaned = searchValCustomerEditor.phones[0].phoneNumber;
        const PhoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        // eslint-disable-next-line sonarjs/no-duplicate-string
        const temp = cleaned.replace(PhoneRegex, "($1) $2-$3");

        setPhonenumber(temp);
      }

      //setsearchResults(searchContextResult)

      if (Object.keys(payloadReq).length > 0) {
        await getSearchValues(payloadReq);
      } else {
        setinitialNoRecPopUp(true);
      }
    }
  };
  // const GetNoDEReason = async () => {
  //   const res: any = await GetNODEReason();
  //   // eslint-disable-next-line no-console
  //   console.log(res);
  //   if (res !== undefined && res.referenceDetails !== undefined) {
  //     const sortedlist = res.referenceDetails.sort((a: any, b: any) =>
  //       a['description'] < b['description'] ? -1 : 1
  //     );
  //     const referenceDetailsObj: any = [];
  //     sortedlist.map((value: any, index: any) => {
  //       if (index === 0) {
  //         referenceDetailsObj.push({
  //           label: 'Select',
  //           value: '0',
  //         });
  //       }
  //       referenceDetailsObj.push({
  //         label: value.description,
  //         value: value.referenceCode,
  //       });
  //     });
  //     // const stateDDStructure = sortedlist.map((el: any, i: any) => {
  //     //   if(i== 0){

  //     //   }
  //     //   res.referenceDetails[i].label = el.description;
  //     //   res.referenceDetails[i].value = el.referenceCode;
  //     //   return el;
  //     // });
  //     setnoDEReasonDD(referenceDetailsObj);
  //     setDropDownLoaded(true);
  //   }
  // };
  const checkField1 = () => {
    if (
      FirstName?.length + LastName?.length >= 3 &&
      Phonenumber?.length == 0 &&
      Ssn1?.length + Ssn2?.length + Ssn3?.length == 0
    ) {
      setdisableSearchBtn(false);
    }
  };
  const checkField2 = () => {
    if (
      FirstName?.length + LastName?.length < 3 &&
      Phonenumber?.length == 14 &&
      Ssn1?.length + Ssn2?.length + Ssn3?.length == 0 &&
      (Dob == "" || Dob !== "")
    ) {
      setdisableSearchBtn(false);
    }
  };
  const checkField3 = () => {
    if (
      FirstName?.length + LastName?.length < 3 &&
      Phonenumber?.length == 0 &&
      Ssn1?.length + Ssn2?.length + Ssn3?.length == 9 &&
      (Dob == "" || Dob !== "")
    ) {
      setdisableSearchBtn(false);
    }
  };
  const checkField4 = () => {
    if (
      FirstName?.length + LastName?.length < 3 &&
      Phonenumber?.length == 0 &&
      Ssn1?.length + Ssn2?.length + Ssn3?.length == 0 &&
      Dob !== ""
    ) {
      setdisableSearchBtn(false);
    }
  };
  const checkField9 = () => {
    if (
      (FirstName?.length + LastName?.length < 3 && Phonenumber?.length == 14) ||
      (Ssn1?.length + Ssn2?.length + Ssn3?.length == 9 && Dob !== "")
    ) {
      setdisableSearchBtn(false);
    }
  };
  const checkField5 = () => {
    if (
      Dob != "" &&
      Phonenumber?.length == 0 &&
      Ssn1?.length + Ssn2?.length + Ssn3?.length == 0
    ) {
      setdisableSearchBtn(false);
    }
  };
  const checkField6 = () => {
    if (
      Dob != "" &&
      Phonenumber?.length >= 14 &&
      Ssn1?.length + Ssn2?.length + Ssn3?.length == 0
    ) {
      setdisableSearchBtn(false);
    }
  };
  const checkField7 = () => {
    if (
      Dob != "" &&
      Phonenumber?.length == 0 &&
      Ssn1?.length + Ssn2?.length + Ssn3?.length == 9
    ) {
      setdisableSearchBtn(false);
    }
  };
  const checkField8 = () => {
    if (
      FirstName?.length + LastName?.length < 3 &&
      Phonenumber?.length == 0 &&
      Ssn1?.length + Ssn2?.length + Ssn3?.length == 0 &&
      Dob == ""
    ) {
      setdisableSearchBtn(true);
    }
  };
  useEffect(() => {
    /*  { pathname: '/customer', state: { FirstName: firstname, LastName: lastname, DOB: dob, PhoneNumber: phonenumber, SSN1: ssn1, SSN2: ssn2, SSN3: ssn3 } }*/
    // grid();
    /* eslint-disable no-console */
    console.log("useEffect getting trigger");
    console.log("second time rendering");
    showSearchResults();
    /* eslint-enable no-console */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);
  const checkPhone = () => {
    if (Phonenumber.length >= 14) {
      setdisableSearchBtn(false);
    } else {
      setdisableSearchBtn(true);
    }
  };
  const checkSSn = () => {
    if (Ssn1?.length + Ssn2?.length + Ssn3?.length == 9) {
      setdisableSearchBtn(false);
    } else {
      setdisableSearchBtn(true);
    }
  };
  // const setFirstNameVal = (props: any) => {
  //   if (props.location.state.FirstName !== undefined) {
  //     setFirstName(props.location.state.FirstName);
  //     //payload.firstName = props.location.state.FirstName;
  //   }
  // };
  const setSeachVal = async (props: any) => {
    const phones: any = [
      {
        phoneNumber: "",
      },
    ];
    const payload: any = {};
    if (
      props.location.state.FirstName !== undefined &&
      props.location.state.FirstName !== null &&
      props.location.state.FirstName !== "" &&
      props.location.state.FirstName.length > 0
    ) {
      setFirstName(props.location.state.FirstName.trim());
      payload.firstName = props.location.state.FirstName.trim();
    }
    if (
      props.location.state.LastName !== undefined &&
      props.location.state.LastName !== null &&
      props.location.state.LastName !== "" &&
      props.location.state.LastName.length > 0
    ) {
      setLastName(props.location.state.LastName.trim());
      payload.lastName = props.location.state.LastName.trim();
    }
    if (
      props.location.state.DOB !== undefined &&
      props.location.state.DOB !== ""
    ) {
      SetDob(props.location.state.DOB);
      payload.dateOfBirth = props.location.state.DOB;
    }
    if (
      props.location.state.PhoneNumber !== undefined &&
      props.location.state.PhoneNumber !== ""
    ) {
      setPhonenumber(
        props.location.state.PhoneNumber.replace(
          /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
          // eslint-disable-next-line sonarjs/no-duplicate-string
          "($1) $2-$3"
        )
      );
      setPhonenumberforrcustValidatoion(
        props.location.state.PhoneNumber.replace(
          /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
          // eslint-disable-next-line sonarjs/no-duplicate-string
          "($1) $2-$3"
        )
      );
      setPhonenumberforrcustValidatoion(
        ("" + props.location.state.PhoneNumber).replace(/\D/g, "")
      );
      phones[0].phoneNumber = props.location.state.PhoneNumber.replace(
        /\D/g,
        ""
      );
      payload.phones = phones;
    }
    if (
      props.location.state.SSN1 !== undefined &&
      props.location.state.SSN1 !== "" &&
      props.location.state.SSN2 !== undefined &&
      props.location.state.SSN2 !== "" &&
      props.location.state.SSN3 !== undefined &&
      props.location.state.SSN3 !== ""
    ) {
      payload.taxId =
        props.location.state.SSN1 +
        props.location.state.SSN2 +
        props.location.state.SSN3;
    }
    if (
      props.location.state.SSN1.length == 3 &&
      props.location.state.SSN2.length == 2 &&
      props.location.state.SSN3.length == 4
    ) {
      setssn1(props.location.state.SSN1);
      setssn2(props.location.state.SSN2);
      setssn3(props.location.state.SSN3);
    }

    // eslint-disable-next-line no-console
    console.log("payloadRequest", payload);
    if (Object.keys(payload).length > 0) {
      setEnbleLoaderButton(true);
      await getSearchValues(payload);
    } else {
      setinitialNoRecPopUp(true);
    }
  };
  const getSearchValues = async (payload: any) => {
    // eslint-disable-next-line no-console
    console.log("payload", payload);
    // setinitialNoRecPopUp(false);
    // setEnbleLoaderButton(true);
    // setcheckBoxActive(undefined);
    // setshowCustomerAgrIndex(undefined);
    setblurLoaderActive(true);
    setinitialNoRecPopUp(false);
    setEnbleLoaderButton(true);
    setcheckBoxActive(undefined);
    setshowCustomerAgrIndex(undefined);

    try {
      const res = await GetSearchResult(payload);
      // eslint-disable-next-line no-console
      console.log("data", res);
      if (
        res.GetCustomers !== undefined &&
        res.GetCustomers.customers !== undefined &&
        res.GetCustomers.customers.length !== undefined &&
        res.GetCustomers.customers.length > 0
      ) {
        setblurLoaderActive(false);
        const getCustomerList: any = [];
        res.GetCustomers.customers.forEach((customer: any) => {
          const agreementCount =
            customer.agreementCounts !== undefined
              ? activeAgreementCount(customer.agreementCounts)
              : 0;
          customer.AgreementCount = agreementCount;
          getCustomerList.push(customer);
        });
        // getCustomerList = getCustomerList.sort((a: any, b: any) =>
        //   a['firstName'] < b['firstName'] ? -1 : 1
        // );
        // let getCustomerOrginStore = getCustomerList.filter(
        //   (value: any) => value.originStore == loginStore
        // );
        // getCustomerOrginStore = getCustomerOrginStore.sort((a: any, b: any) =>
        //   a['AgreementCount'] < b['AgreementCount'] ? -1 : 1
        // );
        // const getCustomerNonOrginStore = getCustomerList.filter(
        //   (value: any) => value.originStore != loginStore
        // );
        // getCustomerList = [];
        // getCustomerOrginStore.forEach((element: any) => {
        //   getCustomerList.push(element);
        // });
        // getCustomerNonOrginStore.forEach((element: any) => {
        //   getCustomerList.push(element);
        // });
        // eslint-disable-next-line no-console
        console.log("getCustomerList", getCustomerList);
        setsearchResults(getCustomerList);
        setEnbleLoaderButton(false);
        setinitialNoRecPopUp(false);
      } else if (res.GetCustomers.customers.length == 0) {
        setblurLoaderActive(false);
        setsearchResults([]);
        setNoSearchResult(LastName + "  " + FirstName);
        setEnbleLoaderButton(false);
        setinitialNoRecPopUp(false);
      }
    } catch (err: any) {
      setblurLoaderActive(false);
      setisUnableTofetchData(true);
      setEnbleLoaderButton(false);
      setinitialNoRecPopUp(false);
      // eslint-disable-next-line no-console
      console.log("err", err);
    }
  };
  const ssnFoc: any = (e: any) => {
    if (e !== undefined) {
      const stDate = moment(e, "YYYY-MM-DD");
      const lt18 = moment(moment().subtract(18, "years"), "YYYY-MM-DD");
      const lt110 = moment(moment().subtract(110, "years"), "YYYY-MM-DD");
      const lt18Res: any = lt18.diff(stDate, "days");
      const lt110Res: any = lt110.diff(stDate, "days");
      const res: any = lt18Res >= 0 && lt110Res < 0 ? true : false;
      if (res) {
        const el: any = document.getElementById("a11y_ssnone");
        el.focus();
      }
    }
  };
  const HandleChange = (e: any, name?: any) => {
    if (name == "DOB") {
      const InputTxt = e;
      const TempArr = InputTxt.split("-");
      const newDate = `${TempArr[1]}/${TempArr[2]}/${TempArr[0]}`;
      const format: any = moment(InputTxt).format("MM/DD/YYYY");
      const temp = InputTxt.replace(format);
      SetDob(temp);
      SetDobnew(newDate);
      ssnFoc(e);
      // if (e != undefined && e != null && e != 'Invalid Date' && e != null) {
      //   SetDob(moment(e).format('YYYY-MM-DD'));
      // } else {
      //   SetDob(e);
      // }
    } else {
      // eslint-disable-next-line no-console
      console.log(e);
      //debugger
      //let Isvalid = true;
      if (e.target.name === "FirstName") {
        //Isvalid = e.target.value.length + LastName.length > 2 ? true :false
        if (
          e.target.value &&
          e.target.value.length > 0 &&
          e.target.value.charAt(e.target.value.length - 1) !== " "
        ) {
          const char: any = capitalize(e.target.value);

          setFirstName(formatTextChange(char.replace(/[^A-Z-'.,’ a-z]/gi, "")));
        } else {
          setFirstName(
            formatTextChange(e.target.value.replace(/[^A-Z-'.,’ a-z]/gi, ""))
          );
        }
      } else if (e.target.name === "LastName") {
        //Isvalid = e.target.value.length + FirstName.length > 2 ? true :false
        if (
          e.target.value &&
          e.target.value.length > 0 &&
          e.target.value.charAt(e.target.value.length - 1) !== " "
        ) {
          const char: any = capitalize(e.target.value);
          setLastName(formatTextChange(char.replace(/[^A-Z-'.,’ a-z]/gi, "")));
        } else {
          setLastName(
            formatTextChange(e.target.value.replace(/[^A-Z-'.,’ a-z]/gi, ""))
          );
        }
      } else if (e.target.name === "Ssn1") {
        setssn1(e.target.value.replace(/\D/g, ""));
        ssn1Handler(e);
      } else if (e.target.name === "Ssn2") {
        // if(Ssn2!==""){
        //   setSocialSecurityBorderTwo("none")
        // }

        setssn2(e.target.value.replace(/\D/g, ""));
        ssn2Handler(e);
        // setssn2(e.target.value.replace(/[0-9]/g, '*'));
      } else if (e.target.name === "Ssn3") {
        setssn3(e.target.value.replace(/\D/g, ""));
        ssn3Handler(e);
      } else if (e.target.name === "phoneNumber") {
        // debugger;
        //alert(e.target.value);
        const cleaned = ("" + e.target.value).replace(/\D/g, "");
        //This is raw data to pass on props
        setPhonenumberforrcustValidatoion(cleaned);
        const PhoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        const temp = cleaned.replace(PhoneRegex, "($1) $2-$3");

        //Isvalid = temp.length == 14 ? true:false
        //alert(temp);
        setPhonenumber(e.target.value);
      }
      // validateSearch();
    }
  };

  const enterKeyPressed = (event: any, field?: any) => {
    // Number 13 is the "Enter" key on the keyboard
    /* eslint-disable no-console */

    console.log("key pressed");

    //This is to prevent the space entered by the user 
    if (event.key == ' ') {
      event.preventDefault();
    }

    /* eslint-enable no-console */
    if ((event.charCode === 13 || event.keyCode == 13) && !disableSearchBtn) {

      if (field == 'SSN' && Ssn1.length +
        Ssn2.length +
        Ssn3.length < 9) {
        return
      }
      // Cancel the default action, if needed
      event.preventDefault();
      /* eslint-disable no-console */
      console.log("enter pressed");
      /* eslint-enable no-console */

      // Trigger the button element with a click
      showSearchResults();
    }
  };
  const ssn2Handler = (e: any) => {
    const ssn3El: any = document.getElementById("a11y_ssnthree");
    const ssn1El: any = document.getElementById("a11y_ssnone");
    const ssn2El: any = document.getElementById("a11y_ssntwo");
    if (e.target.value.length === 2) {
      if (ssn2El == document.activeElement) {
        ssn3El.focus();
      }
    }
    if (e.target.value.length === 0 && Ssn1?.length > 0) {
      if (ssn2El == document.activeElement) {
        ssn1El.focus();
      }
    }
  };
  const ssn1Handler = (e: any) => {
    const ssn2El: any = document.getElementById("a11y_ssntwo");
    const ssn1El: any = document.getElementById("a11y_ssnone");
    if (e.target.value.length === 3) {
      if (ssn1El == document.activeElement) {
        ssn2El.focus();
      }
    }
  };
  const ssn3Handler = (e: any) => {
    const ssn3El: any = document.getElementById("a11y_ssnthree");
    const ssn2El: any = document.getElementById("a11y_ssntwo");
    if (e.target.value.length === 0 && Ssn2.length > 0) {
      if (ssn3El == document.activeElement) {
        ssn2El.focus();
      }
    }
  };
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const showAgreement = async (i: any, gcId: any) => {
    // eslint-disable-next-line no-debugger
    // debugger;
    /* eslint-disable no-console */
    console.log(gcId);
    //setcheckBoxActive(undefined);
    let res: any = "";
    if (i != showCustomerAgrIndex) {
      res = await getcustomerbyid("C", gcId);
    }
    // eslint-disable-next-line no-console
    console.log("response for customer id", res);
    if (!res) {
      setcheckBoxActive(undefined);
      setshowCustomerAgrIndex(undefined);
    } else {
      if (i != showCustomerAgrIndex) {
        if (res && res.customerId) {
          setCustomerId(res.customerId);
          // eslint-disable-next-line no-console
          console.log("inside if condition", showCustomerAgrIndex);
          setshowCustomerAgrIndex(i == showCustomerAgrIndex ? undefined : i);
          const response = await getAgreement(res.customerId);
          setcheckBoxActive(i == showCustomerAgrIndex ? undefined : i);
          // eslint-disable-next-line no-console
          console.log("response for agreement", response);
        }
      } else {
        // eslint-disable-next-line no-console
        console.log("inside else condition");
        setcheckBoxActive(i == showCustomerAgrIndex ? i : undefined);
        //setcheckBoxActive(undefined);
        setshowCustomerAgrIndex(undefined);
      }
    }
    /* eslint-enable no-console */
  };
  const capitalize = (input: any) => {
    const words: any = input.split(" ");
    const CapitalizedWords: any = [];
    words.forEach((element: any) => {
      CapitalizedWords.push(
        element[0].toUpperCase() + element.slice(1, element.length)
      );
    });
    return CapitalizedWords.join(" ");
  };
  const getAgreement = async (id: any) => {
    try {
      setagreements(undefined);
      // eslint-disable-next-line no-debugger
      // debugger;
      const res = await GetAgreementById(id, "N");
      /* eslint-disable no-console */
      const agreement: any = res.agreement;
      // res != undefined && res.agreement !== undefined ? res.agreement : [];
      if (
        res.clubInfoResponse !== undefined &&
        res.clubInfoResponse !== null &&
        res.clubInfoResponse.length > 0
      ) {
        res.clubInfoResponse.forEach((el: any) => {
          res.agreement.push(el);
        });
      }
      if (agreement == []) {
        //showCustomerAgrIndex(undefined);
        setagreements([]);
      } else {
        const agreementList = showRecordsByStoreNumber(res.agreement);
        setagreements(agreementList);
      }
      console.log("getAgreement", res);
      return true;
    } catch (err: any) {
      if (err) {
        setagreements(null);
        return false;
      }
    }
  };
  const formatNum = (val: any) => {
    const cleaned = ("" + val).replace(/\D/g, "");
    const PhoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    return cleaned.replace(PhoneRegex, "($1) $2-$3");
  };

  const activeAgreementCount = (data: any) => {
    let initialAgreement = 0;
    if (data !== undefined && data !== null && data.length > 0) {
      data.forEach((el: any) => {
        if (el.lob !== undefined && el.lob !== null) {
          initialAgreement +=
            el.lob.toUpperCase() == "RTO" ? parseInt(el.active) : 0;
        }
      });
    }
    return initialAgreement;
  };
  const paymentRedirect = () => {
    if (custID?.customerId !== undefined) {
      props.history.push(`/payment1/paymentinformation/${custID?.customerId}/0`);
    }
  };
  const agreementRedirect = () => {
    if (custID?.customerId !== undefined && custID?.coworker !== "Y") {
      props.history.push(`/agreement/rental/itemsearch/${custID?.customerId}`);
    } else if (custID?.customerId !== undefined && custID?.coworker == "Y") {
      props.history.push(
        `/agreement/rental/itemsearch/${custID?.customerId}?coCustomerId=N`
      );
    }
  };
  const checkBoxActiveOnclick = async (index: number, gcd: any) => {
    const agr: any = searchResults.filter((el: any) => {
      if (gcd == el.globalCustomerId) {
        return el;
      }
    });
    setagrDetails(agr);
    setcheckBoxActive(undefined);
    setshowCustomerAgrIndex(undefined);
    const currentCUstomerChecked: number | undefined =
      checkBoxActive == index ? undefined : index;
    if (currentCUstomerChecked !== undefined) {
      // setsomethingone('modal fade show expandPopup');
      // setsomethingtwo('modal fade');
      setblurLoaderActive(true);
      setactiveGcid(gcd);
      const res = await getcustomerbyid("C", gcd);
      /* eslint-disable no-console */
      console.log("custID?.customerId", res);
      if (res == undefined) {
        setcheckBoxActive(undefined);
      } else {
        setcheckBoxActive(currentCUstomerChecked);
      }
    } else {
      setactiveGcid(undefined);
      setcheckBoxActive(currentCUstomerChecked);
    }
  };
  const getcustomerbyid = async (type: any, id: any) => {
    try {
      const agr: any = searchResults.filter((el: any) => {
        if (id == el.globalCustomerId) {
          return el;
        }
      });
      setagrDetails(agr);
      setblurLoaderActive(true);
      const res: any =
        activeGcid !== id
          ? await GetCustomerBasicInfo(id)
          : { customerId: custID?.customerId };
      if (res && res.customerId) {
        setblurLoaderActive(false);
        console.log(res);
        if (type == "C") {
          setcustID(res);
          return res;
        } else if (type == "R") {
          props.history.push(`/customer/update/${res.customerId}/customer`);
        }
      } else {
        setblurLoaderActive(false);
        setisclsAlertMsg(true);
      }
    } catch (err: any) {
      if (err) {
        setblurLoaderActive(false);
        setisclsAlertMsg(true);
        // eslint-disable-next-line no-console
        console.log("error while fetching customerid", err, isclsAlertMsg);
      }
    }
  };

  const agreementTable = (i: any) => {
    return showCustomerAgrIndex == i ? (
      <RACTableRow
        className={`{${classes.customerAccordianopen} ${classes.customerTablecellbgcolor}`}
      >
        <td
          colSpan={15}
          className={`${classes.customerHiddenrow} ${classes.spacerP0}`}
        >
          <div id="row-1" className={`${classes.agreementGridInnerRow}`}>
            {agreements === undefined ? (
              <div className={classes.customerNorecordsAgr}>
                <CircularProgress />
              </div>
            ) : agreements === null ? (
              <div className={classes.customerTextcenter}>
                <div
                  className={`${classes.customerRow} ${classes.customerJustifycontentcenter}`}
                >
                  <div className={classes.customerColmd}>
                    <Alerticon></Alerticon>
                    <p className={classes.spacerMT3}>
                      No rental agreement found
                    </p>
                  </div>
                </div>
              </div>
            ) : agreements !== undefined &&
              agreements !== null &&
              agreements.length > 0 ? (
              <RACTable
                renderTableHead={renderAgreementTableHead}
                renderTableContent={renderAgreementTableContent}
                className={`${classes.agreementGrid} ${classes1.agreementGrid}`}
              />
            ) : (
              <div className={classes.customerTextcenter}>
                <div
                  className={
                    (classes.customerRow, classes.customerJustifycontentcenter)
                  }
                >
                  <div className={classes.customerColmd}>
                    <Alerticon></Alerticon>
                    <p className={classes.spacerMT3}>
                      No rental agreements found
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </td>
      </RACTableRow>
    ) : null;
  };
  const category = (el: any) => {
    const skip = el.skip !== undefined ? el.skip : "N";
    const stolen = el.stolen !== undefined ? el.stolen : "N";
    const hard = el.hard !== undefined ? el.hard : "N";
    if (skip == "N" && stolen == "N" && hard == "N") {
      return "-";
    } else if (skip !== "N" && hard !== "N") {
      return "Skip/Hard";
    } else if (skip !== "N") {
      return "Skip";
    } else if (stolen !== "N") {
      return "Stolen";
    } else if (hard !== "N") {
      return "Hard";
    }
  };
  const formatTextChange = (value: any) => {
    return value !== undefined && value !== null && value.trim().length == 0
      ? value.trim()
      : value;
  };
  const showRecordsByStoreNumber = (agreement: []) => {
    if (agreement.length > 0) {
      const GetStoreByActiveOrPending = agreement.filter((element: any) => {
        if (
          element.agreementStatus !== undefined &&
          (element.agreementStatus.toLowerCase() == "active" ||
            element.agreementStatus.toLowerCase() == "preliminary" ||
            element.agreementStatus.toLowerCase() == "preliminary paid" ||
            element.agreementStatus.toLowerCase() == "preliminary refunded" ||
            element.agreementStatus.toLowerCase() == "preliminary inventory" ||
            element.agreementStatus.toLowerCase() == "pending")
        ) {
          return element;
        }
      });
      /* eslint-disable no-console */
      console.log("311", GetStoreByActiveOrPending);
      /* eslint-enable no-console */
      const filterStoreNum = GetStoreByActiveOrPending.map(
        (el: any) => el.storeNumber
      );
      /* eslint-disable no-console */
      console.log("317", filterStoreNum);
      /* eslint-enable no-console */

      const uniqueSN = Array.from(new Set(filterStoreNum));
      /* eslint-disable no-console */
      console.log("322", uniqueSN);
      let data: { storeNumber: string; agreements: [] }[] = [];
      if (
        GetStoreByActiveOrPending !== undefined &&
        GetStoreByActiveOrPending.length > 0 &&
        uniqueSN.length > 0
      ) {
        uniqueSN.forEach((val) => {
          const agreementValue = GetStoreByActiveOrPending.filter(
            (value: any) => val == value.storeNumber
          );
          const storeNumberWithAgreement: any = {
            storeNumber: val,
            agreements: agreementValue,
          };
          data.push(storeNumberWithAgreement);
        });
      }
      if (loginStore !== undefined && loginStore !== null) {
        const getCurrentStoreAgreements = data.filter(
          (val: any) => val.storeNumber == loginStore
        );
        const getNotCurrentStoreAgreements = data.filter(
          (val: any) => val.storeNumber != loginStore
        );
        data = [];
        getCurrentStoreAgreements.forEach((agreemts) => {
          data.push(agreemts);
        });
        getNotCurrentStoreAgreements.forEach((agreemts) => {
          data.push(agreemts);
        });
      }
      /* eslint-disable no-console */
      console.log("345", data);
      /* eslint-enable no-console */
      return data;
    }
  };
  const truncString = (str: any, max: number, add: any) => {
    add = add || "...";
    return str !== undefined &&
      str !== null &&
      typeof str === "string" &&
      str.length > max
      ? str.substring(0, max) + add
      : str;
  };
  const HandleDecimal = (value: any) => {
    const formatedValue: any = value.toString().replace(",", "");
    // eslint-disable-next-line no-console
    console.log("formatedValue", formatedValue);
    const nfObject = new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
    });
    return nfObject.format(formatedValue);
  };
  const nextDueDayWithDate = (date: any) => {
    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const today = new Date(date);
    const index = today.getDay();
    return days[index] !== undefined && days[index] !== null
      ? days[index] + ", " + date
      : date;
  };
  const isDueDatePassed = (date: any) => {
    /* eslint-disable no-console */
    console.log(date);
    const date1: any = new Date(date);
    const cMonth = dt.getMonth() + 1;
    const cDate = dt.getDate() < 10 ? "0" + dt.getDate() : dt.getDate();
    const cYear = dt.getFullYear();
    const currentDate: any = new Date(`${cMonth}/${cDate}/${cYear}`);
    const diffTime = Math.abs(currentDate - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays > 0 ? false : true;
  };
  const showSearchResults = async () => {
    setSortOrder({ fN: "asc", lN: "asc" });
    setNoSearchResult("");
    setsearchResults([]);
    const phones: any = [
      {
        phoneNumber: "",
      },
    ];
    const payloadReq: any = {};
    const reqPayload = {
      firstName: "",
      lastName: "",
      dob: "",
      taxId: "",
      phones: [
        {
          phoneNumber: "",
        },
      ],
      cancelClick: true,
    };
    if (FirstName != "" && FirstName !== null && FirstName !== undefined) {
      payloadReq.firstName = FirstName.trim();
      reqPayload.firstName = FirstName.trim();
    }
    if (LastName != "" && FirstName !== null && FirstName !== undefined) {
      payloadReq.lastName = LastName.trim();
      reqPayload.lastName = LastName.trim();
    }
    if (Dob != "") {
      payloadReq.dateOfBirth = Dob;
      reqPayload.dob = Dob;
    }
    if (Ssn1 !== "" && Ssn2 !== "" && Ssn3 !== "") {
      payloadReq.taxId = Ssn1 + Ssn2 + Ssn3;
      reqPayload.taxId = Ssn1 + Ssn2 + Ssn3;
    }
    if (Phonenumber !== "" && Phonenumber.length >= 14) {
      phones[0].phoneNumber = Phonenumber.replace(/\D/g, "");
      payloadReq.phones = phones;
      reqPayload.phones = phones;
    }
    console.log("payloadRequest", payloadReq);

    // reqPayload.cancelClick = true;
    const searchVal = {
      firstName: FirstName,
      lastName: LastName,
      dob: Dob,
      ssn1: Ssn1,
      ssn2: Ssn2,
      ssn3: Ssn3,
      phoneNumber: Phonenumber,
      cancelClick: true,
    };
    setSearchValCustomerEditor(searchVal);
    if (Object.keys(payloadReq).length > 0) {
      await getSearchValues(payloadReq);
    } else {
      setinitialNoRecPopUp(true);
    }
  };
  const CreateCustomer = () => {
    const searchVal = {
      firstName: FirstName,
      lastName: LastName,
      dob: Dob,
      ssn1: Ssn1,
      ssn2: Ssn2,
      ssn3: Ssn3,
      phoneNumber: Phonenumber,
    };
    setsearchVal(searchVal);
    props.history.push("/customer/createcustomer");
  };
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const sortGrid = (columnToSort: any, col: any) => {
    // eslint-disable-next-line no-debugger
    // debugger;
    const searchResultslocal = searchResults;
    const sortedData =
      col == "FN"
        ? searchResultslocal.sort(function (a: any, b: any) {
          const x: any =
            a["firstName"] !== undefined && a["firstName"] !== null
              ? a["firstName"].toUpperCase()
              : "",
            y =
              b["lastName"] !== undefined && b["lastName"] !== null
                ? b["lastName"].toUpperCase()
                : "";
          // if (x == y) {
          //   return 0;
          // } else if (x > y && columnToSort.fN != 'asc') {
          //   return 1;
          // } else if (x > y && columnToSort.fN == 'asc') {
          //   return -1;
          // }
          return x == y ? 0 : x > y && columnToSort.fN != "asc" ? 1 : -1;
        })
        : searchResultslocal.sort(function (a: any, b: any) {
          const x =
            a["firstName"] !== undefined && a["firstName"] !== null
              ? a["firstName"].toUpperCase()
              : "",
            y =
              b["lastName"] !== undefined && b["lastName"] !== null
                ? b["lastName"].toUpperCase()
                : "";
          // if (x == y) {
          //   return 0;
          // } else if (x > y && columnToSort.fN != 'asc') {
          //   return 1;
          // } else if (x > y && columnToSort.fN == 'asc') {
          //   return -1;
          // }
          return x == y ? 0 : x > y && columnToSort.lN != "asc" ? 1 : -1;
        });
    setsearchResults([...sortedData]);
    //setSortOrder({ fN: 'asc', lN: 'asc' });
    console.log("columnToSort", columnToSort);
    let data: any;
    if (col == "FN") {
      data = { fN: columnToSort, lN: sortOrder.lN };
    } else {
      data = { fN: sortOrder.fN, lN: columnToSort };
    }
    // })
    setSortOrder(data);
    setcheckBoxActive(undefined);
    setshowCustomerAgrIndex(undefined);
  };
  function CustNotFoundPopUp() {
    return (
      <Grid item md={12} className={classes.px2}>
        <Grid item md={12} className={classes.textCenter}>
          <Alerticon></Alerticon>
          <Typography className={classes.RACPOPMsg}>
            Customer record is incomplete, please create new customer.
          </Typography>
        </Grid>
        <Grid
          container
          className={`${classes.spacerMT4} ${classes.justifyContentCenter}`}
        >
          <RACButton
            color="primary"
            variant="contained"
            onClick={() => {
              setisclsAlertMsg(false);
              setcreatecustomerPopup(true);
            }}
          >
            Ok
          </RACButton>
        </Grid>
      </Grid>
    );

    // Reason popup for Not running DE PC-CC-29
  }
  function GetCustIDAlert() {
    return (
      <RACModalCard
        isOpen={isclsAlertMsg}
        borderRadius="20px"
        maxWidth="xs"
        closeIcon={true}
        onClose={() => setisclsAlertMsg(false)}
        // eslint-disable-next-line react/no-children-prop
        children={CustNotFoundPopUp()}
        title=""
      />
    );
  }
  const createcustomermodalPopup = () => {
    return (
      <CreateCustomerComponent
        basicInfo={agrDetails}
        useGoogleAddressValidation={canEnableGoogleValdation}
        handleClosePopUp={(action: boolean) => {
          setcreatecustomerPopup(action);
        }}
      />
    );
  };
  function CreateCustomerModal() {
    return (
      <RACModalCard
        isOpen={createcustomerPopup}
        borderRadius="20px"
        maxWidth="lg"
        closeIcon={true}
        onClose={() => setcreatecustomerPopup(false)}
        // eslint-disable-next-line react/no-children-prop
        children={createcustomermodalPopup()}
        title=""
      />
    );
  }
  const EnterSSNPopup = () => {
    return (
      <Grid item md={12}>
        <Grid item md={12} className={classes.textCenter}>
          <Alerticon></Alerticon>
          <Typography variant={"body2"} className={classes.RACPOPMsg}>
            Enter the SSN# to run the decision engine.
          </Typography>
        </Grid>
        <Grid
          container
          className={`${classes.justifyContentCenter} ${classes.spacerMT4}`}
        >
          <RACButton
            variant="contained"
            color="primary"
            onClick={() =>
              custID !== undefined && custID?.customerId !== undefined
                ? props.history.push(
                  `/customer/update/${custID?.customerId}/customer`
                )
                : ""
            }
          >
            Ok
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  function EnterSSNfn() {
    return (
      <RACModalCard
        isOpen={enterssnisopen}
        borderRadius="20px"
        maxWidth="xs"
        closeIcon={true}
        onClose={() => setenterssnisopen(false)}
        // eslint-disable-next-line react/no-children-prop
        children={EnterSSNPopup()}
        title=""
      />
    );
  }
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const runDecisionEngine = async () => {
    console.log("DecisionEngine customer id", custID);
    console.log("searchResults", searchResults);

    if (custID !== undefined && custID.globalCustomerId !== undefined) {
      const gcd = custID.globalCustomerId;
      const filterData = searchResults.filter((el: any) => {
        return el.globalCustomerId == gcd;
      });
      console.log("filterData", filterData);
      if (
        filterData == undefined ||
        filterData[0].taxId == null ||
        filterData[0].taxId == ""
      ) {
        setenterssnisopen(true);
      } else {
        const address: any =
          filterData !== undefined &&
            filterData !== null &&
            filterData.length > 0 &&
            filterData[0].addresses !== undefined &&
            filterData[0].addresses !== null &&
            filterData[0].addresses.length > 0
            ? filterData[0].addresses.filter(
              (e: any) => e.addressType == "PRIM" || e.addressType == "P"
            )
            : [];
        const phones: any =
          filterData !== undefined &&
            filterData !== null &&
            filterData.length > 0 &&
            filterData[0].phones !== undefined &&
            filterData[0].phones !== null &&
            filterData[0].phones.length > 0
            ? filterData[0].phones.filter((e: any) => e.isPrimary == "Y")
            : [];
        const payload: any = {
          firstName:
            filterData !== undefined && filterData[0].firstName
              ? filterData[0].firstName
              : "",
          lastName:
            filterData !== undefined && filterData[0].lastName
              ? filterData[0].lastName
              : "",
          dateOfBirth:
            filterData !== undefined && filterData[0].dateOfBirthEnc
              ? filterData[0].dateOfBirthEnc
              : "",
          storeNumber: loginStore,
          taxId:
            filterData !== undefined && filterData[0].taxIdEnc
              ? filterData[0].taxIdEnc
              : "",
          customerId: custID?.customerId,
          address: {
            addressType:
              filterData !== undefined && address !== undefined
                ? address[0].addressType
                : "",
            addressLine1:
              filterData !== undefined && address !== undefined
                ? address[0].addressLine1
                : "",
            addressLine2:
              filterData !== undefined && address !== undefined
                ? address[0].addressLine2
                : "",
            city:
              filterData !== undefined && address !== undefined
                ? address[0].city
                : "",
            state:
              filterData !== undefined && address !== undefined
                ? address[0].stateCode
                : "",
            postalCode:
              filterData !== undefined && address !== undefined
                ? address[0].postalCode
                : "",
          },
          phone: {
            phoneNumber:
              filterData !== undefined && phones !== undefined
                ? phones[0].phoneNumber
                : "",
            phoneType:
              filterData !== undefined && phones !== undefined
                ? phones[0].phoneType
                : "",
            primary:
              filterData !== undefined && phones !== undefined
                ? phones[0].isPrimary
                : "",
            callTimeType: "MORN",
          },
          globalCustomerId:
            filterData?.length > 0 && filterData[0]?.globalCustomerId
              ? filterData[0]?.globalCustomerId
              : "",
        };
        console.log("payload", payload);
        setblurLoaderActive(true);
        await runDEaxiosCall(payload);
      }
    }
  };
  const runDEaxiosCall = async (payload: any) => {
    try {
      const response = await RunDE(payload);
      if (
        response !== undefined &&
        response.approvalResponse !== undefined &&
        (response.approvalResponse == "1" ||
          response.approvalResponse == "2" ||
          response.approvalResponse == "4")
      ) {
        setblurLoaderActive(false);
        setDEStatus(response.approvalResponse);
        setdedeclineisopen(true);
        console.log("Run DE REsp", response);
      }
      console.log("Run DE REsp1", response);
    } catch (e) {
      setblurLoaderActive(false);
      setDEStatus("");
      setdedeclineisopen(true);
      // console.log('payloaad', payload);
      // const runderesponse = await RunDE(payload);

      // console.log('runderesponse', runderesponse);
    }
  };
  const renderSearchTableHead = () => (
    <>
      {SearchGridHeader.map((col: string, index: number) => {
        if (col == "Last Name") {
          return (
            <RACTableCell
              className={`${classes.customerTablecellbgcolor} ${classes.gridBorderBottom} ${classes1.upTableHead}`}
              key={index}
            >
              {col}
              <span>
                <a
                  onClick={() =>
                    sortGrid(sortOrder.lN == "asc" ? "desc" : "asc", "LN")
                  }
                >
                  {sortOrder.lN == "asc" ? (
                    <SortDownIcon className={classes.spacerMS1}></SortDownIcon>
                  ) : (
                    <SortUpIcon className={classes.spacerMS1}></SortUpIcon>
                  )}
                </a>
              </span>
            </RACTableCell>
          );
        } else if (col == "First Name") {
          return (
            <RACTableCell
              className={`${classes.customerTablecellbgcolor} ${classes.gridBorderBottom} ${classes1.upTableHead}`}
              key={index}
            >
              {col}
              <span>
                <a
                  onClick={() =>
                    sortGrid(sortOrder.fN == "asc" ? "desc" : "asc", "FN")
                  }
                >
                  {sortOrder.fN == "asc" ? (
                    <SortDownIcon className={classes.spacerMS1}></SortDownIcon>
                  ) : (
                    <SortUpIcon className={classes.spacerMS1}></SortUpIcon>
                  )}
                </a>
              </span>
            </RACTableCell>
          );
        } else {
          return (
            <RACTableCell
              className={`${classes.customerLineBreak} ${classes.customerTablecellbgcolor} ${classes.gridBorderBottom} ${classes1.upTableHead}`}
              key={index}
            >
              {col}
            </RACTableCell>
          );
        }
      })}
    </>
  );

  const renderSearchTableContent = () => {
    //if (loading) return <></>;

    return searchResults !== undefined &&
      searchResults !== null &&
      searchResults.length > 0
      ? renderSearchTableContentFilled()
      : renderContentNoRecordFound();
  };
  /* eslint-disable sonarjs/cognitive-complexity */
  const renderSearchTableContentFilled = () => {
    return searchResults.map((el: any, i: any) => {
      const address: any =
        el.addresses !== undefined &&
          el.addresses !== null &&
          el.addresses.length > 0
          ? el.addresses.filter(
            (e: any) => e.addressType == "PRIM" || e.addressType == "P"
          )
          : [];
      const phones: any =
        el.phones !== undefined && el.phones !== null && el.phones.length > 0
          ? el.phones.filter((e: any) => e.isPrimary == "Y")
          : [];
      const currentDate = moment();
      const targetDate = moment(el.approvalEndDate);
      const differenceInDays = targetDate.diff(currentDate, 'days');
      return (
        <React.Fragment key={i}>
          <RACTableRow
            key={i}
            className={
              showCustomerAgrIndex !== i
                ? `${classes.customerTablecellbgcolor} ${classes1.upTableRow}`
                : `${classes.customerTablecellbgcolor} ${classes.cusomerInfo} ${classes1.upTableRow}`
            }
          >
            {el.AgreementCount != 0 ? (
              <RACTableCell
                data-bs-toggle="collapse"
                onClick={() => showAgreement(i, el.globalCustomerId)}
                data-bs-target="#row-1"
                aria-expanded={showCustomerAgrIndex == i ? true : false}
                className={`${classes.accordianWidth}`}
              >
                {showCustomerAgrIndex !== i ? (
                  <img
                    className={`${classes.accordionIconGrid} ${classes.me2}`}
                    src={AccordionOpen}
                  />
                ) : (
                  <img
                    className={`${classes.accordionIconGrid} ${classes.me2}`}
                    src={AccordionDown}
                  />
                )}
                {/* <AccordianCloseIcon className={classes.me2} /> */}
                {/* <span
                  className={
                    showCustomerAgrIndex !== i
                      ? `${classes.accordionIconGrid} ${classes.me2} ${classes.accordionGridClose}`
                      : `${classes.accordionIconGrid} ${classes.me2}`
                  }
                ></span> */}
              </RACTableCell>
            ) : (
              <RACTableCell className={classes.customerTablecellbgcolor}>
                <span>{""}</span>
              </RACTableCell>
            )}
            <RACTableCell
              classes={{
                root: clsx(
                  classes.checkBoxwidth,
                  classes.customerTablecellbgcolor
                ),
              }}
            >
              <RACCheckBox
                label=""
                size="small"
                className={classes.customerCheckbox}
                name={`checkbox_${i}`}
                onClick={() => checkBoxActiveOnclick(i, el.globalCustomerId)}
                checked={
                  checkBoxActive !== undefined && checkBoxActive == i
                    ? true
                    : false
                }
              />
            </RACTableCell>
            <RACTableCell className={classes.customerTablecellbgcolor}>
              <a
                onClick={() => getcustomerbyid("R", el.globalCustomerId)}
                className={classes.customerRaclink}
              >
                {el.lastName}
              </a>
            </RACTableCell>
            <RACTableCell className={classes.customerTablecellbgcolor}>
              <a
                onClick={() => getcustomerbyid("R", el.globalCustomerId)}
                className={classes.customerRaclink}
              >
                {el.firstName}
              </a>
            </RACTableCell>
            <RACTableCell
              className={
                el.middleName !== undefined &&
                  el.middleName !== null &&
                  el.middleName !== ""
                  ? classes.customerTablecellbgcolor
                  : classes.textCenter
              }
            >
              {el.middleName !== undefined &&
                el.middleName !== null &&
                el.middleName !== ""
                ? el.middleName
                : "-"}
            </RACTableCell>
            <RACTableCell
              className={
                el.suffix !== undefined &&
                  el.suffix !== null &&
                  el.suffix !== ""
                  ? classes.customerTablecellbgcolor
                  : classes.textCenter
              }
            >
              {el.suffix !== undefined && el.suffix !== null && el.suffix !== ""
                ? el.suffix
                : "-"}
            </RACTableCell>
            <RACTableCell
              className={
                el.dateOfBirth !== undefined &&
                  el.dateOfBirth !== null &&
                  el.dateOfBirth !== ""
                  ? classes.customerTablecellbgcolor
                  : classes.textCenter
              }
            >
              {el.dateOfBirth !== undefined && el.dateOfBirth != null
                ? el.dateOfBirth.split("-")[1] +
                "/" +
                el.dateOfBirth.split("-")[2] +
                "/" +
                "****"
                : ""}
            </RACTableCell>
            <RACTableCell
              className={
                address !== undefined &&
                  address !== null &&
                  address.length > 0 &&
                  address[0].addressLine1 !== ""
                  ? classes.customerTablecellbgcolor
                  : classes.textCenter
              }
            >
              {address !== undefined && address !== null && address.length > 0
                ? address[0].addressLine1
                : ""}
            </RACTableCell>
            <RACTableCell
              className={
                address !== undefined &&
                  address !== null &&
                  address.length > 0 &&
                  address[0].city !== ""
                  ? classes.customerTablecellbgcolor
                  : classes.textCenter
              }
            >
              {address !== undefined && address !== null && address.length > 0
                ? address[0].city
                : ""}
            </RACTableCell>
            <RACTableCell
              className={
                address !== undefined &&
                  address !== null &&
                  address.length > 0 &&
                  address[0].stateCode !== ""
                  ? classes.customerTablecellbgcolor
                  : classes.textCenter
              }
            >
              {address !== undefined && address !== null && address.length > 0
                ? address[0].stateCode
                : ""}
            </RACTableCell>
            <RACTableCell
              classes={{
                root: clsx(
                  classes.customerLinebreak,
                  classes.customerTablecellbgcolor
                ),
              }}
            >
              {address !== undefined && address !== null && address.length > 0
                ? address[0].postalCode
                : ""}
            </RACTableCell>
            <RACTableCell
              classes={{
                root: clsx(
                  classes.customerLinebreak,
                  classes.customerTablecellbgcolor
                ),
              }}
            >
              {phones !== undefined && phones !== null && phones.length > 0
                ? formatNum(phones[0].phoneNumber)
                : ""}
            </RACTableCell>
            <RACTableCell
              classes={{
                root: clsx(
                  classes.customerPointercursor,
                  classes.customerTablecellbgcolor
                ),
              }}
              data-bs-toggle="tooltip"
              data-bs-placement="left"
              title={
                el.approvalStatus !== undefined && el.approvalStatus !== null
                  ? el.approvalStatus.toUpperCase() == "APPROVE" &&
                    differenceInDays < 0
                    ? "DE : Expired"
                    : el.approvalStatus.toUpperCase() == "APPROVE" &&
                      differenceInDays >= 0
                      ? "DE : Approved"
                      : el.approvalStatus.toUpperCase() == "DECLINE"
                        ? "DE : Declined"
                        : el.approvalStatus.toUpperCase() ==
                          "MANUAL_INTERVENTION" ||
                          el.approvalStatus.toUpperCase() == "CONDITIONAL APPROVAL"
                          ? "DE : Conditionally Approved"
                          : "No DE"
                  : "No DE"
              }
            >
              {el.approvalStatus !== undefined && el.approvalStatus !== null ? (
                el.approvalStatus.toUpperCase() == "APPROVE" &&
                  differenceInDays < 0 ? (
                  <DEDeclined
                    data-bs-toggle="tooltip"
                    data-bs-placement="left"
                  ></DEDeclined>
                ) : el.approvalStatus.toUpperCase() == "APPROVE" &&
                  differenceInDays >= 0 ? (
                  <DEApproved
                    data-bs-toggle="tooltip"
                    data-bs-placement="left"
                  ></DEApproved>
                ) : el.approvalStatus.toUpperCase() == "DECLINE" ? (
                  <DEDeclined
                    data-bs-toggle="tooltip"
                    data-bs-placement="left"
                  ></DEDeclined>
                ) : el.approvalStatus.toUpperCase() == "MANUAL_INTERVENTION" ||
                  el.approvalStatus.toUpperCase() == "CONDITIONAL APPROVAL" ? (
                  <DEConditionallyApproved
                    data-bs-toggle="tooltip"
                    data-bs-placement="left"
                  ></DEConditionallyApproved>
                ) : (
                  <DEDeclined
                    data-bs-toggle="tooltip"
                    data-bs-placement="left"
                  ></DEDeclined>
                )
              ) : (
                <DEDeclined
                  data-bs-toggle="tooltip"
                  data-bs-placement="left"
                ></DEDeclined>
              )}
            </RACTableCell>
            <RACTableCell
              className={
                category(el) !== "-"
                  ? `${classes.customerPointercursor} ${classes.customerTablecellbgcolor} ${classes.textCenter}`
                  : classes.textCenter
              }
            >
              {category(el)}
            </RACTableCell>
            <RACTableCell
              classes={{
                root: clsx(
                  classes.customerTextend,
                  classes.customerTablecellbgcolor,
                  classes.textCenter
                ),
              }}
              className={
                el.AgreementCount !== undefined &&
                  el.AgreementCount !== null &&
                  el.AgreementCount !== ""
                  ? classes.customerTablecellbgcolor
                  : classes.textCenter
              }
            >
              {isNaN(el.AgreementCount) ? "-" : el.AgreementCount}
            </RACTableCell>
          </RACTableRow>
          {agreementTable(i)}
        </React.Fragment>
      );
    });
  };
  const renderAgreementTableContentFilled = () => {
    return agreements.map((el: any, i: any) => {
      const exchangeAgreementDeductions: any = agreements[0]?.agreements;
      const exchangeAgreementsList: any = {};
      const agreementStatus : any = {};
      exchangeAgreementDeductions.forEach((element: any) => {
        exchangeAgreementsList[element?.agreementId] = element?.parentAgreementId;
        agreementStatus[element?.agreementId] = element?.isExchangeAgreement
      });
      console.log(exchangeAgreementsList, 'List of the Parent Agreements');
      return (
        <React.Fragment key={i}>
          <RACTableRow key={i}>
            <RACTableCell
              className={`${classes.customerTextsubtilegrid} ${classes.customerTablecellbgcolor}`}
              colSpan={15}
            >
              {el.storeNumber +
                " -  " +
                el.agreements[0].storeCity +
                ", " +
                el.agreements[0].stateProvinceAbbreviation}
            </RACTableCell>
          </RACTableRow>
          {el.agreements.map((el1: any, index: any) => {
            const passDue =
              el1.currentDayslate !== undefined &&
                el1.currentDayslate !== null &&
                el1.currentDayslate !== "" &&
                el1.currentDayslate !== "0" &&
                el1.currentDayslate !== 0
                ? false
                : true;
            return (
              <RACTableRow
                key={index}
                className={
                  passDue === false
                    ? `${classes.paymentIssue} ${classes.paymentIssue} ${classes.customerTablecellbgcolor}`
                    : `${classes.agreementRow} ${classes.customerTablecellbgcolor}`
                }
              >
                <RACTableCell>
                  {featureFlagDetails?.CustomerOrder == "0" &&
                    ["PRELIM", "PRELIMPAID", "PRELIMRFND", "PRELIMINV"].includes(
                      el1?.agreementRefCode
                    ) ? (
                    <a
                      className={`${classes.racpadAgrLink} ${classes.focforLinkPaymentIssue
                        } ${passDue === false
                          ? classes.racpadPaymentFails
                          : classes.racpadPaymentSuccess
                        } ${classes.racpadClubLink}`}
                      style={{ padding: "3px !important" }}
                    >
                      {el1.agreementNumber}
                    </a>
                  ) : (
                    <>
                      {(el1.agreementStatus == "Pending" ||
                        el1.agreementStatus == "Active" ||
                        el1.agreementStatus == "Preliminary" ||
                        el1.agreementStatus == "Preliminary Paid" ||
                        el1.agreementStatus == "Preliminary Refunded" ||
                        el1.agreementStatus == "Preliminary Inventory") &&
                        el1.agreementType !== "club" ? (
                        <a
                          className={`${classes.racpadAgrLink} ${classes.focforLinkPaymentIssue
                            } ${passDue === false
                              ? classes.racpadPaymentFails
                              : classes.racpadPaymentSuccess
                            } ${classes.racpadLink}`}
                          style={{ padding: "3px !important" }}
                          href={exchangeAgreementsList[el1.agreementId] && agreementStatus[el1.parentAgreementId] && racExchangeFuncFlag ?
                          `/agreement/info/details/${custID !== undefined &&
                            custID?.customerId !== undefined
                            ? custID?.customerId
                            : null
                            }/${el1.agreementId}?isExchange=1&exchangeagreementid=${exchangeAgreementsList[el1.agreementId]}` 
                          :`/agreement/info/details/${custID !== undefined &&
                            custID?.customerId !== undefined
                            ? custID?.customerId
                            : null
                            }/${el1.agreementId}`}
                        >
                          {el1.agreementNumber}
                        </a>
                      ) : (
                        <a
                          className={`${classes.racpadAgrLink} ${classes.focforLinkPaymentIssue
                            } ${passDue === false
                              ? classes.racpadPaymentFails
                              : classes.racpadPaymentSuccess
                            } ${classes.racpadClubLink}`}
                          style={{ padding: "3px !important" }}
                        >
                          {el1.agreementNumber}
                        </a>
                      )}
                    </>
                  )}
                  {el1.autoPay !== null &&
                    el1.autoPay !== undefined &&
                    el1.autoPay ? (
                    <span className={classes.badgeContainer}>AP</span>
                  ) : null}
                </RACTableCell>
                <RACTableCell
                  title={el1.agreementDescription}
                  className={
                    el1.agreementDescription !== undefined &&
                      el1.agreementDescription !== null &&
                      el1.agreementDescription !== ""
                      ? ""
                      : `${classes.textCenter}`
                  }
                >
                  {el1.agreementDescription !== undefined &&
                    el1.agreementDescription !== null &&
                    el1.agreementDescription !== ""
                    ? truncString(el1.agreementDescription, 35, "...")
                    : "-"}
                </RACTableCell>
                <RACTableCell
                  title={el1.agreementType}
                  className={
                    el1.agreementType !== undefined &&
                      el1.agreementType !== null &&
                      el1.agreementType !== ""
                      ? ""
                      : `${classes.textCenter}`
                  }
                >
                  {el1.agreementType !== undefined &&
                    el1.agreementType !== null &&
                    el1.agreementType !== ""
                    ? truncString(el1.agreementType, 17, "...")
                    : "-"}
                </RACTableCell>
                <RACTableCell className={classes.textCenter}>
                  {el1.numberOfPayments !== undefined &&
                    el1.numberOfPayments !== null &&
                    el1.numberOfPayments !== ""
                    ? el1.numberOfPayments
                    : "-"}
                </RACTableCell>
                <RACTableCell
                  className={
                    el1.totalCost !== undefined &&
                      el1.totalCost !== null &&
                      el1.totalCost !== ""
                      ? `${classes.lineBreak} ${classes.textRight}`
                      : `${classes.textCenter}`
                  }
                >
                  {el1.totalCost !== undefined &&
                    el1.totalCost !== null &&
                    el1.totalCost !== ""
                    ? "$ "
                    : ""}
                  {el1.totalCost !== undefined &&
                    el1.totalCost !== null &&
                    el1.totalCost !== ""
                    ? HandleDecimal(el1.totalCost)
                    : "-"}
                </RACTableCell>
                <RACTableCell
                  className={
                    el1.paymentSchedule !== undefined &&
                      el1.paymentSchedule !== null &&
                      el1.paymentSchedule !== ""
                      ? classes.lineBreak
                      : `${classes.textCenter}`
                  }
                >
                  {el1.paymentSchedule !== undefined &&
                    el1.paymentSchedule !== null &&
                    el1.paymentSchedule !== ""
                    ? el1.paymentSchedule
                    : "-"}
                </RACTableCell>
                <RACTableCell
                  className={`${classes.lineBreak} ${classes.textCenter}`}
                >
                  {el1?.currentDueDate !== undefined &&
                    el1?.currentDueDate !== null &&
                    el1?.currentDueDate !== ""
                    ? nextDueDayWithDate(el1?.currentDueDate)
                    : "-"}
                </RACTableCell>
                <RACTableCell
                  className={
                    el1.amountDue !== undefined &&
                      el1.amountDue !== null &&
                      el1.amountDue !== ""
                      ? `${classes.textRight}`
                      : `${classes.textCenter}`
                  }
                >
                  {el1.amountDue !== undefined &&
                    el1.amountDue !== null &&
                    el1.amountDue !== ""
                    ? "$ "
                    : "-"}
                  {el1.amountDue !== undefined &&
                    el1.amountDue !== null &&
                    el1.amountDue !== ""
                    ? HandleDecimal(el1.amountDue)
                    : ""}
                </RACTableCell>
                <RACTableCell
                  className={
                    el1.agreementStatus !== undefined &&
                      el1.agreementStatus !== null &&
                      el1.agreementStatus !== ""
                      ? ""
                      : `${classes.textCenter}`
                  }
                >
                  {el1.agreementStatus !== undefined &&
                    el1.agreementStatus !== null &&
                    el1.agreementStatus !== ""
                    ? el1.agreementStatus
                    : "-"}
                </RACTableCell>
                <RACTableCell
                  className={
                    el1.agreementSubstatus !== undefined &&
                      el1.agreementSubstatus !== null &&
                      el1.agreementSubstatus !== ""
                      ? `${classes.textAlign} ${classes.lineBreak}`
                      : `${classes.textCenter}`
                  }
                >
                  {el1.agreementSubstatus !== undefined &&
                    el1.agreementSubstatus !== null &&
                    el1.agreementSubstatus !== ""
                    ? el1.agreementSubstatus
                    : "-"}
                </RACTableCell>
                {isRetentionEnabled || isExchangeEnabled ? (
                  <RACTableCell 
                  className={el1.isExchangeAgreement && racExchangeFuncFlag ? `${classes.textAlignCenter} ${classes.noWrap} ${classes.wrapContent1} ${classes.height100} ${classes.positionRelative} ${classes.pointerEvents}` : `${classes.textAlignCenter} ${classes.noWrap} ${classes.wrapContent1} ${classes.height100}`}>
                  {/* {el1.isExchangeAgreement && racExchangeFuncFlag ? 
                    <Grid className={el1.isExchangeAgreement ? `${classes.overlay}` : ''}>

                    </Grid>
                    : null
                  } */}
                  {validateForExchange(el1?.agreementStatus, el1?.closeDate, el1?.currentDueDate, el1?.agreementOpenDate, el1.isEppAgreement, el1.agreementType) && (el1.storeNumber  == String(window.sessionStorage.getItem("storeNumber"))) ? (
                      <RACTooltip
                        placement={el1?.isDeliveryConfirmed == '0' ? 'top' : 'top-start'}
                        className={classes.tooltipcss} 
                        classes={{
                          tooltip: classes.toolTipStyle,
                          arrow: classes.arrowStyle
                        }}
                        title={<span style={{fontSize:'10px'}}>{el1?.isDeliveryConfirmed == '0' ? `Please confirm delivery to initiate an exchange` :`Exchange`}</span>
                      }
                      >
                        <Grid className={el1?.isDeliveryConfirmed == '0' ? `${classes.positionRelative}` : ``}>
                          {/* {
                            el1?.isDeliveryConfirmed == '0' ? 
                            <Grid className={el1?.isDeliveryConfirmed == '0' ? `${classes.overlay1}` : ``}>

                            </Grid> : null
                          } */}
                          <ExchangeIcon className={isRetentionEnabled && el1.agreementType !== "club" && el1.agreementStatus !== "Pending" && !el1.isEppAgreement && el.storeNumber == String(window.sessionStorage.getItem("storeNumber")) ? (el1?.isDeliveryConfirmed == '0' || el1.isExchangeAgreement) ? `${classes.blur} ${classes.iconWidth1} ${classes.pointer} ${classes.MR1}` : `${classes.iconWidth1} ${classes.pointer} ${classes.MR1}` : (el1?.isDeliveryConfirmed == '0' || el1.isExchangeAgreement) ? `${classes.iconWidth1} ${classes.pointer} ${classes.blur}` : `${classes.iconWidth1} ${classes.pointer}`}
                            onClick={() => {
                              if (racExchangeFuncFlag && el1?.isDeliveryConfirmed == '1') {
                                setOpenSLA(true);
                                setExchangeSelected(true);
                                setExchangeAgreementId(el1?.agreementId);
                                setExchangeRentPaid(el1?.rentPaid);
                                setSuspenseAmount(el1?.suspenseAmount == '0.00' ? 0 : el1?.suspenseAmount)
                                setExchangeAgreementNumber(el1?.agreementNumber)
                              }
                            }}
                          />
                        </Grid>
                      </RACTooltip>
                  ):  null}
                  {isRetentionEnabled && el1.agreementType !== "club" &&
                    !el1.isEppAgreement &&
                    el1.agreementStatus !== "Pending" &&
                    el.storeNumber ==
                    String(window.sessionStorage.getItem("storeNumber")) ? (
                      // <Box
                      // component="span"
                      // style={{ float: 'right', position: 'relative'}}
                      // >
                        <RACTooltip 
                        placement={'top-start'}
                        className={classes.tooltipcss}
                        classes={{
                          tooltip: classes.toolTipStyle,
                          arrow: classes.arrowStyle
                        }}
                        title={<span style={{fontSize:'10px'}}>Retention</span>}>
                          <RetentionIcon className={el1.isExchangeAgreement ? `${classes.iconWidth2} ${classes.pointer} ${classes.blur}` : `${classes.iconWidth2} ${classes.pointer}`} onClick={() => {
                            setOpenSLA(true);
                            setChoosenAgreementNumber(el1.agreementNumber);
                            setChoosenAgreementID(el1.agreementId);
                          }} />
                        </RACTooltip>
                      // </Box>
                  ) : null}
                  {
                    !(validateForExchange(el1?.agreementStatus, el1?.closeDate, el1?.currentDueDate, el1?.agreementOpenDate, el1?.isEppAgreement, el1.agreementType) && el.storeNumber ==
                    String(window.sessionStorage.getItem("storeNumber"))) &&
                    !(isRetentionEnabled && el1.agreementType !== "club" && !el1.isEppAgreement && el1.agreementStatus !== "Pending" &&
                    el.storeNumber ==
                    String(window.sessionStorage.getItem("storeNumber"))) ? <span style={{textAlign: 'center', marginTop: '11px', width: '100%'}}>-</span> 
                    : null
                  }
                </RACTableCell>
                ) : null}
              </RACTableRow>
            );
          })}
        </React.Fragment>
      );
    });
  };
  /* eslint-disable sonarjs/cognitive-complexity */
  const renderContentNoRecordFound = () => {
    return (
      <RACTableRow hover backgroundColor="white">
        <RACTableCell></RACTableCell>
        <RACTableCell></RACTableCell>
        <RACTableCell></RACTableCell>
        <RACTableCell></RACTableCell>
        <RACTableCell></RACTableCell>
        <RACTableCell></RACTableCell>
        <RACTableCell>No Records Found</RACTableCell>
        <RACTableCell></RACTableCell>
        <RACTableCell></RACTableCell>
        <RACTableCell></RACTableCell>
        <RACTableCell></RACTableCell>
        <RACTableCell></RACTableCell>
      </RACTableRow>
    );
  };
  const renderAgreementTableHead = () => (
    <>
      {agreementGridHeader.map((col: string, index: number) => {
        const showAction = isRetentionEnabled || isExchangeEnabled && col == "Action" ? true : false;
        return showAction ? (
          <RACTableCell
            className={`${classes.lineBreak} ${classes.customerTablecellbgcolor} ${classes.Pl25}`}
            key={index}
          >
            {col}
          </RACTableCell>
        ) : col != "Action" ? (
          <RACTableCell
            className={
              col == "Total Cost" || col == "Amount Due"
                ? `${classes.colRight} ${classes.lineBreak} ${classes.customerTablecellbgcolor}`
                : col == "Next Due Date" || col == "# Payments"
                  ? `${classes.textCenter} ${classes.lineBreak} ${classes.customerTablecellbgcolor}`
                  : `${classes.lineBreak} ${classes.customerTablecellbgcolor}`
            }
            key={index}
          >
            {col}
          </RACTableCell>
        ) : null;
      })}
    </>
  );
  const renderAgreementTableContent = () => {
    //if (loading) return <></>;

    return agreements !== undefined &&
      agreements !== null &&
      agreements.length > 0
      ? renderAgreementTableContentFilled()
      : renderContentNoRecordFound();
  };
  const createAgreementPopup = () => {
    return (
      <Grid item md={12}>
        <Grid item md={12} className={classes.textCenter}>
          <Typography className={classes.RACPOPMsg}>
            Would you like to add the Co-Customer for agreements?
          </Typography>
        </Grid>
        <Grid
          container
          className={`${classes.justifyContentCenter} ${classes.spacerMT4}`}
        >
          <RACButton
            className={classes.spacerMR2}
            size="small"
            variant="outlined"
            color="primary"
            onClick={() => agreementRedirect()}
          >
            No
          </RACButton>
          <RACButton
            size="small"
            variant="contained"
            color="primary"
            onClick={() => createAgreementYesHandleChange()}
          >
            Yes
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  const addCoCustomerChildrenPopup = () => {
    return (
      <AddCoCustomer
        customerId={
          custID !== undefined && custID?.customerId !== undefined
            ? custID?.customerId
            : null
        }
        handleClosePopUp={(action: boolean) => {
          Setshowcocustomerpopup(action);
        }}
        showCustomerInfo={(customerId: string) => {
          props.history.push(`/customer/update/${customerId}/customer`);
        }}
        history={props.history}
        styles={customerStyles}
      ></AddCoCustomer>
    );
  };
  const activityLoadingRenderer = () => {
    return (
      <>
        <Grid className={`${classes.textCenter}`}>
          <CircularProgress />
          <Typography
            className={`${classes.textCenter} ${classes.bold} ${classes.mt2} `}
          >
            Saving Information
          </Typography>
          <Typography className={`${classes.gridLabel} ${classes.mt2}`}>
            Please wait...
          </Typography>
        </Grid>
      </>
    );
  };
  function AddCoCustomerPopup() {
    return (
      <RACModalCard
        isOpen={showcocustomerpopup}
        maxWidth="lg"
        borderRadius="20px"
        closeIcon={true}
        onClose={() => Setshowcocustomerpopup(false)}
        // eslint-disable-next-line react/no-children-prop
        children={addCoCustomerChildrenPopup()}
        title="Co-Customer Information"
      />
    );
  }
  const createAgreementYesHandleChange = () => {
    setliketoCreateAgreement(false);
    Setshowcocustomerpopup(true);
  };
  function CreateAgreementrMsg() {
    return (
      <RACModalCard
        isOpen={liketoCreateAgreement}
        maxWidth="xs"
        borderRadius="20px"
        closeIcon={true}
        onClose={() => setliketoCreateAgreement(false)}
        // eslint-disable-next-line react/no-children-prop
        children={createAgreementPopup()}
        title=""
      />
    );
  }
  const postNoDESubmitClick = async () => {
    setreasonDEopen(false);
    if (
      custID !== undefined &&
      custID.globalCustomerId !== undefined &&
      custID?.customerId !== undefined
    ) {
      const res: any = {
        globalCustomerId: custID.globalCustomerId,
        customerId: custID?.customerId,
        deExceptionReason: postnodeReason,
      };
      const payload: any = {
        storeException: [
          {
            identifierId: Number(custID?.customerId),
            identifierType: 'CUSTOMER',
            storeNumber: window.sessionStorage.getItem('storeNumber'),
            exceptionTypeRefCode: "DEB",
            exceptionText: storeExceptionReason
          }
        ]
      }
      console.log("res", res);
      const response = await UpdateNoDeReason(res);
      storeException(payload)
      console.log(response);
      setdedeclineisopen(false);
    }
  };
  const DeOkclick = async () => {
    setdedeclineisopen(false);
    // if (DEStatus == '') {
    //   setreasonDEopen(true);
    // }
  };
  const dedeclineMsgPopup = () => {
    return (
      <>
        <Grid item md={12}>
          <Grid item md={12} className={classes.textCenter}>
            {DEStatus == "" ? (
              <Typography variant={"body2"} className={classes.RACPOPMsg}>
                Error in approval process. Please proceed without approval
              </Typography>
            ) : (
              <Typography variant={"body2"} className={classes.RACPOPMsg}>
                {`Decision engine has ${DEStatus == "1"
                  ? "approved"
                  : DEStatus == "2"
                    ? "declined"
                    : DEStatus == "4"
                      ? "conditionally approved"
                      : ""
                  } the customer`}
              </Typography>
            )}
          </Grid>
          <Grid
            container
            className={`${classes.justifyContentCenter} ${classes.spacerMT4}`}
          >
            <RACButton
              color="primary"
              variant="contained"
              onClick={() => DeOkclick()}
            >
              Ok
            </RACButton>
          </Grid>
        </Grid>
      </>
    );
  };
  // DE decline popup PC-CC-30
  function Dedecline() {
    return (
      <RACModalCard
        isOpen={dedeclineisopen}
        maxWidth="xs"
        borderRadius="20px"
        closeIcon={true}
        onClose={() => setdedeclineisopen(false)}
        // eslint-disable-next-line react/no-children-prop
        children={dedeclineMsgPopup()}
      />
    );
  }
  const noReasonDEPopup = () => {
    return (
      <Grid item md={12}>
        <Grid item md={12} className={classes.textCenter}>
          <Typography className={classes.RACPOPMsg}>
            Please specify the reason, why you are not running the decision
            engine?
          </Typography>
        </Grid>
        <Grid
          container
          className={`${classes.justifyContentCenter} ${classes.spacerMT4}`}
        >
          <RACSelect
            inputLabel="Reason"
            options={noDEReasonDD}
            name="postNODEReason"
            defaultValue={
              postnodeReason !== undefined &&
                postnodeReason !== null &&
                postnodeReason !== ""
                ? postnodeReason
                : "0"
            }
            loading={noDEReasonDD.length == 0 && !dropDownLoaded ? true : false}
            onChange={(e: any) => {
              e.target.name = `postNODEReason`;
              setpostnodeReason(e.target.value);
              setStoreExceptionReason(e?.currentTarget?.innerText);
            }}
            {...(noDEReasonDD.length == 0 &&
              dropDownLoaded && {
              errorMessage: API_ERROR_MESSAGE,
            })}
          />
        </Grid>
        <Grid
          container
          className={`${classes.justifyContentCenter} ${classes.spacerMT4}`}
        >
          <RACButton
            className={classes.spacerMR2}
            size="small"
            variant="outlined"
            color="primary"
            onClick={() => setreasonDEopen(false)}
          >
            Cancel
          </RACButton>
          <RACButton
            size="small"
            variant="contained"
            color="primary"
            isRounded={false}
            onClick={() => postNoDESubmitClick()}
            disabled={postnodeReason == "0" ? true : false}
          >
            Submit
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  function NoDEReason() {
    return (
      <RACModalCard
        isOpen={reasonDEopen}
        maxWidth="xs"
        borderRadius="20px"
        closeIcon={true}
        onClose={() => setreasonDEopen(false)}
        // eslint-disable-next-line react/no-children-prop
        children={noReasonDEPopup()}
      />
    );
  }
  const racCoWorkerFunc = async (moduleName: string) => {
    try {
      // eslint-disable-next-line no-console
      console.log("Module Name", moduleName);
      setSecondLevelAuthenOn(moduleName);
    } catch (e: any) {
      // eslint-disable-next-line no-console
      console.log("getprofileaccess payload", e);
    }
  };
  const handleEPPAuthentication = (type : string) =>{
    setBtnClicked(type);
    /* is rac worker & not yet validated */
    if( custID.coworker == 'Y'){  
      // Condition to check whether the user can able to create or not. 
      console.log("current role",currentRole);
      
      if(['DM','FSC-DM', 'RDT', 'FSC-RDT', 'RD', 'FSC-RD'].includes(currentRole)){
        // two factor authentication
        setSecondLevelAuthenOn('RAC Coworker')

      }else{
        setUpdatePopUp(true);
        // show error popup
      }
    }else{ 
      // Existing flow
      callrespfucntion(type)
    }
  }
  const callrespfucntion = (type : string)=>{
    setBtnClicked(null);
    if(type =='RENTAL')
      RentalRedirect('EPP_FLOw');
    else if(type =='CO')
      customerOrderRedirection(custID?.customerId);
    else if (type =='PROMO')
      PromoRedirection(custID?.customerId);
  }
  const UpdateInfoMsgPopup = () => {
    return (
      <Grid item md={12}>
        <Grid item md={12} className={classes.textCenter}>
          {<Alerticon/> }
          
          <Typography
            variant={'body1'}
            style={{margin:'0.25rem'}}
            // className={`${classes.my3} ${customerStyleClasses.RACPOPMsg}`}
          >{'The selected customer is a RAC Coworker. '}</Typography> 
          <Typography
          variant={'body2'}
          style={{marginTop:'0.25rem'}}
          // className={`${classes.my3} ${customerStyleClasses.RACPOPMsg}`}
        >{'Only DM ( and above ) can create agreements for RAC Coworkers.'}</Typography> 
        </Grid>
        <Grid
          item
          style={{ textAlign: 'center',marginTop:'1.25rem'
        }}
        >
          <RACButton
            variant="contained"
            color="primary"
            onClick={() => setUpdatePopUp(false)}
          >
            Ok
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  const RentalRedirect = (type?:string) => {
    if (
      custID.coworker !== undefined &&
      custID.coworker !== null &&
      custID.coworker == "Y" &&
      featureFlagDetails?.DiscountedAgreement == "0"
    ) {
      setcomingSoonPopup(true);
      //racCoWorkerFunc('RAC Coworker')
    } else if (
      custID.coworker !== undefined &&
      custID.coworker !== null &&
      custID.coworker == "Y" &&
      featureFlagDetails?.DiscountedAgreement == "1" && type!= 'EPP_FLOw'
    ) {
      //setcomingSoonPopup(true);
      racCoWorkerFunc("RAC Coworker");
    } else {
      createAgreementHandler();
    }
  };
  const seconduthenResponses = (e: any) => {
    if(e && btnClicked){
      // Existing Flow
      callrespfucntion(btnClicked)
    }
    else if (e) {
      createAgreementHandler();
    }
  };
  const createAgreementHandler = () => {
    console.log("agreement handler", custID);
    if (
      custID.coworker !== undefined &&
      custID.coworker !== null &&
      custID.coworker == "S"
    ) {
      setcomingSoonPopup(true);
    } else {
      // if (
      //   custID !== undefined &&
      //   custID.governmentIdType !== undefined &&
      //   custID.governmentIdType !== null &&
      //   custID.governmentIdType !== '' &&
      //   custID.emailAddress !== undefined &&
      //   custID.emailAddress !== null &&
      //   custID.emailAddress !== ''
      // ) {
      //   setliketoCreateAgreement(true);
      // } else {
      //   setagreementMandatoryFieldpopup(true);
      // }
      agreementRedirect();
    }
    // agreementRedirect();
  };
  const comingsoonpopUp = () => {
    return (
      <>
        <Grid className={`${classes.infoTextStyle}`}>
          <Typography>Coming Soon!</Typography>
        </Grid>
        <Grid
          container
          item
          xl={12}
          className={`${classes.px2} ${classes.pt2}`}
        >
          <CardContent className={`${classes.infoTextStyle} ${classes.mb5}`}>
            <Typography>
              {coWorkerRole == 1
                ? "Selected customer is a RAC employee. Only a DM (and above) can create an employee discounted Agreement"
                : coWorkerRole == 2
                  ? "Selected customer is a RAC employee, please use SIMS for creating discounted agreement"
                  : ""}
            </Typography>
          </CardContent>
        </Grid>
      </>
    );
  };

  function ComingSoonMsg() {
    return (
      <RACModalCard
        isOpen={comingSoonPopup}
        maxWidth="xs"
        borderRadius="20px"
        closeIcon={true}
        onClose={() => setcomingSoonPopup(false)}
        // eslint-disable-next-line react/no-children-prop
        children={comingsoonpopUp()}
        title=""
      />
    );
  }
  const AgreementMandotoryPopup = () => {
    return (
      <Grid item md={12}>
        <Grid item md={12} className={classes.textCenter}>
          <Alerticon></Alerticon>
          <Typography variant={"body2"} className={classes.RACPOPMsg}>
            Enter the mandatory fields to create agreement
          </Typography>
        </Grid>
        <Grid
          container
          className={`${classes.justifyContentCenter} ${classes.spacerMT4}`}
        >
          <RACButton
            variant="contained"
            color="primary"
            // eslint-disable-next-line sonarjs/no-identical-functions
            onClick={() =>
              custID !== undefined && custID?.customerId !== undefined
                ? props.history.push(
                  `/customer/update/${custID?.customerId}/customer`
                )
                : ""
            }
          >
            Ok
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  function AgreementMandatoryFieldpopup() {
    return (
      <RACModalCard
        isOpen={agreementMandatoryFieldpopup}
        maxWidth="xs"
        borderRadius="20px"
        closeIcon={true}
        onClose={() => setagreementMandatoryFieldpopup(false)}
        // eslint-disable-next-line react/no-children-prop
        children={AgreementMandotoryPopup()}
        title=""
      />
    );
  }
  const clearbtnClick = () => {
    setFirstName("");
    setLastName("");
    SetDob("");
    setssn1("");
    setssn2("");
    setssn3("");
    setPhonenumber("");
  };
  const customerOrderRedirection = (customerId: any) => {
    props.history.push(`/agreement/customerorder/itemsearch/${customerId}`);
  };

  const PromoRedirection = (customerId: any) => {
    props.history.push(`/agreement/promo/itemsearch/${customerId}`);
  };

  // retention

  const configCustomerFunctions = async () => {
    const StoreId = window.sessionStorage.getItem("storeNumber")
    const bodyobject = {
      storeNumbers: [String(window.sessionStorage.getItem("storeNumber"))],
      paramKeyNames: ["CustomerSearchCustomerRetention", "AgreementExchange", 'AgreementExchangePeriod', 'AgreementExchangeReturnPeriod','EnableGoogleAddressValidation', 'RACExchange'],
    };

    let CoworkerNum
    if(StoreId && StoreId != null && StoreId != undefined && StoreId != ''&& StoreId != 'null' && StoreId != 'undefined' ){
       CoworkerNum = await featureFlagInfo(bodyobject);
      console.log("CustomerRetention", CoworkerNum);
    }
  

    if (
      CoworkerNum !== undefined &&
      CoworkerNum !== null &&
      CoworkerNum.storeProfileResponse !== undefined &&
      CoworkerNum.storeProfileResponse &&
      CoworkerNum.storeProfileResponse.configDetails !== undefined &&
      CoworkerNum.storeProfileResponse.configDetails.length > 0 &&
      CoworkerNum.storeProfileResponse.configDetails[0].configDetails !==
      undefined &&
      CoworkerNum.storeProfileResponse.configDetails[0].configDetails.length > 0
    ) {
      for (
        let i = 0;
        i <
        CoworkerNum.storeProfileResponse.configDetails[0].configDetails.length;
        i++
      ) {
        const configArray =
          CoworkerNum.storeProfileResponse.configDetails[0].configDetails;
        if (configArray[i].paramKeyName == "CustomerSearchCustomerRetention") {
          setIsRetentionEnabled(
            Number(configArray[i].paramValue) > 0 ? true : false
          );
        }
        if (configArray[i].paramKeyName == "AgreementExchange") {
          setExchangeEnabled(
            Number(configArray[i].paramValue) > 0 ? true : false
          );
        }
        if (configArray[i].paramKeyName == "AgreementExchangePeriod") {
          setExchangePeriod(
            configArray[i].paramValue
          );
        }
        if (configArray[i].paramKeyName == "AgreementExchangeReturnPeriod") {
          setReturnPeriod(
            configArray[i].paramValue
          );
        }
        /* eslint-disble */
        (configArray[i].paramKeyName === 'RACExchange' && configArray[i].paramValue == '1' ) && setRacExchangeFuncFlag(true);

        (configArray[i].paramKeyName === 'EnableGoogleAddressValidation' && configArray[i].paramValue == '1' ) && setCanEnableGoogleValdation(true)
      }
    }
  };

  const SLACanceled = () => {
    setOpenSLA(false);
    setExchangeSelected(false);
  };

  const SLACompleted = async () => {
    setOpenSLA(false);
    if (exchangeSelected) {
      setExchangeNavigationPopup(true);
      setExchangeSelected(false);
    }
    else {
      setOpenOfferLoading(true);
      try {
        const retentionOffersResult = await getRetentionOffers({
          agreementNumbers: [String(choosenAgreementNumber)],
        });
        console.log("retentionOffersResult", retentionOffersResult);
        const collectedLabels: any = [];
        const constantLabels: any = [
          "Product",
          "No Longer Needs",
          "Service",
          "Moving",
        ];
        retentionOffersResult.data?.returnReasons.map((el: any) => {
          collectedLabels.push(el.reason);
        });

        if (retentionOffersResult?.data?.agreementOffers[0]?.exchangeOfferDescription && racExchangeFuncFlag) {
          collectedLabels.push('RAC Exchange');
        }

        setAvailableOffers([...constantLabels, ...collectedLabels]);
        if (retentionOffersResult.data) {
          setOfferResponse(retentionOffersResult.data);
        }
        setOpenRetention(true);
      } catch {
        setOfferResponse(undefined);
        setAvailableOffers(availableOffers);
        setOpenRetention(true);
      }
      setOpenOfferLoading(false);
    };
  }

  const closeRetentionPopup = async () => {
    setOpenRetention(false);
    setChoosenIndex(undefined);
    setChoosenRetentionOptions(undefined);
    setChoosenOfferReason(undefined);

    const today = new Date();
    const date = today.toISOString().split("T")[0];
    const payloadAgreementActivities: any = [];
    if (choosenOfferReason == "Total RTO/Term too high") {
      retentionOptions.map((el: any) => {
        if (
          el.displayLabel !== "Customer does not accept any offer" &&
          el.displayLabel !== "No retention offer option available"
        ) {
          payloadAgreementActivities.push({
            activityDate: date,
            agreementId: choosenAgreementID,
            activityDescription: `TRTO high Canceled: sel ${el.displayLabel}`,
            agreementActivityType: "CANSCRTRT",
          });
        }
      });
      if (
        choosenRetentionOptions?.displayLabel ==
        "Customer does not accept any offer"
      ) {
        payloadAgreementActivities.push({
          activityDate: date,
          agreementId: choosenAgreementID,
          activityDescription: `TRTO high Canceled: sel Customer does not accept any offer`,
          agreementActivityType: "CANSCRTRT",
        });
      } else {
        payloadAgreementActivities.push({
          activityDate: date,
          agreementId: choosenAgreementID,
          activityDescription: `TRTO high Canceled: Not sel Customer does not accept any offer`,
          agreementActivityType: "CANCRTRTO",
        });
      }
    } else if (choosenOfferReason == "SAC too High") {
      retentionOptions.map((el: any) => {
        if (
          el.displayLabel !== "Customer does not accept any offer" &&
          el.displayLabel !== "No retention offer option available"
        ) {
          payloadAgreementActivities.push({
            activityDate: date,
            agreementId: choosenAgreementID,
            activityDescription: `SAC high Canceled: Sel ${el.displayLabel}`,
            agreementActivityType: "CANSCRSACT",
          });
        }
      });
      if (
        choosenRetentionOptions?.displayLabel ==
        "Customer does not accept any offer"
      ) {
        payloadAgreementActivities.push({
          activityDate: date,
          agreementId: choosenAgreementID,
          activityDescription: `SAC high Canceled: Sel Customer does not accept any offer`,
          agreementActivityType: "CANSCRSACT",
        });
      } else {
        payloadAgreementActivities.push({
          activityDate: date,
          agreementId: choosenAgreementID,
          activityDescription: `SAC high Canceled: Not sel Customer does not accept any offer`,
          agreementActivityType: "CANCRSACTH",
        });
      }
    } else if (choosenOfferReason == "EPO too High") {
      retentionOptions.map((el: any) => {
        if (
          el.displayLabel !== "Customer does not accept any offer" &&
          el.displayLabel !== "No retention offer option available"
        ) {
          payloadAgreementActivities.push({
            activityDate: date,
            agreementId: choosenAgreementID,
            activityDescription: `EPO high Canceled: Sel ${el.displayLabel}`,
            agreementActivityType: "CANSCREPOT",
          });
        }
      });
      if (
        choosenRetentionOptions?.displayLabel ==
        "Customer does not accept any offer"
      ) {
        payloadAgreementActivities.push({
          activityDate: date,
          agreementId: choosenAgreementID,
          activityDescription: `EPO high Canceled: Sel Customer does not accept any offer`,
          agreementActivityType: "CANSCREPOT",
        });
      } else {
        payloadAgreementActivities.push({
          activityDate: date,
          agreementId: choosenAgreementID,
          activityDescription: `EPO high Canceled: Not sel Customer does not accept any offer`,
          agreementActivityType: "CANCREPOTH",
        });
      }
    } else if (choosenOfferReason == "Renewal rate too high") {
      retentionOptions.map((el: any) => {
        if (
          el.displayLabel !== "Customer does not accept any offer" &&
          el.displayLabel !== "No retention offer option available"
        ) {
          payloadAgreementActivities.push({
            activityDate: date,
            agreementId: choosenAgreementID,
            activityDescription: `Rate high Canceled: Sel ${el.displayLabel}`,
            agreementActivityType: "CANSCRRTH",
          });
        }
      });
      if (
        choosenRetentionOptions?.displayLabel ==
        "Customer does not accept any offer"
      ) {
        payloadAgreementActivities.push({
          activityDate: date,
          agreementId: choosenAgreementID,
          activityDescription: `Rate high Canceled: Sel Customer does not accept any offer`,
          agreementActivityType: "CANSCRRTH",
        });
      } else {
        payloadAgreementActivities.push({
          activityDate: date,
          agreementId: choosenAgreementID,
          activityDescription: `Rate high Canceled: Not Sel Customer does not accept any offer`,
          agreementActivityType: "CANCRRTH",
        });
      }
    } else if (choosenOfferReason == "Cannot make current payment") {
      retentionOptions.map((el: any) => {
        if (
          el.displayLabel !== "Customer does not accept any offer" &&
          el.displayLabel !== "No retention offer option available"
        ) {
          payloadAgreementActivities.push({
            activityDate: date,
            agreementId: choosenAgreementID,
            activityDescription: `make paymnt Canceled: Sel ${el.displayLabel}`,
            agreementActivityType: "CANSCRCMCP",
          });
        }
      });
      if (
        choosenRetentionOptions?.displayLabel ==
        "Customer does not accept any offer"
      ) {
        payloadAgreementActivities.push({
          activityDate: date,
          agreementId: choosenAgreementID,
          activityDescription: `make paymnt Canceled: Sel Customer does not accept any offer`,
          agreementActivityType: "CANSCRCMCP",
        });
      } else {
        payloadAgreementActivities.push({
          activityDate: date,
          agreementId: choosenAgreementID,
          activityDescription: `make paymnt Canceled: Not sel Customer does not accept any offer`,
          agreementActivityType: "CANCCRCMCP",
        });
      }
    } else if (choosenOfferReason == "Service") {
      retentionOptions.map((el: any) => {
        if (
          el.displayLabel !== "Customer does not accept any offer" &&
          el.displayLabel !== "No retention offer option available"
        ) {
          payloadAgreementActivities.push({
            activityDate: date,
            agreementId: choosenAgreementID,
            activityDescription: `Service: Sel ${el.displayLabel}`,
            agreementActivityType: "CANSCRSERV",
          });
        }
      });
      if (
        choosenRetentionOptions?.displayLabel ==
        "Customer does not accept any offer"
      ) {
        payloadAgreementActivities.push({
          activityDate: date,
          agreementId: choosenAgreementID,
          activityDescription: `Service: Sel Customer does not accept any offer`,
          agreementActivityType: "CANSCRSERV",
        });
      } else {
        payloadAgreementActivities.push({
          activityDate: date,
          agreementId: choosenAgreementID,
          activityDescription: `Service: Not sel Customer does not accept any offer`,
          agreementActivityType: "CANCRSERV",
        });
      }
    } else if (choosenOfferReason == "Product") {
      retentionOptions.map((el: any) => {
        if (
          el.displayLabel !== "Customer does not accept any offer" &&
          el.displayLabel !== "No retention offer option available"
        ) {
          payloadAgreementActivities.push({
            activityDate: date,
            agreementId: choosenAgreementID,
            activityDescription: `Product: Sel ${el.displayLabel}`,
            agreementActivityType: "CANSCRPROD",
          });
        }
      });
      if (
        choosenRetentionOptions?.displayLabel ==
        "Customer does not accept any offer"
      ) {
        payloadAgreementActivities.push({
          activityDate: date,
          agreementId: choosenAgreementID,
          activityDescription: `Product: Sel Customer does not accept any offer`,
          agreementActivityType: "CANSCRPROD",
        });
      } else {
        payloadAgreementActivities.push({
          activityDate: date,
          agreementId: choosenAgreementID,
          activityDescription: `Product: Not sel Customer does not accept any offer`,
          agreementActivityType: "CANCRPROD",
        });
      }
    } else if (choosenOfferReason == "Moving") {
      retentionOptions.map((el: any) => {
        if (
          el.displayLabel !== "Customer does not accept any offer" &&
          el.displayLabel !== "No retention offer option available"
        ) {
          payloadAgreementActivities.push({
            activityDate: date,
            agreementId: choosenAgreementID,
            activityDescription: `Moving: Sel ${el.displayLabel}`,
            agreementActivityType: "CANSCRMOVI",
          });
        }
      });
      if (
        choosenRetentionOptions?.displayLabel ==
        "Customer does not accept any offer"
      ) {
        payloadAgreementActivities.push({
          activityDate: date,
          agreementId: choosenAgreementID,
          activityDescription: `Moving: Sel Customer does not accept any offer`,
          agreementActivityType: "CANSCRMOVI",
        });
      } else {
        payloadAgreementActivities.push({
          activityDate: date,
          agreementId: choosenAgreementID,
          activityDescription: `Moving: Not sel Customer does not accept any offer`,
          agreementActivityType: "CANCRMOVI",
        });
      }
    } else if (choosenOfferReason == "No Longer Needs") {
      retentionOptions.map((el: any) => {
        if (
          el.displayLabel !== "Customer does not accept any offer" &&
          el.displayLabel !== "No retention offer option available"
        ) {
          payloadAgreementActivities.push({
            activityDate: date,
            agreementId: choosenAgreementID,
            activityDescription: `No Longer Needs: Sel ${el.displayLabel}`,
            agreementActivityType: "CANSCRNLN",
          });
        }
      });
      if (
        choosenRetentionOptions?.displayLabel ==
        "Customer does not accept any offer"
      ) {
        payloadAgreementActivities.push({
          activityDate: date,
          agreementId: choosenAgreementID,
          activityDescription: `No Longer Needs: Sel Customer does not accept any offer`,
          agreementActivityType: "CANSCRNLN",
        });
      } else {
        payloadAgreementActivities.push({
          activityDate: date,
          agreementId: choosenAgreementID,
          activityDescription: `No Longer Needs: Not sel Customer does not accept any offer`,
          agreementActivityType: "CANCRNLN",
        });
      }
    } else if (choosenOfferReason == 'RAC Exchange' && racExchangeFuncFlag) {
      retentionOptions.map((el: any) => {
        if (
          el.displayLabel !== "Customer does not accept any offer" &&
          el.displayLabel !== "No retention offer option available"
        ) {
          payloadAgreementActivities.push({
            activityDate: date,
            agreementId: choosenAgreementID,
            activityDescription: `No Longer Needs: Sel ${el.displayLabel}`,
            agreementActivityType: "AGMXNGCAN",
          });
        }
      });
      if (
        choosenRetentionOptions?.displayLabel ==
        "Customer does not accept any offer"
      ) {
        payloadAgreementActivities.push({
          activityDate: date,
          agreementId: choosenAgreementID,
          activityDescription: `No Longer Needs: Sel Customer does not accept any offer`,
          agreementActivityType: "CANSXNG",
        });
      } else {
        payloadAgreementActivities.push({
          activityDate: date,
          agreementId: choosenAgreementID,
          activityDescription: `No Longer Needs: Not sel Customer does not accept any offer`,
          agreementActivityType: "CANXNG",
        });
      }
    }
    if (payloadAgreementActivities.length) {
      try {
        const agreementActivityPayloadObj = {
          agreementActivities: payloadAgreementActivities,
        };
        await postAgreementActivity(agreementActivityPayloadObj);
      } catch {
        // No Catch
      }
    }
  };

  const recordRedirection = async () => {
    console.log('customerId retention', customerId);
    setOpenActivityLoading(true);
    const today = new Date();
    const date = today.toISOString().split("T")[0];
    const agreementActivityPayload: any = [];
    if (
      choosenRetentionOptions?.offerValue?.redirectionPage == "Payment" &&
      choosenRetentionOptions?.offerValue?.functionalityInPage == "EPO"
    ) {
      agreementActivityPayload.push({
        activityDate: date,
        agreementId: choosenAgreementID,
        activityDescription: "Agreement EPO Selected",
        agreementActivityType: "AEPOS",
      });
    } else if (
      choosenRetentionOptions?.offerValue?.redirectionPage == "Payment" &&
      choosenRetentionOptions?.offerValue?.functionalityInPage == "FREETIME"
    ) {
      agreementActivityPayload.push({
        activityDate: date,
        agreementId: choosenAgreementID,
        activityDescription: "Agreement Extension Selected",
        agreementActivityType: "AEXTNS",
      });
    } else if (
      choosenRetentionOptions?.offerValue?.redirectionPage == "Agreement" &&
      choosenRetentionOptions?.offerValue?.functionalityInPage == "RR"
    ) {
      agreementActivityPayload.push({
        activityDate: date,
        agreementId: choosenAgreementID,
        activityDescription: "Agreement Rate Adjustment Selected",
        agreementActivityType: "ARAS",
      });
    } else if (
      choosenRetentionOptions?.offerValue?.redirectionPage == "Agreement" &&
      choosenRetentionOptions?.offerValue?.functionalityInPage == "TR"
    ) {
      agreementActivityPayload.push({
        activityDate: date,
        agreementId: choosenAgreementID,
        activityDescription: "Agreement Term Adjustment Selected",
        agreementActivityType: "ATAS",
      });
    } else if (
      choosenRetentionOptions?.offerValue?.redirectionPage == "Agreement" &&
      choosenRetentionOptions?.offerValue?.functionalityInPage == "SW"
    ) {
      agreementActivityPayload.push({
        activityDate: date,
        agreementId: choosenAgreementID,
        activityDescription: "Agr Retention Switchout Selected",
        agreementActivityType: "AGMRETSWS",
      });
    } else if (
      choosenRetentionOptions?.offerValue?.redirectionPage == "Agreement" &&
      choosenRetentionOptions?.offerValue?.functionalityInPage == "S"
    ) {
      agreementActivityPayload.push({
        activityDate: date,
        agreementId: choosenAgreementID,
        activityDescription: "Agr Retention Service Selected",
        agreementActivityType: "AGMRETSS",
      });
    } else if (
      choosenRetentionOptions?.offerValue?.redirectionPage ==
      "StoreManagement" &&
      choosenRetentionOptions?.offerValue?.functionalityInPage == "MOVE"
    ) {
      agreementActivityPayload.push({
        activityDate: date,
        agreementId: choosenAgreementID,
        activityDescription: "Moving Selected",
        agreementActivityType: "MS",
      });
    } else if (
      choosenRetentionOptions.displayLabel ==
      "Customer does not accept any offer"
    ) {
      agreementActivityPayload.push({
        activityDate: date,
        agreementId: choosenAgreementID,
        activityDescription: "Agr Retention No Offer Selected",
        agreementActivityType: "AGMRETNONE",
      });
    } else if (
      choosenRetentionOptions.displayLabel ==
      "No retention offer option available"
    ) {
      agreementActivityPayload.push({
        activityDate: date,
        agreementId: choosenAgreementID,
        activityDescription: "Agr Retention No Offer Available",
        agreementActivityType: "RETNOAVAIL",
      });
    } else if (
      choosenRetentionOptions?.offerValue?.redirectionPage  ==
      "Inventory"
    ) {
      agreementActivityPayload.push({
        activityDate: date,
        agreementId: choosenAgreementID,
        activityDescription: "Agreement Exchange Selected",
        agreementActivityType: "AGMXNGS",
      });
    }
    const agreementActivityPayloadObj = {
      agreementActivities: agreementActivityPayload,
    };
    try {
      await postAgreementActivity(agreementActivityPayloadObj);
    } catch {
      // Exception Handle
    }
    if (
      choosenRetentionOptions?.offerValue?.redirectionPage == "Payment" &&
      choosenRetentionOptions?.offerValue?.functionalityInPage == "EPO"
    ) {
      const epoDiscountAmount = choosenRetentionOptions?.offerValue?.epoDiscount
        .trim()
        .replace(/\$/g, "");
      props.history.push({
        pathname: `/payment1/paymentinformation/${custID?.customerId}/0`,
        search: `?CRTYPE=EPO&SRC=PAYMENT`,
        state: {
          retentionData: {
            newEpo: choosenRetentionOptions?.offerValue?.newEpo,
            epoDiscount: epoDiscountAmount,
            currentEpo: choosenRetentionOptions?.offerValue?.currentEpo,
            agreementNumber: choosenAgreementNumber,
            agreementId: choosenAgreementID,
            retentionEPOMessage: choosenRetentionOptions.displayLabel,
          },
        },
      });
    } else if (
      choosenRetentionOptions?.offerValue?.redirectionPage == "Payment" &&
      choosenRetentionOptions?.offerValue?.functionalityInPage == "FREETIME"
    ) {
      props.history.push({
        pathname: `/payment1/paymentinformation/${custID?.customerId}/0`,
        search: `?CRTYPE=FREETIME&SRC=PAYMENT`,
        state: {
          retentionData: {
            extentionDays: choosenRetentionOptions?.offerValue?.extension,
            agreementNumber: choosenAgreementNumber,
            agreementId: choosenAgreementID,
          },
        },
      });
    } else if (
      choosenRetentionOptions?.offerValue?.redirectionPage == "Agreement" &&
      choosenRetentionOptions?.offerValue?.functionalityInPage == "RR"
    ) {
      props.history.push({
        pathname: `/agreement/info/details/${custID?.customerId}/${choosenAgreementID}`,
        search: `?source=AMRETENTION&type=RR`,
        state: {
          retentionData: {
            retentionData: offerResponse,
          },
        },
      });
    } else if (
      choosenRetentionOptions?.offerValue?.redirectionPage == "Agreement" &&
      choosenRetentionOptions?.offerValue?.functionalityInPage == "TR"
    ) {
      props.history.push({
        pathname: `/agreement/info/details/${custID?.customerId}/${choosenAgreementID}`,
        search: `?source=AMRETENTION&type=TR`,
        state: {
          retentionData: offerResponse,
        },
      });
    } else if (
      choosenRetentionOptions?.offerValue?.redirectionPage == "Agreement" &&
      choosenRetentionOptions?.offerValue?.functionalityInPage == "SW"
    ) {
      props.history.push({
        pathname: `/agreement/info/details/${custID?.customerId}/${choosenAgreementID}`,
        search: `?source=AMRETENTION&type=SW`,
        state: {
          retentionData: offerResponse,
        },
      });
    } else if (
      choosenRetentionOptions?.offerValue?.redirectionPage == "Agreement" &&
      choosenRetentionOptions?.offerValue?.functionalityInPage == "R"
    ) {
      props.history.push({
        pathname: `/agreement/info/details/${custID?.customerId}/${choosenAgreementID}`,
        search: `?source=AMRETENTION&type=R`,
        state: {
          retentionData: offerResponse,
        },
      });
    } else if (
      choosenRetentionOptions?.offerValue?.redirectionPage == "Agreement" &&
      choosenRetentionOptions?.offerValue?.functionalityInPage == "S"
    ) {
      props.history.push({
        pathname: `/agreement/info/details/${custID?.customerId}/${choosenAgreementID}`,
        search: `?source=AMRETENTION&type=S`,
        state: {
          retentionData: offerResponse,
        },
      });
    } else if (
      choosenRetentionOptions?.offerValue?.redirectionPage ==
      "StoreManagement" &&
      choosenRetentionOptions?.offerValue?.functionalityInPage == "MOVE"
    ) {
      props.history.push({
        pathname: `/storemgmt/lookupstore`,
      });
    } else if (
      choosenRetentionOptions?.offerValue?.redirectionPage ==
        "Inventory"
    ) {
      if(racExchangeFuncFlag){
      props.history.push(`/agreement/rental/itemsearch/${custID?.customerId}?isExchange=1&agreementId=${choosenAgreementID}`)
      }
      else{
        setExchangeNavigationPopup(true);
      }
    }
    setOpenActivityLoading(false);
  };

  const acceptReinstate = async () => {
    setOpenActivityLoading(true);
    const today = new Date();
    const date = today.toISOString().split('T')[0];

    const agreementActivityPayloadObj = {
      agreementActivities: [
        {
          activityDate: date,
          agreementId: choosenAgreementID,
          activityDescription: 'No Longer Needs Selected',
          agreementActivityType: 'NLNS',
        },
      ],
    };

    try {
      await postAgreementActivity(agreementActivityPayloadObj);
    } catch {
      // Exception Handle
    }
    props.history.push({
      pathname: `/agreement/info/details/${custID?.customerId}/${choosenAgreementID}`,
      search: `?source=AMRETENTION&type=R`,
      state: {
        retentionData: offerResponse,
      },
    });
    setOpenActivityLoading(false);
  };

  const buildReinstateAlert = () => {
    return (
      <Grid>
        <Grid className={`${classes.textCenter}`}>
          <Typography className={`${classes.bold}`}>
            At any time you can contact your store to schedule a return of your
            product or return merchandise in person and pause your payments.
            When you are ready, you can come back, reinstate your agreement to
            get the same or comparable item, and pick up your payments right
            where you left off.
          </Typography>
        </Grid>
        <Grid className={`${classes.py4} ${classes.textCenter}`}>
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => {
              setOpenReinstateAlert(false);
              acceptReinstate();
            }}
          >
            Return
          </RACButton>
          <RACButton
            className={classes.mx1}
            variant="outlined"
            color="primary"
            onClick={() => {
              setOpenReinstateAlert(false);
              setOpenRetention(true);
            }}
          >
            Cancel
          </RACButton>
        </Grid>
      </Grid>
    );
  };

  const renderRetentionModal = () => {
    return (
      <>
        <Grid container className={classes.mt2}>
          <Typography
            variant="h6"
            className={`${classes.title1} ${classes.mb1}`}
          >
            Please select why the customer wishes to return to see Customer
            Retention Offer
          </Typography>
          <Grid container spacing={2}>
            {availableRetentionOffers?.map((el: any, index: any) => {
              return (
                <Grid
                  item
                  sm={6}
                  md={3}
                  className={`${classes.mb2}`}
                  key={index}
                >
                  <Card
                    className={
                      el == choosenOfferReason
                        ? `${classes.cardTile} ${classes.payScheduleHighlight} ${classes.cursorPointer} ${classes.cardTileStyle}`
                        : `${classes.cardTile} ${classes.payScheduleNotHighlight} ${classes.cursorPointer} ${classes.cardTileStyle}`
                    }
                    onClick={() => {
                      setChoosenOfferReason(el);
                      setChoosenRetentionOptions(undefined);
                      setChoosenIndex(undefined);
                    }}
                  >
                    <CardContent>
                      <Grid container className={classes.justifyLabel}>
                        <Typography
                          className={
                            el == choosenOfferReason
                              ? classes.paySchedulePeriodHighlight
                              : classes.paySchedulePeriodNotHighlight
                          }
                          variant="body1"
                        >
                          {el}
                        </Typography>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
          {choosenOfferReason ? (
            <Grid container className={`${classes.mb2}`}>
              <Card
                className={`${classes.card} ${classes.w100} ${classes.alertwidget} ${classes.mb1p}`}
              >
                <Grid
                  item
                  className={`${classes.w100} ${classes.px0} ${classes.floatLeft}`}
                >
                  <Grid container className={`${classes.px2} ${classes.h65}`}>
                    <Grid className={`${classes.w100} ${classes.floatLeft}`}>
                      <Grid
                        item
                        className={`${classes.raccollg6}  ${classes.floatLeft}`}
                      >
                        <Typography
                          component="span"
                          className={`${classes.titletxtstyle} ${classes.bold} ${classes.pe4} ${classes.floatLeft}`}
                        >
                          Retention Option
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container className={`${classes.px2}`}>
                    {retentionOptions?.map((el: any, index: any) => {
                      return (
                        <Grid
                          className={`${classes.w100} ${classes.floatLeft}`}
                          key={index}
                        >
                          <RACCheckBox
                            size="medium"
                            key={index}
                            onChange={() => {
                              if (choosenOptionIndex != index) {
                                setChoosenIndex(index);
                                setChoosenRetentionOptions(el);
                              } else {
                                setChoosenIndex(undefined);
                                setChoosenRetentionOptions(undefined);
                              }
                              //onCheckBoxClicked(index, el);
                            }}
                            checked={choosenOptionIndex == index ? true : false}
                            label={el.displayLabel}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
                <Grid
                  item
                  className={`${classes.w100} ${classes.px20} ${classes.floatRight} `}
                >
                  <RACButton
                    className={`${classes.floatRight} ${classes.ms2}`}
                    color="primary"
                    variant="contained"
                    disabled={choosenRetentionOptions ? false : true}
                    onClick={() => {
                      if (
                        choosenRetentionOptions?.displayLabel ==
                        "Explain lifetime reinstatement to the customer"
                      ) {
                        setOpenRetention(false);
                        setOpenReinstateAlert(true);
                      } else {
                        recordRedirection();
                        setOpenRetention(false);
                      }
                    }}
                  >
                    Record
                  </RACButton>
                  <RACButton
                    className={`${classes.floatRight} ${classes.ms2}`}
                    color="primary"
                    variant="outlined"
                    onClick={() => closeRetentionPopup()}
                  >
                    Cancel
                  </RACButton>
                </Grid>
              </Card>
            </Grid>
          ) : null}
        </Grid>
      </>
    );
  };

  const offerLoadingRenderer = () => {
    return (
      <>
        <Grid className={`${classes.textCenter}`}>
          <CircularProgress />
          <Typography
            className={`${classes.textCenter} ${classes.bold} ${classes.mt2} `}
          >
            Fetching Retention Offers
          </Typography>
          <Typography className={`${classes.gridLabel} ${classes.mt2}`}>
            Please wait...
          </Typography>
        </Grid>
      </>
    );
  };

  //Exchange Indication
  function validateForExchange(agreementStatus: any, closeDate: any, currentDueDate: any, agreementOpenDate: any, isEppAgreement: any, agreementType: any) {
    if(!isExchangeEnabled || isEppAgreement) {
      return false;
  }
  const today = moment().format('YYYY-MM-DD');
  let sevenDaysAgo = moment(today, 'YYYY-MM-DD').subtract(7, 'days');
  if (returnPeriod) {
    sevenDaysAgo = moment(today, 'YYYY-MM-DD').subtract(returnPeriod, 'days');
  }
  let sixMonthsLater = agreementOpenDate && moment(agreementOpenDate, 'YYYY-MM-DD').add(6, 'months').format('YYYY-MM-DD');
  if (exchangePeriod) {
    sixMonthsLater = agreementOpenDate && moment(agreementOpenDate, 'YYYY-MM-DD').add(exchangePeriod, 'months').format('YYYY-MM-DD');
  }
  if((closeDate && (moment(closeDate).format('YYYY-MM-DD') >= sevenDaysAgo.format('YYYY-MM-DD'))) && (agreementStatus == 'Agreement Returned To Store (Repossession)')){
    return true;
  }
  if ((agreementStatus === 'Active' && agreementType == 'Rental Agreement (RTO)')) {
    if (currentDueDate && moment(currentDueDate).format('YYYY-MM-DD') >= today) {
      if (sixMonthsLater && today <= sixMonthsLater) {
        return true;
      }
    }
  }
  return false;
  } 

  return (
    <>
      <GetCustIDAlert />
      <EnterSSNfn />
      <Dedecline />
      <NoDEReason />
      <CreateAgreementrMsg />
      <AddCoCustomerPopup />
      <ComingSoonMsg />
      <AgreementMandatoryFieldpopup />
      <CreateCustomerModal />
      {secondLevelAuthenOn !== null ? (
        <SecondFactor
          setTwoFactorCancelClick={() => setSecondLevelAuthenOn(null)}
          setTwoFactorCompleted={(e: any) => seconduthenResponses(e)}
          moduleName={secondLevelAuthenOn}
          currentRole={currentRole}
        ></SecondFactor>
      ) : null}
      <Modal
        isOpen={blurLoaderActive}
        className={classes.loaderStyle}
        id="openpopup"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <div>
          <div className={classes.RACLoaderPage}>
            <CircularProgress />
          </div>
        </div>
      </Modal>
      <div className={classes.racFixedFooterMargin}>
        <Grid container className={`${classes.px2} ${classes.mb3}`}>
          <Grid item md={12}>
            <Typography
              className={`${classes.widgetTitle} ${classes.font16} ${classes.spacerMB1}`}
            >
              Search Criteria
            </Typography>
          </Grid>
          <Grid item md={12}>
            <Card className={classes.card}>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item lg={2} md={4} sm={6}>
                    <RACTextbox
                      type="text"
                      name="LastName"
                      value={LastName}
                      inputlabel="Last Name"
                      maxlength={30}
                      OnChange={(e: any) => HandleChange(e)}
                      isCurrency={false}
                      onKeyPress={(e: any) => enterKeyPressed(e)}
                    />
                  </Grid>
                  <Grid item lg={2} md={4} sm={6}>
                    <RACTextbox
                      type="text"
                      name="FirstName"
                      inputlabel="First Name"
                      value={FirstName}
                      maxlength={30}
                      OnChange={(e: any) => HandleChange(e)}
                      isCurrency={false}
                      onKeyPress={(e: any) => enterKeyPressed(e)}
                    />
                  </Grid>
                  <Grid
                    item
                    md={4}
                    lg={2}
                    sm={6}
                    className={classes.datePicker}
                  >
                    <Box
                      component="div"
                      className={`${classes.formLabel} ${classes.datePickerAddCo}`}
                    >
                      Date of Birth
                    </Box>
                    <RACDatePicker
                      id="a11y_dob"
                      label=""
                      value={Dob}
                      name={"Dob"}
                      onChange={(e: any) => HandleChange(e, "DOB")}
                      onKeyDown={(e: any) => enterKeyPressed(e)}
                      inputProps={{
                        min: moment()
                          .subtract(110, "years")
                          .format("YYYY-MM-DD"),
                        max: moment()
                          .subtract(18, "years")
                          .format("YYYY-MM-DD"),
                      }}
                    />
                  </Grid>
                  <Grid item lg={2} md={4} sm={6} className={classes.spacerPT1}>
                    <label htmlFor="a11y_ssn" className={classes.formLabel}>
                      Social Security# / ITIN#
                    </label>
                    <Grid container spacing={2} className={classes.ssnpadding}>
                      <Grid
                        item
                        md={4}
                        sm={4}
                        className={`${classesManageCustomerStyles.ssnPaddingTop}`}
                      >
                        <RACTextbox
                          type="password"
                          name="Ssn1"
                          value={Ssn1}
                          placeHolder={"xxx"}
                          id={"a11y_ssnone"}
                          className={classes.textCenterSSN}
                          maxlength={3}
                          OnChange={(e: any) => HandleChange(e)}
                          isCurrency={false}
                          onKeyPress={(e: any) => enterKeyPressed(e, 'SSN')}
                        />
                      </Grid>
                      <Grid
                        item
                        md={3}
                        sm={3}
                        className={`${classesManageCustomerStyles.ssnPaddingTop}`}
                      >
                        <RACTextbox
                          type="password"
                          name="Ssn2"
                          value={Ssn2}
                          placeHolder={"xx"}
                          id={"a11y_ssntwo"}
                          className={classes.textCenterSSN}
                          formControlClassName={classes.ssnTextField}
                          maxlength={2}
                          OnChange={(e: any) => HandleChange(e)}
                          isCurrency={false}
                          onKeyPress={(e: any) => enterKeyPressed(e, 'SSN')}
                        />
                      </Grid>
                      <Grid
                        item
                        md={5}
                        sm={5}
                        className={`${classesManageCustomerStyles.ssnPaddingTop}`}
                      >
                        <RACTextbox
                          type="text"
                          name="Ssn3"
                          value={Ssn3}
                          placeHolder={"xxxx"}
                          id={"a11y_ssnthree"}
                          className={classes.textCenterSSN}
                          maxlength={4}
                          OnChange={(e: any) => HandleChange(e)}
                          isCurrency={false}
                          onKeyPress={(e: any) => enterKeyPressed(e, 'SSN')}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item lg={2} md={4} sm={6}>
                    <RACTextbox
                      type="phoneno"
                      maxlength={14}
                      inputlabel="Phone Number"
                      id="a11y_phonenumber"
                      name="phoneNumber"
                      value={Phonenumber}
                      OnChange={(e: any) => HandleChange(e)}
                      isCurrency={false}
                      onKeyPress={(e: any) => enterKeyPressed(e)}
                    />
                  </Grid>
                  <Grid item lg={2} md={4} sm={6}>
                    <RACButton
                      variant="outlined"
                      color="primary"
                      className={`${classes.spacerMR2} ${classes.foc} ${classes.clrntnstyle}`}
                      disabled={
                        FirstName.length == 0 &&
                          LastName.length == 0 &&
                          Dob == "" &&
                          Ssn1.length == 0 &&
                          Ssn2.length == 0 &&
                          Ssn3.length == 0 &&
                          Phonenumber.length == 0
                          ? true
                          : false
                      }
                      data-testid="clrbtn"
                      onClick={() => {
                        clearbtnClick();
                      }}
                    >
                      Clear
                    </RACButton>
                    <RACButton
                      disabled={disableSearchBtn}
                      variant="contained"
                      color="primary"
                      size="small"
                      className={`${classes.spacerMT2} ${classes.spacerP1}`}
                      onClick={() => showSearchResults()}
                    >
                      <SearchImageDefault
                        className={classes.racGlobalSearchBtn}
                      ></SearchImageDefault>
                      Search
                    </RACButton>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        {initialNoRecPopUp ? (
          ""
        ) : searchResults.length > 0 && EnableLoaderButton === false ? (
          <>
            <Grid container className={`${classes.px2} ${classes.mb2}`}>
              <Grid item md={6} sm={6}>
                <Typography
                  className={`${classes.widgetTitle} ${classes.font16} ${classes.spacerMB1}`}
                >
                  Search Results
                </Typography>
              </Grid>
              <Grid item md={6} sm={6} className={classes.textRight}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => CreateCustomer()}
                >
                  Create Customer
                </Button>
              </Grid>
            </Grid>
            <Grid container className={`${classes.px2} ${classes.mb2}`}>
              <Card className={classes.card}>
                <CardContent className={classes.spacerP0}>
                  <Grid item md={12} className={classes.racGrid}>
                    <RACTable
                      renderTableHead={renderSearchTableHead}
                      renderTableContent={renderSearchTableContent}
                    />
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </>
        ) : EnableLoaderButton === false ? (
          <Grid item md={12} className={classes.px2}>
            <Grid
              container
              className={`${classes.justifyContentCenter} ${classes.mb2}`}
            >
              <Alerticon style={{ width: "30px", height: "30px" }} />
              {!isUnableTofetchData ? (
                <Box className={`${classes.ms2}`} style={{ marginTop: "5px" }}>
                  No Records Found
                </Box>
              ) : null}
            </Grid>
            <Grid item md={12}>
              <Typography
                className={`${classes.RACPOPMsgforCC} ${classes.textCenter}`}
              >
                {isUnableTofetchData ? (
                  <Box>Unable to fetch customer info</Box>
                ) : (
                  <Box>
                    Do you want to create{" "}
                    <Typography
                      variant="caption"
                      className={`${classes.bold} ${classes.font16}`}
                    >
                      {noSearchResult}
                    </Typography>{" "}
                    as a new customer?
                  </Box>
                )}
              </Typography>
            </Grid>
            <Grid
              container
              className={`${classes.justifyContentCenter} ${classes.mt4}`}
            >
              {isUnableTofetchData ? (
                <RACButton
                  color="primary"
                  variant="contained"
                  onClick={() => showSearchResults()}
                >
                  Retry
                </RACButton>
              ) : (
                <RACButton
                  color="primary"
                  variant="contained"
                  onClick={() => CreateCustomer()}
                >
                  Create customer
                </RACButton>
              )}
            </Grid>
          </Grid>
        ) : (
          ""
        )}
      </div>
      <Grid
        item
        classes={{
          root: clsx(classes.customerToolbar, classes.fixedBottom),
        }}
        style={{ zIndex: 0 }}
      >
        <Grid item className={classes.floatLeft}>
          <RACButton
            className={classes.spacerMR2}
            size="small"
            variant="outlined"
            color="primary"
            onClick={() => {
              props.history.push(`/dashboard`);
            }}
          >
            Cancel
          </RACButton>
        </Grid>
        <Grid item className={`${classes.floatRight} ${classes1.dMdNone}`}>
          {searchResults && searchResults.length > 0 ? (
            <>
              <RACButton
                size="small"
                variant="contained"
                color="primary"
                disabled={newCustValidBtnHidden}
                className={classes.spacerMR2}
                onClick={() => runDecisionEngine()}
              >
                Run Decision Engine
              </RACButton>

              <RACButton
                variant="contained"
                color="primary"
                className={classes.spacerMR2}
                disabled={newCustValidBtnHidden}
                onClick={() => paymentRedirect()}
              >
                Take Payment
              </RACButton>
              {legalHoldStatus === false ? (
                <Grid
                  item
                  className={`${classes.floatLeft} ${classes.me1} ${classes.pr}`}
                  role="group"
                >
                  <RACButton
                    variant="contained"
                    color="primary"
                    className={classes.spacerMR2}
                    disabled={newCustValidBtnHidden}
                    // onClick={() => agreementRedirect()}
                    onClick={() => setcreateAgrClicked(!createAgrClicked)}
                  >
                    Create Agreement
                    <span className={classes.dropdowntoggle}></span>
                  </RACButton>
                  {createAgrClicked ? (
                    <Grid
                      className={
                        checkAction == ""
                          ? `${classes.hideRental}`
                          : `${classes.popupRental}`
                      }
                    >
                      <List
                        data-testid="UlButtonsId"
                        aria-labelledby="btnGroupDrop1"
                        style={{
                          left: "947 !important",
                          top: "-35px !important",
                        }}
                      >
                        <ListItem>
                          <a
                            className={`${classes.dropdownitemcustom} ${classes.semiBold} ${classes.bgBlue}`}
                            data-bs-toggle="modal"
                            data-testid="TerminateLDWId"
                            data-bs-target="#terminateldw"
                            onClick={() => handleEPPAuthentication("RENTAL")}
                          >
                            Rental
                          </a>
                        </ListItem>
                        {featureFlagDetails?.CustomerOrder == "1" ? (
                          <ListItem>
                            <a
                              className={`${classes.dropdownitemcustom} ${classes.semiBold} ${classes.bgBlue}`}
                              data-bs-toggle="modal"
                              data-testid="TerminateLDWId"
                              data-bs-target="#terminateldw"
                              onClick={() =>
                                handleEPPAuthentication("CO")
                              }
                            >
                              Customer order
                            </a>
                          </ListItem>
                        ) : null}
                        {featureFlagDetails?.PromoAgreement == "1" ? (
                          <ListItem>
                            <a
                              className={`${classes.dropdownitemcustom} ${classes.semiBold} ${classes.bgBlue}`}
                              data-bs-toggle="modal"
                              data-testid="TerminateLDWId"
                              data-bs-target="#terminateldw"
                              onClick={() =>
                                handleEPPAuthentication("PROMO")
                              }
                            >
                              Promo
                            </a>
                          </ListItem>
                        ) : null}
                      </List>
                    </Grid>
                  ) : null}
                </Grid>
              ) : null}
            </>
          ) : null}
        </Grid>
        <div
          className={`${classes.floatRight} ${classes1.hide} ${classes1.dMdBlock} ${classes1.positionRelative}`}
        >
          {actionBtnView ? (
            <List
              className={`${classes1.positionAbsolute} ${classes1.actionsList}`}
            >
              <ListItem
                className={`${classes1.positionRelative} ${classes1.createAgrList}`}
                disabled={newCustValidBtnHidden}
                onClick={() => setcreateAgrClicked(!createAgrClicked)}
              >
                <img
                  style={{ width: "8px" }}
                  className={`${classes.me2}`}
                  src={PolygonRight}
                />
                <a>Create Agreement</a>
                <List className={`${classes1.positionAbsolute} `}>
                  <ListItem onClick={() => RentalRedirect()}>
                    <a>Rental</a>
                  </ListItem>
                  <ListItem
                    onClick={() => customerOrderRedirection(custID?.customerId)}
                  >
                    <a>Customer order</a>
                  </ListItem>
                </List>
              </ListItem>
              <ListItem
                disabled={newCustValidBtnHidden}
                onClick={() => runDecisionEngine()}
              >
                <img
                  style={{ width: "8px" }}
                  className={`${classes.me2} ${classes1.vNone}`}
                  src={PolygonRight}
                />
                <a>Run Decision Engine</a>
              </ListItem>
              <ListItem
                disabled={newCustValidBtnHidden}
                onClick={() => paymentRedirect()}
              >
                <img
                  style={{ width: "8px " }}
                  className={`${classes.me2} ${classes1.vNone}`}
                  src={PolygonRight}
                />
                <a>Take Payment</a>
              </ListItem>
            </List>
          ) : null}

          <RACButton
            className={`${classes.floatLeft} ${classes.me2}`}
            variant="contained"
            color="primary"
            endIcon={<Polygon />}
            onClick={() => {
              actionBtnView ? setactionBtnView(false) : setactionBtnView(true);
            }}
          >
            Actions
          </RACButton>
        </div>
      </Grid>

      <RACModalCard
        isOpen={openOfferLoading}
        maxWidth="xs"
        borderRadius={"25px !important"}
        //eslint-disable-next-line react/no-children-prop
        children={offerLoadingRenderer()}
      />

      <RACModalCard
        isOpen={openReinstateAlert}
        maxWidth="sm"
        borderRadius={'25px !important'}
        //eslint-disable-next-line react/no-children-prop
        children={buildReinstateAlert()}
      />

      <RACModalCard
        isOpen={openActivityLoading}
        maxWidth="xs"
        borderRadius={'25px !important'}
        //eslint-disable-next-line react/no-children-prop
        children={activityLoadingRenderer()}
      />

      <RACModalCard
        isOpen={openRetention}
        maxWidth="xl"
        title="Customer Retention"
        borderRadius={"25px !important"}
        closeIcon={true}
        onClose={() => closeRetentionPopup()}
        /* eslint-disable react/no-children-prop */
        children={renderRetentionModal()}
      />

      {
        exchangeNavigationPopup ? (
          <RACExchange value={{customerId: customerId,
            rentPaid: rentPaid,
            agreementId: exchangeAgreementId,
            setExchangeNavigationPopup: setExchangeNavigationPopup,
            racExchangeFuncFlag : racExchangeFuncFlag,
            exchangeNavigationPopup: exchangeNavigationPopup,
            suspenseAmount: suspenseAmount,
            exchangeAgreementNumber: exchangeAgreementNumber
          }} />
        ): null
      }
      <RACModalCard
        borderRadius="20px"
        isOpen={updatePopUp}
        maxWidth="xs"
        closeIcon={true}
        onClose={() => setUpdatePopUp(false)}
        // eslint-disable-next-line react/no-children-prop
        children={UpdateInfoMsgPopup()}
      />

      {openSLA === true ? (
        <SecondFactor
          setTwoFactorCancelClick={SLACanceled}
          setTwoFactorCompleted={SLACompleted}
          currentRole={currentRole}
          moduleName={exchangeSelected ? 'RACExchange' :"CustomerRetention"}
        />
      ) : null}
    </>
  );
}
